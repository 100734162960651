<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Notification Preferences</h4>
            </div>
            <Spinner class="text-center" v-if="loading"/>
            <template v-else>
                <div v-for="notificationType in notificationTypes" :key="notificationType.uuid">
                    <EditNotificationType
                        :editable="editable"
                        :client="client"
                        :notification-type="notificationType"
                        :notification-methods="clientNotificationMethods"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import EditNotificationType from './EditClientNotificationType.vue'
import Spinner from '../Spinner.vue'

const axios = inject('axios');
const toast = inject('toast');

const props = defineProps({
    client: {
        type: Object,
        required: true
    },
    readOnly: {
        type: Boolean,
        default: true
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})

const loading = ref(true)
const notificationTypes = ref([])
const clientNotificationMethods = ref([])

const loadNotificationTypes = new Promise((resolve, reject) => {
    axios
        .get(route('api.notification-types.index'), { params: { scope: 'clients' } })
        .then((response) => {
            notificationTypes.value = response.data.data
            resolve()
        })
        .catch(error => {
            reject(error)
        })
})

const loadClientNotificationMethods = new Promise((resolve, reject) => {
    axios
        .get(route('api.clients.notification-methods.index', { client: props.client.uuid }))
        .then((response) => {
            clientNotificationMethods.value = response.data
            resolve()
        })
        .catch(error => {
            reject(error)
        })
})

onMounted(() => {
    Promise.all([loadNotificationTypes, loadClientNotificationMethods])
        .then(() => {
            loading.value = false
        })
        .catch(error => {
            console.error(error)
            toast.error('An error occurred while loading notification preferences.')
        })
    })
</script>
