import axios from "axios";
import { DateTime } from "luxon";
import BookingResource from "@/resources/js/types/resources/BookingResource";
import { PaginatedBookingTagInterface } from "./BookingTag";
import {
    LaravelPaginate,
    PaginatedMetaInterface,
} from "../types/LaravelCommonTypes";

export type BookingInterface = BookingResource;

export interface ListBookingsRequestParameters {
    clients?: string[];
    uuids?: string[];
    date?: DateTime;
    date_from?: DateTime;
    date_to?: DateTime;
    timezone?: string;
    order_by?: string;
    order_direction?: "asc" | "desc";
    paginate?: boolean;
    page?: number;
    amount?: number;
    status_filter?: string[];
    query?: string;
    unallocated?: boolean;
    reschedule_identifier?: string;
    group_booking_identifier?: string;
    sequence_identifier?: string;
    booking_form_identifier?: string;
    transport_requirements?: {
        code: string;
        value: number;
    }[];
}
export interface SaveAllocationChangesRequestInterface {
    bookings: Array<Partial<App.Models.Booking>>;
    journeys: Array<Partial<App.Models.Journey>>;
}

export enum BookingStatus {
    Future = "future",
    Verified = "verified",
    Cancelled = "cancelled",
    Standby = "standby",
    WaitingVerification = "waiting_verification",
    Unmet = "unmet",
    Transferred = "transferred",
    Posted = "posted",
}

export enum RecurringBookingUpdateBehaviour {
    BreakRecurranceChain = "break_recurrance_chain",
    SaveForAllFutureBookings = "save_for_all_future_bookings",
}

export const RecurringBookingUpdateBehaviourOptions: {
    value: RecurringBookingUpdateBehaviour;
    description: string;
}[] = [
    {
        value: RecurringBookingUpdateBehaviour.SaveForAllFutureBookings,
        description:
            "Save for this day and all future bookings in this recurrence",
    },
    {
        value: RecurringBookingUpdateBehaviour.BreakRecurranceChain,
        description: "Save for this booking only, break the recurrence chain",
    },
];

export enum BookingPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

export default class Booking {
    static convertIndexParametersToApiParameters(
        parameters: ListBookingsRequestParameters
    ) {
        return {
            ...parameters,
            ...{
                date: parameters.date
                    ? parameters.date.toFormat("yyyy-MM-dd HH:mm")
                    : undefined,
                date_from: parameters.date_from
                    ? parameters.date_from.toFormat("yyyy-MM-dd")
                    : undefined,
                date_to: parameters.date_to
                    ? parameters.date_to.toFormat("yyyy-MM-dd")
                    : undefined,
            },
        };
    }

    static index(parameters: ListBookingsRequestParameters) {
        return axios.get<BookingInterface[]>(
            route(
                "api.bookings.index",
                this.convertIndexParametersToApiParameters(parameters)
            )
        );
    }

    static indexPaginated(parameters: ListBookingsRequestParameters) {
        return axios.get<LaravelPaginate<BookingResource>>(
            route("api.bookings.index", {
                ...this.convertIndexParametersToApiParameters(parameters),
                ...{ paginate: true },
            })
        );
    }

    // this is only for hotfix, we will remove this in the future and put it back to index
    static tempIndex(parameters: ListBookingsRequestParameters) {
        return axios.get<BookingInterface[]>(
            route(
                "api.bookings.temp",
                this.convertIndexParametersToApiParameters(parameters)
            )
        );
    }

    static listByFormId(parameters: ListBookingsRequestParameters) {
        return axios.get<LaravelPaginate<BookingResource>>(
            route("api.bookings.by-form-id", {
                ...this.convertIndexParametersToApiParameters(parameters)
            })
        );
    }

    static update(
        booking: App.Models.Booking,
        update: Partial<App.Models.Booking> & {
            manual_fee_amount?: number;
        }
    ) {
        return axios.put(
            route("api.bookings.update", { booking: booking.uuid }),
            update
        );
    }

    static showUrl(booking_uuid: string) {
        return route("bookings.show", { booking: booking_uuid });
    }

    static async show(booking_uuid: string) {
        return axios.get(route("api.bookings.show", { booking: booking_uuid }));
    }

    static saveAllocationChanges(
        payload: SaveAllocationChangesRequestInterface
    ) {
        return axios.post(route("bookings.allocations.save"), payload);
    }

    static restoreCancelled(booking: App.Models.Booking) {
        return axios.post(
            route("api.bookings.restore.cancelled", { booking: booking.uuid })
        );
    }
}
