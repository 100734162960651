<template>
    <div>
        <canvas :id="uuid" :width="width" :height="height"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
    name: "ClientTripsChart",
    data() {
        return {
            uuid: this.$uuid.v1(),
            chart: null,
            chartCanvas: null,
        }
    },
    props: {
        width: {
            type: Number,
            required: false,
            default: 400,
        },
        height: {
            type: Number,
            required: false,
            default: 400,
        },
        bookingsPerMonth: Array
    },
    mounted() {
        this.chartCanvas = document.getElementById(this.uuid)
        this.chart = new Chart(this.chartCanvas, {
            type: 'bar',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [{
                    label: '# of Trips',
                    data: this.bookingsPerMonth,
                    borderWidth: 1,
                    backgroundColor: [
                        'rgba(37,80,124,0.7)',
                        'rgb(66,133,206, 0.7)'
                    ]
                }]
            },
            options: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                barPercentage: 0.5,
                borderRadius: 4,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        })
    }
}
</script>

<style scoped>

</style>
