<template>
    <div class="d-flex">
        <a v-if="client.phone" :href="$filters.phone_url(client.phone)" title="Call Client">
            <SvgIcon
                type="mdi"
                :path="mdiPhone"
                class="me-1 w-4 h-4 text-primary"
            ></SvgIcon>
        </a>
        <EditableInput
            @updated="storeChanges"
            :value="$filters.phone(client.phone)"
            :loading="storing"
            :disabled="!editable"
            type="text"
        ></EditableInput>
    </div>
</template>

<script>
export default {
    name: "EditClientPhoneNumber"
}
</script>
<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPhone } from '@mdi/js'
import axios from "axios";
import {computed, ref, inject} from "vue";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";

const toast = inject('toast')

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const errors = ref({})
const storing = ref(false)
const storeChanges = function (phone) {
    storing.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            phone: phone
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            if (error.response && error.response.status === 422) {
                errors.value = error.response.data.errors
                toast.error(errors.value[Object.keys(errors.value)[0]][0])
            } else {
                toast.error('An unknown error occurred while updating the phone number. Our developers have been notified of this issue, please try again later.')
            }
        })
        .finally(() => {
            storing.value = false
        })
}

</script>

<style scoped>

</style>
