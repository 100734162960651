<template>
    <div class="w-25">
        <select v-model="driver" class="form-select mb-3">
            <option v-for="driver in drivers" :value="driver.uuid">{{ driver.name }}</option>
        </select>
        <textarea v-model="message" class="form-control mb-3" cols="30" rows="10"></textarea>
        <div class="form-check form-check-inline">
            <input v-model="newMessage" type="checkbox" class="form-check-input">
            <label class="form-check-label">New message!</label>
        </div>
        <button @click="sendMessage" class="btn btn-primary">Send!</button>
    </div>
</template>

<script>
import { firebase, firebaseRTDB, ref, onValue, get, set, child, push, orderByKey, startAt, endAt, query, update, orderByChild } from "../../firebase"
export default {
    name: "DriverChat",
    data() {
        return {
            driver: null,
            drivers: [],
            message: '',
            newMessage: false,
            organisationBase: 'organisation/' + window.tenant.access_token,
        }
    },
    methods: {
        getDrivers() {
            this.$http
                .get(this.route('drivers.index'))
                .then((response) => {
                    this.drivers = response.data
                })
        },
        sendMessage() {
            const timeNow = new Date().getTime()
            const prevMessageTime = this.newMessage ? timeNow-5000000 : timeNow-20
            const newMessageKey = push(child(ref(firebaseRTDB), this.organisationBase + '/chats/' + this.driver + '/messages')).key
            const updates = {}

            updates[this.organisationBase + '/chats/' + this.driver + '/messages/' + newMessageKey] = {
                content: this.message,
                created_at: timeNow,
                created_by_id: 'driver_' + this.driver,
                type: 'message',
                updated_at: timeNow,
            }

            update(ref(firebaseRTDB), updates)
            set(ref(firebaseRTDB, this.organisationBase + '/events/' + this.driver ), {
                message_id: newMessageKey,
                created_at: timeNow,
                read_at: this.newMessage ? null : timeNow,
                last_message_created_at: prevMessageTime,
            })
        }
    },
    beforeMount() {
        this.getDrivers()
    }
}
</script>

<style scoped>

</style>
