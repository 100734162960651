<template>
    <div>
        <SvgIcon type="mdi" :path="path"></SvgIcon>
        {{ type }}
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import SvgIcon from '@jamescoyle/vue-icon';
    import {
        mdiFilePdfBox,
        mdiFileJpgBox,
        mdiFilePngBox,
        mdiFileGifBox,
        mdiImageArea,
        mdiSvg,
        mdiFileDocumentOutline,
        mdiFileWordBox,
        mdiText,
        mdiGoogleSpreadsheet,
        mdiFileDelimited,
        mdiFileXmlBox,
        mdiFilePowerpointBox,
    } from '@mdi/js';

    const props = withDefaults(
        defineProps<{
            type: string|null,
        }>(),
        {}
    )

    const icons = {
        // Images
        jpg: mdiFileJpgBox,
        jpeg: mdiFileJpgBox,
        png: mdiFilePngBox,
        gif: mdiFileGifBox,
        webp: mdiImageArea,
        svg: mdiSvg,
        // Documents
        pdf: mdiFilePdfBox,
        doc: mdiFileWordBox,
        docx: mdiFileWordBox,
        rtf: mdiFileDocumentOutline,
        odt: mdiFileDocumentOutline,
        txt: mdiText,
        //Data
        ods: mdiGoogleSpreadsheet,
        xls: mdiGoogleSpreadsheet,
        xlsx: mdiGoogleSpreadsheet,
        csv: mdiFileDelimited,
        xml: mdiFileXmlBox,
        // Presentation
        key: mdiFilePowerpointBox,
        odp: mdiFilePowerpointBox,
        ppt: mdiFilePowerpointBox,
        pps: mdiFilePowerpointBox,
        pptx: mdiFilePowerpointBox,
    }

    const path = computed(() => {
        if (props.type == null) {
            return null;
        }
    
        return icons[props.type]
    });

</script>