<template>
    <div>
        <Modal 
            :show="showUploadAttachmentModal"
            :loading="loadingAttachments"
            title="Upload Attachment"
            :large="true"
            @update:show="toggleUploadAttachmentModal"
        >
            <template v-slot:body>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center text-center">
                        <div 
                            @dragover.prevent="dragOver"
                            @dragleave.prevent="dragLeave"
                            @drop="drop"
                            class="uploader"
                            :class="hovering ? 'bg-hover' : 'bg-normal'"
                        >
                            <input
                                id="fileInput"
                                type="file"
                                :multiple="true"
                                name="fields[fileInput][]"
                                @change="onChange"
                                :accept="attachmentExtensions" 
                                ref="filesInput"
                                class="file-input"
                            />
                            <label for="fileInput" class="file-input-label">
                                <SvgIcon class="file-input-icon" type="mdi" :path="mdiFilePlusOutline" size="72"></SvgIcon>
                                <div class="upload-text-main">
                                    Click here to upload attachments
                                </div>
                                or drag & drop here
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="draftAttachments?.length > 0" class="file-list">
                    <div class="row file-item" v-for="(attachment, idx) in draftAttachments" :key="idx">
                        <div class="col-md-5 col-sm-11 lh-lg pt-1">
                            <strong>Filename:</strong> <br />
                            {{ attachment.attachment.name }}
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <strong>Title:</strong> <br />
                            <AttachmentUploadNameInput 
                                @updated="(name) => attachment.name = name" 
                                :index = idx @valid="(event) => handleValid(event, idx)"
                                :name="attachment.name"    
                            />
                        </div>
                        <div class="col-md-1 col-sm-1 text-danger text-end align-self-center">
                            <SvgIcon @click="removeAttachment(idx)" type="mdi" :path="mdiClose"></SvgIcon>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <Button 
                    color="success"
                    :loading="loadingAttachments"
                    :disabled="!isValid"
                    @click="bulkUploadAttachments"
                >
                    Upload
                </Button>
                <Button color="light" @click="toggleUploadAttachmentModal">Close</Button>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed } from "vue";
    import { Modal, Button } from "@components";
    import { storeToRefs } from "pinia";
    import { useAttachmentStore } from "@stores/AttachmentStore";
    import { each } from "lodash";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiClose, mdiFilePlusOutline } from '@mdi/js'
    import AttachmentUploadNameInput from "./AttachmentUploadNameInput.vue";
    import { reduce } from "lodash";
    
    const attachmentStore = useAttachmentStore();
    
    const {
        showUploadAttachmentModal,
        draftAttachments,
        loadingAttachments,
        attachmentExtensions,
    } = storeToRefs(attachmentStore);

    const { bulkUploadAttachments, toggleUploadAttachmentModal } = attachmentStore;

    const props = withDefaults(
        defineProps<{
            attachable?: App.Attachment.Attachable,
        }>(),
        {}
    )

    const filesInput = ref<any>(null);
    const hovering = ref<boolean>(false);

    const onChange = (): void => {
        if (filesInput.value?.files?.length > 0) {
            each(filesInput.value?.files, (file) => {
                let data: App.Attachment.Upload = {
                    name: file.name.split('.')[0],
                    attachment: file,
                    valid: true,
                }

                if (props.attachable != null) {
                    data.attachable_id = props.attachable.attachable_id;
                    data.attachable_type = props.attachable.attachable_type;
                }

                draftAttachments.value.push(data);

            });

            filesInput.value = null;
        };
    }

    const dragOver = (): void => {
        hovering.value = true;
    }

    const dragLeave = (): void => {
        hovering.value = false;
    }

    const drop = (event): void => {
        event.preventDefault();
        
        if (event.dataTransfer.files.length > 0) {
            filesInput.value.files = event.dataTransfer.files
        }

        hovering.value = false;
        onChange();
    }

    const removeAttachment = (index) => {
        draftAttachments.value.splice(index, 1);
    }

    const handleValid = (valid, index) => {
        draftAttachments.value[index].valid = valid;
    }

    const isValid = computed(() => {
        if (draftAttachments.value.length < 1) {
            return false;
        }

        let valid = reduce(draftAttachments.value, (valid, attachment) => {
            if (!attachment.valid) {
                valid = false;
            }

            return valid;
        }, true);

        return valid;
    })
</script>

<style scoped>
    .uploader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        min-height: 200px;
        padding: 2rem;
        border: 2px dashed #dee2e6;
        border-radius: 20px; 
        box-shadow: 1px 1px 1px 0 #F1F3FA,
            5px 2px 5px 0 #BDC1C5;
    }

    .upload-text-main {
        color: #0351BF;
        font-size: 1.1rem;
    }

    .bg-hover {
        background: #F1F3FA;
    }

    .bg-normal {
        background: #fff;
    }

    .file-input {
        opacity: 0;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }

    .file-input-label {
        display: block;
        cursor: pointer;
        color: #BDC1C5;
        font-size: 1rem;
    }

    .file-input-icon {
        fill: #BDC1C5;
        margin-bottom: 2rem;
    }

    .file-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }

    .file-item {
        width: 80%;
        padding: 1rem;
        border: 2px dashed #dee2e6;
        border-radius: 20px; 
        margin-top: 0.5rem;
        box-shadow: 1px 1px 1px 0 #F1F3FA,
            5px 2px 5px 0 #BDC1C5;
    }
</style>