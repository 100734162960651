<template>
    <dl class="row mb-0">
        <dt class="col-sm-4">{{ notificationType.label }}</dt>
        <dd class="col-sm-8">
            <span v-if="!showNotificationsForm" @click="showEditNotificationTypesForm"
                :class="[editable ? 'click-to-edit' : 'text-muted']">
                {{ editNotificationMethodForm.notification_method.label }}
            </span>

            <form v-if="showNotificationsForm">
                <div class="mb-2">
                    <VueMultiselect
                        v-model="editNotificationMethodForm.notification_method"
                        :options="allowedNotificationMethods"
                        :allow-empty="false"
                        deselect-label
                        placeholder="Select notification methods"
                        label="label"
                    ></VueMultiselect>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                    <Button size="small" :loading="updating" @click="update">Save</Button>
                    <Button
                        :class-array="['ms-1']"
                        :disabled="updating"
                        @click="cancelChanges"
                        color="light"
                        size="small"
                    >Cancel</Button>
                </div>
            </form>
        </dd>
    </dl>
</template>

<script setup>
import { ref, inject, computed, onMounted, reactive } from 'vue'
import Button from '../Button.vue'
import VueMultiselect from 'vue-multiselect'
import {filter, indexOf} from "lodash";

const axios = inject('axios');
const toast = inject('toast');

const props = defineProps({
    editable: {
        type: Boolean,
        required: true
    },
    client: {
        type: Object,
        required: true
    },
    notificationType: {
        type: Object,
        required: true
    },
    notificationMethods: {
        type: Array,
        required: true
    },
})

const allowedNotificationMethods = computed(() => {
    return filter(
        props.notificationMethods,
        (method) => {
            return indexOf(props.notificationType.methods, method.value) >= 0
        }
    )
})

const savedNotificationMethod = computed(() => {
    if (props.client.notification_types.find(n => n.name === props.notificationType.name) === undefined) {
        return props.notificationMethods.find(n => n.value === props.notificationType.default_method)
    }

    // Find the method for the client's notifications of this type.
    const method = props.client.notification_types.find(n => n.name === props.notificationType.name).method

    // Now find the label for the method.
    return props.notificationMethods.find(n => n.value === method)
})

const showNotificationsForm = ref(false)
const showEditNotificationTypesForm = function () {
    if (!props.editable) {
        return
    }
    showNotificationsForm.value = true
}

let editNotificationMethodForm = reactive({
    notification_type: props.notificationType,
    notification_method: {},
})

const cancelChanges = function () {
    showNotificationsForm.value = false
    editNotificationMethodForm.notification_method = savedNotificationMethod.value
}

const updating = ref(false)

const update = () => {
    updating.value = true
    axios
        .patch(route('api.clients.update', { client: props.client.uuid }), {
            notification_type: editNotificationMethodForm.notification_type.uuid,
            notification_method: editNotificationMethodForm.notification_method.value,
        })
        .then(response => {
            toast.success('Notification preferences updated')
            showNotificationsForm.value = false
        })
        .catch(error => {
            toast.error('Unable to update notification types')
        })
        .finally(() => {
            updating.value = false
            showNotificationsForm.value = false
        })
}

onMounted(() => {
    editNotificationMethodForm.notification_method = savedNotificationMethod.value
})
</script>
