<template>
    <div class="inline-block">
        <span
            v-if="!editing || loading"
            @click="editing = !(loading || disabled)"
            :class="loading || disabled ? null : 'click-to-edit'"
        >
            <template v-if="value !== null && value?.length">{{
                    label ?? value
                }}</template>
            <template v-else
            ><small><em>Click to add</em></small></template
            >
        </span>
        <input
            v-model="fieldValue"
            v-focus
            @focusout="update"
            v-if="editing && !loading"
            :type="type"
            class="form-control form-control-sm"
            :disabled="loading || disabled"
            @keyup.enter="update"
            @input="updateInput"
        />
        <Spinner
            class="ms-1"
            v-if="loading"
            type="border"
            size="small"
        ></Spinner>
    </div>
</template>

<script setup lang="ts">
import {ref, onBeforeMount} from "vue";
import Spinner from "../../Spinner.vue";
import {debounce} from "lodash";

const fieldValue = ref<string | null>(null);

const props = withDefaults(
    defineProps<{
        value: string | null;
        loading?: boolean;
        disabled?: boolean;
        label?: string;
        type?: "text" | "date" | "datetime-local" | "time";
        maxlength?: number;
    }>(),
    {
        loading: false,
        disabled: false,
        label: undefined,
        type: "text",
    }
);

const emit = defineEmits(["updated"]);

let editing = ref(false);

const update = () => {
    if (!props.loading) {
        onUpdated();
        editing.value = false;
    }
};

const onUpdated = debounce(() => {
    emit("updated", fieldValue.value);
}, 500);

const updateInput = function (event) {
    // max and maxlength isn't working for input type number so we use this
    const value = event.target.value
    if (props.maxlength !== undefined) {
        if (value.length > props.maxlength) {
            fieldValue.value = value.slice(0, props.maxlength);
        }
    }
}

onBeforeMount(() => {
    fieldValue.value = props.value;
});
</script>

<style scoped></style>
