<template>
    <table class="table table-bordered table-sm">
        <thead>
            <tr class="small">
                <th scope="col" class="text-center">Default</th>
                <th scope="col">Region</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(regionPivot, index) in modelValue" :key="index">
                <td class="align-middle col-1">
                    <div class="form-check d-flex justify-content-center">
                        <input
                            v-model="defaultRegionUuid"
                            @change="updateDefaultRegionUuid"
                            :id="`${regionPivot.uuid}-default`"
                            :name="`${regionPivot.uuid}-default`"
                            :value="regionPivot?.uuid"
                            class="form-check-input"
                            type="radio"
                        />
                    </div>
                </td>
                <td class="align-middle col-5">
                    {{ regionPivot.name }}
                </td>
                <td>
                    <button
                        @click="removeRegion(index)"
                        class="btn btn-sm btn-link"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiMinusCircle"
                            class="text-secondary"
                        ></SvgIcon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="align-middle col-1">
                    <div class="form-check d-flex justify-content-center"></div>
                </td>
                <td class="align-middle col-10">
                    <select
                        v-model="newRegionUuid"
                        class="form-select form-select-sm"
                    >
                        <option value="">-- Select Region --</option>
                        <template v-for="region in selectableRegions">
                            <option :value="region.uuid">
                                {{ region.name }}
                            </option>
                        </template>
                    </select>
                </td>
                <td class="align-middle col-1">
                    <div class="d-flex justify-content-center">
                        <button @click="addRegion" class="btn btn-sm btn-link">
                            <SvgIcon
                                type="mdi"
                                :path="mdiPlusCircle"
                                class="text-primary"
                            ></SvgIcon>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { toRefs, ref, onMounted, computed } from "vue";
import Region from "@classes/Region";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusCircle, mdiMinusCircle } from "@mdi/js";
import ClientRegionPivotResource from "@customTypes/resources/ClientRegionPivotResource";
import { useRegionStore } from "../../stores/RegionStore";

const newRegionUuid = ref("");
const regionStore = useRegionStore();

const updateDefaultRegionUuid = () => {
    let newValue: ClientRegionPivotResource[] = [...modelValue.value];

    newValue.forEach((regionPivot) => {
        regionPivot.pivot.is_default =
            regionPivot.uuid === defaultRegionUuid.value;
    });

    emit("update:modelValue", newValue);
};

const emit = defineEmits<{
    (e: "update:modelValue", newValue: ClientRegionPivotResource[]): void;
}>();

const props = withDefaults(
    defineProps<{
        modelValue: ClientRegionPivotResource[];
    }>(),
    {}
);

const regions = ref<App.Models.Region[]>([]);

const getRegions = async () => {
    regions.value = await regionStore.sync();
};

const { modelValue } = toRefs(props);

const defaultRegionUuid = computed({
    get() {
        return modelValue.value.find((i) => i.pivot.is_default)?.uuid || "";
    },
    set(value: string) {
        let newValue: ClientRegionPivotResource[] = [...modelValue.value];
        newValue.forEach((regionPivot) => {
            regionPivot.pivot.is_default = regionPivot.uuid === value;
        });

        emit("update:modelValue", newValue);
    },
});

const selectableRegions = computed(() => {
    return regions.value.filter((region) => {
        return !modelValue.value.some((i) => i.uuid === region.uuid);
    });
});

const addRegion = () => {
    let newRegion = regions.value.find(
        (region) => region.uuid === newRegionUuid.value
    );

    if (!newRegion) {
        return;
    }

    let newValue: ClientRegionPivotResource[] = [...modelValue.value];

    //Check if there is already a default region
    let defaultRegion = newValue.find((i) => i.pivot.is_default);

    newValue.push({
        name: newRegion.name,
        uuid: newRegion.uuid,
        pivot: {
            is_default: defaultRegion ? false : true,
        },
    });

    emit("update:modelValue", newValue);

    newRegionUuid.value = "";
};

const removeRegion = (index: number) => {
    let newValue: ClientRegionPivotResource[] = [...modelValue.value];

    newValue.splice(index, 1);

    emit("update:modelValue", newValue);
};

onMounted(() => {
    getRegions();
});
</script>
