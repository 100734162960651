<template>
    <div>
        <div class="card">
            <div class="card-body">
                <template v-if="loading">
                    <div
                        class="d-flex w-100 justify-content-center align-items-center ps-3"
                    >
                        <div class="spinner-grow my-5 me-3" role="status"></div>
                        <div class="fs-4">Loading vehicle runs</div>
                    </div>
                </template>

                <template v-if="!loading">
                    <template v-if="journeys.length > 0">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-md-3 mb-3 flex-shrink-1 text-nowrap">
                                <h4 class="header-title mt-0">
                                    Allocated Bookings
                                </h4>
                            </div>
                            <div class="col-md-1 mb-3 flex-shrink-1 text-nowrap">
                                <AllocationsFormAllocatedFilter />
                            </div>
                            <div class="col-md-8 mb-3 flex-shrink-1 text-nowrap">
                                <AllocationFormAllocatedSearch />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3" id="vehicleRuns">
                                <h4 class="header-title mt-0 mb-2 d-flex justify-content-between align-items-center">
                                    Vehicle Runs
                                    <a
                                        href="javascript: void(0)"
                                        @click="createVehicleRun"
                                        ><SvgIcon type="mdi" :path="mdiPlus"
                                    /></a>
                                </h4>

                                <div class="d-flex flex-row align-items-center justify-content-between">
                                    <input
                                        v-model="isAllVehicleRunsSelected"
                                        class="form-check-input"
                                        type="checkbox"
                                    />
                                    <div class="d-flex flex-row">
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-light d-flex align-items-center"
                                            @click="expandAll"
                                            v-if="!allExpanded"
                                        >
                                            <SvgIcon
                                                type="mdi"
                                                :size="18"
                                                :path="mdiExpandAll"
                                            />
                                            <span class="ms-1">Expand All</span>
                                        </button>

                                        <button
                                            class="btn btn-sm btn-light d-flex align-items-center"
                                            @click="collapseAll"
                                            v-if="allExpanded"
                                        >
                                            <SvgIcon
                                                :size="18"
                                                type="mdi"
                                                :path="mdiCollapseAll"
                                            />
                                            <span class="ms-1"
                                                >Collapse All</span
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div
                                    v-for="(vehicle, index) in filteredVehicles"
                                    :key="vehicle.vehicle.uuid"
                                    class="border-bottom py-1"
                                >
                                    <div
                                        class="d-flex justify-content-between align-items-center"
                                        style="cursor: pointer"
                                        @click="toggleExpand(index)"
                                    >
                                        <div>
                                            {{
                                                vehicle.vehicle.description ??
                                                vehicle.vehicle
                                                    .registration_code
                                            }}
                                        </div>
                                        <div>
                                            <span
                                                class="text-xs text-secondary"
                                                >{{
                                                    vehicle.vehicle
                                                        .registration_code
                                                }}</span
                                            >
                                            <SvgIcon
                                                type="mdi"
                                                :path="
                                                    expandedVehicleIndexes.includes(
                                                        index
                                                    )
                                                        ? mdiMenuUp
                                                        : mdiMenuDown
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div v-if="
                                            expandedVehicleIndexes.includes(
                                                index
                                            )
                                        "
                                        class="mt-1">
                                        <div
                                            v-for="run in vehicle.runs"
                                            :key="run.uuid"
                                            class="mb-1"
                                        >
                                            <AllocationFormJourneyButton
                                                :journey="run"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <AllocationFormJourneyDetails
                                    v-if="selectedJourney?.uuid"
                                    :key="selectedJourney?.uuid + '_details'"
                                    :bookingTimeValidationErrors="
                                        bookingTimeValidationErrors
                                    "
                                    class="sticky-xxl-top"
                                    style="top: 1rem"
                                ></AllocationFormJourneyDetails>
                            </div>
                        </div>
                    </template>
                    <div class="text-center my-3" v-else>
                        <SvgIcon
                            type="mdi"
                            :path="mdiCar"
                            class="w-10 h-10 text-muted"
                        />
                        <p class="fs-4 mt-2 mb-3">
                            There are no vehicle runs scheduled for this day.
                        </p>
                        <Button :icon="mdiPlus" @click="createVehicleRun"
                            >Schedule Vehicle Run</Button
                        >
                    </div>
                </template>

                <div
                    id="createOrEditVehicleRunModal"
                    aria-hidden="true"
                    aria-labelledby="createOrEditVehicleRunModalLabel"
                    class="modal fade"
                    tabindex="-1"
                >
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5
                                    id="createOrEditVehicleRunModalLabel"
                                    class="modal-title"
                                >
                                    {{ mode }} Vehicle Run
                                </h5>
                                <button
                                    aria-label="Close"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    type="button"
                                ></button>
                            </div>
                            <div class="modal-body">
                                <CreateOrEditVehicleRun
                                    :key="createModalRefreshKey"
                                    ref="vehicleRunCreateForm"
                                    :vehicle-run-statuses="vehicleRunStatuses"
                                    @created="handleCreatedRuns"
                                    :mode="mode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import AllocationFormJourneyDetails from "./AllocationFormJourneyDetails.vue";
import CreateOrEditVehicleRun from "../Vehicles/VehicleRuns/CreateOrEditVehicleRun.vue";
import { computed, onMounted, ref } from "vue";
import Button from "../Button.vue";
import {
    mdiPlus,
    mdiCar,
    mdiMenuUp,
    mdiMenuDown,
    mdiCollapseAll,
    mdiExpandAll,
} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { DateTime } from "luxon";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import AllocationFormJourneyButton from "./AllocationFormJourneyButton.vue";
import AllocationsFormAllocatedFilter from "@components/Allocations/Components/AllocationsFormAllocatedFilter.vue";
import AllocationFormAllocatedSearch from "@components/Allocations/Components/AllocationFormAllocatedSearch.vue";

const allocationStore = useAllocationStore();

const { getJourneys, matchesAllocationAllocatedSectionSearchPhrase } = allocationStore;

const {
    selectedJourney,
    selectedJourneysForAllocation,
    journeys,
    vehicles,
    expandedVehicleIndexes,
    allocatedBookingsFilters,
    date,
    allocationAllocatedSectionSearchPhrase,
    bookings,
} = storeToRefs(allocationStore);

const props = withDefaults(
    defineProps<{
        loading: boolean;
        bookingTimeValidationErrors: object;
    }>(),
    {}
);

const showCreateJourneyForm = ref(false);
const createModalRefreshKey = ref(Math.random());
const vehicleRunStatuses = ref(Object.values(window.enums.vehicleRunStatus));
const mode = ref("Create");
const createOrEditModal = ref<null | bootstrap.Modal>(null);

const filteredVehicles = computed(() => {
    return vehicles.value.filter((vehicleGroup) => {
        let matchesDepotFilter =
            allocatedBookingsFilters.value.depots.length == 0 ||
            allocatedBookingsFilters.value.depots.find(
                (i) => i.uuid == vehicleGroup.vehicle.depot?.uuid
            );

        let matchesSearchResult =
            allocationAllocatedSectionSearchPhrase.value == "" ||
            vehicleGroup.runs.find((i) =>
                bookings.value
                    .filter(matchesAllocationAllocatedSectionSearchPhrase)
                    .filter((a) => a.journey?.uuid)
                    .map((a) => a.journey?.uuid)
                    .includes(i.uuid)
            );

        return matchesDepotFilter && matchesSearchResult;
    });
});

const toggleExpand = (index: number) => {
    if (expandedVehicleIndexes.value.includes(index)) {
        expandedVehicleIndexes.value = expandedVehicleIndexes.value.filter(
            (i) => i !== index
        );
    } else {
        expandedVehicleIndexes.value.push(index);
    }
};

const allExpanded = computed(() => {
    return (
        expandedVehicleIndexes.value.length === vehicles.value.length &&
        vehicles.value.length > 0
    );
});

const expandAll = () => {
    let newIndexes: number[] = [];
    vehicles.value.map((i, v: number) => {
        newIndexes.push(v);
    });
    expandedVehicleIndexes.value = newIndexes;
};

const collapseAll = () => {
    expandedVehicleIndexes.value = [];
};

const isAllVehicleRunsSelected = computed({
    get() {
        return (
            selectedJourneysForAllocation.value.length === journeys.value.length
        );
    },
    set(newValue) {
        if (newValue) {
            selectedJourneysForAllocation.value = journeys.value;
        } else {
            selectedJourneysForAllocation.value = [];
        }
    },
});

const createVehicleRun = () => {
    mode.value = "Create";
    createModalRefreshKey.value = Math.random();
    createOrEditModal.value?.show();
};

const handleCreatedRuns = (journey) => {
    createOrEditModal.value?.hide();
    getJourneys();
};

onMounted(() => {
    createOrEditModal.value = new bootstrap.Modal(
        document.getElementById("createOrEditVehicleRunModal")
    );
});
</script>

<style scoped>
.fade-enter-active {
    transition: opacity 1s;
}

.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.collapse-enter-active,
.collapse-leave-active {
    transition: max-height 0.5s ease;
}
.collapse-enter,
.collapse-leave-to {
    max-height: 0;
    overflow: hidden;
}
</style>
