<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="header-title m-0">Emergency Contacts</h4>
                <Button @click.prevent="showEmergencyContactForm" size="small">
                    <SvgIcon type="mdi" :path="mdiPlusCircle" class="w-4 h-4 me-1"></SvgIcon>
                    Add contact
                </Button>
            </div>
            <p v-if="contacts.length === 0">There is no emergency contact currently specified for this {{ modelName }}.</p>
            <ul v-if="contacts.length > 0" class="list-group mt-2">
                <template v-for="(contact, key) in sortedContacts">
                    <li class="list-group-item">
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="d-flex align-items-start">
                                    <strong class="me-2">{{ contact.name }}</strong>
                                    <div v-if="key === 0 && contact.primary_contact_from">
                                        <span class="badge bg-primary rounded-pill">primary</span>
                                    </div>
                                </div>
                                <div>{{ contactRole(contact) }}</div>
                            </div>
                            <div class="dropdown">
                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="mdi mdi-dots-vertical"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a
                                        @click.prevent="editEmergencyContact(contact)"
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                    >
                                        Edit Contact
                                    </a>
                                    <a
                                        @click.prevent="showDeleteContactModal(contact)"
                                        class="dropdown-item text-danger"
                                        href="javascript:void(0);"
                                    >
                                        Delete Contact
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="contact.address" class="small">
                            {{ contact.address.formattedAddress }}
                        </div>
                        <div v-if="contact.email" class="d-flex flex-wrap">
                            <div><a :href="'mailto:' + contact.email">{{ contact.email }}</a></div>
                        </div>
                        <div class="mt-2 d-flex flex-wrap">
                            <a
                                v-if="contact.mobile_phone"
                                :href="$filters.phone_url(contact.mobile_phone)"
                                class="btn btn-light btn-sm d-flex align-items-center me-1 mb-1"
                                v-tooltip="'Call mobile'"
                            >
                                <SvgIcon type="mdi" :path="mdiCellphone" class="w-4 h-4 text-secondary me-1"></SvgIcon>
                                <small>{{ $filters.phone(contact.mobile_phone) }}</small>
                            </a>
                            <a
                                v-if="contact.home_phone"
                                :href="$filters.phone_url(contact.home_phone)"
                                class="btn btn-light btn-sm d-flex align-items-center me-1 mb-1"
                                v-tooltip="'Call home phone'"
                            >
                                <SvgIcon type="mdi" :path="mdiPhoneClassic" class="w-4 h-4 text-secondary me-1"></SvgIcon>
                                <small>{{ $filters.phone(contact.home_phone) }}</small>
                            </a>
                            <a
                                v-if="contact.work_phone"
                                :href="$filters.phone_url(contact.work_phone)"
                                class="btn btn-light btn-sm d-flex align-items-center me-1 mb-1"
                                v-tooltip="'Call work phone'"
                            >
                                <SvgIcon type="mdi" :path="mdiDomain" class="w-4 h-4 text-secondary me-1"></SvgIcon>
                                <small>{{ $filters.phone(contact.work_phone) }}</small>
                            </a>
                        </div>
                    </li>
                </template>
            </ul>

            <CreateOrEditContact
                id="createOrEditEmergencyContactModal"
                type="emergency"
                :model="modelName"
                :model_id="props.model.uuid"
                :contact="contactInEdit"
                @created="contactCreated"
                @updated="contactUpdated"
            ></CreateOrEditContact>

            <ConfirmationModal
                id="deleteEmergencyContactModal"
                confirmation-button-color="danger"
                @confirmed="deleteContact"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "EmergencyContact"
}
</script>
<script setup>
import { mdiPhoneAlert, mdiCellphone, mdiPlusCircle, mdiPhoneClassic, mdiDomain, mdiPencil } from '@mdi/js'
import {ref, onBeforeMount, onMounted, computed, inject} from 'vue'
import SvgIcon from "@jamescoyle/vue-icon";
import ConfirmButton from "../ConfirmButton.vue";
import Button from "../Button.vue";
import CreateOrEditContact from "../Contacts/CreateOrEditContact.vue";
import ConfirmationModal from '../ConfirmationModal.vue'
import {DateTime} from "luxon";
import ElmHelper from "@classes/Helpers/ElmHelper";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    emergencyContacts: {
        type: Array,
        required: false,
    },
    model: {
        Object,
        required: true
    },
    modelName: {
        String,
        required: true
    },
})

const emergencyContactModal = ref(null)
const showEmergencyContactForm = function () {
    contactInEdit.value = {}
    emergencyContactModal.value.show()
}

const contactInEdit = ref({})
const editEmergencyContact = (contact) => {
    contactInEdit.value = contact
    emergencyContactModal.value.show()
}

const contacts = ref([])
const contactCreated = function (contact) {
    emergencyContactModal.value.hide()
    contacts.value.push(contact)
}

const contactUpdated = function (contact) {
    emergencyContactModal.value.hide()
    const index = contacts.value.findIndex(c => c.uuid === contact.uuid)
    contacts.value[index] = contact
}

const contactToRemove = ref(null)
const showDeleteContactModal = function (contact) {
    contactToRemove.value = contact
    deleteEmergencyContactModal.value.show()
}

const deleteContact = function () {
    axios
        .delete(route('api.contact.destroy', {contact: contactToRemove.value.uuid}), {
            params: {
                for_model: props.modelName,
            }
        })
        .then(() => {
            contacts.value.splice(contacts.value.indexOf(contactToRemove.value), 1)
            contactToRemove.value = null
            deleteEmergencyContactModal.value.hide()
        }).catch(error => {
            toast.error('Error deleting contact')
        })
}

const contactRole = function (contact) {
    let roles = []
    if (contact.relationship) {
        roles.push(contact.relationship)
    }
    if (contact.role) {
        roles.push(contact.role)
    }

    return roles.join(' / ')
}

const sortedContacts = computed(() => {
    return contacts.value.sort((a, b) => {
        if (!a.primary_contact_from && b.primary_contact_from) {
            return 0
        }
        if (!a.primary_contact_from) {
            return 1
        }
        if (!b.primary_contact_from) {
            return -1
        }

        let dateA = DateTime.fromISO(a.primary_contact_from)
        let dateB = DateTime.fromISO(b.primary_contact_from)

        if (dateA < dateB) {
            return 1
        }

        if (dateA > dateB) {
            return -1
        }

        return 0
    })
})

onBeforeMount(() => {
    contacts.value = props.emergencyContacts
})

onMounted(() => {
    ElmHelper.waitUntilRendered('#createOrEditEmergencyContactModal').then((elm) => {
        emergencyContactModal.value = new bootstrap.Modal(document.getElementById('createOrEditEmergencyContactModal'))
    });
    ElmHelper.waitUntilRendered('#deleteEmergencyContactModal').then((elm) => {
        deleteEmergencyContactModal.value = new bootstrap.Modal(document.getElementById('deleteEmergencyContactModal'))
    });
})

</script>

<style scoped>

</style>
