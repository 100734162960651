<template>
    <tr>
        <td class="align-middle">{{ invite.email }}</td>
        <td class="align-middle">{{ invite.role.name }}</td>
        <td class="text-end align-middle">
            <button
                v-if="!resentSuccessfully && !deletedSuccessfully"
                :disabled="resending"
                @click="resendInvite"
                class="btn btn-sm btn-primary"
            >
                <template v-if="!resending">Resend</template>
                <div v-if="resending" class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            <span v-if="resentSuccessfully" class="text-success">Sent!</span>
        </td>
        <td class="text-end td-shrink">
            <button
                :disabled="deleting"
                class="btn btn-sm btn-outline-danger"
                @click="deleteInvite"
                v-if="!deletedSuccessfully"
            >
                <template v-if="!deleting">Cancel</template>
                <div v-if="deleting" class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            <template v-if="deletedSuccessfully">Invite Cancelled</template>
        </td>
    </tr>
</template>

<script>
export default {
    name: "PendingInviteRow",
    props: {
        invite: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            deleting: false,
            deletedSuccessfully: false,
            resending: false,
            resentSuccessfully: false,
        }
    },
    methods: {
        deleteInvite() {
            this.deleting = true
            this.$http
                .delete(this.route('api.invitation.destroy', {
                    invitation: this.invite.id,
                }))
                .then(() => {
                    this.deletedSuccessfully = true
                })
        },
        resendInvite() {
            this.resending = true
            this.$http
                .post(this.route('api.invitation.store'), {
                    invitations: [{
                        email: this.invite.email,
                        role: this.invite.role_id,
                    }],
                })
                .then(() => {
                    this.resentSuccessfully = true
                    setTimeout(() => { this.resentSuccessfully = false }, 5000)
                })
                .finally(() => {
                    this.resending = false
                })
        }
    }
}
</script>

<style scoped>

</style>
