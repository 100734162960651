<template>
    <div class="d-inline-block">
        <EditableInput
            :label="
                actualEventTime
                    ? DateTime.fromISO(actualEventTime).toFormat('HH:mm')
                    : ''
            "
            :loading="updating"
            :value="
                actualEventTime
                    ? DateTime.fromISO(actualEventTime).toISO({
                          includeOffset: false,
                          suppressMilliseconds: true,
                          suppressSeconds: true,
                      })
                    : ''
            "
            type="datetime-local"
            @updated="updateDestinationTime"
        />
    </div>
</template>

<script setup lang="ts">
import EditableInput from "../../Forms/EditableFields/EditableInput.vue";
import { inject, ref, computed } from "vue";
import Booking, { BookingInterface } from "@classes/Booking";
import BookingEvent, { BookingEventType } from "@classes/BookingEvent";
import { toTimeStamp } from "../../Utils.js";
import { DateTime } from "luxon";
import axios from "axios";
import { useVerificationStore } from "../../../stores/VerificationStore.js";

const toast = inject<any>("toast");

const verificationStore = useVerificationStore();

const props = defineProps<{
    booking: BookingInterface;
    journeyId: string;
    type: BookingEventType;
}>();

const actualEventTime = computed(() => {
    return props.booking.events?.find((event) => event.type === props.type)
        ?.occurred_at;
});

const handleErrorDisplay = (
    error: any,
    createOrUpdate: "create" | "update" = "create"
) => {
    let verb = createOrUpdate == "create" ? "creating" : "updating";
    toast.error(
        error?.response?.data?.message ??
            (props.type == BookingEventType.DROP_OFF
                ? `There was an error ${verb} the end time for this booking.`
                : `There was an error ${verb} the start time for this booking.`)
    );
};

const handleSuccess = (
    response: any,
    createOrUpdate: "create" | "update" = "create"
) => {
    let verb = createOrUpdate == "create" ? "created" : "updated";
    Booking.show(props.booking.uuid).then((response) => {
        verificationStore.updateBooking(props.journeyId, response.data);
    });

    toast.success(
        props.type == BookingEventType.DROP_OFF
            ? `End time ${verb} for booking.`
            : `Start time ${verb} for booking.`
    );
};

const updating = ref(false);
const updateDestinationTime = function (newDestinationTime: string) {
    updating.value = true;

    const newDestinationLuxon = DateTime.fromISO(newDestinationTime);

    let event = props.booking.events?.find(
        (event) => event.type === props.type
    );

    if (event) {
        BookingEvent.update(props.booking, event, {
            occurred_at: newDestinationLuxon,
        })
            .then((response) => handleSuccess(response, "update"))
            .catch((error) => handleErrorDisplay(error, "update"))
            .finally(() => {
                updating.value = false;
            });
    } else {
        BookingEvent.store({
            bookings: [props.booking],
            type: props.type,
            occurred_at: newDestinationLuxon,
        })
            .then((response) => handleSuccess(response, "create"))
            .catch((error) => handleErrorDisplay(error, "create"))
            .finally(() => {
                updating.value = false;
            });
    }
};
</script>

<style scoped></style>
