<template>
    <div class="row">
        <div
            :class="{
                'col-md-9': selectedSpaces.length > 0,
                'col-md-12': selectedSpaces.length == 0,
            }"
        >
            <div class="card">
                <div
                    class="card-body d-flex align-items-center justify-content-center flex-column"
                >
                    <h4>Front</h4>
                    <VehicleDiagram></VehicleDiagram>
                    <h4>Back</h4>
                </div>
            </div>
        </div>
        <div v-if="selectedSpaces.length > 0" class="col-md-3">
            <VehicleConfigurationDesignSpaceEdit />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useVehicleConfigurationDesignStore } from "@stores/VehicleConfigurationDesignStore";
import VehicleDiagram from "./VehicleDiagram.vue";
import VehicleConfigurationDesignSpaceEdit from "./VehicleConfigurationDesignSpaceEdit.vue";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const { selectedSpacesUuid, selectedSpaces, rows } = storeToRefs(
    vehicleConfigurationDesignStore
);
import templates, {
    VehicleConfigurationTemplateInterface,
} from "@components/Vehicles/Configuration/Design/VehicleConfigurationTemplates";

const props = withDefaults(
    defineProps<{
        templateid?: number;
    }>(),
    {
        templateid: 1,
    }
);

const { templateid } = toRefs(props);

onMounted(() => {
    let template =
        templates.find((template) => template.id == templateid.value) ??
        templates[0];

    rows.value = template.rows;
});
</script>
