<template>
    <div>
        <div class="row mb-2">
            <div v-if="hasCreateDriverPermission" class="col-md-6">
                <a :href="route('drivers.create')" class="btn btn-primary" type="button">
                    <i class="mdi mdi-plus-circle me-1"></i>
                    Add Driver
                </a>
            </div>
            <div class="col-md-6 text-end">
<!--                <button class="btn btn-primary me-1" type="button">-->
<!--                    <i class="mdi mdi-cog"></i>-->
<!--                </button>-->
<!--                <button class="btn btn-light me-1" type="button">-->
<!--                    <i class="mdi mdi-message me-1"></i>Send Group Email/SMS-->
<!--                </button>-->
<!--                <button class="btn btn-light me-1" type="button">-->
<!--                    <i class="mdi mdi-database-import-outline me-1"></i>Import-->
<!--                </button>-->
<!--                <button class="btn btn-light" type="button">-->
<!--                    <i class="mdi mdi-database-export-outline me-1"></i>Export-->
<!--                </button>-->
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <PageLengthSelector
                            record-name="drivers"
                            :disabled="loadingDrivers"
                        />
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <div
                            class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block"
                        >
                            <button
                                class="btn btn-light btn-sm dropdown-toggle"
                                type="button"
                                id="dropDownFilterBookings"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="mdiFilter"
                                    style="max-width: 16px"
                                ></SvgIcon>
                                Filter
                            </button>
                            <div
                                class="dropdown-menu px-4 py-3"
                                style="min-width: 500px"
                                aria-describedby="dropDownFilterBookings"
                            >
                                <div class="mb-2 row">
                                    <div class="col-md-12">
                                        <label class="form-label" for="service_type_filter"
                                        >Vehicle Depot</label
                                        >
                                        <VueMultiselect
                                            v-model="depotFilter"
                                            :options="depotList"
                                            placeholder="Select vehicle depots"
                                            label="full_address"
                                            track-by="uuid"
                                            multiple
                                        ></VueMultiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableSearch
                            @search="searchDrivers"
                            placeholder="Search drivers..."
                        />
                    </div>
                </div>

                <template v-if="!loadingDrivers">
                    <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                        <tr>
                            <th class="sorting">Driver</th>
                            <th class="sorting">Phone</th>
                            <th class="sorting">Email</th>
                            <th class="sorting">Depot</th>
                            <th class="sorting">Address</th>
                            <th class="text-end"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(driver, index) in drivers" :key="index">
                            <td class="table-user d-flex">
                                <a :href="route('drivers.show', { 'driver': driver.uuid })" class="d-flex align-items-center me-1">
                                    <DriverAvatar :driver="driver" size="extra-small" class="me-2"></DriverAvatar>
                                    {{ driver.name }}
                                </a>
                                <span v-if="driver.deleted_at" v-tooltip="'This driver has been deactivated'">
                                    <i class="mdi mdi-cancel text-danger"></i>
                                </span>
                            </td>
                            <td>{{ $filters.phone(driver.phone) }}</td>
                            <td><a :href="'mailto:'+driver.email">{{ driver.email }}</a></td>
                            <td><div v-for="depot in driver?.depots">{{ depot.full_address }}</div></td>
                            <td><template v-if="driver.address">{{ driver.address.formatted_address }}</template></td>
                            <td class="text-end">
<!--                                <a href="#" class="action-icon"><i class="mdi mdi-square-edit-outline"></i></a>-->
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </template>

                <template v-if="loadingDrivers">
                    <div class="d-flex w-100 justify-content-center align-items-center ps-3">
                        <Loading
                            :show-text="false"
                            spinner-class="spinner-grow"
                        />
                    </div>
                </template>

                <div v-if="paginatedMeta">
                    <Pagination
                        v-if="!loadingDrivers"
                        @pageChanged="getDrivers()"
                        record-name="drivers"
                    ></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {onBeforeMount, computed, watch, toRef, ref} from 'vue';
import { storeToRefs } from 'pinia';
import DriverAvatar from "./DriverAvatar.vue";
import { Pagination, PageLengthSelector, DataTableSearch } from "@components/DataTable";
import { useDriverStore } from "@stores/DriverStore";
import { usePaginationStore } from '@stores/PaginationStore';
import { useRegionStore } from '@stores/RegionStore';
import { Loading } from "@components";
import axios from "axios";
import {mdiFilter} from "@mdi/js";
import VueMultiselect from "vue-multiselect";
import { useDepotStore } from "@stores/DepotStore";
const depotStore = useDepotStore();
import SvgIcon from "@jamescoyle/vue-icon";

const { depotList } = storeToRefs(depotStore);

    const driverStore = useDriverStore();
    const { drivers, loadingDrivers } = storeToRefs(driverStore);
    const { getDrivers } = driverStore;

    const { paginatedMeta, paginateParams, searchQuery } = storeToRefs(usePaginationStore());

    const { selectedRegion } = storeToRefs(useRegionStore());

    const hasCreateDriverPermission = computed(() => {
        return window.permissions.includes('create_driver')
    })

    const depotFilter = ref([]);

    const pageAmount = ref(25);

    const searchDrivers = () => {
        if (searchQuery.value ||
            depotFilter.value.length > 0
        ) {
            const params = {
                query: searchQuery.value,
                amount: paginateParams.value.perPage,
            };

            if (depotFilter.value.length > 0) {
                params.location_ids = depotFilter.value.map((df) => {return df.uuid});
            }

            axios.get(route('api.drivers.search', params))
            .then((resp) => {
                drivers.value = resp.data.data;
                paginatedMeta.value = resp.data.meta;
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                loadingDrivers.value = false;
            });
        } else {
            getDrivers();
        }
    }

    const driverDepots = (driver) => {
        if (driver.depots) {
            const depotsArray = driver?.depots?.map((depot) => {
                return depot.name;
            });

            return depotsArray.toString();
        }

        return '';
    }

    watch(
        selectedRegion,
        () => getDrivers()
    );

    watch(
        paginateParams.value, (newParams) => {
            if (pageAmount.value !== newParams.perPage) {
                pageAmount.value = newParams.perPage;
                searchDrivers();
            }
        }
    );

    onBeforeMount(() => {
        getDrivers();
        depotStore.getDepotList();
    })
</script>

<style scoped>

</style>
