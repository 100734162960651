<template>
    <template v-if="serviceModelType === 'xero_api_xero_php_models_accounting_invoice'">
        <img src="/images/xero-logo.png" alt="Xero Logo" class="w-6" :title="`Last synced with ${serviceProvider} at ${formatDate(props.serviceLink.updated_at, 'short')}`"
             data-bs-toggle="tooltip"/>
    </template>
</template>

<script setup lang="ts">
import {formatDate} from "@components/Utils";
import ServiceLinkResource from "@customTypes/resources/ServiceLinkResource";
import {Tooltip} from "bootstrap";
import {snakeCase, split} from "lodash";
import {computed, onMounted} from "vue";

interface Props {
    serviceLink: ServiceLinkResource
}

const props = withDefaults(defineProps<Props>(), {
    serviceLink: undefined
})

const serviceModelType = computed((): string => {
    return snakeCase(props.serviceLink.service_model_type);
})

const serviceProvider = computed((): string => {
    return split(props.serviceLink.service_model_type, "\\", 1)[0];
})

onMounted(() => {
    new Tooltip(document.body, {
        selector: '[data-bs-toggle="tooltip"]'
    })
})
</script>

<style scoped>

</style>