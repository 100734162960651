<template>
    <div>
        <div v-if="this.successAlert && !loading" class="alert alert-success my-2">
            {{ this.successAlert }}
        </div>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <button @click="inviteMemberModal.show()" class="btn btn-primary" type="button">
                    <i class="mdi mdi-plus-circle me-1"></i>
                    Add Member
                </button>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <template v-if="loading">
                    <div class="d-flex w-100 justify-content-center align-items-center ps-3">
                        <div class="spinner-grow my-5 me-3" role="status"></div>
                        <div class="fs-4">Loading Users</div>
                    </div>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <label class="form-label text-nowrap">
                                Display
                                <select v-model="limit" @change="getUsers" class="form-select form-select-sm d-inline mx-1">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option :value="paginationMeta.total">All</option>
                                </select>
                                members
                            </label>
                        </div>
                    </div>
                    <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                            <tr>
                                <th scope="col">Members</th>
                                <th scope="col">Email</th>
                                <th scope="col">Role</th>
                                <th scope="col">Status</th>
                                <th scope="col" class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <UserListRow
                                v-for="user in users"
                                :key="user.uuid"
                                :user="user"
                                @userRemoved="userRemoved"
                            ></UserListRow>
                        </tbody>
                    </table>
                    <Pagination
                        @pageChanged="loadPage"
                        :pagination="paginationMeta"
                        record-name="members"
                    ></Pagination>
                </template>
            </div>
        </div>
        <InviteMemberModal @invitationsSent="invitationsSent"></InviteMemberModal>
    </div>
</template>

<script>
import UserListRow from "./UserListRow.vue";
import InviteMemberModal from "./InviteMemberModal.vue";
import Pagination from "../Pagination.vue";
export default {
    name: "UsersList",
    components: {
        UserListRow,
        InviteMemberModal,
        Pagination
    },
    data() {
        return {
            loading: true,
            users: null,
            page: 1,
            lastPage: 1,
            totalUserCount: 0,
            limit: 25,
            inviteMemberModal: null,
            successAlert: null,
            paginationMeta: {}
        }
    },
    methods: {
        getUsers() {
            this.$http
                .get(this.route('api.users.index', { page: this.page, limit: this.limit }))
                .then((response) => {
                    this.users = response.data.data
                    this.paginationMeta = {
                        links: response.data.links,
                        from: response.data.from,
                        to: response.data.to,
                        total: response.data.total
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadPage(link) {
            this.page = link.page
            this.getUsers()
        },
        invitationsSent() {
            this.inviteMemberModal.hide()
            this.successAlert = 'Success! Your invitations have been sent!'
            setTimeout(() => { this.successAlert = null }, 10000)
        },
        userRemoved(user) {
            this.getUsers()
            this.successAlert = 'Success! '+ user.name +' has been removed.'
            setTimeout(() => { this.successAlert = null }, 10000)
        }
    },
    mounted() {
        this.getUsers()
        this.inviteMemberModal = new bootstrap.Modal(document.getElementById('inviteMemberModal'))
    }
}
</script>

<style scoped>

</style>
