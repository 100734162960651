export default class Filters {
    static ucwords(str: string) {
        const words = str.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(" ");
    }

    static currency(value: string | number) {
        if (typeof value !== "number" && isNaN(parseFloat(value))) {
            return value;
        }

        value = parseFloat(value.toString());

        const formatter = new Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
        });

        return formatter.format(value);
    }
}
