<template>
    <div v-if="store.invoices?.length > 0">
        <h5>All Invoices</h5>
        <table
            class="table table-sm table-striped"
        >
            <thead>
            <tr>
                <th>Status</th>
                <th>Invoice</th>
                <th>Amount</th>
                <th>Payer</th>
                <th>Description</th>
                <th class="text-end">Created</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="invoice in store.invoices">
                <template v-for="item in invoice.items">
                    <tr>
                        <td>{{ invoice.status }}</td>
                        <td>{{ invoice.uuid }}</td>
                        <td>{{ currencyFilter(invoice.total) }}</td>
                        <td>
                            <template v-if="invoice.client">
                                {{ invoice.client.name }}
                            </template>
                            <template v-else-if="invoice.funding_type">
                                {{ invoice.funding_type.name }}
                            </template>
                        </td>
                        <td>{{ invoice.description }}</td>
                        <td class="text-end">{{ formatTimestamp(invoice.created_at) }}</td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import {currencyFilter} from "../Utils.js";
import {useVerificationStore} from "@stores/VerificationStore";
import {DateTime as Luxon} from "luxon";
const store = useVerificationStore();

const formatTimestamp = (date) => {
    return Luxon.fromSeconds(date).toFormat('dd/MM/yyyy HH:mm')
}

</script>

<style scoped>

</style>
