<template>
    <div>
        <template v-if="!userModel.usesSocialAuthentication">
            <FormSection title="Account Information" subtitle="Update your basic account settings and information.">
                <UpdatePassword :user="userModel" />
            </FormSection>
        </template>
        <FormSection title="Notification Preferences" subtitle="Update your preferences to recieve notifications.">
            <div class="row">
                <div class="col-12">
                    <h4>Notification Preferences</h4>
                    <NotificationPreferencesForm 
                        :user="userModel"
                        :notifications="notifications"
                        @updated="update"
                    />
                </div>
            </div>
        </FormSection>
    </div>
</template>

<script setup>
    import UpdatePassword from './UpdatePassword.vue'
    import NotificationPreferencesForm from '../Notifications/NotificationPreferencesForm.vue'
    import { ref, onBeforeMount, inject } from 'vue'
    import { FormSection } from '../Forms'
    import { copyValues } from '../Utils'

    const axios = inject('axios')

    const { user, notifications } = defineProps({
        user: {
            required: true,
            type: Object,
        },
        notifications: {
            required: true,
            type: Array,
        }
    })

    let userModel = ref({})

    const userNotifications = ref({})

    onBeforeMount(() => {
        userModel.value = copyValues(user)
    })

    const refreshUser = () => {
        axios.get(this.route('api.users.show', {user: userModel.value.uuid}))
            .then((response) => {
                userModel.value = response.data
            })
    }

    const update = (value) => {
        userModel.value = value
    }
</script>

<style scoped>

</style>
