import axios from "axios";

export interface VehicleGroupInterface {
    code: string;
    name: string;
    available_seat_positions: string[];
    vehicle_types: App.Models.VehicleType[];
}

export default class VehicleGroup {
    static getAll() {
        return axios.get(route("api.vehicle-groups.index"));
    }
}
