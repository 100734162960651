import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";

export default class FundingRule {
    static index() {
        return axios.get<LaravelResourceResponse<App.Models.FundingRule[]>>(
            route("api.funding-rules.index")
        );
    }
}
