<template>
    <EditableSelect
        @updated="update"
        :value="client.language"
        :options="languages"
        :loading="updating"
        :disabled="!editable"
    >
        {{ client.language_description }}
    </EditableSelect>
</template>

<script>
export default {
    name: "EditClientLanguage"
}
</script>
<script setup>
import axios from "axios";
import {ref, onBeforeMount, computed} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const languages = ref([])
const getLanguages = function () {
    axios
        .get(route('api.languages'))
        .then((response) => {
            languages.value = response.data
        })
}

const errors = ref({})
const updating = ref(false)
const update = function (newLanguage) {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            language: newLanguage
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

onBeforeMount(() => {
    getLanguages()
})

</script>
