<template>
    <div class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="options"
            :value="journey.driver?.uuid"
            @updated="updateDriver"
        >
            {{ journey.driver.name }}
        </EditableSelect>
    </div>
</template>

<script>
export default {
    name: "EditDriver"
}
</script>
<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {computed, ref, inject} from "vue";
import axios from "axios";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const emit = defineEmits(['updated'])

const props = defineProps({
    journey: {
        type: Object,
        required: true,
    },
    drivers: {
        type: Array,
        required: false,
        default: null,
    },
})

const options = computed(() => {
    let optionsObject = {}
    props.drivers.forEach(driver => {
        optionsObject[driver.uuid] = driver.name
    })
    return optionsObject
})

const updating = ref(false)
const updateDriver = function (newDriverUuid) {
    updating.value = true
    axios
        .put(route('api.journeys.update', {journey: props.journey.uuid}), {
            driver: newDriverUuid,
        })
        .then((response) => {
            verificationStore.updateJourney(response.data)
            toast.success('Driver updated for vehicle run.')
        })
        .catch((error) => {
            toast.error('There was an error updating the driver')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
