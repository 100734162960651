<template>
    <EditableSelect
        @updated="update"
        :value="client.default_priority"
        :options="priorities"
        :loading="updating"
        :disabled="!editable"
    >
        {{ client.default_priority }}
    </EditableSelect>
</template>

<script setup>
import axios from "axios";
import {ref, onBeforeMount, computed} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    },
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const priorities = ref(window.enums.bookingPriorities)

const errors = ref({})
const updating = ref(false)
const update = function (newDefaultPriority) {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            default_priority: newDefaultPriority
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
