<template>
    <div>
        <h4 class="mt-0 d-flex align-items-center">
            <template v-if="confirmationRequired">Confirm Pickup Time</template>
            <template v-else><SvgIcon type="mdi" class="w-8 h-8 me-2 text-success" :path="mdiCheckCircle" />We're on our way!</template>
        </h4>
        <p class="text-muted mb-4">
            <template v-if="confirmationRequired">Please confirm the below pickup time:</template>
            <template v-else>Your driver is estimated to arrive at:</template>
        </p>
        <div class="fw-bold h2">
            {{ DateTime.fromISO(pickupTime).toFormat('H:mm a') }}
        </div>
        <div>{{ DateTime.fromISO(pickupTime).toFormat('ZZZZZ') }}</div>
        <div class="mt-3 fw-bold">Pickup from:</div>
        <div>
            {{ booking.origin.fullAddress }}
        </div>
        <div class="my-3">
            <GoogleMapWithPin
                :locations="[
                    {
                        lat: booking.origin.latitude,
                        lng: booking.origin.longitude,
                    }
                ]"
            />
        </div>
        <template v-if="confirmationRequired">
            <transition name="basic-fade" mode="out-in">
                <div class="d-flex align-items-center mb-4" v-if="!showSelectTimeForm">
                    <Button :loading="confirmingTime" @click="confirmTime">Confirm {{ DateTime.fromISO(booking.planned_origin_time ?? booking.estimated_origin_time).toFormat('H:mm a') }}</Button>
                    <div class="ms-2">or <a href="javascript: void(0)" :disabled="confirmingTime" @click="selectDifferentTime">choose a different time</a></div>
                </div>
                <div v-else>
                    <div>
                        <div class="row">
                            <div class="col-md-8">
                                <FormTimeInput
                                    v-model:input="selectedTime"
                                    label="Select an alternate pickup time"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <Button color="light" class="me-2" @click="showSelectTimeForm = false" :disabled="updatingTime">
                            <SvgIcon type="mdi" class="w-4 h-4" :path="mdiChevronLeft" />
                            Back
                        </Button>
                        <Button @click="updateTime" :loading="updatingTime">Update Time</Button>
                    </div>
                </div>
            </transition>
        </template>

        <div class="mt-3 fw-bold">Transport provided by:</div>
        <div>
            {{ company.name }}
        </div>
        <p class="mt-3">
            If you need to make any additional changes to your booking,
            including cancelling your booking,
            please call {{ company.name }} directly
            <template v-if="supportPhoneNumber"> on: <a :href="'tel:'+supportPhoneNumber">{{ $filters.phone(supportPhoneNumber) }}</a></template>.
        </p>
    </div>
</template>

<script>
export default {
    name: "ConfirmBookingTimings"
}
</script>
<script setup>
import {DateTime} from 'luxon'
import GoogleMapWithPin from "../GoogleMapWithPin.vue";
import Button from "../Button.vue";
import {computed, inject, onMounted, ref} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiChevronLeft, mdiCheckCircle} from "@mdi/js";
import {FormTimeInput} from "../Forms";

const axios = inject('axios')

const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    token: {
        type: String,
        required: true
    },
})

const confirmationRequired = ref(props.booking.origin_time_requires_confirmation)
const pickupTime = ref(props.booking.planned_origin_time)

const company = ref(window.tenant)

const supportPhoneNumber = ref(null)
const getSupportPhoneNumber = () => {
    axios
        .get(route('api.organisation-settings.show', {organisation_setting: 'support_phone_number'}))
        .then(response => {
            supportPhoneNumber.value = response.data.data.value
        })
}

const confirmingTime = ref(false)
const confirmTime = () => {
    confirmingTime.value = true
    axios
        .post(route('api.bookings.confirm-timings', {booking: props.booking.uuid, token: props.token}))
        .then((response) => {
            confirmationRequired.value = false
        })
        .finally(() => {
            confirmingTime.value = false
        })
}

const updatingTime = ref(false)
const updateTime = () => {
    updatingTime.value = true

    const date = DateTime.fromISO(pickupTime.value);
    const time = DateTime.fromISO(selectedTime.value.value);

    const dateTime = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: time.hour,
        minute: time.minute,
    });

    axios
        .post(
            route('api.bookings.confirm-timings', {booking: props.booking.uuid}),
            {token: props.token, time: dateTime.toSeconds()}
        )
        .then((response) => {
            confirmationRequired.value = false
            pickupTime.value = dateTime.toISO()
            showSelectTimeForm.value = false
        })
        .catch((error) => {
            selectedTime.value.errors = error.response.data.errors.time
        })
        .finally(() => {
            updatingTime.value = false
        })
}

const showSelectTimeForm = ref(false)
const selectedTime = ref({
    value: '',
    errors: [],
    required: true,
})
const selectDifferentTime = () => {
    selectedTime.value.value = DateTime.fromISO(props.booking.planned_origin_time ?? props.booking.estimated_origin_time).toFormat('HH:mm');
    showSelectTimeForm.value = true
}

onMounted(() => {
    getSupportPhoneNumber()
})

</script>

<style scoped>

</style>
