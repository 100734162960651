<template>
    <div
        v-for="item in (displayableFare?.items ?? []).filter(
            (i) => i.credit_type !== 'percentage'
        )"
        class="mb-2 pb-1 border-bottom"
    >
        <div class="d-flex flex-row justify-content-between text-wrap">
            <strong>{{ item.description }}</strong>
            ${{ item.amount }}
        </div>
    </div>

    <div
        v-if="typeof displayableFare?.subTotal !== 'undefined'"
        class="d-flex flex-row justify-content-between mt-2"
    >
        <strong>Subtotal</strong>
        ${{ displayableFare.subTotal }}
    </div>

    <div
        v-if="typeof displayableFare?.gst !== 'undefined'"
        class="d-flex flex-row justify-content-between"
    >
        <strong>GST</strong>
        ${{ displayableFare.gst }}
    </div>
    <div
        v-if="typeof displayableFare?.total !== 'undefined'"
        class="d-flex flex-row justify-content-between"
    >
        <strong>Total</strong>
        ${{ displayableFare.total }}
    </div>
    <div
        v-if="typeof displayableFare?.credit !== 'undefined'"
        class="d-flex flex-row justify-content-between"
    >
        <strong>Account credit to be applied</strong>
        (-${{ displayableFare.credit }})
    </div>
</template>

<script setup lang="ts">
import { toRefs, computed } from "vue";
import { BookingCalculatedFareInterface } from "@classes/Fare";

const props = withDefaults(
    defineProps<{
        fare?: BookingCalculatedFareInterface | undefined;
        manualFeeAmount?: number;
        manualFee?: boolean;
        includeGST?: boolean;
    }>(),
    {
        includeGST: false,
        manualFee: false,
        manualFeeAmount: 0,
    }
);

const { fare, manualFeeAmount, includeGST, manualFee } = toRefs(props);

const displayableFare = computed<BookingCalculatedFareInterface | null>(() => {
    let result: BookingCalculatedFareInterface | null = null;

    let manualFeeAmountValue = +(manualFeeAmount?.value ?? 0);

    if (manualFee.value && manualFeeAmountValue > 0) {
        if (includeGST.value) {
            let gst = +(manualFeeAmountValue / 10).toFixed(2);

            result = {
                subTotal: manualFeeAmountValue,
                gst,
                total: +(manualFeeAmountValue + gst).toFixed(2),
            };
        } else {
            result = {
                total: manualFeeAmountValue,
            };
        }
    } else {
        result = fare?.value ?? null;
        // if (includeGST.value) {
        //     result = fare.value ?? null;
        // } else {
        //     result = {
        //         items: fare.value?.items,
        //         total: fare.value?.subTotal,
        //         credit: fare.value?.credit,
        //     };
        // }
    }

    return result;
});
</script>
