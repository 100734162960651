<template>
    <span v-if="booking.cancellation_reason">({{ booking.cancellation_reason.name }})</span>
    <span v-else-if="selectedForCancellation">[Save changes to select reason]</span>
    <a v-else-if="!selectedForCancellation" @click.prevent="setCancellationReason" href="javascript: void(0)">[Choose cancellation reason]</a>
</template>

<script setup>
import { inject, computed, watch, onMounted } from 'vue'

const axios = inject('axios')

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    selected: {
        type: Array,
        required: true,
    },
})

const cancelBooking = inject('cancelBooking')

watch(cancelBooking.getCancelledBooking, (newValue, oldValue) => {
    if (newValue.id === props.booking.id) {
        axios
            .get(route('api.bookings.show', { booking: newValue.uuid }))
            .then(response => {
                props.booking.cancellation_reason = response.data.cancellation_reason
            })
    }
}, { deep: true })

const setCancellationReason = () => {
    cancelBooking.submitFor(props.booking, false);
}

const selectedForCancellation = computed(() => {
    return props.selected.includes(props.booking);
})
</script>