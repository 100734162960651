import $ from "jquery";
import Alpine from "alpinejs";
import moment from "moment-timezone";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import VueApexCharts from "vue3-apexcharts";
import SvgIcon from "@jamescoyle/vue-icon";
import { IMaskDirective } from "vue-imask";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import VueAxios from "vue-axios";
import UUID from "vue-uuid";
import ToastPlugin from "@components/toast";
import TooltipPlugin from "@components/Tooltip";
import Alerts from "@components/Alerts.vue";
import DarkModeToggle from "@components/DarkModeToggle.vue";
import VehiclePositions from "@components/VehiclePositions.vue";
import JourneyTreeView from "@components/JourneyTree/JourneyTreeView.vue";
import BookingForm from "@components/BookingForm/BookingForm.vue";
import DispatchFeed from "@components/DispatchFeed/DispatchFeed.vue";
import BookingAllocationForm from "@components/Allocations/BookingAllocationForm.vue";
import LoginForm from "@components/Auth/LoginForm.vue";
import UserSettings from "@components/UserSettings/UserSettings.vue";
import ListFundingTypes from "@components/FundingTypes/ListFundingTypes.vue";
import ShowFundingType from "@components/FundingTypes/ShowFundingType.vue";
import ClientProfile from "@components/Clients/ClientProfile.vue";
import ClientList from "@components/Clients/ClientList.vue";
import CreateClient from "@components/Clients/CreateClient.vue";
import VehicleList from "@components/Vehicles/VehicleList.vue";
import VehicleCreate from "@components/Vehicles/VehicleCreate.vue";
import VehicleEdit from "@components/Vehicles/VehicleEdit.vue";
import VehicleProfile from "@components/Vehicles/VehicleProfile.vue";
import DriverCreate from "@components/Drivers/DriverCreate.vue";
import DriverList from "@components/Drivers/DriverList.vue";
import ActiveNowDriverList from "@components/Drivers/ActiveNowDriverList.vue";
import DriverProfile from "@components/Drivers/DriverProfile.vue";
import UserAvatar from "@components/Users/UserAvatar.vue";
import BookingsList from "@components/Bookings/BookingsList.vue";
import ChatBox from "@components/ChatBox/ChatBox.vue";
import DriverChat from "@components/ChatBox/DriverChat.vue";
import DriverAppSettings from "@components/Settings/DriverAppSettings.vue";
import GeneralSettings from "@components/Settings/GeneralSettings.vue";
import BookingSettings from "@components/Settings/BookingSettings.vue";
import UnallocatedBookings from "@components/Dispatch/UnallocatedBookings.vue";
import TwoFactorSetup from "@components/Auth/TwoFactorSetup.vue";
import UsersList from "@components/Users/UsersList.vue";
import UserDetails from "@components/Users/UserDetails.vue";
import AcceptInvitation from "@components/Auth/AcceptInvitation.vue";
import BookingDetails from "@components/Bookings/BookingDetails.vue";
import BookingDetailsModalPlugin from "@components/Bookings/BookingDetailsModal";
import ActivityLogsModalPlugin from "@components/ActivityLogsModalPlugin";
import CopyJourneyPlugin from "@components/CopyJourneyPlugin";
import DriverPositionUpdateModalPlugin from "@components/DriverPositionUpdateModalPlugin";
import RegionFilter from "@components/RegionFilter.vue";
import AlertModal from "@components/AlertModal.vue";
import XeroSettings from "@components/XeroSettings.vue";
import Verifications from "@components/Verifications/Verifications.vue";
import { vClickOutside } from "maz-ui";
import DriverEdit from "@components/Drivers/DriverEdit.vue";
import LocationsList from "@components/Locations/LocationsList.vue";
import ListBookingCancellationReasons from "@components/BookingCancellationReasons/ListBookingCancellationReasons.vue";
import ListBookingServiceTypes from "@components/BookingServiceTypes/ListBookingServiceTypes.vue";
import ListFundingRules from "@components/FundingRules/ListFundingRules.vue";
import CreateOrEditFundingRule from "@components/FundingRules/CreateOrEditFundingRule.vue";
import CancelBookingPlugin from "@components/Bookings/CancelBooking";
import RescheduleBookingPlugin from "@components/Bookings/RescheduleBooking";
import TfnswServiceDataExport from "@components/Reports/Tfnsw/TfnswServiceDataExport.vue";
import DataExchangeDashboard from "@components/DataExchange/DataExchangeDashboard.vue";
import ListPayers from "@components/Payers/ListPayers.vue";
import ConfirmBookingTimings from "@components/Bookings/ConfirmBookingTimings.vue";
import ListLocationTypes from "@components/LocationTypes/ListLocationTypes.vue";
import NotificationSettingsList from "@components/Notifications/NotificationSettingsList.vue";
import QueryBuilder from "@components/ReportQueryBuilder/QueryBuilder.vue";
import BookingFareAdjustment from "@components/Services/BookingFareAdjustment/BookingFareAdjustment.vue";
import BookingTagIndex from "@components/Bookings/BookingTags/index.vue";
import AdminTenantSwitcher from "@components/Admin/Auth/AdminTenantSwitcher.vue";
import RestrictionsList from "@components/Locations/Restrictions/RestrictionsList.vue";
import RevertBookingRequestList from "@components/Bookings/RevertBookingRequestList.vue";
import AttachmentsList from "@components/Attachments/AttachmentsList.vue";
import TutorialList from "@components/Tutorials/TutorialList.vue";
import ExportList from "@components/ReportQueryBuilder/ExportList.vue";
import DataImportIndex from "@components/Services/DataImport/DataImportIndex.vue";
import EditInvoice from "@components/Invoices/EditInvoice.vue";
import ListInvoices from "@components/Invoices/ListInvoices.vue";
import ListTransactions from "@components/Transactions/ListTransactions.vue";
import VehicleConfigurationDesignPage from "@components/Vehicles/Configuration/Design/VehicleConfigurationDesignPage.vue";

declare global {
    interface Window {
        [key: string]: any;
    }
}

window.$ = window.jQuery = $;

window.bootstrap = bootstrap;

window.moment = moment;

window.Alpine = Alpine;
Alpine.start();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp({});
window.app = app;

//@ts-ignore
if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
    Sentry.init({
        //@ts-ignore
        app,
        //@ts-ignore
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            new BrowserTracing({
                //@ts-ignore
                tracePropagationTargets: [
                    "localhost",
                    import.meta.env.VITE_HOST,
                    /^\//,
                ],
            }),
        ],
        //@ts-ignore
        debug: import.meta.env.VITE_APP_DEBUG,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        //@ts-ignore
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        tracingOptions: {
            trackComponents: true,
        },
        //@ts-ignore
        logErrors: import.meta.env.VITE_APP_DEBUG,
        attachProps: true,
        attachStacktrace: true,
    });
}

app.use(pinia);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.use(UUID);
app.use(VueApexCharts);
app.use(ToastPlugin);
//@ts-ignore
app.use(TooltipPlugin);
//@ts-ignore
app.use(BookingDetailsModalPlugin);
//@ts-ignore
app.use(ActivityLogsModalPlugin);
//@ts-ignore
app.use(CopyJourneyPlugin);
//@ts-ignore
app.use(DriverPositionUpdateModalPlugin);
//@ts-ignore
app.use(vClickOutside);
app.use(CancelBookingPlugin);
app.use(RescheduleBookingPlugin);

app.directive("popover", {
    mounted(el) {
        new bootstrap.Popover(el);
    },
});

app.directive("dropdown", {
    mounted(el) {
        new bootstrap.Dropdown(el);
    },
});

app.directive("mask", IMaskDirective);

app.directive("click-outside", {
    mounted(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

app.mixin({
    data() {
        return {
            moment: moment,
        };
    },
    computed: {
        timezone() {
            return this.moment.tz.guess() ?? "Australia/Sydney"; // default to Sydney
        },
    },
    methods: {
        //@ts-ignore
        route: (name, params, absolute) => route(name, params, absolute),
        groupBy: function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        global_asset(path) {
            let base_path = window._globalAsset || "";
            return base_path + path;
        },
        asset(path) {
            let base_path = window._asset || "";
            return base_path + path;
        },
        validEmail(string) {
            // noinspection RegExpRedundantEscape
            return String(string)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        inPast(firstDateTime, secondDateTime = null) {
            const first = new Date(firstDateTime);
            const second = secondDateTime
                ? new Date(secondDateTime)
                : new Date();

            return first <= second;
        },
        getPageFromUrl(url) {
            return url !== null
                ? parseInt(url.substring(url.indexOf("page=") + 5))
                : null;
        },
        bookingStatusBadgeClass(status) {
            switch (status) {
                case "verified":
                    return "bg-success";
                case "standby":
                    return "bg-info";
                case "future":
                    return "bg-primary";
                case "cancelled":
                case "unmet":
                    return "bg-danger";
                case "waiting_verification":
                case "transferred":
                case "posted":
                    return "bg-secondary";
            }
        },
    },
});

app.component("alerts", Alerts);
app.component("dark-mode-toggle", DarkModeToggle);
app.component("vehicle-positions", VehiclePositions);
app.component("journey-tree-view", JourneyTreeView);
app.component("booking-form", BookingForm);
app.component("dispatch-feed", DispatchFeed);
app.component("booking-allocation-form", BookingAllocationForm);
app.component("login-form", LoginForm);
app.component("user-settings", UserSettings);
app.component("list-funding-types", ListFundingTypes);
app.component("show-funding-type", ShowFundingType);
app.component("client-profile", ClientProfile);
app.component("client-list", ClientList);
app.component("create-client", CreateClient);
app.component("vehicle-list", VehicleList);
app.component("vehicle-edit", VehicleEdit);
app.component("vehicle-create", VehicleCreate);
app.component("vehicle-profile", VehicleProfile);
app.component("driver-list", DriverList);
app.component("active-now-driver-list", ActiveNowDriverList);
app.component("driver-create", DriverCreate);
app.component("driver-edit", DriverEdit);
app.component("driver-profile", DriverProfile);
app.component("user-avatar", UserAvatar);
app.component("svg-icon", SvgIcon);
app.component("bookings-list", BookingsList);
app.component("chat-box", ChatBox);
app.component("driver-chat", DriverChat);
app.component("driver-app-settings", DriverAppSettings);
app.component("general-settings", GeneralSettings);
app.component("booking-settings", BookingSettings);
app.component("unallocated-bookings", UnallocatedBookings);
app.component("two-factor-setup", TwoFactorSetup);
app.component("users-list", UsersList);
app.component("user-details", UserDetails);
app.component("accept-invitation", AcceptInvitation);
app.component("booking-details", BookingDetails);
app.component("region-filter", RegionFilter);
app.component("alert-modal", AlertModal);
app.component("xero-settings", XeroSettings);
app.component("verifications", Verifications);
app.component("locations-list", LocationsList);
app.component(
    "list-booking-cancellation-reasons",
    ListBookingCancellationReasons
);
app.component("list-booking-service-types", ListBookingServiceTypes);
app.component("list-funding-rules", ListFundingRules);
app.component("create-or-edit-funding-rule", CreateOrEditFundingRule);
app.component("data-exchange-dashboard", DataExchangeDashboard);
app.component("tfnsw-service-data-export", TfnswServiceDataExport);
app.component("list-payers", ListPayers);
app.component("confirm-booking-timings", ConfirmBookingTimings);
app.component("list-location-types", ListLocationTypes);
app.component("notification-settings-list", NotificationSettingsList);
app.component("query-builder", QueryBuilder);
app.component("booking-fare-adjustment", BookingFareAdjustment);
app.component("booking-tags-list", BookingTagIndex);
app.component("admin-tenant-switcher", AdminTenantSwitcher);
app.component("restrictions-list", RestrictionsList);
app.component("revert-booking-request-list", RevertBookingRequestList);
app.component("tutorial-list", TutorialList);
app.component("export-list", ExportList);
app.component("attachment-list", AttachmentsList);
app.component("data-import-index", DataImportIndex);
app.component("edit-invoice", EditInvoice);
app.component("list-invoices", ListInvoices);
app.component("list-transactions", ListTransactions);
app.component("vehicle-configuration-design-page", VehicleConfigurationDesignPage);

app.config.globalProperties.$filters = {
    currency(value) {
        if (typeof value !== "number" && isNaN(parseFloat(value))) {
            return value;
        }

        value = parseFloat(value);

        const formatter = new Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
        });

        return formatter.format(value);
    },
    rego(rego) {
        return (
            rego.slice(0, Math.floor(rego.length / 2)) +
            "-" +
            rego.slice(Math.floor(rego.length / 2), rego.length)
        );
    },
    phone(phoneNumber) {
        if (
            typeof phoneNumber !== "string" ||
            phoneNumber.trim().length === 0 ||
            isPossiblePhoneNumber(phoneNumber, "AU") !== true
        ) {
            return phoneNumber;
        }

        const phone = parsePhoneNumber(phoneNumber, "AU");
        return phone.formatInternational();
    },
    phone_url(phoneNumber) {
        if (
            typeof phoneNumber !== "string" ||
            phoneNumber.trim().length === 0 ||
            isPossiblePhoneNumber(phoneNumber, "AU") !== true
        ) {
            return "";
        }

        const phone = parsePhoneNumber(phoneNumber, "AU");
        return phone.getURI();
    },
    ucwords(str) {
        const words = str.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(" ");
    },
    booking_status(statusCode) {
        switch (statusCode) {
            case "future":
                return "Future";
            case "verified":
                return "Verified";
            case "cancelled":
                return "Cancelled";
            case "standby":
                return "Wait-listed";
            case "waiting_verification":
                return "Awaiting Verification";
            case "unmet":
                return "Unmet";
            case "transferred":
                return "Transferred";
            case "posted":
                return "Posted";
        }
    },
    format_minutes(minutes) {
        return moment.duration(minutes, "minutes").humanize();
    },
    highlight(string: string, query: string): string {
        if (!string || !query) {
            return string;
        }
        query = query.replace(new RegExp("\\\\", "g"), "\\\\");
        const check = new RegExp(query.trim(), "ig");
        let result = string
            .toString()
            .replace(
                check,
                (matchedText) =>
                    '<span class="fw-bolder">' + matchedText + "</span>"
            );
        return result ?? string;
    },
    comma_separated_thousands(number) {
        if (!number) {
            return "0";
        }

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format_datetime(date: any, format: string) {
        if (date == null || date === "") {
            return [];
        }
        const dateTime = new Date(date);
        const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
        let options = { timeZone: window.timezone };
        switch (format) {
            case "short":
                options["dateStyle"] = "short";
                options["timeStyle"] = "short";
                break;
            case "long":
                options["dateStyle"] = "long";
                options["timeStyle"] = "long";
                break;
            case "short time":
                options["timeStyle"] = "short";
                break;
            case "medium time":
                options["timeStyle"] = "medium";
                break;
            case "long time":
                options["timeStyle"] = "long";
                break;
            case "short date":
                options["dateStyle"] = "short";
                break;
            case "medium date":
                options["dateStyle"] = "medium";
                break;
            case "long date":
                options["dateStyle"] = "long";
                break;
            case "full date":
                options["dateStyle"] = "full";
                break;
            default:
                options["dateStyle"] = "medium";
                options["timeStyle"] = "medium";
                break;
        }
        return new Intl.DateTimeFormat(locale, options).format(dateTime);
    },
    format_distance(meters) {
        return (
            (meters / 1000)
                .toFixed(1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " km"
        );
    },
    title_case(string) {
        string = string.replace("_", " ");
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },
};
app.directive("focus", {
    mounted(el, binding) {
        if (binding.value) {
            el.focus();
        }
    },
});
app.mount("#app");
