import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { uuid } from "vue-uuid";
import {
    mdiHumanWheelchair,
    mdiAccountTieHat,
    mdiSeatPassenger,
    mdiLinkVariant,
} from "@mdi/js";

export interface VehicleConfigurationSpaceInterface {
    type: VehicleConfigurationSpaceType[];
    uuid: string;
}

export type VehicleConfigurationRowInterface = {
    spaces: VehicleConfigurationSpaceInterface[];
};

export enum VehicleConfigurationSpaceType {
    DRIVER = "driver",
    SEAT = "seat",
    CARGO = "cargo",
    TRACK = "track",
    EMPTY = "empty",
}

export interface VehicleConfigurationSpaceTypeInterface {
    label: string;
    value: VehicleConfigurationSpaceType;
    icon: any;
    backgroundColor: string | null;
    color: string;
}

export const useVehicleConfigurationDesignStore = defineStore(
    "vehicleConfigurationDesignStore",
    () => {
        const unitSize = ref(100);
        const spaceMargin = ref(10);

        const getUuid = () => {
            return uuid.v4();
        };

        const editable = ref(true);

        const spaceTypes = ref<VehicleConfigurationSpaceTypeInterface[]>([
            {
                label: "Driver",
                value: VehicleConfigurationSpaceType.DRIVER,
                icon: mdiAccountTieHat,
                backgroundColor: "#D6EBFF",
                color: "#007AF8",
            },
            {
                label: "Seat",
                value: VehicleConfigurationSpaceType.SEAT,
                icon: mdiSeatPassenger,
                backgroundColor: "#CBCDE1",
                color: "#393D64",
            },
            {
                label: "Cargo",
                value: VehicleConfigurationSpaceType.CARGO,
                icon: mdiHumanWheelchair,
                backgroundColor: "#C7FAEA",
                color: "#085E43",
            },
            {
                label: "Track",
                value: VehicleConfigurationSpaceType.TRACK,
                icon: mdiLinkVariant,
                backgroundColor: "#FFE8D6",
                color: "#7A3700",
            },
        ]);

        const rows = ref<VehicleConfigurationRowInterface[]>([
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.CARGO],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.TRACK],
                        uuid: getUuid(),
                    },
                ],
            },
        ]);

        const linkedSpaces = ref<{ spaces: string[] }[]>([]);

        const selectedSpacesUuid = ref<string[]>([]);

        const selectedSpaces = computed(() => {
            let spaces: VehicleConfigurationSpaceInterface[] = [];

            for (const row of rows.value) {
                for (const space of row.spaces) {
                    if (selectedSpacesUuid.value.includes(space.uuid)) {
                        spaces.push(space);
                    }
                }
            }

            return spaces;
        });

        const addSpace = (rowIndex: number, afterStopIndex: number = 0) => {
            let newRows = [...rows.value];

            const newSpace = {
                type: [VehicleConfigurationSpaceType.SEAT],

                uuid: getUuid(),
            };

            newRows[rowIndex].spaces.splice(afterStopIndex + 1, 0, newSpace);

            rows.value = newRows;
        };

        const addRow = (afterRowIndex: number = 0) => {
            let newRows = [...rows.value];

            const newSpace = {
                type: [VehicleConfigurationSpaceType.SEAT],

                uuid: getUuid(),
            };

            newRows.splice(afterRowIndex, 0, {
                spaces: [newSpace],
            });

            rows.value = newRows;
        };

        const deleteRow = (rowIndex: number) => {
            let newRows = [...rows.value];

            newRows.splice(rowIndex, 1);

            rows.value = newRows;
        };

        const deleteSpaceByUuid = (spaceUuid: string) => {
            let newRows = [...rows.value];

            for (const row of newRows) {
                for (const space of row.spaces) {
                    if (space.uuid === spaceUuid) {
                        row.spaces.splice(row.spaces.indexOf(space), 1);
                    }
                }
            }

            rows.value = newRows;
        };

        const getRowFromSpaceUuid = (
            spaceUuid: string
        ): VehicleConfigurationRowInterface | undefined => {
            for (const row of rows.value) {
                for (const space of row.spaces) {
                    if (space.uuid === spaceUuid) {
                        return row;
                    }
                }
            }

            return undefined;
        };

        const getRowIndexFromSpaceUuid = (
            spaceUuid: string
        ): number | undefined => {
            let row = getRowFromSpaceUuid(spaceUuid);

            return row ? rows.value?.indexOf(row) : undefined;
        };

        return {
            rows,
            unitSize,
            spaceMargin,
            selectedSpacesUuid,
            selectedSpaces,
            spaceTypes,
            linkedSpaces,
            editable,
            addSpace,
            addRow,
            deleteRow,
            deleteSpaceByUuid,
            getRowFromSpaceUuid,
            getRowIndexFromSpaceUuid,
        };
    }
);
