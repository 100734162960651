<template>
    <div>
        <div class="row">
            <div class="col-lg-8">
                <label :for="componentId + '_fundingType'" class="form-label required-label">Funding Type</label>
                <select
                    :id="componentId + '_fundingType'"
                    v-model="plan.funding_type"
                    class="form-select form-select-sm"
                >
                    <template v-for="type in client.approved_funding_types">
                        <option v-if="type.line_items.length > 0" :value="type.uuid">{{ type.name }}</option>
                    </template>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-6">
                <div class="d-flex align-items-center justify-content-between">
                    <label :for="componentId + '_startDate'" class="form-label">Plan Start Date</label>
                    <small>(optional)</small>
                </div>
                <input
                    :id="componentId + '_startDate'"
                    v-model="plan.start_date"
                    class="form-control form-control-sm"
                    type="date"
                />
            </div>
            <div class="col-lg-6">
                <div class="d-flex align-items-center justify-content-between">
                    <label :for="componentId + '_endDate'" class="form-label">Plan End Date</label>
                    <small>(optional)</small>
                </div>
                <input
                    :id="componentId + '_endDate'"
                    v-model="plan.end_date"
                    class="form-control form-control-sm"
                    type="date"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div v-if="selectedFundingType" class="mt-3">
                    <label class="form-label required-label">Approved Line Items</label>
                    <VueMultiselect
                        v-model="selectedLineItems"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :custom-label="lineItemsLabel"
                        :multiple="true"
                        :option-height="80"
                        :options="selectedFundingType.line_items"
                        :show-labels="false"
                        label="description"
                        placeholder="Search Line Item Description or Number...."
                        track-by="uuid"
                    >
                        <template v-slot:tag="props">
                            <div class="badge bg-light text-dark text-start px-2 py-1 m-1 d-block">
                                <div class="fs-6">{{ props.option.description }}</div>
                                <div class="small fw-normal">{{ props.option.code }}</div>
                            </div>
                        </template>
                        <template v-slot:option="props">
                            <div class="option__desc">
                                <div class="fs-5">{{ props.option.description }}</div>
                                <div class="small">{{ props.option.code }}</div>
                            </div>
                        </template>
                    </VueMultiselect>
                </div>
            </div>
        </div>
        <Accordion v-if="selectedLineItems.length > 0" class="mt-3">
            <template v-for="item in selectedLineItems">
                <AccordionItem>
                    <template #title>
                        {{ item.description + ' - ' + item.code }}
                    </template>
                    <template #content>
                        <div class="mb-2">
                            <label :for="item.uuid + '_fare_collection_method'" class="form-label">
                                Fare collection method
                            </label>
                            <select
                                :id="item.uuid + '_fare_collection_method'"
                                v-model="lineItemConfiguration[item.uuid]['fare_collection_method']"
                                class="form-select form-select-sm"
                            >
                                <template v-for="(label, value) in collectionMethods">
                                    <option :value="value">{{ label }}</option>
                                </template>
                            </select>
                        </div>
                        <div class="form-check mb-2">
                            <input
                                :id="item.uuid + '_apply_to_bookings'"
                                v-model="lineItemConfiguration[item.uuid]['apply_to_bookings']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_apply_to_bookings'" class="form-check-label fw-normal">
                                Automatically apply this line item number to future bookings made by this client
                            </label>
                        </div>
                        <h6>Fee configuration:</h6>
                        <div class="form-check">
                            <input
                                :id="item.uuid + '_charge_distance_based_fees'"
                                v-model="lineItemConfiguration[item.uuid]['charge_distance_based_fees']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_charge_distance_based_fees'" class="form-check-label fw-normal">
                                Distance based costs
                            </label>
                        </div>
                        <div v-if="lineItemConfiguration[item.uuid]['charge_distance_based_fees']" class="mt-2 px-2">
                            <div class="form-check">
                                <input
                                    :id="item.uuid + '_use_estimated_distance'"
                                    v-model="lineItemConfiguration[item.uuid]['use_estimated_distance']"
                                    class="form-check-input"
                                    type="checkbox"
                                >
                                <label :for="item.uuid + '_use_estimated_distance'" class="form-check-label fw-normal">
                                    Use estimated distance
                                </label>
                            </div>
                            <div v-if="!lineItemConfiguration[item.uuid]['use_estimated_distance']" class="form-text">
                                <strong>Note:</strong> Using actual distance will mean the cost of the transport will
                                not
                                be confirmed until after the booking has been completed.
                            </div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center justify-content-between">
                                    <label class="form-label">Distance Based Fees:</label>
                                    <Button :icon="mdiPlusCircle" color="link" size="small"
                                            @click="addDistanceRangeFee(item.uuid)">Add Fee
                                    </Button>
                                </div>
                                <template
                                    v-for="(rangeFee, index) in lineItemConfiguration[item.uuid]['distance_based_fees']"
                                    :key="rangeFee.id">
                                    <div class="d-flex align-items-center justify-content-between mt-1">
                                        <DistanceRangeFeeForm
                                            v-model="lineItemConfiguration[item.uuid]['distance_based_fees'][index]"
                                        ></DistanceRangeFeeForm>
                                        <div>
                                            <button
                                                v-if="lineItemConfiguration[item.uuid]['distance_based_fees'].length > 1"
                                                aria-label="Close"
                                                class="btn-close"
                                                type="button"
                                                @click="removeRangeFee(item.uuid, rangeFee.id)"
                                            ></button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="form-check mt-2">
                            <input
                                :id="item.uuid + '_charge_a_fixed_fee'"
                                v-model="lineItemConfiguration[item.uuid]['charge_a_fixed_fee']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_charge_a_fixed_fee'" class="form-check-label fw-normal">
                                Apply a fixed fee per booking
                            </label>
                        </div>
                        <div v-if="lineItemConfiguration[item.uuid]['charge_a_fixed_fee']" class="mt-2">
                            <label :for="item.uuid + '_fixed_fee_amount'" class="visually-hidden">
                                Fixed Fee Amount
                            </label>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text">AUD $</span>
                                <input
                                    :id="item.uuid + '_fixed_fee_amount'"
                                    v-model="lineItemConfiguration[item.uuid]['fixed_fee_amount']"
                                    class="form-control"
                                    max="99999.99"
                                    min="0.00"
                                    placeholder="0.00"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                        </div>
                        <div class="form-check mt-2">
                            <input
                                :id="item.uuid + '_charge_a_booking_fee'"
                                v-model="lineItemConfiguration[item.uuid]['charge_a_booking_fee']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_charge_a_booking_fee'" class="form-check-label fw-normal">
                                Apply an additional booking fee
                            </label>
                        </div>
                        <div v-if="lineItemConfiguration[item.uuid]['charge_a_booking_fee']" class="mt-2">
                            <label :for="item.uuid + '_booking_fee_amount'" class="visually-hidden">
                                Fixed Fee Amount
                            </label>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text">AUD $</span>
                                <input
                                    :id="item.uuid + '_booking_fee_amount'"
                                    v-model="lineItemConfiguration[item.uuid]['booking_fee_amount']"
                                    class="form-control"
                                    max="99999.99"
                                    min="0.00"
                                    placeholder="0.00"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                        </div>
                        <div class="form-check mt-2">
                            <input
                                :id="item.uuid + '_charge_an_additional_fee'"
                                v-model="lineItemConfiguration[item.uuid]['charge_an_additional_fee']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_charge_an_additional_fee'" class="form-check-label fw-normal">
                                Apply an additional set fee
                            </label>
                        </div>
                        <div v-if="lineItemConfiguration[item.uuid]['charge_an_additional_fee']" class="mt-2">
                            <label :for="item.uuid + '_additional_fee_amount'" class="visually-hidden">
                                Fixed Fee Amount
                            </label>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text">AUD $</span>
                                <input
                                    :id="item.uuid + '_additional_fee_amount'"
                                    v-model="lineItemConfiguration[item.uuid]['additional_fee_amount']"
                                    class="form-control"
                                    max="99999.99"
                                    min="0.00"
                                    placeholder="0.00"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                        </div>
                        <div class="form-check mt-2">
                            <input
                                :id="item.uuid + '_set_a_maximum_spend'"
                                v-model="lineItemConfiguration[item.uuid]['set_a_maximum_spend']"
                                class="form-check-input"
                                type="checkbox"
                            >
                            <label :for="item.uuid + '_set_a_maximum_spend'" class="form-check-label fw-normal">
                                Set a maximum spend
                            </label>
                        </div>
                        <div v-if="lineItemConfiguration[item.uuid]['set_a_maximum_spend']" class="mt-2">
                            <label :for="item.uuid + '_maximum_spend_amount'" class="visually-hidden">
                                Maximum Spend Amount
                            </label>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text">AUD $</span>
                                <input
                                    :id="item.uuid + '_maximum_spend_amount'"
                                    v-model="lineItemConfiguration[item.uuid]['maximum_spend_amount']"
                                    class="form-control"
                                    max="99999.99"
                                    min="0.00"
                                    placeholder="0.00"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                        </div>
                    </template>
                </AccordionItem>
            </template>
        </Accordion>
        <div class="d-flex align-items-center justify-content-end mt-3">
            <Button :class-array="['me-1']" :disabled="loading" color="light" @click="cancelChanges">Cancel</Button>
            <Button :loading="loading" @click="saveData">Save</Button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FundingPlanForm"
}
</script>
<script setup>
import {uuid} from "vue-uuid"
import {ref, computed, onBeforeMount, watch, inject} from "vue";
import axios from "axios";
import Button from "../Button.vue";
import VueMultiselect from "vue-multiselect";
import Accordion from "../Accordion/Accordion.vue";
import AccordionItem from "../Accordion/AccordionItem.vue";
import DistanceRangeFeeForm from "./DistanceRangeFeeForm.vue";
import {mdiPlusCircle} from "@mdi/js";
import {DateTime} from 'luxon'

const componentId = ref(uuid.v4())
const props = defineProps({
    details: {
        type: Object,
        required: false,
    },
    client: Object,
})

const emit = defineEmits(['cancel', 'saved'])

const plan = ref({})
const selectedLineItems = ref([])
const lineItemConfiguration = ref({})
const lineItemsLabel = ({code, description}) => `${code} - ${description}`

const addDistanceRangeFee = function (lineItem) {
    lineItemConfiguration.value[lineItem].distance_based_fees.push({
        id: uuid.v4(),
        min: lineItemConfiguration.value[lineItem].distance_based_fees[lineItemConfiguration.value[lineItem].distance_based_fees.length - 1].max,
        max: null,
        amount: lineItemConfiguration.value[lineItem].distance_based_fees[lineItemConfiguration.value[lineItem].distance_based_fees.length - 1].amount,
        feeRounding: lineItemConfiguration.value[lineItem].distance_based_fees[lineItemConfiguration.value[lineItem].distance_based_fees.length - 1].feeRounding,
    })
}

const removeRangeFee = function (lineItem, feeId) {
    lineItemConfiguration.value[lineItem].distance_based_fees = lineItemConfiguration.value[lineItem].distance_based_fees.filter(fee => fee.id !== feeId)
}

watch(selectedLineItems, function (newValue) {
    Object.keys(lineItemConfiguration.value).forEach((configKey) => {
        if (Object.values(newValue).find((lineItem) => lineItem.uuid === configKey)) {
            return
        }

        delete lineItemConfiguration.value[configKey]
    })

    Object.values(newValue).forEach((lineItem) => {
        if (Object.keys(lineItemConfiguration.value).includes(lineItem.uuid)) {
            return
        }

        lineItemConfiguration.value[lineItem.uuid] = {
            fare_collection_method: 'account',
            apply_to_bookings: true,
            charge_distance_based_fees: false,
            use_estimated_distance: true,
            fixed_fee_amount: 0.00,
            charge_a_booking_fee: false,
            booking_fee_amount: 0.00,
            charge_an_additional_fee: false,
            additional_fee_amount: 0.00,
            set_a_maximum_spend: false,
            maximum_spend_amount: 0.00,
            distance_based_fees: [
                {
                    id: uuid.v4(),
                    min: 0,
                    max: null,
                    amount: 0.00,
                    feeRounding: false,
                }
            ],
        }
    })
})

const selectedFundingType = computed(() => {
  return props.client.approved_funding_types.find(type => type.uuid === plan.value.funding_type)
})

import {toTimeStamp} from "../Utils.js";

const toast = inject('toast')
const loading = ref(false)
const saveData = function () {
    loading.value = true
    let data = {
        funding_type: plan.value.funding_type,
        start_date: toTimeStamp(plan.value.start_date),
        end_date: toTimeStamp(plan.value.end_date),
        line_items: lineItemConfiguration.value,
    };

    let baseRequest = props.details
        ? axios.put(route('api.clients.funding_plans.update', {
            client: props.client.uuid,
            funding_plan: props.details.uuid
        }), data)
        : axios.post(route('api.clients.funding_plans.store', {client: props.client.uuid}), data)

    baseRequest
        .then((response) => {
            toast.success('Funding plan successfully created for ' + (props.client.preferred_name ?? props.client.name))
            emit('saved', response.data)
        })
        .finally(() => {
            loading.value = false
        })
}

const cancelChanges = function () {
    initialiseData()
    emit('cancel')
}

const initialiseData = function () {
    if (props.details) {

        plan.value = {
            funding_type: props.details.funding_type.uuid,
            start_date: DateTime.fromISO(props.details.start_date).toISODate(),
            end_date: DateTime.fromISO(props.details.end_date).toISODate(),
            line_items: props.details.line_items,
        }
        props.details.line_items.forEach((lineItem) => {
            lineItemConfiguration.value[lineItem.uuid] = lineItem.configuration
        })
    } else {
        plan.value = {
            funding_type: null,
            start_date: null,
            end_date: null,
            line_items: [],
        }
    }
    selectedLineItems.value = plan.value.line_items
}

const collectionMethods = ref([])
const getCollectionMethods = function () {
    axios
        .get(route('api.enum.fare-collection-methods'))
        .then(response => collectionMethods.value = response.data)
}

onBeforeMount(() => {
    initialiseData()
    getCollectionMethods()
})

</script>

<style scoped>

</style>
