<template>
    <div class="row">
        <div class="col-6">
            <h5 class="text-uppercase">Bookings {{ timeFrame }}</h5>
            View, add and manage bookings related to the client.
        </div>
        <div class="col-6"></div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <label class="form-label text-nowrap">
                Display
                <select v-model="modelValue.amount"
                        @change="$emit('update:modelValue', modelValue)"
                        class="form-select form-select-sm d-inline mx-1">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                </select>
                bookings
            </label>
        </div>
        <div class="col-6 text-md-end">
            <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                <button class="btn btn-light btn-sm dropdown-toggle" type="button" id="dropDownFilterClients"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <SvgIcon type="mdi" :path="filterIcon" style="max-width: 16px"></SvgIcon>
                    Filter
                </button>
                <div class="dropdown-menu px-4 py-3" style="min-width: 500px;"
                     aria-describedby="dropDownFilterBookings">
                    <div class="row mb-2">
                        <div class="col">
                            <label class="form-label text-sm" for="view_select_filter">View:</label>
                            <VueMultiselect
                                v-model="modelValue.view"
                                :customLabel="upperCase"
                                :options="availableViews"
                                placeholder="Select View"
                                @select="selectViewTimeFrame"
                            ></VueMultiselect>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <label for="date_from"> Date range from:</label>
                            <input type="date" v-model="modelValue.date_from" class="form-control" @change="$emit('update:modelValue', modelValue)"/>
                        </div>
                        <div class="col-6">
                            <label for="date_from">Until:</label>
                            <input type="date" v-model="modelValue.date_to" class="form-control" @change="$emit('update:modelValue', modelValue)"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label class="form-label text-sm" for="view_select_filter">Status:</label>
                            <VueMultiselect
                                v-model="modelValue.status_filter"
                                :customLabel="upperCase"
                                :options="availableStatuses"
                                :multiple="true"
                                placeholder="Select View"
                                @select="$emit('update:modelValue', modelValue)"
                            ></VueMultiselect>
                        </div>
                    </div>
                </div>
            </div>
            <label class="form-label text-nowrap">
                <div class="input-group input-group-sm">
                    <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search bookings ..."
                        v-model="modelValue.query"
                        @keyup.enter="$emit('update:modelValue', modelValue)"
                    />
                    <button class="btn btn-outline-secondary" type="button"
                            @click="$emit('update:modelValue', modelValue)">
                        <SvgIcon type="mdi" :path="searchIcon" style="width: 1.3em"></SvgIcon>
                    </button>
                </div>
            </label>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@jamescoyle/vue-icon";
import VueMultiselect from 'vue-multiselect';
import {computed, ref} from "vue";
import {mdiFilter, mdiMagnify} from "@mdi/js";
import {DateTime} from "luxon";

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const filterIcon = ref(mdiFilter);
const searchIcon = ref(mdiMagnify);

const availableStatuses = ref([
    'future',
    'verified',
    'cancelled',
    'standby',
    'waiting_verification',
    'unmet',
    'transferred',
    'posted',
]);

const availableViews = ref([
    'daily',
    'weekly',
    'monthly',
    'quarterly',
    'yearly',
]);

const upperCase = (value: string) => {
    return (value.charAt(0).toUpperCase() + value.slice(1)).replace("_", ' ');
};

const timeFrame = computed(() => {
    let dateFrom = props.modelValue.date_from;
    let dateTo = props.modelValue.date_to;
    if(!dateFrom && dateTo) {
        return `, until ${DateTime.fromISO(dateTo).toFormat('yyyy LLL dd')}`;
    }
    if(dateFrom && !dateTo) {
        return `, from ${DateTime.fromISO(dateFrom).toFormat('yyyy LLL dd')}`;
    }
    if(dateFrom && dateTo) {
        return `, ${DateTime.fromISO(dateFrom).toFormat('yyyy LLL dd')} - ${DateTime.fromISO(dateTo).toFormat('yyyy LLL dd')}`;
    }
    return ``;
})

const selectViewTimeFrame = (value:string) => {
    switch (value) {
        case 'daily':
            props.modelValue.date_from = DateTime.now().startOf('day').toISODate();
            props.modelValue.date_to = DateTime.now().endOf('day').toISODate();
            break;
        case 'weekly':
            props.modelValue.date_from = DateTime.now().startOf('week').toISODate();
            props.modelValue.date_to = DateTime.now().endOf('week').toISODate();
            break;
        case 'monthly':
            props.modelValue.date_from = DateTime.now().startOf('month').toISODate();
            props.modelValue.date_to = DateTime.now().endOf('month').toISODate();
            break;
        case 'quarterly':
            props.modelValue.date_from = DateTime.now().startOf('quarter').toISODate();
            props.modelValue.date_to = DateTime.now().endOf('quarter').toISODate();
            break;
        case 'yearly':
            props.modelValue.date_from = DateTime.now().startOf('year').toISODate();
            props.modelValue.date_to = DateTime.now().endOf('year').toISODate();
            break;
    }
    emit('update:modelValue', props.modelValue);
}

</script>

<style scoped>

</style>
