<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <div class="list-group">
                        <div class="list-group-item list-group-item-light d-flex justify-content-between align-items-center">
                            <strong>Folders</strong>
                            <div class="dropdown">
                                <SvgIcon :path="mdiDotsVertical" class="w-4 h-4 me-1 dropdown-toggle pointer"
                                         type="mdi" data-bs-toggle="dropdown" aria-expanded="false"></SvgIcon>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li v-if="selectedFolder">
                                        <a class="dropdown-item" href="#" @click="confirmDeleteFolder(selectedFolder)">Delete folder '{{ selectedFolder?.name }}'</a>
                                    </li>
                                    <li v-if="selectedFolder">
                                        <a class="dropdown-item" href="#" @click="renameFolder(selectedFolder)">Rename folder '{{ selectedFolder?.name }}'</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#" @click="createFolder">Create new folder</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="button" v-for="folder in folders"
                                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                :class="{active:folder.uuid === selectedFolder?.uuid}"
                                @click="selectFolder(folder)">
                            {{ folder.name }}
                            <span class="badge bg-primary rounded-pill"
                                  :class="{'bg-light text-dark':folder.uuid === selectedFolder?.uuid}">{{
                                    folder.exports_count
                                }}</span>
                        </button>
                    </div>
                </div>
                <div class="col-9">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-2">Name</th>
                            <th class="col-2">Filename</th>
                            <th class="col-1">Status</th>
                            <th class="col-1">Size</th>
                            <th class="col-2">Created</th>
                            <th class="col-1 text-end">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="_export in exports">
                            <td>{{ _export.name }}</td>
                            <td>{{ _export.filename }}</td>
                            <td><span class="badge rounded-pill bg-primary"
                                      :class="getBackgroundColorByStatus(_export)">{{ _export.status }}</span></td>
                            <td>{{ formatFileSize(_export.size) }}</td>
                            <td>{{ _export.created_at }}</td>
                            <td class="text-end">
                                <div class="dropdown">
                                    <SvgIcon :path="mdiDotsVertical" class="w-4 h-4 me-1 dropdown-toggle pointer"
                                         type="mdi" data-bs-toggle="dropdown" aria-expanded="false"></SvgIcon>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a class="dropdown-item" href="#" @click="download(_export)">Download report</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#" @click="renameExport(_export)">Rename report</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#" @click="moveExport(_export)">Move to other folder</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#" @click="refreshExport(_export)">Rerun report</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="mt-4">
                        <Pagination
                            @pageChanged="pageTo"
                            :pagination="paginationMeta"
                            record-name="records"
                        ></Pagination>
                    </div>
                    <div class="mt-4 text-end">
                        <a :href="route('custom-reports.create', null, null)" class="btn btn-primary">Create report</a>
                    </div>
                </div>
            </div>
        </div>

        <RenameModal id="createFolder" v-model="folderToCreate.name" v-if="folderToCreate && showCreateFolderModal"
                     :title="`Create new folder`"
                     @cancelled="onFolderCreateCancelled" @updated="onFolderCreated"></RenameModal>

        <RenameModal id="renameFolder" v-model="folderToRename.name" v-if="folderToRename && showRenameFolderModal"
                     :title="`Rename ${folderToRename.name}`"
                     @cancelled="onFolderRenameCancelled" @updated="onFolderRenamed"></RenameModal>

        <RenameModal id="renameExport" v-model="selectedExport.name" v-if="selectedExport && showRenameExportModal"
                     :title="`Rename ${selectedExport.name}`"
                     @cancelled="onExportUpdateCancelled" @updated="onExportRenamed"></RenameModal>

        <MoveExportModal id="moveExport" v-model="selectedFolder" v-if="showMoveExportModal" :options="folders"
                   @cancelled="onExportUpdateCancelled" @updated="onExportMoved"></MoveExportModal>

        <ConfirmationModal id="confirmDeleteFolderModal"
                           confirmation-title="Are You Sure"
                           confirmation-button-color="danger"
                           @confirmed="onFolderDeleteConfirmed">
            <template v-slot:title>Confirm delete</template>
            <template v-slot:text>Are you sure you want to delete the folder '{{ selectedFolder?.name }}'?</template>
        </ConfirmationModal>

    </div>
</template>

<style scoped>

</style>

<script setup lang="ts">

import {inject, onMounted, onUpdated, ref} from "vue";
import axios from "axios";
import Pagination from "@components/Pagination.vue";
import Button from "@components/Button.vue";
import {mdiDotsVertical} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import {formatFileSize} from "../Utils";
import RenameModal from "@components/ReportQueryBuilder/RenameModal.vue";
import {cloneDeep, isEmpty, isUndefined} from "lodash";
import ExportResource = App.Resource.ExportResource;
import ExportFolderResource = App.Resource.ExportFolderResource;
import ConfirmationModal from "@components/ConfirmationModal.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import MoveExportModal from "@components/ReportQueryBuilder/MoveExportModal.vue";

const toast = inject('toast');

const props = withDefaults(
    defineProps<{
        exportFolders?: ExportFolderResource[];
    }>(),
    {}
);

const folders = ref<ExportFolderResource[]|undefined>(props.exportFolders);
const selectedFolder = ref<ExportFolderResource | null>(null);
const folderToRename = ref<ExportFolderResource | null>(null);
const folderToCreate = ref<ExportFolderResource | null>(null);
const selectedExport = ref<ExportResource | null>(null);
const confirmDeleteFolderModal = ref();

const showRenameExportModal = ref(false);
const showMoveExportModal = ref(false);
const showRenameFolderModal = ref(false);
const showCreateFolderModal = ref(false);

const exports = ref<ExportResource[]>([]);
const paginationMeta = ref({})
const page = ref(1)

onMounted(() => {
    if (isEmpty(folders.value) || isUndefined(folders.value)) {
        return;
    }
    selectFolder(folders.value[0]);
    confirmDeleteFolderModal.value = new bootstrap.Modal(document.getElementById('confirmDeleteFolderModal'));

});

const selectFolder = (folder: ExportFolderResource): void => {
    selectedFolder.value = folder;
    loadExports();
}

const loadExports = (): void => {
    if (selectedFolder.value === null) return;

    axios.get(route('api.folders.exports', {folder: selectedFolder.value.uuid, page: page.value,}))
        .then((response) => {
            exports.value = response.data.data;
            paginationMeta.value = response.data.meta
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
        });
}

const pageTo = (link: { url: string, page: number }) => {
    page.value = link.page;
    loadExports();
}

const download = (_export: ExportResource): void => {
    window.location.href = route('api.exports.download', {export: _export});
}

const renameExport = (_export: ExportResource): void => {
    selectedExport.value = cloneDeep(_export);
    showRenameExportModal.value = true;
}

const onExportRenamed = (_export: ExportResource): void => {

    axios.patch(route('api.exports.update', {export: selectedExport.value,}), selectedExport.value)
        .then((response) => {
            loadExports();
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            onExportUpdateCancelled();
        });
}

const moveExport = (_export: ExportResource): void => {
    selectedExport.value = _export;
    showMoveExportModal.value = true;
}

const onExportMoved = (folder: ExportFolderResource): void => {
    axios.patch(route('api.exports.move', {export: selectedExport.value,}), folder)
        .then((response) => {
            loadExports();
            updateFolderInProps(response.data.data);
            if(selectedFolder.value) {
                updateFolderInProps(
                    {
                        ...selectedFolder.value,
                        ...{exports_count: selectedFolder.value.exports_count ? selectedFolder.value.exports_count - 1 : 0}
                    }
                );
            }
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            onExportUpdateCancelled();
        });
}

const onExportUpdateCancelled = (): void => {
    selectedExport.value = null;
    showRenameExportModal.value = false;
    showMoveExportModal.value = false;
}

const refreshExport = (_export:ExportResource): void => {
    let params = {
        ...{rule_sets_operator: _export.rule_sets_operator},
        ...{model: _export.model},
        ...{rule_sets: _export.rule_sets},
        ...{fields: _export.fields},
    }
    window.location.href = `${route('custom-reports.create')}?data=${JSON.stringify(params)}`;
}

const renameFolder = (folder: ExportFolderResource|null): void => {
    folderToRename.value = cloneDeep(folder);
    showRenameFolderModal.value = true;
}

const onFolderRenamed = (folder: ExportFolderResource): void => {
    axios.patch(route('api.folders.update', {folder: folderToRename.value,}), folderToRename.value)
        .then((response) => {
            updateFolderInProps(response.data.data);
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            folderToRename.value = null;
            showRenameFolderModal.value = false;
        });
}

const onFolderRenameCancelled = (_export: ExportResource): void => {
    selectedFolder.value = null;
    showRenameFolderModal.value = false;
}

const updateFolderInProps = (folder:ExportFolderResource): void => {
    let index = folders.value?.findIndex((f) => f.uuid === folder.uuid);
    if(index !== undefined && (index >= 0)) {
        folders.value?.splice(index, 1, folder);
    }
}

const removeFolderFromProps = (folder:ExportFolderResource): void => {
    let index = folders.value?.findIndex((f) => f.uuid === folder.uuid);
    if(index !== undefined && (index >= 0)) {
        folders.value?.splice(index, 1);
    }
}

const confirmDeleteFolder = (folder?: ExportFolderResource|null): void => {
    confirmDeleteFolderModal.value.show();
}

const onFolderDeleteConfirmed = (folder?: ExportFolderResource|null): void => {
    confirmDeleteFolderModal.value.hide();
    axios.delete(route('api.folders.destroy', {folder: selectedFolder.value,}))
        .then((response) => {
            if(selectedFolder.value) {
                removeFolderFromProps(selectedFolder.value);
            }
        })
        .catch(error => {
            toast.error(error.response.data.errors.exports[0])
        })
        .finally(() => {
            folderToRename.value = null;
            showRenameFolderModal.value = false;
        });
}

const createFolder = (): void => {
    folderToCreate.value = {
        name: "New folder"
    }
    showCreateFolderModal.value = true;
}

const onFolderCreateCancelled = (): void => {
    showCreateFolderModal.value = false;
}

const onFolderCreated = (folder?: ExportFolderResource|null): void => {
    axios.post(route('api.folders.store'), folderToCreate.value)
        .then((response) => {
            let folder = folders?.value?.push(response.data.data);
            selectFolder(response.data.data);
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            folderToCreate.value = null;
            showCreateFolderModal.value = false;
        });
}

const getBackgroundColorByStatus = (_export: ExportResource): string => {
    switch (_export.status) {
        case "pending":
            return "bg-secondary"
        case "queued":
            return "bg-warning"
        case "completed":
            return "bg-success"
        case "failed":
            return "bg-danger"
        default:
            return "bg-secondary"
    }
}

</script>