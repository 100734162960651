<template>
    <div>
        <div class="d-flex justify-content-between mb-2">
            <div class="flex-shrink-1">
                <div class="d-inline-flex align-items-center">
                    <label class="text-nowrap">Qty</label>
                    <select v-model="filters.perPage" class="form-select form-select-sm mx-2">
                        <option :value="10">10</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option v-if="datatable.meta.total > 250" :value="250">250</option>
                        <option v-if="datatable.meta.total > 1000" :value="1000">1000</option>
                        <option v-else :value="datatable.meta.total">All</option>
                    </select>
                </div>
                <div>
                    <slot name="filters"></slot>
                </div>
            </div>
            <div v-if="search" class="flex-grow">
                <label class="form-label">
                    <div class="input-group input-group-sm">
                        <input
                            v-model="filters.query"
                            @blur="searchRecords"
                            @keypress.enter="searchRecords"
                            :disabled="loading"
                            type="search"
                            class="form-control form-control-sm"
                            :placeholder="'Search ' + recordName + '...'"
                        >
                        <button class="btn btn-outline-secondary" :disabled="loading" type="button" @click="searchRecords">
                            <SvgIcon
                                type="mdi"
                                :path="mdiMagnify"
                                style="width: 1.3em"
                            ></SvgIcon>
                        </button>
                    </div>
                </label>
            </div>
        </div>
        <div class="w-100 position-relative">
            <div
                v-if="loading"
                class="d-flex w-100 h-100 justify-content-center align-items-center ps-3 bg-dark position-absolute"
                style="--bs-bg-opacity: .5"
            >
                <Loading color="text-light" />
            </div>
            <table class="table table-striped table-sm table-centered dt-responsive nowrap w-100">
                <thead>
                    <tr>
                        <template v-for="(header, index) in headers" :key="index">
                            <th :class="header.class">
                                <span v-if="header.orderable" @click="orderBy(header.column)" class="pointer">
                                    <template v-if="header.column === filters.order.column">
                                        {{ header.label }}
                                        <SvgIcon v-if="filters.order.direction === 'asc'" class="w-4 h-4" type="mdi" :path="mdiSortAscending" />
                                        <SvgIcon v-if="filters.order.direction === 'desc'" class="w-4 h-4" type="mdi" :path="mdiSortDescending" />
                                    </template>
                                    <template v-else>
                                        {{ header.label }}
                                        <SvgIcon class="w-4 h-4" type="mdi" :path="mdiSort" />
                                    </template>
                                </span>
                                <span v-else>
                                    {{ header.label }}
                                </span>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in datatable.data" :key="index">
                        <td v-for="(item, key) in row" :key="key" :class="item.class">
                            <a
                                v-if="item.type !== undefined && item.type === 'link'"
                                :href="item.href"
                            >
                                {{ item.content }}
                            </a>
                            <span v-else-if="item.raw" v-html="item.content"></span>
                            <span v-else>{{ item.content }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-end align-items-center">
            <div class="text-secondary me-2" v-if="datatable.meta.from && datatable.meta.to && datatable.meta.total">
                Showing {{ datatable.meta.from }}-{{ datatable.meta.to }} of {{ datatable.meta.total }} {{ recordName }}
            </div>
            <div>
                <ul class="pagination pagination-rounded mb-0 justify-content-center">
                    <template v-if="datatable.meta.lastPage > 5">
                        <li :class="['page-item', { 'disabled': filters.page === 1 }]">
                            <span @click.stop="updatePage(1)" class="page-link pointer">
                                <SvgIcon type="mdi" :path="mdiChevronDoubleLeft" />
                            </span>
                        </li>
                    </template>
                    <template v-if="datatable.meta.lastPage > 1">
                        <li :class="['page-item', { 'disabled': filters.page === 1 }]">
                            <span @click.stop="updatePage(filters.page - 1)" class="page-link pointer">
                                <SvgIcon type="mdi" :path="mdiChevronLeft" />
                            </span>
                        </li>
                    </template>
                    <template v-for="(number, index) in pageLinks" :key="index">
                        <li :class="['page-item', { 'active': number === filters.page, 'disabled': isNaN(number) }]">
                            <span v-if="isNaN(number)" class="page-link">
                                <SvgIcon type="mdi" :path="number" />
                            </span>
                            <span v-else @click.stop="updatePage(number)" class="page-link pointer">
                                {{ number }}
                            </span>
                        </li>
                    </template>
                    <template v-if="datatable.meta.lastPage > 1">
                        <li :class="['page-item', { 'disabled': filters.page === datatable.meta.lastPage }]">
                            <span @click.stop="updatePage(filters.page + 1)" class="page-link pointer">
                                <SvgIcon type="mdi" :path="mdiChevronRight" />
                            </span>
                        </li>
                    </template>
                    <template v-if="datatable.meta.lastPage > 5">
                        <li :class="['page-item', { 'disabled': filters.page === datatable.meta.lastPage }]">
                            <span @click.stop="updatePage(datatable.meta.lastPage)" class="page-link pointer">
                                <SvgIcon type="mdi" :path="mdiChevronDoubleRight" />
                            </span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, nextTick } from 'vue'
    import SvgIcon from '@jamescoyle/vue-icon'
    import { onReady, copyValues } from './Utils'
    import { range, debounce } from 'lodash'
    import { Loading } from '.'
    import { mdiMagnify,
        mdiChevronLeft,
        mdiChevronRight,
        mdiChevronDoubleLeft,
        mdiChevronDoubleRight,
        mdiDotsHorizontal,
        mdiSortAscending,
        mdiSortDescending,
        mdiSort,
    } from '@mdi/js'

    const { filters, datatable, headers, loading, recordName, search, defaultSort } = defineProps({
        filters: {
            required: true,
            type: Object,
        },
        datatable: {
           required: true,
           type: Object,
        },
        headers: {
            required: true,
            type: Array,
        },
        loading: {
            require: true,
            type: Boolean,
        },
        recordName: {
            type: String,
            default: 'Results'
        },
        search: {
            type: Boolean,
            default: true
        },
        defaultSort: {
            type: Object,
            default: {
                column: '',
                direction: 'asc',
            }
        }
    })

    const mounted = ref(false)
    const processing = ref(false)

    onReady(() => mounted.value = true)

    const emit = defineEmits(['update:filters'])

    const searchRecords = () => {
        filters.query = query
    }

    const orderBy = (column) => {
        if (column == filters.order.column) {
            if (filters.order.direction === 'asc') {
                filters.order.direction = 'desc'
                return
            }

            let sort = copyValues(defaultSort)
            filters.order.column = sort.column
            filters.order.direction = sort.direction
            return
        }

        filters.order.column = column
        filters.order.direction = 'asc'
    }

    const updatePage = (value) => {
        if (loading.value || processing.value) {
            return
        }

        processing.value = true

        debounce(() => {
            filters.page = value
            nextTick(() => processing.value = false)
        }, 500)()
    }

    const pageLinks = computed(() => {
        let lastPage = datatable.meta.lastPage

        if (lastPage < 6) {
            return range(1, lastPage + 1)
        }

        let currentPage = filters.page

        if (currentPage > 4 && currentPage < lastPage - 3 ) {
            let pages = range(currentPage -2, currentPage + 3)
            pages.unshift(1, mdiDotsHorizontal)
            pages.push(mdiDotsHorizontal, lastPage)
            return pages
        }

        if (currentPage < 5) {
            let pages = range(1, 8)
            pages.push(mdiDotsHorizontal, lastPage)
            return pages
        }

        if (currentPage > lastPage - 4) {
            let pages = range(lastPage - 6, lastPage + 1)
            pages.unshift(1, mdiDotsHorizontal)
            return pages
        }
    })
</script>
