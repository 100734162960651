import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import Payer from "@classes/Payer";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 30;

export const usePayerStore = defineStore(
    "payerStore",
    () => {
        const payers = ref<App.Models.Payer[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.Payer[]> => {
            return new Promise((resolve, reject) => {
                Payer.index()
                    .then((response) => {
                        payers.value = response.data.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(payers.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                payers.value
            );
        };

        return {
            payers,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "payerStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
