<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 :class="headerClass">{{ header }}</h4>
            <Button
                v-if="permissions.includes('create_vehicle')"
                @click="showCreateModal"
                :icon="mdiPlusCircle"
            >Add Configuration</Button>
        </div>

        <div v-if="loading" class="d-flex justify-content-center">
            <Spinner></Spinner>
        </div>
        <p v-else-if="!vehicleConfigurations.length">There are no configurations assigned to this vehicle.</p>
        <table v-else class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Front Seats</th>
                    <th>Back Seats</th>
                    <th>Wheelchairs</th>
                    <th>Stretchers</th>
                    <th>Cargo Space</th>
                    <th>Anchor Points</th>
                    <th v-if="permissions.includes('create_vehicle')">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="config in vehicleConfigurations" :key="config.uuid">
                    <td>{{ config.name }}</td>
                    <td>{{ config.front_seats }}</td>
                    <td>{{ config.back_seats }}</td>
                    <td>{{ config.wheelchairs }}</td>
                    <td>{{ config.stretchers }}</td>
                    <td>{{ config.cargo_space }}</td>
                    <td>{{ config.anchor_points }}</td>
                    <td v-if="permissions.includes('create_vehicle')">
                        <a href="javascript: void(0)" @click.prevent="showEditModal(config)">Edit</a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            id="vehicleConfigModal"
            class="modal fade modal-lg"
            aria-hidden="true"
            aria-labelledby="vehicleConfigModalLabel"
            tabindex="-1"
            data-bs-keyboard="false"
            data-bs-backdrop="static"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="vehicleConfigModalLabel" class="modal-title">Create Vehicle Configuration</h5>
                        <button @click="closeModal" aria-label="Close" class="btn-close" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <VehicleConfigurationEditOrCreateForm v-model:configuration="vehicleConfigForm" />
                    </div>
                    <div class="modal-footer d-flex justify-content-between flex-nowrap">
                        <div div class="flex-grow-1 me-1">
                            <DeleteVehicleConfiguration
                                v-if="vehicleConfigForm.uuid"
                                :config="vehicleConfigForm"
                                :vehicle="vehicle"
                                @deleted="deleteConfiguration"
                            ></DeleteVehicleConfiguration>
                        </div>
                        <div class="flex-shrink-1 d-flex flex-nowrap">
                            <Button
                                @click="closeModal"
                                color="secondary"
                                class="me-1"
                            >Close</Button>
                            <Button
                                @click="saveVehicleConfiguration"
                                :loading="savingVehicleConfig"
                                color="primary"
                            >{{ method === 'update' ? 'Update' : 'Create' }} Configuration</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from 'vue'
import { mdiPlusCircle } from '@mdi/js'
import Button from '../../Button.vue'
import Spinner from '../../Spinner.vue'
import DeleteVehicleConfiguration from './DeleteVehicleConfiguration.vue'
import VehicleConfigurationEditOrCreateForm from './VehicleConfigurationEditOrCreateForm.vue'
import { transformInput, extractInputValues } from '../../Utils'
import {uuid} from "vue-uuid"

const axios = inject('axios')
const toast = inject('toast')

const permissions = window.permissions

const { vehicle, header, headerClass, labelClass, valueClass } = defineProps({
    vehicle: {
        required: true,
        type: Object
    },
    header: {
        type: String,
        default: 'Vehicle Configuration'
    },
    headerClass:{
        type: String,
        default: 'header-title mt-0 mb-3'
    },
    labelClass: {
        type: String,
        default: 'col-sm-7'
    },
    valueClass: {
        type: String,
        default: 'col-sm-5'
    }
})

const method = computed(() => {
    return vehicleConfigForm.value.uuid !== undefined ? 'update' : 'create'
})

/**
 * Datatable
 */
const loading = ref(true)

let vehicleConfigurations = ref([])

const loadConfigurations = () => {
    axios.get(route('api.vehicles.vehicle-configuration.index', { uuid: vehicle.uuid }))
        .then((response) => {
            vehicleConfigurations.value = response.data.data
        }).catch((err) => {
            console.error(err)
            toast.error('Sorry we were not able to fetch the Vehicle Configurations.')
        }).finally(() => {
            loading.value = false
        })
}

/**
 * Config modal
 */

const vehicleConfigForm = ref({})

const showEditModal = function (vehicleConfig) {
    vehicleConfigForm.value = {
        uuid: vehicleConfig.uuid,
        vehicle_uuid: vehicle.uuid,
        name: transformInput({ value: vehicleConfig.name }),
        description: transformInput({ value: vehicleConfig.description, required: false }),
        notes: transformInput({ value: vehicleConfig.notes, required: false }),
        front_seats: transformInput({ value: vehicleConfig.front_seats, required: false }),
        back_seats: transformInput({ value: vehicleConfig.back_seats, required: false }),
        wheelchairs: transformInput({ value: vehicleConfig.wheelchairs, required: false }),
        stretchers: transformInput({ value: vehicleConfig.stretchers, required: false }),
        cargo_space: transformInput({ value: vehicleConfig.cargo_space, required: false }),
        anchor_points: transformInput({ value: vehicleConfig.anchor_points, required: false }),
    }

    vehicleConfigModal.value.show()
}

let savingVehicleConfig = ref(false)

const saveVehicleConfiguration = () => {
    savingVehicleConfig.value = true

    if (vehicleConfigForm.value.uuid) {
        updateVehicleConfiguration()
    } else {
        createVehicleConfiguration()
    }
}

const createVehicleConfiguration = () => {
    axios.post(
        route('api.vehicles.vehicle-configuration.store', {vehicle: vehicle.uuid}),
        extractInputValues(vehicleConfigForm.value))
        .then(response => {
            closeModal()
            vehicleConfigurations.value.push(response.data.data)
            vehicleConfigurations.value.sort((a, b) => a.name.localeCompare(b.name))
            toast.success('Vehicle configuration created.')
        })
        .catch(error => {
            handleError(error)
        })
        .finally(() => {
            savingVehicleConfig.value = false
        })
}

const updateVehicleConfiguration = () => {
    axios.patch(
        route('api.vehicles.vehicle-configuration.update', {
            vehicle: vehicle.uuid,
            vehicle_configuration: vehicleConfigForm.value.uuid
        }),
        extractInputValues(vehicleConfigForm.value))
        .then(response => {
            closeModal()
            const index = vehicleConfigurations.value.findIndex(config => config.uuid === vehicleConfigForm.value.uuid)
            vehicleConfigurations.value.splice(index, 1, response.data.data)
            toast.success('Vehicle configuration updated.')
        })
        .catch(error => {
            handleError(error)
        })
        .finally(() => {
            savingVehicleConfig.value = false
        })
}

const handleError = (error) => {
    if (error.response && error.response.status === 422) {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
            vehicleConfigForm.value[key].errors = value
        }

        toast.error(error.response.data.message)
    } else {
        toast.error('Sorry we encountered a technical error. Our support staff have been notified. Please try again later.')
    }
}

const deleteConfiguration = (vehicleConfig) => {
    const index = vehicleConfigurations.value.findIndex(config => config.uuid === vehicleConfig.uuid)
    vehicleConfigurations.value.splice(index, 1)

    closeModal()
}

const closeModal = () => {
    vehicleConfigModal.value.hide()
}

let vehicleConfigModal = ref(null)

const showCreateModal = () => {
    vehicleConfigForm.value = {
        vehicle_uuid: vehicle.uuid,
        name: transformInput({ value: '' }),
        description: transformInput({ value: '', required: false }),
        notes: transformInput({ value: '', required: false }),
        front_seats: transformInput({ value: '', required: false }),
        back_seats: transformInput({ value: '', required: false }),
        wheelchairs: transformInput({ value: '', required: false }),
        stretchers: transformInput({ value: '', required: false }),
        cargo_space: transformInput({ value: '', required: false }),
        anchor_points: transformInput({ value: '', required: false }),
    }
    vehicleConfigModal.value.show()
}

onMounted(() => {
    loadConfigurations()
    vehicleConfigModal.value = new bootstrap.Modal(document.getElementById('vehicleConfigModal'))
})
</script>