<template>
    <div class="d-inline-block">
        <span
            v-if="!editing && !loading"
            @click="editing = !(loading || disabled)"
            :class="(loading || disabled) ? null : 'click-to-edit'"
        >
            <template v-if="value"><slot>{{ value }}</slot></template>
            <template v-else-if="!(loading || disabled)"><small><em>Click to add</em></small></template>
        </span>
        <div class="d-flex align-items-center" v-if="editing || loading">
            <select
                v-model="fieldValue"
                :disabled="loading || disabled"
                class="form-select form-select-sm"
                v-focus
                @change="update"
            >
                <option v-if="allowEmptyOption" value="">-- Select --</option>
                <template v-for="(label, value) in options">
                    <option :value="value">{{ label }}</option>
                </template>
            </select>
            <button
                v-if="!loading"
                @click="editing = false"
                type="button"
                class="btn-close ms-1"
                aria-label="Close"
            ></button>
            <Spinner class="ms-1" v-if="loading" type="border" size="small" />
        </div>
    </div>
</template>

<script>
export default {
    name: "EditableSelect"
}
</script>
<script setup>
import {ref, onBeforeMount} from 'vue'
import Spinner from "../../Spinner.vue";

const fieldValue = ref(null)
const props = defineProps({
    value: String,
    loading: Boolean,
    options: Object,
    allowEmptyOption: Boolean,
    disabled: Boolean,
})
const emit = defineEmits(['updated'])

let editing = ref(false)
const update = function () {
    editing.value = false
    emit('updated', fieldValue.value)
}

onBeforeMount(() => {
    fieldValue.value = props.value
})

</script>
<style scoped>

</style>
