<template>
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h5>Generate Service Data Reports</h5>
                </div>
                <div class="card-body">
                    <FormRow>
                        <FormCol lg="6">
                            <FormSelect
                                v-model:selection="form.year"
                                :options="years"
                                label="Year"
                                placeholder="Select year"
                            />
                        </FormCol>
                        <FormCol lg="6">
                            <FormSelect
                                v-model:selection="form.month"
                                :options="months"
                                label="Month"
                                :placeholder="form.year.value ? 'Select month' : 'Select year first'"
                            />
                        </FormCol>
                    </FormRow>
                    <FormRow>
                        <FormCol lg="12">
                            <label for="clientSearchInput" class="form-label">
                                Filter by Funding Type
                            </label>
                            <VueMultiselect
                                v-model="fundingTypes"
                                :close-on-select="true"
                                :multiple="true"
                                :options="fundingTypeOptions"
                                label="label"
                                placeholder="Select Funding Types"
                                track-by="value"
                            ></VueMultiselect>
                        </FormCol>
                    </FormRow>
                    <Button
                        :icon="mdiDownload"
                        :loading="preparingDownload"
                        @click="download"
                        :disabled="invalid"
                        color="primary"
                        size="small"
                    >
                        <template v-if="preparingDownload">Preparing file...</template>
                        <template v-else>Download</template>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import Button from '../../Button.vue'
import SvgIcon from "@jamescoyle/vue-icon"
import { mdiDownload } from '@mdi/js'
import { FormSelect } from '../../Forms'
import { FormRow, FormCol } from '../../Forms/Layouts'
import { transformInput } from '../../Utils'
import VueMultiselect from "vue-multiselect";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    reports: {
        type: Object,
        required: true,
    },
    fundingTypeOptions: {
        type: Array,
        required: true,
    }
})

const form = ref({
    month: transformInput(),
    year: transformInput(),
})

const fundingTypes = ref([])

const years = computed(() => {
    return Object.keys(props.reports).map(year => {
        return {
            label: year,
            value: year,
        }
    }).sort((a, b) => b.value - a.value)
})

const months = computed(() => {
    if (!form.value.year.value) {
        return []
    }

    return props.reports[form.value.year.value].map((item) => {
        return {
            value: item.month,
            label: item.month_full,
        }
    })
})

const fileName = computed(() => {
    if (!form.value.year.value || !form.value.month.value) {
        return ''
    }

    return props.reports[form.value.year.value]
        .filter(item => item.month === form.value.month.value)[0].file;
})

const invalid = computed(() => {
    return !form.value.year.value || !form.value.month.value
})

const preparingDownload = ref(false)
const download = function () {
    preparingDownload.value = true
    axios({
        url: route('api.tfnsw.service-data.generate'),
        method: 'POST',
        responseType: 'blob',
        data: {
            year: form.value.year.value,
            month: form.value.month.value,
            funding_types: fundingTypes.value.map(fundingType => fundingType.value),
        }
    })
        .then((response) => {
            toast.success("You will receive an email with a download link once the report is ready.")
        })
        .catch(async error => {
            if (error.response.status === 422) {
                const text = await error.response.data.text();
                let message = JSON.parse(JSON.parse(text).message)
                    .flatMap((row) => { return Object.entries(row).flatMap(([field, content], index) => {return content})})
                    .join("<br />");

                // Not sure how to get the validation errors from blob response.
                toast.warning(message ?? 'Please select a year and month to download')

                if (!form.value.year.value) {
                    form.value.year.errors = ['The year field is required.']
                }
                if (!form.value.month.value) {
                    form.value.month.errors = ['The month field is required.']
                }
            } else {
                toast.error('There was an error downloading the file.')
            }
        })
        .finally(() => {
            preparingDownload.value = false
        })
}

</script>
