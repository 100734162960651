<template>
    <div :class="{ 'card': true }">
        <div
            class="card-body p-2 d-flex flex-row align-items-center"
            :style="{ opacity: disabled ? 0.5 : 1 }"
            @click="toggle"
        >
            <ClientAvatar
                v-if="selectedClient"
                :client="selectedClient"
                class="me-2"
            />
            <strong v-if="label" class="flex-fill">{{ label }}</strong>
            <div>
                <SvgIcon
                    type="mdi"
                    :path="open ? mdiChevronUp : mdiChevronDown"
                    :size="30"
                ></SvgIcon>
            </div>
        </div>
        <div :class="[className]">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { uuid } from "vue-uuid";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import ClientAvatar from "../../Clients/ClientAvatar.vue";

const bookingStore = useBookingStore();

const { selectedClients, selectedClient, draft } = storeToRefs(bookingStore);

const props = withDefaults(
    defineProps<{
        disabled?: boolean;
        label?: string;
        initiallyOpen: boolean;
    }>(),
    {
        disabled: false,
        label: undefined,
        initiallyOpen: true,
    }
);

const className = ref("toggle-" + uuid.v1());
const open = ref<boolean>(props.initiallyOpen);

const slide = () => {
    if (!open.value) {
        $("." + className.value).slideUp("fast");
    } else {
        $("." + className.value).slideDown("fast");
    }
};

const toggle = () => {
    if (!props.disabled) {
        open.value = !open.value;
        slide();
    }
};

onMounted(() => {
    slide();
});
</script>
