<template>
    <div v-if="modelValue.name">
        <div class="mb-3">
            <FormTextInput
                :key="modelValue.name.uuid"
                v-model:input="modelValue.name"
                label="Name"
                placeholder="e.g. Road Accident"
            ></FormTextInput>
        </div>
        <div class="mb-3">
            <FormPercentageInput
                :key="modelValue.fee_percentage.uuid"
                v-model:input="modelValue.fee_percentage"
                integer-only
                label="Fee Percentage"
                optional
                placeholder="e.g. 10"
                step="1"
            ></FormPercentageInput>
        </div>
        <div class="mb-3">
            <FormDollarInput
                :key="modelValue.fee_amount_without_tax.uuid"
                v-model:input="modelValue.fee_amount_without_tax"
                label="Fixed Fee Amount"
                optional
                placeholder="e.g. 5.50"
                step=".01"
            ></FormDollarInput>
        </div>
        <div class="form-check mb-3">
            <input id="gstCheckBox" v-model="modelValue.add_gst.value"
                   class="form-check-input" type="checkbox" value="">
            <label class="form-check-label" for="gstCheckBox">
                Add Goods and Service Tax (GST) to the fee amounts specified above.
            </label>
        </div>
        <p class="form-text">
            {{ additionalFeeStatement }}
        </p>
    </div>
</template>

<script>
export default {
    name: "BookingCancellationReasonEditOrCreateForm"
}
</script>
<script setup>
import {currencyFilter} from "../Utils.js";
import {FormTextInput, FormPercentageInput, FormDollarInput} from "../Forms";
import {computed} from "vue";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
})

const additionalFeeStatement = computed(() => {
    let statement = "When a booking is cancelled with this cancellation reason, the client will ";
    let statementParts = [];

    if (props.modelValue.fee_percentage.value > 0) {
        statementParts.push("charged a fee equal to " + props.modelValue.fee_percentage.value + "% of the originally quoted pre-tax cost of the booking.");
    }

    if (props.modelValue.fee_amount_without_tax.value > 0) {
        statementParts.push("charged a fixed fee of " + currencyFilter(props.modelValue.fee_amount_without_tax.value * 100) + ".")
    }

    if (statementParts.length === 0) {
        statement += "not be charged a fee."
    } else {
        statement += statementParts.join(" In addition to this, the client will be ");

        if (props.modelValue.add_gst.value) {
            statement += " GST will then be added to the total fee amount."
        } else {
            statement += " GST will not be added to the total fee amount."
        }
    }

    return statement
})

</script>

<style scoped>

</style>
