<template>
    <EditableSelect
        @updated="update"
        :value="client.birth_country"
        :options="countries"
        :loading="updating"
        :disabled="!editable"
    >
        {{ client.birth_country_description }}
    </EditableSelect>
</template>

<script>
export default {
    name: "EditClientCountry"
}
</script>
<script setup>
import axios from "axios";
import {ref, onBeforeMount, computed} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const countries = ref([])
const getCountries = function () {
    axios
        .get(route('api.countries'))
        .then((response) => {
            countries.value = response.data
        })
}

const errors = ref({})
const updating = ref(false)
const update = function (newCountry) {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            birth_country: newCountry
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

onBeforeMount(() => {
    getCountries()
})

</script>
