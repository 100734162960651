<template>
    <div>
        <div class="modal fade" id="verification_transaction_modal" tabindex="-1" aria-labelledby="verification_transaction_modal_label" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="verification_transaction_modal_label">Transactions linked to {{ bookingId }}.</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="store.bookingTransactionsLoading" class="d-flex align-items-center justify-content-center pb-4 pt-2">
                            <Spinner class="me-2" /> Loading booking transactions
                        </div>
                        <table
                            v-if="store.transactions.length > 0"
                            :key="store.transactionRefreshKey + '_booking_transactions'"
                            class="table table-sm table-striped"
                        >
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Amount</th>
                                <th class="text-center">Payment Method</th>
                                <th class="text-center">Collection Method</th>
                                <th class="text-center">Receipt No.</th>
                                <th class="text-end">Created</th>
                                <th>
                                    <span class="invisible">Actions</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="transaction in store.transactions">
                                <tr>
                                    <td>{{ transaction.uuid }}</td>
                                    <td><EditTransactionAmount :transaction="transaction" /></td>
                                    <td class="text-center">{{ transaction.payment_method }}</td>
                                    <td class="text-center">{{ transaction.collection_method }}</td>
                                    <td class="text-center"><EditTransactionReceiptNumber :transaction="transaction" /></td>
                                    <td class="text-end">{{ formatDate(transaction.created_at) }}</td>
                                    <td class="text-end" style="width: 200px;">
                                        <div @mouseenter="offeringUnlink = transaction.uuid" @mouseleave="offeringUnlink = null">
                                            <template v-if="offeringUnlink === transaction.uuid">
                                                <a @click="store.unlinkTransaction(transaction.uuid)" href="javascript: void(0)">
                                                    Unlink
                                                    <SvgIcon type="mdi" :path="mdiLinkVariantOff" class="text-primary ms-1" size="18" />
                                                </a>
                                            </template>
                                            <template v-else>
                                                Linked to booking<SvgIcon type="mdi" :path="mdiLinkVariant" class="text-primary ms-1" size="18" />
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                        <p v-if="store.transactions.length === 0">There are no payments linked to this booking.</p>

                        <template v-if="store.clientTransactions.length > 0 || store.clientTransactionsLoading">
                            <h5>Recent unlinked payments for this client</h5>
                            <div v-if="store.clientTransactionsLoading" class="d-flex align-items-center justify-content-center pb-4 pt-2">
                                <Spinner class="me-2" /> Loading other client transactions
                            </div>

                            <table
                                v-if="store.clientTransactions.length > 0"
                                :key="store.transactionRefreshKey + '_booking_transactions'"
                                class="table table-sm table-striped"
                            >
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Amount</th>
                                    <th class="text-center">Payment Method</th>
                                    <th class="text-center">Collection Method</th>
                                    <th class="text-center">Receipt No.</th>
                                    <th class="text-end">Created</th>
                                    <th>
                                        <span class="invisible">Actions</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="transaction in store.clientTransactions">
                                    <tr>
                                        <td>{{ transaction.uuid }}</td>
                                        <td><EditTransactionAmount :transaction="transaction" /></td>
                                        <td class="text-center">{{ transaction.payment_method }}</td>
                                        <td class="text-center">{{ transaction.collection_method }}</td>
                                        <td class="text-center"><EditTransactionReceiptNumber :transaction="transaction" /></td>
                                        <td class="text-end">{{ formatDate(transaction.created_at) }}</td>
                                        <td class="text-end">
                                            <a @click="store.linkTransactionToBooking(transaction.uuid)" href="javascript: void(0)">
                                                Link<SvgIcon type="mdi" :path="mdiLinkVariantPlus" class="ms-1" size="18" />
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>

                        </template>

                        <Button @click="createTransaction">Add Transaction</Button>

                        <Invoices class="mt-4" />

                    </div>
                </div>
            </div>
        </div>
        <CreateTransaction
            v-if="store.transactionForBooking?.client"
            :client="store.transactionForBooking.client"
            :booking="store.transactionForBooking"
            :open="createModalOpen"
            :payment-methods="paymentMethods"
            :collection-methods="collectionMethods"
            @stored="transactionStored"
            @close="closeTransactionCreation"
        />
        <div class="modal fade" id="verification_funding_rules_modal" tabindex="-1" aria-labelledby="verification_funding_rules_modal" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="verification_transaction_modal_label">Funding rules linked to {{ bookingId }}.</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="store.fundingRulesLoading" class="d-flex align-items-center justify-content-center pb-4 pt-2">
                            <Spinner class="me-2" /> Loading funding rules
                        </div>
                        <table
                            v-if="store.fundingRules.length > 0"
                            :key="store.transactionRefreshKey + '_funding_rules'"
                            class="table table-sm table-striped"
                        >
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Applies</th>
                                <th class="text-end">Companion fixed fee</th>
                                <th class="text-end">Companion percentage fee</th>
                                <th class="text-center">Valid from</th>
                                <th class="text-center">Valid until</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="fundingRule in store.fundingRules">
                                <tr>
                                    <td>{{ fundingRule.name }}</td>
                                    <td>{{ formatArray(fundingRule.applies_to) }}</td>
                                    <td class="text-end">{{ currencyFilter(fundingRule.companion_fixed_fee) }}</td>
                                    <td class="text-end">{{ fundingRule.companion_percentage_fee }}%</td>
                                    <td class="text-center">{{ formatDate(fundingRule.valid_from) }}</td>
                                    <td class="text-center">{{ formatDate(fundingRule.valid_until) }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                        <p v-if="store.fundingRules.length === 0">There are no funding rules to this booking.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Transactions"
}
</script>
<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useVerificationStore} from "@stores/VerificationStore";
import {mdiLinkVariant, mdiLinkVariantOff, mdiLinkVariantPlus} from "@mdi/js";
import Spinner from "../Spinner.vue";
import { DateTime as Luxon } from 'luxon'
import {currencyFilter, formatArray} from "../Utils.js";
import SvgIcon from "@jamescoyle/vue-icon";
import Button from "../Button.vue";
import Invoices from "@components/Verifications/Invoices.vue";
import CreateTransaction from "@components/CreateTransaction.vue";
import EditTransactionReceiptNumber from "@components/Verifications/EditableFields/EditTransactionReceiptNumber.vue";
import EditTransactionAmount from "@components/Verifications/EditableFields/EditTransactionAmount.vue";

const store = useVerificationStore();
const offeringUnlink = ref(null);
const createModalOpen = ref(false);
const axios = inject('axios')

const paymentMethods = ref(null);
const getPaymentMethods = function () {
    axios
        .get(route('api.payment-methods.index'))
        .then(response => {
            paymentMethods.value = Object.keys(response.data).map(key => {
                return {
                    value: key,
                    label: response.data[key]
                }
            })
        })
}

const collectionMethods = ref(null);
const getCollectionMethods = function () {
    axios
        .get(route('api.enum.fare-collection-methods'))
        .then(response => {
            collectionMethods.value = Object.keys(response.data).map(key => {
                return {
                    value: key,
                    label: response.data[key]
                }
            })
        })
}

const bookingId = computed(() => {
    if (!store.transactionForBooking) {
        return 'this booking';
    }

    return store.transactionForBooking.uuid
});

const formatDate = (date) => {
    return Luxon.fromSeconds(date).toFormat('dd/MM/yyyy HH:mm')
}

const createTransaction = function () {
    store.transactionModal.hide();
    createModalOpen.value = true;
}

const transactionStored = function (transaction) {
    store.transactions.push(transaction)
    store.transactionForBooking.transactions.push(transaction)
    store.transactionRefreshKey = Math.random()
}

const closeTransactionCreation = function () {
    createModalOpen.value = false;
    store.transactionModal.show();
}

onMounted(() => {
    getPaymentMethods()
    getCollectionMethods()
});

</script>

<style scoped>

</style>
