<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row mb-3 justify-content-between">
                    <div class="d-flex col-sm-12 col-md-6">
                        <label class="form-label text-nowrap">
                            Display
                            <select v-model="paginateParams.perPage" 
                                class="form-select form-select-sm d-inline mx-1"
                            >
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                            </select>
                            records
                        </label>
                    </div>
                    <div class="d-flex col-sm-12 col-md-6 justify-content-end">
                        <Button @click="createRestriction" class="btn btn-primary" :icon="mdiPlusCircle">
                            Create
                        </Button>
                    </div>
                </div>
                <div v-if="loadingRestrictions" class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading Location Restrictions</div>
                </div>
                <template v-if="!loadingRestrictions">
                    <table class="table table-hover table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                            <tr>
                                <th style="width: 30%" >Location</th>
                                <!-- <th style="width: 10%">Type</th> // Add back when additional types are created -->
                                <th style="width: 20%">Details</th>
                                <th style="width: 40%">Comment</th>
                                <th style="width: 10%" class="text-end">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="restrictions?.length > 0">
                                <template v-for="(restriction, index) in restrictions" :key="index">
                                    <tr>
                                        <td>
                                            <strong>{{ restriction.location?.name ?? 'Unnamed Address' }}</strong><br />
                                            <small>{{ restriction.location?.full_address }}</small>
                                        </td>
                                        <!-- <td>Vehicle</td> -->
                                        <td v-if="restriction.vehicle">
                                            <strong>{{ restriction.vehicle?.description }}: {{ restriction.vehicle?.registration_code }}</strong><br />
                                            <small>{{ restriction.vehicle?.make }} - {{ restriction.vehicle?.model }}</small>
                                        </td>
                                        <td>{{ restriction.comment }}</td>
                                        <td class="text-end">
                                            <div class="dropdown">
                                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="mdi mdi-dots-vertical"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <a
                                                        @click.prevent="editRestriction(restriction)"
                                                        class="dropdown-item text-success-emphasis"
                                                        href="javascript:void(0);"
                                                    >
                                                        Edit
                                                    </a>
                                                    <a
                                                        @click.prevent="confirmDeleteRestriction(restriction)"
                                                        class="dropdown-item text-danger"
                                                        href="javascript:void(0);"
                                                    >
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="paginatedMeta && !loadingRestrictions && restrictions?.length > 0"
                        @pageChanged="loadPage"
                        :pagination="paginatedMeta"
                        record-name="records"
                    ></Pagination>
                </template>
                <div
                    v-if="!loadingRestrictions && restrictions?.length === 0"
                    class="d-flex w-100 justify-content-center align-items-center ps-3"
                >
                    <p>No location Restrictions found.</p>
                </div>
            </div>
        </div>
        <RestrictionForm v-model:show="showModal" :vehicle="props.vehicle" />
        <Confirm v-model:show="showDeleteConfirm" confirmColor="danger" @confirm="processDeleteRestriction">
            <template v-slot:content>
                <p>Are you sure that you want to delete this Location Restriction?</p>
                <p class="text-danger">It may not be recoverable!</p>
            </template>
        </Confirm>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch, computed } from 'vue'
    import { storeToRefs } from "pinia";
    import { Button, Pagination, Confirm } from "@components";
    import { useLocationRestrictionStore } from "@stores/LocationRestrictionStore";
    import RestrictionForm from "./RestrictionForm.vue";
    import { mdiPlusCircle } from '@mdi/js'
    import { copyValues } from "@components/Utils";

    const props = withDefaults(
        defineProps<{
            vehicle?: string,
        }>(), {}
    );

    const restrictionStore = useLocationRestrictionStore();

    const { 
        paginatedMeta,
        paginateParams,
        restrictions,
        restriction,
        changeRestriction,
        loadingRestrictions,
    } = storeToRefs(restrictionStore);

    const { getRestrictions, deleteRestriction, changeRestrictionDefault } = restrictionStore;
    const showModal = ref<boolean>(false);
    const showConfirm = ref<boolean>(false);

    const loadPage = (link) => {
        paginateParams.value.page = link.page;
    }

    const createRestriction = () => {
        restriction.value = undefined;
        showModal.value = true;
    }

    const editRestriction = (record) => {
        restriction.value = record;
        changeRestriction.value = copyValues(changeRestrictionDefault);
        changeRestriction.value.uuid = restriction.value?.uuid;
        changeRestriction.value.location = restriction.value?.location;
        changeRestriction.value.vehicle.value = restriction.value?.vehicle?.uuid;
        changeRestriction.value.vehicle.label = restriction.value?.vehicle?.description + ' (' + restriction.value?.vehicle?.registration_code + ')';
        changeRestriction.value.vehicle.image = window._globalAsset + 'images/vehicles/' + (restriction.value?.vehicle?.type?.image ?? sedan) + '.png';
        changeRestriction.value.comment.value = restriction.value?.comment;
        showModal.value = true;
    }

    const confirmDeleteRestriction = (record) => {
        restriction.value = record;
        showDeleteConfirm.value = true;
    }

    const processDeleteRestriction = () => {
        deleteRestriction(props.vehicle);
        showDeleteConfirm.value = false;
    };

    const showDeleteConfirm = computed<boolean>({
        get: () => showConfirm.value,
        set: (value: boolean) => showConfirm.value = value,
    });

    watch(
        paginateParams.value,
        () => {
            getRestrictions(props.vehicle);
        }
    )

    onMounted(() => {
        getRestrictions(props.vehicle);
    })

</script>