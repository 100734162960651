<template>
    <span
        v-if="!editServiceTypeForm"
        @click="showEditServiceTypeForm"
        :class="[ editable ? 'click-to-edit' : 'text-muted' ]"
    >
        {{ inputData.selectedServiceType.name }}
    </span>
    <div v-if="confirmingChanges">
        <p>Are you sure you wish to adjust this service type to {{ inputData.selectedServiceType.name }}?</p>
        <div class="d-flex justify-content-end">
            <Button @click="cancelChanges" size="small" color="light" class="me-1">Cancel</Button>
            <Button @click="storeChanges" :loading="storing" size="small" color="danger">Confirm</Button>
        </div>
    </div>
    <div v-if="editServiceTypeForm && !confirmingChanges">
        <FormSelect
            v-model:selection="inputData.selectedServiceType"
            :key="'service_default_booking_type'"
            :options="serviceTypes"
            :showLabel="false"
            label="service type"
            placeholder="-- Select Service Type --"
        />
        <div class="d-flex justify-content-end mt-2">
            <Button @click="cancelChanges" size="small" color="light" class="me-1">Cancel</Button>
            <Button @click="confirmChanges" :loading="storing" size="small" color="primary">Save</Button>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, inject, onBeforeMount} from "vue";
import {FormSelect} from "@components/Forms";
import Button from '../../Button.vue';
import { useBookingServiceTypeStore } from "@stores/BookingServiceTypeStore";

const bookingServiceTypeStore = useBookingServiceTypeStore();

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    booking: {
        type: Object,
        required: true
    },
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editServiceTypeForm = ref(false)

const editable = computed(() => {
    return !props.readOnly;
})

const showEditServiceTypeForm = function () {
    if (!editable.value) {
        return
    }

    editServiceTypeForm.value = true
}

const serviceTypes = ref([]);
const inputData = ref(null);

const initialiseData = () => {
    inputData.value = {
        selectedServiceType: {
            value: null,
            name: null,
            required: true
        }
    };
}

const getBookingServiceTypes = async () => {
    const fullServiceTypes = await bookingServiceTypeStore.sync();
    serviceTypes.value = fullServiceTypes.map((serviceType) => {
        return {
            'value': serviceType.uuid,
            'label': serviceType.name,
        };
    });
};

onBeforeMount(() => {
    initialiseData();

    inputData.value.selectedServiceType.value = props.booking.service_type.uuid;
    inputData.value.selectedServiceType.name = props.booking.service_type.name;

    getBookingServiceTypes();
});

const confirmingChanges = ref(false);
const storing = ref(false);

const cancelChanges = () => {
    editServiceTypeForm.value = false;
    confirmingChanges.value = false;
}

const confirmChanges = () => {
    const selectedServiceType = serviceTypes.value.find(st => st.value === inputData.value.selectedServiceType.value);
    inputData.value.selectedServiceType.name = selectedServiceType.label;

    confirmingChanges.value = true;
}

const emit = defineEmits(['updated']);

const storeChanges = () => {
    storing.value = true;

    axios
        .put(route('api.bookings.update', { booking: props.booking.uuid }), {
            service_type: inputData.value.selectedServiceType.value,
        })
        .then((response) => {
            emit('updated', response.data);
            editServiceTypeForm.value = false;
        })
        .catch((error) => {
            if (error.response.status && error.response.status === 422) {
                inputData.value.selectedServiceType.value.errors = [error.response.data.errors.service_type]
            } else {
                toast.error(error?.response?.data?.message ?? 'Something went wrong. Our system administrators have been notified.')
            }
        })
        .finally(() => {
            storing.value = false
            confirmingChanges.value = false
        })
}

</script>

<style scoped>

</style>
