<template>
    <input 
        type="text" 
        v-model="name"
        class="form-control border border-dark border-opacity-25 rounded"
        :class="{'is-invalid': !meta.valid && meta.dirty}"
        @change="nameUpdated"
    />
    <ul v-if="errors?.length > 0" class="text-danger">
        <li v-for="(error, idx2) in errors" :key="idx2">{{ error }}</li>
    </ul>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useAttachmentStore } from '@stores/AttachmentStore';
    import { useField } from 'vee-validate'
    import * as yup from 'yup'

    const { fieldErrors } = useAttachmentStore();

    const props = withDefaults(
        defineProps<{
            name: string,
            index: number
        }>(),
        {}
    )

    const emit = defineEmits<{
        (e: 'updated', value: string): void,
        (e: 'valid', value: boolean): void
    }>();

    const name = ref<string>(props.name);

    const {errorMessage, setValue, validate, meta} = useField('name', yup.string().required().max(255).min(1).label('Attachment Name'));

    const errors = computed(() => {
        let values: string[] | null = [];

        if (fieldErrors[`attachments.${props.index}.name`]?.length > 0) {
            values = values.concat(fieldErrors[`attachments.${props.index}.name`]);
        }

        if (errorMessage.value != undefined) {
            values.push(errorMessage.value);
        }

        return values;
    })

    const nameUpdated = () => {
        setValue(name.value);
        validate().then(() => {
            emit('updated', name.value);
            emit('valid', meta.valid);
        });
    }
</script>