<template>
    <div id="alertModal" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-colored-header bg-danger">
                    <h5 class="modal-title" id="optimiserOptionsModalLabel">There's been a problem</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{ content }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlertModal",
    data() {
        return {
            modal: null,
            content: '',
        }
    },
    methods: {
        initialise() {
            this.modal = new bootstrap.Modal(document.getElementById('alertModal'))
        },
        show(content) {
            this.content = content
            this.modal.show()
        },
    },
    mounted() {
        this.initialise()
    }
}
</script>

<style scoped>

</style>
