<template>
    <li :class="[striped ? 'verification-journey-striped' : null]" class="border-bottom position-relative">
        <div class="d-flex justify-content-between align-items-center p-2" style="cursor: pointer"
             @click="show = !show">
            <div class="flex-grow-1">
                <slot name="title"/>
            </div>
            <div>
                <SvgIcon
                    class="h-6 w-6 text-secondary"
                    type="mdi"
                    :path="show ? mdiMenuUp : mdiMenuDown"
                ></SvgIcon>
            </div>
        </div>
        <div v-if="show" :class="[striped ? 'bg-white' : null]" class="p-2">
            <slot name="content"/>
        </div>
    </li>
</template>

<script>
export default {
    name: "AccordionItem"
}
</script>
<script setup>
import {ref, watch} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiMenuDown, mdiMenuUp} from "@mdi/js";

const start = function (el) {
    el.style.height = el.scrollHeight + "px";
}
const end = function (el) {
    el.style.height = "";
}

const props = defineProps({
    striped: {
        type: Boolean,
        required: false,
        default: false
    },
    visible: {
        type: Boolean,
        required: false,
        default: false
    }
})

let show = ref(false)
watch(() => props.visible, (value) => {
    show.value = value
})

</script>
<!--suppress CssUnusedSymbol -->
<style scoped>
li:last-of-type {
    border-bottom: none !important;
}
.verification-journey-striped:nth-child(odd) {
    background-color: #f8f9fa;
}
</style>
