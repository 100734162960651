<template>
    <div>
        <span
            @click="showModal = true"
            :class="!editable ? null : 'click-to-edit'"
            >{{
                client.regions.length > 0
                    ? client.regions.map((i) => i.name).join(", ")
                    : "Add Regions"
            }}</span
        >

        <Modal title="Edit Client Regions" v-model:show="showModal">
            <template v-slot:body>
                <ClientRegionEditableList v-model="regionPivots" />
            </template>
            <template v-slot:footer>
                <Button
                    type="button"
                    class="btn btn-secondary"
                    @click="showModal = false"
                >
                    Close
                </Button>
                <Button
                    type="button"
                    class="btn btn-primary"
                    @click="saveRegions"
                >
                    Save changes
                </Button>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, toRefs, inject } from "vue";
import Client from "@classes/Client";
import ClientResource from "@customTypes/resources/ClientResource";
import Region from "@classes/Region";
import ClientRegionEditableList from "@components/Clients/ClientRegionEditableList.vue";
import Modal from "@components/Modal.vue";
import Button from "@components/Button.vue";
import ClientRegionPivotResource from "@customTypes/resources/ClientRegionPivotResource";
import { useRegionStore } from "@stores/RegionStore";
const toast = inject<any>("toast");

const showModal = ref(false);
const saving = ref(false);

const regionPivots = ref<ClientRegionPivotResource[]>([]);

const regionStore = useRegionStore();

const props = withDefaults(
    defineProps<{
        client: ClientResource;
        readOnly?: boolean;
    }>(),
    {
        readOnly: false,
    }
);

const { client } = toRefs(props);

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated_at;
});
const emit = defineEmits(["updated"]);

const regions = ref<App.Models.Region[]>([]);
const getRegions = async () => {
    regions.value = await regionStore.sync();
};

const errors = ref({});

const saveRegions = function () {
    saving.value = true;

    Client.update(client.value, { regions: regionPivots.value })
        .then((response) => {
            emit("updated", response.data);
            toast.success("Client regions updated successfully.");
            showModal.value = false;
        })
        .catch((error) => {
            errors.value = error.response.data.errors;
            toast.error(
                error?.response?.data?.message ??
                    "There was an error saving the client."
            );
        })
        .finally(() => {
            saving.value = false;
        });
};

onBeforeMount(() => {
    getRegions();
    regionPivots.value = client.value.regions;
});
</script>
