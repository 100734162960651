<template>
    <div class="d-inline-block">
        <EditableInput
            v-if="occuredAt"
            :label="formatDate(occuredAt, 'short time')"
            :loading="updating"
            :value="
                DateTime.fromISO(occuredAt)
                    .toISO({
                        includeOffset: false,
                        suppressMilliseconds: true,
                        suppressSeconds: true,
                    })
                    .toString()
            "
            type="datetime-local"
            @updated="updateTime"
        />
    </div>
</template>

<script setup lang="ts">
import EditableInput from "../../Forms/EditableFields/EditableInput.vue";
import { inject, ref, computed } from "vue";
import { toTimeStamp, formatDate } from "../../Utils.js";
import { DateTime } from "luxon";
import axios from "axios";
import { useVerificationStore } from "../../../stores/VerificationStore.js";
import JourneyEvent, { JourneyEventType } from "@classes/JourneyEvent";

const toast = inject<any>("toast");

const verificationStore = useVerificationStore();

const props = defineProps<{
    journey: App.Models.Journey;
    eventType: JourneyEventType;
}>();

const event = computed(() => {
    return props.journey?.events?.find(
        (event) => event.type === props.eventType
    );
});

const occuredAt = computed(() => {
    return event.value?.occurred_at;
});

const eventTypeWording =
    props.eventType == JourneyEventType.END ? "end" : "start";

const updating = ref(false);
const updateTime = async (newTime: DateTime) => {
    let eventValue = event.value;

    if (!eventValue) {
        return;
    }

    updating.value = true;

    JourneyEvent.update(eventValue, {
        occurred_at: newTime,
    })
        .then((response) => {
            if (typeof eventValue == "undefined") {
                return;
            }

            let eventIndex = props.journey?.events?.indexOf(eventValue);

            if (typeof eventIndex == "undefined") {
                return;
            }

            let newEvents = props.journey?.events ?? [];
            newEvents[eventIndex] = response.data;

            verificationStore.updateJourney({
                ...props.journey,
                ...{
                    events: newEvents,
                },
            });
            toast.success(
                `Actual ${eventTypeWording} time updated for vehicle run.`
            );
        })
        .catch((error) => {
            toast.error(
                `There was an error updating the ${eventTypeWording} time for this vehicle run.`
            );
        })
        .finally(() => {
            updating.value = false;
        });
};
</script>

<style scoped></style>
