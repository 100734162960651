<template>
    <EditableSelect
        @updated="update"
        :value="client.booking_service_type?.uuid"
        :options="bookingServiceTypeOptions"
        :loading="updating"
        :disabled="!editable"
    >
        {{ client.booking_service_type?.name }}
    </EditableSelect>
</template>

<script>
export default {
    name: "EditClientDefaultBookingServiceType"
}
</script>
<script setup>
import axios from "axios";
import {ref, onBeforeMount, computed} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    },
    bookingServiceTypes: {
        type: Object,
        required: true
    }
})

const bookingServiceTypeOptions = Object.fromEntries(props.bookingServiceTypes.map(type => [type.uuid, type.name]))

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const errors = ref({})
const updating = ref(false)
const update = function (newDefaultBookingServiceType) {
    updating.value = true;


    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            booking_service_type: newDefaultBookingServiceType
        })
        .then((response) => {
            emit('updated', response.data);
            const selectedType = props.bookingServiceTypes.find(bs => bs.uuid === newDefaultBookingServiceType);
            props.client.booking_service_type = selectedType;
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
