<template>
    <div
        :style="{
            width: unitSize + 'px',
            height: unitSize + 'px',

            marginLeft: spaceMargin + 'px',
        }"
        @click="toggleSelect"
    >
        <div
            :style="{
                position: 'relative',
                top: (unitSize - addCircleSize) / 2 + 'px',
                left: 0 - addCircleSize / 2 + 'px',
                width: addCircleSize + 'px',
                height: addCircleSize + 'px',
                'z-index': 1000,
            }"
            class="d-flex"
        >
            <div
                v-if="editable"
                :style="{
                    width: addCircleSize + 'px',
                    height: addCircleSize + 'px',
                    borderRadius: addCircleSize / 2 + 'px',
                    cursor: 'pointer',
                }"
                class="d-flex bg-primary text-white align-items-center justify-content-center"
                @click="onAddSpaceClick"
            >
                +
            </div>
        </div>

        <div
            :style="{
                position: 'relative',
                top: '-' + addCircleSize + 'px',
                width: unitSize + 'px',
                height: unitSize + 'px',
            }"
        >
            <div
                :style="{
                    display: 'block',
                    top: '-' + addCircleSize + 'px',
                    width: unitSize + 'px',
                    height: unitSize + 'px',
                    borderRadius: spaceMargin + 'px',
                    background: spaceProperties?.backgroundColor ?? undefined,
                    border: border,
                }"
            >
                <div
                    :class="{
                        'd-flex': true,
                        'align-items-center': true,
                        'justify-content-center': true,
                        'flex-column': true,
                    }"
                    :style="{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }"
                >
                    <SvgIcon
                        v-if="spaceProperties?.icon"
                        type="mdi"
                        :size="40"
                        :style="{ color: spaceProperties?.color }"
                        :path="spaceProperties?.icon"
                    ></SvgIcon>
                    <span :style="{ color: spaceProperties?.color }">{{
                        spaceProperties?.label
                    }}</span>
                </div>
            </div>
            <div
                v-if="editable"
                :class="{
                    'd-flex': true,
                    'align-items-center': true,
                    'justify-content-center': true,
                }"
                :style="{
                    position: 'absolute',
                    top: '5px',
                    width: '20px',
                    height: '20px',
                    right: '5px',
                    border: selectIndicatorBorder,
                    'border-radius': '50%',
                    background: selected
                        ? spaceProperties?.color
                        : 'transparent',
                }"
            >
                <SvgIcon
                    v-if="selected"
                    color="white"
                    type="mdi"
                    :size="10"
                    :path="mdiCheckBold"
                />
            </div>

            <div
                v-if="linked"
                :class="{
                    'd-flex': true,
                    'align-items-center': true,
                    'justify-content-center': true,
                }"
                :style="{
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                }"
            >
                <SvgIcon
                    :color="spaceProperties?.color"
                    type="mdi"
                    :size="20"
                    :path="mdiLink"
                />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import {
    useVehicleConfigurationDesignStore,
    VehicleConfigurationSpaceInterface,
    VehicleConfigurationSpaceType,
    VehicleConfigurationSpaceTypeInterface,
} from "@stores/VehicleConfigurationDesignStore";
import { mdiCheckBold, mdiCubeUnfolded, mdiLink } from "@mdi/js";
import { computed, ref, toRefs } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const {
    selectedSpacesUuid,
    rows,
    unitSize,
    spaceMargin,
    spaceTypes,
    linkedSpaces,
    editable,
} = storeToRefs(vehicleConfigurationDesignStore);

const { addSpace, getRowIndexFromSpaceUuid, getRowFromSpaceUuid } =
    vehicleConfigurationDesignStore;

const addCircleSize = ref(16);

const props = withDefaults(
    defineProps<{
        space: VehicleConfigurationSpaceInterface;
    }>(),
    {}
);

const emptyProperties: VehicleConfigurationSpaceTypeInterface = {
    label: "Empty",
    value: VehicleConfigurationSpaceType.EMPTY,
    icon: null,
    backgroundColor: null,
    color: "#9597B1",
};

const spaceProperties = computed<VehicleConfigurationSpaceTypeInterface>(() => {
    let length = space.value?.type?.length ?? 0;

    if (length > 1) {
        return {
            label: "Convertable",
            value: VehicleConfigurationSpaceType.EMPTY,
            icon: mdiCubeUnfolded,
            backgroundColor: "#D6EBFF",
            color: "#007AF8",
        };
    } else if (length == 1) {
        let firstMatching = spaceTypes.value?.find(
            (i) => i.value == space.value?.type[0]
        );
        return firstMatching ?? emptyProperties;
    }

    return emptyProperties;
});

const border = computed(() => {
    return selected.value
        ? "2px solid " + spaceProperties.value.color
        : "2px solid " + (spaceProperties.value?.backgroundColor ?? "white");
});

const selectIndicatorBorder = computed(() => {
    return "2px solid " + spaceProperties.value.color;
});

const { space } = toRefs(props);

const toggleSelect = () => {
    if (!editable.value) {
        return;
    }
    selected.value = !selected.value;
};

const onAddSpaceClick = () => {
    let row = getRowFromSpaceUuid(space.value?.uuid);

    let rowIndex = getRowIndexFromSpaceUuid(space.value?.uuid);

    if (rowIndex == undefined || row == undefined) {
        return;
    }

    let spaceIndex = row.spaces.findIndex((s) => s.uuid == space.value?.uuid);

    addSpace(rowIndex, spaceIndex);
};

const linked = computed(() => {
    return linkedSpaces.value.find((i) => {
        return i.spaces.includes(space.value?.uuid);
    });
});

const selected = computed({
    get() {
        return selectedSpacesUuid.value.includes(space.value?.uuid);
    },
    set(newValue) {
        let spacesLinkedToCurrent: string[] = [space.value?.uuid];

        linkedSpaces.value.map((linkedSpace) => {
            if (linkedSpace.spaces.includes(space.value?.uuid)) {
                spacesLinkedToCurrent = [
                    ...spacesLinkedToCurrent,
                    ...linkedSpace.spaces,
                ];
            }
        });

        console.log({ spacesLinkedToCurrent });

        if (newValue) {
            selectedSpacesUuid.value = [
                ...selectedSpacesUuid.value,
                ...spacesLinkedToCurrent,
            ];
        } else {
            selectedSpacesUuid.value = selectedSpacesUuid.value.filter(
                (i) => !spacesLinkedToCurrent.includes(i)
            );
        }
    },
});
</script>
