<template>
    <li class="list-group-item py-3">
        <div class="row">
            <div class="col-md-8 pe-3">
                <label class="form-label" :for="uuid">{{
                    setting.title
                }}</label>
                <p class="text-muted mb-0 break-word-container text-break">
                    {{ setting.description }}
                </p>
            </div>
            <div class="col-md-4 text-end">
                <div
                    v-if="setting.type === 'boolean'"
                    class="form-check form-switch float-end"
                >
                    <input
                        class="form-check-input boolean"
                        type="checkbox"
                        role="switch"
                        :id="uuid"
                        :name="setting.name"
                        @change="onInputChange"
                        :checked="inputValue == '1'"
                    />
                </div>

                <input
                    v-else-if="setting.type === 'string'"
                    :id="uuid"
                    type="text"
                    class="form-control"
                    @change="onInputChange"
                    :name="setting.name"
                    v-model="inputValue"
                />

                <input
                    v-else-if="setting.type === 'number'"
                    :id="uuid"
                    type="number"
                    class="form-control"
                    @change="onInputChange"
                    :name="setting.name"
                    v-model="inputValue"
                />

                <MultiTextInput
                    v-else-if="setting.type === 'multi-email'"
                    :id="uuid"
                    v-bind:initial="JSON.parse(inputValue)"
                    @change="changeSetting"
                    validation="email"
                    :name="setting.name"
                />

                <MultiTextInput
                    v-else-if="setting.type === 'multi-string'"
                    :id="uuid"
                    v-bind:initial="JSON.parse(inputValue)"
                    @change="changeSetting"
                    :name="setting.name"
                />

                <MultipleSelector
                    v-else-if="setting.type === 'multi-select'"
                    :id="uuid"
                    v-bind:initial="JSON.parse(inputValue)"
                    @change="changeSetting"
                    :options="setting.options"
                    :name="setting.name"
                />

                <select
                    v-else-if="setting.type === 'select'"
                    :name="setting.name"
                    :id="uuid"
                    class="form-select"
                    @change="onInputChange"
                    aria-label="Select one of the options"
                    v-model="inputValue"
                >
                    <option
                        v-for="(option, index) in setting.options"
                        :value="option"
                        :selected="option === inputValue"
                    >
                        {{ option }}
                    </option>
                </select>

                <div class="clearfix"></div>

                <div v-if="loading" class="text-primary align-items-center">
                    <div
                        class="spinner-border spinner-border-sm me-1"
                        role="status"
                    ></div>
                    Saving
                </div>

                <p v-else-if="success" class="text-success align-items-center">
                    Saved successfully!
                </p>
                <p v-else-if="fail" class="text-danger align-items-center">
                    Error, could not save!
                </p>
            </div>
        </div>
    </li>
</template>

<script>
import MultiTextInput from "./MultiTextInput.vue";
import MultipleSelector from "./MultipleSelector.vue";
export default {
    name: "SettingRow",
    components: {
        MultiTextInput,
        MultipleSelector,
    },
    props: {
        setting: Object,
    },
    data() {
        return {
            loading: false,
            success: false,
            fail: false,
            inputValue: "",
            uuid: this.$uuid.v1(),
        };
    },
    methods: {
        onInputChange(e) {
            const { target } = e;
            const { classList, checked, value, name } = target;
            const isBoolean = classList.value.includes("boolean");
            const postValue = isBoolean
                ? checked
                    ? "1"
                    : "0"
                : this.inputValue;

            this.changeSetting(name, postValue);
        },
        changeSetting(name, value) {
            this.loading = true;
            this.$http
                .put(this.route("organisation-settings.update"), {
                    category: "driver_app",
                    settings: {
                        [name]: value,
                    },
                })
                .then((response) => {
                    this.success = true;
                    setTimeout(() => {
                        this.success = false;
                    }, 2000);
                })
                .catch(() => {
                    this.fail = true;
                    setTimeout(() => {
                        this.fail = false;
                    }, 2000);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    beforeMount() {
        this.inputValue = this.setting.value;
    },
};
</script>

<style scoped></style>
