import { LaravelPaginate } from "@customtypes/LaravelCommonTypes";
import axios from "axios";


export default class DataImport {
    static async index(data) {
        return await axios.get<Promise<LaravelPaginate<App.Resource.DataImportResource[]>>>(route('services.data-import.index', data), {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    static async store(data: App.DataImport.DraftDataImport) {
        return await axios.postForm<Promise<App.Resource.DataImportResource>>(route('services.data-import.store'), data);
    }

    static async importTypes() {
        return await axios.get<Promise<App.DataImport.ImportTypes[]>>(route('services.data-import.types'));
    }
}