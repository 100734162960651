<template>
    <div v-click-outside="inputFinished">
        <label
            :for="'addressSearch-' + uuidV1"
            :class="[
                'form-label',
                hideLabel ? 'visually-hidden' : null,
                required ? 'required-label' : null,
            ]"
            >{{ label }}</label
        >
        <div
            :class="[
                'input-group',
                unableToParseAddress ? 'has-validation' : null,
                size === 'small' ? 'input-group-sm' : null,
            ]"
            v-click-outside="
                () => {
                    results = [];
                    showDropdownResult = false;
                }
            "
        >
            <span v-if="!props.isDisabled" class="mdi mdi-magnify input-group-text"></span>
            <input
                type="text"
                v-focus="focus"
                v-model="displayableInputValue"
                @keyup="searchLocations"
                @change="handleQueryChange"
                @blur="validate"
                :id="'addressSearch-' + uuidV1"
                :disabled="isDisabled"
                :class="[
                    'form-control',
                    'addressSearch',
                    unableToParseAddress || invalid ? 'is-invalid' : null,
                ]"
                :placeholder="placeholder"
            />
            <!-- Since existing value is populated now want to give the user an easy way to clear the current selected address -->
            <span
                v-if="!props.isDisabled"
                @click="clear"
                class="mdi mdi-close-circle input-group-text"
            ></span>
        </div>
        <div v-if="showDropdownResult" class="autocomplete-results-wrapper">
            <div class="py-1 px-2">Recent locations:</div>
            <div v-if="results.length > 0" class="list-group">
                <a
                    href="javascript: void(0)"
                    v-for="(location, key) in results"
                    :key="key"
                    @click="selectLocation(location)"
                    @mouseover="deselectAll"
                    class="list-group-item autocomplete-suggestion"
                    :id="uuidV1 + '_suggestion_' + key"
                >
                    <div>
                        <Markdown
                            :content="
                                highlightFilters(
                                    location?.full_address ?? '',
                                    queryString
                                )
                            "
                        />
                    </div>
                </a>
                <div
                    v-if="total > results.length"
                    class="list-group-item autocomplete-suggestion text-primary font-weight-bold text-center"
                    style="font-weight: bold"
                    @click="showMoreResults"
                >
                    See more ({{ total - results.length }} more results)
                </div>
                <a
                    v-if="canCreate"
                    href="javascript: void(0)"
                    @click="createLocation()"
                    class="list-group-item autocomplete-suggestion create-location"
                    style="border-top: 1px solid #dee2e6 !important"
                    >Create Location</a
                >
            </div>
            <div v-else class="list-group">
                <div class="py-1 px-2">Location not found</div>
                <a
                    v-if="canCreate"
                    href="javascript: void(0)"
                    @click="createLocation()"
                    class="list-group-item autocomplete-suggestion create-location"
                    style="border-top: 1px solid #dee2e6 !important"
                    >Create Location</a
                >
            </div>
        </div>
        <div>
            <CreateLocationForm
                :show-modal="showModal"
                @stored="handleNewLocationCreated"
            />
        </div>
        <div v-if="errorMessage?.length > 0" class="text-danger">{{ errorMessage }}</div>
    </div>
</template>

<script setup lang="ts">
import Markdown from "./Markdown.vue";
import { uuid } from "vue-uuid";
import { debounce } from "lodash";
import CreateLocationForm from "./Locations/CreateLocationForm.vue";
import { inject, ref, computed, onMounted, watch } from "vue";
import { useField } from 'vee-validate'
import axios from "axios";


const props = withDefaults(
    defineProps<{
        modelValue?: App.Models.Location | null;
        required?: boolean;
        label?: string;
        isDisabled?: boolean;
        hideLabel?: boolean;
        size?: string;
        focus?: boolean;
        invalid?: boolean;
        placeholder?: string;
        canCreate?: boolean;
    }>(),
    {
        modelValue: undefined,
        required: false,
        label: "",
        isDisabled: false,
        hideLabel: false,
        size: "normal",
        focus: false,
        invalid: false,
        placeholder: "Search address",
        canCreate: true,
    }
);

const { errorMessage, validate, setValue } = useField<object>('location', (value) => {
    if (props.required && !value) {
        return "Location is required";
    }

    if (value && typeof value !== 'object') {
        return "Location is required";
    }

    return true;
});

const uuidV1 = ref(uuid.v1());
const queryString = ref("");
let selectedLocation = ref("");
const results = ref<any[]>([]);
const highlighted = ref(-1);
const unableToParseAddress = ref(false);
const searchKey = ref(uuid.v4());
const toast: any = inject("toast");
const showDropdownResult = ref(false);
const showModal = ref(true);
const total = ref(0);
const limit = ref(10);

const emit = defineEmits(["locationSelected", "update:modelValue", "isValid"]);

const searchLocations = (event) => {
    if ([40, 38, 13].includes(event.keyCode)) {
        moveThroughResults(event);
        return;
    }

    stoppedTyping();
};

const clear = () => {
    displayableInputValue.value = "";

    selectedLocation.value = "";

    emit("update:modelValue", '');
};

const moveThroughResults = (event) => {
    switch (event.keyCode) {
        case 40:
            // down arrow
            moveSelection("down");
            break;
        case 38:
            // up arrow
            moveSelection("up");
            break;
        case 13:
            // enter
            event.preventDefault();

            if (highlighted.value !== -1) {
                const id: string =
                    uuidV1.value + "_suggestion_" + highlighted.value;
                document.getElementById(id)?.click();
            }
            break;
    }
};

const moveSelection = (direction) => {
    const suggestions = document.getElementsByClassName(
        "autocomplete-suggestion"
    );

    highlighted.value += direction === "down" ? 1 : -1;

    if (highlighted.value > results.value.length) {
        highlighted.value = 0;
    }

    deselectAll();

    if (highlighted.value < 0) {
        highlighted.value = -1;
        return;
    }

    suggestions[highlighted.value].classList.add("selected");
};

const deselectAll = () => {
    const suggestions = document.getElementsByClassName(
        "autocomplete-suggestion"
    );
    [].forEach.call(suggestions, function (el: HTMLFormElement) {
        el.classList.remove("selected");
    });
};

//Makes sure if the modelValue is already filled the address is shown to user but when they start typing it will be cleared
const displayableInputValue = computed<string>({
    get() {
        return (
            //Some parent components pass the modelValue inside and object that has a value property, they also include things like validation or errors etc
            //I don't want to break those components that rely on this so for now we will have this ugliness here
            props.modelValue?.full_address ??
            //@ts-ignore
            props.modelValue?.fullAddress ??
            //@ts-ignore
            props.modelValue?.value?.full_address ??
            //@ts-ignore
            props.modelValue?.value?.fullAddress ??
            queryString.value
        );
    },
    set(newValue) {
        queryString.value = newValue;
        emit("update:modelValue", null);
    },
});

const selectLocation = (location) => {
    results.value = [];
    unableToParseAddress.value = false;

    queryString.value = location.full_address;
    location.formatted_address = location.full_address;
    selectedLocation.value = location;
    emit("locationSelected", location);
    emit("update:modelValue", location);

    showDropdownResult.value = false;
};

const handleQueryChange = () => {
    if (queryString.value.trim().length === 0) {
        emit("locationSelected", {});
        emit("update:modelValue", null);
    }
};

const highlightFilters = (string: string, query: string): string => {
    if (!string || !query) {
        return string;
    }
    const check = new RegExp(query.trim(), "ig");
    let result = string
        .toString()
        .replace(
            check,
            (matchedText) =>
                '<span class="fw-bolder">' + matchedText + "</span>"
        );
    return result ?? string;
};

const createLocation = () => {
    showModal.value = true;
    showDropdownResult.value = false;
};

const showMoreResults = () => {
    limit.value += 10;
};

const getLocations = () => {
    // reset show modal otherwise it won't open again
    showModal.value = false;

    const currentSearchKey = uuid.v4();
    searchKey.value = currentSearchKey;
    axios
        .get(
            route("api.locations.search", {
                query: queryString.value,
                limit: limit.value,
            })
        )
        .then((response) => {
            if (searchKey.value !== currentSearchKey) {
                return;
            }
            total.value = response?.data?.meta?.total ?? 0;
            results.value = response.data.data;
            showDropdownResult.value = true;
        })
        .catch((error) => {
            toast.error("There was an error retrieving the location types.");
            console.error(error);
        });
};

const stoppedTyping = debounce(() => {
    getLocations();
}, 1000);

watch(
    () => limit.value,
    () => {
        getLocations();
    }
);

const handleNewLocationCreated = (newLocation) => {
    showModal.value = false;
    queryString.value = newLocation.full_address;
    selectedLocation.value = newLocation;
    emit("locationSelected", newLocation);
    emit("update:modelValue", newLocation);
};

const inputFinished = () => {
    showDropdownResult.value = false;
}

onMounted(() => {
    watch(
        selectedLocation,
        () => {
            setValue(selectedLocation.value);
            validate().then((results) => {
                emit("isValid", results.valid);
            })
        }
    )
})
</script>
