<template>
    <div>
        <label
            :for="'addressSearch-'+uuidV1"
            :class="['form-label', hideLabel ? 'visually-hidden' : null, required ? 'required-label' : null]"
        >{{ label }}</label>
        <div
            :class="[
                'input-group',
                (unableToParseAddress ? 'has-validation' : null),
                (size === 'small' ? 'input-group-sm' : null),
            ]"
            v-click-outside="() => { googleResults = [] }"
        >
            <span class="mdi mdi-magnify input-group-text"></span>
            <input
                type="text"
                v-focus="focus"
                v-model="queryString"
                @keyup="searchLocations"
                @change="handleQueryChange"
                :id="'addressSearch-'+uuidV1"
                :disabled="isDisabled"
                :class="[
                    'form-control',
                    'addressSearch',
                    (unableToParseAddress || invalid ? 'is-invalid' : null),
                ]"
                :placeholder="placeholder"
            >
            <div class="input-group-text" v-if="savingGoogleResult">
                <Spinner type="border" size="small"></Spinner>
            </div>
            <div v-if="unableToParseAddress" class="invalid-feedback">
                Unable to save this address, please try searching a more specific location
            </div>
        </div>
        <div v-if="showDropdownResult" class="autocomplete-results-wrapper">
            <div v-if="googleResults.length > 0" class="list-group">
                <div class="py-1 px-2"><img src="/images/google_logo.svg.png" style="max-width: 51px;" alt="Google"> results:</div>
                <div class="list-group">
                    <a
                        href="javascript: void(0)"
                        v-for="(prediction, key) in googleResults"
                        :key="key"
                        @click="selectLocation(prediction)"
                        class="list-group-item autocomplete-suggestion"
                        :id="uuid + '_suggestion_' + key"
                    >
                        <div>
                            <Markdown :content="highlightFilters(prediction.description, queryString)"/>
                        </div>
                    </a>
                </div>
            </div>
            <div v-else class="list-group">
                <div class="py-1 px-2">Location not found</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { copyValues } from './Utils.js'
import Markdown from "./Markdown.vue";
import { uuid } from 'vue-uuid'
import { debounce } from 'lodash'
import Spinner from "./Spinner.vue";
import {inject, onMounted, ref} from "vue";
import * as querystring from "querystring";
import axios from "axios";

const props = withDefaults(
    defineProps<{
        modelValue?: Object,
        required?: boolean,
        label?: string,
        isDisabled?: boolean
        hideLabel?: boolean,
        size?: string,
        focus?: boolean,
        invalid?: boolean,
        placeholder?: string,
    }>(),
    {
        modelValue: undefined,
        required: false,
        label: '',
        isDisabled: false,
        hideLabel: false,
        size: 'normal',
        focus: false,
        invalid: false,
        placeholder: 'Search address'
    }
);

const uuidV1 = ref(uuid.v1());
const queryString = ref('');
const selectedLocation = ref(null);
const googleResults = ref([]);
const highlighted = ref(-1);
let googleAutocompleteService: any = ref(null);
const ipAddress = ref(null);
const clientLocation: any = ref(null);
const unableToParseAddress = ref(false);
const searchKey =  ref(uuid.v4());
const savingGoogleResult = ref(false);
const locationTypes = ref([]);
const toast: any = inject('toast');
const showDropdownResult = ref(false);
const showModal = ref(false);

const emit = defineEmits(["locationSelected", "update:modelValue", "selectedAddressDetail"]);

const searchLocations = (event) => {
    if ([40, 38, 13].includes(event.keyCode)) {
        moveThroughResults(event)
        return
    }

    stoppedTyping();
};

const moveThroughResults = (event) => {
    switch (event.keyCode) {
        case 40:
            // down arrow
            moveSelection('down');
            break;
        case 38:
            // up arrow
            moveSelection('up');
            break;
        case 13:
            // enter
            event.preventDefault();
            const id: string = uuidV1.value + '_suggestion_' + highlighted.value;
            document.getElementById(id)!.click();
            break;
    }
}

const moveSelection = (direction) => {
    const suggestions = document.getElementsByClassName('autocomplete-suggestion');
    highlighted.value += (direction === 'down') ? 1 : -1;

    if (highlighted.value > googleResults.value.length) {
        highlighted.value = 0;
    }

    deselectAll();

    if (highlighted.value < 0) {
        highlighted.value = -1;
        return;
    }

    suggestions[highlighted.value].classList.add('selected');
};

const deselectAll = () => {
    const suggestions = document.getElementsByClassName('autocomplete-suggestion');
    [].forEach.call(suggestions, function (el: HTMLFormElement) {
        el.classList.remove("selected");
    });
};

const selectLocation = (location) => {
    googleResults.value = [];
    unableToParseAddress.value = false;

    queryString.value = location.full_address;
    selectedLocation.value = location;

    const request = {
        placeId: location.place_id,
        fields: ['name', 'geometry', 'address_components'],
    }
    const service = new google.maps.places.PlacesService(document.createElement('div'))
    service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (!place || !place.address_components) {
                // TODO put error meesage
                return;
            }

            let unit = ''
            let streetNumber = ''
            let streetName = ''
            let suburb = ''
            let postcode = ''
            let state = ''
            let latitude = place.geometry?.location?.lat()
            let longitude = place.geometry?.location?.lng()


            for (const component of place.address_components) {
                const componentType = component.types[0]
                switch (componentType) {
                    case 'subpremise':
                        unit = component.long_name
                        break
                    case 'street_number':
                        streetNumber = component.long_name
                        break
                    case 'route':
                        streetName = component.short_name
                        break
                    case "postal_code":
                        postcode = component.long_name
                        break
                    case "locality":
                        suburb = component.long_name;
                        break;
                    case "administrative_area_level_1":
                        state = component.short_name;
                        break;
                }
            }

            const streetAddress = `${streetNumber} ${streetName}`

            const addressDetail = {
                address_line_1: unit.length > 0 ? unit : streetAddress,
                address_line_2: unit.length > 0 ? streetAddress : '',
                suburb: suburb,
                postcode: postcode,
                state: state,
                latitude: latitude,
                longitude: longitude,
            };

            emit('selectedAddressDetail', addressDetail);
        }
    });

    emit('locationSelected', location);
    emit('update:modelValue', location);

    showDropdownResult.value = false;
};

const handleQueryChange = () => {
    if (queryString.value.trim().length === 0) {
        emit('locationSelected', {});
        emit('update:modelValue', null);
    }
};

const highlightFilters = (string: string, query: string): string => {
    if (!string || !query) {
        return string;
    }
    const check = new RegExp(query.trim(), "ig");
    let result = string.toString().replace(check, matchedText => '<span class="fw-bolder">' + matchedText + '</span>');
    return result ?? string;
};

const retrieveGoogleSuggestions = (searchKey) => {
    googleResults.value = []
    if (queryString.value.length === 0) {
        return
    }

    var options = {
        input: queryString.value,
        location: <any> '',
        radius: 0,
    };

    if (clientLocation.value !== null) {
        options.location = new google.maps.LatLng(clientLocation.value.latitude, clientLocation.value.longitude)
        options.radius = 50000
    }

    googleAutocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.warn(status)
            return
        }

        if (searchKey !== searchKey) {
            return
        }

        googleResults.value = predictions;
        showDropdownResult.value = true;
    })
};

const stoppedTyping = debounce(() => {
    const currentSearchKey = uuid.v4();
    searchKey.value = currentSearchKey;
    retrieveGoogleSuggestions(currentSearchKey);
}, 1000);

onMounted(() => {
    googleAutocompleteService = new google.maps.places.AutocompleteService();
});
</script>
