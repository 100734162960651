<template>
    <div>
        <span
            v-if="!editAgencyForm"
            @click="editAgencyForm = !(updating || !editable)"
            :class="(updating || !editable) ? null : 'click-to-edit'"
        >
            <template v-if="client.agency">{{ client.agency.name }}</template>
            <template v-else><small><em>Click to add</em></small></template>
        </span>
        <template v-if="editAgencyForm">
            <div class="mb-1">
                <label for="clientReferringAgency" class="visually-hidden">Referring Agency</label>
                <select v-model="selectedAgencyUuid" id="clientReferringAgency" class="form-select form-select-sm">
                    <option value="">-- Select Agency --</option>
                    <template v-for="agency in agencies">
                        <option :value="agency.uuid">{{ agency.name }}</option>
                    </template>
                </select>
            </div>
            <div v-if="agency" class="mb-1">
                <label for="clientReferringAgencyContact" class="visually-hidden">Referring Agency Contact</label>
                <select v-model="selectedContactUuid" id="clientReferringAgencyContact" class="form-select form-select-sm">
                    <option value="">-- Select Contact --</option>
                    <template v-for="contact in agency.contacts">
                        <option :value="contact.uuid">{{ contact.name }}</option>
                    </template>
                </select>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <Button size="small" :loading="updating" @click="update">Save</Button>
                <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "EditClientReferringAgency"
}
</script>
<script setup>
import axios from "axios";
import {ref, onBeforeMount, watch, computed} from "vue";
import Button from "../Button.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const agency = ref(null)
const selectedAgencyUuid = ref(null)
const selectedContactUuid = ref(null)

const agencies = ref(null)
const getAgencies = function () {
    axios
        .get(route('api.agencies.index'))
        .then((response) => {
            agencies.value = response.data
        })
        .catch((error) => {
            console.error(error)
        })
}

watch(selectedAgencyUuid, function (newValue) {
    if (!agencies.value) {
        return null
    }
    agency.value = agencies.value.find(agency => agency.uuid === newValue)
})

const updating = ref(false)
const errors = ref({})
const update = function () {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            referring_agency: selectedAgencyUuid.value,
            referring_contact: selectedContactUuid.value,
        })
        .then((response) => {
            emit('updated', response.data)
            editAgencyForm.value = false
            agency.value = response.data.agency
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

const editAgencyForm = ref(false)

const initialiseData = function () {
    selectedAgencyUuid.value = props.client.agency ? props.client.agency.uuid : null
    selectedContactUuid.value = props.client.agency_contact ? props.client.agency_contact.uuid : null
}

const cancelChanges = function () {
    initialiseData()
    editAgencyForm.value = false
}

onBeforeMount(() => {
    agency.value = props.client.agency
    initialiseData()
    getAgencies()
})

</script>
