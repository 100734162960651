<template>
    <div>
        <button class="btn btn-primary" @click="createFundingTypeModal.show()">
            <i class="mdi mdi-plus-circle me-1"></i>
            Add Funding Type
        </button>
        <div id="createFundingTypeModal" aria-hidden="true" aria-labelledby="createFundingTypeModalLabel"
             class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createFundingTypeModalLabel" class="modal-title">Create Funding Type</h5>
                        <button aria-label="Close" class="btn-close" @click="closeModal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <FundingTypeEditOrCreateForm v-model="newFundingType"/>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" @click="closeModal" type="button">Close</button>
                        <Button :loading="storing" @click="storeFundingType">Create</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateFundingType"
}
</script>
<script setup>
import {onMounted, ref, inject} from "vue";
import {uuid} from "vue-uuid";
import Button from "../Button.vue";
import FundingTypeEditOrCreateForm from "./FundingTypeEditOrCreateForm.vue";

const toast = inject('toast')
const axios = inject('axios')

const createFundingTypeModal = ref(null)
const newFundingType = ref({})

const emit = defineEmits(['created'])

const initFields = function () {
    newFundingType.value = {
        name: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        code: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        description: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: false,
        },
        yearly_target_amount: {
            uuid: uuid.v4(),
            value: null,
            errors: [],
            required: false,
        },
    }
}
initFields()

const closeModal = () => {
    createFundingTypeModal.value.hide()
    initFields()
}

onMounted(() => {
    const modal = document.getElementById('createFundingTypeModal');
    createFundingTypeModal.value = new bootstrap.Modal(modal)
    modal.addEventListener('hidden.bs.modal', function (event) {
        initFields()
    })
})

const storing = ref(false)
const storeFundingType = function () {
    storing.value = true

    axios
        .post(route('api.funding-types.store'), {
            code: newFundingType.value.code.value,
            name: newFundingType.value.name.value,
            description: newFundingType.value.description.value,
            yearly_target_amount: newFundingType.value.yearly_target_amount.value,
            approved_payers: newFundingType.value.approved_payers?.map(i => i.uuid) ?? [],
        })
        .then(function (response) {
            createFundingTypeModal.value.hide()
            emit('created', response.data)
            initFields()
        })
        .catch(function (error) {
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                newFundingType.value[field].errors = errors
            }
        })
        .then(function () {
            storing.value = false
        })
}

</script>
