<template>
    <Avatar :name="driver.name" :avatar="driver.avatar" :size="size"></Avatar>
</template>

<script>
import Avatar from "../Avatar.vue";
export default {
    name: "DriverAvatar",
    components: {
        Avatar,
    },
    data() {
        return {
            showInitials: true
        }
    },
    props: {
        driver: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'medium',
        }
    },
}
</script>

<style scoped>

</style>
