import { defineStore } from "pinia";
import { ref } from "vue";
import { BookingTag, BookingTagInterface } from '@classes/BookingTag';
import {BookingInterface} from "@classes/Booking";

export const useBookingTagStore = defineStore("bookingTag", () => {
    const draft = ref<BookingTagInterface>({
        code: '',
        description: '',
    });

    const tag = ref<BookingTagInterface>();
    const tags = ref<BookingTagInterface[]>();

    const loadBookingTag = async (uuid) => {
        let result = await BookingTag.get(uuid);
        if (result) {
            tag.value = result;
        }
        return result;
    };

    const loadBookingTags = async () => {
        return new Promise((resolve, reject) => {
            BookingTag.index().then((response) => {
                tags.value = response.data.data;
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const loadBookingTagPages = async (page:1) => {
        let result = await BookingTag.getPage(page);
        if (result) {
            tags.value = result.data;
        }
        return result;
    };

    const createBookingTag = async (draft: BookingTagInterface) => {
        let result = await BookingTag.create(draft);
        if (result) {
            tag.value = result;
        }
        return result;
    };

    const updateBookingTag = async (update: BookingTagInterface) => {
        let result = await BookingTag.update(update);
        if (result) {
            tag.value = result;
        }
        return result;
    };

    const destroyBookingTag = async (uuid: string) => {
        return BookingTag.destroy(uuid);
    };

    return {
        draft,
        tag,
        tags,
        loadBookingTag,
        loadBookingTags,
        loadBookingTagPages,
        createBookingTag,
        updateBookingTag,
        destroyBookingTag,
    };
})
