<template>
    <template v-if="loading">
        <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
            <div class="spinner-grow me-3" role="status"></div>
            <div class="fs-4">Loading Requirements</div>
        </div>
    </template>
    <div :class="{ hidden: loading }">
        <ClientBookingDefaults
            :client="client"
            @updated="updateClient"
            :readOnly="!permissions.includes('update_client') || client.deactivated"
            :bookingServiceTypes="bookingServiceTypes"
        />
        <EditTransportRequirements @saving="save" :booking-requirements="bookingRequirements" />
    </div>

</template>

<script setup lang="ts">
import {inject, onBeforeMount, onMounted, ref, toRef} from "vue";
import ClientBookingDefaults from "@components/Clients/ClientBookingDefaults.vue";
import EditAssistanceRequirements from "@components/Clients/EditAssistanceRequirements.vue";
import EditTransportRequirements from "@components/Clients/EditTransportRequirements.vue";
import EditCargoRequirements from "@components/Clients/EditCargoRequirements.vue";
import BookingRequirement from "@classes/BookingRequirement";
import axios from "axios";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import {storeToRefs} from "pinia";
import SeatType from "@classes/SeatType";
import VehicleGroup from "@classes/VehicleGroup";
import AssistanceType from "@classes/AssistanceType";
import CargoSpace from "@classes/CargoSpace";

const toast: any = inject("toast");
const props = withDefaults(
    defineProps<{
        client: App.Models.Client,
        bookingServiceTypes: App.Models.BookingServiceType[],
    }>(),
    {}
);
const client = toRef(props, "client");
const permissions = ref(window.permissions);
const bookingRequirements = ref([]);
const cargoSpace = ref([]);
const assistanceTypes = ref([]);
const loading = ref(true);
const bookingRequirementStore = useBookingRequirementStore();

const {
    seatTypeOptions,
} = storeToRefs(bookingRequirementStore);

const getSeatTypeOption = async () => {
    bookingRequirementStore.setSeatTypeOptions(await SeatType.getAll());
}

const getVehicleGroupOption = () => {
    VehicleGroup.getAll().then((response) => {
        bookingRequirementStore.setVehicleGroupOptions(response.data.data);
    });
}

const getAssistanceTypeOption = async () => {
    bookingRequirementStore.setAssistanceTypeOptions(await AssistanceType.options());
}

const getCargoOption = async () => {
    bookingRequirementStore.setCargoSpaceOptions(await CargoSpace.options());
}

const updateClient = function (updatedClientData) {
    client.value = updatedClientData.data;
}

const save = (payload) => {
    axios.put(route('api.clients.booking-requirements.update', {client: client.value.uuid}),
        payload
    ).then((response) => {
            toast.success('Successfully save booking requirement.')
        })
        .catch((error) => {
            if (error.response.status === 422) {
                toast.error(error.response.data.message)
            } else {
                toast.error('There was an error saving booking requirements.')
            }
            console.error(error)
        })
        .finally(() => {
            /*updating.value = false*/
        })
}

const getClientBookingRequirements = () => {
    BookingRequirement.getClientDefaultBookingRequirement(client.value.uuid)
    .then((response) => {
        bookingRequirements.value = response.data.data;
        loading.value = false;
    });
}

onBeforeMount(() => {
    Promise.all([
        getVehicleGroupOption(),
        getSeatTypeOption(),
        getAssistanceTypeOption(),
        getCargoOption()
    ])
        .then(function(values) {
            getClientBookingRequirements()
        }.bind(this));

});

onMounted(() => {
})

</script>

<style scoped>
    .hidden {
        display: none;
    }
</style>
