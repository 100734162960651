<template>
    <div>
        <qrcode-vue :value="value" :size="size" :level="level"></qrcode-vue>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
    name: "QrCode",
    props: {
        value: String,
        size: {
            type: Number,
            required: false,
            default: 200,
        },
        level: {
            type: String,
            required: false,
            default: 'M',
        }
    },
    components: {
        QrcodeVue,
    },
}
</script>

<style scoped>

</style>
