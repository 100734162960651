<template>
    <div>
        <div class="row mb-2">
            <div class="col-md-6">
                <Button @click="openBookingTagModal('')">
                    <i class="mdi mdi-plus-circle me-1"></i> Add Booking Tag
                </Button>
            </div>
            <div class="col-md-6 text-end"></div>
        </div>
        <div class="card">
            <div class="card-body">
                <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                    <thead>
                    <tr>
                        <th class="sorting">Code</th>
                        <th class="sorting text-end">Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="tag in tags">
                        <td><a href="javascript:void(0);" class="link-info" v-on:click="openBookingTagModal(tag.uuid)">{{
                                tag.code
                            }}</a></td>
                        <td class="text-end">{{ tag.description }}</td>
                    </tr>
                    </tbody>
                </table>
                <Pagination
                    v-if="!isLoading"
                    @pageChanged="loadBookingTags"
                    :pagination="paginationMeta"
                    record-name="tags"
                ></Pagination>
            </div>
        </div>
        <div v-if="showBookingTagModal" class="modal fade show d-block" tabindex="-1" aria-describedby="Booking Tag Modal" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createBookingTab" class="modal-title">
                            {{ form.uuid ? `Edit booking tag ${form.code.value}` : 'Create booking tab'}}</h5>
                        <button @click.prevent="closeBookingTagModal" aria-label="Close" class="btn-close"
                                type="button"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <FormTextInput
                                    v-if="form"
                                    v-model:input="form.code"
                                    v-bind:validation="yup.string().max(255).required()"
                                    label="Code"
                                    placeholder="e.g. ABC"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <FormTextarea
                                    v-if="form"
                                    v-model:input="form.description"
                                    v-bind:validation="yup.string().max(255).optional()"
                                    label="Description"
                                    placeholder="e.g. Petrol Voucher"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button @click.prevent="closeBookingTagModal" color="light">Cancel</Button>
                        <Button @click.prevent="deleteBookingTag" color="danger" v-if="form.uuid">Delete tag</Button>
                        <Button @click.prevent="saveBookingTag" v-bind:loading="isLoading">Save tag</Button>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop show" style="--bs-backdrop-zindex: -1"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {inject, onBeforeMount, ref} from 'vue'
import {BookingTagInterface} from '@classes/BookingTag'
import {useBookingTagStore} from '@stores/BookingTagStore'
import * as yup from "yup";
import Button from "@components/Button.vue";
import {FormTextInput, FormTextarea} from "@components/Forms";
import {transformInput} from "@components/Utils";
import {each} from "lodash";
import get from "lodash.get";
import {AxiosError, HttpStatusCode} from "axios";
import Pagination from "@components/Pagination.vue";

const toast: any = inject('toast')
const bookingTagStore = useBookingTagStore()
const tags = ref<BookingTagInterface[]>();
const tag = ref<BookingTagInterface>();
const showBookingTagModal = ref<boolean>(false)
const isLoading = ref<boolean>(false);
const paginationMeta = ref({})

const form = ref({
    uuid: tag.value?.uuid,
    code: transformInput({value: tag.value?.code}),
    description: transformInput({value: tag.value?.description}),
})

onBeforeMount(async () => {
    await loadBookingTags();
})

const loadBookingTags = async (link = null) => {
    isLoading.value = true
    let result = await bookingTagStore.loadBookingTagPages(get(link, 'page', 1));
    tags.value = result.data;
    paginationMeta.value = result.meta
    isLoading.value = false
}

const openBookingTagModal = async (uuid: string = '') => {
    if (uuid === '') {
        tag.value = {code: "", description: ""};
    } else {
        await bookingTagStore.loadBookingTag(uuid);
        tag.value = bookingTagStore.tag;
    }
    form.value.uuid = tag.value?.uuid;
    form.value.code = transformInput({value: tag.value?.code});
    form.value.description = transformInput({value: tag.value?.description});
    showBookingTagModal.value = true;
};

const closeBookingTagModal = () => {
    showBookingTagModal.value = false;
};

const saveBookingTag = async () => {
    isLoading.value = true
    if (tag.value?.uuid) {
        await updateBookingTag({uuid: tag.value.uuid, code: form.value.code.value, description: form.value.description.value})
    } else {
        await createBookingTag({code: form.value.code.value, description: form.value.description.value})
    }
    isLoading.value = false
};

const createBookingTag = async (draft: BookingTagInterface) => {
    isLoading.value = true
    try {
        await bookingTagStore.createBookingTag(draft);
        await loadBookingTags();
        closeBookingTagModal();
    } catch (e: any) {
        handleRequestErrors(e)
    }
    isLoading.value = false
}

const updateBookingTag = async (tag: BookingTagInterface) => {
    isLoading.value = true
    try {
        await bookingTagStore.updateBookingTag(tag);
        await loadBookingTags();
        closeBookingTagModal();
    } catch (e: any) {
        handleRequestErrors(e)
    }
    isLoading.value = false
}

const deleteBookingTag = async () => {
    if (!tag.value?.uuid) {
        closeBookingTagModal();
        return;
    }
    try {
        await bookingTagStore.destroyBookingTag(tag.value.uuid);
        await loadBookingTags();
        closeBookingTagModal();
    } catch (e: any) {
        handleRequestErrors(e)
    }
}

const handleRequestErrors = (e:any) => {
    if(get(e, 'response.status') === HttpStatusCode.UnprocessableEntity) {
        each(e.response.data.errors, (error, key) => {
            form.value[key].errors = error
        })
    } else {
        toast.error(e.message)
    }
}

</script>
