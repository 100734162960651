<template>
    <div>
        <BookingStopDropdown
            :label="
                selectedClient
                    ? `Payment Details For ${selectedClient?.name}`
                    : 'Payment Details'
            "
            :initiallyOpen="true"
            :disabled="bookingStore.draft.selectedClientUuid == null"
        >
            <template v-slot:label class="flex-fill"></template>
            <template v-slot:content>
                <div class="p-3" v-if="selectedClient">
                    <BookingFormTags v-model="tagsForClient" />

                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">Funding Type</label>
                            <select
                                class="form-control"
                                v-model="FundingTypeUuid"
                                :disabled="useClientFunding"
                            >
                                <option
                                    v-for="fundingType in displayableFundingTypesForClient"
                                    :value="fundingType.uuid"
                                >
                                    {{ fundingType.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Payment Method</label>
                            <select
                                class="form-select flex-1"
                                v-model="fareCollectionMethod"
                            >
                                <option
                                    v-for="methodKey in Object.keys(
                                        fareCollectionMethods
                                    )"
                                    :value="methodKey"
                                >
                                    {{ fareCollectionMethods[methodKey] }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Payer</label>
                            <select
                                class="form-select flex-1"
                                v-model="payerUuid"
                            >
                                <option :value="null">
                                    -- Select Payer --
                                </option>
                                <option
                                    v-for="payer in displayablePayersForClient"
                                    :value="payer.uuid"
                                >
                                    {{ payer.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div
                            class="col-md-4"
                            :class="[
                                !selectedClient.funding_type &&
                                !useClientFunding
                                    ? 'pe-none'
                                    : '',
                            ]"
                            :style="[
                                !selectedClient.funding_type &&
                                !useClientFunding
                                    ? 'opacity: 0.5'
                                    : '',
                            ]"
                        >
                            <FormSwitch
                                label="Use Client Funding"
                                v-model="useClientFunding"
                            />
                        </div>
                        <div class="col-md-4 d-flex justify-content-center">
                            <FormSwitch
                                label="Include GST"
                                v-model="includeGST"
                            />
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <FormSwitch
                                label="Manually Enter Fee"
                                v-model="manualFee"
                            />
                        </div>
                    </div>

                    <div v-if="manualFee" class="row mt-3">
                        <div class="col-md-12">
                            <FormDollarInput
                                v-model:input="manualFeeAmountInput"
                                label="Manual Fee"
                                optional
                                placeholder="e.g. 5.50"
                                step=".01"
                            ></FormDollarInput>
                        </div>
                    </div>
                </div>
            </template>
        </BookingStopDropdown>
        <div
            v-if="bookingFormErrors.fundingType"
            class="alert alert-danger mt-3"
        >
            <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
            {{ bookingFormErrors.fundingType }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import BookingStopDropdown from "./BookingStopDropdown.vue";
import FormSwitch from "../../Forms/FormSwitch.vue";
import { computed, onMounted, watch, ref } from "vue";
import FormDollarInput from "../../Forms/FormDollarInput.vue";
import BookingFormTags from "./BookingFormTags.vue";
import { BookingTagInterface } from "@/resources/js/classes/BookingTag";
const props = defineProps<{
    stopIndex: number;
}>();

const bookingStore = useBookingStore();

// const payer = ref<App.Models.Payer | undefined>();

const {
    bookingFormErrors,
    selectedClient,
    fundingTypes,
    fareCollectionMethods,
    payers,
} = storeToRefs(bookingStore);

const displayableFundingTypesForClient = computed(() => {
    return fundingTypes.value.filter((fundingType) => {
        return selectedClient.value?.approved_funding_types?.find(
            (approvedFundingType) =>
                approvedFundingType.uuid == fundingType.uuid
        );
    });
});

const displayablePayersForClient = computed(() => {
    let selectedApprovedFundingTypeWithDefaultPivot =
        selectedClient.value?.approved_funding_types?.find(
            (approvedFundingType) =>
                approvedFundingType.uuid == FundingTypeUuid.value
        );

    let selectedApprovedFundingTypeFullDetails = fundingTypes.value?.find(
        (approvedFundingType) =>
            approvedFundingType.uuid == FundingTypeUuid.value
    );

    if (
        !selectedApprovedFundingTypeWithDefaultPivot ||
        !selectedApprovedFundingTypeFullDetails
    ) {
        return [];
    }

    let selectedFundingType = {
        ...selectedApprovedFundingTypeWithDefaultPivot,
        ...selectedApprovedFundingTypeFullDetails,
    };

    //@ts-ignore
    if (selectedFundingType?.pivot?.default_payer_id) {
        return payers.value.filter((payer) => {
            //@ts-ignore
            return payer.id == selectedFundingType?.pivot?.default_payer_id;
        });
    }

    if (
        (selectedFundingType.approved_payers ?? []).length > 0 &&
        selectedFundingType.approved_payers
    ) {
        return selectedFundingType.approved_payers;
    }

    return payers.value;
});

const FundingTypeUuid = bookingStore.getComputedForClientSpecificValue<
    string | undefined
>("fundingTypeUuid");

const fareCollectionMethod = bookingStore.getComputedForClientSpecificValue<
    null | string
>("fareCollectionMethod");

const payerUuid = bookingStore.getComputedForClientSpecificValue<null | string>(
    "payerUuid",
    null
);

const manualFee = bookingStore.getComputedForStopClientSpecificValue<boolean>(
    props.stopIndex,
    "manualFee"
);

const tagsForClient = bookingStore.getComputedForStopClientSpecificValue<
    BookingTagInterface[]
>(props.stopIndex, "tags", []);

const manualFeeAmount =
    bookingStore.getComputedForStopClientSpecificValue<number>(
        props.stopIndex,
        "manualFeeAmount",
        0
    );

const manualFeeAmountInput = computed({
    get() {
        return {
            value: manualFeeAmount.value,
            required: false,
            valid: true,
            errors: [],
        };
    },
    set(newValue) {
        manualFeeAmount.value = newValue.value;
    },
});

const includeGST = bookingStore.getComputedForStopClientSpecificValue<boolean>(
    props.stopIndex,
    "includeGST"
);

const useClientFunding =
    bookingStore.getComputedForClientSpecificValue<boolean>("useClientFunding");

const setSelectedPayer = () => {
    //If a payer already is selected that belongs to the list they are able to select then skip all of this
    if (
        payerUuid.value &&
        displayablePayersForClient?.value.find(
            (payer) => payer.uuid == payerUuid.value
        )
    ) {
        return;
    }
    //Only auto assign if they have one approved payer for the funding type, otherwise leave it blank
    if (displayablePayersForClient?.value.length == 1) {
        let newPayer = displayablePayersForClient?.value?.[0];
        payerUuid.value = newPayer?.uuid ?? null;
    } else {
        payerUuid.value = null;
    }
};

const setDefaultValues = () => {
    if (!FundingTypeUuid.value && selectedClient?.value) {
        FundingTypeUuid.value = selectedClient?.value?.funding_type?.uuid;
    }

    if (typeof manualFee.value == "undefined" && selectedClient?.value) {
        manualFee.value = false;
    }

    if (typeof includeGST.value == "undefined" && selectedClient?.value) {
        includeGST.value = false;
    }

    if (typeof useClientFunding.value == "undefined" && selectedClient?.value) {
        useClientFunding.value = true;
    }

    setSelectedPayer();

    if (
        !fareCollectionMethod.value &&
        selectedClient?.value &&
        Object.keys(fareCollectionMethods.value).length > 0
    ) {
        if (selectedClient?.value?.default_fare_collection_method) {
            fareCollectionMethod.value =
                selectedClient?.value?.default_fare_collection_method;
        } else {
            fareCollectionMethod.value = Object.keys(
                fareCollectionMethods.value
            )[0];
        }
    }
};

watch(useClientFunding, () => {
    if (useClientFunding.value) {
        FundingTypeUuid.value = selectedClient?.value?.funding_type?.uuid;
    }
});

onMounted(() => {
    setDefaultValues();
});

watch(selectedClient, () => {
    setDefaultValues();
});

watch(displayablePayersForClient, () => {
    setSelectedPayer();
    setDefaultValues();
});

watch(fareCollectionMethods, () => {
    setDefaultValues();
});

watch(FundingTypeUuid, () => {
    setSelectedPayer();
});
</script>
