import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";
import {PayerResource} from "@customTypes/resources/PayerResource";

export default class Payer {
    static async index() {
        return axios.get<LaravelResourceResponse<PayerResource[]>>(
            route("api.payers.index")
        );
    }
}
