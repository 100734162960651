<template>
    <FullCalendar :options="calendarOptions"></FullCalendar>
</template>

<script>
export default { name: "Calendar" }
</script>
<script setup>
import {ref, onBeforeMount} from "vue";
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

const calendarOptions = ref({})

const props = defineProps({
    height: {
        type: String,
        required: false,
        default: '400px',
    },
    events: {
        type: Array,
        required: false,
        default() {
            return []
        }
    }
})

const emit = defineEmits(['eventClicked', 'selected'])

const setCalendarOptions = function () {
    calendarOptions.value = {
        locale: 'en-au',
        allDaySlot: false,
        events: props.events,
        eventClick: handleEventClicked,
        headerToolbar: {
            start: 'dayGridMonth,timeGridWeek',
            center: 'title',
        },
        height: props.height,
        initialView: 'timeGridWeek',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        select: handleSelect,
        selectable: true,
        nowIndicator: true,
    }
}

const handleEventClicked = (event) => { emit('eventClicked', event) }
const handleSelect = (selected) => { emit('selected', selected) }

onBeforeMount(() => {
    setCalendarOptions()
})
</script>

<style scoped>
</style>
