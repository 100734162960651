<template>
    <div>
        <img
            :src="'/images/vehicles/'+ vehicle.image  +'.png'"
            :style="'max-width: ' + maxWidth"
            alt="Vehicle Icon"
            class="img-fluid me-3"
        >
    </div>
</template>

<script>
export default {
    name: "VehicleImage"
}
</script>
<script setup>
import {computed} from 'vue';

const props = defineProps({
    vehicle: {
        type: Object,
        required: true,
    },
    size: {
        type: String,
        required: false,
        default: 'medium',
    }
})

const maxWidth = computed(() => {
    switch (props.size) {
        case 'small':
            return '50px'
        case 'medium':
            return '100px'
        case 'large':
            return '200px'
    }
})
</script>

<style scoped>

</style>
