<template>
    <div>
        <div class="row">
            <div class="col">
                <h5 class="text-uppercase">Invoices</h5>
                View booking costs and pending payments.
            </div>
        </div>

        <div class="row mt-3">
                <div class="col">
                    <PageLengthSelector record-name="invoices" store-name="InvoiceStore"/>
                </div>
                <div class="col text-end">
                    <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                        <button
                            class="btn btn-light btn-sm dropdown-toggle"
                            type="button"
                            id="dropDownFilterClients"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <SvgIcon type="mdi" :path="mdiFilter" class="w-4 h-4 me-1"></SvgIcon>
                            Filter
                        </button>
                        <div class="dropdown-menu px-4 py-3" style="min-width: 500px;"
                             aria-describedby="dropDownFilterBookings">
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Status:</label>
                                <VueMultiselect
                                    v-model="invoiceStore.filters.statuses"
                                    :options="invoiceStore.defaultStatuses"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Statuses"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Collection method:</label>
                                <VueMultiselect
                                    v-model="invoiceStore.filters.collectionMethods"
                                    :options="invoiceStore.defaultCollectionMethods"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Collection Methods"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2 text-end">
                                <Button title="Apply" @click="invoiceStore.getAllInvoices(invoiceStore.filters)" color="light">Apply</Button>
                            </div>
                        </div>
                    </div>
                    <DataTableSearch
                        @search="invoiceStore.getAllInvoices(invoiceStore.filters)"
                        placeholder="Search invoices ..."
                        store="InvoiceStore"
                    />
                </div>
            </div>
        <template v-if="invoiceStore.isLoading">
            <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
                <Loading spinner-class="spinner-grow" text="Loading invoices and charges" text-position="after" direction="horizontal"/>
            </div>
        </template>
        <template v-else-if="!invoiceStore.invoices || invoiceStore.invoices?.length === 0">
            <div class="col text-center mt-4 mb-3">
                <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiReceipt"></SvgIcon>
                <div class="fs-4 my-2">{{ client.preferred_name ?? client.given_names }} has no invoices/charges.</div>
            </div>
        </template>
        <template v-else>
                <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                        <th class="td-shrink text-nowrap"><span class="visually-hidden">Status</span></th>
                        <SortableColumn param="uuid" store="InvoiceStore">Invoice No</SortableColumn>
                        <SortableColumn param="created_at" store="InvoiceStore">Date created</SortableColumn>
                        <SortableColumn param="total" store="InvoiceStore">Amount</SortableColumn>
                        <th class="text-nowrap">Collection Method</th>
                        <SortableColumn param="due_at" store="InvoiceStore">Due</SortableColumn>
                        <th class="text-nowrap">Transaction</th>
                        <th>
                            <span class="visually-hidden">Void</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="invoice in invoiceStore.invoices">
                        <tr :class="['void', 'cancelled'].includes(invoice.status) ? 'text-decoration-line-through' : ''">
                            <td class="td-shrink">
                                <InvoiceStatus :status="invoice.status"/>
                            </td>
                            <td class="text-nowrap">
                                <a href="javascript: void(0)" @click="openInvoiceDetails(invoice)">{{ invoice.uuid }}</a>
                            </td>
                            <td class="text-nowrap">{{ DateTime.fromSeconds(invoice.created_at).toLocaleString(DateTime.DATETIME_MED) }}
                            </td>
                            <td>{{ formatCurrency(invoice.total) }}</td>
                            <td class="text-capitalize">{{ invoice.collection_method }}</td>
                            <td class="text-nowrap">
                                <span :class="{ 'text-danger' : invoice.due_at < DateTime.now().toSeconds() && invoice.status === 'unpaid'} " v-if="invoice.due_at">
                                    {{ DateTime.fromSeconds(invoice.due_at).toLocaleString(DateTime.DATE_MED) }}
                                </span>
                            </td>
                            <td class="list"><span v-for="transaction in invoice.transactions"><a :href="route('transactions.index')" >{{ transaction.uuid }}</a></span></td>
                            <td class="text-end">
                                <InvoiceActionsDropdown
                                    :invoice="invoice"
                                    @edit="editInvoice(invoice)"
                                    @sync="syncInvoice(invoice)"
                                    @approve="invoiceStore.invoice = invoice; approveInvoiceModal?.show()"
                                    @void="invoiceStore.invoice = invoice; voidInvoiceModal?.show()"
                                ></InvoiceActionsDropdown>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                <Pagination v-if="!invoiceStore.isLoading" :pagination="paginatedMeta" record-name="invoices"></Pagination>
            </template>

    </div>

    <InvoiceDetails
        v-if="selectedInvoice"
        :show="!isNull(selectedInvoice)"
        :invoice="selectedInvoice"
        :key="selectedInvoice ? selectedInvoice.uuid : uuid.v4()"
        @close="closeInvoiceDetails"
    />

    <ConfirmationModal id="voidInvoiceModal" @confirmed="voidInvoice()">
        <template #title>VOID INVOICE</template>
        <template #text v-if="invoiceStore.invoice">
            <div>Once voided, this invoice cannot be edited and the invoice will be removed from the system.</div>
            <p>Are you sure you want to void this invoice?</p>
            <label class="form-label">Reason to Void</label>
            <textarea class="form-control" placeholder="" v-model="invoiceStore.invoice.void_reason"></textarea>
            <template v-if="invoiceStore.invoice.status === 'paid'">
                <p>This will create a new transaction and credit the client the below amount.</p>
                <dl>
                    <dt>Amount to credit:</dt>
                    <dd>{{ formatCurrency(invoiceStore.invoice.total) }}</dd>
                </dl>
            </template>
        </template>
        <template #confirm-button-text>Void</template>
    </ConfirmationModal>

    <ConfirmationModal id="approveInvoiceModal" @confirmed="approveInvoice()">
        <template #title>APPROVE INVOICE</template>
        <template #text>
            <div>Once approved, this invoice cannot be further edited.</div>
        </template>
        <template #confirm-button-text>Approve</template>
    </ConfirmationModal>

</template>

<script setup lang="ts">
import {formatCurrency} from "@classes/Helpers/Currency";
import {Loading} from "@components";
import Button from "@components/Button.vue";
import ConfirmationModal from "@components/ConfirmationModal.vue";
import InvoiceActionsDropdown from "@components/Invoices/InvoiceActionsDropdown.vue";
import {ToastInterface} from "@components/toast";
import {ApiError} from "@customTypes/LaravelCommonTypes";
import ClientResource from "@customTypes/resources/ClientResource";
import InvoiceResource from "@customTypes/resources/InvoiceResource";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiFilter, mdiReceipt} from "@mdi/js";
import {useInvoiceStore} from "@stores/InvoiceStore";
import {usePaginationStore} from "@stores/PaginationStore";
import {Modal} from "bootstrap";
import {isNull} from "lodash";
import {DateTime} from "luxon";
import {storeToRefs} from "pinia";
import {inject, onMounted, ref, Ref, watch} from "vue";
import VueMultiselect from "vue-multiselect";
import {uuid} from "vue-uuid";
import DataTableSearch from "../DataTable/DataTableSearch.vue";
import PageLengthSelector from "../DataTable/PageLengthSelector.vue";
import SortableColumn from "../DataTable/SortableColumn.vue";
import InvoiceDetails from "../Invoices/InvoiceDetails.vue";
import InvoiceStatus from "../Invoices/InvoiceStatus.vue";
import Pagination from "../Pagination.vue";

const toast: ToastInterface = inject('toast')!;

const invoiceStore = useInvoiceStore();
const paginationStore = usePaginationStore('InvoiceStore');

const props = defineProps<{ client: ClientResource }>()

const selectedInvoice: Ref<InvoiceResource | null> = ref<InvoiceResource | null>(null);

const storingVoidInvoice = ref(false);

const {paginateParams, paginatedMeta, searchQuery} = storeToRefs(paginationStore);

const voidInvoiceModal = ref<Modal>();
const approveInvoiceModal = ref<Modal>();

const openInvoiceDetails = (invoice: InvoiceResource): void => {
    selectedInvoice.value = invoice;
}

const closeInvoiceDetails = (): void => {
    selectedInvoice.value = null;
}

const getInvoices = async function () {
    try {
        await invoiceStore.getAllInvoices();
    } catch (error: unknown) {
        toast.error(`Failed to load invoices: ${(error as ApiError).response?.data?.message}`);
        console.error(error);
    }
}

const syncInvoice = async function (invoice: InvoiceResource) {
    try {
        await invoiceStore.syncInvoice(invoice);
        toast.success("You successfully requested syncing the invoice **" + invoice.uuid + "**");
        await getInvoices();
    } catch (error: unknown) {
        toast.error(`Failed to sync the invoice ** ${invoice.uuid} **: ${(error as ApiError).response?.data?.message}`);
        console.error(error);
    }
}

const voidInvoice = async () => {
    if (!invoiceStore.invoice) {
        return;
    }
    storingVoidInvoice.value = true;
    try {
        await invoiceStore.voidInvoice(invoiceStore.invoice, invoiceStore.invoice.void_reason ?? '');
        toast.success('Invoice Voided');
        voidInvoiceModal.value?.hide();
        invoiceStore.invoice = undefined;
        await getInvoices();
    } catch (error: unknown) {
        toast.error(`Error voiding invoice: ${(error as ApiError).response.data.message}`);
        console.error(error);
    } finally {
        storingVoidInvoice.value = false;
    }
}

const approveInvoice = async () => {
    if (invoiceStore.invoice) {
        await invoiceStore.approveInvoice(invoiceStore.invoice);
        approveInvoiceModal.value?.hide();
        invoiceStore.invoice = undefined;
    }
}

const editInvoice = (invoice: InvoiceResource) => {
    window.location.href = route('invoices.edit', {invoice: invoice.uuid});
}

onMounted(function () {
    invoiceStore.filters = {
        collectionMethods: invoiceStore.defaultCollectionMethods,
        statuses: invoiceStore.defaultStatuses,
        client: props.client?.uuid
    };

    getInvoices();

    voidInvoiceModal.value = new Modal(document.getElementById('voidInvoiceModal') ?? 'voidInvoiceModal')
    approveInvoiceModal.value = new Modal(document.getElementById('approveInvoiceModal') ?? 'approveInvoiceModal')

});

watch(
    paginationStore.paginateParams,
    () => {
        invoiceStore.getAllInvoices();
    }
)

</script>

<style scoped>
.list span:not(:last-child)::after {
    content: ', ';
}
</style>
