<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="header-title m-0">Certificates</h4>
                <Button v-if="!readOnly" @click.prevent="showDriverCertificateModal" size="small">
                    <SvgIcon type="mdi" :path="mdiPlusCircle" class="w-4 h-4 me-1"></SvgIcon>
                    Add certificate
                </Button>
            </div >
            <ul class="list-group" v-if="driverCertificates.length > 0">
                <template v-for="(certificate, index) in driverCertificates" :key="index">
                    <li class="list-group-item d-flex justify-content-between">
                        <div>
                            <strong>{{ certificate.certificate_type?.name }}</strong> ({{certificate.data.state}})
                            <div>
                                <ClickToReveal :value="certificate.identifier"></ClickToReveal>
                                <small class="ms-2">(expires <ColorContextualDate :date="certificate.expiry"></ColorContextualDate>)</small>
                            </div>
                        </div>
                        <div v-if="!readOnly" class="dropdown">
                            <button
                                class="dropdown-toggle arrow-none card-drop p-2"
                                aria-expanded="false"
                                data-bs-toggle="dropdown"
                                href="#"
                            >
                                <i class="mdi mdi-dots-vertical"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    @click.prevent="showEditModal(certificate)"
                                >Edit Certificate</a>
                                <a
                                    class="dropdown-item text-danger"
                                    href="javascript:void(0);"
                                    @click.prevent="showDeleteModal(certificate)"
                                >Delete Certificate</a>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
            <div v-else>There are currently no certificates saved for this driver.</div>
        </div>
    </div>
    <div
        id="driverCertificateModal"
        class="modal fade"
        aria-hidden="true"
        aria-labelledby="driverCertificateModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="driverCertificateModalLabel">Add a certificate for: {{ driver.name }}</h5>
                    <button @click="closeDriverCertificateModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div :key="formRefreshKey" class="modal-body">
                    <div v-if="!createNewCertificate" class="mb-2">
                        <FormSelect
                            v-model:selection="form.certificate_type"
                            :options="driverCertificateTypes"
                            label="Certificate type"
                            placeholder="Select a certificate type..."
                        >
                        </FormSelect>
                        <div  class="d-flex justify-content-end">
                            <a @click.prevent="toggleCustomCertificate"
                                href="javascript:void(0);"
                                class="mt-1"
                            >Create new</a>
                        </div>
                    </div>
                    <div v-else class="mb-2">
                        <FormTextInput
                            v-model:input="form.certificate_type"
                            label="Create a new certificate type"
                            placeholder="e.g. COVID Test"
                            :validation="yup.string().max(255).required()"
                        >
                        </FormTextInput>
                        <div  class="d-flex justify-content-end pt-1">
                            <Button @click.prevent="toggleCustomCertificate" size="small" color="light">
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div class="mb-3">
                        <FormTextInput
                            v-model:input="form.identifier"
                            label="Identifier"
                            placeholder="e.g. DOC-12345"
                            :validation="yup.string().max(255).required()"
                        ></FormTextInput>
                    </div>
                    <div class="mb-3">
                        <FormDateInput
                            v-model:input="form.expiry"
                            label="Expiry date"
                            :validation="yup.date().required()"
                        />
                    </div>
                    <div class="mb-3">
                        <FormSelect
                            v-model:selection="form.state"
                            :options="australianStates"
                            label="State"
                            placeholder="Select an Australian State..."
                            optional
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <Button @click.prevent="closeDriverCertificateModal" color="light">
                        Cancel
                    </Button>
                    <Button @click.prevent="saveCertificate" :loading="saving" color="primary">
                        Save Certificate
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <ConfirmationModal
        id="deleteDriverCertificateModal"
        confirmation-title="Are You Sure"
        confirmation-button-color="danger"
        @confirmed="deleteDriverCertificate"
    >
        <template #text>
            <div>
                <p>Deleting <strong>{{ certificateInDelete?.certificate_type?.name }}</strong> will permanently remove it.</p>
                <p>Are you sure you wish to proceed?</p>
            </div>
        </template>
        <template #confirm-button-text>
            <Spinner
                v-if="deleting"
                class="me-1"
                color="white"
                size="small"
                type="border"
            /> Delete Certificate
        </template>
    </ConfirmationModal>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import Button from "@components/Button.vue"
import ClickToReveal from "@components/ClickToReveal.vue"
import ColorContextualDate from "@components/ColorContextualDate.vue"
import { mdiPlusCircle } from "@mdi/js"
import SvgIcon from "@jamescoyle/vue-icon"
import { transformInput, extractInputValues } from "@components/Utils.js"
import { FormTextInput, FormSelect, FormDateInput } from "@components/Forms"
import ConfirmationModal from "@components/ConfirmationModal.vue"
import Spinner from "@components/Spinner.vue"
import { DateTime } from 'luxon'
import * as yup from "yup"
import ElmHelper from "@classes/Helpers/ElmHelper";

const axios = inject("axios")
const toast = inject("toast")

const readOnly = ref(!window.permissions.includes('update_driver'))

const props = defineProps({
    driver: {
        type: Object,
        required: true,
    },
});

const driverCertificates = ref(props.driver.certificates)
const createNewCertificate = ref(false)
const toggleCustomCertificate = () => {
    createNewCertificate.value = !createNewCertificate.value
    form.value.certificate_type.value = null
}

const initialiseForm = () => {
    createNewCertificate.value = false

    return {
        certificate_uuid: null,
        certificate_type: transformInput(),
        identifier: transformInput(),
        state: transformInput(),
        expiry: transformInput(),
    }
}

const formRefreshKey = ref(Date.now())
const form = ref(initialiseForm());

const showEditModal = (certificate) => {
    form.value.certificate_uuid = certificate.uuid
    form.value.certificate_type.value = certificate.certificate_type.uuid
    form.value.identifier.value = certificate.identifier
    form.value.state.value = certificate.data.state
    form.value.expiry.value = DateTime.fromISO(certificate.expiry).toFormat("yyyy-MM-dd")
    createNewCertificate.value = false
    formRefreshKey.value = Date.now()
    driverCertificateModal.value.show()
}

const saving = ref(false)
const saveCertificate = () => {
    resetErrors()
    saving.value = true

    const params = {
        certificate_uuid: form.value.certificate_uuid,
        certificate_type: form.value.certificate_type.value,
        identifier: form.value.identifier.value,
        expiry: form.value.expiry.value,
        data: {
            state: form.value.state.value,
        },
    }

    let request = !form.value.certificate_uuid
        ? axios.post(route('api.drivers.certificates.store', { driver: props.driver.uuid }), params)
        : axios.put(route('api.drivers.certificates.update', { driver: props.driver.uuid, certificate: form.value.certificate_uuid }), params )

    request
        .then(response => {
            saving.value = false

            if (!form.value.certificate_uuid) {
                driverCertificates.value.push(response.data)
            } else {
                const index = driverCertificates.value.findIndex(certificate => certificate.uuid === form.value.certificate_uuid)
                driverCertificates.value.splice(index, 1, response.data)
            }

            if (createNewCertificate.value) {
                getDriverCertificateTypes()
            }

            closeDriverCertificateModal()
            toast.success("Certificate saved successfully.")
            form.value = initialiseForm()
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
                for (const [field, errors] of Object.entries(error.response.data.errors)) {
                    const fieldKey = field.replace('data.', '')
                    if (form.value[fieldKey] && form.value[fieldKey].errors !== undefined) {
                        form.value[fieldKey].errors = errors
                    }
                }
            } else {
                toast.error("An unknown error occurred while saving the certificate.")
            }
        }).finally(() => {
            saving.value = false
        })
}

const resetErrors = () => {
    form.value.certificate_type.errors = []
    form.value.identifier.errors = []
    form.value.state.errors = []
    form.value.expiry.errors = []
}

const showDriverCertificateModal = () => {
    driverCertificateModal.value.show()
};

const closeDriverCertificateModal = () => {
    driverCertificateModal.value.hide()
    form.value = initialiseForm()
    formRefreshKey.value = Date.now()
};

const certificateInDelete = ref()
const showDeleteModal = (certificate) => {
    certificateInDelete.value = certificate
    deleteDriverCertificateModal.value.show()
}

const deleting = ref(false)
const deleteDriverCertificate = () => {
    deleting.value = true
    axios.delete(route('api.drivers.certificates.destroy', {
        driver: props.driver.uuid,
        certificate: certificateInDelete.value.uuid,
    }))
        .then(() => {
            driverCertificates.value.splice(driverCertificates.value.indexOf(certificateInDelete.value), 1)
            certificateInDelete.value = null
            deleteDriverCertificateModal.value.hide()
        }).catch(() => {
            toast.error(`An error occurred while deleting ***${certificateInDelete.value.certificate_type.name}***.`)
        }).finally(() => {
            deleting.value = false
        })
}

const australianStates = ref([])
const getAustralianStates = () => {
    axios
        .get(route('api.enums.index', { enum: 'australian-state' }))
        .then(response => {
            australianStates.value = response.data
        })
}

const driverCertificateTypes = ref([])
const getDriverCertificateTypes = () => {
    axios
        .get(route('api.driver-certificate-types.index'))
        .then(response => {
            driverCertificateTypes.value = response.data.data.map(certificate => {
                return {
                    value: certificate.uuid,
                    label: certificate.name,
                }
            })
        })
}

const driverCertificateModal = ref(null)
const deleteDriverCertificateModal = ref(null)
onMounted(() => {
    getAustralianStates()
    getDriverCertificateTypes()
    ElmHelper.waitUntilRendered('#driverCertificateModal').then((elm) => {
        driverCertificateModal.value = new bootstrap.Modal(document.getElementById("driverCertificateModal"));
    });
    ElmHelper.waitUntilRendered('#deleteDriverCertificateModal').then((elm) => {
        deleteDriverCertificateModal.value = new bootstrap.Modal(document.getElementById('deleteDriverCertificateModal'))
    });
});
</script>
