<template>
    <div class="d-flex">
        <div class="me-2">
            <VueMultiselect :options="report.ruleFieldsAvailable" v-model="rule.field">
            </VueMultiselect>
        </div>
        <div class="me-1">
            <select class="form-select" v-model="rule.operator">
                <template v-for="(label, value) in operators">
                    <option :value="value">{{ label }}</option>
                </template>
            </select>
        </div>
        <div v-if="rule.operator === 'is_between' || rule.operator === 'is_not_between'" class="d-flex align-items-center">
            <input type="text" class="form-control" v-model="rule.value" />
            <span class="mx-1">and</span>
            <input type="text" class="form-control" />
        </div>
        <div v-else-if="rule.operator !== 'is_empty' && rule.operator !== 'is_not_empty'">
            <input type="text" class="form-control" v-model="rule.value" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Rule"
}
</script>
<script setup>
import {ref} from "vue";
import {customReportStore} from "@stores/CustomReportStore";
import VueMultiselect from "vue-multiselect";

const report = customReportStore();

const props = defineProps({
    setId: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    }
})

const operators = window.enums.queryOperators
const rule = ref(report.getRule(props.setId, props.id))

</script>

<style scoped>

</style>
