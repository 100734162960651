<template>
    <div>
        <div class="vehicle-row">
            <div>
                <div v-if="hasBookings" @click="toggleShowStops">
                    <i class="mdi fs-3" :class="showStops ? 'mdi-menu-down' : 'mdi-menu-right'"></i>
                </div>
            </div>
            <div>
                <img :src="'/images/vehicles/'+ ((journey.vehicle.type && journey.vehicle.type.image) ? journey.vehicle.type.image : 'sedan') +'.png'" class="img-fluid" style="max-width: 70px" alt="Vehicle Icon">
            </div>
            <div>
                <div>
                    <span class="me-2" v-if="journey.driver">{{ journey?.driver?.name }}</span>
<!--                    <a-->
<!--                        v-if="journey.driver"-->
<!--                        class="me-2"-->
<!--                        :href="route('crew.show', { crew: journey.driver.uuid })"-->
<!--                    >-->
<!--                        {{ journey.driver.NAME }}-->
<!--                    </a>-->
                    <small v-if="journey.vehicle">{{ journey.vehicle.description }} ({{ journey.vehicle.registration_code }})</small>
                </div>
                <div>
                    <em class="text-muted" v-if="hasBookings">{{ journey.stops.length }} {{ journey.stops.length === 1 ? 'stop' : 'stops' }}</em>
                    <span v-if="!hasBookings" class="badge rounded-pill bg-info">No bookings allocated</span>
                </div>
            </div>
            <div>
                {{journey.uuid}}
            </div>
            <div>
                <DateTime
                    v-if="moment(journey.start_time).format('HH:mm') !== '00:00'"
                    :date-time="journey.start_time"
                    :format="'short time'"
                ></DateTime>
                <template v-if="moment(journey.start_time).format('HH:mm') === '00:00'">-</template>
            </div>
            <div>
                <DateTime
                    v-if="moment(journey.end_time).format('HH:mm') !== '00:00'"
                    :date-time="journey.end_time"
                    :format="'short time'"
                ></DateTime>
                <template v-if="moment(journey.end_time).format('HH:mm') === '00:00'">-</template>
            </div>
            <div>
                <span v-if="!hasBookings"></span>
                <span v-else-if="status === 'on time'" class="badge rounded-pill bg-success">{{ status }}</span>
                <span v-else-if="status === 'completed'" class="badge rounded-pill bg-info text-dark">{{ status }}</span>
                <span v-else-if="status === 'on break'" class="badge rounded-pill bg-secondary text-light">{{ status }}</span>
                <span v-else class="badge rounded-pill bg-warning text-dark">{{ status }}</span>
            </div>
            <div class="d-flex flex-shrink-1 fs-3 justify-content-end" v-if="journey.driver">
                <div>
                    <a @click="startNewChat(journey.driver)" v-if="enabledFeatures.includes('driver_chat')" class="link-secondary" href="javascript: void(0)">
                        <SvgIcon type="mdi" :path="messageIcon"></SvgIcon>
                    </a>
                </div>
                <div>
                    <a v-if="journey.driver.phone" :href="$filters.phone_url(journey.driver.phone)" class="link-secondary"><i class="mdi mdi-phone"></i></a>
                </div>
<!--                <div><i class="mdi mdi-map"></i></div>-->
                <div>
                    <a href="javascript: void(0)" @click="editVehicleRun" class="link-secondary">
                        <SvgIcon type="mdi" :path="editIcon"></SvgIcon>
                    </a>
                </div>
                <div>
                    <a href="javascript: void(0)" @click="filterVehiclePosition(journey.uuid)">
                        <SvgIcon type="mdi" :path="targetIcon"></SvgIcon>
                    </a>
                </div>
            </div>
            <div v-else class="d-flex flex-shrink-1 fs-3 justify-content-end"></div>
        </div>

        <div v-if="journey.stops.length > 0 && hasBookings" class="stops-rows" :class="'vehicle-' + uuid">
            <div class="vehicle-information">
                Availability: <DateTime :date-time="journey.start_time" format="short time"></DateTime> until <DateTime :date-time="journey.end_time" format="short time"></DateTime><br>
            </div>
            <div class="tl-container my-3">
                <div v-for="stop in journey.stops" class="tl-item" :class="{ 'tl-complete': Array.isArray(stop) ? stop[0].completed : stop.completed }" :key="stop">
                    <div class="tl-dot"></div>
                    <div class="tl-content w-100 stop-row">
                        <div v-if="stop.booking?.status === 'cancelled'">
                            <i class="mdi mdi-cancel"></i>
                        </div>
                        <div v-else :class="crossClass(stop)" @click="showRevertBookingModal(stop)">
                            <i v-if="stop.type === 'depart_depot'" class="mdi mdi-airplane-takeoff"></i>
                            <i v-if="stop.type === 'pickup' && !stop.booking.group_booking_identifier" class="mdi mdi-account-plus text-info"></i>
                            <i v-if="stop.type === 'pickup' && stop.booking.group_booking_identifier" class="mdi mdi-account-multiple-plus text-info"></i>
                            <i v-if="stop.type === 'break'" class="mdi mdi-coffee"></i>
                            <i v-if="stop.type === 'dropOff' && !stop.booking.group_booking_identifier" class="mdi mdi-account-minus-outline text-warning"></i>
                            <i v-if="stop.type === 'dropOff' && stop.booking.group_booking_identifier" class="mdi mdi-account-multiple-minus-outline text-warning"></i>
                            <i v-if="stop.type === 'return_depot'" class="mdi mdi-airplane-landing"></i>
                        </div>
                        <div>
                            <template v-if="stop.completed_at || stop.scheduled_time || stop.arrive_no_later_than || stop.depart_no_later_than">
                                <div :class="{'fw-bold': stop.completed_at !== null}">
                                    <DateTime :date-time="stop.completed_at ? stop.completed_at : (stop.scheduled_time ? stop.scheduled_time : (stop.arrive_no_later_than ? stop.arrive_no_later_than : stop.depart_no_later_than))" format="short time"></DateTime>
                                </div>
                            </template>
                            <template v-else-if="stop.booking">
                                <div v-tooltip="'Estimated time'">
                                    <DateTime
                                        :date-time="stop.type === 'pickup' ? (stop.booking.planned_origin_time ?? stop.booking.estimated_origin_time) : (stop.booking.planned_destination_time ?? stop.booking.estimated_destination_time)"
                                        format="short time"
                                    ></DateTime>
                                </div>
                            </template>
                        </div>
                        <div>
                            <template v-if="stop.type === 'depart_depot'">Depart depot</template>
                            <template v-else-if="stop.type === 'return_depot'">Return to depot</template>
                            <template v-else-if="stop.type === 'break'">
                                {{ moment.duration(moment(stop.end_time).diff(moment(stop.arrive_no_later_than))).asMinutes() }} minute break
                            </template>
                            <template v-else>
                                <div class="d-flex">
                                    <div class="small me-2" v-if="stop.booking">{{  stop?.booking?.client?.full_name }}</div>
                                    <div class="small me-2" v-if="stop.booking && stop?.booking?.client?.phone"><i class="mdi mdi-phone me-1"></i>{{ stop.booking.client.phone }}</div>
                                </div>
                                <em class="text-muted small" v-if="stop.location">{{ stop.location.full_address }}</em>
                            </template>
                        </div>
                        <div class="col-1">
                            <i v-if="stop.booking && stop.booking.destination != null && stop.type === 'pickup'" class="mdi mdi-ray-start-arrow fs-2 text-secondary"></i>
                        </div>
                        <div>
                            <div class="small">
                                <template v-if="stop.booking && stop.booking.destination && stop.type === 'pickup'">
                                    {{ stop.booking.destination.full_address }}
                                </template>
                            </div>
                            <div v-if="stop.booking">
                                <JourneyRowBookingDetails
                                    :booking="stop.booking"
                                    @bookingReallocated="$emit('bookingReallocated', $event)"
                                ></JourneyRowBookingDetails>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="showEditModal"
            id="createOrEditVehicleRunModal"
            aria-hidden="true"
            aria-labelledby="createOrEditVehicleRunModalLabel"
            class="modal fade show d-block"
            tabindex="-1"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                            id="createOrEditVehicleRunModalLabel"
                            class="modal-title"
                        >
                            Edit Vehicle Run
                        </h5>
                        <button
                            aria-label="close"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            type="button"
                            @click="closeEditVehicleRun"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <CreateOrEditVehicleRun
                            key="createModalRefreshKey"
                            ref="vehicleRunCreateForm"
                            :vehicle-run-statuses="statusOptions"
                            mode="Update"
                            :run-updating="journey"
                            :vehicle="journey.vehicle"
                            :start-time="vehicleRunConvertedTimes.start_time"
                            :end-time="vehicleRunConvertedTimes.end_time"
                            :earliest-time="vehicleRunConvertedTimes.earliest_time"
                            :latest-time="vehicleRunConvertedTimes.latest_time"
                            @updated="closeEditVehicleRun"
                            @created="closeEditVehicleRun"
                            @deleted="closeEditVehicleRun"
                            @cancelled="closeEditVehicleRun"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <RevertBooking
        :selected-booking="selectedBooking"
        @booking-actioned="revertBookingActioned"
    />
</template>
<script>
import DateTime from "../DateTime.vue";
import { DateTime as Luxon } from "luxon";
import moment from "moment";
import MessageCrew from "../SendMessage/MessageCrew.vue";
import MessageClient from "../SendMessage/MessageClient.vue";
import JourneyRowBookingDetails from "./JourneyRowBookingDetails.vue";
import { mdiMessage, mdiPencil, mdiTarget } from '@mdi/js';
import SvgIcon from "@jamescoyle/vue-icon";
import { inject } from "vue";
import CreateOrEditVehicleRun from "@components/Vehicles/VehicleRuns/CreateOrEditVehicleRun.vue";
import { transformInput } from '@components/Utils.js';
import RevertBooking from "@components/Bookings/RevertBookingModal.vue";
import { mapStores, storeToRefs } from "pinia";
import { useDispatchStore } from "@stores/DispatchStore";
import { useRevertBookingStore } from "@stores/RevertBookingStore.";

export default {
    name: "JourneyRow",
    components: {
        RevertBooking,
        DateTime,
        MessageCrew,
        MessageClient,
        JourneyRowBookingDetails,
        SvgIcon,
        Luxon,
        CreateOrEditVehicleRun
    },
    props: {
        journey: Object,
        expand: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            uuid: this.$uuid.v1(),
            showStops: false,
            moment: moment,
            permissions: window.permissions,
            messageIcon: mdiMessage,
            enabledFeatures: window.enabled_features,
            createOrEditModal: null,
            editIcon: mdiPencil,
            createModalRefreshKey: Math.random(),
            showEditModal: false,
            selectedBooking: {},
            dispatchStore: useDispatchStore(),
            revertBookingStore: useRevertBookingStore(),
            targetIcon: mdiTarget,
        }
    },
    setup() {
        const bookingDetails = inject('bookingDetails');

        return { bookingDetails };
    },
    emits: [
        'bookingReallocated',
    ],
    computed: {
        hasBookings: function () {
            return this.journey.bookings.filter((booking) => ['future', 'verified', 'waiting_verification'].includes(booking.status)).length > 0
        },
        status: function () {
            if(this.journey.breaks.length > 0 && moment().isAfter(moment(this.journey.breaks[0].start_time).format('YYYY-MM-DD HH:mm:ss')) &&
                moment().isBefore(moment(this.journey.breaks[0].end_time).format('YYYY-MM-DD HH:mm:ss'))) {
                return 'on break'
            }

            let completedStops = this.journey.stops.filter((stop) => stop.completed && stop.completed_at)
            if (completedStops.length === 0) {
                return ''
            }

            let recentStop = completedStops
                .sort((prev, current) => moment(prev.completed_at).isAfter(current.completed_at) ? 1 : 0)
                .at(-1)

            if (!recentStop || !recentStop.completed_at) {
                return ''
            }

            if (recentStop.type === 'return_depot') {
                return 'completed'
            }

            if (recentStop.type !== 'depart_depot' && recentStop.type !== 'return_depot') {
                // this ensures only the hours are compared
                let completedAt = moment(recentStop.completed_at)
                let arriveNotBefore = moment(recentStop.arrive_not_before)
                let departNoLaterThan = moment(recentStop.depart_no_later_than)

                if (completedAt.isBefore(arriveNotBefore.subtract(10, 'minutes'))) {
                    return completedAt.to(arriveNotBefore.add(10, 'minutes'), true) + ' early'
                }

                if (completedAt.isAfter(departNoLaterThan.add(10, 'minutes'))) {
                    return completedAt.from(departNoLaterThan.subtract(10, 'minutes'), true) + ' late'
                }

                return 'on time'
            }

            return ''
        },
        statusOptions: () => {
            let statusList = window.enums.vehicleRunStatus

            return Object.keys(statusList).map((index) => {
                return {
                    value: index,
                    label: statusList[index],
                }
            })
        },
        vehicleRunConvertedTimes: function () {
            return {
                ...this.journey,
                ... {
                    start_time: Luxon.fromISO(this.journey.start_time).toFormat("yyyy-MM-dd'T'HH:mm"),
                    end_time: Luxon.fromISO(this.journey.end_time).toFormat("yyyy-MM-dd'T'HH:mm"),
                    earliest_time: this.journey.earliest_time ? Luxon.fromISO(this.journey.earliest_time).toFormat("yyyy-MM-dd'T'HH:mm") : null,
                    latest_time: this.journey.latest_time ? Luxon.fromISO(this.journey.latest_time).toFormat("yyyy-MM-dd'T'HH:mm") : null,
                }
            }
        },
        ...mapStores(useDispatchStore, useRevertBookingStore),
    },
    watch: {
        expand: function () {
            this.showStops = this.expand
            if (this.showStops) {
                $('.vehicle-' + this.uuid).slideDown('fast')
            } else {
                $('.vehicle-' + this.uuid).slideUp('fast')
            }
        },
        revertBookingStore: {
            handler(revertBooking) {
                const stopIndex = this.journey.stops.findIndex(stop => stop.booking?.uuid === revertBooking.storeSelectedBookingToRevert.uuid);

                if (stopIndex != -1) {
                    this.journey.stops[stopIndex].booking.revert_requested = 0;
                    this.journey.stops[stopIndex].booking.revert_reason = '';
                    // forcing it to rerender as it doesn't want to rerender
                    this.$forceUpdate();
                }
            },
            deep: true
        }
    },
    methods: {
        toggleShowStops: function () {
            this.showStops = !this.showStops
            $('.vehicle-' + this.uuid).slideToggle('fast')
        },
        delayDetails(delay) {
            switch (true) {
                case (delay <= -20):
                    return {
                        label: Math.abs(delay) + 'min early',
                        color: 'danger',
                    }
                case (delay > -20 && delay <= -5):
                    return {
                        label: Math.abs(delay) + 'min early',
                        color: 'warning',
                    }
                case (delay > -5 && delay < 5):
                    return {
                        label: 'On time',
                        color: 'success',
                    }
                case (delay >= 5 && delay < 20):
                    return {
                        label: delay + 'min late',
                        color: 'warning',
                    }
                case (delay >= 20):
                    return {
                        label: delay + 'min late',
                        color: 'danger',
                    }
            }
        },
        getClientList(stops) {
            let html = "<ul class='list-unstyled mb-0'>"
            stops.forEach((stop) => {
                if (!stop.booking || !stop.booking.client) {
                    return
                }

                html += "<li>" + stop.booking?.client?.full_name + " <a href='tel:"+ stop.booking.client.phone +"'><i class='mdi mdi-phone ms-1'></i></a></li>"
            })
            html += "</ul>"

            return html
        },
        startNewChat(driver) {
            this.$root.$refs.chatbox.startNewChat(driver)
        },
        editVehicleRun() {
            this.showEditModal = true;
        },
        closeEditVehicleRun() {
            this.showEditModal = false;
            this.$emit('bookingReallocated', true);
        },
        crossClass(stop) {
            if (this.isStopRevertable(stop)) {
                return 'overlay-cross';
            }

            return '';
        },
        isStopRevertable(stop) {
            if (stop.type !== 'pickup') {
                return false;
            }

            if (!stop.booking.revert_requested) {
                return false;
            }

            return true;
        },
        showRevertBookingModal(stop) {
            if (!this.isStopRevertable(stop)) {
                return;
            }

            // reset showModal so it can open again
            this.selectedBooking = {};
            const booking = this.journey.bookings.find(b => b.uuid == stop.booking.uuid);

            setTimeout(() => {
                this.selectedBooking = booking;
            }, 200);
        },
        revertBookingActioned(booking) {
            const stopIndex = this.journey.stops.findIndex(stop => stop.booking?.uuid === booking.uuid);
            this.journey.stops[stopIndex].booking.revert_requested = '';
            this.journey.stops[stopIndex].booking.revert_reason = '';

            this.$forceUpdate();
        },
        filterVehiclePosition(journeyUuid) {
            this.dispatchStore.runFilter = journeyUuid;
            this.dispatchStore.refreshMapCounter++;
        },
    }
}
</script>

<style scoped>
    .modal {
        background: rgba(0,0,0,0.75);
    }
</style>
