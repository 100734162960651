<template>
    <div v-if="invoice">
        <EditableDollar
            :label="invoice.total"
            :value="invoice.total"
            :loading="loading"
            :disabled="invoice.status != 'draft'"
            @updated="updateAmount"
        />
        <span class="text-danger">
            {{ errorMessage }}
        </span>
    </div>
</template>

<script setup lang="ts">
import EditableDollar from "@components/Forms/EditableFields/EditableDollar.vue";
import { Axios } from "axios";
import {ref, inject, toRef} from 'vue'

const props = withDefaults(
    defineProps<{
        invoice: App.Models.Invoice,
    }>(),
    {}
);

const invoice = toRef(props, 'invoice');

const axios = <Axios>inject('axios');
const toast = <any>inject('toast');

const emit = defineEmits<{
    (e: 'updated', value: boolean): void
}>();

const loading = ref<boolean>(false);
const errorMessage = ref<string>();

const updateAmount = (newValue) => {
    if (props.invoice.status !== 'draft') {
        toast.error('Invoice can only be updated when it is in draft status')
        return
    }

    loading.value = true

    axios
        .put(route('api.invoices.update', {invoice: props.invoice.uuid}), {
            total: newValue,
        })
        .then((response) => {
            invoice.value = response.data;
            emit('updated', true);
        })
        .catch((error) => {
            toast.error(error.response.data.message);
            console.error(error);
        })
        .finally(() => {
            loading.value = false
        })
}

</script>

<style scoped>

</style>
