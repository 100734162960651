import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { ref } from "vue";
import { parse, stringify } from "zipson";

export const useDispatchStore = defineStore(
    "dispatchStore",
    () => {
        const journeys = ref<App.Models.Journey[]>([]);
        const driverList = ref<any[]>([]);
        const vehicleList = ref<any[]>([]);
        const journeyList = ref<App.Models.Journey[]>([]);
        const driverFilter = ref("");
        const vehicleFilter = ref("");
        const runFilter = ref("");
        const refreshMapCounter = ref(0);

        function setJourneyDriverVehicle(allJourneys: any[]) {
            journeyList.value = allJourneys;
            driverList.value = [];
            vehicleList.value = [];

            journeyList.value.forEach((journey) => {
                driverList.value.push(journey.driver);
                vehicleList.value.push(journey.vehicle);
            });
        }

        function clearFilter() {
            driverFilter.value = "";
            vehicleFilter.value = "";
            runFilter.value = "";
        }

        return {
            setJourneyDriverVehicle,
            driverList,
            vehicleList,
            journeyList,
            driverFilter,
            vehicleFilter,
            runFilter,
            refreshMapCounter,
            journeys,
            clearFilter,
        };
    },
    {
        persist: {
            key:
                "dispatchStore_" +
                DateTime.now().toFormat("yyyy-MM-dd") +
                "_" +
                window?.tenant?.tenancy_db_name,
            paths: ["journeys"],
            serializer: {
                deserialize: parse,
                serialize: stringify,
            },
        },
    }
);
