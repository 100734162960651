<template>
    <div>
        <h4 class="header-title mt-0 mb-3">Availability</h4>
        <Calendar
            :key="calendarRefreshKey"
            :events="driverAvailability"
            :height="'600px'"
            @eventClicked="handleEventClicked"
            @selected="handleSelected"
        ></Calendar>

        <div
            class="modal fade"
            id="createAvailabilityFormModal"
            tabindex="-1"
            aria-labelledby="createAvailabilityFormModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createAvailabilityFormModalLabel">Add Availability Period</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <label class="form-label">Driver will be:</label>
                            <div class="d-flex">
                                <div class="form-check me-2">
                                    <input
                                        v-model="newAvailabilityPeriod.type"
                                        value="available"
                                        class="form-check-input"
                                        type="radio"
                                        name="period_time"
                                        id="periodTypeAvailable"
                                    >
                                    <label class="form-check-label fw-normal" for="periodTypeAvailable">
                                        Available
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        v-model="newAvailabilityPeriod.type"
                                        value="unavailable"
                                        class="form-check-input"
                                        type="radio"
                                        name="period_time"
                                        id="periodTypeUnavailable"
                                    >
                                    <label class="form-check-label fw-normal" for="periodTypeUnavailable">
                                        Unavailable
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 has-validation">
                                <label for="availabilityStart" class="form-label">Start time</label>
                                <input
                                    v-model="newAvailabilityPeriod.start_at"
                                    :max="newAvailabilityPeriod.end_at"
                                    @change="validateTimes"
                                    name="start_at"
                                    type="datetime-local"
                                    :class="{'form-control': true, 'is-invalid': errors.start_at }"
                                >
                                <div v-if="errors.start_at" id="start_at_feedback" class="invalid-feedback">
                                    {{ errors.start_at[0] }}
                                </div>
                            </div>
                            <div class="col-md-6 has-validation">
                                <label for="availabilityEnd" class="form-label">End time</label>
                                <input
                                    v-model="newAvailabilityPeriod.end_at"
                                    :min="newAvailabilityPeriod.start_at"
                                    @change="validateTimes"
                                    name="end_at"
                                    type="datetime-local"
                                    :class="{'form-control': true, 'is-invalid': errors.end_at }"
                                >
                                <div v-if="errors.end_at" id="end_at_feedback" class="invalid-feedback">
                                    {{ errors.end_at[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <a href="javascript: void(0)"
                               @click="rescheduleEnabled = !rescheduleEnabled; setInitialRescheduleOptions()">
                                Reschedule this availability period
                                <SvgIcon :path="rescheduleEnabled ? mdiMenuUp : mdiMenuDown"
                                         class="w-4 h-4 text-primary"
                                         type="mdi"></SvgIcon>
                            </a>
                        </div>
                        <RescheduleOptions v-if="rescheduleEnabled" v-model="reschedule" class="mt-3"
                                           event-type="availability period"></RescheduleOptions>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <Button :loading="saving" @click="storeAvailabilityPeriod">Save</Button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="editAvailabilityPeriodModal"
            tabindex="-1"
            aria-labelledby="editAvailabilityPeriodModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createAvailabilityFormModalLabel">Update Availability Period</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 has-validation">
                                <label for="availabilityStart" class="form-label">Start time</label>
                                <input
                                    v-model="periodUpdating.start_at"
                                    :max="periodUpdating.end_at"
                                    @change="validateTimes"
                                    name="start_at"
                                    type="time"
                                    :class="{'form-control': true, 'is-invalid': errors.start_at }"
                                >
                                <div v-if="errors.start_at" id="start_at_feedback" class="invalid-feedback">
                                    {{ errors.start_at[0] }}
                                </div>
                            </div>
                            <div class="col-md-6 has-validation">
                                <label for="availabilityEnd" class="form-label">End time</label>
                                <input
                                    v-model="periodUpdating.end_at"
                                    :min="periodUpdating.start_at"
                                    @change="validateTimes"
                                    name="end_at"
                                    type="time"
                                    :class="{'form-control': true, 'is-invalid': errors.end_at }"
                                >
                                <div v-if="errors.end_at" id="end_at_feedback" class="invalid-feedback">
                                    {{ errors.end_at[0] }}
                                </div>
                            </div>
                        </div>
                        <div v-if="periodUpdating.series_key" class="mt-3 form-check">
                            <input
                                v-model="periodUpdating.update_series"
                                class="form-check-input"
                                type="checkbox"
                                id="updateFutureEvents"
                            >
                            <label class="form-check-label" for="updateFutureEvents">Update all future availability periods linked with this one.</label>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <div>
                            <div class="btn-group" role="group">
                                <Button :loading="saving" color="danger" @click="deletePeriod(false)">Delete</Button>
                                <div v-if="periodUpdating.series_key" class="btn-group" role="group">
                                    <button
                                        :disabled="saving"
                                        id="deleteAllButtonGroup"
                                        type="button"
                                        class="btn btn-danger dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    ><span class="visually-hidden">More Options</span></button>
                                    <ul class="dropdown-menu" aria-labelledby="deleteAllButtonGroup">
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                href="javascript:void(0)"
                                                @click="deletePeriod(true)"
                                            >
                                                Delete all future periods linked with this one
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary me-1" data-bs-dismiss="modal">Close</button>
                            <Button @click="updatePeriod" :loading="saving">Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "DriverAvailability"
}
</script>
<script setup>
import Calendar from "../../Calendar.vue";
import {ref, onBeforeMount, onMounted, computed, inject} from "vue";
import {mdiMenuUp, mdiMenuDown} from '@mdi/js'
import Button from "../../Button.vue";
import RescheduleOptions from "../../RescheduleOptions.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {DateTime} from "luxon";

const reschedule = ref({})
const setInitialRescheduleOptions = function () {
    reschedule.value = {
        period: 'day',
        frequency: '1',
        weekdays: [],
        monthDays: [],
        monthly: 'exact',
        end: DateTime.now().plus({ months: 2 }).toSeconds().toFixed(0),
        dynamicMonthDay: '1',
        dynamicMonthWeekday: 'day',
    }
}

const newAvailabilityPeriod = ref({
    start_at: null,
    end_at: null,
    type: 'available',
})
const rescheduleEnabled = ref(false)

const validateTimes = function () {
    errors.value = []
    if (DateTime.fromISO(newAvailabilityPeriod.value.start_at) > DateTime.fromISO(newAvailabilityPeriod.value.end_at)) {
        errors.value['start_at'] = ['The start time needs to be before the end time.']
    }
    if (DateTime.fromISO(newAvailabilityPeriod.value.end_at) < DateTime.fromISO(newAvailabilityPeriod.value.start_at)) {
        errors.value['end_at'] = ['The end time needs to be after the start time.']
    }
}

const periodUpdating = ref({})
const handleEventClicked = function (event) {
    let period = JSON.parse(JSON.stringify(event.event.extendedProps))
    period.start_at = DateTime.fromISO(period.start_at).toFormat('HH:mm')
    period.end_at = DateTime.fromISO(period.end_at).toFormat('HH:mm')
    period.update_series = false

    periodUpdating.value = period
    updatePeriodModal.value.show()
}

const handleSelected = function (selected) {
    createPeriodModal.value.show()
    newAvailabilityPeriod.value.start_at = DateTime.fromISO(selected.startStr).toISO({ includeOffset: false, suppressMilliseconds: true, suppressSeconds: true, })
    newAvailabilityPeriod.value.end_at = DateTime.fromISO(selected.endStr).toISO({ includeOffset: false, suppressMilliseconds: true, suppressSeconds: true, })
}

const saving = ref(false)
import axios from "axios";
const errors = ref([])
const toast = inject('toast')
const calendarRefreshKey = ref(Date.now())
import { toTimeStamp } from "../../Utils.js";
const storeAvailabilityPeriod = function () {
    saving.value = true
    axios
        .post(route('api.drivers.availability.store', {driver: props.driver.uuid}), {
            start_at: toTimeStamp(newAvailabilityPeriod.value.start_at),
            end_at: toTimeStamp(newAvailabilityPeriod.value.end_at),
            type: newAvailabilityPeriod.value.type,
            recursion: rescheduleEnabled.value ? reschedule.value : {},
        })
        .then((response) => {
            createPeriodModal.value.hide()
            response.data.forEach((period) => {
                availabilityPeriods.value.push(period)
            })
            toast.success('The availability period was added successfully.', {timeout: 5000})
            calendarRefreshKey.value = Date.now()
        })
        .catch((error) => {
            errors.value = error?.response?.data?.errors ?? []
        })
        .finally(() => {
            saving.value = false
        })
}

const updatePeriod = function () {
    saving.value = true
    axios
        .put(
            route('api.drivers.availability.update', {driver: props.driver.uuid, availability_period: periodUpdating.value.uuid}),
            periodUpdating.value
        )
        .then((response) => {
            updatePeriodModal.value.hide()
            availabilityPeriods.value = response.data
            toast.success('The availability period was updated successfully.', {timeout: 5000})
            calendarRefreshKey.value = Date.now()
        })
        .catch((error) => {
            errors.value = error?.response?.data?.errors ?? []
        })
        .finally(() => {
            saving.value = false
        })
}

const deletePeriod = function (all_in_series = false) {
    saving.value = true
    axios
        .delete(route('api.drivers.availability.update', {driver: props.driver.uuid, availability_period: periodUpdating.value.uuid}), {
            data: {
                all_in_series: all_in_series
            },
        })
        .then((response) => {
            updatePeriodModal.value.hide()
            availabilityPeriods.value = response.data
            toast.success('The availability period has been deleted.', {timeout: 5000})
            calendarRefreshKey.value = Date.now()
        })
        .finally(() => {
            saving.value = false
        })
}

const props = defineProps({
    driver: Object,
})

const availabilityPeriods = ref([])
const driverAvailability = computed(() => {
    if (!availabilityPeriods.value) {
        return [];
    }

    return availabilityPeriods.value.map((period) => {
        return {
            id: period.uuid,
            title: (period.type === 'available') ? 'Available' : 'Not Available',
            start: period.start_at,
            end: period.end_at,
            extendedProps: period,
            allDay: false,
            color: (period.type === 'available') ? '#3788d8' : '#919ca7',
        }
    })
})
onBeforeMount(() => {
    availabilityPeriods.value = props.driver.availabilityPeriods
})

const createPeriodModal = ref(null)
const updatePeriodModal = ref(null)
onMounted(() => {
    createPeriodModal.value = new bootstrap.Modal(document.getElementById('createAvailabilityFormModal'))
    updatePeriodModal.value = new bootstrap.Modal(document.getElementById('editAvailabilityPeriodModal'))
})
</script>

<style scoped>

</style>
