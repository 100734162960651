<template>
    <div>
        <VueApexCharts
            :height="height"
            :width="width"
            :options="chartOptions"
            :series="series"
        ></VueApexCharts>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import VueApexCharts from "vue3-apexcharts";

import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
const allocationStore = useAllocationStore();
const { bookingsAllocatedToJourney, getUpdateParametersForJourney } =
    allocationStore;

import moment from "moment";

const { selectedJourney } = storeToRefs(allocationStore);

const props = withDefaults(
    defineProps<{
        height?: string;
        width?: string;
        options?: any;
    }>(),
    {
        height: "100%",
        width: "100%",
        options: {},
    }
);

const series = computed(() => [
    {
        name: "Seats Taken (Unsaved Draft)",
        type: "line",
        data: currentSeatsInTime.value,
    },
    {
        name: "Seats Taken (Original)",
        type: "line",
        data: originalSeatsInTime.value,
    },
]);

const chartOptions = computed(() => {
    return {
        chart: {
            height: props.height,
            type: "line",
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        colors: ["#10BD85", "#E3655B"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "stepline",
            width: [3, 2],
        },
        tooltip: {
            x: {
                format: "HH:mm",
            },
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeUTC: false,
            },
        },
        yaxis: {
            title: {
                text: "Seats Taken",
            },
        },
    };
});

const getSeatsInTimeForBookings = (bookings: App.Models.Booking[]) => {
    let seats: any[] = [];

    if (!selectedJourney.value) {
        return seats;
    }
    let seatCount = 0;
    let adjustments: any[] = [];
    seats.push([selectedJourney.value.start_time, seatCount]);

    bookings.forEach((booking) => {
        if (booking.planned_origin_time || booking.estimated_origin_time) {
            adjustments.push([
                moment(
                    booking.planned_origin_time ?? booking.estimated_origin_time
                ).format("YYYY-MM-DD HH:mm:ss"),
                1,
            ]);
        }

        if (
            booking.planned_destination_time ||
            booking.estimated_destination_time
        ) {
            adjustments.push([
                moment(
                    booking.planned_destination_time ??
                        booking.estimated_destination_time
                ).format("YYYY-MM-DD HH:mm:ss"),
                -1,
            ]);
        }
    });

    adjustments.push([selectedJourney.value.end_time, seatCount]);

    adjustments.sort((a, b) => {
        if (a[0] === b[0]) {
            return 0;
        } else {
            return Date.parse(a[0]) < Date.parse(b[0]) ? -1 : 1;
        }
    });

    adjustments.forEach((adjustment) => {
        seatCount += adjustment[1];
        seats.push([adjustment[0], seatCount]);
    });

    return seats;
};

const currentSeatsInTime = computed(() => {
    return getSeatsInTimeForBookings(currentBookings.value);
});

const originalSeatsInTime = computed(() => {
    return getSeatsInTimeForBookings(originalBookings.value);
});

const currentBookings = computed(() => {
    return selectedJourney.value
        ? bookingsAllocatedToJourney(selectedJourney.value)
        : [];
});

const originalBookings = computed(() => {
    return selectedJourney.value
        ? bookingsAllocatedToJourney(selectedJourney.value, true)
        : [];
});
</script>
