<template>
    <div class="card shadow-none border">
        <div class="card-header d-flex justify-content-between align-items-center">
            <strong>{{ plan.funding_type.name }}</strong>
            <Button color="link" size="small" @click="showEditForm">
                <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                Edit
            </Button>
        </div>
        <div class="card-body mb-0">
            <div class="row">
                <div v-if="plan.start_date" class="col-md-6">
                    <strong>Start date</strong>
                    <div>{{ $filters.format_datetime(plan.start_date, 'medium date') }}</div>
                </div>
                <div v-if="plan.end_date" class="col-md-6">
                    <strong>End date</strong>
                    <div>{{ $filters.format_datetime(plan.end_date, 'medium date') }}</div>
                </div>
            </div>
            <div class="mt-3">
                <h5>Approved line items</h5>
                <Accordion class="mb-0">
                    <template v-for="item in plan.line_items">
                        <AccordionItem>
                            <template #title>
                                {{ item.description + ' - ' + item.code }}
                            </template>
                            <template #content>
                                <p v-if="item.configuration.apply_to_bookings">
                                    This line item will automatically apply to all bookings
                                </p>
                                <dl class="mb-0">
                                    <dt>Fare collection method</dt>
                                    <dd>{{ item.configuration.fare_collection_method_label }}</dd>

                                    <template v-if="item.configuration.fixed_fee_amount">
                                        <dt>Fixed fee amount</dt>
                                        <dd>{{ $filters.currency(item.configuration.fixed_fee_amount) }}</dd>
                                    </template>

                                    <template v-if="item.configuration.charge_a_booking_fee">
                                        <dt>Booking fee amount</dt>
                                        <dd>{{ $filters.currency(item.configuration.booking_fee_amount) }}</dd>
                                    </template>

                                    <template v-if="item.configuration.charge_an_additional_fee">
                                        <dt>Additional fee amount</dt>
                                        <dd>{{ $filters.currency(item.configuration.additional_fee_amount) }}</dd>
                                    </template>

                                    <template v-if="item.configuration.set_a_maximum_spend">
                                        <dt>Maximum spend</dt>
                                        <dd>{{ $filters.currency(item.configuration.maximum_spend_amount) }}</dd>
                                    </template>

                                    <template v-if="item.configuration.charge_distance_based_fees">
                                        <dt>Distance Based Fees</dt>
                                        <dd>
                                            <p v-if="item.configuration.use_estimated_distance"
                                               class="text-sm fst-italic">
                                                Fees are based on estimated distance
                                            </p>
                                            <table class="table table-sm table-bordered mt-2 mb-0">
                                                <thead class="text-sm">
                                                <tr>
                                                    <th class="text-center">Min km</th>
                                                    <th class="text-center">Max km</th>
                                                    <th class="text-center">Amount</th>
                                                    <th class="text-center">Round fee</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-for="fee in item.configuration.distance_based_fees">
                                                    <tr>
                                                        <td class="text-center">{{ fee.min }}</td>
                                                        <td class="text-center">{{ fee.max }}</td>
                                                        <td class="text-center">{{ $filters.currency(fee.amount) }}</td>
                                                        <td class="text-center">
                                                            <SvgIcon v-if="fee.feeRounding" :path="mdiCheck"
                                                                     class="h-4 w-4"
                                                                     type="mdi"></SvgIcon>
                                                            <SvgIcon v-else :path="mdiClose" class="h-4 w-4"
                                                                     type="mdi"></SvgIcon>
                                                        </td>
                                                    </tr>
                                                </template>
                                                </tbody>
                                            </table>
                                        </dd>
                                    </template>
                                </dl>
                            </template>
                        </AccordionItem>
                    </template>
                </Accordion>
            </div>
        </div>
        <div :id="componentId + '_editModal'" aria-hidden="true" aria-labelledby="createFundingPlanLabel"
             class="modal fade"
             tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createFundingPlanLabel" class="modal-title">Edit Funding Plan</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <FundingPlanForm
                            :key="modalKey"
                            :client="client"
                            :details="plan"
                            @cancel="cancelModalEditing"
                            @saved="updateFundingPlan"
                        ></FundingPlanForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FundingPlan"
}
</script>
<script setup>

import Accordion from "../Accordion/Accordion.vue";
import AccordionItem from "../Accordion/AccordionItem.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCheck, mdiClose, mdiPencil} from "@mdi/js";
import Button from "../Button.vue";
import {onBeforeMount, onMounted, ref} from "vue";
import FundingPlanForm from "./FundingPlanForm.vue";
import {uuid} from "vue-uuid"

const emit = defineEmits(['updated'])

const fundingPlan = ref({})
const editModal = ref(null);
const componentId = ref(uuid.v4());
const showEditForm = function () {
    editModal.value.show()
}

const modalKey = ref(Date.now())
const cancelModalEditing = function () {
    editModal.value.hide()
    modalKey.value = Date.now()
}

import {copyValues} from "../Utils.js";

const updateFundingPlan = function (newPlanDetails) {
    fundingPlan.value = newPlanDetails
    editModal.value.hide()
    modalKey.value = Date.now()

    emit('updated', newPlanDetails)
}

onBeforeMount(function () {
    fundingPlan.value = props.plan
})

onMounted(() => {
    editModal.value = new bootstrap.Modal(document.getElementById(componentId.value + '_editModal'));
})

const props = defineProps({
    plan: Object,
    client: Object,
})

</script>
<style scoped>
</style>
