import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import Attachment from "@classes/Attachment";
import { useMessageStore } from '@stores/MessageStore';
import { usePaginationStore } from "./PaginationStore";
import { each } from "lodash";
import { sortDirectionEnum } from "../types/DataTableTypes";

export interface getAttachmentsData {
    paginate: boolean,
    perPage?: number,
    page?: number,
    column?: string | null,
    direction?: sortDirectionEnum | null,
    class?: string,
    class_id?: string,
    document?: string,
    query?: string,
}

export const useAttachmentStore = defineStore('attachmentStore', () => {
    const { paginatedMeta, paginateParams, searchQuery } = storeToRefs(usePaginationStore());

    const messageStore = useMessageStore();
    const { fieldErrors } = storeToRefs(messageStore);
    const { addToastMessage, addFieldError, processValidationErrors } = messageStore;

    paginateParams.value.sortBy = 'created_by';
    paginateParams.value.sortDirection = sortDirectionEnum.Descending

    const attachments = ref<App.Resource.Attachment[]>();
    const loadingAttachments = ref<boolean>(false);
    const updatingAttachments = ref<boolean>(false);
    const filters = ref<App.Attachment.Filters>({
        class: null,
        classId: null,
        document: null,
    });

    const getAttachments = (): void => {
        loadingAttachments.value = true;
        
        let data: getAttachmentsData = {
            paginate: true,
            perPage: paginateParams.value.perPage,
            page: paginateParams.value.page,
            column: paginateParams.value.sortBy,
            direction: paginateParams.value.sortDirection,
        }

        if (filters.value?.class != null) {
            data.class = filters.value.class;
        }

        if (filters.value?.classId != null) {
            data.class_id = filters.value.classId;
        }

        if (filters.value?.document) {
            data.document = filters.value.document;
        }

        if (searchQuery.value?.trim()?.length > 0) {
            data.query = searchQuery.value?.trim()
            data.column = null
        }

        Attachment.index(data)
            .then((resp) => {
                attachments.value = resp.data.data;
                // @ts-ignore
                paginatedMeta.value = resp.data.meta;
            })
            .catch((err) => {
                addToastMessage('error', 'Failed to get Attachments list');
                console.error(err);
            })
            .finally(() => {
                loadingAttachments.value = false;
            });
    };

    const showUploadAttachmentModal = ref<boolean>(false);

    const toggleUploadAttachmentModal = () => {
        if (showUploadAttachmentModal.value) {
            draftAttachments.value = [];
            fieldErrors.value = {};
        }
        showUploadAttachmentModal.value = !showUploadAttachmentModal.value;
    }

    const draftAttachments = ref<App.Attachment.Upload[]>([]);
    const draftAttachment = ref<App.Attachment.Upload>();
    const attachment = ref<App.Resource.Attachment>();
    const attachmentExtensions = ref<string>();
    const attachmentMimes = ref<string[]>();

    const setAttachment = (newValue: App.Resource.Attachment) => {
        attachment.value = newValue;
    }

    const getFileExtensions = (): void => {
        Attachment.extensionIndex()
        .then((resp) => {
            attachmentExtensions.value = resp.data.extensions;
            attachmentMimes.value = resp.data.mimes;
        })
        .catch((err) => {
            console.error(err);
            addToastMessage('error', 'Failed to get Attachments Extensions');
        })
    }

    const uploadAttachment = (): void => {
        loadingAttachments.value = true;
        fieldErrors.value = {};

        Attachment.store(draftAttachment.value)
            .then(() => {
                addToastMessage('success', 'Attachment was successfully uploaded');
                toggleUploadAttachmentModal();
            })
            .catch((error) => {
                if (error.response.status == 422 && error.response.data.errors) {
                    addToastMessage('warning', 'Inputs need correcting');
                    processValidationErrors(error.response.data.errors);
                    return;
                }

                console.error(error);
                addToastMessage('error', 'Failed to upload Attachment');
            })
            .finally(() => {
                loadingAttachments.value = false;
            });
    }

    const bulkUploadAttachments = (): void => {
        loadingAttachments.value = true;

        if (fieldErrors.value?.length > 0 || draftAttachments.value.length < 1) {
            addToastMessage('error', 'Please ensure that you have a file uploaded and that all files have name');
        }

        fieldErrors.value = {};

        let data = new FormData;

        each(draftAttachments.value, (attachment, index) => {
            data.append(`attachments[${index}][name]`, attachment.name);
            data.append(`attachments[${index}][attachment]`, attachment.attachment);

            if (index == 0 && attachment?.attachable_id != null) {
                data.append('attachable_id', attachment?.attachable_id);
                // @ts-ignore
                data.append('attachable_type', attachment?.attachable_type);
            }
        });

        Attachment.bulk(data)
            .then(() => {
                addToastMessage('success', 'Attachments were successfully uploaded');
                toggleUploadAttachmentModal();
                getAttachments();
            })
            .catch((error) => {
                if (error?.response?.status == 422 && error?.response?.data?.errors) {
                    addToastMessage('error', 'Please ensure that you have a file uploaded and that all files have name')
                    processValidationErrors(error.response.data.errors);
                    return;
                }

                console.error(error);
                addToastMessage('error', 'Failed to upload Attachment');
            })
            .finally(() => {
                loadingAttachments.value = false;
            });
    }

    const updateAttachment = (attachment: string, data: object) => {
        updatingAttachments.value = true;
        fieldErrors.value = {};

        Attachment.update(attachment, data)
            .then(() => {
                addToastMessage('success', 'Attachment Updated Successfully');
            })
            .catch((error) => {
                if (error?.response?.status == 422 && error?.response?.data?.errors) {
                    addToastMessage('error', 'Inputs need correcting');
                    processValidationErrors(error.response.data.errors, attachment);
                    return;
                }

                console.error(error);
                addToastMessage('error', 'Failed to update Attachment');
            })
            .finally(() => {
                updatingAttachments.value = false;
            })
    }

    const deleteAttachment = (value: App.Resource.Attachment | undefined = attachment.value) => {
        loadingAttachments.value = true;

        Attachment.destroy(value)
            .then(() => {
                addToastMessage('success', 'Attachment Deleted Successfully');
                getAttachments();
            })
            .catch((error) => {
                console.error(error);
                addToastMessage('error', 'Failed to delete Attachment');
            })
            .finally(() => {
                loadingAttachments.value = false;
            })
    }

    return {
        fieldErrors,
        filters,
        attachments,
        loadingAttachments,
        updatingAttachments,
        paginatedMeta,
        paginateParams,
        showUploadAttachmentModal,
        draftAttachments,
        attachment,
        attachmentExtensions,
        attachmentMimes,
        getAttachments,
        setAttachment,
        getFileExtensions,
        toggleUploadAttachmentModal,
        uploadAttachment,
        bulkUploadAttachments,
        updateAttachment,
        deleteAttachment,
    }
})