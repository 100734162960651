<template>
    <div>
        <ClientBookingsFilters v-model="filters" @update:model-value="getBookings"></ClientBookingsFilters>
        <template v-if="loading">
            <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
                <Loading spinner-class="spinner-grow" text="Loading bookings" text-position="after" direction="horizontal"/>
            </div>
        </template>
        <template v-else-if="bookings.length === 0">
            <div class="text-center mt-4 mb-3">
                <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiCarClock"></SvgIcon>
                <div class="fs-4 my-2">{{ client.preferred_name ?? client.given_names }} has no bookings.</div>
            </div>
        </template>
        <template v-else>
            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th>Status</th>
                    <th>Reference</th>
                    <th class="text-nowrap">Leg</th>
                    <th class="sortable" :class="{
                        'sort-asc': filters.order_by === 'date' && filters.order_direction === 'asc',
                        'sort-desc': filters.order_by === 'date' && filters.order_direction === 'desc',}"
                        role="button"
                        @click="toggleSort('date')">
                        Date
                    </th>
                    <th class="sortable col-4" :class="{
                        'sort-asc': filters.order_by === 'origin_address' && filters.order_direction === 'asc',
                        'sort-desc': filters.order_by === 'origin_address' && filters.order_direction === 'desc',}"
                        role="button"
                        @click="toggleSort('origin_address')">
                        Origin
                    </th>
                    <th class="sortable col-4" :class="{
                        'sort-asc': filters.order_by === 'destination_address' && filters.order_direction === 'asc',
                        'sort-desc': filters.order_by === 'destination_address' && filters.order_direction === 'desc',}"
                        role="button"
                        @click="toggleSort('destination_address')">
                        Destination
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="booking in bookings">
                    <tr>
                        <td style="min-width: 70px;">
                            <div class="d-flex">
                                <BookingStatus :status="booking.status"/>
                                <span
                                    v-if="booking.reschedule_identifier"
                                    v-tooltip="'This booking is part of a reoccurrence'"
                                >
                                    <SvgIcon
                                        type="mdi"
                                        :path="mdiRepeatVariant"
                                        class="me-1 text-info"
                                    ></SvgIcon>
                                </span>
                            </div>
                        </td>
                        <td class="text-nowrap"><a href="javascript: void(0)" @click="bookingDetails.showForBooking(booking)">{{ booking.uuid }}</a></td>
                        <td>{{ booking.daily_leg_order }}</td>
                        <td class="text-nowrap">{{ $filters.format_datetime(booking.planned_origin_time ?? booking.planned_destination_time, 'medium date') }}</td>
                        <td class="text-truncate" style="max-width: 0;" :title="booking.origin.short_address" data-bs-toggle="tooltip" >
                            <BookingTime type="origin" :booking="booking" :key="`origin_time_${booking.uuid}`"/>
                            {{ booking.origin.short_address }}
                        </td>
                        <td class="text-truncate" style="max-width: 0;" :title="booking.origin.short_address" data-bs-toggle="tooltip" >
                            <BookingTime type="destination" :booking="booking" :key="`destination_time_${booking.uuid}`"/>
                            {{ booking.destination.short_address }}
                        </td>
                        <td>
                            <span v-if="(booking?.carers?.length ?? 0) > 0">
                                    <IconHoverCompanion :booking="booking"></IconHoverCompanion>
                                </span>
                            </td>
                            <td>
                                <BookingActionsDropdown :booking="booking" />
                        </td>
<!--                            <td>-->
<!--                                <a v-if="booking.invoices?.length === 0"-->
<!--                                   href="javascript: void(0)"-->
<!--                                   @click="generateInvoice(booking)"-->
<!--                                   class="mx-1">-->
<!--                                    <i class="mdi mdi-receipt" v-bind:id="`booking-generate-icon-${booking.uuid}`"></i>-->
<!--                                    Generate invoice-->
<!--                                </a>-->
<!--                            </td>-->
                    </tr>
                </template>
                </tbody>
            </table>
            <Pagination
                v-if="!loading"
                @pageChanged="loadPage"
                :pagination="paginationMeta"
                record-name="bookings"
            ></Pagination>
        </template>
    </div>
    <DeallocateBookingModal :toggle-details-modal="true" />
</template>

<script>
export default {
    name: "ClientBookings"
}
</script>
<script setup>
import {inject, onMounted, ref} from "vue"
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCarClock, mdiRepeatVariant, mdiClipboardTextClockOutline, mdiPlus} from "@mdi/js";
import axios from "axios";
import Pagination from "../Pagination.vue"
import BookingStatus from "../BookingStatus.vue"
import DeallocateBookingModal from '@components/Bookings/RescheduleBooking/DeallocateBookingModal.vue'
import {DateTime} from "luxon";
import ClientBookingsFilters from "@components/Clients/ClientBookingsFilters.vue";
import {omitBy, isEmpty, isNil} from "lodash";
import BookingTime from "@components/Bookings/BookingTime.vue";
import BookingActionsDropdown from "@components/Bookings/BookingActionsDropdown.vue";
import Button from "@components/Button.vue";
import {Loading} from "@components";
import IconHoverCompanion from "@components/Companion/IconHoverCompanion.vue";

const bookingDetails = inject("bookingDetails");

const toast = inject('toast')
const props = defineProps({
    client: Object,
})
const emit = defineEmits(['setTab']);

const bookings = ref([])
const loading = ref(false)
const filters = ref({
    amount: 25,
    query: null,
    status_filter: null,
    view: 'quarterly',
    date_from: DateTime.now().startOf('day').toISODate(),
    date_to: DateTime.now().startOf('day').plus({ months: 3 }).toISODate(),
    order_direction: 'asc',
    page: 1,
    order_by: 'date',
});
let paginationMeta = ref({})

const loadPage = (link) => {
    filters.value.page = link.page;
    getBookings();
}

const getBookings = function () {
    loading.value = true

    const urlParams = {
        clients: [props.client.uuid],
        paginate: true,
        ...omitBy(filters.value, isNil)
    };

    axios
        .get(route('api.bookings.index', urlParams))
        .then((response) => {
            bookings.value = response.data.data
            paginationMeta.value = response.data.meta
        })
        .catch((error) => {
            toast.error('An error occurred while loading bookings.')
        })
        .finally(() => {
            loading.value = false
        })
}

const generateInvoice = (booking) => {
    axios
        .post(route('api.invoices.generate', {
            booking: [booking.uuid],
        }))
        .then((response) => {
            if (response.status === 200) {
                emit('setTab', 'invoices');
            } else {
                toast.error(error.response.data.message)
            }
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            loading.value = false
        })
}

const toggleSort = (column) => {
    let direction = filters.value.order_direction === 'asc' ? 'desc' : 'asc'

    if (filters.value.order_by !== column) {
        direction = 'asc'
    }

    filters.value.order_by = column;
    getBookings();
    filters.value.order_direction = direction;
};

onMounted(function () {
    getBookings();
})
</script>

<style scoped>
    .mdi-repeat {
        margin-left: 5px;
        color: #0351BF;
    }

    th.sortable:before {
        top: 2px;
    }

    th.sortable:after {
        top: 8px;
    }

</style>
