<template>
    <div class="row">
        <div :class="selectedBooking ? 'col-lg-7' : null">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <PageLengthSelector
                                record-name="bookings"
                                :disabled="loadingBookings"
                            />
                        </div>

                        <div class="col-sm-12 col-md-6 text-md-end">
                            <div
                                class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block"
                            >
                                <button
                                    class="btn btn-light btn-sm dropdown-toggle"
                                    type="button"
                                    id="dropDownFilterBookings"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                >
                                    <SvgIcon
                                        type="mdi"
                                        :path="mdiFilter"
                                        style="max-width: 16px"
                                    ></SvgIcon>
                                    Filter
                                </button>
                                <div
                                    class="dropdown-menu px-4 py-3"
                                    style="min-width: 500px"
                                    aria-describedby="dropDownFilterBookings"
                                >
                                    <div class="mb-2 row">
                                        <div
                                            class="col-sm-6"
                                            style="min-width: 200px"
                                        >
                                            <label
                                                for="date_from"
                                                class="form-label text-sm mb-1"
                                                >From:</label
                                            >
                                            <input
                                                v-model="dateRange.from"
                                                @change="getBookings()"
                                                type="date"
                                                id="date_from"
                                                class="form-control form-control-sm"
                                            />
                                        </div>
                                        <div
                                            class="col-sm-6"
                                            style="min-width: 200px"
                                        >
                                            <label
                                                for="date_to"
                                                class="form-label text-sm mb-1"
                                                >Until:</label
                                            >
                                            <input
                                                v-model="dateRange.to"
                                                @change="getBookings()"
                                                type="date"
                                                id="date_to"
                                                class="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label
                                            class="form-label text-sm"
                                            for="status_filter_select"
                                            >Status:</label
                                        >
                                        <select
                                            v-model="statusFilter"
                                            @change="getBookings()"
                                            id="status_filter_select"
                                            class="form-select form-select-sm"
                                        >
                                            <option value="">Any Status</option>
                                            <option value="future">
                                                Future
                                            </option>
                                            <option value="verified">
                                                Verified
                                            </option>
                                            <option value="cancelled">
                                                Cancelled
                                            </option>
                                            <option value="standby">
                                                Wait-listed
                                            </option>
                                            <option
                                                value="waiting_verification"
                                            >
                                                Waiting Verification
                                            </option>
                                            <option value="unmet">Unmet</option>
                                            <option value="transferred">
                                                Transferred
                                            </option>
                                            <option value="posted">
                                                Posted
                                            </option>
                                        </select>
                                    </div>
<!--   TODO waiting for UX  -->
<!--                                    <div>
                                        <div class="mb-2">
                                            <label
                                                class="form-label text-sm"
                                                for="transport_requirements_filter_select"
                                                >Transport Requirements:</label
                                            >
                                            <VueMultiselect
                                                id="transport_requirements_filter_select"
                                                v-model="
                                                    transportRequirementsFilters
                                                "
                                                :options="
                                                    transportRequirementOptions
                                                "
                                                placeholder="Select transport requirements"
                                                label="label"
                                                track-by="value"
                                                multiple
                                            ></VueMultiselect>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="col-md-6"
                                                v-for="(
                                                    filter, index
                                                ) in transportRequirementsFilters"
                                                :key="index"
                                            >
                                                <template v-if="!filter.bool">
                                                    <label
                                                        :for="filter.value"
                                                        >{{
                                                            filter.label
                                                        }}</label
                                                    >
                                                    <div class="mb-2">
                                                        <input
                                                            :id="filter.value"
                                                            v-model="
                                                                transportRequirementsFilters[
                                                                    index
                                                                ]['number']
                                                            "
                                                            class="form-control form-control-sm"
                                                            :name="filter.value"
                                                            type="number"
                                                            placeholder="E.g. 2"
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                            <DataTableSearch
                                @search="getBookings"
                                placeholder="Search client name...."
                            />
                        </div>
                    </div>

                    <template v-if="loadingBookings">
                        <div
                            class="d-flex w-100 justify-content-center align-items-center"
                        >
                            <div
                                class="spinner-grow my-5 me-3"
                                role="status"
                            ></div>
                            <div class="fs-4">Loading bookings</div>
                        </div>
                    </template>

                    <template v-if="!loadingBookings">
                        <table
                            class="table table-centered table-striped table-xs nowrap w-100 text-sm"
                        >
                            <thead>
                                <tr class="small">
                                    <SortableColumn param="date">
                                        Date
                                    </SortableColumn>
                                    <SortableColumn param="clients">
                                        Client
                                    </SortableColumn>
                                    <th>Status</th>
                                    <SortableColumn param="origin">
                                        Origin
                                    </SortableColumn>
                                    <SortableColumn param="destination">
                                        Destination
                                    </SortableColumn>
                                    <th>Allocated</th>
                                    <th>Companions</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="booking in bookings">
                                    <tr>
                                        <td
                                            @click="selectedBooking = booking"
                                            style="cursor: pointer"
                                        >
                                            <div
                                                class="d-flex flex-row align-items-center"
                                            >
                                                <BookingListLinkIcon
                                                    :booking="booking"
                                                    class="me-1"
                                                />
                                                <DateTime
                                                    :date-time="
                                                        booking.planned_origin_time ??
                                                        booking.planned_destination_time
                                                    "
                                                    format="medium date"
                                                ></DateTime>
                                            </div>
                                        </td>
                                        <td class="table-user">
                                            <a
                                                v-if="booking.client"
                                                :href="
                                                    route('clients.show', {
                                                        client: booking.client
                                                            .uuid,
                                                    })
                                                "
                                            >
                                                <ClientAvatar
                                                    :size="'extra-small'"
                                                    class="me-1"
                                                    :client="booking.client"
                                                ></ClientAvatar>
                                                {{ booking.client.name }}
                                            </a>
                                            <div v-else class="fst-italic">
                                                Unknown Client
                                            </div>
                                        </td>
                                        <td>
                                            <BookingStatus
                                                :status="booking.status"
                                            />
                                            <span
                                                v-if="
                                                    hasOutOfSyncDates(booking)
                                                "
                                                v-tooltip="
                                                    'Booking has out of sync dates'
                                                "
                                            >
                                                <a
                                                    :href="
                                                        bookingEditUrl(booking)
                                                    "
                                                >
                                                    <SvgIcon
                                                        type="mdi"
                                                        :path="mdiAlert"
                                                        :size="20"
                                                        class="me-1 text-danger"
                                                    ></SvgIcon>
                                                </a>
                                            </span>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <BookingTimingsHoverIcon
                                                    :booking="booking"
                                                    type="origin"
                                                    class="me-1"
                                                />
                                                <BookingTime
                                                    type="origin"
                                                    :booking="booking"
                                                    class="me-2"
                                                    style="min-width: 3.5rem"
                                                    :key="
                                                        'origin_time_' +
                                                        booking.uuid
                                                    "
                                                />
                                                <div class="d-flex justify-content-start align-content-center">
                                                    {{ booking.origin.full_address }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <BookingTimingsHoverIcon
                                                    :booking="booking"
                                                    type="destination"
                                                    class="me-1"
                                                />
                                                <BookingTime
                                                    type="destination"
                                                    :booking="booking"
                                                    class="me-2"
                                                    style="min-width: 3.5rem"
                                                    :key="
                                                        'dest_time_' +
                                                        booking.uuid
                                                    "
                                                />
                                                <div class="d-flex justify-content-start align-content-center">
                                                    {{ booking.destination.full_address }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="booking.journey">
                                                <div class="d-inline-block me-1" style="max-width: 40px">
                                                    <img :src="`/images/vehicles/${booking.journey?.vehicle?.type?.image ? booking.journey.vehicle.type.image : 'sedan'}.png`"
                                                        class="img-fluid"
                                                        alt="Vehicle Icon"
                                                    />
                                                </div>
                                                {{ booking.journey?.vehicle?.description }}
                                            </template>
                                        </td>
                                        <td>
                                            <span v-if="(booking?.companions?.length ?? 0) > 0">
                                                <IconHoverCompanion :booking="booking"></IconHoverCompanion>
                                            </span>
                                        </td>
                                        <td>
                                            <BookingActionsDropdown :booking="booking" />
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <Pagination
                            v-if="!loadingBookings"
                            @pageChanged="getBookings"
                            record-name="bookings"
                            key="bookings"
                        ></Pagination>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="selectedBooking" class="col-lg-5">
            <div class="card sticky-lg-top" style="z-index: 500">
                <div class="card-body">
                    <BookingDetails
                        :booking="selectedBooking"
                        :key="selectedBooking.uuid"
                        :show-close-icon="true"
                        @bookingDetailsUpdated="getBookings"
                        @closed="selectedBooking = null"
                    ></BookingDetails>
                </div>
            </div>
        </div>
    </div>
    <DeallocateBookingModal />
</template>

<script setup lang="ts">
    import { computed, ref, watch, onBeforeMount, inject } from "vue";
    import { storeToRefs } from "pinia";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiFilter, mdiMagnify, mdiAlert, mdiClipboardTextClockOutline } from "@mdi/js";
    import DateTime from "../DateTime.vue";
    import ClientAvatar from "../Clients/ClientAvatar.vue";
    import BookingDetails from "./BookingDetails.vue";
    import BookingStatus from "../BookingStatus.vue";
    import BookingTime from "@components/Bookings/BookingTime.vue";
    import DeallocateBookingModal from "@components/Bookings/RescheduleBooking/DeallocateBookingModal.vue";
    import VueMultiselect from "vue-multiselect";
    import IconHoverCompanion from "@components/Companion/IconHoverCompanion.vue";
    import { DateTime as Luxon } from "luxon";
    import BookingTimingsHoverIcon from "@components/Bookings/BookingTimingsHoverIcon.vue";
    import BookingListLinkIcon from "@components/Bookings/BookingListLinkIcon.vue";
    import { useRegionStore } from "@stores/RegionStore";
    import { usePaginationStore } from "@stores/PaginationStore";
    import { PageLengthSelector, Pagination, SortableColumn, DataTableSearch } from "@components/DataTable";
    import { userCan } from "../Utils";
    import { Axios } from "axios";
    import BookingActionsDropdown from "./BookingActionsDropdown.vue";

    const toast = inject<any>('toast');
    const axios = inject<Axios>('axios');

    const { selectedRegion } = storeToRefs(useRegionStore());
    const { paginateParams, paginatedMeta, searchQuery } = storeToRefs(usePaginationStore());

    paginateParams.value.sortBy = "date";

    const bookings = ref<App.Resource.Booking[]>([]);
    const dateRange = ref({
        from: null,
        to: null,
    });
    const loadingBookings = ref<boolean>(true);
    const selectedBooking = ref<App.Resource.Booking>(null);
    const statusFilter =  ref<string>("");
    const transportRequirementOptions = ref([]);
    const transportRequirementsFilters = ref([]);
    const pageAmount = ref(25);

    const bookingRequestBodyParams = computed(() => {
        return {
            paginate: 1,
            timezone: timezone.value,
            order_by: paginateParams.value.sortBy,
            order_direction: paginateParams.value.sortDirection.value,
            amount: paginateParams.value.perPage,
            page: paginateParams.value.page,
            date_from: dateRange.value.from,
            date_to: dateRange.value.to,
            status_filter: [statusFilter.value],
            transport_requirements: transportRequirementsFilters.value.map(
                function (requirement) {
                    return {
                        code: requirement.value,
                        value: requirement.number ?? null,
                    };
                }
            ),
            query: searchQuery.value,
        };
    });

    const getBookings = async () => {
        loadingBookings.value = true;

        await axios.get(route("api.bookings.index"), {
                params: bookingRequestBodyParams.value,
            })
            .then((response) => {
                bookings.value = response.data.data;
                paginatedMeta.value = response.data.meta;
            })
            .catch((err) => {
                toast.error('Failed to fetch Bookings');
                console.error(err);
            })
            .finally(() => {
                loadingBookings.value = false;
            });
    };

    const getTransportRequirements = async () => {
        await axios.get(route("api.transport-requirements.options"))
            .then((response) => {
                transportRequirementOptions.value = response.data;
            })
            .catch((err) => {
                toast.error('Failed to fetch transport requirements');
                console.error(err);
            })
    };

    const hasOutOfSyncDates = (booking: App.Models.Booking) => {
        const comparingFormat = "yyyy-MM-dd";

        const originalTimings = [
            booking.planned_origin_time,
            booking.planned_destination_time,
            booking.estimated_origin_time,
            booking.estimated_destination_time,
        ];

        const setTimings = originalTimings.filter((timing) => timing);

        for (let i = 1; i < setTimings.length; i++) {
            if (
                Luxon.fromISO(setTimings[i]).toFormat("yyyy-MM-dd") !==
                Luxon.fromISO(setTimings[0]).toFormat("yyyy-MM-dd")
            ) {
                return true;
            }
        }
        return false;
    };

    const bookingEditUrl = (booking) => {
        return route("bookings.edit", { booking: booking.uuid });
    };

    watch(
        dateRange.value,
        () => {
            getBookings();
        },
    );

    watch(
        transportRequirementsFilters,
        () =>  {
            getBookings();
        },
        { deep: true },
    );

    watch(
        selectedRegion,
        () => {
            getBookings();
        }
    );

    watch(
        paginateParams.value,
        (newParams) => {
            // the if condition is need as the watch is use for record show per page changes
            // removing if condition will result in double call when clicking pagination
            if (pageAmount.value !== newParams.perPage) {
                pageAmount.value = newParams.perPage;
                getBookings();
            }
        },
        { deep: true }
    )

    onBeforeMount(() => {
        getBookings();
        getTransportRequirements();
    });
</script>

<style scoped></style>
