<template>
    <div>
        <template v-if="!currentlyUploading && !maxReached">
            <form enctype="multipart/form-data"
                  class="py-2 rounded-4 position-relative text-center"
                  @dragover.prevent="handleDragOver"
                  @dragleave.prevent="handleDragLeave"
                  :class="{
              'border-primary': dragging,
              'd-inline-block': (windowWidth < 700),
              'drag-box': (windowWidth >= 700)
            }"
                  novalidate>
                <input type="file"
                       multiple
                       :accept="props.options.acceptedFormats"
                       @change="handleFilesChosen"
                       class="position-absolute">
                <template v-if="dragging">
                    <div class="py-3 text-primary">
                        <SvgIcon type="mdi" :path="mdiFilePlusOutline" size="50"/>
                        <div class="pt-2 text-lg">
                            Drop to upload <template v-if="draggingCount > 1">files</template><template v-else>file</template>
                        </div>
                        <div class="pt-1 text-secondary" v-if="windowWidth >= 700">
                            {{ draggingCount }} <template v-if="draggingCount > 1">files</template><template v-else>file</template> selected
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="py-3 px-3 text-secondary">
                        <SvgIcon type="mdi" :path="mdiFilePlusOutline" size="50"/>
                        <div class="pt-2 text-lg">
                            <a href="javascript: void(0)">Click here to select files to upload</a>
                        </div>
                        <div class="pt-1" v-if="windowWidth >= 700">or drag &amp; drop here</div>
                    </div>
                </template>
            </form>
            <div class="pt-2" v-if="windowWidth >= 700">{{ props.options.message }}</div>
        </template>
        <div v-if="currentlyUploading" class="d-flex mx-auto align-items-center justify-content-center">
            <Spinner class="me-2" />Uploading files...
        </div>
        <div v-if="maxReached" class="text-muted">
            You can only upload {{ options.maxUploads }} files.
        </div>
    </div>
</template>

<script>
export default {
    name: "UploaderForm"
}
</script>

<script setup>
import defaultOptions from "../defaultOptions"
import {ref, computed} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiFilePlusOutline} from "@mdi/js";
import Spinner from "../../Spinner.vue";

const dragging = ref(false)
const draggingCount = ref(0)
const windowWidth = ref(window.innerWidth)

const props = defineProps({
    completedUploadCount: {
        type: Number,
        default: 0
    },
    options: {
        required: false,
        type: Object,
        default: () => defaultOptions
    },
    currentlyUploading: {
        type: Boolean,
        default: false
    },
})

const emit = defineEmits(['chosen'])

const maxReached = computed(() => {
    return props.completedUploadCount >= props.options.maxUploads
})

const handleFilesChosen = (event) => {
    dragging.value = false
    let fileArray = []
    let completedUploads = props.completedUploadCount
    Array.prototype.forEach.call(event.target.files, (file) => {
        if (completedUploads + fileArray.length < props.options.maxUploads) {
            fileArray.push(file)
        }
    })

    emit('chosen', fileArray)
}

const handleDragOver = function (event) {
    dragging.value = true
    draggingCount.value = event.dataTransfer.items.length
}

const handleDragLeave = function () {
    dragging.value = false
    draggingCount.value = 0
}

</script>

<style scoped>
input[type=file] {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.drag-box {
    display: block;
    border: 2px dashed #ccc;
}
</style>
