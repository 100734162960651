<template>
    <div>
        <div class="mb-1 d-flex justify-content-between align-items-center">
            <h4 class="header-title mb-0">Current Vehicle Positions</h4>
            <div>
                <span v-if="lastUpdatedAt" class="small me-1"
                    >Last updated: {{ lastUpdatedAt?.toFormat("HH:mm") }}</span
                >
                <a
                    href="javascript: void(0)"
                    v-if="!loading && !reloading"
                    @click="reloadMarkers"
                    title="Reload markers"
                >
                    <SvgIcon
                        class="h-5 w-5"
                        type="mdi"
                        :path="mdiRefresh"
                    ></SvgIcon>
                    <span class="visually-hidden">Refresh</span>
                </a>
                <div
                    v-if="reloading"
                    class="spinner-border spinner-border-sm text-secondary"
                    role="status"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="!loading && !reloading" class="d-flex justify-content-end align-items-center mb-2">
            <a href="javascript:void(0);" style="margin-right: 15px;" @click="clearFilter">
                Clear Filters
            </a>
            <div>
                <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="dropDownFilterPositions"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <SvgIcon
                        type="mdi"
                        :path="mdiFilter"
                        style="max-width: 16px"
                    ></SvgIcon>
                    Filter
                </button>
                <div
                    class="dropdown-menu px-4 py-3"
                    style="min-width: 500px"
                    aria-describedby="dropDownFilterVehiclePositions"
                    id="dropdown-menu-vehicle-position"
                >
                    <div class="mb-2 row">
                        <div
                            class="col-sm-12"
                            style="min-width: 200px"
                        >
                            <label
                                for="driver_id"
                                class="form-label text-sm mb-1"
                            >Driver:</label
                            >
                            <select
                                v-model="driverFilter"
                                id="driver_id"
                                class="form-select form-select-sm"
                            >
                                <option value="">Select Driver</option>
                                <template v-for="driver in dispatchStore.driverList">
                                    <option v-if="driver" :value="driver?.uuid">
                                        {{ driver?.name }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div
                            class="col-sm-12"
                            style="min-width: 200px"
                        >
                            <label
                                for="vehicle_id"
                                class="form-label text-sm mb-1"
                            >Vehicle:</label
                            >
                            <select
                                v-model="vehicleFilter"
                                id="vehicle_id"
                                class="form-select form-select-sm"
                            >
                                <option value="">Select Vehicle</option>
                                <template v-for="vehicle in dispatchStore.vehicleList">
                                    <option v-if="vehicle" :value="vehicle?.uuid">
                                        {{ vehicle?.description }} - {{ vehicle?.registration_code }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div
                            class="col-sm-12"
                            style="min-width: 200px"
                        >
                            <label
                                for="journey_id"
                                class="form-label text-sm mb-1"
                            >Vehicle Run:</label
                            >
                            <select
                                v-model="runFilter"
                                id="vehicle_id"
                                class="form-select form-select-sm"
                            >
                                <option value="">Select Vehicle Run</option>
                                <template v-for="journey in dispatchStore.journeyList">
                                    <option v-if="journey" :value="journey?.uuid">
                                        {{ journey?.uuid }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <button class="btn btn-primary btn-sm" @click="filterMap">
                            Apply Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="{ 'd-none': markers.length === 0 || loading }"
            :style="{
                width: '100%',
                height: mapHeight,
            }"
            :id="uuid"
            class="google-map"
        ></div>
        <div v-if="loading">
            <div
                class="d-flex w-100 justify-content-center align-items-center ps-3"
            >
                <div class="spinner-grow my-5 me-3" role="status"></div>
                <div class="fs-4">Loading Vehicle Positions</div>
            </div>
        </div>
        <div v-if="!loading && !reloading && markers.length === 0">
            <div class="text-center mt-4">
                <SvgIcon
                    class="h-14 w-14 text-secondary"
                    type="mdi"
                    :path="mdiMapMarkerQuestion"
                ></SvgIcon>
                <div class="fs-4 mt-2 mb-3">
                    There are no recent vehicle location updates
                </div>
                <Button
                    :loading="reloading"
                    :icon="mdiRefresh"
                    color="outline-primary"
                    @click="reloadMarkers"
                >
                    Refresh
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import mapstyle from "../themes/caremaster_theme.json";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { uuid as vueUuid } from "vue-uuid";
    import {mdiRefresh, mdiMapMarkerQuestion, mdiFilter} from "@mdi/js";
    import DriverPositionUpdate from "@classes/DriverPositionUpdate";
    import Button from "./Button.vue";
    import { DateTime } from "luxon";
    import { ref, onMounted, watch } from "vue";
    import { useRegionStore } from "@stores/RegionStore";
    import { storeToRefs } from "pinia";
    import VueMultiselect from "vue-multiselect";
    import { useDispatchStore } from "@stores/DispatchStore";

    const { selectedRegion } = storeToRefs(useRegionStore());
    const dispatchStore = useDispatchStore();

    const loading = ref(true);
    const reloading = ref(true);
    const map = ref<undefined | google.maps.Map>();
    const markers = ref<google.maps.Marker[]>([]);
    const bounds = ref<undefined | google.maps.LatLngBounds>();
    const uuid = ref(vueUuid.v1());
    const lastUpdatedAt = ref<DateTime | undefined>();
    const {
        vehicleFilter,
        runFilter,
        driverFilter,
        refreshMapCounter
    } = storeToRefs(dispatchStore);

    const props = withDefaults(
        defineProps<{
            carIconPath: string;
            defaultMapImage: string;
            mapHeight?: string;
        }>(),
        { mapHeight: "400px" }
    );

    const setMarkers = async () => {
        const carIcon = props.carIconPath;
        bounds.value = new google.maps.LatLngBounds();
        const filter = {};

        if (driverFilter.value) {
            filter.driver = driverFilter.value;
        }

        if (vehicleFilter.value) {
            filter.vehicle = vehicleFilter.value;
        }

        if (runFilter.value) {
            filter.run = runFilter.value;
        }

        // clear markers for filtering
        for (let i = 0; i < markers.value.length; i++) {
            markers.value[i].setMap(null);
        }
        markers.value = [];

        await DriverPositionUpdate.getVehiclePositions(filter)
            .then((response) => {
                let gpsDataPoint = response.data;

                gpsDataPoint.forEach((dataPoint) => {
                    let position = new google.maps.LatLng(
                        dataPoint.latitude,
                        dataPoint.longitude
                    );

                    const vehicleRegisterationCode =
                        dataPoint?.journey?.vehicle?.registration_code;

                    //Check if we already have a marker for the vehicle

                    let existingMarker = markers.value.find(
                        (marker) =>
                            //@ts-ignore
                            marker.vehicleRegisterationCode ==
                            vehicleRegisterationCode
                    );

                    //If does not exist, create else update marke position
                    if (!existingMarker) {
                        let marker = new google.maps.Marker({
                            position: position,
                            map: map.value,
                            animation: google.maps.Animation.DROP,
                            title: dataPoint?.journey?.vehicle?.registration_code,
                            //@ts-ignore
                            vehicleRegisterationCode: dataPoint?.journey?.vehicle?.registration_code,
                            icon: carIcon,
                        });

                        marker.addListener("click", () => {
                            let printDate = DateTime.fromISO(
                                dataPoint.position_recorded_at
                            )
                                .toLocal()
                                .toFormat("dd LLL yyyy HH:mm");

                            new google.maps.InfoWindow({
                                content: `
                                    <strong>
                                        ${dataPoint?.journey?.driver?.given_names} ${dataPoint?.journey?.driver?.last_name}
                                    </strong>
                                    <br>
                                        ${dataPoint?.journey?.vehicle?.description} (${dataPoint?.journey?.vehicle?.registration_code})
                                    <br>
                                        ${dataPoint?.journey?.driver?.phone}
                                    <br>
                                        ${printDate}`,
                            }).open({
                                anchor: marker,
                                map: map.value,
                                shouldFocus: false,
                            });
                        });
                        let currentBounds = bounds.value;

                        currentBounds?.extend(position);
                        bounds.value = currentBounds;

                        markers.value = [...markers.value, marker];
                    } else {
                        existingMarker.setPosition(position);
                    }

                    lastUpdatedAt.value = DateTime.now();
                });
            })
            .finally(() => {
                loading.value = false;
                reloading.value = false;

                document.getElementById('dropdown-menu-vehicle-position')?.classList.remove("show");
                document.getElementById('dropDownFilterPositions')?.classList.remove("show");
            });
    };

    const reloadMarkers = () => {
        reloading.value = true;
        setMarkers();
    };

    const filterMap = () => {
        const element = document.getElementById(uuid.value ?? "");

        if (!element) {
            return;
        }

        map.value = new google.maps.Map(element, {
            styles: mapstyle,
        });

        const mapValue = map.value;

        if (!mapValue) {
            return;
        }

        const trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(map.value);
        setMarkers().then(() => {
            let boundsValue = bounds.value;
            if (!boundsValue) {
                return;
            }

            mapValue.fitBounds(boundsValue);

            google.maps.event.addListenerOnce(
                mapValue,
                "bounds_changed",
                function (event) {
                    if ((mapValue.getZoom() ?? 0) < 10) {
                        mapValue.setZoom(10);
                    }
                }
            );
        });
    };

    const clearFilter = () => {
        dispatchStore.clearFilter();
        filterMap();
    }

    watch(
        selectedRegion,
        () => {
            filterMap();
            // reloadMarkers()
        }
    );

    watch(refreshMapCounter, () => {
        filterMap();
    })

    onMounted(() => {
        filterMap();
        setInterval(filterMap, 60 * 1000);
    });
</script>
<style scoped>
.grayed-map label {
    display: inline-block;
    position: relative;
    top: -30px;
    left: 10px;
    color: #222;
}
</style>
