<template>
    <div>
       <div class="row mb-2">
           <div class="col-md-6">
               <a class="btn btn-primary" :href="route('vehicles.create')">
                   <i class="mdi mdi-plus-circle me-1"></i>
                   Add Vehicle
               </a>
           </div>
           <!-- <div class="col-md-6 text-end">
               <button class="btn btn-primary me-1" type="button">
                   <i class="mdi mdi-cog"></i>
               </button>
               <button class="btn btn-light me-1" type="button">
                   <i class="mdi mdi-database-import-outline me-1"></i>Import
               </button>
               <button class="btn btn-light" type="button">
                   <i class="mdi mdi-database-export-outline me-1"></i>Export
               </button>
           </div> -->
       </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label text-nowrap">
                            Display
                            <select v-model="amountToDisplay" class="form-select form-select-sm d-inline mx-1">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option :value="paginationMeta.total">All</option>
                            </select>
                            vehicles
                        </label>
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <label class="form-label">
                            <div class="input-group input-group-sm">
                                <input v-model="query" @keyup.enter="getVehicles()" :disabled="loadingVehicles" type="search" class="form-control form-control-sm"
                                   placeholder="Search vehicle rego...">
                                <button class="btn btn-outline-secondary" :disabled="loadingVehicles" type="button" @click="getVehicles()">
                                    <SvgIcon
                                        type="mdi"
                                        :path="searchIconPath"
                                        style="width: 1.3em"
                                    ></SvgIcon>
                                </button>
                            </div>
                        </label>
                    </div>
                </div>

                <table v-if="!loadingVehicles" class="table table-striped table-sm table-centered dt-responsive nowrap w-100">
                    <thead>
                    <tr>
                        <th class="sorting">Registration</th>
                        <th class="sorting">Vehicle</th>
                        <th class="sorting text-center">Odometer</th>
                        <th class="sorting text-center">Region</th>
                        <th class="sorting text-center">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(vehicle, index) in datatable.vehicles" :key="index">
                        <td>
                            <template v-if="vehicle.registration_code">
                                {{ regoFilter(vehicle.registration_code) }}
                            </template>
                        </td>
                        <td>
                            <a :href="route('vehicles.show', { 'vehicle': vehicle.uuid })"
                               class="d-flex align-items-center">
                                <div>
                                    <img
                                        :src="'/images/vehicles/'+ vehicle.image +  '.png'"
                                        class="img-fluid me-3"
                                        style="max-width: 70px;"
                                        alt="Vehicle Icon"
                                    >
                                </div>
                                <div>
                                    {{ vehicle.description }}<br>
                                    <small class="text-muted">
                                        {{ regoFilter(vehicle.registration_code) }}
                                    </small>
                                </div>
                            </a>
                        </td>
                        <td class="text-center">
                            <template v-if="vehicle.odometer">{{ odometerFilter(vehicle.odometer) }}
                            </template>
                            <template v-else>–</template>
                        </td>
                        <td class="text-center">
                            <template v-if="vehicle.region">
                                {{ vehicle.region }}
                            </template>
                        </td>
                        <td class="text-center">
                            <span class="badge" :class="status(vehicle)">{{
                                vehicle.available ? 'Available' : 'Unavailable'
                            }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div v-if="loadingVehicles" class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading vehicles</div>
                </div>

                <Pagination
                    v-if="!loadingVehicles"
                    @pageChanged="loadPage"
                    :pagination="paginationMeta"
                    record-name="vehicles"
                ></Pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {inject, onBeforeMount, reactive, ref, watch} from 'vue'
    import { mdiMagnify } from '@mdi/js';
    import SvgIcon from "@jamescoyle/vue-icon"
    import { odometerFilter, regoFilter } from '../Utils'
    import Pagination from "../Pagination.vue"
    import { useRegionStore } from "@stores/RegionStore";
    import { storeToRefs } from "pinia";

    const { selectedRegion } = storeToRefs(useRegionStore());

    const axios = inject('axios')
    const toast = inject('toast')

    const amountToDisplay = ref(25)
    const query = ref('')
    const page = ref(1)
    const searchIconPath = mdiMagnify
    let loadingVehicles = ref(false)
    const paginationMeta = ref({})

    const datatable = reactive({
        vehicles: {}
    })

    const loadPage = (link) => {
        page.value = link.page
    }

    const getVehicles = function (uri = 'api.vehicles.index') {

        loadingVehicles.value = true

        let params = {
            paginate: true,
            page: page.value,
            limit: amountToDisplay.value
        }

        if (query.value.trim().length > 0) {
            params.query = query.value
            uri = 'api.vehicles.search'
        }

        axios
            .get(route(uri, params))
            .then(resp => {
                paginationMeta.value = resp.data.meta
                datatable.vehicles = resp.data.data
            })
            .catch(err => {
                console.error('Sorry! Something went wrong')
            })
            .finally(() => {
                loadingVehicles.value = false
            })
    }

    const status = function (vehicle) {
        return vehicle.available ? 'badge-success-lighten' : 'badge-danger-lighten';
    }

    watch(amountToDisplay, () => getVehicles())

    watch(selectedRegion, () => getVehicles());

    watch(page, () => getVehicles())

    onBeforeMount(() => getVehicles())

    const editVehicle = (uuid) => {
        location.href = route('vehicles.edit', { 'vehicle': uuid })
    }
</script>

<style scoped>

</style>
