<template>
    <a href="javascript: void(0)">
        <div class="alert alert-info">
            <div class="float-end text-xs fst-italic">Click to open chat</div>
            <div class="d-flex text-start align-items-center">
                <SvgIcon type="mdi" :path="messageIcon" class="me-2 flex-shrink-1"></SvgIcon>
                <div>
                    {{ driver.name }}
                    <div class="text-xs">New message sent {{ moment(this.sentAt).fromNow() }}</div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import { mdiMessageBadgeOutline } from '@mdi/js'
import SvgIcon from "@jamescoyle/vue-icon"
export default {
    name: "UnreadMessage",
    components: {
        SvgIcon,
    },
    props: {
        driver: {
            type: Object,
            required: true,
        },
        sentAt: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            messageIcon: mdiMessageBadgeOutline,
        }
    }
}
</script>

<style scoped>

</style>
