import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import FundingRule from "@classes/FundingRules";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 30;

export const useFundingRuleStore = defineStore(
    "fundingRuleStore",
    () => {
        const fundingRules = ref<App.Models.FundingRule[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.FundingRule[]> => {
            return new Promise((resolve, reject) => {
                FundingRule.index()
                    .then((response) => {
                        fundingRules.value = response.data.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(fundingRules.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                fundingRules.value
            );
        };

        return {
            fundingRules,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "fundingRuleStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
