<template>
    <div class="d-inline-block">
        <span
            v-if="!editing && !loading"
            @click="editing = !(loading || disabled)"
            :class="(loading || disabled) ? null : 'click-to-edit'"
        >
            <template v-if="fieldValue || disabled"><slot>{{ currencyFilter(fieldValue) }}</slot></template>
            <template v-else><small><em>Click to add</em></small></template>
        </span>
        <div class="d-flex align-items-center" v-if="editing || loading">
            <input
                v-focus
                v-model="fieldValue"
                :disabled="updating"
                type="number"
                class="form-control form-control-sm"
            >
            <template v-if="!loading">
                <a href="javascript:void(0);" class="px-1" @click="update">
                    <SvgIcon type="mdi" :path="mdiCheck" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
                <a href="javascript:void(0);" class="px-1" @click="editing = false">
                    <SvgIcon type="mdi" :path="mdiClose" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
            </template>
            <Spinner v-else class="ms-1" type="border" size="small"></Spinner>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, toRef } from "vue"
    import Spinner from "../../Spinner.vue"
    import SvgIcon from "@jamescoyle/vue-icon"
    import { mdiCheck, mdiClose } from "@mdi/js"
    import { copyValues, currencyFilter } from "@components/Utils";

    const props = withDefaults(
        defineProps<{
            value: number,
            loading?: boolean,
            disabled?: boolean,
            updating?: boolean
        }>(),
        {
            loading: false,
            disabled: false,
            updating: false,
        }
    );

    const fieldValue = ref(copyValues(props.value));
    let editing = ref<boolean>(false)

    const emit = defineEmits<{
        (e: 'updated', value: number): void
    }>()

    const update = function (): void {
        editing.value = false
        emit('updated', fieldValue.value)
    }

</script>
