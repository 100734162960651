import {defineStore} from "pinia";
import {ref} from "vue";
import {uuid} from "vue-uuid";

export const customReportStore = defineStore('customReport', () => {
    const ruleSets = ref([]);
    const fieldsAvailable = ref([]);
    const ruleFieldsAvailable = ref([]);
    const showUpdateButton = ref(false);

    const addRuleSet = () => {
        ruleSets.value.push({
            id: uuid.v4(),
            matchType: 'all',
            rules: []
        })
    }

    const removeRuleSet = (id) => {
        ruleSets.value = ruleSets.value.filter(ruleSet => ruleSet.id !== id)
    }

    const getRuleSet = (id) => {
        return ruleSets.value.find(ruleSet => ruleSet.id === id)
    }

    const addRule = (ruleSetId) => {
        const ruleSet = getRuleSet(ruleSetId)
        ruleSet.rules.push({
            id: uuid.v4(),
            field: ruleFieldsAvailable.value[0],
            operator: 'contains',
            value: null
        })
    }

    const removeRule = (ruleSetId, ruleId) => {
        const ruleSet = getRuleSet(ruleSetId)
        ruleSet.rules = ruleSet.rules.filter(rule => rule.id !== ruleId)
    }

    const getRule = (ruleSetId, ruleId) => {
        const ruleSet = getRuleSet(ruleSetId)
        return ruleSet.rules.find(rule => rule.id === ruleId)
    }

    return {
        ruleSets,
        fieldsAvailable,
        ruleFieldsAvailable,
        showUpdateButton,
        addRuleSet,
        removeRuleSet,
        getRuleSet,
        addRule,
        removeRule,
        getRule
    }
});
