<template>
    <div class="card">
        <div class="card-body">
            <h4 class="header-title mt-0 mb-3">Licence, Background &amp; Medical</h4>
            <dl class="row mb-0">
                <dt class="col-sm-5">Driver's Licence:</dt>
                <dd class="col-sm-7">
                    <EditDriverLicenceExpiry :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Licence Classes:</dt>
                <dd class="col-sm-7">
                    <EditDriverLicenceClasses :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Driver Authority:</dt>
                <dd class="col-sm-7">
                    <EditDriverAuthority :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Demerit Check:</dt>
                <dd class="col-sm-7">
                    <EditDriverDemeritCheck :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">NDIS Worker Expiry Date:</dt>
                <dd class="col-sm-7">
                    <EditDriverNdisWorkerExpiry :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Police Check Expiry:</dt>
                <dd class="col-sm-7">
                    <EditDriverPoliceCheckExpiry :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Medical Check Expiry:</dt>
                <dd class="col-sm-7">
                    <EditDriverMedicalExpiry :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Annual Training Completed Date:</dt>
                <dd class="col-sm-7">
                    <EditDriverAnnualTraining :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Covid Safety Sign Off Date:</dt>
                <dd class="col-sm-7">
                    <EditDriverCovidSignOff :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
            <dl class="row mb-0">
                <dt class="col-sm-5">Flu Vaccine Date:</dt>
                <dd class="col-sm-7">
                    <EditDriverFluVaccine :driver="driver" :read-only="readOnly"/>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ColorContextualDate from "../ColorContextualDate.vue"
import EditDriverAuthority from "./EditDriverAuthority.vue"
import EditDriverNdisWorkerExpiry from "./EditDriverNdisWorkerExpiry.vue"
import EditDriverDemeritCheck from "./EditDriverDemeritCheck.vue"
import EditDriverLicenceExpiry from "./EditDriverLicenceExpiry.vue"
import EditDriverLicenceClasses from "./EditDriverLicenceClasses.vue"
import EditDriverMedicalExpiry from "./EditDriverMedicalExpiry.vue"
import EditDriverPoliceCheckExpiry from "./EditDriverPoliceCheckExpiry.vue"
import EditDriverAnnualTraining from "@components/Drivers/EditDriverAnnualTraining.vue";
import EditDriverCovidSignOff from "@components/Drivers/EditDriverCovidSignOff.vue";
import EditDriverFluVaccine from "@components/Drivers/EditDriverFluVaccine.vue";

const props = defineProps({
    driver: {
        type: Object,
        required: true,
    },
})

const readOnly = ref(!window.permissions.includes('update_driver'))

</script>
