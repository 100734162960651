<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center flex-fill">
                        <label class="mx-2 text-nowrap">Start Date</label>
                        <input
                            class="form-control"
                            type="date"
                            style="max-width: 11rem"
                            v-model="dateFrom"
                        />
                        <label class="mx-2">End Date</label>
                        <input
                            class="form-control"
                            type="date"
                            style="max-width: 11rem"
                            v-model="dateTo"
                        />
                    </div>

                    <div>
                        <Button
                            :loading="false"
                            :icon="mdiRefresh"
                            @click="loadBookings"
                            size="small"
                        >
                            Update Preview
                        </Button>
                        <Button
                            class="ms-2"
                            v-if="bookings?.data"
                            :loading="false"
                            @click="openConfirmationModal"
                            size="small"
                            color="danger"
                        >
                            Apply Changes
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="loading">
            <div
                class="card-body d-flex align-items-center justify-content-center"
            >
                <div class="spinner-grow text-primary" role="status"></div>
            </div>
        </div>
        <div class="card" v-if="!loading && bookings?.data">
            <div class="card-body">
                <h4 class="header-title mt-0 mb-3">Data Preview</h4>
                <div class="table-responsive">
                    <table class="table table-xs table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style="white-space: nowrap">Date</th>
                                <th style="white-space: nowrap">Client</th>
                                <th style="white-space: nowrap">
                                    Sequence Identifier
                                </th>
                                <th style="white-space: nowrap">Leg Order</th>
                                <th style="white-space: nowrap">Status</th>
                                <th style="white-space: nowrap">
                                    Estimated Distance
                                </th>
                                <th style="white-space: nowrap">
                                    Actual Distance
                                </th>
                                <th style="white-space: nowrap">
                                    Current Total
                                </th>
                                <th style="white-space: nowrap">New Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-for="(booking, key) in bookings?.data ?? []"
                                :key="booking?.uuid ?? key"
                            >
                                <BookingFareAdjustmentRow :booking="booking" />
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="mt-4">
                    <Pagination
                        @pageChanged="updatePage"
                        :pagination="paginationMeta"
                        record-name="records"
                    ></Pagination>
                </div>
            </div>
        </div>

        <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="header-title mt-0">Are you sure?</h4>
                    </div>
                    <div class="modal-body pb-3">
                        Have you double checked all the New Fare Totals that
                        will be assigned to the bookings?
                    </div>
                    <div class="modal-footer">
                        <Button
                            class="btn btn-secondary"
                            @click="modal?.hide()"
                        >
                            Close
                        </Button>
                        <Button
                            :loading="saving"
                            class="btn btn-danger"
                            @click="applyChanges"
                        >
                            {{
                                saving
                                    ? "Applying Changes"
                                    : "Apply Fare Changes"
                            }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, watch } from "vue";
import Pagination from "@components/Pagination.vue";
import BookingResource from "@/resources/js/types/resources/BookingResource";
import Booking, { BookingStatus } from "@classes/Booking";
import Button from "@components/Button.vue";
import { mdiDownload, mdiRefresh } from "@mdi/js";
import {
    LaravelPaginate,
    PaginatedMetaInterface,
} from "@/resources/js/types/LaravelCommonTypes";
import { DateTime } from "luxon";
import BookingFareAdjustmentRow from "@components/Services/BookingFareAdjustment/BookingFareAdjustmentRow.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Fare from "@classes/Fare";

const toast = inject<any>("toast");

const dateFrom = ref<null | string>(null);
const dateTo = ref<null | string>(null);

const loading = ref<boolean>(false);
const saving = ref<boolean>(false);

const page = ref<number>(1);

const modalId = ref("BookingFareAdjustmentConfirmationModal");

const modal = ref<null | bootstrap.Modal>(null);

const updatePage = (link) => {
    page.value = link.page;
    loadBookings();
};
const paginationMeta = ref<any>({});
const bookings = ref<LaravelPaginate<BookingResource> | undefined>();

const loadBookings = () => {
    if (!dateFrom.value || !dateTo.value) {
        toast.error("Please select a date range.");
        return;
    }
    loading.value = true;

    let bookingStatuses: string[] = [];
    Object.keys(BookingStatus).forEach((key) => {
        bookingStatuses.push(BookingStatus[key]);
    });

    let notCancelledStatuses = bookingStatuses.filter(
        (status) => status !== BookingStatus.Cancelled
    );

    Booking.indexPaginated({
        page: page.value,
        date_from: DateTime.fromISO(dateFrom.value ?? "2023-09-27"),
        date_to: DateTime.fromISO(dateTo.value ?? "2023-09-28"),
        order_by: "date_client_sequence",
        order_direction: "desc",
        status_filter: notCancelledStatuses,
        amount: 15,
    }).then((response) => {
        bookings.value = response.data;
        paginationMeta.value = response.data.meta;
    });
    loading.value = false;
};

const openConfirmationModal = () => {
    modal.value?.show();
};

const applyChanges = () => {
    if (!dateFrom.value || !dateTo.value) {
        toast.error("Please select a date range.");
        return;
    }
    saving.value = true;
    Fare.recalculateByDateRange({
        date_from: DateTime.fromISO(dateFrom.value ?? "2023-09-27"),
        date_to: DateTime.fromISO(dateTo.value ?? "2023-09-28"),
    })
        .then((response) => {
            console.log(response);
            toast.success("Fare changes applied.");
            modal.value?.hide();
            loadBookings();
        })
        .finally(() => {
            saving.value = false;
        });
};

const initModal = () => {
    const modalElement = document.getElementById(modalId.value);
    if (!modalElement) {
        return;
    }
    modal.value = new bootstrap.Modal(modalElement, {});
};

watch(
    () => [dateFrom.value, dateTo.value],
    () => {
        bookings.value = undefined;
    }
);

onMounted(() => {
    initModal();
});
</script>
