<template>
    <div class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="Object.fromEntries(Object.values(reasons).map((reason) => [reason.uuid, reason.name]))"
            :value="booking.booking_cancelation_reason?.name"
            @updated="update"
        >
            {{ booking.booking_cancelation_reason?.name }}
        </EditableSelect>
    </div>
</template>

<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {ref, inject} from "vue";
import axios from "axios";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
    reasons: {
        type: Object,
        required: true,
    },
})

const updating = ref(false)
const update = function (newReason) {
    updating.value = true
    axios
        .put(route('api.bookings.update', {booking: props.booking.uuid}), {
            cancellation_reason: newReason,
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            toast.success('Booking cancellation reason updated.')
        })
        .catch((error) => {
            toast.error('There was an error updating the booking purpose.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
