<template>
    <div class="row">
        <div class="col-7">
            <h4>Data Imports List</h4>
        </div>
        <div class="col-5 d-inline-flex justify-content-center align-items-center">
            <DataImportTypeSelect
                :show-label="false"
                @selected="(type) => templateType = type"
            />
            <Button 
                :icon="mdiDownload"
                :loading="downloadingTemplate"
                :disable="templateType == null"
                @click="downloadTemplate"
                class="ms-1"
            >Download</Button>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <PageLengthSelector
                            record-name="imports"
                            :disabled="loadingImports"
                        />
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                    </div>
                </div>
                <template v-if="loadingImports">
                    <div class="d-flex w-100 justify-content-center align-items-center">
                        <div
                            class="spinner-grow my-5 me-3"
                            role="status"
                        ></div>
                        <div class="fs-4">Loading Data Imports</div>
                    </div>
                </template>
                <template v-if="!loadingImports">
                    <table class="table table-centered table-striped table-xs nowrap w-100 text-sm">
                        <thead>
                            <th class="text-center" style="width: 10%">Id</th>
                            <th class="text-center" style="width: 20%"><SortableColumn param="created_at">date</SortableColumn></th>
                            <th class="text-center" style="width: 20%"><SortableColumn param="type">Type</SortableColumn></th>
                            <th class="text-center" style="width: 10%"><SortableColumn param="status">Status</SortableColumn></th>
                            <th class="text-center" style="width: 40%">Details</th>
                        </thead>
                        <tbody v-for="(dataImport, importNo) in dataImports" :key="importNo">
                            <td>{{ dataImport.uuid }}</td>
                            <td>{{ dataImport.date }}</td>
                            <td>{{ dataImport.type }}</td>
                            <td>
                                <span 
                                    class="badge rounded-pill p-1 fs-5" 
                                    :class="statusBadgeColour(dataImport.status.value)"
                                >
                                    {{ upperCaseWords(dataImport.status.value) }}
                                </span>
                            </td>
                            <td>
                                <div v-if="dataImport.failures" class="failureDetails">
                                    <div v-for="(failure, key) in dataImport.failures" :key="key" >
                                        <div @click="toggleFailureShowing(key + '_import_' + importNo)" class="failureHeading">
                                            <strong class="py-2">{{ prettifyWord(key) }} Validation Errors:</strong>
                                            <div>
                                                <SvgIcon type="mdi" :path="toggleFailureToggleIcon(key + '_import_' + importNo)" class="me-1"/>
                                            </div>
                                        </div>
                                        <div v-if="failureShowing === key + '_import_' + importNo" class="mt-2">
                                            <div v-for="(details, idx) in failure" :key="idx" class="ms-2">
                                                <strong>Attribute:</strong> {{ details.attribute }}<br />
                                                <strong>Errors:</strong> 
                                                <ul v-for="(error, idx2) in details.errors" :key="idx2">
                                                    <li>{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="dataImport.errors">
                                    Data Import Exception:
                                    {{ dataImport.errors }}
                                </template>
                            </td>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="!loadingImports"
                        @pageChanged="getImports"
                    ></Pagination>
                </template>
            </div>
        </div>
    </div> 
</template>

<script setup lang="ts">
    import { onMounted, onBeforeMount, ref } from 'vue';
    import DataImportTypeSelect from './DataImportTypeSelect.vue';
    // @ts-ignore
    import { Button } from '@components';
    import { mdiDownload, mdiChevronDown, mdiChevronUp } from "@mdi/js";
    import { PageLengthSelector, SortableColumn, Pagination } from "@components/DataTable";
    import { useDataImportStore } from "@stores/DataImportStore";
    import { storeToRefs } from "pinia";
    import SvgIcon from "@jamescoyle/vue-icon";

    const dataImportStore = useDataImportStore();
    const { dataImports, loadingImports } = storeToRefs(dataImportStore);
    const { getImports } = dataImportStore;

    const templateType = ref<string | null>(null);
    const downloadingTemplate = ref<boolean>(false);
    const failureShowing = ref<string | null>(null);
    
    const statusBadgeColours = {
        queued: 'text-bg-primary',
        processing: 'text-bg-info',
        completed: 'text-bg-success',
        failed: 'text-bg-danger',
    };
    
    const downloadTemplate = (): void => {
        downloadingTemplate.value = true;

        if (templateType.value) {
            location.href = route('services.data-import.templates', {type: templateType.value});
        }

        downloadingTemplate.value = false;
    }

    const statusBadgeColour = (status: string):string => {
        return statusBadgeColours[status];
    }

    const upperCaseWords = (words: string): string => {
        return words.replace(/\b\w/g, (match) => match.toUpperCase())
    }

    const prettifyWord = (words: string): string => {
        words = words.replace('_', ' ');
        return upperCaseWords(words);
    }

    const toggleFailureShowing = (key): void => {
        if (failureShowing.value === key) {
            failureShowing.value = null;
            return;
        }

        failureShowing.value = key;
    }

    const toggleFailureToggleIcon = (key): string => {
        if (failureShowing.value === key) {
            return mdiChevronUp;
        }

        return mdiChevronDown;
    }
    
    const refreshDataImportList = () => {
        setTimeout(() => {
            getImports();
            refreshDataImportList();
            
        }, 60000);
    }

    onBeforeMount(() => {
        getImports();
    })

    onMounted(() => {
        refreshDataImportList()
    })
</script>

<style scoped>
    .failureHeading {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #999;
    }

    .failureDetails {
        border: none !important;
    }
</style>