<template>
    <div :id="componentId + '_modal'" :aria-labelledby="componentId + '_modal_label'" aria-hidden="true"
         class="modal fade text-start" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 :id="componentId + '_modal_label'" class="modal-title">Create Location</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <FormTextInput
                            :key="location.name.uuid"
                            v-model:input="location.name"
                            label="Description"
                            optional
                            placeholder="e.g. Northern Hospital"
                        ></FormTextInput>
                    </div>
                    <div class="mt-3">
                        <AddressGoogleSearch
                            v-model="location.use_address.value"
                            label="Address"
                            @selected-address-detail="handleSelectedAddressDetail"
                        />
                    </div>
                    <div>
                        <div class="mt-3">
                            <FormTextInput
                                :key="location.address_line_1.uuid"
                                v-model:input="location.address_line_1"
                                label="Address Line 1"
                                placeholder="e.g. 123 Fake Street"
                            />
                        </div>
                        <div class="mt-3">
                            <FormTextInput
                                :key="location.address_line_2.uuid"
                                v-model:input="location.address_line_2"
                                label="Address Line 2"
                                optional
                                placeholder="e.g. Apartment 15"
                            />
                        </div>
                        <div class="mt-3 row">
                            <div class="col-md-4">
                                <FormTextInput
                                    :key="location.suburb.uuid"
                                    v-model:input="location.suburb"
                                    label="Suburb"
                                    placeholder="e.g. Newcastle"
                                />
                            </div>
                            <div class="col-md-4">
                                <FormSelect
                                    :key="location.state.uuid"
                                    v-model:selection="location.state"
                                    :options="australianStates"
                                    label="State"
                                    placeholder="Select a state..."
                                />
                            </div>
                            <div class="col-md-4">
                                <FormNumberInput
                                    :key="location.postcode.uuid"
                                    v-model:input="location.postcode"
                                    :mask="{
                                                            mask: Number,
                                                            scale: 0,
                                                            signed: false,
                                                            thousandsSeparator: ''
                                                        }"
                                    label="Postcode"
                                    placeholder="e.g. 2000"
                                />
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-6">
                                <FormTextInput
                                    :key="location.latitude.uuid"
                                    v-model:input="location.latitude"
                                    label="Latitude"
                                    placeholder="e.g. -33.85662"
                                />
                            </div>
                            <div class="col-md-6">
                                <FormTextInput
                                    :key="location.longitude.uuid"
                                    v-model:input="location.longitude"
                                    label="Longitude"
                                    placeholder="e.g. 151.21534"
                                />
                            </div>
                        </div>
                        <div v-if="location.latitude.value && location.longitude.value" class="mt-2">
                            <GoogleMapWithPin
                                :key="location.latitude.value + location.longitude.value"
                                :locations="[{lat: location.latitude.value, lng: location.longitude.value}]"
                            />
                        </div>
                        <div class="mt-3">
                            <FormSelect
                                :key="location.timezone.uuid"
                                v-model:selection="location.timezone"
                                :options="timezoneOptions"
                                label="Timezone"
                                optional
                                placeholder="Select a timezone..."
                            />
                        </div>
                    </div>
                    <div class="my-3">
                        <div v-if="!showCreateLocationTypeForm">
                            <FormSelect
                                :key="location.location_type.uuid"
                                v-model:selection="location.location_type"
                                :options="locationTypes"
                                label="Location type"
                                optional
                                placeholder="Select a location type..."
                            />
                            <div class="d-flex justify-content-end mt-1">
                                <a @click.prevent="createLocationType" href="javascript: void(0)">Create new</a>
                            </div>
                        </div>
                        <div v-else>
                            <FormTextInput
                                :key="location.location_type.uuid"
                                v-model:input="location.location_type"
                                label="Create a new location type"
                                placeholder="e.g. Library"
                            />
                            <div class="d-flex justify-content-end mt-1">
                                <Button @click="hideCreateLocationTypeForm" size="small" color="light">Cancel</Button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <FormTextarea
                            :key="location.driver_alert.uuid"
                            v-model:input="location.driver_alert"
                            label="Driver alert"
                            maxlength="250"
                            optional
                            placeholder="e.g. Low ceiling in car park"
                        ></FormTextarea>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" data-bs-dismiss="modal" type="button">Close</button>
                        <Button @click="storeLocation" :loading="storingLocation">Save location</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateLocationForm"
}
</script>
<script setup>
import {FormNumberInput, FormSelect, FormTextarea, FormTextInput} from "@components/Forms";
import GoogleMapWithPin from "@components/GoogleMapWithPin.vue";
import AddressGoogleSearch from "@components/AddressGoogleSearch.vue";
import Button from "@components/Button.vue";
import {inject, onMounted, ref, watch} from "vue";
import {uuid} from "vue-uuid";
import axios from "axios";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { useLocationTypeStore } from "@stores/LocationTypeStore";

const locationTypeStore = useLocationTypeStore();

const toast = inject('toast')
const componentId = ref(uuid.v4());
const storingLocation = ref(false);
const modal = ref(null);
const location = ref({});

const timezones = Intl.supportedValuesOf('timeZone');
const timezoneOptions = timezones.map(timezone => {
    return {
        value: timezone,
        label: timezone
    }
});

const australianStates = ref([
    {value: 'ACT', label: 'ACT'},
    {value: 'NSW', label: 'NSW'},
    {value: 'NT', label: 'NT'},
    {value: 'QLD', label: 'QLD'},
    {value: 'SA', label: 'SA'},
    {value: 'TAS', label: 'TAS'},
    {value: 'VIC', label: 'VIC'},
    {value: 'WA', label: 'WA'},
]);

const emit = defineEmits(['stored']);


const props = defineProps({
    showModal: {
        type: Boolean,
        required: true,
        default: false,
    }
});

const initialiseLocation = function () {
    location.value = {
        name: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
        },
        use_address: {
            value: {},
        },
        address_line_1: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        address_line_2: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: false,
        },
        suburb: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        state: {
            uuid: uuid.v4(),
            value: 'NSW',
            errors: [],
            required: true,
        },
        postcode: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        latitude: {
            uuid: uuid.v4(),
            value: -3385662,
            errors: [],
            required: true,
        },
        longitude: {
            uuid: uuid.v4(),
            value: 15121534,
            errors: [],
            required: true,
        },
        timezone: {
            uuid: uuid.v4(),
            value: 'Australia/Sydney',
            errors: [],
        },
        driver_alert: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
        },
        location_type: {
            uuid: uuid.v4(),
            value: null,
            errors: [],
        },
    }
}
initialiseLocation();
const storeLocation = function () {
    storingLocation.value = true;
    let baseRequest

    let parameters = Object.keys(location.value).reduce((accumulator, key) => {
        accumulator[key] = location.value[key].value;
        return accumulator;
    }, {});

    parameters.location_type = location.value.location_type.value;

    if (parameters.use_address.uuid && Object.values(parameters.use_address.uuid).length > 0) {
        baseRequest = axios.put(route('api.locations.update', {location: parameters.use_address.uuid}), {
            name: parameters.name,
            driver_alert: parameters.driver_alert,
            location_type: location.value.location_type.value
        })
    } else {
        baseRequest = axios.post(route('api.locations.store'), parameters);
    }

    baseRequest
        .then(response => {
            toast.success('New location created');
            initialiseLocation();
            hideCreateLocationTypeForm()
            emit('stored', response.data.data);
            modal.value.hide();
        })
        .finally(() => {
            storingLocation.value = false;
            refreshKey.value = Date.now();
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
                toast.error('There was an error creating the location. Please check the form and try again.')
                Object.keys(error.response.data.errors).forEach(key => {
                    location.value[key].errors = error.response.data.errors[key];
                });
            }
        });
}

const resetForm = () => {
    initialiseLocation();
    showCreateLocationTypeForm.value = false;
}

const showCreateLocationTypeForm = ref(false)
const createLocationType = function () {
    location.value.location_type.value = null;
    showCreateLocationTypeForm.value = true;
}

const hideCreateLocationTypeForm = () => {
    showCreateLocationTypeForm.value = false;
    location.value.location_type.value = null;
}

onMounted(() => {
    getLocationTypes();
    modal.value = new bootstrap.Modal(document.getElementById(componentId.value + '_modal'));
});

watch(() => props.showModal, (value) => {
    if (value == true) {
        modal.value.show();
    } else {
        modal.value.hide();
    }
});


const handleSelectedAddressDetail = (selectedAddressDetail) => {
    let newLocation = location.value;

    newLocation.address_line_1.value = selectedAddressDetail.address_line_1;
    newLocation.address_line_2.value = selectedAddressDetail.address_line_2;
    newLocation.suburb.value = selectedAddressDetail.suburb;
    newLocation.state.value = selectedAddressDetail.state;
    newLocation.postcode.value = selectedAddressDetail.postcode;
    newLocation.latitude.value = selectedAddressDetail.latitude;
    newLocation.longitude.value = selectedAddressDetail.longitude;

    location.value = newLocation;
}

const locationTypes = ref([]);
const getLocationTypes = async () => {
    let fullLocationTypes = await locationTypeStore.sync();
    locationTypes.value = fullLocationTypes.map(type => {
        return {
            value: type.uuid,
            label: type.description
        }
    });
}


</script>

<style scoped>

</style>
