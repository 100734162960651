<template>
    <div>
        <label class="form-label">
            {{ setting?.title }}
        </label>
        <div>
            {{ setting?.description }}
        </div>
        <select
            v-model="selection"
            class="form-select mt-2"
            @change="updateSetting"
        >
            <option
                v-for="(option, index) in setting?.options ?? []"
                :key="index"
                :value="option"
            >
                {{ getOptionTitle(option) }}
            </option>
        </select>
        <div v-if="saved" class="d-flex align-items-center mt-2">
            <SvgIcon
                type="mdi"
                :path="mdiCheckCircle"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { useOrganisationSettingStore } from "@stores/OrganisationSettingStore";
import OrganisationSetting from "@classes/OrganisationSetting";
import { storeToRefs } from "pinia";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle } from "@mdi/js";
import axios from "axios";

const organisationSettingStore = useOrganisationSettingStore();

const { organisationSettings } = storeToRefs(organisationSettingStore);
const selection = ref();
const saved = ref(false);

const setting = computed(() => {
    return organisationSettings.value.find(
        (setting) => setting.name === props.name
    );
});

const options = computed(() => {
    return setting.value?.options ?? [];
});

const props = withDefaults(
    defineProps<{
        name: string;
    }>(),
    {}
);

onMounted(() => {
    organisationSettingStore.sync();
    selection.value = organisationSettingStore.organisationSettingCachedValue(
        props.name
    );
});

const updateSetting = (e) => {
    const newValue = e.target.value;

    if (!setting.value) {
        return;
    }

    OrganisationSetting.update({
        category: setting.value?.category,
        settings: { [setting.value?.name]: newValue },
    })
        .then((response) => {
            saved.value = true;
            organisationSettingStore.sync(true);
            setTimeout(() => {
                saved.value = false;
            }, 2000);
        });
};

const getOptionTitle = (input) => {
    // Split the input string by underscores
    const words = input.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words with spaces
    const formattedSentence = capitalizedWords.join(" ");

    return formattedSentence;
};
</script>

<style scoped></style>
