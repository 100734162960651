<template>
    <div v-if="driverLoaded">
        <DriverCreateOrEditForm
            :form="form"
            :abilities="abilities"
            :licenceClasses="licenceClasses"
            mode="update"
        />
    </div>
    <div v-else class="d-flex justify-content-center">
        <Spinner></Spinner>
    </div>
</template>

<script setup>
import {reactive, onMounted, ref, computed, inject} from 'vue'
import { onReady, transformInput } from '../Utils'
import DriverCreateOrEditForm from './DriverCreateOrEditForm.vue'
import { Button } from '../'
import Spinner from "@components/Spinner.vue";
const axios = inject('axios');
const toast = inject('toast');

const props = defineProps({
    driver: {
        type: Object,
        required: true
    },
    abilities: {
        type: Array,
        required: true
    },
    licenceClasses: {
        type: Array,
        required: true
    },
})

const driver = ref({});
const driverLoaded = ref(false);
const form = ref({});

const assignForm = () => {
    if (driver.value) {
        form.value = {
            uuid: driver.value.uuid,
            given_names: transformInput({ value: driver.value.given_names }),
            last_name: transformInput({ value: driver.value.last_name }),
            username: transformInput({ value: driver.value.username }),
            address: driver.value.address,
            email: transformInput({ value: driver.value.email }),
            phone: transformInput({ value: driver.value.phone }),
            date_of_birth: transformInput({ value: driver.value.date_of_birth }),
            region: transformInput({ value: driver.value.region.uuid }),
            app_access: transformInput({ value: driver.value.app_access }),
            indigenous_status: transformInput({ value: driver.value.indigenous_status }),
            languages_spoken: driver.value.languages_spoken,
            gender: transformInput({ value: driver.value.gender }),

            ndis_worker_expiry_date: transformInput({ value: driver.value.ndis_worker_expiry_date, required: false }),
            demerit_check_date: transformInput({ value: driver.value.demerit_check_date, required: false }),
            driver_authority_expiry_date: transformInput({ value: driver.value.driver_authority_expiry_date }),
            driver_record_check_date: transformInput({ value: driver.value.driver_record_check_date }),
            licence_expiry_date: transformInput({ value: driver.value.licence_expiry_date }),
            medical_expiry_date: transformInput({ value: driver.value.medical_expiry_date }),
            police_check_expiry_date: transformInput({ value: driver.value.police_check_expiry_date }),
            licence_classes: driver.value.licence_classes,
            annual_driver_training_completed_date: transformInput({ value: driver.value.annual_driver_training_completed_date, required: false }),
            covid_safe_plan_signed_date: transformInput({ value: driver.value.covid_safe_plan_signed_date, required: false }),
            flu_vaccine_date: transformInput({value: driver.value.flu_vaccine_date, required: false }),

            joined_date: transformInput({ value: driver.value.joined_date }),
            employment_type: transformInput({ value: driver.value.employment_type }),
            driver_type: transformInput({ value: driver.value.driver_type.code }),

            emergency_contacts: driver.value.emergency_contacts.map(contact => ({
                uuid: contact.uuid,
                name: transformInput({ value: contact.name }),
                address: contact.address,
                role: transformInput({ value: contact.role, required: false }),
                organisation: transformInput({ value: contact.organisation, required: false }),
                work_phone: transformInput({ value: contact.work_phone }),
                home_phone: transformInput({ value: contact.home_phone }),
                mobile_phone: transformInput({ value: contact.mobile_phone }),
                fax: transformInput({ value: contact.fax }),
                relationship: transformInput({ value: contact.relationship, required: false }),
                email: transformInput({ value: contact.email, required: false }),
                is_primary: contact.primary_contact_from ? true : false,
            })),

            tags: driver.value.tags,

            equipment: driver.value.equipment.map(item => ({
                uuid: item.uuid,
                description: transformInput({ value: item.description }),
                identifier: transformInput({ value: item.identifier }),
                issued_date: transformInput({ value: item.issued_date }),
            })),

            abilities: driver.value.abilities.map(ability => ({
                uuid: ability.uuid,
                code: ability.code,
                name: ability.name,
                description: ability.description,
                expiry: transformInput({ value: ability.document?.expiry, required: false }),
                doc_name: transformInput({ value: ability.document?.name, required: false }),
                source: transformInput({ value: ability.document?.source, required: false }),
            })),
        }
    }
};

const getDriverDetailForEdit = () => {
    axios.get(route('api.driver.detail.edit', { driver: props.driver.uuid})).then((resp) => {
        driver.value = resp.data.data;
        assignForm();
        driverLoaded.value = true;
    }).catch((message) => {
        console.error(message)
        toast.error(message)
    })
}

onMounted(() => {
    getDriverDetailForEdit();
});

</script>
