<template>
    <div class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="purposes"
            :value="booking.purpose"
            @updated="update"
        >
            {{ booking.purpose }}
        </EditableSelect>
    </div>
</template>

<script>
export default {
    name: "EditBookingPurpose"
}
</script>
<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {ref, inject} from "vue";
import axios from "axios";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
    purposes: {
        type: Object,
        required: true,
    },
})

const updating = ref(false)
const update = function (newPurpose) {
    updating.value = true
    axios
        .put(route('api.bookings.update', {booking: props.booking.uuid}), {
            purpose: newPurpose,
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            toast.success('Booking purpose updated.')
        })
        .catch((error) => {
            toast.error('There was an error updating the booking purpose.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
