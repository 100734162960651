<template>
    <label :class="labelClass" :for="inputName">
        {{ label }}<span v-if="input.required" class="text-danger ms-1">*</span>
    </label>
    <input
        v-model="inputValue"
        v-mask="mask"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        type="email"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {kebabCase} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage} from '../Utils'

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    mask,
    type
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: () => {
            kebabCase((Math.random() + 1).toString(36).substring(7))
        }
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().max(255),
    },
    mask: {
        type: Object,
        default: null
    }
})

const mounted = ref(false)
const inputValue = ref(copyValues(input.value))

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

watch(
    inputValue,
    (value) => {
        if (mounted.value) {
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(input)
                returnValue.value = meta.valid ? value : ''
                returnValue.valid = meta.valid
                emit('update:input', returnValue)
            })
        }
    }
)
</script>
