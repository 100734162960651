<template>
    <div class="row">
        <div class="col-sm-3">
            <label class="form-label" :for="componentId + '_copaymentType'">Type</label>
            <select class="form-select form-select-sm" v-model="copayment.type" :id="componentId + '_copaymentType'">
                <option value="amount">Amount</option>
                <option value="percentage">Percentage</option>
            </select>
        </div>
        <div class="col-sm-4 has-validation">
            <label class="form-label" :for="componentId + '_copaymentAmount'">
                <transition name="basic-fade" mode="out-in">
                    <span v-if="copayment.type === 'amount'">Maximum amount per booking</span>
                    <span v-else>Percentage of booking fee</span>
                </transition>
            </label>
            <div class="input-group input-group-sm">
                <transition name="basic-fade" mode="out-in">
                    <span class="input-group-text" v-if="copayment.type === 'amount'">$</span>
                </transition>
                <input
                    type="text"
                    v-model="copayment.amount"
                    :id="componentId + '_copaymentAmount'"
                    :placeholder="copayment.type === 'amount' ? 'e.g. 55.00' : 'e.g. 60'"
                    :class="{ 'form-control': true, 'is-invalid': errors.amount }"
                    aria-label="Amount"
                >
                <transition name="basic-fade" mode="out-in">
                    <span class="input-group-text" v-if="copayment.type === 'amount'">AUD</span>
                    <span class="input-group-text" v-else>%</span>
                </transition>
            </div>
            <p class="invalid-feedback d-block" v-if="errors.amount">{{ errors.amount[0] }}</p>
        </div>
        <div class="col-sm-5">
            <label class="form-label" :for="componentId + '_copaymentPayer'">Payer</label>
            <select class="form-select form-select-sm" v-model="copayment.payer" :id="componentId + '_copaymentPayer'">
                <option>-- Select Payer --</option>
                <template v-for="type in fundingTypes">
                    <option :value="type.uuid">{{ type.name }}</option>
                </template>
            </select>
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, computed} from 'vue';
import {uuid} from 'vue-uuid';

const componentId = ref(uuid.v4());
const props = defineProps({
    modelValue: {
        required: true,
        type: Object,
    },
    fundingTypes: {
        type: Array,
        default: [],
    },
    errors: {
        type: Object,
        default: {},
    },
})

const copayment = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

</script>

<style scoped>

</style>
