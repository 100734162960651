<template>
    <div class="row">
        <div class="col-md-4">
            <h4>Invoice Export</h4>
            <p>Use this section to configure and export Xero invoices for bookings.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-2 has-validation">
                            <label for="invoice_prefix" class="form-label">{{ props.settings.invoice_prefix.title }}</label>
                            <input
                                v-model="xero.invoicePrefix"
                                maxlength="200"
                                type="text"
                                name="invoice_prefix"
                                id="invoice_prefix"
                                :class="{ 'form-control': true, 'is-invalid': errors['settings.invoice_prefix'] }"
                                aria-describedby="invoice_prefix_feedback"
                            >
                            <div v-if="errors['settings.invoice_prefix']" id="invoice_prefix_feedback" class="invalid-feedback">
                                {{ errors['settings.invoice_prefix'][0] }}
                            </div>
                        </div>
                        <div class="col-md-4 has-validation">
                            <label for="next_invoice_number" class="form-label">{{ props.settings.next_invoice_number.title }}</label>
                            <input
                                v-model="xero.nextInvoiceNumber"
                                type="number"
                                name="next_invoice_number"
                                id="next_invoice_number"
                                min="0"
                                :class="{ 'form-control': true, 'is-invalid': errors['settings.next_invoice_number'] }"
                                aria-describedby="next_invoice_number_feedback"
                            >
                            <div v-if="errors['settings.next_invoice_number']" id="next_invoice_number_feedback" class="invalid-feedback">
                                {{ errors['settings.next_invoice_number'][0] }}
                            </div>
                            <p class="form-text">{{ props.settings.next_invoice_number.description }}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 has-validation">
                            <label for="invoice_due_days" class="form-label">{{ props.settings.invoice_due_days.title }}</label>
                            <select
                                v-model="xero.invoiceDueDays"
                                :class="{ 'form-select': true, 'is-invalid': errors['settings.invoice_due_days'] }"
                                name="invoice_due_days"
                                id="invoice_due_days"
                            >
                                <template v-for="option in props.settings.invoice_due_days.options">
                                    <option :value="option">{{ option }} days</option>
                                </template>
                            </select>
                            <div v-if="errors['settings.invoice_due_days']" id="invoice_due_days_feedback" class="invalid-feedback">
                                {{ errors['settings.invoice_due_days'][0] }}
                            </div>
                            <p class="form-text">{{ props.settings.invoice_due_days.description }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <label for="account_code" class="form-label">{{ props.settings.account_code.title }}</label>
                        <div class="col-md-4 has-validation">
                            <input
                                v-model="xero.accountCode"
                                type="text"
                                name="account_code"
                                id="account_code"
                                maxlength="10"
                                :class="{ 'form-control': true, 'is-invalid': errors['settings.account_code'] }"
                                aria-describedby="account_code_feedback"
                            >
                            <div v-if="errors['settings.account_code']" id="account_code_feedback" class="invalid-feedback">
                                {{ errors['settings.account_code'][0] }}
                            </div>
                            <p class="form-text">{{ props.settings.account_code.description }}</p>
                        </div>
                        <div class="col-md-5">
                            <img src="/images/xero/account-code-helper-image.png" class="img-fluid" alt="Account Code Example Image">
                            <p class="form-text fst-italic">An example of where you can find the account code on your Xero dashboard</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <Button @click="saveSettings" :loading="savingSettings">Save Settings</Button>
                        <transition name="fade">
                            <div v-if="showSavedFeedback" class="ms-2 text-success d-flex align-items-center">
                                <SvgIcon
                                    type="mdi"
                                    :path="checkIcon"
                                    class="me-1"
                                ></SvgIcon>Saved
                            </div>
                        </transition>
                    </div>
                    <hr>
                    <div class="mb-3">
                        <label class="form-label">Download File</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="start_date" class="form-label">Bookings From</label>
                                <input
                                    v-model="download.date_from"
                                    :max="moment().format('YYYY-MM-DD')"
                                    type="date"
                                    name="start_date"
                                    id="start_date"
                                    class="form-control"
                                >
                            </div>
                            <div class="col-sm-6">
                                <label for="start_date" class="form-label">Bookings Until (and including)</label>
                                <input
                                    v-model="download.date_until"
                                    :max="DateTime.local().toISODate()"
                                    type="date"
                                    name="end_date"
                                    id="end_date"
                                    class="form-control"
                                >
                            </div>
                        </div>
                    </div>
                    <a
                        :href="route('xero.download', { date_from: download.date_from, date_until: download.date_until })"
                        class="btn btn-outline-primary"
                    >
                        Download .csv file
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="trackingCategories.length">
        <div class="col-md-4">
            <h4>Tracking categories</h4>
            <p>Xero uses tracking categories and options instead of department codes or cost centres. You can have two active tracking categories. You can
                add multiple tracking options to each category.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <XeroTrackingCategories></XeroTrackingCategories>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "XeroSettings"
}
</script>

<script setup>
import { ref, onBeforeMount, inject } from 'vue'
import Button from "@components/Button.vue";
import { DateTime } from 'luxon'
import { mdiCheck } from '@mdi/js'

import SvgIcon from "@jamescoyle/vue-icon";
import XeroTrackingCategories from "./XeroTrackingCategories.vue";

const axios = inject('axios');

const checkIcon = ref(mdiCheck);
const showSavedFeedback = ref(false)

const props = defineProps({
    settings: {
        type: Object,
        required: true,
    },
    trackingCategories: {
        type: Array
    }
})

const xero = ref({
    invoicePrefix: 'INV-',
    nextInvoiceNumber: 1,
    invoiceDueDays: 30,
    accountCode: '',
})
const errors = ref([])

onBeforeMount(() => {
    xero.value.invoicePrefix = props.settings.invoice_prefix.value
    xero.value.nextInvoiceNumber = props.settings.next_invoice_number.value
    xero.value.invoiceDueDays = props.settings.invoice_due_days.value
    xero.value.accountCode = props.settings.account_code.value
})

let savingSettings = ref(false)
const saveSettings = function () {
    savingSettings.value = true
    errors.value = []
    axios
        .put(route('organisation-settings.update'), {
            category: 'xero',
            settings: {
                invoice_prefix: xero.value.invoicePrefix,
                next_invoice_number: xero.value.nextInvoiceNumber,
                invoice_due_days: xero.value.invoiceDueDays,
                account_code: xero.value.accountCode,
            }
        })
        .then(() => {
            showSavedFeedback.value = true
            setTimeout(() => showSavedFeedback.value = false, 2000)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            savingSettings.value = false
        })
}

const download = ref({
    date_from: DateTime.local().minus({ days: 30 }).toISODate(),
    date_until: DateTime.local().toISODate(),
})

</script>

<style scoped>
.fade-enter-active {
    transition: opacity 1s;
}

.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
