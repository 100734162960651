<template>
    <HoverPopUp v-if="(client?.allocations_alert?.length ?? 0) > 0">
        <template v-slot:element>
            <SvgIcon
                type="mdi"
                :path="mdiAlert"
                class="text-danger"
                :size="18"
            ></SvgIcon>
        </template>
        <template v-slot:popup-content>
            <p class="text-danger"><strong>Allocations Alert</strong></p>
            <p>{{ client?.allocations_alert }}</p>
        </template>
    </HoverPopUp>
</template>

<script setup lang="ts">
import SvgIcon from "@jamescoyle/vue-icon";
import HoverPopUp from "@components/HoverPopUp/HoverPopUp.vue";
import { mdiAlert } from "@mdi/js";
import { toRefs } from "vue";

const props = withDefaults(
    defineProps<{
        client: App.Models.Client;
    }>(),
    {}
);

const { client } = toRefs(props);
</script>
