<template>
    <div>
        <Modal
            :show="true"
            title="Move export"
            @update:show="cancel"
        >
            <template v-slot:body>
                <div class="row">
                    <div class="col">
                        <VueMultiselect
                            v-model="currentFolder"
                            :options="options"
                            :taggable="true"
                            @tag="createFolder"
                            placeholder="Select folder"
                            label="name"
                            track-by="uuid"
                            id="exportFolders"
                        ></VueMultiselect>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                <Button @click="save" class="btn btn-primary">Save</Button>
            </template>
        </Modal>
    </div>
</template>

<style scoped>

</style>

<script setup lang="ts">
import Button from "@components/Button.vue";
import {onMounted, ref} from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Modal from "@components/Modal.vue";
import VueMultiselect from "vue-multiselect";
import ExportFolderResource = App.Resource.ExportFolderResource;

const props = defineProps(['modelValue', 'options']);
const emit = defineEmits(['update:modelValue', 'updated', 'cancelled']);

const moveModal = ref<null | bootstrap.Modal>(null);
const currentFolder = ref<ExportFolderResource|null>(props.modelValue);

const cancel = () => {
    emit('cancelled');
};

const save = () => {
    emit('updated', currentFolder.value);
};

const createFolder = (tag) => {
    // emit('updated')
};

onMounted(() => {
    const modalElement = document.getElementById("moveModal");
    if (!modalElement) {
        return;
    }
    moveModal.value = new bootstrap.Modal(modalElement);
});
</script>