<template>
    <div class="card border mt-3">
        <div class="card-header">
            <slot name="header"/>
        </div>
        <div class="card-body p-0">
            <Accordion style="border-width: 0 !important;">
                <template v-for="journeyStatistic in getJourneys()">
                    <AccordionItem
                        v-if="journeyStatistic.totalBookings > 0 || searchBookingResult.length > 0"
                        :visible="autoExpandAccordion"
                        striped
                    >
                        <template #title>
                            <div class="d-flex justify-content-between flex-grow-1">
                                <div class="d-flex align-items-center">
                                    <div class="me-2">
                                        <JourneyStatus :status="journeyStatistic.status" v-if="journeyStatistic.status !== 'verified'"></JourneyStatus>
                                        <JourneyStatusDropdown :journey="journeyStatistic" mode="compact" v-if="journeyStatistic.status === 'verified'"
                                                               @updated="onJourneyStatusUpdated($event)"></JourneyStatusDropdown>
                                    </div>
                                    <div class="me-2">
                                        {{ journeyStatistic.vehicle }}
                                    </div>
                                    <div class="d-flex align-items-center">
                                        {{ $filters.format_datetime(journeyStatistic.start_time, 'short time') }}
                                        – {{ $filters.format_datetime(journeyStatistic.end_time, 'short time') }}
                                        <div v-if="inDifferentTz" class="small mx-2">{{ orgTimezone }}</div>
                                    </div>
                                    <div class="me-2 badge bg-light text-dark">
                                        ID: {{ journeyStatistic.uuid }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="me-3" data-bs-toggle="tooltip" title="Bookings Count">
                                        <SvgIcon :path="mdiCarMultiple" class="h-4 w-4" type="mdi"/>
                                        {{ journeyStatistic.totalBookings }}
                                    </div>
                                    <div class="me-3" data-bs-toggle="tooltip" title="Client Count">
                                        <SvgIcon :path="mdiAccountMultiple" class="h-4 w-4" type="mdi"/>
                                        {{ journeyStatistic.clientCount }}
                                    </div>
                                    <div class="me-3" data-bs-toggle="tooltip" title="Funds Collected">
                                        <SvgIcon :path="mdiCashMultiple" class="h-4 w-4" type="mdi"/>
                                        {{ $filters.currency(journeyStatistic.fundsCollected) }}
                                    </div>
                                    <div class="me-3" data-bs-toggle="tooltip" title="Total Passenger Onboard Time">
                                        <SvgIcon :path="mdiAccountClockOutline" class="h-4 w-4" type="mdi"/>
                                        {{ journeyStatistic.passengerOnBoardDuration }}
                                    </div>
                                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip" title="Total Distance Travelled">
                                        <SvgIcon :path="mdiMapMarkerDistance" class="h-4 w-4" type="mdi"/>
                                        {{ $filters.comma_separated_thousands(journeyStatistic.totalDistanceTravelled) }}km
                                    </div>
                                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip"
                                         title="Dead Running (Distance Without Passengers)">
                                        <SvgIcon :path="mdiAccountOffOutline" class="h-4 w-4" type="mdi"/>
                                        {{ $filters.comma_separated_thousands(journeyStatistic.totalDistanceWithoutPassengers) }}km
                                    </div>
                                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip" title="Distance With Passengers">
                                        <SvgIcon :path="mdiAccountOutline" class="h-4 w-4" type="mdi"/>
                                        {{ $filters.comma_separated_thousands(journeyStatistic.totalDistanceWithPassengers) }}km
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #content>
                            <VerificationJourneyContent
                                :journey-statistic="journeyStatistic"
                                :drivers="drivers"
                                :query-string="queryString"
                                :key="tableRefreshKey"
                            />
                        </template>
                    </AccordionItem>
                </template>
            </Accordion>
        </div>
        <div class="card-footer">
            <div class="d-flex">
                <strong>Total for all vehicle runs: </strong>
                <div class="ms-3 d-flex align-items-center">
                    <div class="me-3" data-bs-toggle="tooltip" title="Bookings Count">
                        <SvgIcon :path="mdiCarMultiple" class="h-4 w-4" type="mdi"/>
                        {{ allRunTotalBookings }}
                    </div>
                    <div class="me-3" data-bs-toggle="tooltip" title="Client Count">
                        <SvgIcon :path="mdiAccountMultiple" class="h-4 w-4" type="mdi"/>
                        {{ allRunClientCount }}
                    </div>
                    <div class="me-3" data-bs-toggle="tooltip" title="Funds Collected">
                        <SvgIcon :path="mdiCashMultiple" class="h-4 w-4" type="mdi"/>
                        {{ $filters.currency(allRunFundsCollected) }}
                    </div>
                    <div class="me-3" data-bs-toggle="tooltip" title="Total Passenger Onboard Time">
                        <SvgIcon :path="mdiAccountClockOutline" class="h-4 w-4" type="mdi"/>
                        {{ passengerOnBoardDuration }}
                    </div>
                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip"
                         title="Total Distance Travelled">
                        <SvgIcon :path="mdiMapMarkerDistance" class="h-4 w-4" type="mdi"/>
                        {{ $filters.comma_separated_thousands(allRunTotalDistanceTravelled) }}km
                    </div>
                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip"
                         title="Dead Running (Distance Without Passengers)">
                        <SvgIcon :path="mdiAccountOffOutline" class="h-4 w-4" type="mdi"/>
                        {{ $filters.comma_separated_thousands(allRunTotalDistanceWithoutPassengers) }}km
                    </div>
                    <div v-if="allRunTotalDistanceTravelled" class="me-3" data-bs-toggle="tooltip"
                         title="Distance With Passengers">
                        <SvgIcon :path="mdiAccountOutline" class="h-4 w-4" type="mdi"/>
                        {{ $filters.comma_separated_thousands(allRunTotalDistanceWithPassengers) }}km
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VerificationJourneyGroup"
}
</script>
<script setup>
import Accordion from "../Accordion/Accordion.vue";
import AccordionItem from "../Accordion/AccordionItem.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiCarMultiple,
    mdiAccountMultiple,
    mdiCashMultiple,
    mdiMapMarkerDistance,
    mdiAccountOffOutline,
    mdiAccountOutline,
    mdiAccountClockOutline,
} from '@mdi/js';
import {computed, inject, ref, watch} from "vue";
import JourneyStatus from "@components/JourneyStatus.vue";
import {useVerificationStore} from "../../stores/VerificationStore.js";
import {storeToRefs} from "pinia";
import VerificationJourneyContent from "@components/Verifications/VerificationJourneyContent.vue";
import {DateTime} from "luxon";
import {uuid} from "vue-uuid";
import JourneyStatusDropdown from "@components/JourneyStatusDropdown.vue";

const tableRefreshKey = ref(DateTime.now());
const verificationStore = useVerificationStore();
const { journeys, searchBookingResult } = storeToRefs(verificationStore);

const axios = inject('axios');

const emit = defineEmits(['bookingReallocated']);

const props = defineProps({
    statusStatistics: {
        type: Object,
        required: false,
        default: null,
    },
    queryString: {
        type: String,
        required: false,
        default: null,
    },
    drivers: {
        type: Array,
        required: false,
        default: null,
    },
})

const allRunTotalBookings = ref(props.statusStatistics.allRunStatistics.totalBookings);
const allRunClientCount = ref(props.statusStatistics.allRunStatistics.clientCount);
const allRunFundsCollected = ref(props.statusStatistics.allRunStatistics.fundsCollected);
const allRunTotalDistanceTravelled = ref(props.statusStatistics.allRunStatistics.totalDistanceTravelled);
const allRunTotalDistanceWithoutPassengers = ref(props.statusStatistics.allRunStatistics.totalDistanceWithoutPassengers);
const allRunTotalDistanceWithPassengers = ref(props.statusStatistics.allRunStatistics.totalDistanceWithPassengers);
const autoExpandAccordion = ref(false);

const inDifferentTz = computed(() => {
    return window.timezone !== DateTime.local().zoneName
})

const orgTimezone = computed(() => {
    return window.timezone
})

const passengerOnBoardDuration = computed(() => {
    // this is commented out because it doesn't seem to ever worked
    /*let totalTime = props.journeys.reduce((total, journey) => {
        let journeyTotal = 0
        journey.bookings.forEach((booking) => {
            journeyTotal += DateTime.fromISO(booking.actual_drop_off_time).diff(DateTime.fromISO(booking.actual_pick_up_time), 'minutes').minutes
        })

        return total + journeyTotal
    }, 0)

    if (isNaN(totalTime)) {
        return '–'
    }

    let hours = Math.floor(totalTime / 60)
    let minutes = Math.floor(totalTime % 60)*/

    return 0 + 'h ' + 0 + 'm'
});

const getJourneys = () => {
    if (props.queryString) {
        return props.statusStatistics.breakdown.filter((journey) => {
            return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
        });
    }

    return props.statusStatistics.breakdown;
}

const onJourneyStatusUpdated = (event) => {
    emit('bookingReallocated');
}

watch(props, (newProps) => {
    autoExpandAccordion.value = newProps.queryString.length > 0;
    tableRefreshKey.value = DateTime.now();
})

</script>

<style scoped>
</style>
