<template>
    <span>
        <Button
            :color="color"
            :size="size"
            :loading="loading"
            :disabled="disabled"
            :icon="icon"
            :class-array="classArray"
            @click="handleButtonClick"
        >
            <slot></slot>
        </Button>
        <ConfirmationModal
            :id="modalId"
            :confirmation-button-color="confirmationButtonColor"
            @confirmed="$emit('confirmed'); modal.hide()"
        >
            <template v-slot:title>{{ confirmationTitle }}</template>
            <template v-slot:text>{{ confirmationText }}</template>
        </ConfirmationModal>
    </span>
</template>

<script>
import { Button } from './'
import ConfirmationModal from './ConfirmationModal.vue'
import SvgIcon from "@jamescoyle/vue-icon"
export default {
    name: "ConfirmButton",
    components: {
        ConfirmationModal,
        SvgIcon,
        Button
    },
    props: {
        color: {
            required: false,
            type: String,
            default: 'primary',
        },
        size: {
            required: false,
            type: String,
            default: 'medium',
        },
        loading: {
            required: false,
            type: Boolean,
            default: false,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        confirmationTitle: {
            required: false,
            type: String,
            default: 'Are you sure?',
        },
        confirmationText: {
            required: false,
            type: String,
            default: 'Are you sure you wish to continue with this change?',
        },
        confirmationButtonColor: {
            type: String,
            default: 'primary'
        },
        icon: {
            required: false,
        },
        classArray: {
            required: false,
            type: Array,
            default() {
                return []
            },
        }
    },
    data() {
        return {
            modal: null,
            modalId: this.$uuid.v1() + '_modal',
        }
    },
    methods: {
        handleButtonClick() {
            this.modal.show()
        }
    },
    mounted() {
        this.modal = new bootstrap.Modal(document.getElementById(this.modalId))
    }
}
</script>

<style scoped>

</style>
