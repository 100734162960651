<template>
    <div v-if="mounted">
        <div v-if="Object.keys(selectedAssistanceTypes).length > 0">
            <div
                v-for="(value, index) in selectedAssistanceTypes"
                :key="index"
                class="config-row align-items-center"
            >
                <div class="col-11 config-col align-self-center">
                    <span>{{ getDetailInfo(value.code)?.label }}</span>
                    <span
                        v-tooltip="getDetailInfo(value.code)?.description"
                        class="ms-1 mb-2"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        />
                    </span>
                </div>

                <div class="col-1 config-col" v-if="!value.seat_type">
                    <button
                        @click="() => removeAssistanceType(value.code)"
                        class="h-100 w-100"
                        v-tooltip="'Remove Item'"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiCloseCircleOutline"
                            class="text-danger"
                        ></SvgIcon>
                    </button>
                </div>
            </div>
        </div>
        <div class="config-row">
            <div class="col-11 config-col">
                <select v-model="assistanceTypeSelected" class="form-select">
                    <option value="null" disabled>
                        Please select an assistance type to add
                    </option>
                    <option
                        v-for="(option, index) in filteredAssistanceTypeOptions"
                        :value="option.value"
                        :key="index"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="col-1 config-col justify-content-center">
                <button
                    @click="addAssistanceType"
                    class="h-100 w-100"
                    :disable="!assistanceTypeSelected"
                    v-tooltip="'Add Assistance Type'"
                >
                    <SvgIcon
                        type="mdi"
                        :path="mdiPlusCircle"
                        class="text-primary"
                        size="30"
                    ></SvgIcon>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRefs } from "vue";
import { onReady } from "../Utils";
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiPlusCircle,
    mdiInformationOutline,
    mdiCloseCircleOutline,
} from "@mdi/js";
import { Button } from "../";
import { cloneDeep } from "lodash";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import { storeToRefs } from "pinia";

const bookingRequirementStore = useBookingRequirementStore();

const props = withDefaults(
    defineProps<{
        details: string[];
    }>(),
    {}
);

const { details } = toRefs(props);

const {
    assistanceTypeOptions,
    selectedAssistanceTypes,
    filteredAssistanceTypeOptions,
} = storeToRefs(bookingRequirementStore);

const detailsValue = ref<string[]>([]);

const mounted = ref(false);

onReady(() => {
    detailsValue.value = cloneDeep(details.value);
    mounted.value = true;
});

const assistanceTypeSelected = ref<null | string>(null);

const addAssistanceType = () => {
    let option = filteredAssistanceTypeOptions.value.find((option) => {
        return option.value === assistanceTypeSelected.value;
    });

    if (!option) {
        return;
    }

    selectedAssistanceTypes.value.push({
        code: option.value,
        removable: true
    });

    bookingRequirementStore.filterOutSelectedAssistanceType();

    return new MouseEvent("");
};

const removeAssistanceType = (key: string) => {
    selectedAssistanceTypes.value = selectedAssistanceTypes.value.filter((value) => {
        return value.code !== key;
    });

    bookingRequirementStore.filterOutSelectedAssistanceType();

    return new MouseEvent("");
};

const getDetailInfo = (value: string) => {
    let result = assistanceTypeOptions.value.find((option) => {
        return option.value === value;
    });

    return result;
};

const emit = defineEmits(["updated"]);

// newValue and oldValue always the same, so need to emit update when this watcher is triggered
watch(
    detailsValue,
    (newValue, oldValue) => {
        emit("updated", newValue);
    },
    { deep: true }
);
</script>

<style scoped>
.config-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}
.config-col {
    padding: 10px 5px;
    justify-content: center;
}
.total {
    border-top: 1px solid var(--bs-gray-300);
}
</style>
