import Invoice from "@classes/Invoice";
import {ApiResourceResponseInterface} from "@customTypes/LaravelCommonTypes";
import InvoiceResource, {InvoiceItemResource} from "@customTypes/resources/InvoiceResource";
import {usePaginationStore} from "@stores/PaginationStore";
import {MultiSelectOption} from "@stores/TransactionStore";
import {AxiosResponse} from "axios";
import {DateTime} from "luxon";
import {defineStore, storeToRefs} from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";

export interface GetAllInvoicesRequestInterface {
    category?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    date_to?: string | null,
    collectionMethods?: { label: string, value: string }[] | null,
    statuses?: { label: string, value: string }[] | null,
    client?: string | null,
}

export const useInvoiceStore = defineStore("InvoiceStore", () => {
    const invoices: Ref<InvoiceResource[] | undefined> = ref<InvoiceResource[]>();
    const invoice: Ref<InvoiceResource | undefined> = ref<InvoiceResource>();

    const isLoading: Ref<boolean> = ref<boolean>(false);

    const {paginatedMeta, paginateParams, searchQuery} = storeToRefs(usePaginationStore('InvoiceStore'));
    const defaultStatuses: { label: string, value: string }[] = [
        {label: "Draft", value: 'draft'},
        {label: "Unpaid", value: 'unpaid'},
        {label: "Paid", value: 'paid'},
        {label: "Cancelled", value: 'cancelled'},
        {label: "Approved", value: 'approved'},
        {label: "Void", value: 'void'},
    ];

    const defaultCollectionMethods = [
        {label: 'Account', value: 'account'},
        {label: 'PaymentToDriver', value: 'payment_to_driver'},
        {label: 'Prepaid', value: 'prepaid'},
    ];

    const filters: Ref<GetAllInvoicesRequestInterface> = ref({
        category: 'invoices',
        dateFrom: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        dateTo: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        collectionMethods: defaultCollectionMethods,
        statuses: defaultStatuses,
    })

    const selectedInvoices: ComputedRef<InvoiceResource[] | undefined> = computed(() => invoices.value?.filter((invoice: InvoiceResource) => invoice.selected));
    const isAllSelected: ComputedRef<boolean> = computed(() => selectedInvoices.value?.length === invoices.value?.length);
    const isSomeSelected: ComputedRef<boolean> = computed(() => <boolean>(selectedInvoices.value?.length && invoices.value?.length && selectedInvoices.value?.length < invoices.value?.length));
    const totalDistance: ComputedRef<number> = computed(() => <number>(parseInt(invoice.value?.bookings_sum_actual_distance ?? '0') + parseInt(invoice.value?.bookings_sum_estimated_distance ?? '0')));

    const getAllInvoices = async (request: GetAllInvoicesRequestInterface = filters.value): Promise<InvoiceResource[]> => {
        isLoading.value = true;
        let data = {
            paginate: true,
            perPage: paginateParams.value.perPage,
            page: paginateParams.value.page,
            column: paginateParams.value.sortBy,
            direction: paginateParams.value.sortDirection,
            dateFrom: request.dateFrom,
            dateTo: request.dateTo,
            query: searchQuery.value?.trim(),
            collectionMethods: request.collectionMethods?.map((method: MultiSelectOption) => method.value),
            statuses: request.statuses?.map((status: MultiSelectOption) => status.value),
            client: request.client
        }

        try {
            let response: AxiosResponse<ApiResourceResponseInterface<InvoiceResource[]>> = await Invoice.index(data);
            invoices.value = response.data.data;
            paginatedMeta.value = response.data.meta ?? {};
            return invoices.value;
        } catch (e: unknown) {
            throw e;
        } finally {
            isLoading.value = false;
        }
    }

    const getAllInvoiceItems = async (event?: { page: number, url: string, per_page?: number }): Promise<void> => {
        if (!invoice.value) return;
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.getItems(invoice.value, {
            page: event?.page ?? paginateParams.value.page ?? 1,
            per_page: event?.per_page ?? paginateParams.value.perPage
        });
        if (invoice.value) {
            invoice.value.items = response.data.data;
            paginatedMeta.value = response.data.meta ?? {};
        }
    }

    const deleteInvoiceItems = async (selectedItems: InvoiceItemResource[]): Promise<ApiResourceResponseInterface<InvoiceResource> | void> => {
        if (!invoice.value) return;
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.deleteItems(invoice.value, selectedItems);
        updateInvoicesFromResponse(response.data.data)
        return response.data
    }

    const save = async (): Promise<ApiResourceResponseInterface<InvoiceResource> | void> => {
        if (!invoice.value) return;
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.save(invoice.value);
        updateInvoicesFromResponse(response.data.data)
        return response.data
    }

    const approveInvoice = async (invoice: InvoiceResource): Promise<InvoiceResource> => {
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.approve(invoice);
        updateInvoicesFromResponse(response.data.data)
        return response.data.data;
    }

    const bulkApproveInvoices = async (invoices: InvoiceResource[]): Promise<InvoiceResource> => {
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.bulkApprove(invoices);
        let results: InvoiceResource[] = response.data.data as InvoiceResource[]
        results.forEach((invoice: InvoiceResource) => updateInvoicesFromResponse(invoice));
        return response.data.data;
    }

    const voidInvoice = async (invoice: InvoiceResource, reason: string = 'Voided from editor'): Promise<InvoiceResource | void> => {
        let response: AxiosResponse<ApiResourceResponseInterface<InvoiceResource>> = await Invoice.void(invoice, reason);
        updateInvoicesFromResponse(response.data.data)
        return response.data.data
    }

    const syncInvoice = async (invoice: InvoiceResource): Promise<InvoiceResource> => {
        let response: AxiosResponse<ApiResourceResponseInterface<any>> = await Invoice.sync(invoice);
        updateInvoicesFromResponse(response.data.data)
        return response.data.data
    }

    const updateInvoicesFromResponse = (invoice: InvoiceResource): InvoiceResource | void => {
        if (!invoices.value) return;
        let old: InvoiceResource | undefined = invoices.value.find((inv: InvoiceResource) => inv.uuid === invoice.uuid);
        if (old) {
            Object.assign(old, invoice)
        }
        return old
    }

    const toggleSelect = (invoice: InvoiceResource): void => {
        invoice.selected = !invoice.selected;
    }

    const toggleSelectAll = (event: Event): void => {
        if ((<HTMLInputElement>event.target)?.checked) {
            invoices.value?.map((invoice: InvoiceResource) => invoice.selected = true);
        } else {
            invoices.value?.forEach((invoice: InvoiceResource) => invoice.selected = false);
        }
    }

    const itemCount = () => {
        return paginatedMeta.value.total
    }

    return {
        invoice,
        invoices,
        filters,
        selectedInvoices,
        defaultStatuses,
        defaultCollectionMethods,
        isAllSelected,
        isSomeSelected,
        isLoading,
        totalDistance,
        getAllInvoices,
        getAllInvoiceItems,
        deleteInvoiceItems,
        save,
        toggleSelect,
        toggleSelectAll,
        approveInvoice,
        voidInvoice,
        syncInvoice,
        itemCount,
        bulkApproveInvoices,
    }
})