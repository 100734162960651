<template>
    <HoverPopUp
        v-if="
            vehicleTypes.length > 0 ||
            cargoSpaces.length > 0 ||
            assistanceTypes.length > 0
        "
    >
        <template v-slot:element>
            <SvgIcon
                :path="mdiAsteriskCircleOutline"
                :size="20"
                type="mdi"
                class="text-primary"
            />
        </template>
        <template v-slot:popup-content>
            <div>
                <div class="d-flex flex-row align-items-center">
                    <strong class="text-primary">Booking Requirements</strong>
                </div>

                <br />

                <div class="d-flex flex-row">
                    <div v-if="vehicleTypes.length > 0">
                        <strong>Vehicle Requirements</strong>

                        <div v-for="vehicleType in vehicleTypes" class="mb-1">
                            <div
                                class="d-flex flex-row align-items-center justify-content-between"
                            >
                                <div class="text-wrap">
                                    {{ vehicleType.name }}
                                </div>
                                <div
                                    v-if="
                                        booking.chosen_seat ===
                                            'non_transfer_manual_wheelchair' ||
                                        booking.chosen_seat === 'ewheelchair'
                                    "
                                >
                                    <div>Wheelchair</div>
                                </div>
                                <div v-else>
                                    <div
                                        v-for="seatPosition in vehicleType.chosen_seat_positions"
                                    >
                                        {{ startCase(seatPosition) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="cargoSpaces.length > 0" class="ms-2">
                        <strong>Cargo Spaces</strong>

                        <div v-for="cargo in cargoSpaces" class="mb-1">
                            <div
                                class="d-flex flex-row align-items-center justify-content-between"
                            >
                                <div class="text-wrap">{{ cargo.name }}</div>
                                <div>{{ cargo?.pivot?.space }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="assistanceTypes.length > 0" class="ms-2">
                        <strong>Assistance Types</strong>

                        <div v-for="assistance in assistanceTypes">
                            <div
                                class="d-flex flex-row align-items-center justify-content-between"
                            >
                                <div class="text-wrap">
                                    {{ assistance.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </HoverPopUp>
</template>
<script setup lang="ts">
import { computed, toRefs } from "vue";
import HoverPopUp from "@components/HoverPopUp/HoverPopUp.vue";
import BookingResource from "@customTypes/resources/BookingResource";
import { DateTime } from "luxon";
import {
    mdiClockTimeEightOutline,
    mdiOpenInNew,
    mdiAsteriskCircleOutline,
} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import Booking from "@classes/Booking";
import { startCase } from "lodash";

const props = withDefaults(
    defineProps<{
        booking: BookingResource;
    }>(),
    {}
);

const { booking } = toRefs(props);

const vehicleTypes = computed(() => {
    return booking.value?.vehicle_types ?? [];
});

const cargoSpaces = computed(() => {
    return booking.value?.cargo_spaces ?? [];
});

const assistanceTypes = computed(() => {
    return booking.value?.assistance_types ?? [];
});
</script>
