<template>
    <div @click.stop="">
        <div class="dropdown pointer" :class="{'mb-2': mode === 'verbose'}">
            <button type="button"
                    :id="componentId"
                    :disabled="isButtonDisabled()"
                    aria-expanded="false"
                    class="btn dropdown-toggle text-dark"
                    :class="{'btn-outline-light': mode === 'verbose', 'ps-0': mode === 'compact'}"
                    data-bs-toggle="dropdown">
                <JourneyStatus :status="draftJourney.status"></JourneyStatus>
                <template v-if="mode === 'verbose'">{{ title }}</template>
            </button>
            <ul :aria-labelledby="componentId" class="dropdown-menu" :class="mode">
                <template v-for="(label, status) in filteredStatuses()">
                    <li v-if="status.toString() !== draftJourney.status">
                        <a class="dropdown-item"
                           href="javascript: void(0)"
                           @click="confirmUpdateStatus(status)">
                            <BookingStatus
                                :status="status"
                                class="me-1"
                                hide-tool-tip
                            />
                            {{ label }}
                        </a>
                    </li>
                </template>
            </ul>
        </div>
        <p v-if="saving && mode === 'verbose'" class="text-success">Saving status ...</p>
        <ConfirmModal :show="showConfirm" @confirmed="updateStatus($event)"></ConfirmModal>
    </div>
</template>
<script setup lang="ts">
import BookingStatus from "@components/BookingStatus.vue";
import ConfirmModal from "@components/ConfirmModal.vue";
import JourneyStatus from "@components/JourneyStatus.vue";
import JourneyApi, {JourneyStatus as JourneyStatusEnum} from "@classes/JourneyApi";
import {pick, pickBy} from "lodash";
import {computed, inject, Ref, ref} from "vue";
import {uuid} from "vue-uuid";

const componentId = ref(uuid.v4());
const props = withDefaults(defineProps<{
        journey: App.Models.Journey;
        mode?: "verbose" | "compact";
    }>(),
    {
        mode: 'verbose'
    }
);
const emit = defineEmits<{
    (e: 'updated', status: string): void
}>()
const toast = inject<any>("toast");
const draftJourney = ref<App.Models.Journey>(props.journey);
const statuses = ref<string[]>(window.enums.vehicleRunStatus);
const saving = ref(false);
const showConfirm: Ref<boolean> = ref<boolean>(false);

let statusToConfirm: Ref<string> = ref<string>(draftJourney.value.status);

const confirmUpdateStatus = (status: string) => {
    showConfirm.value = true;
    statusToConfirm.value = status;
}

const updateStatus = async function (confirmed: boolean) {
    showConfirm.value = false;

    if (!confirmed) {
        return;
    }

    try {
        await JourneyApi.updateStatus(draftJourney.value.uuid, statusToConfirm.value);
        emit('updated', statusToConfirm.value);
        toast.success("Booking status updated");
    } catch (error) {
        toast.error(
            error?.response?.data?.message ??
            "There was an error updating the journey status"
        );
    }
    saving.value = false;
}

const isButtonDisabled = function () {
    return false;
}

const title = computed(() => {
    return statuses.value[draftJourney.value.status];
});

const filteredStatuses = () => {
    if (draftJourney.value.status === JourneyStatusEnum.Verified) {
        return pick(statuses.value, [JourneyStatusEnum.WaitingVerification]);
    }

    return pickBy(statuses.value, (value, key) => draftJourney.value.status !== key);
}

</script>
