<template>
    <div v-if="details.general">
        <div class="row">
            <div class="col-md-4">
                <h4>General Information</h4>
                <p>Update the basic details of the vehicle.</p>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <VehicleGeneralForm
                            v-model="details.general"
                            :types="types"
                            :regions="regions"
                            :colours="colours"
                            :ownership="ownership"
                            :licenses="licenses"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8 offset-md-4">
                <div class="row">
                    <div class="col-3 offset-6">
                        <ConfirmButton
                            color="danger"
                            :class-array="['float-end']"
                            :loading="loading"
                            confirmationTitle="Are You Sure"
                            :confirmationText="`Are you sure that want to delete ${details.general.description.value} vehicle`"
                            @confirmed="deleteVehicle"
                        >
                            Delete Vehicle
                        </ConfirmButton>
                    </div>
                    <div class="col-3">
                        <Button
                            color="primary"
                            :class-array="['float-end']"
                            :loading="loading"
                            :disabled="!valid"
                            @click="saveVehicle"
                        >
                            Update Vehicle
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex justify-content-center">
        <Spinner></Spinner>
    </div>
</template>

<script setup>
import {ref, reactive, inject, computed, nextTick, onMounted} from 'vue'
    import VehicleGeneralForm from './parts/VehicleGeneralForm.vue'
    import { reduce, each } from 'lodash'
    import { onReady, copyValues } from '../Utils'
    import { Button, ConfirmButton } from '../'
import Spinner from "@components/Spinner.vue";

    const axios = inject('axios')
    const toast = inject('toast')

    const props = defineProps({
        details: {
            required: true,
            type: Object,
        },
        types: {
            required: true,
            type: Object,
        },
        regions: {
            required: true,
            type: Object,
        },
        colours: {
            required: true,
            type: Object,
        },
        ownership: {
            required: true,
            type: Object,
        },
        licenses: {
            required: true,
            type: Object,
        },
        access: {
            required: true,
            type: Object,
        },
    })

    const {types, regions, colours, ownership, licenses, access} = props;
    const details = ref({});

    let mounted = ref(false)

    onReady(() => mounted.value = true)

    let loading = ref(false)

    const valid = computed(() => {
        if (mounted) {
            return reduce(details.value.general, (result, input) => {
                // NOTE, when pressing the backspace on address, depo will comeback as null as it is not valid address or something
                // so we have to check the input if its null before checking valid property

                if (input?.valid == undefined) {
                    return result
                }

                if (!input?.valid) {
                    result = false
                    return result
                }

                return result

            }, true)
        }
        return false
    })

    const saveVehicle = () => {
        if (valid.value) {
            loading.value = true

            let data = Object.assign({}, details.value);

            let params = {
                type: data.general.type.value,
                region: data.general.region.value,
                depot: data.general.depot.value !== undefined ? data.general.depot.value.uuid : data.general.depot.uuid,
                registration_code: data.general.registration_code.value,
                description: data.general.description.value,
                make: data.general.make.value,
                model: data.general.model.value,
                year: data.general.year.value,
                colour: data.general.colour.value,
                owner: data.general.owner.value,
                operating_costs: data.general.operating_costs.value,
                license_required: data.general.license_required.value,
                registration_expiry: data.general.registration_expiry.value,
                insurance_expiry: data.general.insurance_expiry.value,
                notes: data.general.notes.value,
                access: data.access,
                vin: data.general.vin.value,
                modification_compliance_plate_date: data.general.modification_compliance_plate_date.value,
                date_of_purchase: data.general.date_of_purchase.value,
                purchase_cost: data.general.purchase_cost.value,
                date_of_disposal: data.general.date_of_disposal.value,
                leasing_company: data.general.leasing_company.value,
                lease_expiry_date: data.general.lease_expiry_date.value,
            }

            axios.put(route('api.vehicles.update', { vehicle: details.value.general.uuid }, true), params)
                .then(resp => {
                window.location.href = route('vehicles.show', {vehicle: details.value.general.uuid}) + '?refresh=' + (new Date()).getTime();
            }).catch(err => {
                if (err.response && err.response.status === 422) {

                    each(err.response.data.errors, (error, key) => {
                        details.value.general[key].errors = error
                    })
                    toast.warning('Please Correct Marked Vehicle Input Issues')
                    loading.value = false
                    return
                }
                toast.error('Vehicle Failed to Update')
                console.error(err)
                loading.value = false
            })
        }
    }

    const deleteVehicle = () => {
        loading.value = true
        let message = 'An unknown error has occurred'

        axios.delete(route('api.vehicles.destroy', { vehicle: details.value.general.uuid}, true)).then((resp) => {
            if (resp.data.success) {
                location.href = route('vehicles.index')
                return
            }

            console.error(message)
            toast.error(message)
            loading.value = false
        }).catch((err) => {
            console.error(message)
            toast.error(message)
            loading.value = false
        })
    }

    const getVehicleProfileData = (mountModal = false) => {
        axios.get(route('api.vehicles.edit', { vehicle: props.details.general.uuid})).then((resp) => {
            details.value = resp.data.data;
        }).catch((message) => {
            console.error(message)
            toast.error(message)
        })
    }

    onMounted(() => {
        getVehicleProfileData();
    });
</script>
