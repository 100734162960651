<template>
    <label v-if="showLabel" :for="inputName" :class="[
        labelClass,
        selection.required ? 'required-label' : '',
        optional ? 'optional-label' : '',
    ]">
        {{ label }}
    </label>
    <slot name="label-suffix"></slot>
    <select
        v-model="selected"
        :tabindex="tabindex"
        :class="[inputClass, {'is-invalid': isInvalid(meta, selection)}]"
        :name="inputName"
        :disabled="disabled"
        class="form-select">
        <option v-if="placeholderOption" :disabled="selection.required" :value="null">{{ placeholder }}</option>
        <option v-for="(option, index) in thisOptions" :key="index" :value="option[optionKeyField]">{{ option[optionLabelField] }}</option>
    </select>
    <span :class="errorClass">{{ getErrorMessage(selection, errorMessage) }}</span>
</template>

<script setup>
import {ref, watch} from 'vue'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage} from '../Utils'
import {kebabCase} from "lodash";

const props = defineProps({
    selection: {
        required: false,
        type: [Object, undefined],
        default: {}
    },
    options: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    showLabel: {
        type: Boolean,
        default: true,
    },
    inputName: {
        type: String,
        default: kebabCase((Math.random() + 1).toString(36).substring(7)),
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-select',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().nullable(),
    },
    placeholderOption: {
        type: Boolean,
        default: true,
    },
    tabindex: {
        type: Number,
        default: 0
    },
    disabled: {
        type: Boolean,
        default: false
    },
    optional: {
        type: Boolean,
        default: false
    },
    optionLabelField: {
        type: String,
        default: 'label'
    },
    optionKeyField: {
        type: String,
        default: 'value'
    },
})

// we have to split the const like this to make watchers work for entire props
const {
    selection,
    options,
    label,
    showLabel,
    inputName,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    validation,
    tabindex,
    optional,
} = props;

const mounted = ref(false)
const selected = ref(copyValues(selection[props.optionKeyField]));
const thisOptions = ref(copyValues(options));

onReady(() => mounted.value = true)

const emit = defineEmits(['update:selection'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

watch(
    selected,
    (value) => {
        if (mounted) {
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(selection)
                returnValue[props.optionKeyField] = meta.valid ? value : ''
                returnValue.value = meta.valid ? value : ''
                returnValue.valid = meta.valid
                emit('update:selection', returnValue)
            })
        }
    }
)

watch(props, (value) => {
    selected.value = copyValues(props.selection.value);
    thisOptions.value = value.options;
});
</script>
