<template>
    <div class="row align-items-center">
        <div class="col-lg-6 d-flex align-items-center">
            <div class="input-group input-group-sm me-2">
                <input
                    :id="componentId + '_min_distance'"
                    v-model="rangeFee.min"
                    class="form-control"
                    max="99999"
                    min="0"
                    placeholder="0"
                    step="1"
                    type="number"
                >
                <span class="input-group-text">km</span>
            </div>
            to
            <div class="input-group input-group-sm ms-2">
                <input
                    :id="componentId + '_max_distance'"
                    v-model="rangeFee.max"
                    class="form-control"
                    max="999999"
                    min="0"
                    placeholder="infinity"
                    step="1"
                    type="number"
                >
                <span class="input-group-text">km</span>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="input-group input-group-sm">
                <span class="input-group-text">AUD $</span>
                <input
                    :id="componentId + '_amount'"
                    v-model="rangeFee.amount"
                    class="form-control"
                    max="99999.99"
                    min="0.00"
                    placeholder="0.00"
                    step="0.01"
                    type="number"
                >
                <span class="input-group-text" v-if="perKm">
                    per km
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DistanceRangeFeeForm"
}
</script>
<script setup>
import {uuid} from "vue-uuid"
import {computed, ref} from 'vue'

const componentId = ref(uuid.v4());

const props = defineProps({
    modelValue: {
        type: Object,
        default: () => ({}),
    },
    perKm: {
        type: Boolean,
        required: false,
        default: true,
    },
})

const rangeFee = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

</script>

<style scoped>

</style>
