<template>
    <div
        @click="selectJourney"
        class="d-block mb-2 vehicle-run-button p-1 rounded"
        :class="selected ? 'bg-secondary text-white' : ''"
    >
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <input
                    @click="(e) => e.stopPropagation()"
                    v-model="isSelectedForAllocation"
                    class="form-check-input"
                    type="checkbox"
                />
            </div>
            <div class="flex-fill p-1">
                <div class="d-flex flex-row justify-content-between">
                    <div class="badge bg-light mb-1 text-dark">
                        ID: {{ journey.uuid }}
                    </div>
                    <SvgIcon
                        :path="mdiContentCopy"
                        :size="18"
                        type="mdi"
                        @click="() => copyJourney.show(journey.uuid)"
                    />
                </div>
                <div>
                    {{
                        journey.driver
                            ? `${journey.driver.given_names} ${journey.driver.last_name}`
                            : "No driver allocated"
                    }}
                </div>
                <div class="d-flex flex-column align-items-start">
                    <span
                        v-if="
                            getUpdateParametersForJourney(journey)?.driver_uuid
                        "
                        class="badge rounded-pill bg-success text-light mb-1"
                    >
                        <SvgIcon :path="mdiShimmer" :size="10" type="mdi" />
                        Driver Auto Allocated
                    </span>
                    <DraftIndicator v-if="journeyHasUnsavedChanges(journey)" />
                </div>

                <div class="d-flex flex-row mt-1">
                    <EditableInput
                        :label="journeyStartTimeLabel"
                        :value="startTimeInputValue"
                        type="time"
                        @updated="updateStartTime"
                    />
                    <span class="me-1 ms-1">-</span>
                    <EditableInput
                        :label="journeyEndTimeLabel"
                        :value="endTimeInputValue"
                        type="time"
                        @updated="updateEndTime"
                    />
                </div>
                <div
                    class="text-xs"
                    :class="selected ? 'text-white' : 'text-secondary'"
                >
                    {{ journey.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import DriverAvatar from "../Drivers/DriverAvatar.vue";
import moment from "moment";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import { DateTime } from "luxon";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";
import JourneyDatetime from "../../classes/DateHelpers/JourneyDatetime";
import DatetimeHelper from "../../classes/DateHelpers/DatetimeHelper";
import DraftIndicator from "./Components/DraftIndicator.vue";
import { mdiShimmer, mdiContentCopy } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const allocationStore = useAllocationStore();

const copyJourney = inject<any>("copyJourney");

const { journeyHasUnsavedChanges, getUpdateParametersForJourney } =
    allocationStore;
const { selectedJourney, selectedJourneysForAllocation } =
    storeToRefs(allocationStore);

const props = withDefaults(
    defineProps<{
        journey: App.Models.Journey;
    }>(),
    {}
);

const { journey } = props;

const journeyDatetime = computed(() => {
    return new JourneyDatetime(props.journey);
});

const selectJourney = () => {
    selectedJourney.value = journey;
    selected.value = true;
};

const selected = computed({
    get() {
        return selectedJourney.value?.uuid === journey.uuid;
    },
    set() {
        selectedJourney.value = journey;
    },
});

const isSelectedForAllocation = computed({
    get() {
        return (
            typeof selectedJourneysForAllocation.value.find(
                (i) => i.uuid === journey.uuid
            ) !== "undefined"
        );
    },
    set(newValue) {
        let newSelected = selectedJourneysForAllocation.value;
        if (newValue) {
            newSelected.push(journey);
        } else {
            newSelected = newSelected.filter((i) => i.uuid !== journey.uuid);
        }
        selectedJourneysForAllocation.value = newSelected;
    },
});

const journeyStartTimeLabel = computed(() => {
    return new JourneyDatetime(props.journey)
        ?.startTime()
        ?.toLocaleString(DateTime.TIME_24_SIMPLE);
});

const journeyEndTimeLabel = computed(() => {
    return new JourneyDatetime(props.journey)
        ?.endTime()
        ?.toLocaleString(DateTime.TIME_24_SIMPLE);
});

const startTimeInputValue = computed(() => {
    let startTime = journeyDatetime.value.startTime();

    if (!startTime) {
        return null;
    }

    return DatetimeHelper.toDateTimeInput(startTime, "time");
});

const endTimeInputValue = computed(() => {
    let endTime = journeyDatetime.value.endTime();

    if (!endTime) {
        return null;
    }

    return DatetimeHelper.toDateTimeInput(endTime, "time");
});

const updateStartTime = (newTime: string) => {
    journey.start_time = DateTime.fromFormat(
        journeyDatetime.value.startTime()?.toFormat("yyyy-MM-dd") +
            " " +
            newTime,
        "yyyy-MM-dd HH:mm"
    ).toISO();
};

const updateEndTime = (newTime: string) => {
    journey.end_time = DateTime.fromFormat(
        journeyDatetime.value.endTime()?.toFormat("yyyy-MM-dd") + " " + newTime,
        "yyyy-MM-dd HH:mm"
    ).toISO();
};
</script>

<style scoped>
.vehicle-run-button:hover {
    background-color: #f1f3fa;
}
</style>
