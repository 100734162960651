<template>
    <div v-if="mounted">
        <div v-if="(details ?? []).length > 0">
            <div v-for="(detail, index) in details" :key="index" class="card bg-light shadow-none">
                <div class="card-body p-3">
                    <div class="d-flex justify-content-end">
                        <a @click.prevent="removeSelected(index)" href="javascript: void(0)">
                            <i class="mdi mdi-close"></i>
                        </a>
                    </div>
                    <div class="row mt-1">
                        <div class="col-4">
                            <span><strong>Ability: </strong></span>
                        </div>
                        <div class="col-8">
                            <span>{{ detail.name }}</span>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-4">
                            <span><strong>Description: </strong></span>
                        </div>
                        <div class="col-8">
                            <span>{{ detail.description }}</span>
                        </div>
                    </div>

                    <div :key="formRefreshKey" v-if="detailsValue[index].showCertificationForm">
                        <div class="row mt-1">
                            <div class="col-4">
                                <span><strong>Document Name: </strong></span>
                            </div>
                            <div class="col-8">
                                <FormTextInput v-model:input="detail.doc_name" :validation="yup.string()" />
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-4">
                                <strong>Expiry: </strong>
                            </div>
                            <div class="col-8">
                                <FormDateInput
                                    v-model:input="detail.expiry"
                                    :validation="yup.date().nullable().default(null).transform((curr, orig) => orig === '' ? null : curr).min(moment(), 'Invalid Date')"
                                />
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-4">
                                <span><strong>Source: </strong></span>
                            </div>
                            <div class="col-8">
                                <FormTextInput v-model:input="detail.source" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <a
                            v-if="!detailsValue[index].showCertificationForm"
                            href="javascript: void(0)"
                            @click.prevent="showCertificationForm(index)"
                        >
                            <SvgIcon
                                :path="mdiPlusCircle"
                                type="mdi"
                                class="me-1"
                                size="20"
                            ></SvgIcon>
                            Add a certification document
                        </a>
                        <Button v-else @click="hideCertificationForm(index)" size="small" color="secondary">Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
        <div class="config-row">
            <div  class="col-11 config-col">
                <select v-model="selected" class="form-select">
                    <option value="null" disabled>Please select an Ability to add</option>
                    <option v-for="(option, index) in filteredOptions" :value="option.code" :key="index">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="col-1 config-col justify-content-center">
                <button @click="addSelected" class="h-100 w-100" :disable="!selected" v-tooltip="'Add Selected Ability'">
                    <SvgIcon type="mdi" :path="mdiPlusCircle" class="text-primary" size="30"></SvgIcon>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, watch, computed } from 'vue'
    import { onReady, copyValues, transformInput } from '../Utils'
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiPlusCircle, mdiInformationOutline, mdiCloseCircleOutline  } from '@mdi/js'
    import { Button } from '../'
    import { FormDateInput, FormTextInput } from '../Forms'
    import { debounce } from 'lodash'
    import * as yup from 'yup'

    const axios = inject('axios')
    const toast = inject('toast')

    const { options, details } = defineProps({
        options: {
            required: true,
            type: Array,
        },
        details: {
            required: true,
            type: Array
        }
    })

    const detailsValue = ref ([])

    const mounted = ref(false)

    onReady(() => {
        detailsValue.value = copyValues(details ?? [])
        mounted.value = true
    })

    const selected = ref(null)

    const addSelected = () => {
        let option = filteredOptions.value.filter((option) => {
            return option.code === selected.value
        })

        if (!option) {
            let message = 'Unable to add selected Option';
            toast.error(message, { title: 'OOPs something went wrong'})
            return
        }

        option = option[0]

        let item = {
            code: option.code,
            name: option.name,
            description: option.description,
            doc_name: transformInput({
                required: false,
                valid: true
            }),
            source: transformInput({
                required: false,
                valid: true
            }),
            expiry: transformInput({
                required: false,
                valid: true,
            }),
            showCertificationForm: false
        }

        detailsValue.value.push(item);

        selected.value = null
    }

    const removeSelected = (index) => {
        detailsValue.value.splice(index, 1)
    }

    const filteredOptions = computed(() => {
        let used = (detailsValue.value ?? []).map((item) => {
            return item.code
        })

        let selectable = options.filter((item) => {
            return !used.includes(item.code)
        })

        return selectable
    })

    const formRefreshKey = ref(Date.now())
    const showCertificationForm = (index) => {
        detailsValue.value[index].showCertificationForm = true
    }
    const hideCertificationForm = (index) => {
        detailsValue.value[index].showCertificationForm = false
        detailsValue.value[index].doc_name.value = ''
        detailsValue.value[index].source.value = ''
        detailsValue.value[index].expiry.value = ''
        formRefreshKey.value = ref(Date.now())
    }

    const emit = defineEmits(['updated'])

    watch(
        details,
        debounce((value) => {
            if (value !== detailsValue) {
                detailsValue.value = value
            }
        }, 500)
    )

    watch(
        detailsValue,
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                emit('updated', newValue)
            }
        }
    )
</script>

<style scoped>
    .config-row {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
    }
    .config-col {
        padding: 10px 5px;
        justify-content: center;
    }
    .data-item {
        border: 1px solid var(--bs-gray-500);
        border-radius: var(--bs-border-radius);
        padding-left: 0.7rem;
        margin-top: 0.375rem;
    }
</style>
