<template>
    <div class="dropdown pointer">
        <button type="button"
                :id="componentId"
                :disabled="isButtonDisabled()"
                aria-expanded="false"
                class="btn dropdown-toggle text-dark"
                :class="{'btn-outline-light': mode === 'verbose', 'ps-0': mode === 'compact'}"
                data-bs-toggle="dropdown">
            <InvoiceStatus
                :status="draft.status"
                :class="{'me-2': mode === 'verbose'}"
                hide-tool-tip
            />
            <template v-if="mode === 'verbose'">{{ title }}</template>
        </button>
        <ul :aria-labelledby="componentId" class="dropdown-menu" :class="mode">
            <template v-for="(label, status) in statuses">
                <li v-if="status.toString() !== draft.status">
                    <a class="dropdown-item"
                       href="javascript: void(0)"
                       @click="updateStatus(status)">
                        <InvoiceStatus
                            :status="status"
                            class="me-1"
                            hide-tool-tip
                        />
                        {{ label }}
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {uuid} from "vue-uuid";
import InvoiceStatus from "@components/Invoices/InvoiceStatus.vue";

const componentId = ref(uuid.v4());
const props = withDefaults(defineProps<{
        invoice: App.Models.Invoice;
        mode: "verbose" | "compact";
    }>(),
    {
        mode: 'verbose'
    }
);

const draft = ref<App.Models.Invoice>(props.invoice);
const statuses = ref<string[]>(window.enums.invoiceStatus);
const originalStatus = ref('');

const updateStatus = function (status: string) {
    draft.value.status = status;
};

const title = computed(() => {
    return statuses.value[draft.value.status];
});

const isButtonDisabled = () => {
    return false;
}

onMounted(() => {
    originalStatus.value = draft.value.status;
});
</script>

<style scoped>
</style>
