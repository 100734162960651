<template>
    <div class="d-flex flex-column flex-1">
        <div class="d-flex flex-row">
            <input
                :type="validation === 'email' ? 'email' : 'text'"
                :id="id"
                class="form-control me-1"
                v-bind:class="{ 'border-danger': !isTextValid }"
                v-model="text"
            />

            <input type="hidden" :name="name" v-model="value" />
            <button
                type="button"
                class="btn btn-primary"
                @click="storeItem"
                :disabled="text.trim().length === 0 || !isTextValid"
            >
                +
            </button>
        </div>
        <p v-if="!isTextValid" class="text-danger">
            Invalid {{ validation }}
        </p>
        <ul class="list-group mt-1">
            <li
                class="list-group-item d-flex justify-content-between align-items-center list-group-item-light"
                v-for="(item, index) in items"
            >
                {{ item }}
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    @click="onDelete(index)"
                ></button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "MultiTextInput",
    props: {
        initial: Array,
        name: String,
        validation: String,
        id: String,
    },
    data() {
        return {
            items: [],
            text: "",
        };
    },
    computed: {
        value: function () {
            return JSON.stringify(this.items);
        },
        isTextValid: function () {
            if (!this.validation) {
                return true;
            } else if (this.validation === "email") {
                return this.text === "" || this.validateEmail(this.text);
            } else {
                return true;
            }
        },
    },
    beforeMount() {
        this.items = this.initial
    },
    emits: [
        'change',
    ],
    methods: {
        validateEmail: function (email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        storeItem: function () {
            this.items.push(this.text);
            this.$emit("change", this.name, JSON.stringify(this.items))
            this.text = ''
        },
        onDelete: function (index) {
            this.items.splice(index, 1);
            this.$emit("change", this.name, JSON.stringify(this.items));
        },
    },
};
</script>

<style scoped></style>
