<template>
    <div class="d-flex justify-content-center mb-2">
        <template v-if="paginatedMeta.to && paginatedMeta.last_page !== 1">
            <ul class="pagination pagination-rounded mb-0">
                <template v-for="(link, index) in paginatedMeta.links" :key="index">
                    <li class="page-item" :class="{ 'active': link.active }">
                        <a
                            v-html="link.label"
                            :class="link.url ?? 'disabled'"
                            @click.prevent="setPage(link)"
                            class="page-link"
                            href="javascript:void(0)"
                        ></a>
                    </li>
                </template>
            </ul>
        </template>
    </div>
    <div class="d-flex justify-content-center">
        <div>
            Showing {{ props.recordName }} from {{ paginatedMeta.from }} to {{ paginatedMeta.to }} of {{ paginatedMeta.total }}
        </div>
    </div>
</template>
<script setup lang="ts">
    import { storeToRefs } from "pinia";
    import { usePaginationStore } from "@stores/PaginationStore";
    
    const props = withDefaults(
        defineProps<{
            recordName?: string,
            storeName?: string,
        }>(),
        {
            recordName: 'record',
            storeName: 'default',
        },
    );

    const { paginateParams, paginatedMeta } = storeToRefs(usePaginationStore(props.storeName));

    const emit = defineEmits<{
        (e:'pageChanged', value: true): void,
    }>();

    const setPage = (link) => {
        paginateParams.value.page = getPageFromUrl(link.url);
        emit('pageChanged', true);
    }
    
    const getPageFromUrl = (url) => {
        if (url) {
            return url.split('page=')[1]
        }
    }
</script>