<template>
    <div class="d-flex align-items-center">
        <FormSwitch v-model="showFareAmount" label="Show Fare Amount" />
        <div v-if="fareAmountSaved" class="ms-2 d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="mdiCheckCircle"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>
    </div>

    <div class="d-flex align-items-center">
        <FormSwitch v-model="showCollectedAmount" label="Show Amount Collected" />
        <div v-if="collectedAmountSaved" class="ms-2 d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="mdiCheckCircle"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>
    </div>


</template>

<script setup>
import {inject, ref, watch} from "vue";
import FormSwitch from "@components/Forms/FormSwitch.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle } from "@mdi/js";

const props = defineProps({
    settings: {
        required: true,
        type: Array,
    },
})

const showFareAmount = ref(props.settings.find((setting) => setting.name === 'run_list_show_fare_amount')?.value === '1')
const showCollectedAmount = ref(props.settings.find((setting) => setting.name === 'run_list_show_amount_collected')?.value === '1')

watch(showFareAmount, () => {
    updateSettings('fare_amount')
})

watch(showCollectedAmount, () => {
    updateSettings('collected_amount')
})

const axios = inject('axios')
const fareAmountSaved = ref(false)
const collectedAmountSaved = ref(false)
const updateSettings = (setting) => {
    axios
        .put(route("organisation-settings.update"), {
            category: 'general',
            settings: {
                run_list_show_fare_amount: showFareAmount.value ? 1 : 0,
                run_list_show_amount_collected: showCollectedAmount.value ? 1 : 0,
            }
        }).then(() => {
            if (setting === 'fare_amount') {
                fareAmountSaved.value = true
                setTimeout(() => { fareAmountSaved.value = false }, 2000)
            } else if (setting === 'collected_amount') {
                collectedAmountSaved.value = true
                setTimeout(() => { collectedAmountSaved.value = false }, 2000)
            }
        })
}

</script>

<style scoped>

</style>
