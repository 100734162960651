<template>
    <div :key="refreshKey">
        <FormRow>
            <FormCol sm="12">
                <div v-if="!driverIsCompliant && showWarning" class="alert alert-warning">
                    Drivers without a current Driver Record Check can not be allocated to vehicle runs.
                </div>
                <div class="form-check">
                    <div v-for="complianceType in driverComplianceTypes" :key="complianceType.value" class="mb-1">
                        <input
                            v-model="record.compliance"
                            :id="`driver-compliance-${complianceType.value}`"
                            class="form-check-input"
                            type="checkbox"
                            :value="complianceType.value"
                            @change="emitRecordDetails"
                        >
                        <label
                            class="form-check-label"
                            :for="`driver-compliance-${complianceType.value}`"
                        >{{ complianceType.label }}</label>
                    </div>
                </div>
                <FormValidationError :errors="errors" field="compliance"></FormValidationError>
            </FormCol>
        </FormRow>
        <FormCol md="4">
            <FormDateInput
                v-model:input="record.driver_record_check_date"
                @update:input="emitRecordDetails"
                label="Driver Record Check Date"
                :validation="yup.date()"
            />
        </FormCol>
    </div>
</template>

<script setup>
import { reactive, ref, inject, computed, watch, onMounted } from 'vue'
import { DateTime, Interval } from "luxon";
import { onReady, transformInput } from '../Utils'
import { FormRow, FormCol } from '../Forms/Layouts'
import { FormDateInput, FormValidationError } from '../Forms'
import * as yup from 'yup'

const axios = inject('axios')

const { errors, showWarning } = defineProps({
    errors: {
        type: Object,
        required: true
    },
    showWarning: {
        type: Boolean,
        default: true
    },
    isCompliant: {
        type: Boolean,
        default: false
    }
})

const refreshKey = ref(Date.now())

watch(
    () => errors,
    (newValue, oldValue) => {
        if (newValue.driver_record_check_date) {
            record.driver_record_check_date.valid = false
            record.driver_record_check_date.errors = newValue.driver_record_check_date
        } else {
            record.driver_record_check_date.valid = true
            record.driver_record_check_date.errors = []
        }
    },
    { deep: true }
)

const emit = defineEmits(['updated'])

const emitRecordDetails = () => {
    emit('updated', record)
}

const record = reactive({
    driver_record_check_date: transformInput({ value: '', required: false }),
    compliance: [],
})

const driverComplianceTypes = ref([]);
const getDriverComplianceTypes = function () {
    axios
        .get(route('api.enum.driver-compliance-types'))
        .then((response) => {
            driverComplianceTypes.value = response.data
        })
}

const driverIsCompliant = computed(() => {
    return allComplianceItemsChecked.value && recordCheckDateWithin3Years.value
})

const allComplianceItemsChecked = computed(() => {
    let allItemsChecked = false

    if (record.compliance.length) {
        allItemsChecked = driverComplianceTypes.value.every(type => {
            return record.compliance.includes(type.value)
        })
    }

    return allItemsChecked
})

const recordCheckDateWithin3Years = computed(() => {
    const recordDate = DateTime.fromISO(record.driver_record_check_date.value)
    const today = DateTime.fromISO(DateTime.now())

    const diff = today.diff(recordDate, ["years"])

    if (isNaN(diff)) {
        return false
    }

    return diff.years < 3
})

onMounted(() => {
    getDriverComplianceTypes()
})
</script>