<template>
    <tr>
        <td>
            <EditbreakTime
                :journey="journey"
                :journey-break="journeyBreak"
                field="start_time"
                @updated="emit('updated', $event)"
            />
        </td>
        <td>
            <EditbreakTime
                :journey="journey"
                :journey-break="journeyBreak"
                field="end_time"
                @updated="emit('updated', $event)"
            />
        </td>
        <td>{{ breakDuration }}</td>
        <td class="text-end">
            <a
                @click.prevent="emit('confirmDelete', journeyBreak)"
                class="text-danger"
                href="javascript: void(0)"
            >
                Delete
            </a>
        </td>
    </tr>
</template>

<script setup>
import { computed, inject } from 'vue'
import EditbreakTime from './EditableFields/EditBreakTime.vue'
import { DateTime } from 'luxon'

const props = defineProps({
    journey: {
        type: Object,
        required: true
    },
    journeyBreak: {
        type: Object,
        required: true
    },
})

const emit = defineEmits(['updated', 'confirmDelete'])

const breakDuration = computed(() => {
    const start = DateTime.fromISO(props.journeyBreak.start_time)
    const end = DateTime.fromISO(props.journeyBreak.end_time)
    const diff = end.diff(start, ['hours', 'minutes'])

    if (diff.hours > 0 && !diff.minutes) {
        return `${diff.hours}h`
    } else if (diff.hours && diff.minutes) {
        return `${diff.hours}h ${Math.floor(diff.minutes)}m`
    }

    return `${diff.minutes}m`
})
</script>