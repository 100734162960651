<template>
    <div>
        <div v-if="showLabel">
            <strong>Type:</strong> <br />
        </div>
        <select class="w-100 form-control" :class="{'mt-2': showLabel}" v-model="type" @change="typeSelected">
            <option value="null" disabled>Select a Data Import Type</option>
            <option v-for="(type, idx) in importOptions" :key="idx" :value="type.value">{{ type.label }}</option>
        </select>
    </div>
</template>

<script setup lang="ts">
    import { onBeforeMount, ref } from "vue";
    import { storeToRefs } from "pinia";
    import { useDataImportStore } from "@stores/DataImportStore";

    const dataImportStore = useDataImportStore();
    const { importOptions } = storeToRefs(dataImportStore);
    const { getImportOptions } = dataImportStore;

    const props = withDefaults(
        defineProps<{
            showLabel: boolean,
        }>(), {
            showLabel: true,
        }
    );

    const emit = defineEmits<{
        (e: 'selected', value: string): void
    }>();

    const type = ref(null);

    const typeSelected = (): void => {
        if (type.value) {
            emit('selected', type.value);
        }
    }

    onBeforeMount(() => {
        getImportOptions();
    })
</script>