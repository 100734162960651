<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <PageLengthSelector
                            record-name="drivers"
                            :disabled="loadingDrivers"
                        />
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <DataTableSearch 
                            @search="getDrivers(true)"
                            placeholder="Search drivers..."
                        />
                    </div>
                </div>

                <template v-if="!loadingDrivers">
                    <table
                        class="table table-striped table-centered table-sm dt-responsive nowrap w-100"
                    >
                        <thead>
                            <tr>
                                <th class="sorting">Driver</th>
                                <th class="sorting">Actions</th>
                                <th class="text-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="driver in drivers">
                                <td class="table-user d-flex">
                                    <a
                                        :href="
                                            //@ts-ignore
                                            route('drivers.show', {
                                                driver: driver.uuid,
                                            })
                                        "
                                        class="d-flex align-items-center me-1"
                                    >
                                        <DriverAvatar
                                            :driver="driver"
                                            size="extra-small"
                                            class="me-2"
                                        ></DriverAvatar>
                                        {{ driver.name }}
                                    </a>
                                    <span
                                        v-if="driver.deleted_at"
                                        v-tooltip="
                                            'This driver has been deactivated'
                                        "
                                    >
                                        <i
                                            class="mdi mdi-cancel text-danger"
                                        ></i>
                                    </span>
                                </td>
                                <td>
                                    <a
                                        href="#"
                                        onclick="event.preventDefault();"
                                        @click="driverToBeLoggedOut = driver"
                                        >Log Out</a
                                    >
                                </td>
                                <td class="text-end"></td>
                            </tr>
                        </tbody>
                    </table>
                </template>

                <template v-if="loadingDrivers">
                    <div class="d-flex w-100 justify-content-center align-items-center ps-3">
                        <Loading
                            :show-text="false"
                            spinner-class="spinner-grow"
                        />
                    </div>
                </template>

                <Pagination
                    v-if="!loadingDrivers"
                    @pageChanged="getDrivers(true)"
                    record-name="drivers"
                ></Pagination>
            </div>
        </div>

        <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="header-title mt-0">Logout Confirmation</h4>
                    </div>
                    <div class="modal-body pb-3">
                        Are you sure you want to log
                        <strong>{{ driverToBeLoggedOut?.name }}</strong> out of
                        the Trips Driver app?
                    </div>
                    <div class="modal-footer">
                        <Button
                            class="btn btn-secondary"
                            @click="driverToBeLoggedOut = null"
                        >
                            Close
                        </Button>
                        <Button
                            class="btn btn-danger"
                            @click="onLogDriverOut"
                            :loading="loggingDriverOut"
                        >
                            Log Driver Out
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount, inject, onMounted, watch } from "vue";
    import { storeToRefs } from "pinia";
    import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
    import DriverAvatar from "./DriverAvatar.vue";
    import Button from "../../components/Button.vue";
    import { Axios } from "axios";
    import { Pagination, PageLengthSelector, DataTableSearch } from "@components/DataTable";
    import { useDriverStore } from "@stores/DriverStore";
    import { usePaginationStore } from "@stores/PaginationStore";
    import { useRegionStore } from "@stores/RegionStore";
    import { Loading } from "@components";

    const axios = inject<Axios>("axios");

    const driverStore = useDriverStore();
    const { drivers, loadingDrivers } = storeToRefs(driverStore);
    const { getDrivers } = driverStore;

    const { paginateParams } = storeToRefs(usePaginationStore());

    const { selectedRegion } = storeToRefs(useRegionStore());

    const loggingDriverOut = ref<boolean>(false);
    const driverToBeLoggedOut = ref<null | App.Models.Driver>(null);

    const modalId = ref<string>("ActiveNowDriverDeactivationConfirmationModal");
    const modal = ref<null | bootstrap.Modal>(null);

    const onLogDriverOut = async () => {
        if (!driverToBeLoggedOut.value) {
            return;
        }

        loggingDriverOut.value = true;

        await axios.post(route("api.drivers.logout"), {
            driver: driverToBeLoggedOut.value?.uuid,
        })
        .then(() => {
            driverToBeLoggedOut.value = null;
            getDrivers(true);
        })
        .catch((err) => console.error(err))
        .then(() => loggingDriverOut.value = false);

    };

    const initModal = () => {
        const modalElement = document.getElementById(modalId.value);
        if (!modalElement) {
            return;
        }
        modal.value = new bootstrap.Modal(modalElement, {});
    };

    watch(
        driverToBeLoggedOut, 
        (newValue) => {
            if (newValue) {
                modal.value?.show();
            } else {
                modal.value?.hide();
            }
        }
    );

    watch(
        paginateParams.value,
        () => {
            getDrivers(true);
        }
    );

    watch(
        selectedRegion,
        () => {
            getDrivers(true);
        }
    )

    onBeforeMount(() => {
        getDrivers(true);
    });

    onMounted(() => {
        initModal();
    });
</script>
