import axios from "axios";
import { ApiResourceResponseInterface } from "../types/LaravelCommonTypes";
import ClientResource from "@customTypes/resources/ClientResource";

//Override some properties
export type ClientInterface = ClientResource;

export default class Client {
    static index(data: object) {
        return axios.get<ApiResourceResponseInterface<ClientInterface>>(
            route('api.clients.index', data)
        );
    }

    static getInitials(client: ClientInterface): string {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
        let initials: any[] | string = [...client.name.matchAll(rgx)] || [];
        let initialsString = (
            (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();

        return initialsString;
    }

    static show(clientUuid: string) {
        return axios.get<ApiResourceResponseInterface<ClientInterface>>(
            route("api.clients.show", { client: clientUuid })
        );
    }

    static update(
        client: ClientInterface,
        parameters: Partial<ClientInterface>
    ) {
        return axios.put(route("api.clients.update", { client: client.uuid }), {
            ...parameters,
            ...{
                regions: parameters.regions?.map((region) => {
                    return {
                        uuid: region.uuid,
                        is_default: region.pivot.is_default,
                    };
                }),
            },
        });
    }
}
