<template>
    <EditableMultiSelect
        :key="refreshKey"
        @updated="update"
        :disabled="!editable"
        :value="savedClasses"
        :options="licenceClassOptions"
        track-by="value"
        label="label"
        :multiple="true"
        :loading="updating"
    >
        <small>{{ licenceClassNames.join(', ') }}</small>
    </EditableMultiSelect>
    <FormValidationError :errors="errors" field="licence_classes"></FormValidationError>
</template>

<script setup>
import {computed, inject, ref, onMounted, onBeforeMount} from "vue"
import EditableMultiSelect from "@components/Forms/EditableFields/EditableMultiSelect.vue"
import { FormValidationError } from "@components/Forms"

const axios = inject('axios')

const props = defineProps({
    driver: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const refreshKey = ref(Date.now())

const licenceClassNames = ref(props.driver.licence_class_names)
const driverClasses = ref(props.driver.licence_classes)

const savedClasses = computed(() => {
    return licenceClassOptions.value.filter(licenceClass => {
        return driverClasses.value.includes(licenceClass.value)
    })
})

const editable = computed(() => {
    return !props.readOnly
})

const emit = defineEmits(['updated'])

const updating = ref(false)
const errors = ref([])
const update = function (newClasses) {
    updating.value = true
    axios
        .put(route('api.drivers.update', { driver: props.driver.uuid }), {
            licence_classes: newClasses.map(licenceClass => licenceClass.value)
        })
        .then((response) => {
            driverClasses.value = response.data.licence_classes
            licenceClassNames.value = response.data.licence_class_names
            refreshKey.value = Date.now()
            errors.value = []
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

const licenceClassOptions = ref([])
const getLicenceClasses = () => {
    axios
        .get(route('api.enums.index', { enum: 'license-class' }))
        .then(response => {
            licenceClassOptions.value = response.data
            refreshKey.value = Date.now()
        })
}

onBeforeMount(() => {
    getLicenceClasses()
})

</script>
