<template>
    <div class="row">
        <div class="col-md-4">
            <h4>General Information</h4>
            <p>Enter the basic details of the vehicle.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <VehicleGeneralForm
                        v-model="vehicle.general"
                        :licenses="licenses"
                        :regions="regions"
                        :types="types"
                        :colours="colours"
                        :ownership="ownership"
                    />
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-8 offset-md-4">
            <Button color="primary" :class-array="['float-end']" @click="saveVehicle" :loading="loading" :disabled="!valid">Create Vehicle</Button>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, inject, computed } from 'vue'
    import VehicleGeneralForm from './parts/VehicleGeneralForm.vue'
    import { reduce, each, map } from 'lodash'
    import { onReady } from '../Utils'
    import { Button } from '../'

    const axios = inject('axios')
    const toast = inject('toast')

    const { types, regions, licenses, access, colours, ownership } = defineProps({
        types: {
            required: true,
            type: Object,
        },
        regions: {
            required: true,
            type: Object,
        },
        licenses: {
            required: true,
            type: Object,
        },
        access: {
            required: true,
            type: Object,
        },
        colours: {
            required: true,
            type: Object,
        },
        ownership: {
            required: true,
            type: Object,
        },
    })

    const vehicle = reactive({
        general: {
            uuid: null,
            registration_code: {
                value: '',
                valid: false,
                required: true,
                errors: [],
            },
            type: {
                value: null,
                valid: false,
                required: true,
                errors: [],
            },
            starting_odometer: {
                value: '',
                valid: false,
                required: true,
                errors: [],
            },
            description: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            make: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            model: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            operating_costs: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            license_required: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            region: {
                value: null,
                valid: false,
                required: true,
                errors: [],
            },
            depot: {
                value: {
                    full_address: null
                },
                valid: false,
                required: true,
                errors: [],
            },
            notes: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            year: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            owner: {
                value: null,
                valid: false,
                required: true,
                errors: [],
            },
            colour: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            registration_expiry: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            insurance_expiry: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            vin: {
                value: null,
                valid: true,
                required: true,
                errors: [],
            },
            modification_compliance_plate_date: {
                value: null,
                valid: true,
                required: true,
                errors: [],
            },
            date_of_purchase: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            purchase_cost: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            date_of_disposal: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            leasing_company: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
            lease_expiry_date: {
                value: null,
                valid: true,
                required: false,
                errors: [],
            },
        },
        config: {},
        access: [],
    })

    const mounted = ref(false)
    const loading = ref(false)

    onReady(() => mounted.value = true)

    const valid = computed(() => {
        let outcome = false
        if (mounted.value) {
            outcome = reduce(vehicle.general, (result, input) => {
                if (input !== null && input.valid !== undefined) {
                    result = input.valid ? (result ?? true) : false
                    return result
                }
            }, true)
        }
        return outcome
    })

    const configParam = (value, key) => {
        return {
            key: key,
            value: value,
        }
    }

    const saveVehicle = () => {
        if (valid.value) {
            loading.value = true

            let params = {
                type: vehicle.general.type.value,
                region: vehicle.general.region.value,
                depot: vehicle.general.depot.uuid,
                registration_code: vehicle.general.registration_code.value,
                description: vehicle.general.description.value,
                starting_odometer: vehicle.general.starting_odometer.value,
                make: vehicle.general.make.value,
                model: vehicle.general.model.value,
                operating_costs: vehicle.general.operating_costs.value,
                license_required: vehicle.general.license_required.value,
                notes: vehicle.general.notes.value,
                owner: vehicle.general.owner.value,
                year: vehicle.general.year.value,
                colour: vehicle.general.colour.value,
                registration_expiry: vehicle.general.registration_expiry.value,
                insurance_expiry: vehicle.general.insurance_expiry.value,
                // config: map(vehicle.config, configParam),
                // access: Object.keys(vehicle.access ?? {}),
                vin: vehicle.general.vin.value,
                modification_compliance_plate_date: vehicle.general.modification_compliance_plate_date.value,
                date_of_purchase: vehicle.general.date_of_purchase.value,
                purchase_cost: vehicle.general.purchase_cost.value,
                date_of_disposal: vehicle.general.date_of_disposal.value,
                leasing_company: vehicle.general.leasing_company.value,
                lease_expiry_date: vehicle.general.lease_expiry_date.value,
            }

            axios.post(route('api.vehicles.store', {}, true), params).then(resp => {
                window.location = route('vehicles.show', {vehicle: resp.data.data.general.uuid}, true)
            }).catch(err => {
                if (err.response && err.response.status === 422) {
                    each(err.response.data.errors, (error, key) => {
                        vehicle.general[key].errors = error
                    })
                    toast.warning('Please Correct Marked Vehicle Input Issues')
                    return
                }
                console.error(err)
            }).finally(() => {
                loading.value = false
            })

        }

    }
</script>
