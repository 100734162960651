<template>
    <div class="d-flex justify-content-center align-items-center p-2" :class="directionCss">
        <span v-if="props.showText && props.textPosition == 'before'" :class="[props.color, textCss]">{{ props.text }}</span>
        <div :class="[props.spinnerClass, props.color]" role="status">
            <span class="visually-hidden">{{ props.text }}</span>
        </div>
        <span v-if="props.showText && props.textPosition == 'after'" :class="[props.color, textCss]">{{ props.text }}</span>
    </div>
</template>

<script setup lang="ts">
import {reactive} from "vue";

const props = withDefaults(
    defineProps<{
        text?: string,
        showText?: boolean,
        textPosition?: string,
        direction?: string,
        spinnerClass?: string,
        color?: string,
    }>(), {
        text: 'Loading...',
        showText: true,
        textPosition: 'after',
        direction: 'vertical',
        spinnerClass: 'spinner-border',
        color: 'text-dark',
    }
);

const directionCss = reactive({
    'flex-column': props.direction === 'vertical',
    'flex-row': props.direction === 'horizontal'
})

const textCss = reactive({
    'me-1': props.direction === 'horizontal' && props.textPosition === 'before',
    'ms-1': props.direction === 'horizontal' && props.textPosition === 'after',
    'mb-1': props.direction === 'vertical' && props.textPosition === 'before',
    'mt-1': props.direction === 'vertical' && props.textPosition === 'after',
})
</script>