<template>
    <label class="form-label text-nowrap">
        <span>Display</span>
        <select
            v-model="paginateParams.perPage"
            class="form-select form-select-sm d-inline-block mx-1"
            :disabled="props.disabled"
            @change="emit('changed')"
        >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
        </select>
        <span>{{ props.recordName }}</span>
    </label>
</template>

<script setup lang="ts">
    import ClientRegionPivotResource from "@customTypes/resources/ClientRegionPivotResource";
    import { storeToRefs } from "pinia";
    import { usePaginationStore } from "@stores/PaginationStore";

    const props = withDefaults(
        defineProps<{
            recordName?: string,
            disabled?: boolean,
            storeName?: string
        }>(),
        {
            recordName: 'records',
            disabled: false,
            storeName: 'default'
        }
    )

    const emit = defineEmits<{
        (e: "changed"): void;
    }>();

    const { paginateParams } = storeToRefs(usePaginationStore(props.storeName));

</script>