import {defineStore, storeToRefs} from "pinia";
import {Ref, ref} from "vue";
import TransactionResource from "@customTypes/resources/TransactionResource";
import {usePaginationStore} from "@stores/PaginationStore";
import {AxiosResponse} from "axios";
import {ApiResourceResponseInterface} from "@customTypes/LaravelCommonTypes";
import {DateTime} from "luxon";
import Transaction from "@classes/Transaction"

export interface GetAllTransactionsRequestInterface {
    category?: string,
    dateFrom?: string | null,
    dateTo?: string | null,
    paymentMethods?: { label: string, value: string }[] | null,
    collectionMethods?: { label: string, value: string }[] | null,
    types?: { label: string, value: string }[] | null,
    client?: string | null,
}

export interface MultiSelectOption {
    label: string,
    value: string,
}

export const useTransactionStore = defineStore("TransactionStore", () => {
    const transactions: Ref<TransactionResource[] | undefined> = ref<TransactionResource[]>();
    const transaction: Ref<TransactionResource | undefined> = ref<TransactionResource>();
    const {paginatedMeta, paginateParams, searchQuery} = storeToRefs(usePaginationStore('TransactionStore'));

    // @todo consider loading these values from API
    const availableTypes: MultiSelectOption[] = [
        {label: "Deposit", value: 'deposit'},
        {label: "Withdrawal", value: 'withdrawal'},
        {label: "Payment", value: 'payment'},
        {label: "Refund", value: 'refund'},
    ];

    // @todo consider loading these values from API
    const availableCollectionMethods: MultiSelectOption[] = [
        {label: 'Account', value: 'account'},
        {label: 'Payment To Driver', value: 'payment_to_driver'},
        {label: 'Prepaid', value: 'prepaid'},
    ];

    // @todo consider loading these values from API
    const availablePaymentMethods: MultiSelectOption[] = [
        {label: 'Cash', value: 'cash'},
        {label: 'Credit Card', value: 'credit_card'},
        {label: 'Direct Debit', value: 'direct_debit'},
        {label: 'Bank Transfer', value: 'bank_transfer'},
        {label: 'Admin Adjustment', value: 'adjustment'},
        {label: 'Account Credit', value: 'account_credit'},
    ];

    const defaultTypes: MultiSelectOption[] = availableTypes;
    const defaultCollectionMethods: MultiSelectOption[] = availableCollectionMethods;
    const defaultPaymentMethods: MultiSelectOption[] = availablePaymentMethods;

    const filters: Ref<GetAllTransactionsRequestInterface> = ref({
        category: 'transactions',
        dateFrom: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        dateTo: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        collectionMethods: defaultCollectionMethods,
        paymentMethods: defaultPaymentMethods,
        types: defaultTypes,
    })

    const isLoading:Ref<boolean> = ref<boolean>(false);

    const getAllTransactions = async (request: GetAllTransactionsRequestInterface = filters.value): Promise<ApiResourceResponseInterface<TransactionResource[]>> => {
        isLoading.value = true;

        let data = {
            paginate: true,
            perPage: paginateParams.value.perPage,
            page: paginateParams.value.page,
            column: paginateParams.value.sortBy,
            direction: paginateParams.value.sortDirection,
            dateFrom: request.dateFrom,
            dateTo: request.dateTo,
            query: searchQuery.value?.trim(),
            collectionMethods: request.collectionMethods?.map((method: MultiSelectOption) => method.value),
            paymentMethods: request.paymentMethods?.map((method: MultiSelectOption) => method.value),
            types: request.types?.map((type: MultiSelectOption) => type.value),
            client: request.client,
        }

        try {
            let response: AxiosResponse<ApiResourceResponseInterface<TransactionResource[]>> = await Transaction.index(data);
            transactions.value = response.data.data;
            paginatedMeta.value = response.data.meta ?? {};
            return response.data;
        } catch(e:unknown) {
            throw e;
        } finally {
            isLoading.value = false;
        }
    }

    const addTransaction = async (transaction: TransactionResource): Promise<ApiResourceResponseInterface<TransactionResource>> => {
        let data = {
            amount: transaction.invoice?.total,
            invoice: transaction.invoice?.uuid,
            collection_method: (transaction.collection_method as unknown as MultiSelectOption).value,
            comments: transaction.comments,
            payment_method: (transaction.payment_method as unknown as MultiSelectOption).value,
            receipt_number: transaction.receipt_number,
            type: 'payment',
        }
        let response: AxiosResponse<ApiResourceResponseInterface<TransactionResource>> = await Transaction.create(data);
        return response.data;
    }

    return {
        transaction,
        transactions,
        filters,
        availableTypes,
        availableCollectionMethods,
        availablePaymentMethods,
        defaultTypes,
        defaultCollectionMethods,
        defaultPaymentMethods,
        isLoading,
        getAllTransactions,
        addTransaction
    }
})