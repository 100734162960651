<template>
    <div>
        <div v-if="client.deactivated" class="alert alert-warning d-flex justify-content-between align-items-center">
            <div>This client is currently not active, you will need to re-activate them in order to update their information.</div>
            <ReactivateClientLink :client="client" @restored="updateClient"></ReactivateClientLink>
        </div>
        <ClientAlert :client="client" :read-only="!permissions.includes('update_client')" @updated="updateClient"/>
        <div v-if="!client.alert"></div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="flex-shrink-1 mx-2">
                        <div class="me-2 flex-shrink-1 d-none d-md-block">
                            <ClientAvatar :size="'full'" :client="client"></ClientAvatar>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <h4>{{ client.name }}</h4>
                        <p>{{ client.address ? client.address.formatted_address : '' }}</p>
                        <div class="d-flex justify-content-between align-items-end mt-3">
                            <div class="d-flex">
                                <div class="me-4">
                                    <strong class="fs-5">{{ $filters.comma_separated_thousands(client.total_metres) }}
                                        km</strong>
                                    <div>Total Distance Travelled</div>
                                </div>
                                <div class="me-4">
                                    <strong class="fs-5">{{ totalTrips }}</strong>
                                    <div>Trips This Year</div>
                                </div>
                                <div class="me-4" v-if="client.funding_type">
                                    <strong class="fs-5">{{ client.funding_type.name }} ({{ client.funding_type.code }})</strong>
                                    <div>Default Funding Type</div>
                                </div>
                                <div class="me-4">
                                    <strong class="fs-5">History</strong>
                                    <div>
                                        <a href="javascript: void(0)" @click="activityLogs.show('client', client.uuid)">View Activity Logs</a>
                                    </div>
                                </div>
                            </div>
                            <div>

                                <CreateBookingForClientButton :client="client">
                                    <Button color="light">
                                        <SvgIcon class="me-1" type="mdi" :path="mdiClockPlusOutline"/>
                                        Create Booking
                                    </Button>
                                </CreateBookingForClientButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body pb-2">
                        <div class="d-flex justify-content-between align-items-start">
                            <h4 class="header-title mt-0 mb-3">Basic Details</h4>
                            <div v-if="permissions.includes('update_client') && !client.deactivated" class="dropdown">
                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="mdi mdi-dots-vertical"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a
                                        class="dropdown-item text-danger"
                                        href="javascript:void(0);"
                                        @click.prevent="deactivateModal.show()"
                                    >
                                        Deactivate Client
                                    </a>
                                </div>
                            </div>
                        </div>
                        <DeactivateClientModal :client="client"></DeactivateClientModal>
                        <dl class="row mb-0">
                            <dt class="col-sm-4">Client ID:</dt>
                            <dd class="col-sm-8">#{{ client.uuid }}</dd>
                            <dt class="col-sm-4">Full Name:</dt>
                            <dd class="col-sm-8">
                                <EditClientName
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Preferred Name:</dt>
                            <dd class="col-sm-8">
                                <EditClientPreferredName
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Email:</dt>
                            <dd class="col-sm-8">
                                <EditClientEmail
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Phone:</dt>
                            <dd class="col-sm-8">
                                <div>
                                    <EditClientPhoneNumber
                                        :read-only="!permissions.includes('update_client')"
                                        @updated="updateClient"
                                        :client="client"
                                    />
                                    <div>
                                        <SendMessage :receiver-type="'client'" :receiver-info="client">
                                            <i class="mdi mdi-message"></i> Send message
                                        </SendMessage>
                                    </div>
                                </div>
                            </dd>
                            <dt class="col-sm-4">Additional Phone:</dt>
                            <dd class="col-sm-8">
                                <div>
                                    <EditClientAdditionalPhoneNumber
                                        :read-only="!permissions.includes('update_client')"
                                        @updated="updateClient"
                                        :client="client"
                                    />
                                </div>
                            </dd>
                            <dt class="col-sm-4">Home Address:</dt>
                            <dd class="col-sm-8">
                                <EditClientAddress
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Postal Address:</dt>
                            <dd class="col-sm-8">
                                <EditClientPostalAddress
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Date of Birth:</dt>
                            <dd class="col-sm-8">
                                <EditClientDOB
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Gender:</dt>
                            <dd class="col-sm-8">
                                <EditClientGender
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Region:</dt>
                            <dd class="col-sm-8">
                                <EditClientRegion
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Referring Agency:</dt>
                            <dd class="col-sm-8">
                                <EditClientReferringAgency
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <template v-if="client.agency_contact">
                                <dt class="col-sm-4">Agency Contact:</dt>
                                <dd class="col-sm-8">{{ client.agency_contact.name }}</dd>
                            </template>
                            <dt class="col-sm-4">Birth Country:</dt>
                            <dd class="col-sm-8">
                                <EditClientCountry
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Language:</dt>
                            <dd class="col-sm-8">
                                <EditClientLanguage
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Indigenous Status:</dt>
                            <dd class="col-sm-8">
                                <EditClientIndigenousStatus
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">DVA Number:</dt>
                            <dd class="col-sm-8">
                                <EditClientDvaNumber
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">My Aged Care Number:</dt>
                            <dd class="col-sm-8">
                                <EditClientMyAgedCareNumber
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">NDIS Number:</dt>
                            <dd class="col-sm-8">
                                <EditClientNdisNumber
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">CRN:</dt>
                            <dd class="col-sm-8">
                                <EditClientCrn
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Medicare Card Number:</dt>
                            <dd class="col-sm-8">
                                <EditClientMedicareCardNumber
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Medicare IRN:</dt>
                            <dd class="col-sm-8">
                                <EditClientMedicareIrn
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                />
                            </dd>
                            <dt class="col-sm-4">Fare collection method:</dt>
                            <dd class="col-sm-8">
                                <EditClientDefaultFareCollectionMethod
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    :client="client"
                                    :fareCollectionMethods="fareCollectionMethods"
                                />
                            </dd>
                            <dt class="col-sm-4">Allocation Alert:</dt>
                            <dd class="col-sm-8">
                                <EditClientRecordTextField
                                    :read-only="!permissions.includes('update_client')"
                                    @updated="updateClient"
                                    field="allocations_alert"
                                    :client="client"
                                />
                            </dd>
                        </dl>
                        <template v-if="!client.deactivated && permissions.includes('delete_client')">
                            <hr>
                            <button @click="deactivateModal.show()" class="btn btn-link text-danger mb-1">Deactivate</button>
                        </template>
                    </div>
                </div>
                <EmergencyContacts
                    :model="client"
                    model-name="client"
                    :emergency-contacts="emergencyContacts"
                ></EmergencyContacts>
                <EditClientAssessmentDetails :client="client" @updated="updateClient"/>
                <EditClientFundingDetails @updated="updateClient" :client="client"></EditClientFundingDetails>
                <EditClientFundingPlans
                    v-if="client.funding_type && client.funding_type.line_items.length > 0"
                    @updated="updateClient"
                    :client="client"
                ></EditClientFundingPlans>
                <EditClientNotificationTypes
                    :read-only="!permissions.includes('update_client')"
                    :client="client"
                ></EditClientNotificationTypes>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-sm-4">
                        <ProfileTileCard :title="'Trips This Year'" :value="totalTrips" :icon-class="'mdi mdi-car'"></ProfileTileCard>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <i class="mdi mdi-currency-usd float-end text-muted" style="font-size: 2em; opacity: .3"></i>
                                <div class="d-flex align-items-baseline">
                                    <h6 class="text-muted text-uppercase my-0">{{ client.balance > 0 ? 'Client is in credit' : 'Client Owes' }}</h6>
                                    <div v-if="client.balance > 0" class="ms-2 lh-1 text-xs">
                                        (<a href="javascript: void(0)" @click="showRefundModal">Issue Refund</a>)
                                    </div>
                                </div>
                                <h2 class="m-b-20" :class="client.balance > 0 ? 'text-success' : (client.balance !== 0 ? 'text-warning' : '')">
                                    {{ currencyFilter(Math.abs(client.balance)) }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <ProfileTileCard :title="'Incidents'" :value="client.incidents.length" :icon-class="'mdi mdi-alert-circle-outline'"></ProfileTileCard>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <ClientTripsChart :bookingsPerMonth="client.bookings_per_month"></ClientTripsChart>
                    </div>
                </div>
                <div class="tabs">
                    <div class="nav nav-tabs">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'bookings' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('bookings')"
                            >Bookings</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'booking_requirements' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('booking_requirements')"
                            >Default Booking Requirements</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'invoices' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('invoices')"
                            >Invoices & Charges</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'transactions' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('transactions')"
                            >Transactions</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'comments' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('comments')"
                            >Comments</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'carers' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('carers')"
                            >Carers</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'attachments' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('attachments')"
                            >Attachments</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'referrals' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('referrals')"
                            >Referrals</a>
                        </li>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <ClientBookings v-if="currentTab === 'bookings'" :client="client" @setTab="setTab"/>
                        <ClientBookingRequirements v-if="currentTab === 'booking_requirements'" :booking-service-types="bookingServiceTypes" :client="client"/>
                        <ClientInvoices v-if="currentTab === 'invoices'" :client="client"/>
                        <ClientTransactions v-if="currentTab === 'transactions'" :client="client"/>
                        <ClientComment v-if="currentTab === 'comments'" :client="client"/>
                        <ClientCarer v-if="currentTab === 'carers'" :client="client"/>
                        <AttachmentsList v-if="currentTab === 'attachments'" :client="client"/>
                        <EditReferrals v-if="currentTab === 'referrals'" @updated="updateClient" :client="client" :availableReferrals="referrals"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="refundModal" tabindex="-1" aria-labelledby="refundModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="refundModalLabel">Issue Refund</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure you would like to record a refund transaction of <strong>{{ currencyFilter(Math.abs(client.balance)) }}</strong> for <strong>{{
                            client.name
                        }}</strong>?
                    </div>
                    <div class="modal-footer">
                        <Button color="secondary" data-bs-dismiss="modal">Cancel</Button>
                        <Button @click="issueRefund" :loading="issuingRefund">Record Refund</Button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import Referral from "@classes/Referral";
import AttachmentsList from "@components/Attachments/AttachmentsList.vue";
import Button from "@components/Button.vue";
import ClientBookingRequirements from "@components/Clients/ClientBookingRequirements.vue";
import ClientCarer from "@components/Clients/ClientCarerList.vue";
import ClientComment from "@components/Clients/ClientCommentList.vue";
import CreateBookingForClientButton from "@components/Clients/CreateBookingForClientButton.vue";
import EditReferrals from "@components/Clients/EditReferrals.vue";
import ReferralResource from "@customTypes/resources/ReferralsResource";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiClockPlusOutline} from '@mdi/js'
import axios from "axios";
import {Modal} from "bootstrap";
import {computed, inject, onBeforeMount, onMounted, ref} from "vue"
import EmergencyContacts from "../Contacts/EmergencyContacts.vue";
import SendMessage from "../SendMessage/SendMessage.vue";
import {currencyFilter} from "../Utils.js";
import ClientAlert from "./ClientAlert.vue";
import ClientAvatar from "./ClientAvatar.vue";
import ClientBookings from "./ClientBookings.vue";
import ClientInvoices from "./ClientInvoices.vue";
import ClientTransactions from "./ClientTransactions.vue";
import ClientTripsChart from "./ClientTripsChart.vue";
import DeactivateClientModal from "./DeactivateClientModal.vue";
import EditClientAdditionalPhoneNumber from "./EditClientAdditionalPhoneNumber.vue";
import EditClientAddress from "./EditClientAddress.vue";
import EditClientAssessmentDetails from './EditClientAssessmentDetails.vue'
import EditClientCountry from "./EditClientCountry.vue";
import EditClientCrn from "./EditClientCrn.vue";
import EditClientDefaultFareCollectionMethod from "./EditClientDefaultFareCollectionMethod.vue";
import EditClientDOB from "./EditClientDOB.vue";
import EditClientDvaNumber from "./EditClientDvaNumber.vue";
import EditClientEmail from "./EditClientEmail.vue";
import EditClientFundingDetails from "./EditClientFundingDetails.vue";
import EditClientFundingPlans from "./EditClientFundingPlans.vue";
import EditClientGender from "./EditClientGender.vue";
import EditClientIndigenousStatus from "./EditClientIndigenousStatus.vue";
import EditClientLanguage from "./EditClientLanguage.vue";
import EditClientMedicareCardNumber from "./EditClientMedicareCardNumber.vue";
import EditClientMedicareIrn from "./EditClientMedicareIrn.vue";
import EditClientMyAgedCareNumber from "./EditClientMyAgedCareNumber.vue";
import EditClientName from "./EditClientName.vue";
import EditClientNdisNumber from "./EditClientNdisNumber.vue";
import EditClientNotificationTypes from "./EditClientNotificationTypes.vue";
import EditClientPhoneNumber from "./EditClientPhoneNumber.vue";
import EditClientPostalAddress from "./EditClientPostalAddress.vue";
import EditClientPreferredName from "./EditClientPreferredName.vue";
import EditClientRecordTextField from "./EditClientRecordTextField.vue";
import EditClientReferringAgency from "./EditClientReferringAgency.vue";
import EditClientRegion from "./EditClientRegion.vue";
import ProfileTileCard from "./ProfileTileCard.vue";
import ReactivateClientLink from "./ReactivateClientLink.vue";

const permissions = ref(window.permissions);

const activityLogs = inject("activityLogs");

const client = ref({
    uuid: '',
    contacts: [{
        type: '',
    }],
    bookings_per_month: [],
})

const {clientModel, transportRequirements, cargoSpace, assistanceTypes} = defineProps({
    clientModel: {
        required: true,
        type: Object,
    },
    transportRequirements: {
        required: true,
        type: Object,
    },
    cargoSpace: {
        required: true,
        type: Object,
    },
    assistanceTypes: {
        required: true,
        type: Object,
    },
    bookingServiceTypes: {
        required: true,
        type: Object,
    }
})

const currentTab = ref('bookings')
const setTab = (tab) => {
    currentTab.value = tab
}

const updateClient = function (updatedClientData) {
    client.value = updatedClientData.data
}

const emergencyContacts = computed(() => {
    if (client.value.contacts.length === 0) {
        return []
    }

    return client.value.contacts.filter((contact) => contact.type === 'emergency')
})

const totalTrips = computed(() => client.value.bookings_per_month.reduce((prev, curr) => prev + curr, 0))

let fareCollectionMethods = ref([])
const getFareCollectionMethods = function () {
    axios
        .get(route('api.enum.fare-collection-methods'))
        .then((response) => {
            fareCollectionMethods.value = response.data
        })
}

const showRefundModal = function () {
    refundModal.value.show();
}

const toast: any = inject("toast");
const issuingRefund = ref(false)
const issueRefund = function () {
    issuingRefund.value = true
    axios
        .post(route('api.clients.refund', {client: client.value.uuid}))
        .then((response) => {
            client.value = response.data.data
            refundModal.value.hide()
        })
        .catch((error) => {
            toast.error('There was an error recording the refund. ' + error.response.data.message)
        })
        .finally(() => {
            issuingRefund.value = false
        })
}

const referrals = ref<ReferralResource[]>([]);
const getReferrals = async () => {
    referrals.value = await Referral.getAll();
};

onBeforeMount(() => {
    client.value = clientModel as typeof client.value;
    getFareCollectionMethods();
    getReferrals();
})

const refundModal = ref()
const deactivateModal = ref()
onMounted(() => {
    deactivateModal.value = new Modal(document.getElementById('deactivateModal') as HTMLElement)
    refundModal.value = new Modal(document.getElementById('refundModal') as HTMLElement)
})

</script>

<style scoped>

</style>
