<template>
    <div>
        <div>
            <label class="form-label">Bookings:</label>
            <div class="table-responsive-xxl">
                <table
                    class="allocations-table table-xs text-sm centered dt-responsive no-wrap w-100 align-middle"
                >
                    <thead>
                        <tr class="small">
                            <th class="td-shrink text-center ps-2">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    title="Select All"
                                    v-model="selectAll"
                                />
                            </th>
                            <th class="td-shrink"></th>
                            <th class="td-shrink"></th>
                            <th
                                class="td-shrink text-center sortable"
                                :class="{
                                    'sort-asc':
                                        sortBy === 'priority' &&
                                        sortDirection === 'asc',
                                    'sort-desc':
                                        sortBy === 'priority' &&
                                        sortDirection === 'desc',
                                }"
                                role="button"
                                @click="toggleSort('priority')"
                            >
                                Info
                            </th>
                            <th
                                :class="{
                                    sortable: true,
                                    'sort-asc':
                                        sortBy === 'client' &&
                                        sortDirection === 'asc',
                                    'sort-desc':
                                        sortBy === 'client' &&
                                        sortDirection === 'desc',
                                }"
                                role="button"
                                @click="toggleSort('client')"
                            >
                                Client
                            </th>
                            <th
                                :class="{
                                    sortable: true,
                                    'sort-asc':
                                        sortBy === 'origin' &&
                                        sortDirection === 'asc',
                                    'sort-desc':
                                        sortBy === 'origin' &&
                                        sortDirection === 'desc',
                                }"
                                role="button"
                                @click="toggleSort('origin')"
                            >
                                Origin
                            </th>
                            <th
                                :class="{
                                    sortable: true,
                                    'sort-asc':
                                        sortBy === 'destination' &&
                                        sortDirection === 'asc',
                                    'sort-desc':
                                        sortBy === 'destination' &&
                                        sortDirection === 'desc',
                                }"
                                role="button"
                                @click="toggleSort('destination')"
                            >
                                Destination
                            </th>
                            <th class="td-shrink">Actions</th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="currentBookings"
                        group="meals"
                        tag="tbody"
                        item-key="uuid"
                    >
                        <template #item="{ element: booking, index: key }">
                            <AllocationsBookingRow
                                :booking="booking"
                                :keyValue="key"
                                :key="key"
                                :allocation-errors="allocationErrors"
                            />
                        </template>
                    </draggable>
                </table>
            </div>
            <div
                class="dropdown"
                :class="{
                    'd-none': selectedBookingsInAllocatedList.length === 0,
                    'text-end': true,
                }"
            >
                <button
                    class="btn btn-primary btn-sm dropdown-toggle mt-2"
                    type="button"
                    :id="'actionAllocatedBookings_' + uuid"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    With Selected
                </button>
                <div
                    class="dropdown-menu"
                    :aria-labelledby="'actionAllocatedBookings_' + uuid"
                >
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="deAllocateSelectedBookings"
                        >Deallocate</a
                    >
                    <div class="dropdown">
                        <a
                            class="dropdown-item dropdown-toggle arrow-none"
                            href="javascript: void(0)"
                            :id="'other-journeys-list-' + uuid"
                            role="button"
                            data-bs-toggle="dropdown"
                        >
                            Move to another vehicle run
                            <div class="arrow-down"></div>
                        </a>
                        <div
                            class="dropdown-menu"
                            style="max-height: 300px; overflow: scroll"
                            :aria-labelledby="'other-journeys-list-' + uuid"
                        >
                            <a
                                href="javascript: void(0)"
                                @click="
                                    reAllocateSelectedBookings(otherJourney)
                                "
                                v-for="otherJourney in otherJourneys"
                                class="dropdown-item"
                            >
                                <template v-if="otherJourney.driver"
                                    >{{ otherJourney.driver.given_names }}
                                    {{
                                        otherJourney.driver.last_name
                                    }}</template
                                >
                                <template v-else>No driver specified</template>

                                <template v-if="otherJourney.vehicle"
                                    >{{ " " }} -
                                    {{ otherJourney.vehicle.description }}
                                    ({{
                                        otherJourney.vehicle.registration_code
                                    }})</template
                                >
                            </a>
                        </div>
                    </div>
                    <a
                        class="dropdown-item"
                        @click="cancelSelectedBookings"
                        href="javascript:void(0)"
                        >Mark as cancelled</a
                    >
                    <a
                        class="dropdown-item"
                        @click="forceAllocationBookings"
                        href="javascript:void(0)"
                    >
                        Force Allocation
                    </a>
                    <a
                        class="dropdown-item"
                        @click="removeForceAllocationBookings"
                        href="javascript:void(0)"
                    >
                        Remove Force Allocation
                    </a>
                </div>
            </div>
        </div>
        <div v-if="waitlistedBookings.length > 0">
            <label class="form-label">Wait-listed (Standby)</label>
            <ul class="list-unstyled">
                <li v-for="booking in waitlistedBookings">
                    {{
                        booking.planned_origin_time
                            ? moment(booking.planned_origin_time).format(
                                  "HH:mm"
                              ) + " – "
                            : ""
                    }}
                    {{ booking.client?.given_names }}
                    {{ booking.client?.last_name }} (<a
                        href="javascript: void(0)"
                        @click="() => (booking.status = BookingStatus.Future)"
                        >allocate</a
                    >)
                </li>
            </ul>
        </div>
        <div v-if="cancelledBookings.length > 0">
            <label class="form-label">Cancelled</label>
            <ul class="list-unstyled">
                <li v-for="booking in cancelledBookings">
                    {{
                        //Use BookingDatetime here when I figure out why in UAT envoking that class fails, possibly node versions? But this will do for now
                        DateTime.fromISO(
                            booking.planned_origin_time ??
                                booking.estimated_origin_time ??
                                ""
                        ).toFormat("HH:mm")
                    }}
                    –
                    {{ booking.client?.given_names }}
                    {{ booking.client?.last_name }}
                    <AllocationBookingCancellation
                        :selected="selectedBookingsInAllocatedList"
                        :booking="booking"
                    />
                    (<a
                        href="javascript: void(0)"
                        @click="() => (booking.status = BookingStatus.Future)"
                        >restore</a
                    >)
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import AllocationBookingCancellation from "./AllocationBookingCancellation.vue";
import { DateTime } from "luxon";
import { computed, inject, ref, watch } from "vue";
import { uuid as vueUuid } from "vue-uuid";
import moment from "moment";
import draggable from "vuedraggable";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import AllocationsBookingRow from "./Components/AllocationsBookingRow.vue";
import Booking, { BookingStatus } from "../../classes/Booking";

const toast = inject<any>("toast");

const allocationStore = useAllocationStore();

const { bookingsAllocatedToJourney, checkAllocationRequirements, matchesAllocationAllocatedSectionSearchPhrase } = allocationStore;

const {
    selectedBookingsInAllocatedList,
    selectedJourney,
    journeys,
    bookings,
    originalBookings,
    allocationErrors,
} = storeToRefs(allocationStore);

const sortBy = ref("origin");
const sortDirection = ref("asc");
const uuid = ref(vueUuid.v1());

const selectAll = computed({
    get: () => {
        return (
            selectedBookingsInAllocatedList.value.length > 0 &&
            selectedBookingsInAllocatedList.value.length ===
                selectedBookingsInVehicleRun.value.length
        );
    },
    set: (checked) => {
        let newSelected: App.Models.Booking[] = [];
        if (checked) {
            selectedJourney?.value?.bookings?.forEach((booking) => {
                newSelected.push(booking);
            });
        }
        selectedBookingsInAllocatedList.value = newSelected;
    },
});

const getOriginalBooking = (booking: App.Models.Booking) => {
    return originalBookings.value.find((i) => i.uuid == booking.uuid);
};

const otherJourneys = computed(() => {
    return journeys.value.filter((journey) => {
        return journey.uuid !== selectedJourney.value?.uuid;
    });
});

const bookingsForRun = computed(() => {
    return bookings.value.filter(
        (booking) => booking?.journey?.uuid == selectedJourney.value?.uuid
    );
});

//Handles the drag and drop logic
const currentBookings = computed({
    get: () => {
        if (!selectedJourney.value) {
            return [];
        }

        let bookingsForJourney = bookingsAllocatedToJourney(
            selectedJourney.value
        );

        return sort(bookingsForJourney.filter(matchesAllocationAllocatedSectionSearchPhrase));
    },
    set: (newCurrentBookings) => {
        if (! checkAllocationRequirements(newCurrentBookings)) {
            return;
        };

        const bookingsToDeAllocate = currentBookings.value.filter((booking) => {
            return !newCurrentBookings.find(
                (newBooking) => newBooking.uuid === booking.uuid
            );
        });

        bookingsToDeAllocate.map((bookingToDeAllocate) => {
            bookingToDeAllocate.journey = null;
        });

        const bookingsToAllocate = newCurrentBookings.filter((booking) => {
            return !currentBookings.value.find(
                (currentBooking) => currentBooking.uuid === booking.uuid
            );
        });

        if (!selectedJourney?.value) {
            return;
        }

        bookingsToAllocate.map((bookingToAllocate) => {
            bookingToAllocate.journey = selectedJourney?.value;
            bookingToAllocate.is_force_allocation = false;
            bookingToAllocate.is_manually_allocated = true;
        });
    },
});

const selectedBookingsInVehicleRun = computed(() => {
    return bookingsForRun.value.filter((booking) => {
        return (
            selectedBookingsInAllocatedList.value.find((selected) => {
                return selected.uuid === booking.uuid;
            }) !== undefined
        );
    });
});

const cancelledBookings = computed(() => {
    return bookingsForRun.value.filter((booking) => {
        return booking.status === BookingStatus.Cancelled;
    });
});

const waitlistedBookings = computed(() => {
    return bookingsForRun.value.filter((booking) => {
        return booking.status === BookingStatus.Standby;
    });
});

const cancelSelectedBookings = () => {
    selectedBookingsInVehicleRun.value.forEach((selectedBooking) => {
        selectedBooking.status = BookingStatus.Cancelled;
    });
};

const waitlistSelectedBookings = () => {
    selectedBookingsInVehicleRun.value.forEach((selectedBooking) => {
        selectedBooking.status = BookingStatus.Standby;
    });
};

const deAllocateSelectedBookings = () => {
    selectedBookingsInVehicleRun.value.forEach((selectedBooking) => {
        selectedBooking.journey = null;
    });
};

const forceAllocationBookings = () => {
    selectedBookingsInVehicleRun.value.forEach((selectedBooking) => {
        selectedBooking.is_force_allocation = true;
    });
};

const removeForceAllocationBookings = () => {
    selectedBookingsInVehicleRun.value.forEach((selectedBooking) => {
        selectedBooking.is_force_allocation = false;
    });
}

const reAllocateBooking = (
    booking: App.Models.Booking,
    otherJourney: App.Models.Journey
) => {
    booking.journey = otherJourney;
};

const reAllocateSelectedBookings = (otherJourney) => {
    selectedBookingsInAllocatedList.value.forEach((selectedBooking) => {
        let booking = selectedBookingsInVehicleRun.value?.find(
            (bookingModel) => bookingModel.uuid === selectedBooking.uuid
        );
        if (booking) {
            if (! checkAllocationRequirements([booking], otherJourney)) {
                return;
            }

            reAllocateBooking(booking, otherJourney);
        }
    });

    checkAllocationRequirements(selectedBookingsInAllocatedList.value, otherJourney);
};

const sort = (bookings: App.Models.Booking[]) => {
    let sorted = bookings;
    switch (sortBy.value) {
        case "priority":
            sorted = sortByPriority(bookings);
            break;
        case "client":
            sorted = sortByClient(bookings);
            break;
        case "destination":
            sorted = sortByDestination(bookings);
            break;
        default:
            sorted = sortByOrigin(bookings);
    }

    if (sortDirection.value === "desc") {
        sorted = sorted.reverse();
    }

    return sorted;
};

const toggleSort = (column) => {
    sortDirection.value =
        sortBy.value !== column
            ? "asc"
            : sortDirection.value === "asc"
            ? "desc"
            : "asc";

    sortBy.value = column;
};

watch(
    () => [sortBy.value, sortDirection.value],
    () => {
        currentBookings.value = sort(currentBookings.value);
    }
);

const sortByPriority = (bookings: App.Models.Booking[]) => {
    return bookings.sort((a, b) => {
        if (a.priority === b.priority) {
            return 0;
        }
        if (a.priority === "Low" || b.priority === "High") {
            return -1;
        }
        if (b.priority === "Low" || a.priority === "High") {
            return 1;
        }
        return 0;
    });
};
const sortByClient = (bookings: App.Models.Booking[]) => {
    return bookings.sort((a, b) => {
        //@ts-ignore
        if (a.client.name < b.client.name) {
            return -1;
        }
        //@ts-ignore
        if (a.client.name > b.client.name) {
            return 1;
        }
        return 0;
    });
};
const sortByDestination = (bookings: App.Models.Booking[]) => {
    return bookings.sort((a, b) => {
        let destinationA =
            a.planned_destination_time ?? a.estimated_destination_time;
        let destinationB =
            b.planned_destination_time ?? b.estimated_destination_time;

        if (!destinationA) {
            return -1;
        }

        if (!destinationB) {
            return 1;
        }

        if (destinationA < destinationB) {
            return -1;
        }
        if (destinationA > destinationB) {
            return 1;
        }

        return 0;
    });
};
const sortByOrigin = (bookings: App.Models.Booking[]) => {
    return bookings.sort((a, b) => {
        let originA = a.planned_origin_time ?? a.estimated_origin_time;
        let originB = b.planned_origin_time ?? b.estimated_origin_time;

        if (!originA) {
            return -1;
        }

        if (!originB) {
            return 1;
        }

        if (originA < originB) {
            return -1;
        }
        if (originA > originB) {
            return 1;
        }
        return 0;
    });
};
</script>

<style scoped>
th.sortable {
    cursor: pointer;
    min-width: 65px;
    position: relative;
}

th.sortable:before,
th.sortable:after {
    right: 0.5rem;
    left: auto;
    font-family: "Material Design Icons";
    font-size: 1rem;
    position: absolute;
    bottom: 0.5em;
    display: block;
    opacity: 0.3;
}

th.sortable:before {
    content: "\F0360";
    top: 2px;
}

th.sortable:after {
    content: "\F035D";
    top: 8px;
}

th.sortable.sort-asc:before {
    opacity: 1;
}

th.sortable.sort-desc:after {
    opacity: 1;
}
</style>
