<template>
    <template v-for="(trackingCategory, index) in trackingCategories" v-if="trackingCategories">
        <div class="row mb-3">
            <div class="col-md-3">
                Category #{{ index + 1 }}
                <p class="form-text">Description</p>
            </div>
            <div class="col-md-8">
                <EditableInput :value="trackingCategory.name" @updated="updateTrackingCategory(trackingCategory, $event)"></EditableInput>
            </div>
            <div class="col-md-1 text-end">
                <Button color="danger-link" size="small" :icon="mdiTrashCan"
                        @click="confirmDeleteTrackingCategory(trackingCategory)"></Button>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                Options
                <p class="form-text">Description</p>
            </div>
            <div class="col-md-9 pe-0">
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th class="text-end">
                            <Button color="link" size="small" :icon="mdiPlusCircle" @click="confirmCreateTrackingCategoryOption(trackingCategory)"></Button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="trackingCategoryOption in trackingCategory.options" class="px-0">
                        <td class="align-middle">
                            <EditableInput
                                :value="trackingCategoryOption.name"
                                @updated="updateTrackingCategoryOption(trackingCategory, trackingCategoryOption, $event)">
                            </EditableInput>
                        </td>
                        <td class="text-end">
                            <Button color="danger-link" size="small" :icon="mdiTrashCan" @click="confirmDeleteTrackingCategoryOption(trackingCategory,
                            trackingCategoryOption)">
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
    <div class="row mb-3">
        <div class="col text-end">
            <Button color="primary" size="small" :icon="mdiPlusCircle"
                    @click="confirmCreateTrackingCategory">Create tracking category
            </Button>
        </div>
    </div>
    <ConfirmModal :show="showDeleteTrackingCategoryConfirmationModal"
                  @cancelled="resetDeleteTrackingCategoryAndOption"
                  @confirmed="deleteTrackingCategory">
    </ConfirmModal>
    <ConfirmModal :show="showDeleteTrackingCategoryOptionConfirmationModal"
                  @cancelled="resetDeleteTrackingCategoryAndOption"
                  @confirmed="deleteTrackingCategoryOption">

    </ConfirmModal>
    <ConfirmModal :show="showCreateTrackingCategoryModal"
                  @cancelled="resetDeleteTrackingCategoryAndOption"
                  @confirmed="createTrackingCategory">
        <template #body>
            <input type="text" class="form-control" v-model="(selectedTrackingCategory as TrackingCategoryResource).name"/>
        </template>
    </ConfirmModal>
    <ConfirmModal :show="showCreateTrackingCategoryOptionModal"
                  @cancelled="resetDeleteTrackingCategoryAndOption"
                  @confirmed="createTrackingCategoryOption">
        <template #body>
            <input type="text" class="form-control" v-model="(selectedTrackingOption as TrackingCategoryOptionResource).name"/>
        </template>
    </ConfirmModal>
</template>

<style scoped>

</style>

<script setup lang="ts">
import EditableInput from "@components/Forms/EditableFields/EditableInput.vue";
import TrackingCategoryResource, {TrackingCategoryOptionResource} from "@customTypes/resources/TrackingCategoryResource";
import TrackingCategory from "@classes/TrackingCategory";
import {inject, onMounted, ref, toRaw} from "vue";
import {AxiosError, AxiosResponse} from "axios";
import {ApiError, ApiErrorResponse, ApiResourceResponseInterface} from "@customTypes/LaravelCommonTypes";
import Button from "@components/Button.vue";
import {mdiPlusCircle, mdiTrashCan} from "@mdi/js";
import {ToastInterface} from "@components/toast";
import {ConfirmModal} from "@components/index";

const trackingCategories = ref<TrackingCategoryResource[]>();
const selectedTrackingCategory = ref<TrackingCategoryResource | null>(null);
const selectedTrackingOption = ref<TrackingCategoryOptionResource | null>(null);

const toast: ToastInterface = inject('toast') as ToastInterface;
const showDeleteTrackingCategoryConfirmationModal = ref<boolean>(false);
const showDeleteTrackingCategoryOptionConfirmationModal = ref<boolean>(false);
const showCreateTrackingCategoryModal = ref<boolean>(false);
const showCreateTrackingCategoryOptionModal = ref<boolean>(false);

const getAllTrackingCategories = async () => {
    let response: AxiosResponse<ApiResourceResponseInterface<TrackingCategoryResource[]>> = await TrackingCategory.index(true);
    trackingCategories.value = response.data.data;
    return trackingCategories.value;
};

const confirmCreateTrackingCategory = () => {
    selectedTrackingCategory.value = <TrackingCategoryResource>{
        name: "Untitled tracking category",
        status: 'ACTIVE'
    };
    showCreateTrackingCategoryModal.value = true;
}

const createTrackingCategory = async () => {
    console.log(`[XeroTrackingCategories] - createTrackingCategory() :: `, selectedTrackingCategory.value);
    try {
        await TrackingCategory.create(<TrackingCategoryResource>toRaw(selectedTrackingCategory.value));
    } catch (error: unknown) {
        console.error(`[XeroTrackingCategories] - createTrackingCategory() :: Error creating tracking category: `, (error as ApiError).response.data.message);
        toast.error((error as ApiError).response.data.message)
    } finally {
        await getAllTrackingCategories();
    }
    selectedTrackingCategory.value = null;
    showCreateTrackingCategoryModal.value = false;
}

const confirmDeleteTrackingCategory = (trackingCategory: TrackingCategoryResource): void => {
    selectedTrackingCategory.value = trackingCategory;
    showDeleteTrackingCategoryConfirmationModal.value = true;
}
const deleteTrackingCategory = async () => {
    try {
        await TrackingCategory.delete(<TrackingCategoryResource>toRaw(selectedTrackingCategory.value));
    } catch (error: unknown) {
        toast.error((error as ApiError).response.data.message)
    } finally {
        await getAllTrackingCategories();
        resetDeleteTrackingCategoryAndOption();
    }
}

const confirmCreateTrackingCategoryOption = (trackingCategory: TrackingCategoryResource) => {
    console.log(`[XeroTrackingCategories] - confirmCreateTrackingCategoryOption() :: `, trackingCategory);
    selectedTrackingCategory.value = trackingCategory;
    selectedTrackingOption.value = <TrackingCategoryOptionResource>{
        tracking_category_id: trackingCategory.tracking_category_id,
        name: "Untitled tracking option",
        status: 'ACTIVE'
    };
    showCreateTrackingCategoryOptionModal.value = true;
}
const createTrackingCategoryOption = async () => {
    console.log(`[XeroTrackingCategories] - createTrackingCategoryOption() :: `, selectedTrackingCategory.value, selectedTrackingOption.value);
    await TrackingCategory.createOption(
        <TrackingCategoryResource>toRaw(selectedTrackingCategory.value),
        <TrackingCategoryOptionResource>toRaw(selectedTrackingOption.value)
    );
    showCreateTrackingCategoryOptionModal.value = false;
    await getAllTrackingCategories();
}

const confirmDeleteTrackingCategoryOption = (trackingCategory: TrackingCategoryResource, trackingCategoryOption: TrackingCategoryOptionResource): void => {
    selectedTrackingCategory.value = trackingCategory;
    selectedTrackingOption.value = trackingCategoryOption;
    showDeleteTrackingCategoryOptionConfirmationModal.value = true;
}
const deleteTrackingCategoryOption = async () => {
    try {
        await TrackingCategory.deleteOption(
            <TrackingCategoryResource>toRaw(selectedTrackingCategory.value),
            <TrackingCategoryOptionResource>toRaw(selectedTrackingOption.value)
        );
    } catch (error: unknown) {
        toast.error((error as ApiError).response.data.message)
    } finally {
        await getAllTrackingCategories();
        resetDeleteTrackingCategoryAndOption();
    }
}

const updateTrackingCategoryOption = async (trackingCategory: TrackingCategoryResource, trackingCategoryOption: TrackingCategoryOptionResource, value) => {
    try {
        await TrackingCategory.updateOption(toRaw(trackingCategory), {...trackingCategoryOption, name: value});
    } catch (error: unknown) {
        toast.error((error as ApiError).response.data.message)
    } finally {
        await getAllTrackingCategories();
    }
}
const updateTrackingCategory = async (trackingCategory: TrackingCategoryResource, value: string) => {
    try {
        let result = await TrackingCategory.update({...trackingCategory, name: value});
        trackingCategories.value = result.data.data;
    } catch (error: unknown) {
        toast.error((error as ApiError).response.data.message)
    }
}

const resetDeleteTrackingCategoryAndOption = (event?): void => {
    selectedTrackingCategory.value = null;
    selectedTrackingOption.value = null;
    showCreateTrackingCategoryModal.value = false;
    showCreateTrackingCategoryOptionModal.value = false;
    showDeleteTrackingCategoryConfirmationModal.value = false;
    showDeleteTrackingCategoryOptionConfirmationModal.value = false;
}

onMounted(async () => {
    await getAllTrackingCategories();
})
</script>
