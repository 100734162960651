<template>
    <div class="card bg-light shadow-none" v-if="companion">
        <div class="card-body p-2">
            <slot name="action"></slot>
            <div class="d-flex">
                <div class="avatar-sm me-2 flex-shrink-1 d-none d-md-block">
                    <span class="avatar-title bg-secondary rounded-circle">
                        {{ initials }}
                    </span>
                </div>
                <div class="flex-grow-1" style="max-width: 280px">
                    <strong
                        >{{ companion?.given_names }}
                        {{ companion?.last_name }}</strong
                    ><br />
                    <small>{{ companion?.phone }}</small>
                </div>
            </div>
            <div class="mt-1 small">
                <slot name="content"></slot>
            </div>
        </div>
        <slot name="modal"></slot>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
    companion: App.Models.Carer | undefined;
}>();

const initials = computed<string>(() => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let carer = props.companion;

    if (!carer) {
        return "";
    }

    let full_name = carer.given_names + " " + carer.last_name;

    let result = [...full_name.matchAll(rgx)] || [];
    return (
        (result.shift()?.[1] || "") + (result.pop()?.[1] || "")
    ).toUpperCase();
});
</script>
