<template>
    <div>
        <label class="visually-hidden" for="searchBookings">Search</label>
        <div class="input-group" style="z-index: 1030">
            <input
                type="search"
                class="form-control form-control-sm"
                :placeholder="
                    'Search ' +
                    (allocationAllocatedSectionSearchBy === 'Clients'
                        ? 'client name'
                        : 'location') +
                    '...'
                "
                id="searchBookings"
                v-model="allocationAllocatedSectionSearchPhrase"
            />
            <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {{ allocationAllocatedSectionSearchBy }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li>
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="allocationAllocatedSectionSearchBy = 'Clients'"
                    >
                        Clients
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="
                            allocationAllocatedSectionSearchBy = 'Locations'
                        "
                    >
                        Locations
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";

const allocationStore = useAllocationStore();
const {
    allocationAllocatedSectionSearchPhrase,
    allocationAllocatedSectionSearchBy,
} = storeToRefs(allocationStore);
</script>
