<template>
    <div>
        <div
            v-if="!isCompliant"
            class="alert d-flex align-items-center"
            :class="{
                'alert-warning': driverRegulationCheckEnabled,
                'alert-info': !driverRegulationCheckEnabled
            }"
        >
            <SvgIcon type="mdi" :path="mdiAlertCircle" class="me-2 text-info"></SvgIcon>
            <p v-if="driverRegulationCheckEnabled">The Driver Record Check for {{ driver.name }} is out of date, and must be completed before assigning them to
                a vehicle run.
                <a @click.prevent="showComplianceModal" href="#">Update Driver Record Check</a>
            </p>
            <p v-else>The Driver Record Check for {{ driver.name }} is out of date, however regulation checks are currently not being enforced by the system when assigning drivers to vehicle runs.
                <a @click.prevent="showComplianceModal" href="#">Update Driver Record Check</a>
            </p>
        </div>
        <div id="driverComplianceModal" aria-hidden="true" aria-labelledby="driverComplianceModalLabel" class="modal fade"
            tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="driverComplianceModalLabel" class="modal-title">Update Driver Record Check</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <DriverRecordCheck
                            :errors="errors"
                            :show-warning="false"
                            @updated="driverLicenceComplianceUpdated"
                        ></DriverRecordCheck>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" data-bs-dismiss="modal" type="button">Close</button>
                        <Button @click="updateDriverRecordCheck" :loading="loading">Update Record</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, inject, watch} from 'vue'
import {mdiAlertCircle} from '@mdi/js'
import SvgIcon from "@jamescoyle/vue-icon"
import Button from "../Button.vue";
import DriverRecordCheck from './DriverRecordCheck.vue'
import { transformInput } from '../Utils';
import { each } from 'lodash'
import ElmHelper from "@classes/Helpers/ElmHelper";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    driver: Object,
    isCompliant: {
        type: Boolean,
        default: true
    },
    driverRegulationCheckEnabled: {
        type: Boolean,
        required: true
    },
    showModal: {
        type: Boolean,
        default: false
    },
})

watch(() => props.showModal, (value) => {
    if (value) {
        showComplianceModal()
    }
})

const driverComplianceModal = ref(null)

const showComplianceModal = () => {
    driverComplianceModal.value.show()
}

const driverRecordCheck = reactive({
    driver_record_check_date: '',
    compliance: []
})

const driverLicenceComplianceUpdated = (record) => {
    driverRecordCheck.driver_record_check_date = record.driver_record_check_date.value
    driverRecordCheck.compliance = record.compliance
}

let loading = ref(false)
let isCompliant = ref(props.isCompliant || false)
let errors = reactive({
    driver_record_check_date: [],
    compliance: [],
})

const updateDriverRecordCheck = () => {
    loading.value = true

    axios.post(route('api.drivers.driver-record-check', { driver: props.driver.uuid }, true), driverRecordCheck).then(res => {
        toast.success('Driver Record Check Updated')
        driverComplianceModal.value.hide()

        if (res.data.is_compliant) {
            isCompliant.value = true
        }
    }).catch(err => {
        if (err.response && err.response.status === 422) {
            each(err.response.data.errors, (error, key) => {
                errors[key] = error
            })

            toast.warning('Please Correct Marked Input Issues')
            loading.value = false
            return
        }

        toast.error('Failed to Create Driver Record Check')
        console.error(err)
        loading.value = false
    }).finally(() => {
        loading.value = false
    })
}

const emit = defineEmits(['modal-hidden'])
const emitModalHidden = () => {
    emit('modal-hidden')
}

onMounted(() => {
    ElmHelper.waitUntilRendered('#driverComplianceModal').then((elm) => {
        const modal = document.getElementById('driverComplianceModal')
        driverComplianceModal.value = new bootstrap.Modal(modal)

        modal.addEventListener('hidden.bs.modal', function (event) {
            emitModalHidden()
        })
    })
})
</script>
