<template>
  <div>
      <SendMessage :receiver-type="'crew'" :receiver-info="crewInfo">
          <slot></slot>
      </SendMessage>
  </div>
</template>

<script>
import SendMessage from "./SendMessage.vue";
export default {
    name: "MessageCrew",
    components: {
        SendMessage,
    },
    data() {
        return {
            crewInfo: null
        }
    },
    props: {
        crew: {
            type: Object,
            required: true,
        }
    },
    beforeMount() {
        this.crewInfo = {
            id: this.crew.SYSID,
            name: this.crew.NAME
        }
    }
}
</script>

<style scoped>

</style>
