<template>
    <div class="layout">
        <div class="col-9">
            <label for="vehicle_type" :class="labelClass">
            {{ label }}<span v-if="selection.required" class="text-danger">*</span>
            </label>
            <select v-model="selected" :class="[inputClass, { 'is-invalid': isInvalid(meta, selection) }]" name="vehicle_type" @blur="updated">
                <option disabled :value="null">-- Select Vehicle Type --</option>
                <option v-for="(type, index) in types" :key="index" :value="type.value">{{ type.label }}</option>
            </select>
        </div>
        <div class="col-3">
            <img
                v-if="selected"
                :src="typePreview"
                :class="imageClass"
                alt="Vehicle Icon"
            >
        </div>
    </div>
    <span :class="errorClass">{{ getErrorMessage(selection, errorMessage) }}</span>
</template>

<script setup>
    import { computed, ref, watch } from 'vue'
    import { useField } from 'vee-validate'
    import * as yup from 'yup'
    import { copyValues, onReady, isInvalid, getErrorMessage } from '../../Utils'

    const {  selection, types, validation, label, inputClass, labelClass, errorClass, imageClass } = defineProps({
        selection: {
            type: Object,
            required: true,
        },
        types: {
            type: Object,
            required: true
        },
        validation: {
            type: Object,
            default: yup.string().required()
        },
        label:{
            type: String,
            default: 'Vehicle Type',
        },
        inputClass: {
            type: String,
            default: 'form-select',
        },
        labelClass: {
            type: String,
            default: 'form-label',
        },
        errorClass: {
            type: String,
            default: 'text-danger',
        },
        imageClass: {
            type: String,
            default: 'image'
        }
    })

    const selected = ref(copyValues(selection.value))
    const mounted = ref(false)

    onReady(() => mounted.value = true)

    const typePreview = computed(function () {
        let result = types.find(function (type) {
            return type.value === selected.value
        })

        return result ? '/images/vehicles/' + result.image + '.png' : null
    })

    const { errorMessage, setValue, validate, meta } = useField('vehicle_type', validation.label('Vehicle Type'))

    const emit = defineEmits(['update:selection'])

    watch(
        selected,
        (value) => {
            if (mounted.value) {
                setValue(value)
                validate().then(() => {
                    let returnValue = copyValues(selection)
                    returnValue.value = meta.valid ? value : ''
                    returnValue.valid = meta.valid
                    emit('update:selection', returnValue)
                })
            }
        }
    )
</script>

<style scoped>
    .layout {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }
    .image {
        width: 100%;
        max-width: 250px;
        padding: 0px 10px;
    }
</style>
