export default class ElmHelper {
    /**
     * This is to wait for the element to finish rendering
     * Use this only when nextTick can't handle it anymore
     */
    static waitUntilRendered(selector)
    {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
}
