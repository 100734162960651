<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div>
                    <DataImportUpload />
                </div>
                <div class="my-3">
                    <DataImportList />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useDataImportStore } from "@stores/DataImportStore";
    import { storeToRefs } from "pinia";
    import DataImportUpload from "./DataImportUpload.vue";
    import DataImportList from "./DataImportList.vue";

    const dataImportStore = useDataImportStore();

    const { dataImports } = storeToRefs(dataImportStore);
</script>