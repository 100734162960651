import _ from "lodash";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import {
    BookingFormStopInterface,
    ClientSpecificValue,
    DraftBookingRequestInterface,
    useBookingStore,
} from "../../stores/BookingStore";
import Booking from "../Booking";
import Location from "../Location";
import BookingFormHelper from "./BookingFormHelper";
import { ClientInterface } from "../Client";
import Fare, { BookingCalculatorLegRequestInterface } from "../Fare";

export default class BookingFormOptimizerHelper {
    //Determins if parameters for fare calculation have changed between each render of the booking form so we are not sending
    //Unnecessary requests to the backend for calculation
    static fareRecalculationRequired(
        draft: DraftBookingRequestInterface,
        oldDraft: DraftBookingRequestInterface
    ): boolean {
        if (!draft.selectedClientUuid) {
            return false;
        }

        if (draft.stops.length !== oldDraft.stops.length) {
            return true;
        }

        if (draft.selectedClientUuid !== oldDraft.selectedClientUuid) {
            return true;
        }

        //Detect not existing stops, when new stop has been added
        let stopsNotExistingOnOldDraft = draft.stops.filter(
            (newStop, index) => !oldDraft.stops?.[index]
        );
        if (stopsNotExistingOnOldDraft.length > 0) {
            return true;
        }

        let recalculateRequired = false;

        function getClientSpecificValueForKey<T>(
            clientSpecificValue: ClientSpecificValue<T>[],
            defaultValue: any = undefined
        ): T {
            return (
                clientSpecificValue.find(
                    (i) => i.client_uuid == draft.selectedClientUuid
                )?.value ?? defaultValue
            );
        }

        if (
            getClientSpecificValueForKey(oldDraft.fundingTypeUuid) !==
            getClientSpecificValueForKey(draft.fundingTypeUuid)
        ) {
            recalculateRequired = true;
        }

        if (
            getClientSpecificValueForKey(oldDraft.regionUuid) !==
            getClientSpecificValueForKey(draft.regionUuid)
        ) {
            recalculateRequired = true;
        }

        draft.stops.map((newStop, index) => {
            let oldStop = oldDraft.stops?.[index];

            let newStopDistance =
                getClientSpecificValueForKey(
                    newStop.historicalEstimatedDistances
                ) ??
                Location.getDistanceFromDirections(
                    getClientSpecificValueForKey(newStop.directionResponses)
                );
            let oldStopDistance =
                getClientSpecificValueForKey(
                    oldStop.historicalEstimatedDistances
                ) ??
                Location.getDistanceFromDirections(
                    getClientSpecificValueForKey(oldStop.directionResponses)
                );

            if (newStopDistance !== oldStopDistance) {
                recalculateRequired = true;
            }

            if (
                !_.isEqual(
                    getClientSpecificValueForKey(newStop.tags, []).map(
                        (i) => i.uuid
                    ),
                    getClientSpecificValueForKey(oldStop.tags, []).map(
                        (i) => i.uuid
                    )
                )
            ) {
                recalculateRequired = true;
            }

            if (newStop.purpose !== oldStop.purpose) {
                recalculateRequired = true;
            }

            if (
                newStop.time?.toFormat("HH:mm") !==
                oldStop.time?.toFormat("HH:mm")
            ) {
                recalculateRequired = true;
            }

            if (newStop.transportType !== oldStop.transportType) {
                recalculateRequired = true;
            }

            if (newStop.vehicleTypeCode !== oldStop.vehicleTypeCode) {
                recalculateRequired = true;
            }
        });

        return recalculateRequired;
    }

    static getFundingRulesAppliedToBookingForm = (client: ClientInterface) => {
        const bookingStore = useBookingStore();
        const { transportRequirements, fundingTypes, draft, fundingRules } =
            storeToRefs(bookingStore);

        function getClientSpecificValueForKey<T>(
            clientSpecificValue: ClientSpecificValue<T>[],
            defaultValue: any = undefined
        ): T {
            return (
                clientSpecificValue.find((i) => i.client_uuid == client?.uuid)
                    ?.value ?? defaultValue
            );
        }

        let fundingTypeUuid = getClientSpecificValueForKey<string | undefined>(
            draft.value.fundingTypeUuid
        );

        let selectedFundingType = fundingTypes.value.find(
            (i) => i.uuid == fundingTypeUuid
        );

        if (!selectedFundingType) {
            return;
        }

        //Assuming only one funding rule can affect the booking form at a time
        //Find the funding rule to check logics with
        let fundingRulesApplyingToBookingForm = fundingRules.value?.filter(
            (rule) => {
                let fundingTypesAppliedToRule = rule?.funding_types ?? [];

                let appliesToSelectedFundingType =
                    fundingTypesAppliedToRule.length == 0 ||
                    fundingTypesAppliedToRule.find(
                        (i) => i.uuid == fundingTypeUuid
                    ) !== undefined;

                //valid_from and valid_until are actually stored as timestamps rather than usual datetime string in database
                let validFrom = rule.valid_from
                    ? DateTime.fromSeconds(+rule.valid_from)
                    : undefined;
                let validUntil = rule.valid_until
                    ? DateTime.fromSeconds(+rule.valid_until)
                    : undefined;

                let appliesToTransportType = false;

                draft.value.stops.forEach((stop) => {
                    if (rule.applies_to?.includes(stop.transportType)) {
                        appliesToTransportType = true;
                    }
                });

                let isValidForDatePeriod =
                    (!validFrom || validFrom < DateTime.now()) &&
                    (!validUntil || validUntil > DateTime.now());

                return (
                    appliesToTransportType &&
                    isValidForDatePeriod &&
                    appliesToSelectedFundingType
                );
            }
        );

        return fundingRulesApplyingToBookingForm;
    };
}

export const calculateBookingFareIfRequired = async () => {
    const bookingStore = useBookingStore();
    const { transportRequirements, selectedClient, fundingTypes } =
        storeToRefs(bookingStore);

    watch(
        () => _.cloneDeep(bookingStore.draft),
        async (draft, oldDraft) => {
            //Don't recalculate for every change, only for changes that affect fare
            if (
                !BookingFormOptimizerHelper.fareRecalculationRequired(
                    draft,
                    oldDraft
                )
            ) {
                return;
            }

            if (!selectedClient.value) {
                return;
            }

            let client = selectedClient.value;

            function getClientSpecificValueForKey<T>(
                clientSpecificValue: ClientSpecificValue<T>[],
                defaultValue: any = undefined
            ): T {
                return (
                    clientSpecificValue.find(
                        (i) => i.client_uuid == client.uuid
                    )?.value ?? defaultValue
                );
            }

            let fundingTypeUuid = getClientSpecificValueForKey<
                string | undefined
            >(draft.fundingTypeUuid);

            let selectedFundingType = fundingTypes.value.find(
                (i) => i.uuid == fundingTypeUuid
            );

            let selectedRegionUuid: string | undefined =
                getClientSpecificValueForKey<string | undefined>(
                    draft.regionUuid
                );

            if (!selectedFundingType) {
                return;
            }

            //Had a chat with Rachel based on providers needs, If there are tags applied to the booking form, we can't link bookings because they may want to specify

            let tagsAppliedToBookingForm = false;

            let stopLegLink: { stopIndex: number; legIndex: number }[] = [];

            draft.stops.forEach((stop, stopIndex) => {
                let tags = getClientSpecificValueForKey(
                    draft.stops[stopIndex].tags,
                    []
                );

                if (tags.length > 0) {
                    tagsAppliedToBookingForm = true;
                }
            });

            let fundingRulesApplyingToBookingForm =
                BookingFormOptimizerHelper.getFundingRulesAppliedToBookingForm(
                    client
                );

            let legs: BookingCalculatorLegRequestInterface[] = [];

            draft.stops.map(async (stop, index) => {
                if (index == 0) {
                    return;
                }

                let previousStop = draft.stops?.[index - 1];

                const originDestination =
                    BookingFormHelper.getOriginDestinationForCurrentStop({
                        previousStop,
                        stop,
                        client,
                    });

                const origin = originDestination.origin?.uuid;

                const destination = originDestination?.destination?.uuid;

                let distance =
                    getClientSpecificValueForKey(
                        stop.historicalEstimatedDistances
                    ) ??
                    Location.getDistanceFromDirections(
                        getClientSpecificValueForKey(stop.directionResponses)
                    );

                if (!selectedFundingType) {
                    return;
                }

                if (!stop?.time) {
                    return;
                }

                stopLegLink.push({
                    stopIndex: index,
                    legIndex: legs.length,
                });

                legs.push({
                    distance,
                    funding_type: selectedFundingType?.uuid,
                    region: selectedRegionUuid,
                    origin,
                    destination,
                    vehicle_type: stop?.vehicleTypeCode ?? undefined,
                    purpose: stop?.purpose ?? undefined,
                    group_booking: stop?.transportType == "group",
                    start_time: stop?.time,
                    tags: getClientSpecificValueForKey(stop.tags, []).map(
                        (t) => t.uuid
                    ),
                });
            });

            if (legs.length == 0) {
                return;
            }

            //Add the details in needs for linked bookings
            legs = legs.map((leg, index) => {
                return {
                    ...leg,
                    ...{
                        sequence_bookings_count: legs.length,
                        combined_estimated_distance: legs.reduce(
                            (a, b) => a + b.distance,
                            0
                        ),
                        combined_actual_distance: legs.reduce(
                            (a, b) => a + b.distance,
                            0
                        ),
                    },
                };
            });

            let fare = await Fare.calculate({
                legs,
            });

            draft.stops.map(async (stop, index) => {
                let legIndex = stopLegLink.find(
                    (i) => i.stopIndex == index
                )?.legIndex;

                if (legIndex == undefined) {
                    return;
                }

                if (!fare[legIndex]) {
                    return;
                }

                bookingStore.appendToStopByIndex(index, {
                    fare: bookingStore.appendToClientSpecificValues(
                        stop.fare,
                        client.uuid,
                        fare[legIndex]
                    ),
                });
            });
        }
    );
};
