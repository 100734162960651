import axios from "axios";
import {LocationInterface} from "@classes/Location";
import {uuid} from "vue-uuid";

export interface CarerInterface {
    uuid: string;
    given_names: string;
    last_name: string;
    email: string;
    phone: string;
    alt_phone: string;
    relationship: string;
    gender: string;
    date_of_birth: string;
    date_of_birth_is_estimated: boolean;
    birth_country: string;
    language: string;
    indigenous_status: string;
    residency_status: string;
    carer_for_multiple: boolean;
    address?: string;
    transport_requirements: [];
    assistance_types: [];
    cargo_spaces: [];
}

export interface InputInterface {
    uuid: string,
    value: string,
    required: boolean,
    errors: []
}

export interface CarerInputPayloadInterface {
    given_names: InputInterface,
    last_name: InputInterface,
    email: InputInterface,
    phone: InputInterface,
    alt_phone: InputInterface,
    address: InputInterface,
    date_of_birth: InputInterface,
    date_of_birth_is_estimated: {
        uuid: string,
        value: boolean,
        required: boolean,
        errors: []
    },
    gender: InputInterface,
    indigenous_status: InputInterface,
    language: InputInterface,
    birth_country: InputInterface,
    residency_status: InputInterface,
    carer_for_multiple: {
        uuid: string,
        value: boolean,
        required: boolean,
        errors: []
    },
    relationship: InputInterface,
}

export default class ClientCarer {
    static async list(clientUuid: string): Promise<CarerInterface[]> {
        let result: CarerInterface[] = [];

        try {
            let response: any = await axios.get(
                route("api.clients.carers.index", {client: clientUuid})
            );

            result = response.data.data;
        } catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static store(clientUuid: string, payload: CarerInputPayloadInterface) {
        return axios.post(route('api.clients.carers.store', {
            client: clientUuid
        }), payload);
    }

    static update(clientUuid: string, companionUuid: string, payload: CarerInputPayloadInterface) {
        return axios.patch(route('api.clients.carers.update', {
            client: clientUuid,
            carer: companionUuid,
        }), payload)
    }

    static delete(clientUuid: string, companionUuid: string) {
        return axios.delete(route('api.clients.carers.destroy', {
            client: clientUuid,
            carer: companionUuid,
        }));
    }
}
