<template>
    <div
        id="deactivateDriverConfirmationModal"
        class="modal fade"
        aria-hidden="true"
        aria-labelledby="deactivateDriverConfirmationModal"
        tabindex="-1"
    >
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="driverDeactivationModalLabel" class="modal-title">Deactivate Driver</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body text-start">
                    <p>Deactivating a driver will result in the driver being de-allocated from all future Vehicle Runs.</p>
                    <p>They will also be removed from any future Vehicle Runs where they have been included as an additional driver.</p>
                    <p>Are you sure you want to proceed?</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-white" data-bs-dismiss="modal" type="button">Close</button>
                    <Button @click="deactivateDriver" :loading="deactivatingDriver" color="danger">Deactivate Driver</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, inject, onMounted} from 'vue'
import Button from "../Button.vue";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    driver: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['deactivated'])

let deactivatingDriver = ref(false)

const deactivateDriver = () => {
    deactivatingDriver.value = true
    axios.delete(route('api.drivers.destroy', { driver: props.driver.uuid }))
        .then(() => {
            toast.success(`You deactivated ${props.driver.name}`)
            emit('deactivated', 'deleted')
        })
        .catch((error) => {
            toast.error('An error occurred while deactivating the driver.')
        }).finally(() => {
            deactivatingDriver.value = false
        })
}

</script>