import { uuid } from "vue-uuid";
import {
    VehicleConfigurationRowInterface,
    VehicleConfigurationSpaceType,
} from "@stores/VehicleConfigurationDesignStore";

const getUuid = () => {
    return uuid.v4();
};

export interface VehicleConfigurationTemplateInterface {
    id: number;
    name: string;
    rows: VehicleConfigurationRowInterface[];
}

const templates: VehicleConfigurationTemplateInterface[] = [
    {
        id: 1,
        name: "Sedan design",
        rows: [
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        name: "Standard Bus Design",
        rows: [
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        name: "Convertible Bus Design",
        rows: [
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        name: "Custom Bus Design",
        rows: [
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.TRACK],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.TRACK],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.TRACK],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.TRACK],
                        uuid: getUuid(),
                    },
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        name: "Van Design",
        rows: [
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.DRIVER],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                    {
                        type: [],
                        uuid: getUuid(),
                    },
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },

                    {
                        type: [],
                        uuid: getUuid(),
                    },

                    {
                        type: [VehicleConfigurationSpaceType.SEAT],
                        uuid: getUuid(),
                    },
                ],
            },
            {
                spaces: [
                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },

                    {
                        type: [],
                        uuid: getUuid(),
                    },

                    {
                        type: [
                            VehicleConfigurationSpaceType.SEAT,
                            VehicleConfigurationSpaceType.CARGO,
                        ],
                        uuid: getUuid(),
                    },
                ],
            },
        ],
    },
];

export default templates;
