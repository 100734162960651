<template>
    <div>
        <transition name="basic-fade" mode="out-in">
            <div v-if="editFormShowing" class="mb-3">
                <label for="client_booking_alert" class="form-label">Client Alert</label>
                <textarea v-model="alert" class="form-control" id="client_booking_alert" placeholder="e.g. Client has asked that we use their title 'Professor'."></textarea>
                <div class="form-text">The client alert will be displayed to the staff member completing a booking form.</div>
                <div class="mt-2 d-flex justify-content-between">
                    <div v-if="errors.alert">
                        {{ errors.alert }}
                    </div>
                    <div class="d-flex justify-content-end flex-grow-1">
                        <Button class="me-1" color="secondary" @click="editFormShowing = false" :disabled="saving">Cancel</Button>
                        <Button @click="saveAlert" :loading="saving">Save</Button>
                    </div>
                </div>
            </div>
            <template v-else>
                <div v-if="alert" class="alert alert-info d-flex align-items-center">
                    <SvgIcon
                        type="mdi"
                        :path="mdiInformation"
                        class="me-2 text-info"
                    ></SvgIcon>
                    <div><span class="click-to-edit" @click="editFormShowing = true">{{ alert }}</span></div>
                </div>
                <div v-else-if="editable">
                    <p class="text-end click-to-edit fst-italic" @click="editFormShowing = true">Click here to add a client alert</p>
                </div>
            </template>
        </transition>

    </div>
</template>

<script setup>
import {mdiInformation} from '@mdi/js'
import SvgIcon from "@jamescoyle/vue-icon";
import {computed, inject, ref} from "vue";
import Button from "../Button.vue";

const axios = inject('axios')

const props = defineProps({
    client: {
        type: Object,
        required: true
    },
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})

const editFormShowing = ref(false)

const alert = ref(props.client.alert)

const errors = ref({})
const emit = defineEmits(['updated'])
const saving = ref(false)
const saveAlert = () => {
    saving.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            alert: alert.value
        })
        .then(response => {
            emit('updated', response.data)
            editFormShowing.value = false
        })
        .catch(error => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            saving.value = false
        })
}

</script>

<style scoped>

</style>
