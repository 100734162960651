import { ApiResourceResponseInterface } from "@customTypes/LaravelCommonTypes";
import { DataTableInterface } from "@customTypes/DataTableTypes";
import axios from "axios";


export default class LocationRestriction {
    static async getAll(parameters: DataTableInterface): Promise<ApiResourceResponseInterface<App.Models.LocationRestriction>> {
        return await axios.get(route("settings.locations.location-restrictions.index", parameters));
    }

    static async store(values: LocationRestrictionChange): Promise<ApiResourceResponseInterface<App.Models.LocationRestriction>> {
        return await axios.post(route("settings.locations.location-restrictions.store"), values);
    }

    static async update(restriction: string, values: LocationRestrictionChange): Promise<ApiResourceResponseInterface<App.Models.LocationRestriction>> {
        return await axios.put(route("settings.locations.location-restrictions.update", {
            location_restriction: restriction
        }), values);
    }

    static async delete(restriction: string): Promise<ApiResourceResponseInterface<App.Models.LocationRestriction>> {
        return await axios.delete(route("settings.locations.location-restrictions.destroy", {
            location_restriction: restriction,
        }));
    }
}

export interface LocationRestrictionChange {
    location: string,
    type: string,
    vehicle: string,
    comment: string,
}