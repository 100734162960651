import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import PersistedStore from "./PersistedStore";
import OrganisationSetting from "@classes/OrganisationSetting";

const dataExpiryThresholdMinutes = 30;

export const useOrganisationSettingStore = defineStore(
    "organisationSettingStore",
    () => {
        const organisationSettings = ref<App.Models.OrganisationSetting[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.OrganisationSetting[]> => {
            return new Promise((resolve, reject) => {
                OrganisationSetting.index()
                    .then((response) => {
                        organisationSettings.value = response.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(organisationSettings.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                organisationSettings.value
            );
        };

        const organisationSettingCachedValue = (setting: string) => {
            return organisationSettings.value.find((i) => i.name === setting)
                ?.value;
        };

        return {
            organisationSettings,
            organisationSettingCachedValue,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "organisationSettingStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
