<template>
    <div>
        <div class="row">
            <div class="col">
                <h5 class="text-uppercase">Transactions</h5>
                View transactions
            </div>
            <div class="col d-flex align-items-end justify-content-end">
                <Button title="Add Transactions" @click="addTransactionModal.show()" class="mt-2">
                    <SvgIcon type="mdi" :path="mdiPlus" class="w-4 h-4 me-1"></SvgIcon>
                    Add Transactions
                </Button>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <PageLengthSelector record-name="transactions" store-name="TransactionStore"/>
            </div>
            <div class="col text-end">
                <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                    <button
                        class="btn btn-light btn-sm dropdown-toggle"
                        type="button"
                        id="dropDownFilterClients"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                    >
                        <SvgIcon type="mdi" :path="mdiFilter" class="w-4 h-4 me-1"></SvgIcon>
                        Filter
                    </button>
                    <div class="dropdown-menu px-4 py-3" style="min-width: 500px;"
                         aria-describedby="dropDownFilterBookings">
                        <div class="mb-2">
                            <label class="form-label text-sm" for="status_filter_select">Transaction type:</label>
                            <VueMultiselect
                                v-model="transactionStore.filters.types"
                                :options="transactionStore.defaultTypes"
                                track-by="value"
                                label="label"
                                placeholder="Select Statuses"
                                multiple
                            ></VueMultiselect>
                        </div>
                        <div class="mb-2">
                            <label class="form-label text-sm" for="status_filter_select">Payment method:</label>
                            <VueMultiselect
                                v-model="transactionStore.filters.paymentMethods"
                                :options="transactionStore.defaultPaymentMethods"
                                track-by="value"
                                label="label"
                                placeholder="Select Collection Methods"
                                multiple
                            ></VueMultiselect>
                        </div>
                        <div class="mb-2">
                            <label class="form-label text-sm" for="status_filter_select">Collection method:</label>
                            <VueMultiselect
                                v-model="transactionStore.filters.collectionMethods"
                                :options="transactionStore.defaultCollectionMethods"
                                track-by="value"
                                label="label"
                                placeholder="Select Collection Methods"
                                multiple
                            ></VueMultiselect>
                        </div>
                        <div class="mb-2 text-end">
                            <Button title="Apply" @click="transactionStore.getAllTransactions()" color="light">Apply</Button>
                        </div>
                    </div>
                </div>
                <DataTableSearch
                    @search="transactionStore.getAllTransactions()"
                    placeholder="Search transactions ..."
                    store="TransactionStore"
                />
            </div>
        </div>
        <template v-if="transactionStore.isLoading">
            <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
                <Loading spinner-class="spinner-grow" text="Loading transactions" text-position="after" direction="horizontal"/>
            </div>
        </template>
        <template v-else-if="!transactionStore.transactions || transactionStore.transactions?.length === 0">
            <div class="text-center mt-4 mb-3">
                <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiBankTransfer"></SvgIcon>
                <div class="fs-4 my-2">{{ client.preferred_name ?? client.given_names }} has no transactions.</div>
                <Button title="Add Transactions" @click="addTransactionModal.show()" class="mt-2">
                    <SvgIcon type="mdi" :path="mdiPlus" class="w-4 h-4 me-1"></SvgIcon>
                    Add Transactions
                </Button>
            </div>
        </template>
        <template v-else>
            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th class="td-shrink"><span class="visually-hidden">Type</span></th>
                    <SortableColumn param="uuid" store="TransactionStore">Reference</SortableColumn>
                    <SortableColumn param="created_at" store="TransactionStore">Date</SortableColumn>
                    <SortableColumn param="amount" store="TransactionStore">Amount</SortableColumn>
                    <th>Invoice</th>
                    <th>Payment Method</th>
                    <th>Collection Method</th>
                    <th>Receipt No.</th>
                    <th>Comments</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="transaction in transactionStore.transactions">
                    <tr>
                        <td class="td-shrink">
                            <TransactionType :type="transaction.type"/>
                        </td>
                        <td>{{ transaction.uuid }}</td>
                        <td>{{ DateTime.fromSeconds(transaction.created_at as Number).toLocaleString(DateTime.DATETIME_MED) }}</td>
                        <td>{{ formatCurrency(transaction.amount) }}</td>
                        <td class="text-capitalize"><a v-if="transaction.invoice"
                                                       :href="route('invoices.edit', {invoice: transaction.invoice?.uuid}, true)">{{ transaction.invoice?.uuid }}</a></td>
                        <td class="text-capitalize">
                            {{ transactionStore.availablePaymentMethods.find((item) => item.value === transaction.payment_method).label }}
                        </td>
                        <td class="text-capitalize">
                            {{ transactionStore.availableCollectionMethods.find((item) => item.value === transaction.collection_method).label }}
                        </td>
                        <td>{{ transaction.receipt_number }}</td>
                        <td class="text-center">
                            <SvgIcon type="mdi"
                                     :path="mdiMessageReplyText"
                                     size="18"
                                     v-popover
                                     data-bs-container="body"
                                     data-bs-trigger="focus"
                                     data-bs-html="true"
                                     :class="{'text-black-50':!transaction.comments}"
                                     :disabled="!transaction.comments"
                                     :data-bs-content="transaction.comments"
                                     data-bs-placement="left">
                                >
                            </SvgIcon>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <Pagination v-if="!transactionStore.isLoading" :pagination="paginatedMeta" record-name="transactions"></Pagination>
        </template>
    </div>
    <AddTransactionModal id="addTransactionModal" @confirmed="addTransaction()"></AddTransactionModal>
</template>

<script setup lang="ts">
import {formatCurrency} from "@classes/Helpers/Currency";
import {Loading} from "@components";
import Button from "@components/Button.vue";
import {ToastInterface} from "@components/toast";
import AddTransactionModal from "@components/Transactions/AddTransactionModal.vue";
import {ApiError} from "@customTypes/LaravelCommonTypes";
import ClientResource from "@customTypes/resources/ClientResource";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiBankTransfer, mdiFilter, mdiMessageReplyText, mdiPlus, mdiReceipt} from "@mdi/js";
import {useInvoiceStore} from "@stores/InvoiceStore";
import {usePaginationStore} from "@stores/PaginationStore";
import {useTransactionStore} from "@stores/TransactionStore";
import {Modal} from "bootstrap";
import {DateTime} from "luxon";
import {storeToRefs} from "pinia";
import {inject, onMounted, Ref, ref, watch} from "vue";
import VueMultiselect from "vue-multiselect";
import DataTableSearch from "../DataTable/DataTableSearch.vue";
import PageLengthSelector from "../DataTable/PageLengthSelector.vue";
import SortableColumn from "../DataTable/SortableColumn.vue";
import Pagination from "../Pagination.vue";
import TransactionType from "../TransactionType.vue"

const addTransactionModal = ref();

const toast: ToastInterface = inject('toast')!;

const props = defineProps<{ client: ClientResource }>()
const transactionStore = useTransactionStore();
const paginationStore = usePaginationStore('TransactionStore');
const invoiceStore = useInvoiceStore();

const {paginateParams, paginatedMeta, searchQuery} = storeToRefs(paginationStore);

const getTransactions = async function () {
    transactionStore.filters = {
        collectionMethods: transactionStore.defaultCollectionMethods,
        paymentMethods: transactionStore.defaultPaymentMethods,
        types: transactionStore.defaultTypes,
        client: props.client?.uuid
    };
    try {
        await transactionStore.getAllTransactions();
    } catch (error: unknown) {
        toast.error(`Error loading transactions.  ${(error as ApiError).response?.data?.message}`);
        console.error(error);
    }
}

const addTransaction = async () => {
    if (transactionStore.transaction) {
        await transactionStore.addTransaction(transactionStore.transaction);
        addTransactionModal.value.hide();
        await getTransactions();
    }
}

onMounted(function () {
    invoiceStore.filters.client = props.client.uuid;

    addTransactionModal.value = new Modal(<HTMLElement>document.getElementById('addTransactionModal'));

    getTransactions()
})

watch(
    paginationStore.paginateParams,
    () => {
        transactionStore.getAllTransactions();
    }
)
</script>

<style scoped>

</style>
