<template>
    <div :id="'stop-index-' + stopIndex">
        <div class="card bg-light shadow-none my-2">
            <div class="card-body">
                <div
                    v-if="
                        bookingStore.draft.stops.length >= 3 || stopIndex !== 1
                    "
                    class="position-absolute"
                    style="right: -0.9em; top: -0.9em"
                >
                    <div class="position-relative" style="">
                        <button
                            class="btn btn-link p-0 text-secondary"
                            @click="removeStopConfirmationModal?.show()"
                        >
                            <SvgIcon
                                v-if="!bookingStore.bookingEdit"
                                :path="mdiCloseCircle"
                                type="mdi"
                                class="w-8 h-8"
                            ></SvgIcon>
                            <span class="visually-hidden">Remove Stop</span>
                        </button>
                    </div>
                </div>

                <h4
                    class="header-title d-flex justify-content-between align-items-center mt-0 mb-3"
                >
                    <div>Destination {{ stopIndex }}</div>
                    <div class="fw-normal text-xs" style="text-transform: none">
                        Pick up from:
                        <strong>
                            <template
                                v-if="stop?.usePreviousStopLocationAsOrigin"
                            >
                                <template
                                    v-if="previousStop?.useClientHomeAsLocation"
                                    >Home</template
                                >
                                <template v-else-if="previousStop?.location">{{
                                    previousStop?.location?.full_address
                                }}</template>
                            </template>
                            <template v-else>{{
                                stop?.originLocation?.full_address
                            }}</template>
                        </strong>
                    </div>
                </h4>

                <BookingFormStopLocationSelect
                    :stopIndex="stopIndex"
                    label="Destination address"
                    placeholder="Search destination address"
                />
                <BookingStopTimingSelect :stopIndex="stopIndex" />
                <BookingStopPurposeTreatmentType
                    :stopIndex="stopIndex"
                    class="mb-3"
                />
                <BookingStopMap :stopIndex="stopIndex" />
                <BookingStopRequirements
                    class="mt-2 mb-2"
                    :stopIndex="stopIndex"
                />
                <BookingStopAdditionalDetails
                    class="my-2"
                    :stopIndex="stopIndex"
                />
                <BookingStopPaymentDetails
                    class="my-2"
                    :stopIndex="stopIndex"
                />
                <BookingStopFare class="my-2" :stopIndex="stopIndex" />
            </div>
        </div>

        <div v-if="!bookingStore.bookingEdit" class="d-flex align-items-center">
            <Button
                color="link"
                @click="bookingStore.addStop(stopIndex)"
                :icon="mdiPlusCircle"
                >Add stop</Button
            >
            <div v-if="stopIndex < bookingStore.draft.stops.length - 1">
                between
                <strong>{{ currentStopName }}</strong> and
                <strong>{{ nextStopName }}</strong>
            </div>
        </div>

        <div
            :id="removeStopConfirmationModalId"
            class="modal fade"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="header-title mt-2">Confirmation</h4>
                    </div>
                    <div class="modal-body">
                        <p>
                            Are you sure you want to remove this stop from the
                            draft booking?
                        </p>
                    </div>
                    <div class="modal-footer">
                        <Button
                            class="btn btn-secondary"
                            @click="removeStopConfirmationModal?.hide()"
                        >
                            Close
                        </Button>

                        <Button
                            class="btn btn-danger"
                            @click="() => onRemoveStopConfirmed()"
                        >
                            Remove Stop
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import { computed, onMounted, ref } from "vue";
import BookingFormStopLocationSelect from "./BookingStop/BookingFormStopLocationSelect.vue";
import BookingStopMap from "./BookingStop/BookingStopMap.vue";
import BookingStopRequirements from "./BookingStop/BookingStopRequirements.vue";
import BookingStopPaymentDetails from "./BookingStop/BookingStopPaymentDetails.vue";
import BookingStopAdditionalDetails from "./BookingStop/BookingStopAdditionalDetails.vue";
import { mdiPlusCircle, mdiCloseCircle } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import Button from "../../components/Button.vue";
import BookingStopTimingSelect from "./BookingStop/BookingStopTimingSelect.vue";
import BookingStopFare from "./BookingStop/BookingStopFare.vue";
import BookingStopPurposeTreatmentType from "./BookingStop/BookingStopPurposeTreatmentType.vue";
import { uuid } from "vue-uuid";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

const props = defineProps<{
    stopIndex: number;
}>();

const removeStopConfirmationModalId = ref(
    "BookingFormStopDeleteConfirmationModal_" +
        props.stopIndex +
        "_" +
        uuid.v1()
);

const removeStopConfirmationModal = ref<null | bootstrap.Modal>(null);

const bookingStore = useBookingStore();

const previousStop = computed(() => {
    return bookingStore.draft.stops?.[props?.stopIndex - 1];
});

const stop = computed(() => {
    return bookingStore.draft.stops[props?.stopIndex];
});

const nextStop = computed(() => {
    return bookingStore.draft.stops[props?.stopIndex + 1];
});

const currentStopName = computed(() => {
    if (stop.value.useClientHomeAsLocation) {
        return "client's home";
    }

    if (stop.value.location) {
        return stop.value.location.name ?? stop.value.location.address_line_1;
    }

    return "the above stop";
});

const nextStopName = computed(() => {
    if (nextStop.value?.useClientHomeAsLocation) {
        return "client's home";
    }

    if (nextStop.value?.location) {
        return (
            nextStop.value.location.name ??
            nextStop.value.location.address_line_1
        );
    }

    return "the below stop";
});

const onRemoveStopConfirmed = (): void => {
    removeStopConfirmationModal.value?.hide();

    bookingStore.removeStopByIndex(props.stopIndex);
};

const initBookingStopRemoveConfirmationModal = () => {
    const modalElement = document.getElementById(
        removeStopConfirmationModalId.value
    );
    if (!modalElement) {
        return;
    }
    removeStopConfirmationModal.value = new bootstrap.Modal(modalElement, {});
};

onMounted(() => {
    initBookingStopRemoveConfirmationModal();
});
</script>
