import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import Client, { ClientInterface } from "@classes/Client";
import { useMessageStore } from '@stores/MessageStore';
import { usePaginationStore } from "./PaginationStore";

export const useClientStore = defineStore('clientStore', () => {
    const { paginatedMeta, paginateParams, searchQuery } = storeToRefs(usePaginationStore());
    const { addToastMessage } = useMessageStore();

    const clients = ref<ClientInterface>();
    const loadingClients = ref<boolean>(false);

    const getClients = (statusFilter: object) => {
        loadingClients.value = true;

        let data = {
            paginate: true,
            perPage: paginateParams.value.perPage,
            page: paginateParams.value.page,
            column: paginateParams.value.sortBy,
            direction: paginateParams.value.sortDirection,
            statusFilter: statusFilter.value.map(sf => sf.value)
        }

        if (searchQuery.value?.trim()?.length > 0) {
            data.query = searchQuery.value?.trim()
            data.column = null
        }

        Client.index(data)
            .then((resp) => {
                clients.value = resp.data.data;
                paginatedMeta.value = resp.data.meta;
            })
            .catch((err) => {
                addToastMessage('error', 'Failed to get clients list');
                console.error(err);
            })
            .finally(() => {
                loadingClients.value = false;
            });
    };

    return {
        clients,
        loadingClients,
        getClients,
    }
})