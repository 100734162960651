<template>
    <label class="form-label">Driver breaks</label>
    <table class="table table-sm table-bordered">
        <thead>
            <tr>
                <th class="text-sm">Start time</th>
                <th class="text-sm">End time</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(driverBreak, index) in modelValue">
                <tr>
                    <td>
                        {{
                            DateTime.fromSeconds(driverBreak.start_time)
                                .toLocal()
                                .toLocaleString(DateTime.TIME_24_SIMPLE)
                        }}
                    </td>
                    <td>
                        {{
                            DateTime.fromSeconds(driverBreak.end_time)
                                .toLocal()
                                .toLocaleString(DateTime.TIME_24_SIMPLE)
                        }}
                    </td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-light btn-circle btn-circle-sm"
                            @click="removeBreak(index)"
                        >
                            <SvgIcon
                                type="mdi"
                                :path="mdiMinusThick"
                                class="position-relative text-dark w-4 h-4"
                                style="top: -1px"
                            />
                        </button>
                    </td>
                </tr>
            </template>
            <tr>
                <td class="col-5">
                    <FormTimeInput
                        :disabled="!runStartTime || !runEndTime"
                        label-class="visually-hidden"
                        v-model:input="newBreak.start_time"
                        :key="'new_break_start_' + newBreakKey"
                        placeholder="hh:mm"
                    />
                    <span class="form-text" v-if="!runStartTime || !runEndTime">
                        Set the run start and end times in order to create a
                        driver break
                    </span>
                </td>
                <td class="col-5">
                    <FormTimeInput
                        :disabled="!runStartTime || !runEndTime"
                        label-class="visually-hidden"
                        v-model:input="newBreak.end_time"
                        :key="'new_break_end_' + newBreakKey"
                        placeholder="hh:mm"
                    />
                </td>
                <td class="td-shrink">
                    <button
                        type="button"
                        class="btn btn-primary btn-circle btn-circle-sm"
                        @click="addBreak"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiPlusThick"
                            class="position-relative text-white w-4 h-4"
                            style="top: -1px"
                        />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script setup lang="ts">
import { ref, watch, toRefs } from "vue";
import { DateTime } from "luxon";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusThick, mdiMinusThick } from "@mdi/js";
import { FormTimeInput } from "../../Forms";

export interface DraftDriverBreakInterface {
    start_time: number;
    end_time: number;
}

const props = withDefaults(
    defineProps<{
        modelValue: DraftDriverBreakInterface[];
        runStartTime: DateTime | undefined;
        runEndTime: DateTime | undefined;
    }>(),
    {
        runStartTime: undefined,
        runEndTime: undefined,
    }
);

const { modelValue, runStartTime, runEndTime } = toRefs(props);

const emit = defineEmits<{
    (e: "update:modelValue", newValue: DraftDriverBreakInterface[]): void;
}>();

const newBreak = ref<{
    start_time: {
        value: any;
        errors: string[];
    };
    end_time: {
        value: any;
        errors: string[];
    };
}>({
    start_time: {
        value: null,
        errors: [],
    },
    end_time: {
        value: null,
        errors: [],
    },
});
const newBreakKey = ref(Math.random());

const addBreak = () => {
    newBreak.value.start_time.errors = [];
    newBreak.value.end_time.errors = [];

    if (!newBreak.value.start_time.value) {
        newBreak.value.start_time.errors.push("Please enter a start time");
        return;
    }

    if (!newBreak.value.end_time.value) {
        newBreak.value.end_time.errors.push("Please enter an end time");
        return;
    }

    if (!runStartTime.value || !runEndTime.value) {
        newBreak.value.start_time.errors.push(
            "Break start time must be during the run"
        );
        return;
    }

    const breakStartTime = DateTime.fromFormat(
        runStartTime.value?.toFormat("yyyy MM dd") +
            " " +
            newBreak.value.start_time.value,
        "yyyy MM dd HH:mm"
    );
    const breakEndTime = DateTime.fromFormat(
        runStartTime.value?.toFormat("yyyy MM dd") +
            " " +
            newBreak.value.end_time.value,
        "yyyy MM dd HH:mm"
    );

    if (breakEndTime.toSeconds() < breakStartTime.toSeconds()) {
        newBreak.value.end_time.errors.push(
            "End time must be after start time"
        );
        return;
    }

    if (
        breakStartTime.toSeconds() <= runStartTime.value.toSeconds() ||
        breakStartTime.toSeconds() >= runEndTime.value.toSeconds()
    ) {
        newBreak.value.start_time.errors.push(
            "Break start time must be during the run"
        );
        return;
    }

    if (
        breakEndTime.toSeconds() <= runStartTime.value.toSeconds() ||
        breakEndTime.toSeconds() >= runEndTime.value.toSeconds()
    ) {
        newBreak.value.end_time.errors.push(
            "Break start time must be during the run"
        );
        return;
    }

    if (modelValue.value.length > 0) {
        const lastBreak = modelValue.value[modelValue.value.length - 1];
        if (breakStartTime.toSeconds() < lastBreak.end_time) {
            newBreak.value.end_time.errors.push(
                "Break start time must be after the last break finishes"
            );
            return;
        }
    }

    let newModelValue = modelValue.value;

    newModelValue.push({
        start_time: breakStartTime.toSeconds(),
        end_time: breakEndTime.toSeconds(),
    });

    emit("update:modelValue", newModelValue);

    newBreak.value = {
        start_time: {
            value: null,
            errors: [],
        },
        end_time: {
            value: null,
            errors: [],
        },
    };
    newBreakKey.value = Math.random();
};

const removeBreak = (index: number) => {
    let newModelValue = modelValue.value;

    newModelValue.splice(index, 1);

    emit("update:modelValue", newModelValue);
};
</script>
