<template>
    <div>
        <EditableInput
            @updated="storeChanges"
            :value="client[field]"
            :loading="storing"
            :disabled="!editable"
        ></EditableInput>
        <div
            v-if="Object.keys(errors ?? {}).length > 0"
            class="form-text text-danger"
        >
            {{ errors[field][0] }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, toRefs } from "vue";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";
import axios from "axios";
import Client, { ClientInterface } from "@classes/Client";

const props = withDefaults(
    defineProps<{
        client: ClientInterface;
        readOnly?: boolean;
        field: string;
    }>(),
    {
        readOnly: false,
    }
);

const { client, field } = toRefs(props);

const editable = computed(() => {
    return !props.readOnly && !props.client?.deactivated_at;
});

const emit = defineEmits(["updated"]);

const errors = ref({});
const storing = ref(false);
const storeChanges = function (value: string) {
    storing.value = true;
    errors.value = {};
    Client.update(client.value, { [field.value]: value })
        .then((response) => {
            emit("updated", response.data);
        })
        .catch((error) => {
            errors.value = error.response.data.errors;
        })
        .finally(() => {
            storing.value = false;
        });
};
</script>

<style scoped></style>
