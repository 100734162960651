<template>
    <div>
        <h4 class="mt-0">Two-factor authentication</h4>
        <template v-if="!url">
            <p class="text-muted mb-3">You will need to enable two-factor authentication to use your account.</p>
            <p class="text-muted mb-3">When two factor authentication is enabled, you will be prompted for a secure,
                random token when you log in.</p>
            <button class="btn btn-primary" @click="generateQRCode">
                Enable two-factor authentication
            </button>
        </template>
        <template v-if="url && !recoveryCodes">
            <p class="text-muted mb-3">
                To set up two factor authentication please follow the steps below.
            </p>
            <p class="text-muted mb-3">
                1. Download an authenticator app to your mobile device (such as the "Google Authenticator" app).
            </p>
            <p class="text-muted mb-3">
                2. Scan the following QR code using the app you downloaded in step 1.
            </p>
            <QrCode :value="url" class="mb-3"></QrCode>
            <p class="text-muted mb-3">
                3. The app will generate a six digit authentication code, please enter it in the field below:
            </p>
            <div class="mb-3">
                <label for="twoFactorCode" class="form-label">Authentication code</label>
                <input
                    v-model="code"
                    type="text"
                    :class="{ 'form-control': true, 'is-invalid': error }"
                    placeholder="6-digit code"
                    name="code"
                    id="twoFactorCode"
                    maxlength="6"
                >
                <div v-if="error" class="invalid-feedback mb-3">{{ error }}</div>
            </div>
            <button :disabled="code.trim().length === 0" @click="enableTwoFactor" class="btn btn-primary">
                Continue
            </button>
        </template>
        <template v-if="recoveryCodes">
            <p class="text-muted mb-3">
                4. Please save the below recovery codes in a secure location such as a password manager application.
                    They can be used to access your account if you are unable to use your authenticator app.
            </p>
            <div>
                <div class="card bg-light shadow-none d-inline-block">
                    <div class="card-body">
                        <code>
                            <ul class="list-unstyled mb-0">
                                <li v-for="code in recoveryCodes" class="mb-1 text-secondary">{{ code }}</li>
                            </ul>
                        </code>
                    </div>
                </div>
            </div>
            <a :href="route('dispatch.index')" class="btn btn-primary">
                Continue
            </a>
        </template>
    </div>
</template>

<script>
import QrCode from "../QrCode.vue";

export default {
    name: "TwoFactorSetup",
    components: {
        QrCode,
    },
    data() {
        return {
            url: null,
            secret: '',
            code: '',
            error: null,
            recoveryCodes: null,
        }
    },
    methods: {
        generateQRCode() {
            this.$http
                .post(this.route('two-factor.generate-url'))
                .then((response) => {
                    this.url = response.data.url
                    this.secret = response.data.secret
                })
        },
        enableTwoFactor() {
            this.$http
                .post(this.route('two-factor.enable'), {
                    code: this.code,
                    secret: this.secret,
                })
                .then((response) => {
                    this.recoveryCodes = response.data.recovery_codes
                })
                .catch((error) => {
                    this.error = error.response.data.message
                })
        }
    }
}
</script>

<style scoped>

</style>
