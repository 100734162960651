<template>
    <div :key="formRefreshKey" class="col-md-8">
        <FormSection title="Basic Configuration" subtitle="Add the basic configuration for this billing rule, including when it should apply to bookings.">
            <div class="row mb-3">
                <div class="col-md-7">
                    <FormTextInput
                        v-model:input="rule.name"
                        label="Rule name"
                        placeholder="e.g. Standard Booking Fee"
                        :validation="yup.string().max(255).required('Please enter a name for this rule.')"
                    />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <label class="form-label required-label">Billing rule type</label>
                    <div class="d-flex">
                        <div class="form-check me-3">
                            <input
                                v-model="rule.type"
                                value="fixed"
                                class="form-check-input"
                                type="radio"
                                name="ruleType"
                                id="ruleTypeFixedRadio"
                                :disabled="mode === 'edit'"
                            >
                            <label class="form-check-label" for="ruleTypeFixedRadio">
                                Fixed amount
                            </label>
                        </div>
                        <div class="form-check me-3">
                            <input
                                v-model="rule.type"
                                value="distance"
                                class="form-check-input"
                                type="radio"
                                name="ruleType"
                                id="ruleTypeDistanceRadio"
                                :disabled="mode === 'edit'"
                            >
                            <label class="form-check-label" for="ruleTypeDistanceRadio">
                                Distance based
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <label for="fundingTypes" class="form-label optional-label">Funding types</label>
                    <VueMultiselect
                        v-model="rule.funding_types"
                        :options="fundingTypes"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Any funding type"
                        label="name"
                        track-by="uuid"
                        id="fundingTypes"
                    ></VueMultiselect>
                    <div class="form-text">This billing rule should apply for clients that have any of the selected funding types.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <label for="regions" class="form-label optional-label">Regions</label>
                    <VueMultiselect
                        v-model="rule.regions"
                        :options="regions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Any Region"
                        label="name"
                        track-by="uuid"
                        id="regions"
                    ></VueMultiselect>
                    <div class="form-text">This billing rule should only apply for clients assigned to the selected regions.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <label for="purposes" class="form-label optional-label">Purposes</label>
                    <VueMultiselect
                        v-model="rule.purposes"
                        :options="purposes"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Any Purpose"
                        label="name"
                        track-by="id"
                        id="purposes"
                    ></VueMultiselect>
                    <div class="form-text">This billing rule should apply for bookings with the selected purposes.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <label for="vehicle_types" class="form-label optional-label">Vehicle Types</label>
                    <VueMultiselect
                        v-model="rule.vehicle_types"
                        :options="vehicleTypes.map((type) => {
                            return {
                                id: type.code,
                                name: type.description,
                            }
                        })"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Select Vehicle Types"
                        label="name"
                        track-by="id"
                        id="vehicleTypes"
                    ></VueMultiselect>
                    <div class="form-text"><strong>Note:</strong> This billing rule will only be included in estimated costs if the booking has been allocated to a vehicle run with one of the selected types.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <div class="d-flex justify-content-between">
                        <label for="booking_tags" class="form-label optional-label">Booking Tags</label>
                        <div>
                            <div class="form-check form-check-inline">
                                <input v-model="rule.tags_white_list" class="form-check-input" type="radio" name="bookingTagWhiteList" id="bookingTagWhiteList-whiteList" value="whiteList">
                                <label class="form-check-label" for="bookingTagWhiteList-whiteList">Must have</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input v-model="rule.tags_white_list" class="form-check-input" type="radio" name="bookingTagWhiteList" id="bookingTagWhiteList-blackList" value="blackList">
                                <label class="form-check-label" for="bookingTagWhiteList-blackList">Must not have</label>
                            </div>
                        </div>
                    </div>
                    <VueMultiselect
                        v-model="rule.tags"
                        :options="tags.map((tag) => {
                            return {
                                id: tag.uuid,
                                name: tag.description,
                            }
                        })"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Select Booking Tags"
                        label="name"
                        track-by="id"
                        id="bookingTags"
                    ></VueMultiselect>
                    <div v-if="rule.tags_white_list === 'whiteList'" class="form-text">This billing rule will apply only if the booking has the required tags.</div>
                    <div v-else class="form-text">This billing rule will not apply if the booking has one of the selected tags.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-5">
                    <FormDateInput
                        optional
                        v-model:input="rule.valid_from"
                        label="Valid from"
                        :validation="yup.date().optional().typeError('There seems to be an issue with this date, please check to make sure it is correct.')"
                    />
                    <div class="form-text">This billing rule will only be applied to bookings created after this date.</div>
                </div>
                <div class="col-md-5">
                    <FormDateInput
                        optional
                        v-model:input="rule.valid_until"
                        label="Valid until"
                        :validation="yup.date().optional().typeError('There seems to be an issue with this date, please check to make sure it is correct.')"
                    />
                    <div class="form-text">This billing rule will only be applied to bookings created before this date.</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <label class="form-label required-label">Apply billing rule to</label>
                    <div class="d-flex">
                        <div class="form-check me-3">
                            <input
                                v-model="rule.applies_to.value"
                                value="individual"
                                class="form-check-input"
                                type="checkbox"
                                name="appliesTo[]"
                                id="feeApplyToIndividualsCheckbox"
                                :disabled="!rule.applies_to.value.includes('group')"
                            >
                            <label class="form-check-label" for="feeApplyToIndividualsCheckbox">
                                Individual bookings
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                v-model="rule.applies_to.value"
                                value="group"
                                class="form-check-input"
                                type="checkbox"
                                name="appliesTo[]"
                                id="feeApplyToGroupsCheckbox"
                                :disabled="!rule.applies_to.value.includes('individual')"
                            >
                            <label class="form-check-label" for="feeApplyToGroupsCheckbox">
                                Group bookings
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="form-check">
                    <input v-model="rule.gst_applies" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Charge Goods and Services Tax (GST) on this billing rule
                    </label>
                </div>
                <div class="form-text">Charging GST will add 10% to the total cost of this billing rule.</div>
            </div>

        </FormSection>
        <transition name="fade" mode="out-in">
            <FormSection v-if="rule.type === 'fixed'" title="Fixed Fee" subtitle="Set the fixed amount that should be charged for this billing rule.">
                <div class="row">
                    <div class="col-md-6">
                        <FormDollarInput
                            v-model:input="rule.fixed_fee"
                            label="Fixed fee amount"
                            placeholder="e.g. 5.50"
                            step=".01"
                            :validation="
                                yup
                                    .number()
                                    .min(0, 'The fee amount must be positive.')
                                    .required('Please enter a number amount for this fee.')
                                    .typeError('Please enter a number amount for this fee.')
                            "
                        />
                    </div>
                </div>
            </FormSection>
            <FormSection v-else-if="rule.type === 'distance'" title="Distance Based Fees" subtitle="Configure how the fee will be determined depending on the distance travelled during the booking.">
                <div>
                    <label class="form-label required-label">How to apply fee</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card shadow-none border">
                                <div class="card-body p-3">
                                    <div class="form-check mb-2">
                                        <input
                                            v-model="rule.distance_fee_type"
                                            value="fixed"
                                            class="form-check-input"
                                            type="radio"
                                            name="distanceFeeType"
                                            id="distanceTypeFixedRadio"
                                        >
                                        <label class="form-check-label" for="distanceTypeFixedRadio">
                                            Set fee per distance range
                                        </label>
                                    </div>
                                    <p class="card-text">
                                        A set fixed fee will be applied whenever the booking is within a particular distance range.
                                    </p>
                                    <p class="card-text fst-italic">
                                        e.g. $14 when the booking distance is between 0km and 5km
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card shadow-none border">
                                <div class="card-body p-3">
                                    <div class="form-check mb-2">
                                        <input
                                            v-model="rule.distance_fee_type"
                                            value="per_km"
                                            class="form-check-input"
                                            type="radio"
                                            name="distanceFeeType"
                                            id="distanceTypePerKmRadio"
                                        >
                                        <label class="form-check-label" for="distanceTypePerKmRadio">
                                            Fee per km within range
                                        </label>
                                    </div>
                                    <p class="card-text">
                                        The fee will be charged for each km completed within the particular distance range.
                                    </p>
                                    <p class="card-text fst-italic">
                                        e.g. When the booking distance is between 5km and 10km, the fee will be $1 per km
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label required-label">Use estimated or actual distance</label>
                    <p class="form-text">When calculating using <strong>Actual distance</strong>, the fee will be recalculated after the booking has been completed.</p>
                    <div>
                        <div class="form-check form-check-inline">
                            <input v-model="rule.distance_estimated" class="form-check-input" type="radio" name="estimated_or_actual" id="useEstimatedDistanceRadio" value="estimated">
                            <label class="form-check-label" for="useEstimatedDistanceRadio">Estimated</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="rule.distance_estimated" class="form-check-input" type="radio" name="estimated_or_actual" id="useActualDistanceRadio" value="actual">
                            <label class="form-check-label" for="useActualDistanceRadio">Actual</label>
                        </div>
                    </div>
                </div>
                <label class="form-label required-label">Fees</label>
                <transition-group name="slow-fade">
                    <template
                        v-for="(rangeFee, index) in rule.distance_fees"
                        :key="rangeFee.id">
                        <div class="d-flex align-items-center justify-content-between mt-1 w-full">
                            <div class="flex-grow-1">
                                <DistanceRangeFeeForm
                                    :per-km="rule.distance_fee_type === 'per_km'"
                                    v-model="rule.distance_fees[index]"
                                ></DistanceRangeFeeForm>
                            </div>
                            <div class="flex-shrink-1 ps-1">
                                <button
                                    v-if="rule.distance_fees.length > 1"
                                    aria-label="Close"
                                    class="btn-close"
                                    type="button"
                                    @click="rule.distance_fees.splice(index, 1)"
                                ></button>
                            </div>
                        </div>
                    </template>
                </transition-group>
                <div class="d-flex align-items-center justify-content-start mt-2">
                    <Button :icon="mdiPlusCircle" color="link" size="small" @click="addDistanceFee">
                        Add Fee
                    </Button>
                </div>
            </FormSection>
        </transition>
        <FormSection title="Linked bookings" subtitle="Configure the way the fees are calculated when a client makes multiple linked bookings (e.g. return bookings)">
            <div class="mb-3">
                <label class="form-label required-label">Linked bookings</label>
                <div class="form-check mb-2">
                    <input v-model="rule.use_total_booking_sequence_distance" class="form-check-input" type="radio" name="use_total_booking_sequence_distance" id="allLinkedBookingsRadio" value="all">
                    <label class="form-check-label" for="allLinkedBookingsRadio">All linked bookings</label>
                    <div class="form-text">When a client makes multiple linked bookings (e.g. return bookings), then the total distance should be used to calculate the fee.</div>
                </div>
                <div class="form-check">
                    <input v-model="rule.use_total_booking_sequence_distance" class="form-check-input" type="radio" name="use_total_booking_sequence_distance" id="individualBookingsRadio" value="individual">
                    <label class="form-check-label" for="individualBookingsRadio">Each booking</label>
                    <div class="form-text">Each booking is treated independently, and a separate fee is calculated using the distance of each leg of the booking.</div>
                </div>
            </div>
        </FormSection>
        <FormSection title="Companion Fees" subtitle="Configure the fee charged when a carer or companion accompanies the primary client">
            <div class="row mb-3">
                <div class="col-md-6">
                    <FormDollarInput
                        v-model:input="rule.companion_fixed_fee"
                        optional
                        label="Fixed fee amount"
                        placeholder="e.g. 5.50"
                        step=".01"
                        :validation="
                                yup
                                    .number()
                                    .min(0, 'The fee amount must be positive.')
                                    .required('Please enter a number amount for this fee.')
                                    .typeError('Please enter a number amount for this fee.')
                            "
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <FormPercentageInput
                        v-model:input="rule.companion_percentage_fee"
                        optional
                        label="Percentage fee amount"
                        placeholder="e.g. 50"
                        step=".01"
                        :validation="
                                yup
                                    .number()
                                    .min(0, 'The fee percentage amount must be positive.')
                                    .required('Please enter a number amount for this fee.')
                                    .typeError('Please enter a number amount for this fee.')
                            "
                    />
                    <div class="form-text">This fee will be calculated as a percentage of the fee that would otherwise be charged, and takes into account any other billing rules.</div>
                </div>
            </div>
        </FormSection>
        <FormSection title="Postcode Settings" :subtitle="postcodeSettingsSubtitle">
            <div class="mb-3">
                <div class="form-check form-check-inline">
                    <input v-model="rule.postcode_white_list" class="form-check-input" type="radio" name="postcodeWhiteList" id="postcode-whiteList" value="whiteList">
                    <label class="form-check-label" for="postcode-whiteList">Must have</label>
                </div>
                <div class="form-check form-check-inline">
                    <input v-model="rule.postcode_white_list" class="form-check-input" type="radio" name="postcodeWhiteList" id="postcode-blackList" value="blackList">
                    <label class="form-check-label" for="postcode-blackList">Must not have</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <SearchSuburb label="Origin Postcodes" @select="addOriginPostcode" />
                    <div class="d-flex mt-1">
                        <template v-for="suburb in rule.origin_postcodes">
                            <div class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1" style="font-size: 0.9em">
                                <div>{{ suburb.name }} {{ suburb.postcode }}</div>
                                <button
                                    type="button"
                                    class="btn-close btn-close-white ms-1"
                                    aria-label="Close"
                                    @click="removeOriginPostcode(suburb)"></button>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="postcodesOperator" class="form-label invisible">Operator</label>
                    <select v-model="rule.postcode_operator" id="postcodesOperator" class="form-select">
                        <option value="and">AND</option>
                        <option value="or">OR</option>
                    </select>
                </div>
                <div class="col-md-5">
                    <SearchSuburb label="Destination Postcodes" @select="addDestinationPostcode" />
                    <div class="d-flex mt-1">
                        <template v-for="suburb in rule.destination_postcodes">
                            <div class="badge rounded-pill bg-secondary m-1 d-flex align-items-center lh-1" style="font-size: 0.9em">
                                <div>{{ suburb.name }} {{ suburb.postcode }}</div>
                                <button
                                    type="button"
                                    class="btn-close btn-close-white ms-1"
                                    aria-label="Close"
                                    @click="removeDestinationPostcode(suburb)"></button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </FormSection>
        <FormSection title="Credit Payment">
            <template v-slot:subtitle>
                <p>Specify any credit transactions that should be applied to the client's account when this billing rule is applied.</p>
            </template>
            <div>
                <div class="d-flex">
                    <div class="form-check">
                        <input v-model="rule.credit_type" class="form-check-input" type="radio" name="credit_type" id="credit_type_percentage" value="percentage">
                        <label class="form-check-label" for="credit_type_percentage">
                            Percentage of total fare
                        </label>
                    </div>
                    <div class="form-check ms-3">
                        <input v-model="rule.credit_type" class="form-check-input" type="radio" name="credit_type" id="credit_type_fixed" value="fixed">
                        <label class="form-check-label" for="credit_type_fixed">
                            Fixed amount per booking
                        </label>
                    </div>
                </div>
                <div class="mt-3 row">
                    <div class="col-md-4">
                        <div class="input-group input-group-sm">
                            <transition name="basic-fade" mode="out-in">
                                <span class="input-group-text" v-if="rule.credit_type === 'fixed'">$</span>
                            </transition>
                            <input
                                type="text"
                                v-model="rule.credit_amount"
                                id="credit_amount"
                                :placeholder="rule.credit_type === 'fixed' ? 'e.g. 55.00' : 'e.g. 60'"
                                :class="{ 'form-control': true, 'is-invalid': errors.credit_amount }"
                                aria-label="Amount"
                            >
                            <transition name="basic-fade" mode="out-in">
                                <span class="input-group-text" v-if="rule.credit_type === 'fixed'">AUD</span>
                                <span class="input-group-text" v-else>%</span>
                            </transition>
                        </div>
                        <p class="invalid-feedback d-block" v-if="errors.credit_amount">{{ errors.credit_amount[0] }}</p>
                    </div>
                </div>
            </div>
        </FormSection>
        <FormSection title="Co-payment Settings">
            <template v-slot:subtitle>
                <p>Set the portion of the fee that will be paid by a third-party.</p>
                <p><strong>Note: </strong>This amount will be visible by staff members when making a booking.</p>
            </template>
            <transition-group name="slow-fade">
                <template v-for="(copayment, index) in rule.copayments" :key="copayment.id">
                    <div class="d-flex align-items-end mb-3">
                        <div class="flex-grow-1">
                            <CopaymentAmount
                                v-model="rule.copayments[index]"
                                :key="copayment.id"
                                :funding-types="fundingTypes"
                                :errors="copaymentErrors[index]"
                            />
                        </div>
                        <div class="flex-shrink-1 ps-1 mb-1">
                            <button
                                v-if="rule.copayments.length > 1"
                                aria-label="Close"
                                class="btn-close"
                                type="button"
                                @click="rule.copayments.splice(index, 1)"
                            ></button>
                        </div>
                    </div>
                </template>
            </transition-group>
            <div class="d-flex align-items-center justify-content-start mt-2">
                <Button :icon="mdiPlusCircle" color="link" size="small" @click="addCopayment">
                    Add Copayment
                </Button>
            </div>
        </FormSection>
        <FormSection title="Subsidies">
            <template v-slot:subtitle>
                <p>Set the subsidy amounts that are paid to your organisation each time a booking is completed using this billing rule.</p>
                <p><strong>Note: </strong>This amount will not be visible by staff members when making a booking.</p>
            </template>
            <transition-group name="slow-fade">
                <template v-for="(subsidy, index) in rule.subsidies" :key="subsidy.id">
                    <div class="d-flex align-items-end mb-3">
                        <div class="flex-grow-1">
                            <Subsidy v-model="rule.subsidies[index]" :key="subsidy.id" :funding-types="fundingTypes" />
                        </div>
                        <div class="flex-shrink-1 ps-1 mb-1">
                            <button
                                v-if="rule.subsidies.length > 1"
                                aria-label="Close"
                                class="btn-close"
                                type="button"
                                @click="rule.subsidies.splice(index, 1)"
                            ></button>
                        </div>
                    </div>
                </template>
            </transition-group>
            <div class="d-flex align-items-center justify-content-start mt-2">
                <Button :icon="mdiPlusCircle" color="link" size="small" @click="addSubsidy">
                    Add Subsidy
                </Button>
            </div>
        </FormSection>
        <div class="row">
            <div class="col-md-8 offset-md-4">
                <Button color="primary" :class-array="['float-end']" @click="store" :loading="storing">
                    {{ props.mode === 'create' ? 'Create' : 'Update' }} Billing Rule
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateFundingRule"
}
</script>
<script setup>
import { FormSection } from '../Forms/Layouts'
import { FormTextInput, FormDateInput, FormDollarInput, FormPercentageInput } from '../Forms'
import {copyValues, transformInput, toTimeStamp} from '../Utils.js'
import Button from '../Button.vue'
import * as yup from 'yup'
import {onMounted, reactive, ref, inject, computed} from "vue";
import VueMultiselect from "vue-multiselect";
import DistanceRangeFeeForm from "../Clients/DistanceRangeFeeForm.vue";
import {uuid} from "vue-uuid";
import {mdiPlusCircle} from "@mdi/js";
import SearchSuburb from "../SearchSuburb.vue";
import CopaymentAmount from "./Components/CopaymentAmount.vue";
import Subsidy from "./Components/Subsidy.vue";
import { BookingTag } from '@classes/BookingTag';
import { useRegionStore } from "@stores/RegionStore";
import { usePersistedBookingTagStore } from "@stores/PersistedBookingTagStore";
const axios = inject('axios')
const toast = inject('toast')

const regionStore = useRegionStore();
const persistedBookingTagStore = usePersistedBookingTagStore();


const props = defineProps({
    fundingRule: {
        type: Object,
        required: false,
    },
    mode: {
        type: String,
        default: 'create',
    },
})

const rule = reactive({
    uuid: null,
    name: transformInput({
        value: '',
        required: true,
        errors: [],
    }),
    type: 'fixed',
    valid_from: transformInput({
        value: '',
        required: false,
    }),
    valid_until: transformInput({
        value: '',
        required: false,
    }),
    funding_types: [],
    regions: [],
    purposes: [],
    vehicle_types: [],
    tags: [],
    tags_white_list: 'whiteList',
    applies_to: transformInput({
        value: ['individual', 'group'],
        errors: [],
    }),
    gst_applies: false,
    distance_fees: [
        {
            id: uuid.v4(),
            uuid: null,
            min: 0,
            max: null,
            amount: 0.00,
            feeRounding: false,
        }
    ],
    fixed_fee: transformInput({
        value: null,
        required: true,
    }),
    distance_fee_type: 'fixed',
    distance_estimated: 'estimated',
    companion_fixed_fee: transformInput({
        value: null,
        required: false,
    }),
    companion_percentage_fee: transformInput({
        value: null,
        required: false,
    }),
    use_total_booking_sequence_distance: 'individual',
    origin_postcodes: [],
    destination_postcodes: [],
    postcode_white_list: 'whiteList',
    postcode_operator: 'and',
    credit_type: 'percentage',
    credit_amount: null,
    copayments: [
        {
            id: uuid.v4(),
            uuid: null,
            type: 'amount',
            amount: null,
            payer: '',
        }
    ],
    subsidies: [
        {
            id: uuid.v4(),
            uuid: null,
            amount: null,
            payer: '',
        }
    ],
})

const postcodeSettingsSubtitle = computed(() => {
    if (rule.postcode_white_list === 'whiteList') {
        return 'This billing rule will apply to bookings that begin in ' + rule.postcode_operator + ' end in one the selected postcodes'
    } else {
        return 'This billing rule will not apply to a booking if it begins in ' + rule.postcode_operator + ' ends in one the selected postcodes'
    }
})

const errors = ref({})
const copaymentErrors = computed(() => {
    return Object.keys(errors.value).filter(key => key.includes('copayments')).reduce((obj, key) => {
        let parts = key.split('.')
        if (!obj[parts[1]]) {
            obj[parts[1]] = {}
        }
        obj[parts[1]][parts[2]] = errors.value[key]
        return obj
    }, {})
})

const storing = ref(false)
const store = () => {
    storing.value = true

    let postData = copyValues(rule)

    postData.funding_types = postData.funding_types.map(fundingType => fundingType.uuid)
    postData.regions = postData.regions.map(region => region.uuid)
    postData.purposes = postData.purposes.map(purpose => purpose.id)
    postData.vehicle_types = postData.vehicle_types.map(vehicleType => vehicleType.id)
    postData.tags = postData.tags.map(tag => tag.id)
    postData.tags_white_list = postData.tags_white_list === 'whiteList'

    postData.valid_from = postData.valid_from.value ? toTimeStamp(postData.valid_from.value) : null
    postData.valid_until = postData.valid_until.value ? toTimeStamp(postData.valid_until.value) : null

    postData.applies_to = postData.applies_to.value

    postData.distance_fees = postData.distance_fees.map(fee => {
        fee.uuid = fee.uuid ? fee.uuid : null
        //JS is weird, check https://stackoverflow.com/questions/21472828/javascript-multiplying-by-100-giving-weird-result
        fee.amount = +(fee.amount * 100).toFixed(2);
        fee.min = fee.min ? parseInt(fee.min * 1000) : 0
        fee.max = fee.max ? parseInt(fee.max * 1000) : null
        return fee
    })

    postData.copayments = postData.copayments
        .map(copayment => {
            copayment.uuid = copayment.uuid ? copayment.uuid : null
            copayment.amount = copayment.type === 'amount' ? parseInt(copayment.amount * 100) : copayment.amount
            return copayment
        })
        .filter(copayment => copayment.amount > 0)

    postData.subsidies = postData.subsidies
        .map(subsidy => {
            subsidy.uuid = subsidy.uuid ? subsidy.uuid : null
            subsidy.amount = parseInt(subsidy.amount * 100)
            return subsidy
        })
        .filter(copayment => copayment.amount > 0)

    postData.name = postData.name.value
    postData.fixed_fee = parseInt(postData.fixed_fee.value * 100)
    postData.companion_fixed_fee = parseInt(postData.companion_fixed_fee.value * 100)
    postData.companion_percentage_fee = postData.companion_percentage_fee.value

    postData.origin_postcodes = postData.origin_postcodes.map(suburb => suburb.uuid)
    postData.destination_postcodes = postData.destination_postcodes.map(suburb => suburb.uuid)
    postData.postcode_white_list = postData.postcode_white_list === 'whiteList'

    postData.use_total_booking_sequence_distance = postData.use_total_booking_sequence_distance !== 'individual'

    const request = props.mode === 'create'
        ? axios.post(route('api.funding-rules.store'), postData)
        : axios.put(route('api.funding-rules.update', props.fundingRule.uuid), postData)

    request.then(() => {
            const title = props.mode === 'create' ? 'Created' : 'Updated'
            toast.success(
                `The funding rule was successfully ${title.toLowerCase()}, you will be redirected to the billing settings.`,
                { title: `Funding Rule ${title}` }
            )
            setTimeout(() => window.location = route('funding-rules.index'), 5000)
        })
        .catch((error) => {
            if (error.response && error.response.status === 422) {
                toast.error(error.response.data.message)
            }
            errors.value = error.response.data.errors
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (!rule[errorKey]) {
                    return
                }
                rule[errorKey].errors = error.response.data.errors[errorKey]
            })

            storing.value = false
        })
}

const addCopayment = () => {
    rule.copayments.push({
        id: uuid.v4(),
        type: 'amount',
        amount: null,
        payer: '',
    })
}

const addSubsidy = () => {
    rule.subsidies.push({
        id: uuid.v4(),
        amount: null,
        payer: '',
    })
}

const addDistanceFee = () => {
    rule.distance_fees.push({
        id: uuid.v4(),
        min: rule.distance_fees[rule.distance_fees.length - 1]?.max ?? 0,
        max: null,
        amount: 0.00,
        feeRounding: false,
    })
}

const addOriginPostcode = (suburb) => {
    if (suburb === {} || rule.origin_postcodes.find((s) => s.uuid === suburb.uuid)) {
        return;
    }

    rule.origin_postcodes.push(suburb)
}

const removeOriginPostcode = (suburb) => {
    rule.origin_postcodes = rule.origin_postcodes.filter((s) => s.uuid !== suburb.uuid)
}

const addDestinationPostcode = (suburb) => {
    if (suburb === {} || rule.destination_postcodes.find((s) => s.uuid === suburb.uuid)) {
        return;
    }

    rule.destination_postcodes.push(suburb)
}

const removeDestinationPostcode = (suburb) => {
    rule.destination_postcodes = rule.destination_postcodes.filter((s) => s.uuid !== suburb.uuid)
}

const regions = ref([])
const getRegions = async () => {
    regions.value = await regionStore.sync();
}

const purposes = ref([])
const getPurposes = function () {
    axios
        .get(route('api.booking-purposes.index'))
        .then((response) => {
            purposes.value = Object.keys(response.data).map((key) => { return { id: key, name: response.data[key] }})
        })
}

const fundingTypes = ref([])
const getFundingTypes = function () {
    axios
        .get(route('api.funding-types.index'))
        .then((response) => {
            fundingTypes.value = response.data
        })
}

const vehicleTypes = ref([])
const getVehicleTypes = function () {
    axios
        .get(route('api.vehicle-types.index'))
        .then((response) => {
            vehicleTypes.value = response.data.data
        })
}

const tags = ref([])
const getBookingTags = async () => {
    tags.value = await persistedBookingTagStore.sync();
}

const formRefreshKey = ref(Date.now())
const populateForm = () => {
    if (props.mode === 'edit') {
        rule.uuid = props.fundingRule.uuid
        rule.name.value = props.fundingRule.name
        rule.type = props.fundingRule.type
        rule.valid_from.value = props.fundingRule.valid_from
        rule.valid_until.value = props.fundingRule.valid_until
        rule.applies_to.value = props.fundingRule.applies_to
        rule.funding_types = props.fundingRule.funding_types
        rule.regions = props.fundingRule.regions
        rule.gst_applies = props.fundingRule.gst_applies ? true : false
        rule.fixed_fee.value = props.fundingRule.fixed_fee ?? 0
        rule.companion_fixed_fee.value = props.fundingRule.companion_fixed_fee
        rule.companion_percentage_fee.value = props.fundingRule.companion_percentage_fee
        rule.purposes = props.fundingRule.purposes
        rule.distance_estimated = props.fundingRule.distance_estimated ? 'estimated' : 'actual'
        rule.distance_fee_type = props.fundingRule.distance_fee_type
        rule.credit_amount = props.fundingRule.credit_type == 'percentage' ? props.fundingRule.credit_amount : props.fundingRule.credit_amount * 100;
        rule.credit_type = props.fundingRule.credit_type
        rule.use_total_booking_sequence_distance = props.fundingRule.use_total_booking_sequence_distance ? 'all' : 'individual'
        rule.vehicle_types = props.fundingRule.vehicle_types.map((type) => {
            return {
                id: type.code,
                name: type.description,
            }
        })
        rule.tags = props.fundingRule.booking_tags.map((tag) => {
            return {
                id: tag.uuid,
                name: tag.description,
            }
        })
        rule.tags_white_list = props.fundingRule.tags_white_list
        rule.copayments = props.fundingRule.copayments.map((copayment) => {
            return {
                id: uuid.v4(),
                uuid: copayment.uuid,
                type: copayment.type,
                amount: copayment.amount,
                percentage: copayment.percentage,
                payer: copayment.payer.uuid,
            }
        })
        rule.distance_fees = props.fundingRule.fees.map((fee) => {
            return {
                id: uuid.v4(),
                uuid: fee.uuid,
                min: fee.min_distance_meters,
                max: fee.max_distance_meters !== 0 ? fee.max_distance_meters : null,
                amount: fee.amount,
                feeRounding: false,
            }
        })
        rule.subsidies = props.fundingRule.subsidies.map((subsidy) => {
            return {
                id: uuid.v4(),
                uuid: subsidy.uuid,
                amount: subsidy.amount,
                payer: subsidy.payer.uuid,
            }
        })
        rule.origin_postcodes = props.fundingRule.origin_postcodes
        rule.destination_postcodes = props.fundingRule.destination_postcodes
        rule.postcode_white_list = props.fundingRule.postcode_white_list ?? 'whiteList'
        rule.postcode_operator = props.fundingRule.postcode_operator ?? 'and'

        if (!rule.copayments.length) {
            addCopayment()
        }

        if (!rule.subsidies.length) {
            addSubsidy()
        }

        formRefreshKey.value = Date.now()
    }
}

onMounted(() => {
    populateForm()
    getFundingTypes()
    getPurposes()
    getVehicleTypes()
    getBookingTags()
    getRegions()
})

</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
}
</style>
