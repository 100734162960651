<template>
    <div>
        <label for="clientSearchInput" class="form-label">Who is this booking for?</label>
        <div class="input-group">
            <VueMultiselect
                v-model="selectedClient"
                placeholder="Search client name or phone number"
                label="name"
                track-by="uuid"
                selectLabel=""
                deselectLabel=""
                :options="clients"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                @search-change="searchClients"
                @select="selectClient"
            >
                <template v-slot:option="{option, search}">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <Markdown :content="$filters.highlight(option.name ?? '', search)"/>
                        </div>
                        <small>
                            <Markdown :content="$filters.highlight(option.uuid ?? '', search)"/>
                        </small>
                    </div>
                    <div class="d-flex justify-content-between">
                        <small v-if="option.address">
                            <Markdown :content="$filters.highlight(option.address?.formatted_address ?? '', search)"/>
                        </small>
                        <small v-if="option.phone">
                            <Markdown :content="$filters.highlight($filters.phone(option.phone ?? ''), search)"/>
                        </small>
                    </div>
                </template>
            </VueMultiselect>
        </div>
    </div>
</template>

<script setup lang="ts">
import Markdown from "../Markdown.vue";
import {ref} from "vue";
import axios from "axios";
import ClientResource from "@customTypes/resources/ClientResource";
import VueMultiselect from 'vue-multiselect';
import {useDebounceFn} from "@vueuse/core";

const clients = ref<ClientResource[]>([]);
const selectedClient = ref<ClientResource>();
const isLoading = ref<boolean>(false);

const emit = defineEmits(["clientSelected"])

const selectClient = (selected: string) => {
    axios.get(route('api.clients.show', selected))
        .then((response) => {
            emit('clientSelected', response.data.data)
            clients.value = []
        })
};

const searchClients = useDebounceFn((query: string) => {
    isLoading.value = true;
    axios.get(route('api.clients.index'), {params: {query: query, paginate:true}})
        .then((response) => {
            clients.value = response.data.data
        })
        .finally(() => {
            isLoading.value = false;
        })
}, 500);

</script>

<style scoped>

</style>
