<template>
    <div :id="componentId" class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="{ group: 'Group Booking', individual: 'Individual Booking' }"
            :value="booking.group_booking_identifier ? 'group' : 'individual'"
            @updated="update"
        >
            <template v-if="booking.group_booking_identifier">
                <SvgIcon
                    :path="mdiAccountGroupOutline"
                    class="w-4 h-4 me-1"
                    type="mdi"
                />
                Group
            </template>
            <template v-else>
                <SvgIcon
                    :path="mdiAccountOutline"
                    class="w-4 h-4 me-1"
                    type="mdi"
                />
                Individual
            </template>
        </EditableSelect>
    </div>
</template>

<script>
export default {
    name: "EditBookingPurpose"
}
</script>
<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {ref, inject, onMounted} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiAccountGroupOutline, mdiAccountOutline} from '@mdi/js';
import axios from "axios";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();
import {uuid} from "vue-uuid";

const componentId = ref(uuid.v4());

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
    purposes: {
        type: Array,
        required: false,
        default: null,
    },
})

const updating = ref(false)
const update = function (bookingType) {
    updating.value = true
    axios
        .put(route('api.bookings.update', {booking: props.booking.uuid}), {
            group_booking: bookingType === 'group',
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            toast.success('Booking type updated.')
        })
        .catch((error) => {
            toast.error('There was an error updating the booking type.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
