<template>
    <div>
        <div class="card">
            <div class="card-body">
                <template v-if="!loading">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md-3">
                            <h4 class="header-title mt-0">
                                Unallocated Bookings
                            </h4>
                        </div>
                    </div>
                    <div
                        class="table-responsive-xxl table-scroll"
                        style="max-height: 25vh"
                    >
                        <table
                            class="table table-sm table-striped centered no-wrap w-100 align-middle text-sm"
                        >
                            <thead class="response-table-header-white">
                                <tr class="small">
                                    <th>Info</th>
                                    <th>Client</th>
                                    <th>Service Type</th>
                                    <th>Origin</th>
                                    <th>Destination</th>
                                    <th class="td-shrink"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="booking in bookings">
                                    <tr
                                        v-if="booking.client"
                                        :key="booking.uuid"
                                    >
                                        <td class="td-shrink text-center">
                                            <BookingIcons :booking="booking" />
                                        </td>
                                        <td class="table-user text-nowrap">
                                            <div
                                                class="d-flex flex-row align-items-center"
                                            >
                                                <div class="d-flex flex-column">
                                                    <BookingListLinkIcon :booking="booking" />
                                                    <ClientAllocationAlertsIcon :client="booking.client" />
                                                </div>
                                                <ClientName
                                                    :client="booking.client"
                                                ></ClientName>
                                            </div>
                                        </td>
                                        <td>
                                            {{ booking.service_type?.name }}
                                        </td>
                                        <td class="text-xs">
                                            <div
                                                class="d-flex flex-row align-items-center"
                                            >
                                                <BookingTimingsHoverIcon
                                                    :booking="booking"
                                                    type="origin"
                                                    class="me-1"
                                                />
                                                <BookingTime
                                                    :booking="booking"
                                                    type="origin"
                                                    class="me-2"
                                                />
                                                {{
                                                    booking.origin
                                                        ?.formatted_address
                                                }}
                                            </div>
                                        </td>
                                        <td class="text-xs">
                                            <div
                                                class="d-flex flex-row align-items-center"
                                            >
                                                <BookingTimingsHoverIcon
                                                    :booking="booking"
                                                    type="destination"
                                                    class="me-1"
                                                />
                                                <BookingTime
                                                    :booking="booking"
                                                    type="destination"
                                                    class="me-2"
                                                />
                                                {{
                                                    booking.destination
                                                        ?.formatted_address
                                                }}
                                            </div>
                                        </td>
                                        <td class="td-shrink pe-3">
                                            <a
                                                href="javascript: void(0)"
                                                v-tooltip="
                                                    'Allocate this booking'
                                                "
                                                @click="
                                                    showBookingModal(booking)
                                                "
                                            >
                                                <SvgIcon
                                                    type="mdi"
                                                    :path="allocateIcon"
                                                ></SvgIcon>
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="d-flex w-100 justify-content-center align-items-center ps-3"
                    >
                        <div class="spinner-grow my-5 me-3" role="status"></div>
                        <div class="fs-4">Loading Unallocated Bookings</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ClientName from "../Clients/ClientName.vue";
import {
    mdiChevronDoubleUp,
    mdiChevronDoubleDown,
    mdiHumanWheelchair,
    mdiAsterisk,
    mdiFilter,
    mdiCarArrowRight,
    mdiOpenInNew,
} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import BookingTime from "../Bookings/BookingTime.vue";
import { inject, onMounted, ref, watch } from "vue";
import { DateTime } from "luxon";
import BookingIcons from "@components/Bookings/BookingIcons.vue";
import Booking, { BookingStatus } from "@classes/Booking";
import BookingResource from "../../types/resources/BookingResource";
import IconHoverCompanion from "@components/Companion/IconHoverCompanion.vue";
import BookingTimingsHoverIcon from "@components/Bookings/BookingTimingsHoverIcon.vue";
import BookingListLinkIcon from "../Bookings/BookingListLinkIcon.vue";
import { useRegionStore } from "@stores/RegionStore";
import { storeToRefs } from "pinia";
import ClientAllocationAlertsIcon from "@components/Allocations/Components/ClientAllocationAlertsIcon.vue";

const { selectedRegion } = storeToRefs(useRegionStore());

const allocateIcon = ref(mdiCarArrowRight);

const bookingDetails = inject<any>("bookingDetails");
const bookings = ref<BookingResource[]>([]);
const loading = ref(true);

const getBookings = () => {
    Booking.index({
        date: DateTime.now(),
        status_filter: [BookingStatus.Future, BookingStatus.Standby],
        order_by: "planned_origin_time",
        unallocated: true,
    })
        .then((response) => {
            bookings.value = response.data;
        })
        .finally(() => {
            loading.value = false;
        });
};

const listen = () => {
    setInterval(getBookings, 120000); // two minutes
};

const showBookingModal = (booking: BookingResource) => {
    bookingDetails.showForBooking(booking);
};

watch(
    selectedRegion,
    () => {
        getBookings();
    }
)

onMounted(() => {
    getBookings();
    listen();
});
</script>

<style scoped></style>
