<template>
    <div class="d-flex flex-row justify-content-end dropdown">
        <button
            class="btn btn-light btn-sm mb-1 dropdown-toggle"
            type="button"
            id="dropDownFilterBookings"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
        >
            <SvgIcon
                type="mdi"
                :path="mdiFilter"
                style="max-width: 16px"
            ></SvgIcon>
        </button>
        <div
            class="dropdown-menu px-4 py-3 max-w-2xl"
            style="min-width: 500px; z-index: 9000"
            aria-describedby="dropDownFilterBookings"
        >
            <div class="row mb-3">
                <div class="col-md-12">
                    <label class="form-label" for="service_type_filter"
                        >Vehicle Depot</label
                    >
                    <VueMultiselect
                        v-model="allocatedBookingsFilters.depots"
                        :options="vehicleDepots"
                        placeholder="Select vehicle depots"
                        label="full_address"
                        track-by="uuid"
                        multiple
                    ></VueMultiselect>
                </div>
                <div class="col-md-6"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiFilter } from "@mdi/js";
import { computed, ref, watch } from "vue";
import VueMultiselect from "vue-multiselect";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";

const allocationStore = useAllocationStore();

const { vehicleDepots, allocatedBookingsFilters } =
    storeToRefs(allocationStore);
</script>
