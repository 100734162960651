<template>
    <table class="table table-bordered table-sm">
        <thead>
        <tr class="small">
            <th scope="col">Funding Type</th>
            <th scope="col">Reference Number</th>
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="reference in references">
            <tr>
                <td class="align-middle">{{ reference.funding_type.name }}</td>
                <td class="align-middle">{{ reference.reference_number }}</td>
                <td>
                    <button @click="removeReference(reference.uuid)" class="btn btn-sm btn-link">
                        <SvgIcon type="mdi" :path="minusCircleIcon" class="text-secondary"></SvgIcon>
                    </button>
                </td>
            </tr>
        </template>
        <tr>
            <td class="col-5">
                <select v-model="unsaved.fundingType" class="form-select form-select-sm">
                    <option value="">-- Select Funding Type --</option>
                    <template v-for="type in props.fundingTypes">
                        <option :value="type.uuid">{{ type.name }}</option>
                    </template>
                </select>
            </td>
            <td class="col-6">
                <input
                    v-model="unsaved.reference"
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="XXX XXX XXX"
                >
            </td>
            <td class="col-1">
                <button @click="addReferenceNumber" class="btn btn-sm btn-link">
                    <SvgIcon type="mdi" :path="plusCircleIcon" class="text-primary"></SvgIcon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "FundingReferenceNumbers"
}
</script>
<script setup>
import {ref, watch, onBeforeMount, nextTick, computed} from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    fundingTypes: {
        type: Object,
        required: true,
    },
    modelValue: Object,
    approvedFundingTypes: Array
})

const references = ref([])

watch(references.value, (newValue) => {
    // @TODO Post Launch - This is not working
    emit('update:modelValue', newValue)
})

const approved = computed(() => {
    return props.approvedFundingTypes.map(type => type.uuid)
})

watch(approved, (newValue) => {
    if (newValue.length === 0) {
        references.value = []
        emit('update:modelValue', references)
    }
})

const unsaved = ref({
    fundingType: '',
    reference: ''
})

import { uuid as uuidv4 } from 'vue-uuid'
const addReferenceNumber = function () {
    if (!unsaved.value.fundingType || !unsaved.value.reference) {
        return
    }

    const referenceToAdd = JSON.parse(JSON.stringify(unsaved.value))
    references.value.push({
        uuid: uuidv4.v4(),
        funding_type: props.fundingTypes.find((type) => type.uuid === referenceToAdd.fundingType),
        reference_number: referenceToAdd.reference,
    })

    unsaved.value = {
        fundingType: '',
        reference: ''
    }

    emit('update:modelValue', references)
}
const removeReference = function (uuidToRemove) {
    references.value = references.value.filter((reference) => reference.uuid !== uuidToRemove)
    emit('update:modelValue', references)
}

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
const plusCircleIcon = ref(mdiPlusCircle)
const minusCircleIcon = ref(mdiMinusCircle)

onBeforeMount(() => {
    references.value = props.modelValue
})

</script>

<style scoped>

</style>
