<template>
    <div>
        <div v-if="saved" class="alert alert-success">
            The details have been saved successfully.
        </div>
        <div class="card">
            <div class="card-header">
                Basic Details
            </div>
            <div class="card-body">
                <dl class="row mb-0">
                    <div class="col-md-6">
                        <dt>Email</dt>
                        <dd>{{ this.user.email }}</dd>
                    </div>
                    <div class="col-md-3">
                        <dt>Status</dt>
                        <dd class="d-flex align-items-center">
                            <template v-if="status === 'requires_verification'">
                                <SvgIcon type="mdi" :path="infoIcon" class="text-info me-1"></SvgIcon>Email Not Verified
                            </template>
                            <template v-if="status === 'two_factor_disabled'">
                                <SvgIcon type="mdi" :path="infoIcon" class="text-info me-1"></SvgIcon>2FA Not Enabled
                            </template>
                            <template v-if="status === 'active'">
                                <SvgIcon type="mdi" :path="activeUserIcon" class="text-success me-1"></SvgIcon>Active
                            </template>
                        </dd>
                    </div>
                    <div class="col-md-3">
                        <dt>Added</dt>
                        <dd>{{ moment(this.user.created_at).format('D MMMM YYYY') }}</dd>
                    </div>
                </dl>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Authentication
            </div>
            <div class="card-body">
                <div v-if="twoFactorEnabled" class="d-flex justify-content-between">
                    <div>
                        <h5>Reset two-factor authentication</h5>
                        <p>Resetting two-factor authentication will remove all two-factor authentication methods for this member.</p>
                    </div>
                    <ConfirmButton
                        color="outline-secondary"
                        :confirmation-title="'Reset 2FA for '+ user.name + '?'"
                        :confirmation-text="'Two-factor authentication currently provides additional protection to this account, are you sure you want to remove this security feature?'"
                        @confirmed="reset2fa()"
                    >
                        Reset two-factor authentication
                    </ConfirmButton>
                </div>
                <div v-if="!twoFactorEnabled">
                    <div>
                        <h5>Two-factor authentication</h5>
                        <p>Two-factor authentication is currently not enabled on this account.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="roles">
            <div class="card-header">
                Role
            </div>
            <div class="card-body">
                <ul class="list-group list-group-flush">
                    <div class="alert alert-info" v-if="user.uuid === currentUser.uuid">You are not able to change your own role.</div>
                    <li v-for="role in roles" :key="role.id" class="list-group-item">
                        <div class="d-flex form-check align-items-center">
                            <div class="h-5 w-5">
                                <input
                                    :disabled="user.uuid === currentUser.uuid"
                                    v-model="unsaved.role_id"
                                    class="form-check-input me-3"
                                    type="radio"
                                    name="role"
                                    :id="'role_radio_' + role.id"
                                    :value="role.id"
                                >
                            </div>
                            <label class="form-check-label" :for="'role_radio_' + role.id">
                                <div>{{ role.name }}</div>
                                <p class="fw-normal text-muted mb-0">{{ role.description }}</p>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card" v-if="showRegions">
            <div class="card-header">
                Region restriction
            </div>
            <div class="card-body">
                <VueMultiselect
                    v-if="regions"
                    v-model="unsaved.allowedRegions"
                    v-bind:options="regions"
                    v-bind:multiple="true"
                    v-bind:disabled="!canEditRegions"
                    label="name"
                    track-by="uuid">
                </VueMultiselect>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <Button
                color="primary"
                :icon="saveIcon"
                :loading="saving"
                @click="saveDetails"
            >
                Save details
            </Button>
        </div>
    </div>
</template>

<script>
import {mdiAccountMinusOutline, mdiCheckCircleOutline, mdiInformationOutline, mdiContentSaveOutline} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { Button, ConfirmButton } from '../';
import VueMultiselect from "vue-multiselect";
import {find, flatMap, indexOf, map} from "lodash";

export default {
    name: "UserDetails",
    components: {
        SvgIcon,
        Button,
        ConfirmButton,
        VueMultiselect,
    },
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            activeUserIcon: mdiCheckCircleOutline,
            infoIcon: mdiInformationOutline,
            saveIcon: mdiContentSaveOutline,
            twoFactorEnabled: this.user.has_two_factor_enabled,
            roles: null,
            saving: false,
            saved: false,
            unsaved: null,
            currentUser: window.user,
            regions: null,
            canEditRegions: window.permissions.indexOf('update_user_regions') > -1
        }
    },
    computed: {
        status: function () {
            if (!this.user.email_verified_at) {
                return 'requires_verification'
            }
            if (!this.user.has_two_factor_enabled) {
                return 'two_factor_disabled'
            }

            return 'active'
        },
        showRegions: function() {
            let role = find(this.roles, {'id': this.unsaved.role_id});
            let permissions = role ? flatMap(role.permissions, (permission) =>
                map(permission.actions, action => action)
            ) : [];
            return indexOf(permissions, 'update_user_regions') === -1;
        }
    },
    methods: {
        getRoles() {
            this.$http
                .get(this.route('api.roles.index'))
                .then((response) => {
                    this.roles = response.data
                })
        },
        getRegions() {
            this.$http
                .get(this.route('api.regions.index'))
                .then((response) => {
                    this.regions = response.data
                })
        },
        reset2fa() {
            this.$http
                .delete(this.route('two-factor.disable', { user: this.user.uuid }))
                .then(() => {
                    this.twoFactorEnabled = false
                })
        },
        saveDetails() {
            this.saving = true
            this.$http
                .put(this.route('api.users.update', { user: this.user.uuid }), {
                    role_id: this.unsaved.role_id,
                    allowed_regions: this.unsaved.allowedRegions.map((region) => { return region.uuid })
                })
                .then(() => {
                    this.saved = true
                })
                .finally(() => this.saving = false)
        },
    },
    beforeMount() {
        this.getRoles();
        this.getRegions();
        this.unsaved = JSON.parse(JSON.stringify(this.user));
    }
}
</script>

<style scoped>

</style>
