import Admin from "./Admin";
import Driver from "./Driver";
import User from "./User";

export default class Chat {
    //Not the most slick way to do this, but it works for now, doing as a hotfix to improve performance
    //I know in React we have redux persist so we may be able to find a way to do this in Vue/Pinia in the future with a package that allows us to handle objects better
    static getChatCache() {
        try {
            return JSON.parse(localStorage.chatCache);
        } catch {
            return {};
        }
    }

    static appendToChatCache(object: any) {
        localStorage.chatCache = JSON.stringify({
            ...this.getChatCache(),
            ...object,
        });
    }

    static async getCachableDriver(driverUuid: string) {
        if (!this.getChatCache()?.drivers) {
            this.appendToChatCache({
                drivers: {},
            });
        }

        if (this.getChatCache()?.drivers?.[driverUuid]) {
            return this.getChatCache()?.drivers?.[driverUuid];
        }

        try {
            let response = await Driver.show(driverUuid);
            this.appendToChatCache({
                drivers: {
                    ...this.getChatCache().drivers,
                    [driverUuid]: response.data,
                },
            });
        } catch (e) {
            console.error({ e });
        }

        return this.getChatCache()?.drivers?.[driverUuid];
    }

    static async getCachableUser(userUuid: string) {
        if (!this.getChatCache()?.users) {
            this.appendToChatCache({
                users: {},
            });
        }

        if (this.getChatCache()?.users?.[userUuid]) {
            return this.getChatCache()?.users?.[userUuid];
        }

        await new Promise((resolve, reject) => {
            User.show(userUuid)
                .then((response) => {
                    this.appendToChatCache({
                        users: {
                            ...this.getChatCache().users,
                            [userUuid]: response.data,
                        },
                    });
                    resolve(response.data);
                })
                .catch(async (e) => {
                    await Admin.show(userUuid)
                        .then((response) => {
                            this.appendToChatCache({
                                users: {
                                    ...this.getChatCache().users,
                                    [userUuid]: response.data,
                                },
                            });
                            resolve(response.data);
                        })
                        .catch((e) => {
                            console.error({ e });
                            resolve(undefined);
                        });
                });
        });

        return this.getChatCache()?.users?.[userUuid];
    }
}
