<template>
    <a
        @click.prevent="!props.client.deactivated && navigateToCreateBooking()"
        :href="createBookingLink"
        v-tooltip="props.client.deactivated ? 'Client is not active, no bookings can be accepted' : 'Create a new booking for ' + client.name"
    >
        <slot></slot>
    </a>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ClientInterface } from "@classes/Client";

const createBookingLink = computed(() => {
    return route("bookings.create", { client: [props.client.uuid] });
});

const props = withDefaults(
    defineProps<{
        client: ClientInterface;
    }>(),
    {}
);

const navigateToCreateBooking = function() {
    window.location.href = createBookingLink.value
}
</script>
