import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import VehicleType from "../classes/VehicleType";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 60 * 24;

export const useVehicleTypeStore = defineStore(
    "vehicleTypeStore",
    () => {
        const vehicleTypes = ref<App.Models.VehicleType[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.VehicleType[]> => {
            return new Promise((resolve, reject) => {
                VehicleType.index()
                    .then((response) => {
                        vehicleTypes.value = response.data.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(vehicleTypes.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                vehicleTypes.value
            );
        };
        return {
            vehicleTypes,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "vehicleTypeStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
