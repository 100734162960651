<template>
    <div v-if="show">
        <Modal v-model:show="show" :title="process + ' Location'" :loading="processing">
            <template v-slot:body>
                <div>
                    <div class="mt-2">
                        <AddressDbSearch
                            v-model="changeRestriction.location"
                            label="Location for Restriction"
                            placeholder="Location for Restriction"
                            size="large"
                            :required="true"
                            :is-disabled="process === 'Save'"
                            @isValid="(valid) => updateValidity('location', valid)"
                        />
                    </div>
                    <div class="mt-2">
                        <VehicleSearchSelect 
                            v-model:vehicle="changeRestriction.vehicle" 
                            :required="true"
                            label="Vehicle for Restriction"
                            @isValid="(valid) => updateValidity('vehicle', valid)"
                            :disabled="process === 'Save'"
                        />
                    </div>
                    <div class="mt-2">
                        <FormTextarea 
                            v-model:input="changeRestriction.comment"
                            label="Comment"
                            placeholder="Enter a comment to explian the reason for the location restriction"
                        />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="closeModal" class="btn btn-light" type="button">Close</button>
                <Button :icon="icon" :disabled="processing" @click="finished">{{ process }}</Button>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, reactive, watch } from "vue";
    import { storeToRefs } from "pinia";
    import { AddressDbSearch, VehicleSearchSelect, Modal, Button, Loading } from "@components"
    import { FormTextarea } from "@components/Forms";
    import { mdiPencil, mdiContentSaveOutline } from '@mdi/js';
    import { useLocationRestrictionStore } from "@stores/LocationRestrictionStore";
    import { copyValues } from "@components/Utils";

    const toast = inject('toast');

    const props = withDefaults(
        defineProps<{
            show: boolean,
            vehicle?: string | undefined,
        }>(),
        {
            show: false,
        }
    );

    const restrictionStore = useLocationRestrictionStore();

    const { 
        restriction, 
        changeRestriction,
        storingRestriction,
        updatingRestriction
    } = storeToRefs(restrictionStore);

    const { storeRestriction, updateRestriction, changeRestrictionDefault } = restrictionStore;

    const emit = defineEmits<{
        (e: 'update:show', value: boolean): void;
    }>();

    const validity = reactive<object>({
        location: restriction.value?.location?.uuid?.length ?? 0 > 0,
        vehicle: restriction.value?.vehicle?.uuid?.length ?? 0 > 0,
    });

    const closeModal = () => {
        show.value = false;
        changeRestriction.value = copyValues(changeRestrictionDefault);
    }

    const show = computed<boolean>({
        get: () => props.show,
        set: (value: boolean) => emit('update:show', value),
    });

    const icon = computed<string>(() => {
        return process.value === 'Create' ? mdiPencil : mdiContentSaveOutline;
    });

    const process = computed<string>(() => {
        return restriction.value?.uuid ? 'Save' : 'Create';
    });

    const processing = computed<string>(() => {
        return storingRestriction.value || updatingRestriction.value;
    });

    const isValid = computed((): boolean => {
        return validity.location && validity.vehicle && changeRestriction.value.comment.valid
    });

    const finished = async (): void => {
        if (!isValid.value) {
            toast.error("Please complete the required fields to continue");
            return;
        }
 
        if (process.value === 'Create') {
            storeRestriction(props.vehicle);
        }

        if (process.value === 'Save') {
            updateRestriction(props.vehicle);
        }

        closeModal();
    }

    const updateValidity = (type: string, valid: boolean) => {
        validity[type] = valid;
    }

    watch(
        show,
        () => {
            validity.location = restriction.value?.location?.uuid?.length ?? 0 > 0;
            validity.vehicle = restriction.value?.vehicle?.uuid?.length ?? 0 > 0;
        },
    )
</script>

<style scoped>
    .loading {
        background: rgba(0,0,0,0.75);
    }
</style>