<template>
    <div>
        <EditableInput
            @updated="storeChanges"
            :value="client.dva_number"
            :loading="storing"
            :disabled="!editable"
        ></EditableInput>
        <div v-if="Object.keys(errors).length > 0" class="form-text text-danger">{{ errors.dva_number[0] }}</div>
    </div>
</template>

<script>
export default {
    name: "EditClientDvaNumber"
}
</script>
<script setup>
import {ref, computed} from "vue";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";
import axios from "axios";
const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})

const emit = defineEmits(['updated'])

const errors = ref({})
const storing = ref(false)
const storeChanges = function (dvaNumber) {
    storing.value = true
    errors.value = {}
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            dva_number: dvaNumber
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storing.value = false
        })
}
</script>

<style scoped>

</style>
