<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title mt-0 mb-3">Default Booking Details</h4>
            </div>
            <dl class="row mb-0">
                <dt class="col-sm-4">Default booking service:</dt>
                <dd class="col-sm-8">
                    <EditClientDefaultBookingServiceType
                        :readOnly="readOnly"
                        @updated="$emit('updated', $event)"
                        :client="client"
                        :bookingServiceTypes="bookingServiceTypes"
                    />
                </dd>
                <dt class="col-sm-4">Default booking priority:</dt>
                <dd class="col-sm-8">
                    <EditClientDefaultPriority
                        :readOnly="readOnly"
                        @updated="$emit('updated', $event)"
                        :client="client"
                    />
                </dd>
                <dt class="col-sm-4">Default message For Driver:</dt>
                <dd class="col-sm-8">
                    <EditableTextarea
                        @updated="updateCommentChanges"
                        :disabled="readOnly"
                        :value="comments"
                        :loading="storingComment"
                        placeholder="Click to add a default message to a driver"
                    />
                </dd>
            </dl>
        </div>
    </div>
</template>

<script setup>
import EditableTextarea from "@components/Forms/EditableFields/EditableTextarea.vue";
import {inject, ref} from "vue";
import EditClientDefaultBookingServiceType from "@components/Clients/EditClientDefaultBookingServiceType.vue";
import EditClientDefaultPriority from "@components/Clients/EditClientDefaultPriority.vue";

const axios = inject('axios')

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    },
    bookingServiceTypes: Object,
})
const emit = defineEmits(['updated'])

const comments = ref(props.client.comments)

const storingComment = ref(false)
const updateCommentChanges = function (comment) {
    comments.value = comment
    storingComment.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            comments: comment
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storingComment.value = false
        })
}

</script>

<style scoped>

</style>
