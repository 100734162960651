<template>
    <tr>
        <td style="white-space: nowrap">
            <div class="d-flex flex-row align-items-center">
                <BookingListLinkIcon :booking="booking" class="me-1" />
                <DateTime
                    :date-time="
                        booking.planned_origin_time ??
                        booking.planned_destination_time
                    "
                    format="medium date"
                ></DateTime>
            </div>
        </td>
        <td style="white-space: nowrap">
            <ClientName :client="booking.client"></ClientName>
        </td>
        <td style="white-space: nowrap">
            {{ booking?.sequence_identifier }}
        </td>
        <td style="white-space: nowrap">
            {{ booking?.daily_leg_order }}
        </td>
        <td style="white-space: nowrap">
            {{ booking?.status ?? "N/A" }}
        </td>
        <td style="white-space: nowrap">
            {{ displayDistance(booking?.estimated_distance) }}
        </td>
        <td style="white-space: nowrap">
            {{ displayDistance(booking?.actual_distance) }}
        </td>
        <td style="white-space: nowrap">
            {{ formatCurrency(+(booking?.total_cost ?? 0) / 100) }}
        </td>
        <td style="white-space: nowrap">
            <div
                v-if="loading"
                class="spinner-border spinner-border-sm text-primary"
                role="status"
            ></div>
            <div v-if="!loading" class="d-flex flex-row align-items-center">
                <HoverPopUp>
                    <template v-slot:element>
                        <SvgIcon
                            type="mdi"
                            :path="mdiReceipt"
                            class="text-primary"
                            :size="20"
                        ></SvgIcon>
                    </template>
                    <template v-slot:popup-content>
                        <BookingFareResultItemsDisplay :fare="fare" />
                    </template>
                </HoverPopUp>
                <strong
                    :class="{
                        'ms-2': true,
                        'text-danger': isNewTotalDifferent,
                        'text-success': !isNewTotalDifferent,
                    }"
                >
                    ${{ fare?.total }}
                </strong>
            </div>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import Pagination from "@components/Pagination.vue";
import BookingResource from "@/resources/js/types/resources/BookingResource";
import BookingListLinkIcon from "@components/Bookings/BookingListLinkIcon.vue";
import DateTime from "@components/DateTime.vue";
import ClientName from "@components/Clients/ClientName.vue";
import { formatCurrency } from "@classes/Helpers/Currency";
import Booking from "@classes/Booking";
import Fare from "@classes/Fare";
import { BookingCalculatedFareInterface } from "@/resources/js/classes/Fare";
import HoverPopUp from "../../HoverPopUp/HoverPopUp.vue";

import { mdiReceipt } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import BookingFareResultItemsDisplay from "@components/BookingFare/BookingFareResultItemsDisplay.vue";

const props = withDefaults(
    defineProps<{
        booking: BookingResource;
    }>(),
    {}
);

const loading = ref<boolean>(false);
const fare = ref<BookingCalculatedFareInterface | undefined>(undefined);

const loadFare = async () => {
    loading.value = true;
    let result = await Fare.calculateForExistingBooking(props.booking);
    fare.value = result;
    loading.value = false;
};

const isNewTotalDifferent = computed(() => {
    return (
        fare.value?.total?.toFixed(2) !==
        ((props.booking?.total_cost ?? 0) / 100)?.toFixed(2)
    );
});

const displayDistance = (distance: number | null) => {
    if (distance === null || distance === undefined) {
        return "N/A";
    }
    return `${(distance / 1000).toFixed(2)} KM`;
};

onMounted(() => {
    loadFare();
});
</script>
