import CancelBooking from "./CancelBooking.vue";
import {ref} from 'vue'

const cancelBookingPlugin = {
    install: (app, customOptions = {}) => {
        const cancelBooking = {}
        const booking = ref({})
        const reloadOnSubmit = ref(true)
        const cancelledBooking = ref(null)

        cancelBooking.submitFor = (bookingToCancel, reload = true) => {
            booking.value = bookingToCancel
            reloadOnSubmit.value = reload
        }

        cancelBooking.updateCancelledBooking = (booking) => {
            cancelledBooking.value = booking
        }

        cancelBooking.getCancelledBooking = () => {
            return cancelledBooking.value
        }

        cancelBooking.reloadOnSubmit = () => {
            return reloadOnSubmit.value
        }

        app.provide('bookingToBeCancelled', booking)
        app.provide('cancelBooking', cancelBooking)
        app.component("cancel-booking", CancelBooking);
    }
}

export default cancelBookingPlugin
