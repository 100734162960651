import axios from "axios";

export type AssistanceTypeWithPivot = App.Models.AssistanceType & {
    pivot: App.Pivots.AssistanceTypePassengerable;
};

export interface AssistanceTypeOptionsInterface {
    label: string;
    value: any;
    description: string;
    seat_types: [];
}

export interface AssistanceTypeDetailsObjectInterface {
    code: string;
    seat_type?: string;
    removable?: boolean;
}

export default class AssistanceType {
    static async getAll(): Promise<App.Models.AssistanceType[]> {
        let result: App.Models.AssistanceType[] = [];

        try {
            let response: any = await axios.get(
                route("api.assistance-types.index")
            );

            if (response?.data?.some((i) => i?.id)) {
                result = response?.data;
            }
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }

    static async options(): Promise<AssistanceTypeOptionsInterface[]> {
        let result: AssistanceTypeOptionsInterface[] = [];

        try {
            let response: any = await axios.get(
                route("api.assistance-types.options")
            );

            result = response.data.data;
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }
}
