<template>
    <div>
        <Modal
            :show="props.show"
            :title="`Export ${ props.model } report`"
            @update:show="cancel"
        >
            <template v-slot:body>
                <template v-if="reportExport.status === 'draft'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Report title</label>
                            <input
                                type="text"
                                v-model="reportExport.name"
                                class="form-control border border-dark border-opacity-25 rounded"
                            />
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12">
                            <label class="form-label">Select folder</label>
                            <VueMultiselect
                                v-model="reportExport.folder"
                                :options="folders"
                                :taggable="true"
                                @tag="createFolder"
                                placeholder="Select folder"
                                label="name"
                                track-by="uuid"
                                id="exportFolders"
                            ></VueMultiselect>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'pending' || reportExport.status === 'queued'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Preparing report</label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                                     role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                     style="width: 100%"></div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'in-progress'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Exporting report</label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-animated" role="progressbar"
                                     :aria-valuenow="reportExport.progress" aria-valuemin="0" aria-valuemax="100"
                                     :style="`width: ${reportExport.progress}%`"></div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'completed'">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-success" role="alert">
                                Export complete.
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="reportExport.status === 'failed'">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Report failed</label>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                                     role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                     style="width: 100%"></div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-slot:footer>
                <template v-if="reportExport.status === 'draft'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="start" class="btn btn-primary">Export</Button>
                </template>
                <template v-if="reportExport.status === 'pending' || reportExport.status === 'queued'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="notify" class="btn btn-primary" disabled>
                        <span class="mdi mdi-email me-1"></span>Email me
                    </Button>
                </template>
                <template v-if="reportExport.status === 'in-progress'">
                <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                <Button v-if="notificationRequested" class="btn btn-success" disabled="disabled">
                    <span class="mdi mdi-check-circle me-1"></span>Email me
                </Button>
                <Button v-else @click="notify" class="btn btn-primary">
                    <span class="mdi mdi-email me-1"></span>Email me
                </Button>
                </template>
                <template v-if="reportExport.status === 'completed'">
                    <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                    <Button @click="download" class="btn btn-primary">
                        <span class="mdi mdi-download me-1"></span>Download
                    </Button>
                </template>
            </template>
        </Modal>
    </div>
</template>
<style scoped>

</style>

<script setup lang="ts">

import {inject, onMounted, ref, watch} from "vue";
import Modal from "@components/Modal.vue";
import FormTextInput from "@components/Forms/FormTextInput.vue";
import * as yup from "yup";
import Button from "@components/Button.vue";
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import ExportFolderResource = App.Resource.ExportFolderResource;
import {uuid} from "vue-uuid";

let timer: number;
const toast = inject('toast')
const notificationRequested = ref(false);

const props = withDefaults(
    defineProps<{
        show: boolean,
        model: string,
        fields: string[],
        rule_sets: { "type": string, "rules": { "field": string, "operator": string, "value": string }[][] }[],
        rule_sets_operator: string,
    }>(),
    {}
)

const emit = defineEmits<{
    (e: 'update:show', value: boolean): void,
}>();

const reportExport = ref<App.Resource.ExportResource>({
    name: `${props.model} report`,
    model: props.model,
    status: "draft",
    notify: false,
});

const folders = ref<App.Resource.ExportFolderResource[]>([]);

const cancel = () => {
    clearInterval(timer);
    emit('update:show', false);
}

const start = () => {
    axios
        .post(
            route('api.report-builder.query.generate'),
            {
                model: props.model,
                fields: props.fields.filter(n => n),
                rule_sets: props.rule_sets.filter(n => n?.type),
                rule_sets_operator: props.rule_sets_operator,
                name: reportExport.value.name,
                download: true,
                folder: {
                    name: reportExport.value.folder?.name,
                    uuid: reportExport.value.folder?.uuid
                }
            }
        )
        .then(response => {
            reportExport.value = response.data.data;
            timer = setInterval(() => progress(), 3000);
        })
        .catch(error => {
            toast.error(error.response.data.message);
            clearInterval(timer);
        })
}

const progress = () => {
    axios
        .get(
            route('api.exports.show', {export: reportExport.value})
        )
        .then(response => {
                reportExport.value = response.data.data;
            }
        )
}

const notify = () => {
    axios
        .patch(
            route('api.exports.update', {export: reportExport.value}),
            {notify: true,}
        )
        .then(response => {
            reportExport.value = response.data.data;
            notificationRequested.value = true;
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
}

const download = () => {
    window.location.href = route('api.exports.download', {export: reportExport.value});
}

const getFolders = () => {
    axios
        .get(route('api.folders.index'))
        .then((response) => {
            folders.value = response.data.data
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
};

const createFolder = (name:string) => {
    reportExport.value.folder = {
        name: name
    }
};

onMounted(() => {
    getFolders();
});

watch(reportExport, (reportExport) => {
    if (reportExport.status === 'completed' || reportExport.status === 'failed') {
        clearInterval(timer);
    }
})

watch(props, (props) => {
    reportExport.value.name = `${props.model} report`;
    reportExport.value.model = props.model;
    reportExport.value.status = "draft";
    reportExport.value.notify = false;
})

</script>