<template>
    <label :class="[labelClass, input.required ? 'required-label' : '']" :for="inputName">
        {{ label }}
    </label>
    <input
        type="number"
        v-model="inputValue"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        v-mask="mask"
        @accept="updated"
        @complete="updated"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
    import {computed, ref} from 'vue'
    import { kebabCase } from 'lodash'
    import { useField } from 'vee-validate'
    import * as yup from 'yup'
    import { copyValues, onReady, isInvalid, getErrorMessage } from '../Utils'

    const {input, label, inputName, placeholder, inputClass, labelClass, errorClass, validation, mask} = defineProps({
        input: {
            required: true,
            type: Object,
        },
        label: {
            type: String,
            default: null,
        },
        inputName: {
            type: String,
            default: kebabCase((Math.random() + 1).toString(36).substring(7)),
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        labelClass:{
            type: String,
            default: 'form-label'
        },
        errorClass: {
            type: String,
            default: 'text-danger form-text'
        },
        validation: {
            type: Object,
            default: yup.number().nullable(),
        },
        mask: {
            type: Object,
            default: {
                mask: Number,
                scale: 2,
                signed: false,
                radix: '.',
            }
        }
    })

    const modifier = Math.pow(10, mask.scale)
    const mounted = ref(false)
    const inputValue = ref(copyValues(input.value ? input.value / modifier : 0.00));

    onReady(() => mounted.value = true)

    const emit = defineEmits(['update:input'])

    const { errorMessage, setValue, validate, meta } = useField(inputName, validation)

    const updated = event => {
        if (mounted.value) {
            let value = event.detail.unmaskedValue
            value = value.length > 0 ? value * modifier : null
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(input)
                returnValue.value = meta.valid ? value : null
                returnValue.valid = meta.valid
                emit('update:input', returnValue)
            })
        }
    }
</script>
