import { ref } from "vue";
import { defineStore } from "pinia";
import { DataTableInterface, sortDirectionEnum } from "@customTypes/DataTableTypes";
import { PaginatedMetaInterface } from "@customTypes/LaravelCommonTypes";
export const usePaginationStore = (componentId:string = 'default') => defineStore(`PaginationStore:${componentId}`, () => {
    const paginateParams = ref<Partial<DataTableInterface>>({
        paginate: true,
        perPage: 25,
        page: 1,
        sortBy: null,
        sortDirection: sortDirectionEnum.Ascending,
    });

    const paginatedMeta = ref<Partial<PaginatedMetaInterface>>({
        current_page: 1,
        from: 1,
        to: 1,
        total: 1,
        per_page: 25,
        last_page: 1,
        path: '',
    });

    const searchQuery = ref<string>('');

    const updatePaginateSort = (column: string):void => {
        if (column != paginateParams.value.sortBy) {
            paginateParams.value.sortDirection = sortDirectionEnum.Ascending;
            paginateParams.value.sortBy = column;
            return;
        }
        
        if (paginateParams.value.sortDirection === sortDirectionEnum.Ascending) {
            paginateParams.value.sortDirection = sortDirectionEnum.Descending;
            return;
        }

        paginateParams.value.sortDirection = sortDirectionEnum.Ascending;
    }

    return {
        paginateParams,
        paginatedMeta,
        searchQuery,
        updatePaginateSort,
    }
})();