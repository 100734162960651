<template>
    <div class="d-flex align-items-center">
        <template v-if="checkingIfReasonCanBeDeleted">
            <Spinner size="small" type="border"/>
        </template>
        <template v-else>
            <template v-if="showDeleteConfirmation">
                <span class="me-2">Are you sure? </span>
                <Button v-if="reasonCanBeDeleted" color="danger" size="small" @click="deleteReason">Yes</Button>
            </template>
            <template v-else>
                <Button v-if="reasonCanBeDeleted" color="outline-danger" @click="showConfirmation">Delete</Button>
                <div v-else class="form-text text-danger">This cancellation reason cannot be removed as it has been
                    already been used.
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "DeleteBookingCancellationReason",
    components: {}
}
</script>
<script setup>
import Button from "../Button.vue";
import {onMounted, ref, inject} from "vue";
import axios from "axios";
import Spinner from "../Spinner.vue";

const toast = inject('toast')

const props = defineProps({
    reason: {
        required: true,
        type: Object,
    },
});

const emit = defineEmits(['deleted']);

const reasonCanBeDeleted = ref(true);
const checkingIfReasonCanBeDeleted = ref(true);
const checkIfReasonCanBeDeleted = function () {
    checkingIfReasonCanBeDeleted.value = true;
    axios
        .get(route('api.booking-cancellation-reasons.can-delete', {booking_cancellation_reason: props.reason.uuid}))
        .then(response => {
            reasonCanBeDeleted.value = !response.data.canDelete;
        })
        .catch(error => {
            toast.error('Error checking if cancellation reason can be deleted');
        })
        .finally(() => {
            checkingIfReasonCanBeDeleted.value = false;
        })
}

const showDeleteConfirmation = ref(false);
const showConfirmation = function () {
    showDeleteConfirmation.value = true;
}

const deleting = ref(false);
const deleteReason = function () {
    deleting.value = true;
    axios
        .delete(route('api.booking-cancellation-reasons.destroy', {booking_cancellation_reason: props.reason.uuid}))
        .then(response => {
            toast.success('Cancellation reason deleted');
            emit('deleted', props.reason);
        })
        .catch(error => {
            toast.error('Error deleting cancellation reason');
        })
        .finally(() => {
            deleting.value = false;
        })
}

onMounted(() => {
    checkIfReasonCanBeDeleted();
})

</script>

<style scoped>

</style>
