<template>
    <tr
        :class="{
            draft: allocationStore.bookingHasUnsavedChanges(booking),
        }"
    >
        <td class="td-shrink text-center ps-2">
            <input class="form-check-input" type="checkbox" v-model="checked" />
        </td>
        <td class="td-shrink">
            <a
                v-if="getRequirementsList(booking) !== null"
                href="javascript: void(0)"
                :id="'popover_' + keyValue + rowKey"
                data-bs-container="body"
                v-popover
                data-bs-trigger="focus"
                data-bs-html="true"
                :data-bs-content="getRequirementsList(booking)"
                data-bs-placement="top"
            >
                <i class="mdi mdi-information"></i>
            </a>
        </td>
        <td class="td-shrink text-center"></td>
        <td class="td-shrink">
            <BookingIcons
                :booking="booking"
                :warningAlertMessages="
                    unscheduledReasonForBookingReason
                        ? [unscheduledReasonForBookingReason]
                        : []
                "
                :dangerAlertMessages="
                    constraintViolationsMessage
                        ? [constraintViolationsMessage]
                        : []
                "
            />

            <span v-if="(booking?.companions?.length ?? 0) > 0">
                <IconHoverCompanion
                    :booking="booking"
                ></IconHoverCompanion>
            </span>

            <div v-if="props.allocationErrors?.hasOwnProperty(booking.uuid)" >
                <AllocationIcon :message="props.allocationErrors[booking.uuid]" iconClass="text-danger" />
            </div>

            <span v-if="props.booking?.is_force_allocation"
                  v-tooltip="'Force Allocation'"
            >
                <SvgIcon
                    type="mdi"
                    :path="mdiPin"
                    :size="iconSize"
                    class="me-1 text-danger"
                ></SvgIcon>
            </span>
        </td>
        <td class="table-user">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column">
                    <BookingListLinkIcon :booking="booking" />
                    <ClientAllocationAlertsIcon :client="booking.client" />
                </div>
                <ClientName class="align-self-center" :client="booking.client"></ClientName>
            </div>
            <span v-if="frontSeatOnly" v-tooltip="'Front Seat Only in ' + frontSeatGroups" class="seat-border border rounded" :class="frontSeatWrapperClass">
                <SvgIcon
                    type="mdi"
                    :path="mdiCarSide"
                    class="me-1 text-black"
                    v-if="frontSeatGroups.includes('Standard')"
                ></SvgIcon>
                <SvgIcon
                    type="mdi"
                    :path="mdiVanPassenger"
                    class="me-1 text-black van-icon"
                    v-if="frontSeatGroups.includes('Van')"
                ></SvgIcon>
                <img src="/images/front-seat.svg">
            </span>
            <DraftIndicator
                v-if="allocationStore.bookingHasUnsavedChanges(booking)"
            />
        </td>
        <td class="text-xs">
            <div class="d-flex flex-row align-items-center">
                <BookingTimingsHoverIcon
                    :booking="booking"
                    type="origin"
                    class="me-1"
                />
                <AllocationFormJourneyBookingTime
                    :booking="booking"
                    type="origin"
                />
            </div>

            <div>
                {{ booking.origin?.full_address }}
            </div>
        </td>
        <td class="text-xs">
            <div class="d-flex flex-row align-items-center">
                <BookingTimingsHoverIcon
                    :booking="booking"
                    type="destination"
                    class="me-1"
                />

                <AllocationFormJourneyBookingTime
                    :booking="booking"
                    type="destination"
                />
            </div>

            <div>
                {{ booking.destination?.full_address }}
            </div>
        </td>
        <td class="td-shrink pe-2">
            <div class="dropdown" v-if="booking.journey !== null">
                <button
                    :id="'actionItemsButton' + keyValue + rowKey"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <SvgIcon
                        type="mdi"
                        :path="mdiDotsVertical"
                        class="text-primary"
                    ></SvgIcon>
                </button>
                <div
                    class="dropdown-menu"
                    :aria-labelledby="'actionItemsButton' + keyValue + rowKey"
                >
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="() => (booking.journey = null)"
                        >Deallocate</a
                    >
                    <div class="dropdown">
                        <a
                            class="dropdown-item dropdown-toggle arrow-none"
                            href="javascript: void(0)"
                            :id="'other-journeys-list-' + keyValue + rowKey"
                            role="button"
                            data-bs-toggle="dropdown"
                        >
                            Move to another vehicle run
                            <div class="arrow-down"></div>
                        </a>
                        <div
                            class="dropdown-menu"
                            style="max-height: 200px; overflow: scroll"
                            :aria-labelledby="
                                'other-journeys-list-' + keyValue + rowKey
                            "
                        >
                            <a
                                href="javascript: void(0)"
                                @click="reallocateBooking(booking, otherJourney)"
                                v-for="otherJourney in otherJourneys"
                                class="dropdown-item"
                            >
                                <template v-if="otherJourney.driver"
                                    >{{ otherJourney.driver.given_names }}
                                    {{
                                        otherJourney.driver.last_name
                                    }}</template
                                >
                                <template v-else>No driver specified</template>

                                <template v-if="otherJourney.vehicle"
                                    >{{ " " }} -
                                    {{ otherJourney.vehicle.description }}
                                    ({{
                                        otherJourney.vehicle.registration_code
                                    }})</template
                                >
                            </a>
                        </div>
                    </div>
                    <a
                        class="dropdown-item"
                        @click="
                            () => (booking.status = BookingStatus.Cancelled)
                        "
                        href="javascript:void(0)"
                    >
                        Mark as cancelled
                    </a>
                    <a  v-if="!booking.is_force_allocation"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="
                            () => (booking.is_force_allocation = true)
                        "
                    >
                        Force Allocation
                    </a>
                    <a  v-else
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="
                            () => (booking.is_force_allocation = false)
                        "
                    >
                        Remove Force Allocation
                    </a>
                </div>
            </div>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { useAllocationStore } from "@stores/AllocationStore";
import ClientName from "../../Clients/ClientName.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import AllocationFormJourneyBookingTime from "../AllocationFormJourneyBookingTime.vue";
import {mdiDotsVertical, mdiCarSide, mdiVanPassenger, mdiPin} from "@mdi/js";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import Booking, { BookingStatus } from "@classes/Booking";
import DraftIndicator from "./DraftIndicator.vue";
import BookingIcons from "@components/Bookings/BookingIcons.vue";
import BookingResource from "@/resources/js/types/resources/BookingResource";
import IconHoverCompanion from "@components/Companion/IconHoverCompanion.vue";
import BookingTimingsHoverIcon from "@components/Bookings/BookingTimingsHoverIcon.vue";
import BookingListLinkIcon from "../../Bookings/BookingListLinkIcon.vue";
import AllocationIcon from "./AllocationIcon.vue";
import ClientAllocationAlertsIcon from "./ClientAllocationAlertsIcon.vue";

const allocationStore = useAllocationStore();

const { unscheduledReasonForBooking, constraintViolationsForBooking, checkAllocationRequirements } = allocationStore;

const {
    selectedBookingsInAllocatedList,
    selectedBookingsInUnAllocatedList,
    journeys,
    originalBookings,
    selectedJourney,
} = storeToRefs(allocationStore);

const props = withDefaults(
    defineProps<{
        booking: BookingResource,
        keyValue: number,
        allocationErrors?: object,
    }>(),
    {}
);

const unscheduledReasonForBookingReason = computed(() => {
    return unscheduledReasonForBooking(props.booking)?.reason;
});

const otherJourneys = computed(() => {
    return journeys.value.filter((journey) => {
        return journey.uuid !== selectedJourney.value?.uuid;
    });
});

const allocated = computed(() => {
    return props.booking.journey !== null;
});

const originalBooking = computed(() => {
    return originalBookings.value.find(
        (booking) => booking.uuid === props.booking.uuid
    );
});

const constraintViolationsMessage = computed<string | null>(() => {
    const violations = constraintViolationsForBooking(props.booking);

    if (violations.length === 0) {
        return null;
    }

    let message = "Constraint violation: \n\n";

    violations.forEach((violation) => {
        message += violation + "\n";
    });

    return message;
});

const checked = computed<boolean>({
    get() {
        let list = allocated.value
            ? selectedBookingsInAllocatedList
            : selectedBookingsInUnAllocatedList;

        let matching = list.value.find(
            (booking) => booking.uuid === props.booking.uuid
        );

        return matching !== undefined;
    },
    set(newValue) {
        let newSelectedBookings = (
            allocated.value
                ? selectedBookingsInAllocatedList
                : selectedBookingsInUnAllocatedList
        ).value;

        if (newValue) {
            newSelectedBookings.push(props.booking);
        } else {
            newSelectedBookings = newSelectedBookings.filter(
                (booking) => booking.uuid !== props.booking.uuid
            );
        }
        if (allocated.value) {
            selectedBookingsInAllocatedList.value = newSelectedBookings;
        } else {
            selectedBookingsInUnAllocatedList.value = newSelectedBookings;
        }
    },
});

const rowKey = ref(Math.random());

const getRequirementsList = (booking: App.Models.Booking) => {
    let notes = (booking?.accessibility_options ?? []).map(
        (option) => option.label
    );
    return notes.length > 0
        ? "<ul class='list-unstyled mb-0'><li>" +
              notes.join("</li><li>") +
              "</li></ul>"
        : null;
};

const reallocateBooking = (booking, otherJourney) => {
    checkAllocationRequirements([booking], otherJourney);

    booking.journey = otherJourney;
}

const frontSeatGroups = ref([]);
const frontSeatWrapperClass = ref('');
const frontSeatOnly = computed(() => {
    if (props.booking.chosen_seat === 'non_transfer_manual_wheelchair' ||
        props.booking.chosen_seat === 'ewheelchair') {
        return false;
    }

    if (props.booking.vehicle_types.length === 0) {
        return false;
    }

    for (var i = 0; i < props.booking.vehicle_types.length; i ++) {
        const vt = props.booking.vehicle_types[i];
        if (!vt.chosen_seat_positions) {
            return false;
        }

        if (vt.chosen_seat_positions.includes('back_seat')) {
            return false;
        }

        if (vt.chosen_seat_positions.includes('anchored_seat')) {
            return false;
        }

        if (!vt.chosen_seat_positions.includes('front_seat')) {
            return false;
        }

        // add vehicle groups
        if (!frontSeatGroups.value.includes(vt.group_name)) {
            frontSeatGroups.value.push(vt.group_name);
        }
    }

    if (frontSeatGroups.value.includes('Van')) {
        frontSeatWrapperClass.value = 'border-primary';
    }

    if (frontSeatGroups.value.includes('Standard')) {
        frontSeatWrapperClass.value = 'border-info';
    }

    if (frontSeatGroups.value.includes('Van') &&
        frontSeatGroups.value.includes('Standard')
    ) {
        frontSeatWrapperClass.value = 'border-warning';
    }

    return true;
});
</script>

<style>
    .seat-border {
        padding: 0 5px;
    }

    .van-icon {
        margin-top: -2px;
    }
</style>
