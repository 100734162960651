<template>
    <div>
        <span
            v-if="!editNameForm"
            @click="showEditNameForm"
            :class="[ editable ? 'click-to-edit' : 'text-muted' ]"
        >
            {{ client.formal_name }}
        </span>
        <div v-if="editNameForm">
            <div class="row mb-2">
                <div class="col-3 pe-0">
                    <label for="clientTitle" class="visually-hidden">Title:</label>
                    <select
                        v-model="unsaved.title"
                        class="form-select form-select-sm"
                        name="title"
                        id="clientTitle"
                        :disabled="storing"
                    >
                        <option value="">-- Select Title --</option>
                        <option value=""></option>
                        <template v-for="title in titles">
                            <option :value="title.value">{{ title.label }}</option>
                        </template>
                    </select>
                </div>
                <div class="col-md-4 px-1">
                    <label for="clientGivenNames" class="visually-hidden">Given Names:</label>
                    <input
                        v-model="unsaved.given_names"
                        required
                        :class="{ 'form-control': true, 'form-control-sm': true, 'is-invalid': errors.given_names }"
                        :disabled="storing"
                        type="text"
                        id="clientGivenNames"
                        name="given_names"
                        placeholder="Given name(s)"
                    >
                </div>
                <div class="col-md-5 ps-0 pe-1">
                    <label for="clientLastName" class="visually-hidden">Last Name:</label>
                    <input
                        v-model="unsaved.last_name"
                        required
                        :class="{ 'form-control': true, 'form-control-sm': true, 'is-invalid': errors.last_name }"
                        :disabled="storing"
                        type="text"
                        id="clientLastName"
                        name="last_name"
                        placeholder="Lastname"
                    >
                </div>
            </div>
            <div v-if="Object.keys(errors).length > 0" class="form-text text-danger">{{ Object.values(errors)[0][0] }}</div>
            <div class="form-text">This name should be exactly the same as the client has registered with Medicare.</div>
            <div class="d-flex justify-content-end align-items-center mt-2">
                <Button :loading="storing" size="small" :class-array="['me-1']" @click="storeChanges">Save</Button>
                <Button :disabled="storing" size="small" color="light" @click="cancelChanges">Cancel</Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditClientName"
}
</script>
<script setup>
import {ref, onBeforeMount, computed, inject} from "vue";
import Button from "../Button.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle, mdiCloseCircle } from "@mdi/js";
const axios = inject('axios')

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})

const emit = defineEmits(['updated'])

const editNameForm = ref(false)
const showEditNameForm = function () {
    if (!editable.value) {
        return
    }

    editNameForm.value = true
}

const initialiseData = function () {
    unsaved.value.title = props.client.title
    unsaved.value.given_names = props.client.given_names
    unsaved.value.last_name = props.client.last_name
}

const cancelChanges = function () {
    initialiseData()
    editNameForm.value = false
}

const storing = ref(false)
const storeChanges = function () {
    storing.value = true
    errors.value = {}
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), unsaved.value)
        .then((response) => {
            emit('updated', response.data)
            editNameForm.value = false
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storing.value = false
        })
}

const errors = ref({})
const unsaved = ref({
    title: null,
    given_names: null,
    last_name: null,
})
const titles = window.enums.titles

onBeforeMount(() => {
    initialiseData()
})

</script>

<style scoped>

</style>
