<template>
    <div class="d-inline-block">
        <EditableInput
            :loading="updating"
            :value="booking.destination_odometer?.toString() ?? ''"
            type="number"
            @updated="updateDestinationOdometer"
            :maxlength="7"
        />
    </div>
</template>

<script>
export default {
    name: "EditBookingDestinationOdometer"
}
</script>
<script setup>
import EditableInput from "../../Forms/EditableFields/EditableInput.vue";
import {inject, ref} from 'vue'
import {toTimeStamp} from "../../Utils.js";
import {DateTime} from 'luxon'

const toast = inject('toast')
import axios from "axios";
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },

})

const updating = ref(false)
const updateDestinationOdometer = function (newDestinationOdometer) {
    updating.value = true
    axios
        .put(route('api.bookings.update', {booking: props.booking.uuid}), {
            destination_odometer: newDestinationOdometer,
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            toast.success('Destination odometer updated for booking.')
        })
        .catch((error) => {
            if (error.code === 'ERR_BAD_REQUEST') {
                toast.error(error.response.data.message);
            } else {
                toast.error('There was an error updating the odometer value for this booking.')
            }
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
