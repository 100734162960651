<template>
    <div>
        <div class="card bg-light shadow-none mb-3">
            <div class="card-body p-3">
                <div class="d-flex justify-content-end">
                    <a @click.prevent="emitDelete" href="javascript: void(0)">
                        <i class="mdi mdi-close"></i>
                    </a>
                </div>
                <FormRow>
                    <FormCol md="6">
                        <FormTextInput
                            v-model:input="form.name"
                            label="Name"
                            placeholder="Contact name"
                            :validation="yup.string().max(255).required()"
                        />
                    </FormCol>
                    <FormCol md="6">
                        <FormTextInput
                            v-model:input="form.email"
                            label="Email"
                            placeholder="emergency-contant@example.com"
                            :validation="yup.string().email()"
                            type="email"
                            optional
                        />
                    </FormCol>
                </FormRow>

                <FormRow>
                    <FormCol>
                        <div class="form-check form-switch">
                            <input
                              v-model="form.is_primary"
                              :id="`primaryContactCheckbox-${index}`"
                              class="form-check-input"
                              type="checkbox"
                              @input="emitNonPrimaryContactToggle"
                            >
                            <label
                              class="form-check-label"
                              :for="`primaryContactCheckbox-${index}`"
                            >Set as primary contact</label>
                        </div>
                    </FormCol>
                </FormRow>

                <FormRow>
                    <FormCol xl="6" lg="12">
                        <FormInputLabel input-name="emergencyContactMobile" label="Mobile Phone" required></FormInputLabel>
                        <MazPhoneNumberInput
                            v-model="form.mobile_phone.value"
                            :placeholder="'Example 0412 345 678'"
                            :preferred-countries="['AU']"
                            :success="mobilePhoneValidation?.isValid"
                            @update="mobilePhoneValidation = $event"
                            color="primary"
                            default-country-code="AU"
                            size="sm"
                            show-code-on-list
                        />
                    </FormCol>
                    <FormCol xl="6" lg="12">
                        <FormInputLabel input-name="emergencyContactWorkPhone" label="Work Phone"></FormInputLabel>
                        <MazPhoneNumberInput
                            v-model="form.work_phone.value"
                            :placeholder="'Example 02 1234 5678'"
                            :preferred-countries="['AU']"
                            :success="workPhoneValidation?.isValid"
                            @update="workPhoneValidation = $event"
                            color="primary"
                            default-country-code="AU"
                            size="sm"
                            show-code-on-list
                        />
                    </FormCol>
                </FormRow>

                <FormRow>
                    <FormCol xl="6" lg="12">
                        <FormInputLabel input-name="emergencyContactHomePhone" label="Home Phone"></FormInputLabel>
                        <MazPhoneNumberInput
                            v-model="form.home_phone.value"
                            :placeholder="'Example 02 1234 5678'"
                            :preferred-countries="['AU']"
                            :success="homePhoneValidation?.isValid"
                            @update="homePhoneValidation = $event"
                            color="primary"
                            default-country-code="AU"
                            size="sm"
                            show-code-on-list
                        />
                    </FormCol>
                    <FormCol xl="6" lg="12">
                        <FormInputLabel input-name="emergencyContactFax" label="Fax"></FormInputLabel>
                        <MazPhoneNumberInput
                            v-model="form.fax.value"
                            :placeholder="'Example 02 1234 5678'"
                            :preferred-countries="['AU']"
                            :success="faxValidation?.isValid"
                            @update="faxValidation = $event"
                            color="primary"
                            default-country-code="AU"
                            size="sm"
                            show-code-on-list
                        />
                    </FormCol>
                </FormRow>

                <AddressDbSearch
                    :label="'Address'"
                    v-model="form.address"
                    @locationSelected="selectEmergencyContactAddress"
                    class="mb-3"
                />

                <FormRow>
                    <FormCol md="6">
                        <FormTextInput
                            v-model:input="form.organisation"
                            label="Organisation"
                            placeholder="ABC company"
                            :validation="yup.string().max(255)"
                            optional
                        />
                    </FormCol>
                    <FormCol md="6">
                        <FormTextInput
                            v-model:input="form.role"
                            label="Role"
                            placeholder="Manager"
                            :validation="yup.string().max(255)"
                            optional
                        />
                    </FormCol>
                </FormRow>

                <FormRow>
                    <FormCol md="6">
                        <FormSelect
                            v-model:selection="form.relationship"
                            :options="relationships"
                            label="Relationship"
                            placeholder="Select a Relationship"
                        />
                    </FormCol>
                </FormRow>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import AddressDbSearch from "../AddressDbSearch.vue";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { FormTextInput, FormSelect } from '../Forms'
import { FormRow, FormCol } from '../Forms/Layouts'
import FormInputLabel from '../Forms/parts/FormInputLabel.vue'
import * as yup from 'yup'

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    index: {
        type: Number,
        required: true
    },
    form: {
        type: Object,
        required: true
    },
    relationships: {
        type: Array,
        required: true
    },
})

const mobilePhoneValidation = ref(null)
const homePhoneValidation = ref(null)
const workPhoneValidation = ref(null)
const faxValidation = ref(null)

const address = ref(null)
const selectEmergencyContactAddress = function (location) {
    props.form.address = location.uuid
}

const emit = defineEmits(['delete', 'toggle-non-primary-contacts'])

const emitDelete = () => {
    emit('delete', props.index)
}

const emitNonPrimaryContactToggle = () => {
    emit('toggle-non-primary-contacts', props.index)
}
</script>