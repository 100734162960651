<template>
    <label :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
           :for="componentId">
        {{ label }}
    </label>
    <MazPhoneNumberInput
        @input="validateInput"
        :id="componentId"
        :model-value="input.value"
        :name="inputName"
        :placeholder="placeholder"
        :preferred-countries="['AU']"
        :success="phoneValidation?.isValid"
        color="primary"
        default-country-code="AU"
        size="sm"
        @change="phoneValidation = $event"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {kebabCase} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage} from '../Utils'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import {uuid} from 'vue-uuid'

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    mask,
    optional
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: () => {
            kebabCase((Math.random() + 1).toString(36).substring(7))
        }
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: null,
    },
    mask: {
        type: Object,
        default: null
    },
    optional: {
        type: Boolean,
        default: false
    },
})

const mounted = ref(false)
const inputValue = ref(copyValues(input.value))

let phoneValidation = ref()
const componentId = ref(uuid.v4())

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

const validateInput = (event) => {
    const value = event.target.value
    setValue(value)
    validate().then(() => {
        let returnValue = copyValues(input)
        returnValue.value = value;
        returnValue.valid = meta.valid;
        emit('update:input', returnValue)
    })
}
</script>
