<template>
    <div class="dropdown">
        <SvgIcon :path="mdiDotsVertical" class="w-4 h-4 me-1 dropdown-toggle pointer" type="mdi" data-bs-toggle="dropdown"
                 aria-expanded="false"></SvgIcon>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
                <a class="dropdown-item" :class="{ 'disabled': uneditable }" href="javascript:void(0)" @click="emit('edit', invoice)">
                    <SvgIcon :path="mdiPencil" class="me-1" size="14" type="mdi"></SvgIcon>
                    Edit
                </a>
            </li>
            <li>
                <a class="dropdown-item" :class="{ 'disabled': unapprovable }" href="javascript:void(0)" @click="emit('approve', invoice)">
                    <SvgIcon :path="mdiCheckDecagram" class="me-1" size="14" type="mdi"></SvgIcon>
                    Approve
                </a>
            </li>
            <li>
                <a class="dropdown-item" :class="{ 'disabled': unsyncable }" href="javascript:void(0)" @click="emit('sync', invoice)">
                    <SvgIcon :path="mdiSync" class="me-1" size="14" type="mdi"></SvgIcon>
                    Sync
                </a>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
                <a class="dropdown-item text-danger" :class="{ 'disabled': unvoidable }" href="javascript:void(0)" @click="emit('void', invoice)">
                    <SvgIcon :path="mdiCancel" class="me-1" :size="14" type="mdi"></SvgIcon>
                    Void
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import {computed, defineProps, toRefs} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCancel, mdiCheckDecagram, mdiDotsVertical, mdiPencil, mdiSync} from "@mdi/js";
import Invoice = App.Models.Invoice;
import {InvoiceStatus} from "@classes/Invoice";

const props = withDefaults(
    defineProps<{
        invoice: Invoice;
    }>(),
    {}
);

const emit = defineEmits<{
    (e: 'edit', invoice: Invoice): void
    (e: 'approve', invoice: Invoice): void
    (e: 'void', invoice: Invoice): void
    (e: 'sync', invoice: Invoice): void
}>()

const {invoice} = toRefs(props);

const uneditable = computed(() => { return false; });
const unsyncable = computed(() => { return false; });
const unapprovable = computed(() => { return invoice.value.status !== InvoiceStatus.Draft; });
const unvoidable = computed(() => { return invoice.value.status === InvoiceStatus.Void; });

</script>
