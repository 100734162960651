<template>
    <span>
        <template v-if="showValue">{{ value }}</template>
        <a v-else href="javascript: void(0)" class="fst-italic text-secondary" @click="reveal">Click to reveal</a>
    </span>
</template>

<script>
export default {
    name: "ClickToReveal",
    data() {
        return {
            showValue: false,
        }
    },
    props: {
        value: String,
    },
    methods: {
        reveal() {
            this.showValue = true
            setTimeout(() => { this.showValue = false }, 1000 * 10)
        }
    }
}
</script>

<style scoped>

</style>
