<template>
    <div class="modal fade text-dark" id="addTransactionModal" tabindex="-1" ref="addTransactionModal"
         aria-labelledby="addTransactionModal_label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addTransactionModal_label">
                        Add Transaction
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-start" v-if="transactionStore.transaction">
                    <div class="row">
                        <div class="col">
                            <label for="invoiceSearchInput" class="form-label">Invoice Number</label>
                            <div class="input-group" v-if="invoiceStore.invoices">
                                <VueMultiselect
                                    v-model="transactionStore.transaction.invoice"
                                    placeholder="Search issued invoice"
                                    label="uuid"
                                    track-by="uuid"
                                    selectLabel=""
                                    deselectLabel=""
                                    :options="invoiceStore.invoices"
                                    :multiple="false"
                                    :searchable="true"
                                    :internal-search="false"
                                    @search-change="searchInvoices"
                                    @select="selectInvoice"
                                >
                                    <template v-slot:option="{option, search}">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Markdown :content="$filters.highlight(option.uuid ?? '', search)"/>
                                            </div>
                                            <small>
                                                <Markdown
                                                    :content="$filters.highlight(option.recipient.full_name ?? option.recipient.name ?? '', search)"/>
                                            </small>
                                        </div>
                                    </template>
                                </VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <template v-if="transactionStore.transaction?.invoice">
                                <div class="card bg-light shadow-none border border-primary my-2">
                                    <div class="card-body p-0">
                                        <div class="bg-primary text-white text-center">
                                            <strong class="small">Invoice Details</strong>
                                        </div>
                                        <div class="p-2">
                                            <div class="row">
                                                <div class="col-1">
                                                    <Avatar
                                                        size="extra-small"
                                                        :avatar="transactionStore.transaction.invoice.recipient?.avatar"
                                                        :name="transactionStore.transaction.invoice.recipient.full_name ?? transactionStore.transaction.invoice.recipient.name"
                                                    />
                                                </div>
                                                <div class="col">
                                                    <strong>
                                                        {{
                                                            transactionStore.transaction.invoice.recipient.full_name ??
                                                            transactionStore.transaction.invoice.recipient.name
                                                        }}
                                                    </strong>
                                                    <br/>
                                                    <small>{{
                                                            transactionStore.transaction.invoice.recipient.uuid
                                                        }}</small>
                                                </div>
                                                <div class="col-1">
                                                    <a href="javascript: void(0)"
                                                       @click="() => transactionStore.transaction.invoice = null">
                                                        <i class="mdi mdi-close"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-1"></div>
                                                <div class="col-2">
                                                    <strong>Invoice</strong>
                                                </div>
                                                <div class="col">
                                                    {{ transactionStore.transaction.invoice.uuid }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-2">
                                                    <strong>Amount</strong>
                                                </div>
                                                <div class="col">
                                                    {{ formatCurrency(transactionStore.transaction.invoice.total) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <slot name="modal"></slot>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <label for="paymentMethodInput" class="form-label">Payment method</label>
                            <div class="input-group" v-if="transactionStore.transaction">
                                <VueMultiselect
                                    v-model="transactionStore.transaction.payment_method"
                                    placeholder="Select Payment Method"
                                    label="label"
                                    track-by="value"
                                    selectLabel=""
                                    deselectLabel=""
                                    :options="transactionStore.defaultPaymentMethods"
                                    :multiple="false"
                                    :searchable="false"
                                ></VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <label for="collectionMethodInput" class="form-label">Collection method</label>
                            <div class="input-group" v-if="transactionStore.transaction">
                                <VueMultiselect
                                    v-model="transactionStore.transaction.collection_method"
                                    placeholder="Select Collection Method"
                                    label="label"
                                    track-by="value"
                                    selectLabel=""
                                    deselectLabel=""
                                    :options="transactionStore.defaultCollectionMethods"
                                    :multiple="false"
                                    :searchable="false"
                                ></VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <label for="receiptNumberInput" class="form-label">Receipt Number</label>
                            <div class="input-group" v-if="transactionStore.transaction">
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="transactionStore.transaction.receipt_number"
                                    placeholder="e.g. 1234"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <label for="commentInput" class="form-label">Comment</label>
                            <div class="input-group" v-if="transactionStore.transaction">
                                <textarea
                                    class="form-control"
                                    v-model="transactionStore.transaction.comments"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <slot name="buttons">
                        <Button color="light" data-bs-dismiss="modal" ref="cancelButtonRef">
                            <slot name="cancel-button-text">Cancel</slot>
                        </Button>
                        <Button color="primary" @click="$emit('confirmed')">
                            <slot name="confirm-button-text">Add</slot>
                        </Button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from '@components/Button.vue'
import VueMultiselect from "vue-multiselect";
import Markdown from "@components/Markdown.vue";
import {useInvoiceStore} from "@stores/InvoiceStore";
import {useTransactionStore} from "@stores/TransactionStore";
import {usePaginationStore} from "@stores/PaginationStore";
import {onMounted, ref} from "vue";
import {formatCurrency} from "@classes/Helpers/Currency";
import Avatar from "@components/Avatar.vue";
import InvoiceResource from "@customTypes/resources/InvoiceResource";

const invoiceStore = useInvoiceStore();
const transactionStore = useTransactionStore();
const paginationStore = usePaginationStore('InvoiceStore');
const addTransactionModal = ref();

onMounted(function () {

    addTransactionModal.value.addEventListener('shown.bs.modal', (event: Event) => {
        invoiceStore.getAllInvoices();
    });

    transactionStore.transaction = {
        activities: undefined,
        activities_count: undefined,
        amount: 0,
        booking: undefined,
        booking_id: null,
        client: undefined,
        client_id: 0,
        collection_method: null,
        comments: null,
        created_at: null,
        currency: null,
        funding_type_id: null,
        id: 0,
        invoice: undefined,
        payment_method: null,
        receipt_number: null,
        type: "",
        updated_at: null,
        uuid: ""
    }
})

const searchInvoices = (query: string) => {
    paginationStore.searchQuery = query;
    invoiceStore.getAllInvoices();
}

const selectInvoice = (invoice: InvoiceResource) => {
    if (transactionStore.transaction) {
        transactionStore.transaction.invoice = invoice;
    }
}
</script>

<style scoped>

</style>
