import { DateTime } from "luxon";
import {
    BookingFormStopInterface,
    BookingStopScheduleType,
    ClientSpecificValue,
    getDefaultStop,
} from "../../stores/BookingStore";
import Booking, {
    BookingInterface,
    ListBookingsRequestParameters,
} from "../Booking";
import BookingPurpose, { BookingPurposesInterface } from "../BookingPurpose";
import Client, { ClientInterface } from "../Client";
import TransportRequirement from "../TransportRequirement";
import FareCollectionMethod, { FareCollectionMethodsInterface } from "../FareCollectionMethod";
import { DraftBookingRequestInterface } from "../../stores/BookingStore";
import BookingResource from "../../types/resources/BookingResource";

export default class BookingFormEditHelper {
    static async getBookingsInGroup(
        booking: BookingInterface
    ): Promise<BookingInterface[]> {
        let parameters: ListBookingsRequestParameters =
            booking.booking_form_identifier
                ? {
                      booking_form_identifier: booking?.booking_form_identifier,
                      date: DateTime.fromISO(booking.date),
                  }
                : { uuids: [booking.uuid] };
        return await new Promise<BookingInterface[]>((resolve) => {
            Booking.index(parameters)
                .then((response) => {
                    if (Array.isArray(response.data)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    console.error({ error });
                    resolve([]);
                });
        });
    }

    static async getClientDetailsFromArrayOfBookings(
        bookings: BookingInterface[]
    ) {
        //Because we don't want to get the address and additional details on all client list requests
        //We should probably normalise or add ability to fetch different relations from request but given the deadlines this works best currently
        let clients: ClientInterface[] = [];

        let uniqueClientUuids: string[] = [];

        bookings.map((booking) => {
            if (!uniqueClientUuids.includes(booking.client?.uuid ?? "")) {
                uniqueClientUuids.push(booking.client?.uuid ?? "");
            }
        });

        await Promise.all(
            uniqueClientUuids.map(async (clientUuid) => {
                if (!clientUuid) {
                    return;
                }

                let matchingClient = clients.find((i) => clientUuid == i.uuid);
                if (matchingClient) {
                    return;
                }

                let clientDetails = await new Promise<
                    ClientInterface | undefined
                >((resolve) => {
                    Client.show(clientUuid)
                        .then((response) => {
                            if (response.data.data.uuid) {
                                resolve(response.data.data);
                            } else {
                                resolve(undefined);
                            }
                        })
                        .catch((error) => {
                            console.error({ error });
                            resolve(undefined);
                        });
                });

                if (!clientDetails) {
                    return;
                }
                if (!matchingClient) {
                    clients.push(clientDetails);
                }
            })
        );

        return clients;
    }

    static groupBookingsByClients = (bookings: BookingInterface[]) => {
        let bookingsGroupedByClients: ClientSpecificValue<
            BookingInterface[]
        >[] = [];

        bookings.map((booking) => {
            let matchingGroup = bookingsGroupedByClients.find(
                (i) => i.client_uuid == booking.client.uuid
            );
            if (matchingGroup) {
                let matchingIndex =
                    bookingsGroupedByClients.indexOf(matchingGroup);
                bookingsGroupedByClients[matchingIndex].value.push(booking);
            } else {
                bookingsGroupedByClients.push({
                    client_uuid: booking.client.uuid,
                    value: [booking],
                });
            }
        });
        return bookingsGroupedByClients;
    };

    static getPurposeKeyFromValue = (
        purposes: object,
        purposeValue: string | null | undefined
    ): string | null => {
        if (!purposeValue) {
            return null;
        } else {
            return (
                Object.keys(purposes).find(
                    (key) => purposes[key] == purposeValue
                ) ?? null
            );
        }
    };

    static getFareCollectionKeyFromValue = (
        fareCollectionMethods: object,
        fareCollectionValue: string | null | undefined
    ): string | null => {
        let defaultMethod = Object.keys(fareCollectionMethods)[0];

        if (!fareCollectionValue) {
            return defaultMethod;
        } else {
            return (
                Object.keys(fareCollectionMethods).find(
                    (key) => fareCollectionMethods[key] == fareCollectionValue
                ) ?? defaultMethod
            );
        }
    };

    static async getClientSpecificValues(
        bookings: BookingInterface[],
        clients: ClientInterface[],
        fareCollectionMethods: FareCollectionMethodsInterface,
    ): Promise<Partial<DraftBookingRequestInterface>> {
        let values = {
            agencyUuid: [],
            agencyContactUuid: [],
            companionUuids: [],
            fareCollectionMethod: [],
            payerUuid: [],
            fundingTypeUuid: [],
            useClientFunding: [],
        };

        let bookingsGroupedByClients = this.groupBookingsByClients(bookings);

        bookingsGroupedByClients.map((clientGroupedBookings) => {
            let { client_uuid, value } = clientGroupedBookings;
            let firstBookingForClient = value[0];

            function storeClientSpecificValue(
                key: keyof DraftBookingRequestInterface,
                value: any
            ) {
                let matching = values[key]?.find(
                    (i) => i.client_uuid == client_uuid
                );
                if (matching) {
                    values[key].value = value;
                } else {
                    values[key].push({
                        client_uuid,
                        value,
                    });
                }
            }

            storeClientSpecificValue(
                "agencyUuid",
                firstBookingForClient.agency?.uuid
            );
            storeClientSpecificValue(
                "agencyContactUuid",
                firstBookingForClient.agency_contact?.uuid
            );

            storeClientSpecificValue(
                "fareCollectionMethod",
                this.getFareCollectionKeyFromValue(
                    fareCollectionMethods,
                    firstBookingForClient.fare_collection_method
                )
            );

            storeClientSpecificValue(
                "payerUuid",
                firstBookingForClient?.payer?.uuid ?? null
            );

            storeClientSpecificValue(
                "fundingTypeUuid",
                firstBookingForClient?.funding_type?.uuid
            );

            storeClientSpecificValue(
                "companionUuids",
                firstBookingForClient?.carers?.map((i) => i.uuid) ?? []
            );

            storeClientSpecificValue(
                "useClientFunding",
                firstBookingForClient?.funding_type?.uuid ==
                    clients.find((i) => i.uuid == client_uuid)?.funding_type
                        ?.uuid
            );
        });
        return values;
    }

    static async convertBookingsToStops(
        bookings: BookingInterface[],
        purposes: BookingPurposesInterface
    ): Promise<BookingFormStopInterface[]> {
        let stops: BookingFormStopInterface[] = [];

        if (bookings.length == 0) {
            return stops;
        }

        let bookingsGroupedByClients = this.groupBookingsByClients(bookings);

        //Insert default stops for all the bookings first (need one extra because stops 0/1 make one booking, 1/2 make another booking etc)
        for (let i = 0; i <= bookingsGroupedByClients[0].value.length; i++) {
            stops.push(getDefaultStop());
        }

        //Loop over the bookings for the first client,
        bookingsGroupedByClients[0].value.map((booking, bookingIndex) => {
            let originStopIndex = bookingIndex;
            let destinationStopIndex = bookingIndex + 1;
            let previousBooking: BookingResource | undefined =
                bookingsGroupedByClients[0].value?.[bookingIndex - 1];

            if (!stops[originStopIndex] || !stops[destinationStopIndex]) {
                console.warn(
                    "The number of stops for different clients do not match. Likely bad data"
                );
                return;
            }

            let originUseClientHomeAsLocation =
                booking.origin?.uuid == booking.client.address?.uuid;
            let destinationUseClientHomeAsLocation =
                booking.destination?.uuid == booking.client.address?.uuid;

            //By default have this as true unless both bookings have specific locations and they do not match
            let usePreviousStopLocationAsOrigin =
                booking.origin?.uuid && previousBooking?.destination?.uuid
                    ? booking.origin?.uuid == previousBooking?.destination?.uuid
                    : true;

            if (bookingIndex == 0) {
                stops[originStopIndex].useClientHomeAsLocation = originUseClientHomeAsLocation;
                stops[originStopIndex].location = booking.origin;
            }

            stops[destinationStopIndex].useClientHomeAsLocation = destinationUseClientHomeAsLocation;
            stops[destinationStopIndex].usePreviousStopLocationAsOrigin = usePreviousStopLocationAsOrigin;
            if (!usePreviousStopLocationAsOrigin) {
                stops[destinationStopIndex].originLocation = booking.origin;
            }
            stops[destinationStopIndex].location = booking.destination;
            stops[destinationStopIndex].bookingServiceType = booking.service_type?.uuid ?? null;
            stops[destinationStopIndex].purpose = this.getPurposeKeyFromValue(
                purposes,
                booking.purpose
            );

            stops[destinationStopIndex].treatmentTypeUuid =
                booking.treatment_type?.uuid ?? null;

            stops[destinationStopIndex].treatmentTypeUuid =
                booking.treatment_type?.uuid ?? null;
            stops[destinationStopIndex].transportType =
                booking.group_booking_identifier ? "group" : "individual";

            let scheduleTypeIfColumnMissing: BookingStopScheduleType =
                booking.depart_origin_no_later_than ? "pick_up" : "drop_off";

            //@ts-ignore
            let scheduleType: BookingStopScheduleType =
                booking.schedule_type ?? scheduleTypeIfColumnMissing;

            stops[destinationStopIndex].scheduleType = scheduleType;
            stops[destinationStopIndex].time = DateTime.fromISO(
                booking.requested_origin_time ??
                    booking.requested_destination_time ??
                    booking.planned_origin_time ??
                    booking.planned_destination_time ??
                    ""
            );
            stops[destinationStopIndex].clientToConfirmTime =
                scheduleType == "pick_up"
                    ? booking.origin_time_requires_confirmation
                    : false;

            let flexibilityBeforeComparison =
                scheduleType == "pick_up"
                    ? booking.depart_origin_not_before
                    : booking.arrive_destination_not_before;
            let flexibilityAfterComparison =
                scheduleType == "pick_up"
                    ? booking.depart_origin_no_later_than
                    : booking.arrive_destination_no_later_than;
            let plannedTime =
                scheduleType == "pick_up"
                    ? booking.planned_origin_time
                    : booking.planned_destination_time;

            let flexibilityBefore =
                plannedTime && flexibilityBeforeComparison
                    ? Math.abs(
                          DateTime.fromISO(plannedTime).diff(
                              DateTime.fromISO(flexibilityBeforeComparison),
                              "minutes"
                          ).minutes
                      )
                    : 0;

            let flexibilityAfter =
                plannedTime && flexibilityAfterComparison
                    ? Math.abs(
                          DateTime.fromISO(plannedTime).diff(
                              DateTime.fromISO(flexibilityAfterComparison),
                              "minutes"
                          ).minutes
                      )
                    : 0;

            stops[destinationStopIndex].flexibilityBefore = flexibilityBefore;
            stops[destinationStopIndex].flexibilityAfter = flexibilityAfter;

            //Populate client specific values
            bookingsGroupedByClients.map((clientBookingsGroup) => {
                let bookingsForClient = clientBookingsGroup.value;
                let matchingBookingForClient =
                    bookingsForClient?.[bookingIndex];

                if (!matchingBookingForClient) {
                    console.warn(
                        "The number of bookings for different clients do not match. Likely bad data"
                    );
                    return;
                }

                function storeClientStopSpecificValue(
                    stopIndex: number,
                    key: keyof BookingFormStopInterface,
                    value: any
                ) {
                    let client_uuid = clientBookingsGroup.client_uuid;
                    //@ts-ignore
                    let matching = stops[stopIndex][key]?.find(
                        (i) => i.client_uuid == client_uuid
                    );
                    if (matching) {
                        //@ts-ignore
                        stops[stopIndex][key].value = value;
                    } else {
                        //@ts-ignore
                        stops[stopIndex][key].push({
                            client_uuid,
                            value,
                        });
                    }
                }

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "uuid",
                    matchingBookingForClient.uuid
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "tags",
                    matchingBookingForClient.tags
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "appointmentTime",
                    matchingBookingForClient.appointment_time
                        ? DateTime.fromISO(
                              matchingBookingForClient.appointment_time
                          )
                        : null
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "referenceNumber",
                    matchingBookingForClient.custom_reference_number
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "priority",
                    matchingBookingForClient.priority
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "client_notes",
                    matchingBookingForClient.client_notes
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "notes",
                    matchingBookingForClient.notes
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "includeGST",
                    matchingBookingForClient?.include_gst ?? false
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "manualFee",
                    matchingBookingForClient?.manual_fee_applied ?? false
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "manualFeeAmount",
                    matchingBookingForClient?.manual_fee_applied
                        ? (matchingBookingForClient.subtotal ?? 0) / 100
                        : 0
                );

                storeClientStopSpecificValue(
                    destinationStopIndex,
                    "bookingRequirements",
                    {
                        chosen_seat: matchingBookingForClient?.chosen_seat,
                        vehicle_types: matchingBookingForClient?.vehicle_types,
                        assistance_types: matchingBookingForClient?.assistance_types,
                        cargo_spaces: matchingBookingForClient?.cargo_spaces,
                        client_uuid: matchingBookingForClient?.uuid,
                    }
                )
            });
        });

        return stops;
    }
}
