<template>
    <div v-if="selectedRegion">
        <div v-for="optimiserSolution in optimiserSolutions">
            <div
                v-if="optimiserSolution.solution.messages.length > 0"
                class="alert alert-warning d-flex align-items-center"
            >
                <SvgIcon
                    type="mdi"
                    :path="sparkleIconPath"
                    style="width: 1.3em"
                    class="me-1"
                ></SvgIcon>
                <div>
                    <p>
                        <strong
                            >Auto Allocation message for
                            {{
                                regions.find(
                                    (r) => r.uuid == optimiserSolution.region
                                )?.name ?? "Unknown"
                            }}
                            region:</strong
                        >
                    </p>
                    <p>{{ optimiserSolution.solution.messages }}</p>
                </div>
            </div>
        </div>
        <div
            v-for="error in autoAllocationDisabledErrors"
            class="alert alert-danger"
        >
            <p>
                {{ error }}
            </p>
        </div>

        <div
            v-for="error in autoAllocationResponseValidationErrors"
            class="alert alert-warning"
        >
            <p>
                {{ error }}
            </p>
        </div>
        <div class="alert alert-danger" v-if="errors.length > 0">
            <p>
                There was an error processing your allocations, some of your
                changes may not have been saved.
            </p>
            <p>
                Our developers have been notified of this issue, please try
                again later.
            </p>
        </div>
        <div
            class="alert alert-danger"
            v-if="bookingTimeValidationErrors.length > 0"
        >
            <p>
                There was an error processing allocations. Please check your
                input and try again.
            </p>
        </div>
        <div
            class="alert alert-danger"
            v-if="vehicleRunTimeValidationErrors.length > 0"
        >
            <div v-for="error in vehicleRunTimeValidationErrors">
                {{ error }}
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 mb-3 mb-md-0">
                <label for="bookingsForDate" class="visually-hidden"
                    >Select Date:</label
                >
                <div class="row align-items-center">
                    <div class="col-sm-8">
                        <input
                            type="date"
                            v-model="date"
                            class="form-control"
                            id="bookingsForDate"
                            :min="
                                Luxon.now()
                                    .minus({ year: 1 })
                                    .toFormat('yyyy-MM-dd')
                            "
                        />
                    </div>
                    <div class="col-sm-4">
                        <div
                            class="btn-group btn-group-sm d-none d-sm-inline-flex"
                            role="group"
                        >
                            <button
                                type="button"
                                class="btn btn-light"
                                aria-label="Previous Day"
                                @click="adjustDate(-1)"
                                :disabled="shouldDisablePreviousButton"
                            >
                                <i class="mdi mdi-chevron-left"></i>
                                <span class="visually-hidden"
                                    >Previous Day</span
                                >
                            </button>
                            <button
                                type="button"
                                class="btn btn-light"
                                aria-label="Next Day"
                                @click="adjustDate(1)"
                            >
                                <i class="mdi mdi-chevron-right"></i>
                                <span class="visually-hidden">Next Day</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="col-md-8 align-items-center justify-content-end flex-wrap"
            >
                <div class="d-flex align-items-center">
                    <div
                        :class="{
                            invisible:
                                !allocationsPageHasUnsavedChanges &&
                                !changedSaved,
                        }"
                        class="flex-grow-1 d-flex align-items-center justify-content-end flex-wrap"
                    >
                        <div
                            v-if="allocationsPageHasUnsavedChanges"
                            class="text-end pe-2 flex-grow-1 mb-2 mb-md-0"
                        >
                            Your changes have not been saved.
                        </div>
                        <div
                            v-if="changedSaved"
                            class="text-end pe-2 flex-grow-1 mb-2 mb-md-0 text-success"
                        >
                            Saved
                        </div>
                        <button
                            :disabled="savingChanges || changedSaved"
                            class="btn btn-primary me-1 mb-2 mb-md-0 d-flex align-items-center"
                            @click="saveChanges"
                        >
                            <i
                                v-if="!savingChanges"
                                class="mdi mdi-content-save me-1"
                            ></i>
                            <div
                                v-if="savingChanges"
                                class="spinner-grow spinner-grow-sm me-1"
                                role="status"
                            ></div>
                            Save changes
                        </button>
                        <button
                            :disabled="savingChanges || changedSaved"
                            class="btn btn-light me-1 mb-2 mb-md-0"
                            @click="cancelChanges"
                        >
                            Cancel Changes
                        </button>
                    </div>
                    <div v-if="!loadingOptimiserSolutions">
                        <div class="btn-group mb-2 mb-md-0">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm d-flex align-items-center"
                                v-tooltip="
                                    autoAllocationDisabledMessage.length > 0
                                        ? autoAllocationDisabledMessage[0]
                                        : 'Auto Allocate selected bookings to selected vehicle runs'
                                "
                                @click="autoAllocateBookings"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="sparkleIconPath"
                                    style="width: 1.3em"
                                    class="me-1"
                                ></SvgIcon>
                                Auto Allocate
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showManualOverrideWarning"
                    class="text-danger mt-2 text-end"
                >
                    Caution: manually adjusting the timings could result in a
                    schedule that is not feasible.
                </div>
            </div>
        </div>

        <template v-if="loadingOptimiserSolutions">
            <div
                class="d-flex w-100 justify-content-center align-items-center ps-3"
            >
                <div class="spinner-grow my-5 me-3" role="status"></div>
                <div class="fs-4">
                    Preparing data required for automatic allocation
                </div>
            </div>
        </template>
        <template v-if="!loadingOptimiserSolutions">
            <div class="row">
                <div class="col-xxl-5">
                    <UnallocatedBookings
                        :loading="loadingBookings || savingChanges"
                        :bookings="bookings"
                        class="mb-3 sticky-xxl-top"
                        style="top: 1rem"
                    ></UnallocatedBookings>
                </div>
                <div class="col-xxl-7">
                    <AllocationFormJourneys
                        :key="
                            date +
                            '_' +
                            journeys.length +
                            '_' +
                            reloadTablesKey +
                            '_journeys'
                        "
                        :loading="loadingJourneys || savingChanges"
                        :bookingTimeValidationErrors="
                            bookingTimeValidationErrors
                        "
                        @newJourneyCreated="getJourneys"
                    ></AllocationFormJourneys>
                </div>
            </div>
        </template>

        <!--        <div-->
        <!--            class="modal fade"-->
        <!--            id="optimiserOptionsModal"-->
        <!--            data-bs-backdrop="static"-->
        <!--            data-bs-keyboard="false"-->
        <!--            tabindex="-1"-->
        <!--            aria-labelledby="optimiserOptionsModalLabel"-->
        <!--            aria-hidden="true"-->
        <!--        >-->
        <!--            <div class="modal-dialog">-->
        <!--                <div class="modal-content">-->
        <!--                    <OptimiserSettings-->
        <!--                        @settingsUpdated="updateOptimiserSettings"-->
        <!--                    ></OptimiserSettings>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <div
            class="modal fade"
            id="optimiserProcessingModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <div
                            class="spinner-border text-primary my-3"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="fs-4 mb-2 text-dark">
                            Allocating Bookings
                        </div>
                        <p>
                            This process may take a minute or two, please do not
                            navigate away from this page or refresh your
                            browser.
                        </p>
                        <template v-if="regionAllocationStates.length > 1">
                            <table class="mx-auto my-2">
                                <tr v-for="state in regionAllocationStates">
                                    <template v-if="!state.completed">
                                        <td>
                                            <div
                                                class="me-3 spinner-border spinner-border-sm"
                                                role="status"
                                            ></div>
                                        </td>
                                        <td class="text-start">
                                            <template v-if="state.region">
                                                Allocating bookings in region
                                                "{{
                                                    regions.find(
                                                        (region) =>
                                                            region.uuid ===
                                                            state.region
                                                    )?.name
                                                }}"
                                            </template>
                                            <template v-else>
                                                Allocating bookings with no
                                                region specified
                                            </template>
                                        </td>
                                    </template>
                                    <template v-if="state.completed">
                                        <td>
                                            <SvgIcon
                                                type="mdi"
                                                :path="tickIcon"
                                                style="width: 1.3em"
                                                class="me-3 text-success"
                                            ></SvgIcon>
                                        </td>
                                        <td class="text-start">
                                            <template v-if="state.region">
                                                Bookings allocated for region
                                                "{{
                                                    regions.find(
                                                        (region) =>
                                                            region.uuid ===
                                                            state.region
                                                    )?.name
                                                }}"
                                            </template>
                                            <template v-else>
                                                Bookings allocated with no
                                                region specified
                                            </template>
                                        </td>
                                    </template>
                                </tr>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="optimiserUnscheduledModal"
            tabindex="-1"
            aria-labelledby="optimiserUnscheduledModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        The following bookings were unable to be automatically
                        scheduled
                    </div>
                    <div class="modal-body">
                        <div style="max-height: 30vh; overflow: scroll">
                            <ul class="list-group list-group-flush">
                                <template
                                    v-for="booking in unscheduledByOptimiser"
                                >
                                    <li
                                        v-if="booking.booking"
                                        class="list-group-item"
                                    >
                                        <strong>{{
                                            booking.booking.client.name
                                        }}</strong>
                                        <div class="text-sm">
                                            Origin:
                                            <template
                                                v-if="
                                                    booking.booking
                                                        .planned_origin_time
                                                "
                                            >
                                                <DateTime
                                                    :date-time="
                                                        booking.booking
                                                            .planned_origin_time
                                                    "
                                                    format="short time"
                                                ></DateTime>
                                                –
                                            </template>
                                            {{
                                                booking.booking.origin
                                                    .formattedAddress
                                            }}
                                        </div>
                                        <p class="mb-0 text-danger">
                                            {{ booking.reason }}
                                        </p>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="d-flex flex-column align-items-center justify-content-center p-5"
    >
        <div class="d-flex flex-row align-items-center justify-content-center">
            <SvgIcon
                type="mdi"
                :path="mdiFilter"
                :size="40"
                class="me-2 text-primary"
            ></SvgIcon>
            <h3 class="text-primary">Please select a region</h3>
        </div>
        <div
            class="d-flex flex-row align-items-center justify-content-center text-secondary text-center mt-2"
        >
            Viewing Allocations page with no regions is temporarily disabled
            while we work on improving the performance of this page. We are
            working to bring this feature back as soon as possible. Thank you so
            much for your patience during this time!
        </div>
    </div>
</template>

<script setup lang="ts">
import AllocationFormJourneys from "./AllocationFormJourneys.vue";
import OptimiserSettings from "./OptimiserSettings.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import UnallocatedBookings from "./UnallocatedBookings.vue";
import { mdiShimmer, mdiCheckCircle, mdiFilter } from "@mdi/js";
import DateTime from "../DateTime.vue";
import { DateTime as Luxon } from "luxon";
import { uuid } from "vue-uuid";
import { ref, computed, watch, onBeforeMount, onMounted } from "vue";
import axios from "axios";
import _ from "lodash";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import { useAllocationStore } from "@stores/AllocationStore";
import { useRegionStore } from "@stores/RegionStore";
import { storeToRefs } from "pinia";
import Region from "@classes/Region";

const allocationStore = useAllocationStore();
const regionStore = useRegionStore();

const { getJourneys, autoAllocateBookings, getBookings, saveChanges } =
    allocationStore;

const {
    adjustments,
    bookings,
    optimiserProcessingModal,
    loadingJourneys,
    journeys,
    date,
    showManualOverrideWarning,
    reloadTablesKey,
    unscheduledByOptimiser,
    regionAllocationStates,
    bookingTimeValidationErrors,
    vehicleRunTimeValidationErrors,
    errors,
    changedSaved,
    loadingOptimiserSolutions,
    optimiserSolutions,
    regions,
    optimiserUnscheduledModal,
    optimiserSettings,
    optimiserSettingsModal,
    loadingBookings,
    selectedJourneysForAllocation,
    selectedBookingsInUnAllocatedList,
    savingChanges,
    allocationsPageHasUnsavedChanges,
    autoAllocationDisabledMessage,
    autoAllocationDisabledErrors,
    autoAllocationResponseValidationErrors,
    autoAllocatePressed
} = storeToRefs(allocationStore);

const { selectedRegion } = storeToRefs(regionStore);

const sparkleIconPath = ref(mdiShimmer);
const tickIcon = ref(mdiCheckCircle);

const props = withDefaults(
    defineProps<{
        forDate?: string;
    }>(),
    {
        forDate: Luxon.now().toFormat("yyyy-MM-dd"),
    }
);

const shouldDisablePreviousButton = computed(() => {
    return date.value === Luxon.now().minus({ year: 1 }).toFormat("yyyy-MM-dd");
});

const adjustDate = (amount: number) => {
    date.value = Luxon.fromISO(date.value)
        .plus({ day: amount })
        .toFormat("yyyy-MM-dd");
};

const updateOptimiserSettings = () => {
    axios
        .post(route("optimiser.update-settings"), {
            optimiser_solution: optimiserSolutions[0],
            settings: optimiserSettings,
        })
        .then((response) => {
            if (response.data.success) {
                optimiserSettingsModal.value?.hide();
            }
        });
};

const cancelChanges = () => {
    adjustments.value = [];
    showManualOverrideWarning.value = false;
    bookingTimeValidationErrors.value = [];
    getBookings();
    getJourneys();
    autoAllocatePressed.value = false;
};

const AutoAllocate = (settings) => {
    optimiserSettings.value = settings;
    optimiserSettingsModal.value?.hide();
};

const getRegions = async () => {
    regions.value = (await regionStore.sync()) ?? [];
};

watch(date, (newDate) => {
    window.history.pushState(
        {},
        "",
        route("bookings.allocations.index", { date: newDate })
    );
    getBookings();
    getJourneys();
});

watch(changedSaved, (newChangedSaved) => {
    if (newChangedSaved === true) {
        setTimeout(() => {
            changedSaved.value = false;
        }, 5000);
    }
});

watch(allocationsPageHasUnsavedChanges, (newHasUnsavedChanges) => {
    window.onbeforeunload = newHasUnsavedChanges ? () => true : null;
});

watch(
    () => _.cloneDeep(regionAllocationStates.value),
    (states) => {
        if (states.some((state) => !state.completed)) {
            return;
        }

        optimiserProcessingModal.value?.hide();
        if (unscheduledByOptimiser.value.length > 0) {
            optimiserUnscheduledModal.value?.show();
        }
    }
);

watch(selectedRegion, () => {
    load();
});

onBeforeMount(() => {
    date.value = props.forDate
        ? Luxon.fromISO(props.forDate).toFormat("yyyy-MM-dd")
        : Luxon.local().toFormat("yyyy-MM-dd");
});

const load = () => {
    if (selectedRegion.value) {
        getRegions();
        getBookings();
        getJourneys();
    }
};

onMounted(() => {
    // optimiserSettingsModal.value = new bootstrap.Modal(
    //     document.getElementById("optimiserOptionsModal")
    // );
    optimiserProcessingModal.value = new bootstrap.Modal(
        document.getElementById("optimiserProcessingModal")
    );
    optimiserUnscheduledModal.value = new bootstrap.Modal(
        document.getElementById("optimiserUnscheduledModal")
    );
    load();
});
</script>
