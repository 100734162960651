import axios from "axios";
import { DateTime } from "luxon";
import BookingResource from "../types/resources/BookingResource";

export interface BookingCalculatedFareInterface {
    gst?: number;
    items?: {
        description: string;
        amount: number;
        credit_type: string | null;
        type: string;
    }[];
    subTotal?: number;
    total?: number;
    credit?: number;
}

export interface BookingCalculatorLegRequestInterface {
    funding_type: string;
    distance: number;
    start_time: DateTime;
    group_booking?: boolean;
    origin?: string;
    purpose?: string;
    destination?: string;
    vehicle_type?: string;
    tags?: string[];
    region?: string;
    sequence_bookings_count?: number;
    combined_estimated_distance?: number;
    combined_actual_distance?: number;
}

export default class Fare {
    static async calculate(parameters: {
        legs: BookingCalculatorLegRequestInterface[];
    }): Promise<BookingCalculatedFareInterface[]> {
        let result = [];

        try {
            const payload = {
                legs: parameters.legs.map((leg) => {
                    return {
                        ...leg,
                        ...{
                            start_time: leg?.start_time
                                ? leg?.start_time.toFormat("X")
                                : undefined,
                            group_booking: leg?.group_booking ? 1 : 0,
                        },
                    };
                }),
            };

            let response: any = await axios.get(
                route("bookings.multi-leg.calculate"),
                {
                    params: payload,
                }
            );

            if (typeof response?.data?.data?.some((i) => i?.total)) {
                result = response?.data?.data;
            }
        } catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static async calculateForExistingBooking(
        booking: BookingResource
    ): Promise<BookingCalculatedFareInterface | undefined> {
        let result = undefined;

        try {
            let response: any = await axios.get(
                route("bookings.calculate.from-booking", {
                    booking: booking.uuid,
                }),
                {}
            );

            if (typeof response?.data?.data?.total) {
                result = response?.data;
            }
        } catch (ex) {
            console.log(ex);
        }

        return result;
    }

    static recalculateByDateRange(parameters: {
        date_from: DateTime;
        date_to: DateTime;
    }) {
        return axios.post(route("bookings.calculate.by-date-range"), {
            date_from: parameters.date_from
                ? parameters.date_from.toFormat("yyyy-MM-dd")
                : undefined,
            date_to: parameters.date_to
                ? parameters.date_to.toFormat("yyyy-MM-dd")
                : undefined,
        });
    }
}
