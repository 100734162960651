<template>
    <div
        id="deallocateBookingModal"
        class="modal fade"
        aria-hidden="true"
        aria-labelledby="deallocateBookingModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deallocateBookingModalLabel">Confirm booking deallocation {{ booking?.uuid }}</h5>
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>The time you have chosen falls outside of the currently allocated vehicle run start and end times. If you proceed, this booking will get deallocated from the current run.</p>
                    <p>Are you sure you want to proceed?</p>
                    <div class="mb-3">
                        <div class="mb-2">
                            <strong>New {{ newBookingDate?.timePoint === 'origin' ? 'pick up' : 'drop off' }} time:</strong>
                            <span class="ms-2">
                                {{ newBookingDate?.dateTime.toLocaleString(LuxonDateTime.TIME_24_SIMPLE) }} - {{ newBookingDate?.dateTime.toLocaleString(LuxonDateTime.DATE_HUGE) }}
                            </span>
                        </div>
                        <div class="mb-2">
                            <strong>Currently allocated run:</strong>
                            <span class="ms-2">
                                {{ booking?.journey?.driver?.name }} ({{ booking?.journey?.vehicle?.description }} - {{ booking?.journey?.vehicle?.registration_code }})
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <Button
                        @click="closeModal"
                        color="light"
                    >
                        Cancel
                    </Button>
                    <Button
                        :loading="submitting"
                        @click="confirmDeallocation"
                        color="danger"
                    >
                        Confirm Deallocation
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, watch, onMounted } from 'vue'
import Button from '@components/Button.vue'
import {DateTime as LuxonDateTime} from 'luxon'

const toast = inject('toast')

const props = defineProps({
    toggleDetailsModal: {
        type: Boolean,
        default: false
    }
})

const booking = ref({})
const rescheduleBooking = inject('rescheduleBooking')
const submitting = ref(false)
const newBookingDate = ref(null)

watch(rescheduleBooking.getBooking, (bookingBeingDeallocated) => {
    if (rescheduleBooking.shouldPromptForDeallocation()) {
        hideBookingDetailsModal()
        booking.value = bookingBeingDeallocated
        newBookingDate.value = rescheduleBooking.getNewBookingDate()
        deallocateBookingModal.value.show()
    }
}, { deep: true })

watch(rescheduleBooking.isSubmitting, (isSubmitting) => {
    submitting.value = isSubmitting
})

const closeModal = () => {
    deallocateBookingModal.value.hide()
    showBookingDetailsModal()
}

const confirmDeallocation = () => {
    rescheduleBooking.submitReschedule().then(response => {
        deallocateBookingModal.value.hide()
        showBookingDetailsModal()
    }).catch(error => {
        toast.error('There was an unknown error deallocating the booking. Our developers have been notified of this issue.')
    }).finally(() => {
        submitting.value = false
    })
}

const bookingDetailsModal = () => {
    return bootstrap.Modal.getInstance(document.getElementById('bookingDetailsModal'))
}

const hideBookingDetailsModal = () => {
    if (props.toggleDetailsModal) {
        bookingDetailsModal().hide()
    }
}

const showBookingDetailsModal = () => {
    if (props.toggleDetailsModal) {
        bookingDetailsModal().show()
    }
}

const deallocateBookingModal = ref(null)
onMounted(() => {
    deallocateBookingModal.value = new bootstrap.Modal(document.getElementById("deallocateBookingModal"))
})
</script>