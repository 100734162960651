<script lang="ts" setup>
import {defineComponent, defineProps, defineEmits, ref} from 'vue'
import {mdiFilter, mdiMagnify} from "@mdi/js";
import VueMultiselect from 'vue-multiselect';
import SvgIcon from "@jamescoyle/vue-icon";

const component = defineComponent({name: "TutorialFilter"})
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const filterIcon = ref(mdiFilter);
const searchIcon = ref(mdiMagnify);

const availableServices = ref([
    'youtube',
    'vimeo',
]);

</script>

<template>
    <div class="row">
        <div class="col-6">
            <label class="form-label text-nowrap">
                Show listings
                <select v-model="modelValue.perPage"
                        @change="$emit('update:modelValue', modelValue)"
                        class="form-select form-select-sm d-inline mx-1">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                </select>
            </label>
        </div>
        <div class="col-6 text-md-end">
            <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="dropDownFilterClients"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <SvgIcon type="mdi" :path="filterIcon" style="max-width: 16px"></SvgIcon>
                    Filter
                </button>
                <div class="dropdown-menu px-4 py-3" style="min-width: 500px;" aria-describedby="dropDownFilterBookings">
                    <div class="row mb-2">
                        <div class="col">
                            <label class="form-label text-sm" for="view_select_filter">Service:</label>
                            <VueMultiselect
                                v-model="modelValue.video_service"
                                :options="availableServices"
                                :multiple="true"
                                placeholder="Select service"
                                @select="$emit('update:modelValue', modelValue)"
                                @remove="$emit('update:modelValue', modelValue)"
                            ></VueMultiselect>
                        </div>
                    </div>
                </div>
            </div>
            <label class="form-label text-nowrap">
                <div class="input-group input-group-sm">
                    <input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder="Search tutorials ..."
                        v-model="modelValue.query"
                        @keyup.enter="$emit('update:modelValue', modelValue)"
                    />
                    <button class="btn btn-outline-secondary" type="button"
                            @click="$emit('update:modelValue', modelValue)">
                        <SvgIcon type="mdi" :path="searchIcon" style="width: 1.3em"></SvgIcon>
                    </button>
                </div>
            </label>
        </div>
    </div>
</template>

<style scoped>

</style>