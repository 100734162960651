<template>
    <span :class="statusClass" :title="title" class="badge rounded-pill text-dark" data-bs-toggle="tooltip">
        <SvgIcon :path="iconPath" class="w-3 h-3" type="mdi"/>
    </span>
</template>

<script>
export default {
    name: "JourneyStatus"
}
</script>
<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCancel, mdiCarClock, mdiAlertCircleOutline, mdiFileCheckOutline, mdiFileClockOutline} from '@mdi/js';
import {Tooltip} from 'bootstrap'
import {computed, onMounted} from "vue";

const props = defineProps({
    status: {
        type: String,
        required: true
    }
})

const iconPath = computed(() => {
    switch (props.status) {
        case 'waiting_verification':
            return mdiFileClockOutline
        case 'verified':
            return mdiFileCheckOutline
        case 'unmet':
            return mdiAlertCircleOutline
        case 'cancelled':
            return mdiCancel
        case 'pending_information':
            return mdiFileClockOutline
        default:
            return mdiCarClock
    }
})

const title = computed(() => {
    switch (props.status) {
        case 'waiting_verification':
            return 'Waiting Verification'
        case 'pending_information':
            return 'Pending Information'
        case 'verified':
            return 'Verified'
        case 'unmet':
            return 'Unmet Vehicle Run'
        case 'cancelled':
            return 'Cancelled Vehicle Run'
        default:
            return 'Future Vehicle Run'
    }
})

const statusClass = computed(() => {
    switch (props.status) {
        case 'waiting_verification':
            return 'bg-warning-lighten'
        case 'pending_information':
            return 'bg-warning-lighten'
        case 'verified':
            return 'bg-success-lighten'
        case 'unmet':
            return 'bg-danger-lighten'
        case 'cancelled':
            return 'bg-secondary-lighten'
        default:
            return 'bg-info-lighten'
    }
})

onMounted(() => {
    new Tooltip(document.body, {
        selector: '[data-bs-toggle="tooltip"]'
    })
})
</script>


<style scoped>

</style>
