<template>
    <div>
        <span
            v-if="!editAddressForm"
            @click="showEditAddressForm"
            :class="[ !editable ? 'text-muted' : 'click-to-edit' ]"
        >
            <template v-if="client.address">{{ client.address.formatted_address }}</template>
            <template v-else><small><em>Click to add</em></small></template>
        </span>
        <div v-if="editAddressForm" class="d-flex align-items-center">
            <AddressDbSearch
                focus
                :isDisabled="!editable"
                id="clientHomeAddress"
                class="flex-grow-1"
                v-model="clientAddress"
                :label="'Home address'"
                hide-label
                size="small"
                @locationSelected="updateHomeAddress"
                :can-create="false"
            />
            <button
                v-if="!storing"
                @click="editAddressForm = false"
                type="button"
                class="btn-close ms-1"
                aria-label="Close"
            ></button>
            <Spinner class="ms-1" v-if="storing" type="border" size="small"></Spinner>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditClientAddress"
}
</script>
<script setup>
import {ref, onBeforeMount, computed} from "vue";
import AddressDbSearch from "../AddressDbSearch.vue";
import axios from "axios";
import Spinner from "../Spinner.vue";
import Button from "../Button.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const errors = ref({})
const storing = ref(false)

const clientAddress = ref(null)

const editAddressForm = ref(false)
const showEditAddressForm = function () {
    if (!editable.value) {
        return
    }

    editAddressForm.value = true
}

const updateHomeAddress = function (address) {
    storing.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            address: address.uuid ? address.uuid : ''
        })
        .then((response) => {
            emit('updated', response.data)
            editAddressForm.value = false
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storing.value = false
        })
}

onBeforeMount(() => {
    clientAddress.value = props.client.address
})

</script>

<style scoped>

</style>
