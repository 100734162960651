<template>
    <div v-if="mounted">
        <div v-if="clientReferrals">
            <div v-for="referral in clientReferrals"
                 class="config-row align-items-center">
                <div class="col-4 config-col align-self-center">
                    <span>{{ referral.name }}</span>
                    <span v-tooltip="referral.description" class="ms-1 mb-2">
                        <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" class="text-info"/>
                    </span>
                </div>

                <div class="col-7 config-col align-self-center">
                    <ReferralFormInput :referral="referral"></ReferralFormInput>
                </div>

                <div class="col-1 config-col">
                    <button @click="() => removeReferral(referral)" class="h-100 w-100" v-tooltip="'Remove Item'">
                        <SvgIcon type="mdi" :path="mdiMinusCircle" class="text-secondary"></SvgIcon>
                    </button>
                </div>
            </div>
        </div>
        <div class="config-row">
            <div class="col-11 config-col">
                <VueMultiselect :options="filteredAvailableReferrals" v-model="selectedReferral" track-by="uuid"
                                label="name" placeholder="--Select Referral Source--"></VueMultiselect>
            </div>
            <div class="col-1 config-col justify-content-center">
                <button @click="addReferral" class="h-100 w-100" :disable="!selectedReferral"
                        v-tooltip="'Add referral'">
                    <SvgIcon type="mdi" :path="mdiPlusCircle" class="text-primary" size="30"></SvgIcon>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, onUpdated, ref, toRefs, watch} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCloseCircleOutline, mdiInformationOutline, mdiMinusCircle, mdiPlusCircle,} from "@mdi/js";
import {Button} from "../";
import VueMultiselect from "vue-multiselect";
import ReferralResource from "@customTypes/resources/ReferralsResource";
import ReferralFormInput from "@components/Referrals/ReferralFormInput.vue";
import {cloneDeep} from "lodash";

const mounted = ref(false);
const initialised = ref(false);
const props = withDefaults(
    defineProps<{
        availableReferrals: ReferralResource[];
        clientReferrals: ReferralResource[];
    }>(),
    {}
);
const {availableReferrals, clientReferrals} = toRefs(props);
const filteredAvailableReferrals = computed(() => {
    return availableReferrals.value.filter((referral) => {
        return !clientReferrals?.value?.find(r => r.uuid === referral.uuid);
    })
})

onMounted(() => {
    mounted.value = true;
})

onUpdated(() => {
    initialised.value = true
})

const selectedReferral = ref<ReferralResource | null>(null);

const addReferral = () => {
    if (selectedReferral.value) {
        clientReferrals?.value?.push({...selectedReferral.value, pivot: {value: null}});
    }
    selectedReferral.value = null;
    return new MouseEvent("");
};

const removeReferral = (referral: ReferralResource) => {
    if (!clientReferrals) {
        return;
    }
    let index = clientReferrals.value.indexOf(referral);
    clientReferrals.value.splice(index, 1);

    return new MouseEvent("");
};

const emit = defineEmits(["updated"]);

// newValue and oldValue always the same, so need to emit update when this watcher is triggered
watch(
    clientReferrals,
    (newValue, oldValue) => {
        if (!initialised.value) {
            return
        }
        emit("updated", newValue);
    },
    {deep: true}
);
</script>

<style scoped>
.config-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}

.config-col {
    padding: 10px 5px;
    justify-content: center;
}

.total {
    border-top: 1px solid var(--bs-gray-300);
}
</style>
