export type LocationInterface = App.Models.Location & {
    formattedAddress: string;
    fullAddress: string;
    formatted_address: string;
    full_address: string;
};

export interface LatLng {
    lat: number;
    lng: number;
}

export default class Location {
    static locationToLatLng = (
        location: LocationInterface | App.Models.Location
    ): LatLng => {
        return {
            lat: +location?.latitude,
            lng: +location?.longitude,
        };
    };

    static getDistanceFromDirections(
        googleMapsDirectionsResponse: google.maps.DirectionsResult
    ): number {
        let distance = 0;

        googleMapsDirectionsResponse?.routes.map((route) => {
            route.legs.map((leg) => {
                distance += +(leg?.distance?.value ?? 0);
            });
        });

        return distance;
    }

    static getDurationFromDirections = (
        googleMapsDirectionsResponse: google.maps.DirectionsResult
    ) => {
        let duration = 0;

        googleMapsDirectionsResponse.routes.map((route) => {
            route.legs.map((leg) => {
                duration += +(leg?.duration?.value ?? 0);
            });
        });

        return +(duration / 60).toFixed(0);
    };

    static getReadableDistanceFromDirections(
        googleMapsDirectionsResponse: google.maps.DirectionsResult
    ) {
        let distance = this.getDistanceFromDirections(
            googleMapsDirectionsResponse
        );
        return `${(distance / 1000).toFixed(2)} km`;
    }
}
