<template>
    <div
        @click="handleClick"
        :class="[
            'card bg-light shadow-none border',
            selected ? 'border-primary' : 'border-white',
        ]"
    >
        <div class="card-body p-0">
            <div v-if="selected" class="bg-primary text-white text-center">
                <strong class="small"
                    >Selected for viewing / editing booking details</strong
                >
            </div>
            <div class="p-2">
                <slot name="action"></slot>
                <div class="d-flex">
                    <ClientAvatar :client="client" class="me-2" />
                    <div class="flex-grow-1" style="max-width: 280px">
                        <strong>{{ client?.name }}</strong>
                        <br />
                        <small v-if="client?.phone">{{ client?.phone }}</small>
                        <br />
                        <small>{{ client?.address?.formatted_address }}</small>
                    </div>
                </div>
                <div class="mt-1 small">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
        <slot name="modal"></slot>
    </div>
</template>

<!--suppress Annotator -->
<script setup lang="ts">
import { computed } from "vue";
import { ClientInterface } from "@classes/Client";
import { useBookingStore } from "@stores/BookingStore";
import ClientAvatar from "../Clients/ClientAvatar.vue";

const bookingStore = useBookingStore();

const props = withDefaults(
    defineProps<{
        client: ClientInterface;
    }>(),
    {}
);

const selected = computed(() => {
    return bookingStore.draft.selectedClientUuid == props.client.uuid;
});

const handleClick = () => {
    let event: MouseEvent = new MouseEvent("");
    bookingStore.draft.selectedClientUuid = selected.value
        ? null
        : props.client.uuid;

    return event;
};
</script>

<style scoped></style>
