<template>
    <div class="row">
        <div class="col-md-4 pe-5">
            <h4>Booking Settings</h4>
            <p>
                Here you can update the application settings that effect how
                bookings are created/updated within the application
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <OrganisationSettingOption
                            name="booking_form_estimated_distance"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import OrganisationSettingOption from "./OrganisationSettingOption.vue";
import { toRefs } from "vue";

const props = withDefaults(
    defineProps<{
        settings: App.Models.OrganisationSetting[];
    }>(),
    {
        //@ts-ignore
        settings: [],
    }
);

const { settings } = toRefs(props);
</script>

<style scoped></style>
