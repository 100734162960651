<template>
    <div class="card shadow-none mb-0">
        <div class="card-body border rounded">
            <div class="float-end">
                <button @click="report.removeRuleSet(ruleSet.id)" type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="d-flex align-items-center">
                <div>
                    <label :for="'match-type-' + componentId" class="visually-hidden">Match:</label>
                    <select class="form-select" :id="'match-type-' + componentId" v-model="ruleSet.matchType">
                        <option value="all">All</option>
                        <option value="any">Any</option>
                    </select>
                </div>
                <div class="flex-grow-1 ms-1">of the following rules are true</div>
            </div>
            <div v-if="ruleSet.rules && ruleSet.rules.length > 0" class="mt-1">
                <template v-for="(rule, index) in ruleSet.rules" :key="rule.id">
                    <div class="my-2" v-if="index !== 0">
                        <template v-if="ruleSet.matchType === 'all'">AND</template>
                        <template v-else>OR</template>
                    </div>
                    <div class="d-flex d-flex align-items-center">
                        <Rule :set-id="ruleSet.id" :id="rule.id" />
                        <button @click="report.removeRule(ruleSet.id, rule.id)" type="button" class="btn-close ms-1" aria-label="Close"></button>
                    </div>
                </template>
            </div>
            <div class="mt-2">
                <a href="javascript: void(0)" @click="report.addRule(ruleSet.id)">Add Rule</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RuleSet"
}
</script>
<script setup>
import {uuid} from 'vue-uuid'
import {computed, ref, reactive} from "vue";
import Rule from "./Rule.vue";
import {customReportStore} from "../../stores/CustomReportStore.js";
const report = customReportStore();

const props = defineProps({
    id: {
        type: String,
        required: true
    }
})

const componentId = ref(uuid.v4())
const ruleSet = ref(report.getRuleSet(props.id))

</script>

<style scoped>

</style>
