<template>
    <div class="table-responsive">
        <table class="table table-sm table-striped text-xs">
            <thead>
            <tr>
                <th></th>
                <th></th>
                <th>Client</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Booking Purpose</th>
                <th class="text-center">Booking Type</th>
                <th class="text-center">Cancellation Reason</th>
                <th class="text-center">Funding Type</th>
                <th class="text-center">Amount Asked</th>
                <th class="text-center">Invoice Total</th>
                <th>Payer</th>
                <th>Payment Received</th>
                <th class="text-center">Funding Rules</th>
                <th class="text-center" v-if="windowRef?.user_type == 'Admin'">GPS Updates</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="booking in journey?.bookings">
                <tr>
                    <td class="text-center align-middle">
                        <div aria-expanded="false"
                             :id="`${componentId}_booking_actions_${booking.uuid}`"
                             class="dropdown"
                             role="button"
                             data-bs-auto-close="outside"
                             data-bs-toggle="dropdown">
                            <span class="visually-hidden">Actions</span>
                            <SvgIcon :path="mdiDotsVertical"
                                     class="w-4 h-4 me-1 dropdown-toggle"
                                     type="mdi"
                                     data-bs-toggle="dropdown"
                                     data-bs-auto-close="outside"
                                     aria-expanded="false"></SvgIcon>
                            <div :aria-labelledby="`${componentId}_booking_actions_${booking.uuid}`" class="dropdown-menu">
                                <div class="dropdown">
                                    <a v-if="(journeysNoDetail.length - 1) > 0"
                                       :id="`other-journeys-list-${componentId}${booking.uuid}`"
                                       class="dropdown-item dropdown-toggle arrow-none"
                                       data-bs-toggle="dropdown"
                                       href="javascript: void(0)"
                                       role="button">
                                        Move to another vehicle run
                                        <div class="arrow-down"></div>
                                    </a>
                                    <div :aria-labelledby="`other-journeys-list-${componentId}${booking.uuid}`" class="dropdown-menu">
                                        <template v-for="otherJourney in journeysNoDetail">
                                            <a v-if="journey.uuid !== otherJourney.uuid"
                                               class="dropdown-item"
                                               @click="moveBookingToJourney(booking, otherJourney)"
                                               href="javascript: void(0)">
                                                {{ otherJourney.vehicle }}
                                                {{ $filters.format_datetime(otherJourney.start_time, 'short time') }}
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="align-middle">
                        <BookingStatusDropDown :booking="booking" mode="compact"/>
                    </td>
                    <td class="align-middle">
                        <div class="d-flex flex-row align-items-center">
                            <BookingListLinkIcon :booking="booking" :showServiceType="true" />
                            <ClientName :client="booking.client"></ClientName>
                        </div>
                    </td>
                    <td class="align-middle">
                        <div>
                            <strong>Departure Time: </strong>
                            <EditBookingEventTime :booking="booking" :journey-id="journey.uuid" :type="BookingEventType.PICK_UP"/>
                        </div>
                        <div>
                            <strong>Odometer: </strong>
                            <EditBookingOriginOdometer :booking="booking" :journey-id="journey.uuid"/>
                        </div>
                        <EditBookingOrigin :booking="booking" :journey-id="journey.uuid"/>
                    </td>
                    <td class="align-middle">
                        <div>
                            <strong>Arrival Time: </strong>
                            <EditBookingEventTime :booking="booking" :journey-id="journey.uuid" :type="BookingEventType.DROP_OFF"/>
                        </div>
                        <div>
                            <strong>Odometer: </strong>
                            <EditBookingDestinationOdometer :booking="booking" :journey-id="journey.uuid"/>
                        </div>
                        <div>
                            <strong>Actual Distance:</strong>
                            {{ Math.round(booking.actual_distance / 100) / 10 }} km
                        </div>
                        <EditBookingDestination :booking="booking" :journey-id="journey.uuid"/>
                    </td>
                    <td class="align-middle">
                        <EditBookingPurpose :booking="booking" :journey-id="journey.uuid" :purposes="purposes"/>
                    </td>
                    <td class="text-center align-middle">
                        <EditBookingType :booking="booking" :journey-id="journey.uuid"/>
                    </td>
                    <td class="text-center align-middle">
                        <EditBookingCancellationReason v-if="booking.status === 'cancelled'" :booking="booking" :journey-id="journey.uuid" :reasons="cancellationReasons" />
                        <template v-else>—</template>
                    </td>
                    <td class="text-center align-middle">
                        <EditBookingFundingType :booking="booking" :journey-id="journey.uuid"/>
                    </td>
                    <td class="text-center align-middle">{{ Filters.currency(booking.total_cost/100) }}</td>
                    <td class="text-center align-middle">
                        <EditBookingCost :invoice="booking.client_invoice" />
                    </td>
                    <td class="text-left align-middle">
                        <EditBookingPayer :booking="booking" :journey-id="journey.uuid"/>
                    </td>
                    <td class="align-middle">
                        <div>
                            <strong>Collection Method:</strong>
                            <FareCollectionMethod
                                v-if="booking.transactions.length > 0 && booking.transactions[0].collection_method"
                                :method="booking.transactions[0].collection_method"
                                hide-icon
                            />
                        </div>
                        <div>
                            <strong>Amount:</strong>
                            {{ currencyFilter(amountReceived(booking)) }}
                        </div>
                        <div v-if="booking.transactions.length > 0">
                            <strong>Receipt Number:</strong>
                            {{ booking.transactions[0].receipt_number }}
                        </div>
                        <a href="javascript: void(0)" @click="showTransactions(booking)">View Transactions</a>
                    </td>
                    <td class="text-center align-middle"><a href="javascript: void(0)" v-on:click="showFundingRules(booking)">Funding Rules</a></td>
                    <td v-if="windowRef?.user_type == 'Admin'" class="text-center align-middle"><a href="javascript: void(0)" v-on:click="driverPositionUpdates.showForBooking(booking)">GPS Updates</a></td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import {BookingEventType} from "@classes/BookingEvent"
import Filters from "@classes/Helpers/Filters"
import BookingListLinkIcon from "@components/Bookings/BookingListLinkIcon.vue";
import BookingStatusDropDown from "@components/Bookings/BookingStatusDropDown.vue";
import ClientName from "@components/Clients/ClientName.vue";
import FareCollectionMethod from "@components/FareCollectionMethod.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiDotsVertical} from "@mdi/js";
import {useVerificationStore} from "@stores/VerificationStore";
import axios from "axios";
import {inject, ref, toRefs} from "vue";
import {uuid} from 'vue-uuid';
import useWindow from "../../hooks/useWindow"
import {currencyFilter} from "../Utils.js";
import EditBookingCancellationReason from "./EditableFields/EditBookingCancellationReason.vue";
import EditBookingCost from "./EditableFields/EditBookingCost.vue";
import EditBookingDestination from "./EditableFields/EditBookingDestination.vue";
import EditBookingDestinationOdometer from "./EditableFields/EditBookingDestinationOdometer.vue";
import EditBookingEventTime from "./EditableFields/EditBookingEventTime.vue";
import EditBookingFundingType from "./EditableFields/EditBookingFundingType.vue";
import EditBookingOrigin from "./EditableFields/EditBookingOrigin.vue";
import EditBookingOriginOdometer from "./EditableFields/EditBookingOriginOdometer.vue";
import EditBookingPayer from "./EditableFields/EditBookingPayer.vue";
import EditBookingPurpose from "./EditableFields/EditBookingPurpose.vue";
import EditBookingType from "./EditableFields/EditBookingType.vue";

const toast = inject<any>('toast');

const verificationStore = useVerificationStore();

const driverPositionUpdates = inject<any>("driverPositionUpdates");
const windowRef = useWindow();

const purposes = ref(window.enums.bookingPurpose)
const cancellationReasons = ref(verificationStore.cancellationReasons)
const {journeys, journeysNoDetail, reloadStatisticRequired} = toRefs(verificationStore);

const emit = defineEmits(['bookingReallocated'])

const props = defineProps({
    journey: {
        type: Object,
        required: true
    },
});

const componentId = ref(uuid.v4())
const journey = ref(props.journey);

const amountReceived = function (booking) {
    if (booking.transactions.length === 0) {
        return 0
    }

    return booking.transactions.reduce((total, transaction) => {
        return total + transaction.amount
    }, 0)
}




const moveBookingToJourney = function (booking, journey) {
    axios
        .post(route('api.bookings.reallocate', {booking: booking.uuid}), {
            journey: journey.uuid
        })
        .then(() => {
            toast.success('You have moved the booking for **' + booking.client.name + '** to vehicle run: **' + journey.vehicle + '**')
            emit('bookingReallocated');
            reloadStatisticRequired.value = true;
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
}

const showTransactions = function (booking) {
    verificationStore.transactionModal.show()
    verificationStore.getBookingTransactions(booking, props.journey.uuid)
    verificationStore.getClientTransactions(booking.client)
    verificationStore.getInvoices(booking)
}

const showFundingRules = function (booking) {
    verificationStore.fundingRulesModal.show()
    verificationStore.getFundingRules(booking)
}

</script>

<style scoped>

</style>
