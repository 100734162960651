<template>
    <FormSelect
        v-model:selection="driverSetting"
        :options="settingOptions"
        :label="setting.title"
        placeholder="-- Select an option --"
        @update:selection="updateDriverRegulationSetting"
    />
    <p class="form-text">
        {{ setting.description }}
    </p>
    <div v-if="saved" class="d-flex align-items-center">
        <SvgIcon
            type="mdi"
            :path="mdiCheckCircle"
            class="text-success h-5 w-5 me-1"
        ></SvgIcon>
        Changes Saved
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import FormSelect from "../Forms/FormSelect.vue"
import { debounce } from 'lodash'
import SvgIcon from "@jamescoyle/vue-icon"
import { mdiCheckCircle } from "@mdi/js"

const axios = inject('axios')

const props = defineProps({
    setting: {
        type: Object,
        required: true,
    },
})

const settingOptions = [
    { value: '0', label: 'Disabled' },
    { value: '1', label: 'Enabled' },
];

const driverSetting = ref({
    value: props.setting.value,
    errors: [],
});

const saved = ref(false)
const updateDriverRegulationSetting = debounce(function (providerId) {
    driverSetting.value.errors = []
    axios
        .post(route('api.set-driver-regulation-check'), {
            value: driverSetting.value.value,
        }).then(() => {
            saved.value = true
            setTimeout(() => { saved.value = false }, 2000)
        }).catch(error => {
            if (error.response.status === 422) {
                driverSetting.value.errors = error.response.data.errors.value
            }
        })
}, 500)

</script>