<template>
    <ul class="list-unstyled mb-0 border rounded">
        <slot/>
    </ul>
</template>

<script>
export default {
    name: "Accordion"
}
</script>

<style scoped>

</style>
