<template>
    <div v-if="selectedClient && fare" class="card">
        <div class="card-body">
            <h4 class="header-title mt-0 mb-3">Fare Estimate</h4>

            <div class="alert alert-info">
                <div
                    class="d-flex flex-row align-items-center justify-content-between"
                >
                    <div class="d-flex flex-row align-items-center">
                        <SvgIcon
                            class="h-5 w-5 me-1"
                            type="mdi"
                            :path="mdiInformationOutline"
                        ></SvgIcon>
                        <h5>Billing Rules Applied</h5>
                    </div>
                    <div>
                        <a
                            href="javascript: void(0)"
                            @click="showFundingRules = !showFundingRules"
                        >
                            {{ showFundingRules ? "Hide" : "Show" }} Rules
                        </a>
                    </div>
                </div>

                <div
                    v-if="showFundingRules"
                    v-for="fundingRule in fundingRulesApplied"
                >
                    {{ fundingRule.name }}
                </div>
            </div>

            <div v-if="displayableFare !== null">
                <div v-if="fareReloading">
                    <Spinner />
                </div>
                <div v-else>
                    <BookingFareResultItemsDisplay
                        :fare="fare"
                        :includeGST="includeGST"
                        :manualFee="manualFee"
                        :manualFeeAmount="manualFeeAmount"
                    />
                </div>
            </div>

            <div class="d-flex align-items-center mt-3">
                <div>Vehicle type used in calculation:</div>
                <div class="dropdown">
                    <a
                        class="btn btn-link btn-sm p-1 dropdown-toggle"
                        style="text-decoration: underline dotted"
                        href="javascript: void(0)"
                        role="button"
                        :id="'vehicleTypeDropdown_' + stopIndex"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {{
                            selectedVehicleType
                                ? selectedVehicleType.description
                                : "none"
                        }}
                    </a>

                    <ul
                        class="dropdown-menu"
                        :aria-labelledby="'vehicleTypeDropdown_' + stopIndex"
                    >
                        <li>
                            <a
                                class="dropdown-item"
                                href="javascript: void(0)"
                                @click="
                                    selectedVehicleType = null;
                                    vehicleTypeCode = null;
                                "
                            >
                                none
                            </a>
                        </li>
                        <template v-for="vehicleType in vehicleTypes">
                            <li>
                                <a
                                    class="dropdown-item"
                                    href="javascript: void(0)"
                                    @click="
                                        selectedVehicleType = vehicleType;
                                        vehicleTypeCode = vehicleType.code;
                                    "
                                >
                                    {{ vehicleType.description }}
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Booking from "@classes/Booking";
import { BookingCalculatedFareInterface } from "@classes/Fare";
import { useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import { computed, watch, ref, onMounted } from "vue";
import moment from "moment";
import Spinner from "@components/Spinner.vue";
import BookingFormFareHelper from "@classes/Helpers/BookingFormFareHelper";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiInformationOutline } from "@mdi/js";
import BookingFareResultItemsDisplay from "@components/BookingFare/BookingFareResultItemsDisplay.vue";

const props = defineProps<{
    stopIndex: number;
}>();

const fareReloading = ref(false);

const showFundingRules = ref(false);

const bookingStore = useBookingStore();

const fare = bookingStore.getComputedForStopClientSpecificValue<
    BookingCalculatedFareInterface | undefined
>(props.stopIndex, "fare");

const { selectedClient, vehicleTypes } = storeToRefs(bookingStore);

const selectedVehicleType = ref<App.Models.VehicleType | null>(null);

const stop = computed(() => {
    return bookingStore.draft.stops[props?.stopIndex];
});

const previousStop = computed(() => {
    return bookingStore.draft.stops?.[props?.stopIndex - 1];
});

const fundingRulesApplied = computed(() => {
    if (!selectedClient.value) {
        return [];
    }
    return BookingFormFareHelper.getFundingRulesAppliedToBookingForm(
        selectedClient.value
    );
});

const distance = computed(() => {
    let result = 0;
    directionResponseForClient.value?.routes.map((route) => {
        route.legs.map((leg) => {
            result += +(leg?.distance?.value ?? 0);
        });
    });

    return result;
});

const directionResponseForClient = computed(() => {
    return stop.value.directionResponses.find(
        (i) => i.client_uuid == selectedClient.value?.uuid
    )?.value;
});

const fundingTypeUuid = bookingStore.getComputedForClientSpecificValue<
    string | null
>("fundingTypeUuid", null);

const purpose = bookingStore.getComputedForStopValue(
    props.stopIndex,
    "purpose",
    null
);

const vehicleTypeCode = bookingStore.getComputedForStopValue<string | null>(
    props.stopIndex,
    "vehicleTypeCode",
    null
);

const tagsForClient = bookingStore.getComputedForStopClientSpecificValue<
    App.Models.BookingTag[]
>(props.stopIndex, "tags");

const includeGST = bookingStore.getComputedForStopClientSpecificValue<boolean>(
    props.stopIndex,
    "includeGST"
);

const manualFeeAmount =
    bookingStore.getComputedForStopClientSpecificValue<number>(
        props.stopIndex,
        "manualFeeAmount",
        0
    );

const manualFee = bookingStore.getComputedForStopClientSpecificValue<boolean>(
    props.stopIndex,
    "manualFee"
);

const displayableFare = computed<BookingCalculatedFareInterface | null>(() => {
    let result: BookingCalculatedFareInterface | null = null;

    let manualFeeAmountValue = +manualFeeAmount.value;

    if (manualFee.value && manualFeeAmountValue > 0) {
        if (includeGST.value) {
            let gst = +(manualFeeAmountValue / 10).toFixed(2);

            result = {
                subTotal: manualFeeAmountValue,
                gst,
                total: +(manualFeeAmountValue + gst).toFixed(2),
            };
        } else {
            result = {
                total: manualFeeAmountValue,
            };
        }
    } else {
        result = fare.value ?? null;
        // if (includeGST.value) {
        //     result = fare.value ?? null;
        // } else {
        //     result = {
        //         items: fare.value?.items,
        //         total: fare.value?.subTotal,
        //         credit: fare.value?.credit,
        //     };
        // }
    }

    return result;
});
</script>
