<template v-if="bookings.length > 0">
    <div v-for="booking in bookings">
        <div><b>Booking:</b> #{{booking.uuid}}
            <JourneyRowBookingDetails
            :booking="booking"
        ></JourneyRowBookingDetails></div>
        <div><b>Vehicle:</b> #{{booking.journey?.vehicle.uuid}}, {{booking.journey?.vehicle.make}} {{booking.journey?.vehicle.model}} - {{booking.journey?.vehicle.registration_code}}</div>
        <div><b>Driver:</b> #{{booking.journey?.driver.uuid}}, {{booking.journey?.driver.given_names}} {{booking.journey?.driver.last_name}}</div>
        <a href="javascript: void(0)" @click="showRevertBookingModal(booking)">Action</a>
    </div>
    <RevertBooking
        :selected-booking="selectedBooking"
    />
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from "vue";
import axios from "axios";
import RevertBooking from "@components/Bookings/RevertBookingModal.vue";
import JourneyRowBookingDetails from "@components/JourneyTree/JourneyRowBookingDetails.vue";
const toast: any = inject('toast');
const bookings = ref([]);
import { storeToRefs } from "pinia";
import { useRevertBookingStore } from "@stores/RevertBookingStore.";

const showModal = ref(false);
const selectedBooking = ref({});
const revertBookingStore = useRevertBookingStore();
const {storeSelectedBookingToRevert} = storeToRefs(revertBookingStore);

const showRevertBookingModal = (booking) => {
    selectedBooking.value = {};

    setTimeout(function(){
        selectedBooking.value = booking;
        console.log(booking);
    }, 200);
}

const getRevertList = () => {
    axios.get(route('api.bookings.revert.list'),
        {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
        .then(response => {
            bookings.value = response.data;
        }).catch(error => {
        toast.error('There was an error when retrieving revert booking requests.');
    });
}

onMounted(() => {
    getRevertList();
});

watch(() => revertBookingStore.storeSelectedBookingToRevert, (store) => {
    const index = bookings.value.findIndex(booking => booking?.uuid === store.uuid);
    bookings.value.splice(index, 1);
})

</script>

<style scoped>

</style>
