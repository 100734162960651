<template>
    <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">Allocated Bookings Update</h4>
                </div>
                <div class="modal-body">
                    <p>
                        Atleast one of the bookings in this form (or recurrances
                        of this form) have been allocated to to a vehicle run.
                        Would you like to deallocate these bookings or keep them
                        in the vehicle run? Only the bookings that have been updated and have a status of Future will be deallocated.
                    </p>
                    <div v-for="option in options">
                        <div
                            :class="[
                                'me-2 card shadow-none border border-2 rounded-3',
                                deallocationBehaviour === option.value
                                    ? 'border-primary'
                                    : 'border-light',
                            ]"
                        >
                            <div class="card-body p-2">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        :disabled="option.disabled"
                                        type="radio"
                                        :value="option.value"
                                        v-model="deallocationBehaviour"
                                    />
                                    <label class="form-check-label">
                                        {{ option.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="warningMessage"
                        class="alert alert-danger d-flex flex-column"
                    >
                        <div class="d-flex flex-row align-items-center">
                            <SvgIcon
                                type="mdi"
                                :path="mdiAlert"
                                class="me-1"
                            ></SvgIcon>
                            <strong>Warning!</strong>
                        </div>
                        <p>
                            {{ warningMessage }}
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <Button class="btn btn-secondary" @click="close">
                        Close
                    </Button>
                    <Button class="btn btn-primary" @click="applyChanges">
                        Update Bookings
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRef, watch } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Button from "@components/Button.vue";
import { mdiAlert } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { storeToRefs } from "pinia";
import { useBookingStore } from "@stores/BookingStore";

const bookingStore = useBookingStore();
const { hasDateChanged } = storeToRefs(bookingStore);

const modalId = ref("BookingFormUpdateDeallocationOptionsModal");

const modal = ref<null | bootstrap.Modal>(null);

const deallocationBehaviour = ref<"deallocate" | "keep">("deallocate");

const warningMessage = computed<string | null>(() => {
    if (deallocationBehaviour.value == "keep") {
        return "The changes made may no longer qualify the assigned vehicle for this booking or affect the order of the bookings in the run. Please check the vehicle run details to ensure the vehicle is still suitable for this booking before proceeding.";
    }

    if (hasDateChanged.value) {
        return "The date of the booking has changed. Keeping the bookings allocated to the vehicle run will not be possible."
    }

    return null;
});

const options = computed(() => {
    return [
        {
            value: "deallocate",
            label: "Deallocate bookings",
            disabled: false,
        },
        {
            value: "keep",
            label: "Keep bookings allocated to Vehicle Run",
            disabled: hasDateChanged.value,
        },
    ];
});

const props = withDefaults(
    defineProps<{
        show?: boolean;
    }>(),
    {
        show: false,
    }
);

const emit = defineEmits<{
    (e: "update:show", value: boolean): void;
    (e: "deallocateOnUpdate", value: boolean): void;
}>();

const show = toRef(props, "show");

const applyChanges = () => {
    emit("deallocateOnUpdate", deallocationBehaviour.value == "deallocate");
    emit("update:show", false);
};

const close = () => {
    emit("update:show", false);
};

const initModal = () => {
    const modalElement = document.getElementById(modalId.value);
    if (!modalElement) {
        return;
    }
    modal.value = new bootstrap.Modal(modalElement, {});
};

watch(
    () => show.value,
    (newValue) => {
        if (newValue && modal.value) {
            modal.value?.show();
        } else {
            modal.value?.hide();
        }
    }
);

onMounted(() => {
    initModal();
});
</script>
