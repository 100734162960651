<template>
    <div :key="uuid" :class="{'message-container': true, 'message-sent': messageSentByUser, 'message-received': !messageSentByUser}">
        <div class="message">
            <div v-if="!messageSentByUser" class="text-xs text-secondary d-flex w-100 justify-content-between">
                {{ this.senderName }}
                <SvgIcon v-if="senderType === 'user'" type="mdi" :path="buildingIcon" class="h-4 w-4 ms-1"></SvgIcon>
                <SvgIcon v-if="senderType === 'crew'" type="mdi" :path="carIcon" class="h-4 w-4 ms-1"></SvgIcon>
            </div>
            <div style="word-break: break-word">{{ message.content }}</div>
            <div v-if="messageTime" :class="'text-xs mt-1' + (messageSentByUser ? '' : ' text-secondary')">{{ messageTime }}</div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCarSide, mdiDomain } from '@mdi/js'
import Chat from "@classes/Chat";

export default {
    name: "ChatMessage",
    props: {
        message: {
            type: Object,
            required: true,
        }
    },
    components: {
        SvgIcon,
    },
    data() {
        return {
            buildingIcon: mdiDomain,
            carIcon: mdiCarSide,
            senderName: null,
            uuid: this.$uuid.v4(),
        }
    },
    computed: {
        messageSentByUser: function () {
            return this.message.created_by_id.includes('office_' + window.user.uuid)
        },
        messageTime: function () {
            if (parseInt(this.message.created_at) > new Date().getTime()-120000) { // within last 2 minutes
                return null
            }

            if (parseInt(this.message.created_at) > new Date().getTime()-3600000) { // within last hour
                return this.moment(parseInt(this.message.created_at)).fromNow()
            }

            return this.moment(parseInt(this.message.created_at)).format('D MMM YY HH:mm')
        },
        senderType: function () {
            return this.message.created_by_id.includes('office_') ? 'user' : 'crew'
        }
    },
    methods: {
        async getSender() {
            const userId = this.message.created_by_id.split('_').at(-1)

            if (this.senderType === 'user') {
                this.senderName = (await Chat.getCachableUser(userId))?.name ?? 'Unknown User';
            } else {
                this.senderName = (await Chat.getCachableDriver(userId))?.name ?? 'Unknown Driver';
            }
        }
    },
    mounted() {
        this.getSender()
    }
}
</script>

<style scoped>
</style>
