<template>
    <div>
        <Button
            v-if="!loading && types.length > 0"
            @click="createBookingServiceType"
            class="mb-2"
            :icon="mdiPlus"
        >
            Create Booking Service Type
        </Button>
        <div class="card">
            <div class="card-body">
                <div v-if="loading" class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading Booking Service Types</div>
                </div>
                <div v-if="!loading && types.length === 0" class="d-flex flex-column w-100 justify-content-center align-items-center py-3">
                    <SvgIcon class="h-10 w-10 text-secondary mb-2" type="mdi" :path="mdiFolderMultiple" />
                    <p class="lead">No Booking Service Types have been created yet.</p>
                    <Button class="mt-2" @click="createBookingServiceType" :icon="mdiPlus">Create Booking Service Type</Button>
                </div>
                <template v-if="!loading && types.length > 0">
                    <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Booking Type</th>
                            <th>Origin Flexibility</th>
                            <th>Destination Flexibility</th>
                            <th>Status</th>
                            <th><span class="visually-hidden">Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(type, index) in types">
                            <tr>
                                <td>{{ type.name }}</td>
                                <td>{{ type.default_booking_type === 'individual' ? 'Individual Bookings' : 'Group Bookings' }}</td>
                                <td>{{ type.pickup_early_minutes }}m early / {{ type.pickup_late_minutes }}m late</td>
                                <td>{{ type.drop_off_early_minutes }}m early / {{ type.drop_off_late_minutes }}m late</td>
                                <td>{{ type.is_active ? 'Active' : 'Deactivated'}}</td>
                                <td class="text-end">
                                    <a href="javascript: void(0)" @click="editBookingServiceType(type)">Edit</a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>

        <div class="modal fade" id="typeFormModal" tabindex="-1" aria-labelledby="typeFormModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="typeFormModalLabel">
                            {{ unsavedType.uuid ? 'Update Booking Service Type' : 'Create Booking Service Type' }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <FormTextInput
                                v-model:input="unsavedType.name"
                                :key="fieldRefreshKey + '_name'"
                                label="Name/Description"
                                placeholder="e.g. Group Shopping"
                            />
                        </div>
                        <div class="mb-3">
                            <FormSelect
                                v-model:selection="unsavedType.default_booking_type"
                                :key="fieldRefreshKey + '_default_booking_type'"
                                :options="bookingTypes"
                                label="Default booking type"
                                placeholder="-- Select Booking Type --"
                            />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Origin timing flexibility</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <FormSelect
                                        v-model:selection="unsavedType.pickup_early_minutes"
                                        :key="fieldRefreshKey + '_pickup_early_minutes'"
                                        :options="flexibilityOptions"
                                        label="Early"
                                        placeholder="-- Select Minutes --"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormSelect
                                        v-model:selection="unsavedType.pickup_late_minutes"
                                        :key="fieldRefreshKey + '_pickup_late_minutes'"
                                        :options="flexibilityOptions"
                                        label="Late"
                                        placeholder="-- Select Minutes --"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Destination timing flexibility</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <FormSelect
                                        v-model:selection="unsavedType.drop_off_early_minutes"
                                        :key="fieldRefreshKey + '_drop_off_early_minutes'"
                                        :options="flexibilityOptions"
                                        label="Early"
                                        placeholder="-- Select Minutes --"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormSelect
                                        v-model:selection="unsavedType.drop_off_late_minutes"
                                        :key="fieldRefreshKey + '_drop_off_late_minutes'"
                                        :options="flexibilityOptions"
                                        label="Late"
                                        placeholder="-- Select Minutes --"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <div>
                            <template v-if="unsavedType.uuid">
                                <transition name="basic-fade" mode="out-in">
                                    <div v-if="unsavedType.is_active">
                                        <template v-if="!confirmationQuestionShowing">
                                            <Button
                                            class="btn-danger"
                                            @click="showConfirmationQuestion('deactivate')"
                                            >
                                                Deactivate
                                            </Button>
                                            <template v-if="unsavedType.can_be_deleted">
                                                <Button
                                                    v-if="!showDeleteConfirmation"
                                                    color="danger-link"
                                                    @click="showConfirmationQuestion('delete')"
                                                >
                                                    Delete
                                                </Button>
                                            </template>
                                        </template>
                                        <span v-else-if="showDeleteConfirmation">
                                            Are you sure to delete? <Button size="small" color="danger" class="ms-1" @click="deleteServiceType">Yes</Button>
                                        </span>
                                    </div>
                                    <div v-else>
                                        <template v-if="!confirmationQuestionShowing">
                                        <Button
                                            class="btn-success"
                                            @click="showConfirmationQuestion('activate')"
                                        >
                                            Activate
                                        </Button>
                                        </template>
                                        <span v-else-if="showActivateConfirmation">
                                            Are you sure to Activate? <Button size="small" color="success" class="ms-1" @click="activateServiceType">Yes</Button>
                                        </span>
                                    </div>
                                </transition>
                            </template>
                        </div>
                        <div class="text-end">
                            <Button color="light" class="me-1" data-bs-dismiss="modal">Close</Button>
                            <Button v-if="unsavedType.uuid" @click="updateBookingServiceType" :loading="updating">Save changes</Button>
                            <Button v-else @click="storeBookingServiceType" :loading="storing">Create Service Type</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deactiavationConfirmation" tabindex="-1" aria-labelledby="typeFormModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-warning mb-2"><b>Note</b> Moving service type will not update booking's flexibility.</div>
                        Update future booking and client to other service type:
                        <div class="mt-2">
                            <select class="form-select" v-model="deactivateServiceTypeToMove">
                                <option value="">-- Select Service Type to move --</option>
                                <option v-for="(type, index) in bookingServiceTypeWithoutSelectedType"
                                    :value="type.uuid"
                                >
                                    {{type.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="deactivateMoveLoading" class="d-flex align-items-center">
                            <div class="spinner-grow me-3" role="status"></div>
                            Deactivating...
                        </div>
                        <div v-else>
                            Are you sure to deactivate? <Button size="small" color="danger" class="ms-1" @click="deactivateServiceType">Deactivate</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ListBookingServiceTypes"
}
</script>
<script setup>
import {ref, inject, onMounted, onBeforeMount} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiPlus, mdiFolderMultiple} from "@mdi/js";
import Button from "../Button.vue";
import {FormTextInput,FormSelect} from "@components/Forms";
import BookingServiceType from "../../classes/BookingServiceType";

const axios = inject('axios')
const toast = inject('toast')
const fieldRefreshKey = ref(Math.random())

const bookingTypes = [
    {value: 'group', label: 'Group bookings'},
    {value: 'individual', label: 'Individual bookings'},
]

const flexibilityOptions = [
    {value: 0, label: 'No flexibility'},
    {value: 5, label: '5 minutes'},
    {value: 10, label: '10 minutes'},
    {value: 15, label: '15 minutes'},
    {value: 30, label: '30 minutes'},
    {value: 60, label: '1 hour'},
];

const types = ref([]);
const loading = ref(false);
const getBookingServiceTypes = async () => {
    loading.value = true;
    axios
        .get(route('api.booking-service-types.index'))
        .then((response) => {
            types.value = response.data.data;
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        })
        .finally(() => {
            loading.value = false;
        });
};

const unsavedType = ref({})

const initialiseData = () => {
    unsavedType.value = {
        uuid: '',
        name: {
            value: '',
            required: true
        },
        default_booking_type: {
            value: 'individual',
            required: true
        },
        pickup_early_minutes: {
            value: 5,
        },
        pickup_late_minutes: {
            value: 5,
        },
        drop_off_early_minutes: {
            value: 5,
        },
        drop_off_late_minutes: {
            value: 5,
        },
        is_active: false,
        can_be_deleted: false
    }
}

const createOrEditModal = ref(null);
const deactivateConfirmationModal = ref(null);
const deactivateServiceTypeToMove = ref('');
const deactivateMoveLoading = ref(false);
const createBookingServiceType = () => {
    initialiseData()
    fieldRefreshKey.value = Math.random();
    createOrEditModal.value.show();
};

const storing = ref(false)
const storeBookingServiceType = () => {
    storing.value = true;
    axios
        .post(
            route('api.booking-service-types.store'),
            {
                name: unsavedType.value.name.value,
                default_booking_type: unsavedType.value.default_booking_type.value,
                pickup_early_minutes: unsavedType.value.pickup_early_minutes.value,
                pickup_late_minutes: unsavedType.value.pickup_late_minutes.value,
                drop_off_early_minutes: unsavedType.value.drop_off_early_minutes.value,
                drop_off_late_minutes: unsavedType.value.drop_off_late_minutes.value,
            }
        )
        .then((response) => {
            toast.success("You successfully created the service type **" + unsavedType.value.name.value + "**");
            types.value.push(response.data.data);
            createOrEditModal.value.hide();
        })
        .catch((error) => {
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (!unsavedType.value[errorKey]) {
                    return
                }
                unsavedType.value[errorKey].errors = error.response.data.errors[errorKey]
            })
        })
        .finally(() => {
            storing.value = false;
        });
}

const editBookingServiceType = (type) => {
    initialiseData()

    unsavedType.value.uuid = type.uuid;
    unsavedType.value.booking_count = type.booking_count;
    unsavedType.value.name.value = type.name;
    unsavedType.value.default_booking_type.value = type.default_booking_type;
    unsavedType.value.pickup_early_minutes.value = type.pickup_early_minutes;
    unsavedType.value.pickup_late_minutes.value = type.pickup_late_minutes;
    unsavedType.value.drop_off_early_minutes.value = type.drop_off_early_minutes;
    unsavedType.value.drop_off_late_minutes.value = type.drop_off_late_minutes;
    unsavedType.value.is_active = type.is_active;
    unsavedType.value.can_be_deleted = type.can_be_deleted;

    fieldRefreshKey.value = Math.random();
    resetConfirmationQuestion();
    createOrEditModal.value.show();
};

const updating = ref(false)
const updateBookingServiceType = () => {
    updating.value = true;
    axios
        .put(
            route('api.booking-service-types.update', {booking_service_type: unsavedType.value.uuid}),
            {
                name: unsavedType.value.name.value,
                default_booking_type: unsavedType.value.default_booking_type.value,
                pickup_early_minutes: unsavedType.value.pickup_early_minutes.value,
                pickup_late_minutes: unsavedType.value.pickup_late_minutes.value,
                drop_off_early_minutes: unsavedType.value.drop_off_early_minutes.value,
                drop_off_late_minutes: unsavedType.value.drop_off_late_minutes.value,
            }
        )
        .then((response) => {
            toast.success("You successfully updated the service type **" + unsavedType.value.name.value + "**");
            types.value = types.value.map((type) => {
                if (type.uuid === unsavedType.value.uuid) {
                    return response.data.data;
                }
                return type;
            });
            createOrEditModal.value.hide();
        })
        .catch((error) => {
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (!unsavedType.value[errorKey]) {
                    return
                }
                unsavedType.value[errorKey].errors = error.response.data.errors[errorKey]
            })
        })
        .finally(() => {
            updating.value = false;
        });
};

const showDeleteConfirmation = ref(false);
const showDeactivateConfirmation = ref(false);
const confirmationQuestionShowing = ref(false);
const showActivateConfirmation = ref(false);

const deleting = ref(false);
const deleteServiceType = () => {
    deleting.value = true
    BookingServiceType
        .delete(unsavedType.value.uuid)
        .then((response) => {
            toast.success("You successfully deleted the service type **" + unsavedType.value.name.value + "**");
            types.value = types.value.filter((type) => type.uuid !== unsavedType.value.uuid);
            createOrEditModal.value.hide();
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        })
        .finally(() => {
            deleting.value = false;
        });
};

const showConfirmationQuestion = (type) => {
    confirmationQuestionShowing.value = true;
    switch (type) {
        case 'delete':
            showDeleteConfirmation.value = true;
            break;
        case 'deactivate':
            showDeactivateConfirmation.value = true;
            getBookingServiceTypeWithoutSelectedType();
            createOrEditModal.value.hide();
            deactivateConfirmationModal.value.show();
            break;
        case 'activate':
            showActivateConfirmation.value = true;
            break;
    }
}

const resetConfirmationQuestion = () => {
    confirmationQuestionShowing.value = false;
    showDeleteConfirmation.value = false;
    showDeactivateConfirmation.value = false;
    showActivateConfirmation.value = false;
}

const deactivateServiceType = () => {
    deactivateMoveLoading.value = true;
    BookingServiceType
        .deactivate(unsavedType.value.uuid, deactivateServiceTypeToMove.value)
        .then((response) => {
            toast.success("You successfully deactivated the service type **" + unsavedType.value.name.value + "**");
            const index = types.value.indexOf(types.value.find((type) => type.uuid === unsavedType.value.uuid));
            types.value[index].is_active = 0;
            deactivateMoveLoading.value = false;
            createOrEditModal.value.hide();
            deactivateConfirmationModal.value.hide();
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        });
}

const activateServiceType = () => {
    BookingServiceType
        .activate(unsavedType.value.uuid)
        .then((response) => {
            toast.success("You successfully activated the service type **" + unsavedType.value.name.value + "**");
            const index = types.value.indexOf(types.value.find((type) => type.uuid === unsavedType.value.uuid));
            types.value[index].is_active = 1;
            getBookingServiceTypeWithoutSelectedType();
            createOrEditModal.value.hide();
        })
        .catch((error) => {
            toast.error(error.response.data.message);
        });
}

const bookingServiceTypeWithoutSelectedType = ref([]);
const getBookingServiceTypeWithoutSelectedType = () => {
    bookingServiceTypeWithoutSelectedType.value = types.value.filter(type => unsavedType.value.uuid != type.uuid && type.is_active === 1);
}

onBeforeMount(() => {
    initialiseData();
});

onMounted(() => {
    getBookingServiceTypes()
    createOrEditModal.value = new bootstrap.Modal(document.getElementById('typeFormModal'));
    deactivateConfirmationModal.value = new bootstrap.Modal(document.getElementById('deactiavationConfirmation'));
});
</script>

<style scoped>

</style>
