<template>
    <div>
        <h4 class="header-title mt-0 mb-3">Vehicle Log</h4>
        <hr />
        <DataTable
            v-model:filters="filters"
            :datatable="datatable"
            :headers="headers"
            :loading="loading"
            :search="true"
            :default-sort="defaultSort"
        />
    </div>
</template>

<script setup>
    import { ref, reactive, watchEffect, inject } from 'vue'
    import DataTable from '../../DataTable.vue'
    import { onReady, odometerFilter } from '../../Utils'

    const axios = inject('axios')
    const toast = inject('toast')

    const { vehicle } = defineProps({
        vehicle: {
            required: true,
            type: Object,
        }
    })

    const datatable = reactive({
        meta: {
            from: null,
            to: null,
            total: null,
            lastPage: null,
        },
        data: {},
    })

    let filters = reactive({
        page: 1,
        perPage: 10,
        order: {
            column: 'date',
            direction: 'desc'
        }
    })

    const loading = ref(true)
    const mounted = ref(false)

    onReady(() => {
        mounted.value = true
    })

    const headers = [
        {
            class: '',
            column: 'date',
            label: 'Date',
            orderable: true,
        },
        {
            class: '',
            column: 'start_time',
            label: 'Start Time',
            orderable: true,
        },
        {
            class: '',
            column: 'end_time',
            label: 'Ending Time',
            orderable: true,
        },
        {
            class: '',
            column: 'driver',
            label: 'Driver',
            orderable: true,
        },
        {
            class: '',
            column: 'status',
            label: 'Status',
            orderable: true,
        },
        {
            class: '',
            column: 'start_odometer',
            label: 'Start Odometer',
            orderable: true,
        },
        {
            class: '',
            column: 'end_odometer',
            label: 'End Odometer',
            orderable: true,
        },
    ]

    const defaultSort = reactive({
        column: 'date',
        direction: 'desc'
    })

    watchEffect(() => {
        loading.value = true
        let params = {
            paginate: 1,
            page: filters.page,
            limit: filters.perPage,
        }

        if (filters.order.column || filters.order.column.length > 0) {
            params['order[column]'] = filters.order.column
            params['order[direction]'] = filters.order.direction
        }

        axios.get(route('api.vehicles.logs', { uuid: vehicle.uuid }, true), { params: params })
            .then((resp) => {
                let data = resp.data
                datatable.data = data.data.map((record) => fetchRow(record))
                datatable.meta = {
                    from: data.meta.from,
                    to: data.meta.to,
                    total: data.meta.total,
                    lastPage: data.meta.last_page,
                }
                filters.page = data.meta.current_page
            }).catch((err) => {
                console.error(err)
                toast.error('Sorry we were not able to fetch the Vehicle Logs', 'Oops Something Went Wrong!')
            }).finally(() => {
                loading.value = false
            })
    })

    const fetchRow = (record) => {
        return {
            date: {
                class: null,
                content: moment(record.start_time).format('D MMM YYYY')
            },
            start_time: {
                class: '',
                content: moment(record.start_time).format('HH:mm')
            },
            end_time: {
                class: '',
                content: moment(record.end_time).format('HH:mm')
            },
            driver_name: {
                class: '',
                content: record.driver_uuid ? `<a href="${route('drivers.show', { driver: record.driver_uuid }, true)}">${record.driver_name}</a>`: '',
                raw: true,
            },
            status: {
                class: '',
                content: record.status
            },
            start_odometer: {
                class: '',
                content: odometerFilter(record.start_odometer)
            },
            end_odometer: {
                class: '',
                content: odometerFilter(record.end_odometer)
            },
        }
    }

</script>
