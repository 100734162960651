<template>
    <div class="accordion-item">
        <div class="d-flex flex-column items-stretch py-2">
            <div>
                <div class="progress-bar rounded-sm" :class="{'px-0': (progress === 0)}" :style="'width:'+progress+'%'"></div>
            </div>
            <div class="d-flex mt-2">
                <div class="d-flex flex-grow-1 flex-column justify-content-between">
                    <div>
                        <div class="font-medium mr-3 text-700-gray leading-tight">
                            {{ upload.file.name }}
                        </div>
                        <div class="small mt-1">
                            {{ sizeDisplay }} MB
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column text-end">
                    <div class="text-gray-600 small">{{ progress }}%</div>
                    <div class="text-gray-600 small align-content-end">
                        <span v-if="state === states.WAITING">Waiting</span>
                        <span v-if="state === states.FAILED" class="text-danger">Failed</span>
                        <span v-if="state === states.CANCELLED" class="text-danger">Cancelled</span>
                        <span v-if="state === states.UNSUPPORTED" class="text-danger">Sorry, this file type is not supported.</span>
                        <span v-if="state === states.UPLOADING">
                            <a href="javascript:void(0)" @click.prevent="cancelUpload">Cancel</a>
                        </span>
                        <span v-if="state === states.COMPLETED">Complete</span>
                        <span v-if="state === states.TOO_LARGE" class="text-danger">File too large</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-2" v-if="errors.length > 0">
            <div @click="toggleErrors" class="d-flex justify-content-between">
                <h4 class="text-danger">{{ errors.length }} Errors</h4>
                <SvgIcon type="mdi" :path="showErrors ? mdiChevronUp : mdiChevronDown"></SvgIcon>
            </div>
            <div class="row" v-for="error in errors" v-if="showErrors">
                <div class="col-2 text-danger">
                    <span class="fw-bold">Row:</span> {{ error.row }}
                </div>
                <div class="col-10 text-danger">
                    <span v-for="message in error.errors">
                        <span class="fw-bold">Message:</span> {{ message }}<br />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "File"
}
</script>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import states from "../states.js";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const axios = inject('axios')
const state = ref(null)
const progress = ref(0)

const id = (Math.random() + 1).toString(36).substring(7);

const props = defineProps({
    upload: {
        required: true,
        type: Object
    },
    baseUrl: {
        required: true,
        type: String
    },
    endpoint: {
        required: true,
        type: String
    }
})

const emit = defineEmits(['progress', 'stateChange'])

const sizeDisplay = computed(() => (props.upload.file.size / 1000000).toFixed(2))

watch(() => props.upload.queued, (queued) => {
    if (state.value === states.UNSUPPORTED) {
        return
    }

    if (queued !== false) {
        return
    }

    startUpload()
})

watch(progress, (progress) => {
    emit('progress', {id: props.upload.id, progress})
})

watch(state, (state) => {
    emit('stateChange', {id: props.upload.id, state})

    if (state === states.CANCELLED || state === states.FAILED) {
        progress.value = 0
    }
})

const makeFormData = function (file) {
    const form = new FormData()
    form.append('attachment', file, file.name)
    return form
}

const handleUploadProgress = function (event) {
    progress.value = Math.round((event.loaded * 100) / event.total)
}

const axiosSource = axios.CancelToken.source()


const cancelUpload = function () {
    axiosSource.cancel()
}

const startUpload = function () {
    state.value = states.UPLOADING
    axios
        .post(props.endpoint, makeFormData(props.upload.file), {
            onUploadProgress: handleUploadProgress,
            cancelToken: axiosSource.token
        })
        .then((response) => {
            state.value = states.COMPLETED
            if (response.data.errors) {
                errors.value = response.data.errors;
            }
        })
        .catch((error) => {
            if (error instanceof axios.Cancel) {
                return state.value = states.CANCELLED;
            }

            if (error.response.status === 413) {
                return state.value = states.TOO_LARGE;
            }

            state.value = states.FAILED;
        })
}

const errors = ref([]);
const showErrors  = ref(false);

const toggleErrors = () => {
    showErrors.value = !showErrors.value;
}

const errorShowIcon = showErrors.value ? mdiChevronUp : mdiChevronDown

onMounted(() => {
    if (props.endpoint === null) {
        return state.value = states.UNSUPPORTED
    }

    state.value = states.WAITING
})

</script>

<style scoped>
.progress-bar {
    height: 2px;
}
</style>
