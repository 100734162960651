import axios from "axios";

export interface SeatTypeInterface {
    code: string;
    name: string;
    description: string;
    is_transferable: boolean;
    with_hoist: boolean;
}

export default class SeatType {
    static async getAll(): Promise<SeatTypeInterface[]> {
        let result: SeatTypeInterface[] = [];

        try {
            let response: any = await axios.get(
                route("api.seat-types")
            );

            result = response.data.data;
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }
}
