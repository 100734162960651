<template>
    <EditableSelect
        @updated="update"
        :value="client.indigenous_status"
        :options="indigenous_statuses"
        :loading="updating"
        :disabled="!editable"
    >
        {{ client.indigenous_status_description }}
    </EditableSelect>
</template>

<script>
export default {
    name: "EditClientIndigenousStatus"
}
</script>
<script setup>
import axios from "axios";
import {ref, onBeforeMount, computed} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const indigenous_statuses = ref({
    not_stated: 'Not stated/inadequately described',
    neither: 'Neither Aboriginal nor Torres Strait Islander',
    aboriginal: 'Aboriginal',
    torres_straight_islander: 'Torres Strait Islander',
    aboriginal_and_torres_straight_islander: 'Both Aboriginal & Torres Strait Islander',
})

const errors = ref({})
const updating = ref(false)
const update = function (newIndigenousStatus) {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            indigenous_status: newIndigenousStatus
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
