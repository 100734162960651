<template>
    <div
        class="card"
        @mouseover="showEditButton = true"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body row">
            <template v-if="!showEditForm">
                <div class="col-md-6">
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title my-0 mb-3">Transport Requirements</h4>
                    </div>
                    <div>
                        <b>{{ chosenSeatDetail?.name}}</b>
                    </div>
                    <div class="mt-4">
                        <h4 class="header-title my-0 mb-3">Vehicle</h4>
                        <template v-for="(groupVal, group) in chosenVehicle">
                            <div v-if="groupVal.vehicles.length > 0" class="border border-light p-2 mb-2">
                                <b>{{bookingRequirementStore.getGroupDetail(group)?.name}}</b>
                                <div class="row">
                                    <div v-for="vehicleType in groupVal.vehicles" class="col-md-6">
                                        {{ bookingRequirementStore.getVehicleTypeDetail(vehicleType)?.description }}
                                    </div>
                                </div>
                                <div class="mt-2"><b>Seat Options</b></div>
                                <div class="row">
                                    <div v-for="seatPosition in groupVal.seat_positions" class="col-md-6">
                                        {{ bookingRequirementStore.getSeatPositionDetail(seatPosition)?.label }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title my-0 mb-3">Assistance Requirements</h4>
                        <div v-if="showEditButton && !showEditForm">
                            <Button @click="switchToEditForm()" color="link" size="small">
                                <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                                Edit
                            </Button>
                        </div>
                    </div>
                    <div v-for="assistanceType in selectedAssistanceTypes" :set="assistanceTypeDetail = bookingRequirementStore.getAssistanceTypeDetail(assistanceType.code)">
                        <span
                            v-tooltip="assistanceTypeDetail?.description"
                            class="ms-1 mb-2"
                        >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        /></span> {{ assistanceTypeDetail?.label }}
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title mt-4 mb-3">Cargo Requirements</h4>
                    </div>
                    <div v-for="cargoSpace in selectedCargoSpaces" :set="cargoSpaceDetail = bookingRequirementStore.getCargoSpaceDetail(cargoSpace.code)">
                        <span
                            v-tooltip="cargoSpaceDetail?.description"
                            class="ms-1 mb-2"
                        >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        /></span> {{ cargoSpaceDetail?.name }}
                    </div>
                </div>
            </template>
            <template v-if="showEditForm">
                <div class="col-md-6">
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title my-0 mb-3">Transport Requirements</h4>
                    </div>
                    <div>
                        Does {{props.requirementFor}} have wheelchair?
                        <div>
                            <div v-for="seat in seatTypeOptions">
                                <label>
                                    <input type="radio" v-model="chosenSeat" :value="seat.code"> {{ seat.code === 'standard_seat' ? 'No' : seat.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="chosenSeat" class="mt-4">
                        <h4 class="header-title my-0 mb-3">Vehicle</h4>
                        <div class="mb-2">Select suitable vehicles and type of seats to meet the client's requirement</div>
                        <div v-for="vehicleGroup in getSeatVehicleGroups()" :set="isDisabled = seatIsDisabled(vehicleGroup)" class="border border-light p-2 mb-2">
                            <div class="row mb-2">
                                <b class="col-9">{{vehicleGroup.name}}</b><a href="javascript:void(0);" class="col-3" @click="selectAllVehiclesInGroup(vehicleGroup)">Select All</a>
                            </div>
                            <div class="row mb-2">
                                <label v-for="vehicleType in getSeatAvailableVehiclesTypeInGroup(vehicleGroup)" class="col-md-6">
                                    <input type="checkbox" v-model="chosenVehicle[vehicleGroup.code].vehicles" :value="vehicleType.code"> {{ vehicleType.description }}
                                </label>
                            </div>
                            <div class="row mb-2">
                                <b class="">Seat Options</b>
                                <div v-if="isDisabled">(Please select vehicle to select position)</div>
                                <div v-else-if="seatIsEmpty(vehicleGroup)">
                                    (Please select one of the seat)
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="seatRequireHoist()">
                                    <div class="col-md-12">
                                        Track Required
                                    </div>
                                </template>
                                <template v-if="seatIsTransferable()">
                                    <label v-for="(positionCode) in vehicleGroup.available_seat_positions" class="col-md-6">
                                        <input type="checkbox" v-model="chosenVehicle[vehicleGroup.code].seat_positions" :value="positionCode" :disabled="isDisabled">
                                        {{ (bookingRequirementStore.getSeatPositionDetail(positionCode))?.label }}
                                    </label>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4 class="header-title my-0 mb-3">Assistance Requirements</h4>
                    <div class="mb-2">Please select the type of assistance required. Leave blank if not required.</div>
                    <div>
                        <div v-if="Object.keys(selectedAssistanceTypes).length > 0" >
                            <div
                                v-for="(value, index) in selectedAssistanceTypes"
                                :key="index"
                                class="config-row align-items-center"
                                :set="assistanceDetailInfo = getAssistanceDetailInfo(value.code)"
                            >
                                <div class="col-11 config-col align-self-center">
                                    <span>{{ assistanceDetailInfo?.label }}</span>
                                    <span
                                        v-tooltip="assistanceDetailInfo?.description"
                                        class="ms-1 mb-2"
                                    >
                                        <SvgIcon
                                            type="mdi"
                                            :path="mdiInformationOutline"
                                            :size="20"
                                            class="text-info"
                                        />
                                    </span>
                                </div>

                                <div class="col-1 config-col" v-if="!value.seat_type">
                                    <button
                                        @click="() => removeAssistanceType(value.code)"
                                        class="h-100 w-100"
                                        v-tooltip="'Remove Item'"
                                    >
                                        <SvgIcon
                                            type="mdi"
                                            :path="mdiCloseCircleOutline"
                                            class="text-danger"
                                        ></SvgIcon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="config-row">
                            <div class="col-11 config-col">
                                <select v-model="assistanceTypeSelected" class="form-select">
                                    <option value="null" disabled>
                                        Please select an assistance type to add
                                    </option>
                                    <option
                                        v-for="(option, index) in filteredAssistanceTypeOptions"
                                        :value="option.value"
                                        :key="index"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 config-col justify-content-center">
                                <button
                                    @click="addAssistanceType"
                                    class="h-100 w-100"
                                    :disable="!assistanceTypeSelected"
                                    v-tooltip="'Add Assistance Type'"
                                >
                                    <SvgIcon
                                        type="mdi"
                                        :path="mdiPlusCircle"
                                        class="text-primary"
                                        size="30"
                                    ></SvgIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <h4 class="header-title mt-4 mb-3">Cargo Requirements</h4>
                    <div class="mb-2">Please select the mobility aid that will be carried on. Leave blank if none.</div>
                    <div>
                        <div v-if="selectedCargoSpaces.length > 0">
                            <div
                                v-for="(detail, index) in selectedCargoSpaces"
                                :key="index"
                                class="config-row align-items-center"
                                :set="cargoDetail = getCargoDetailInfo(detail.code)"
                            >
                                <div class="col-11 config-col align-self-center">
                                    <span>{{ cargoDetail.name }}</span>
                                    <span
                                        v-tooltip="cargoDetail.description"
                                        class="ms-1 mb-2"
                                    >
                                        <SvgIcon
                                            type="mdi"
                                            :path="mdiInformationOutline"
                                            :size="20"
                                            class="text-info"
                                        />
                                    </span>
                                </div>
                                <div class="col-1 config-col" v-if="!detail.seat_type">
                                    <button
                                        @click="() => removeCargoSpace(index)"
                                        class="h-100 w-100"
                                        v-tooltip="'Remove Item'"
                                    >
                                        <SvgIcon
                                            type="mdi"
                                            :path="mdiCloseCircleOutline"
                                            class="text-danger"
                                        ></SvgIcon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="config-row">
                            <div class="col-11 config-col">
                                <select v-model="cargoSpaceSelected" class="form-select">
                                    <option value="null" disabled>
                                        Please select a cargo type to add
                                    </option>
                                    <option
                                        v-for="(option, index) in filteredCargoSpaceOptions"
                                        :value="option.code"
                                        :key="index"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 config-col justify-content-center">
                                <button
                                    @click="addCargoSpace"
                                    class="h-100 w-100"
                                    :disable="!cargoSpaceSelected"
                                    v-tooltip="'Add Selected Cargo'"
                                >
                                    <SvgIcon
                                        type="mdi"
                                        :path="mdiPlusCircle"
                                        class="text-primary"
                                        size="30"
                                    ></SvgIcon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                    <Button size="small" :loading="updating" :disabled="saveIsDisabled()" @click="saving">Save</Button>
                    <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref, onBeforeMount, inject, watch, computed, toRef} from "vue";
import Button from "../Button.vue";
import {mdiPencil, mdiInformationOutline, mdiCloseCircleOutline, mdiPlusCircle} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon"
import { TransportRequirementsForm } from '../AccessRequirements'
import AssistanceType, {
    AssistanceTypeDetailsObjectInterface,
    AssistanceTypeOptionsInterface
} from "@classes/AssistanceType";
import {BookingRequirementPayloadInterface, useBookingRequirementStore} from "@stores/BookingRequirementStore";
import CargoSpace, {CargoSpaceFormDetailsInterface, CargoSpaceOptionsInterface} from "@classes/CargoSpace";
import {storeToRefs} from "pinia";
import VehicleGroup, {VehicleGroupInterface} from "@classes/VehicleGroup";
import SeatType, {SeatTypeInterface} from "@classes/SeatType";
import CargoRequirementForm from "@components/AccessRequirements/CargoRequirementForm.vue";
import AssistanceTypeRequirementForm from "@components/AccessRequirements/AssistanceTypeRequirementForm.vue";
import {cloneDeep} from "lodash";

const axios = inject('axios');
const toast = inject('toast');
const bookingRequirementStore = useBookingRequirementStore();

const props = withDefaults(
    defineProps<{
        bookingRequirements: Object;
    }>(),
    {
    }
);

const selectedAssistanceTypes = ref<AssistanceTypeDetailsObjectInterface[]>([]);
const selectedCargoSpaces = ref<CargoSpaceFormDetailsInterface[]>([]);
const chosenSeat = ref('transfer_manual_wheelchair');
const chosenVehicle = ref({});

const {
    seatTypeOptions,
    seatPositionList,
    vehicleGroupOptions,
    assistanceTypeOptions,
    cargoSpaceOptions
} = storeToRefs(bookingRequirementStore);

const emit = defineEmits(['saving', 'updated']);
const showEditButton = ref(false);
const showEditForm = ref(false);
const cargoSpaceSelected = ref<null | string>(null);
const assistanceTypeSelected = ref<null | string>(null);
const originalChosenSeat = ref('');
const originalChosenVehicle = ref({});
const originalSelectedAssistanceType = ref<AssistanceTypeDetailsObjectInterface[]>([]);
const originalSelectedCargoSpace = ref<CargoSpaceFormDetailsInterface[]>([]);

const filteredCargoSpaceOptions = computed(() => {
    return cargoSpaceOptions.value.filter(
        (cargo) => {
            return !selectedCargoSpaces.value.some(selectedCargo => selectedCargo.code === cargo.code);
        }
    );
});

const filteredAssistanceTypeOptions = computed(() => {
    return assistanceTypeOptions.value.filter(
        (assistance) => {
            return !selectedAssistanceTypes.value.some(selectedAssistance => selectedAssistance.code === assistance.value);
        }
    );
});


const cancelChanges = function () {
    cancelToBeforeSaveData();

    showEditForm.value = false;
}

const chosenSeatDetail = computed(() => {
    return seatTypeOptions.value.find(sto => sto.code === chosenSeat.value);
})

const updating = ref(false)

const convertToPayload = (): BookingRequirementPayloadInterface => {
    const chosenVehicleTypes: any[] = [];

    for (const vehicleGroup in chosenVehicle.value) {
        const vehicleType = chosenVehicle.value[vehicleGroup];

        if (vehicleType.vehicles.length > 0) {
            vehicleType.vehicles.forEach((trCode, index) => {
                chosenVehicleTypes.push({
                    code: trCode,
                    value: 1,
                    chosen_seat_positions: [...vehicleType.seat_positions],
                    group: vehicleGroup
                })
            });
        }
    }

    return {
        chosen_seat: chosenSeat.value,
        vehicle_types: [...chosenVehicleTypes],
        assistance_types: [...selectedAssistanceTypes.value],
        cargo_spaces: [...selectedCargoSpaces.value]
    }
}

const saving = () => {
    const payload = convertToPayload();
    emit('saving', payload);
    showEditForm.value = false;
}

const getSeatTypeOption = async () => {
    bookingRequirementStore.setSeatTypeOptions(await SeatType.getAll());
}

const getVehicleGroupOption = () => {
    VehicleGroup.getAll().then((response) => {
        bookingRequirementStore.setVehicleGroupOptions(response.data.data);
    });
}

const getAssistanceTypeOption = async () => {
    bookingRequirementStore.setAssistanceTypeOptions(await AssistanceType.options());
}

const getCargoOption = async () => {
    bookingRequirementStore.setCargoSpaceOptions(await CargoSpace.options());
}

const convertFromPayload = (bookingRequirementObj: BookingRequirementPayloadInterface) => {
    chosenSeat.value = bookingRequirementObj.chosen_seat;

    if (bookingRequirementObj.vehicle_types) {
        chosenVehicle.value = bookingRequirementStore.convertVehicleTypesFromPayload(bookingRequirementObj.vehicle_types);
    }

    const assistanceTypes: AssistanceTypeDetailsObjectInterface[] = [];
    if (bookingRequirementObj.assistance_types !== undefined) {
        bookingRequirementObj.assistance_types.forEach((at: AssistanceTypeDetailsObjectInterface) => {
            const atDetail = getAssistanceDetailInfo(at.code);
            if (atDetail) {
                if (atDetail.seat_types.some(atSeatType => atSeatType.code === chosenSeat.value)) {
                    at.seat_type = chosenSeat.value;
                }

                assistanceTypes.push(at);
            }
        });
    }

    selectedAssistanceTypes.value = assistanceTypes;

    const cargoSpaces: CargoSpaceFormDetailsInterface[] = [];
    if (bookingRequirementObj.cargo_spaces !== undefined) {
        selectedCargoSpaces.value = bookingRequirementObj.cargo_spaces;

        bookingRequirementObj.cargo_spaces.forEach((cs: CargoSpaceFormDetailsInterface) => {
            const cargoSpaceDetail = getCargoDetailInfo(cs.code);
            if (cargoSpaceDetail) {
                if (cargoSpaceDetail.seat_types.some(csSeatType => csSeatType.code === chosenSeat.value)) {
                    cs.seat_type = chosenSeat.value;
                }

                cargoSpaces.push(cs);
            }
        });
    }

    selectedCargoSpaces.value = cargoSpaces;
}

const seatRequireHoist = () => {
    return seatTypeOptions.value.some(sto => sto.code === chosenSeat.value && sto.require_hoist);
}

const seatIsTransferable = () => {
    return seatTypeOptions.value.some(sto => sto.code === chosenSeat.value && sto.is_transferable);
}

const getSeatVehicleGroups = () => {
    return vehicleGroupOptions.value.filter((option) => {
        if (option.code === 'standard' &&
            seatRequireHoist()
        ) {
            return false;
        }

        return true;
    });
}

const getSeatAvailableVehiclesTypeInGroup = (vehicleGroup: VehicleGroupInterface) => {
    return vehicleGroup.vehicle_types.filter((vehicleType) => {
        if (!seatIsTransferable() && vehicleType.with_hoist === false) {
            return false;
        }

        if (seatRequireHoist() && vehicleType.with_hoist === false) {
            return false;
        }

        return true;
    });
}

const addCargoSpace = () => {
    let option = cargoSpaceOptions.value.find((option) => {
        return option.code === cargoSpaceSelected.value;
    });

    if (!option) {
        return;
    }

    let cargo = {
        code: option.code,
        space: option.space_required,
        removable: true
    };

    selectedCargoSpaces.value.push(cargo);

    cargoSpaceSelected.value = null;

    bookingRequirementStore.filterOutSelectedCargo();

    return new MouseEvent("");
};

const removeCargoSpace = (index: number) => {
    selectedCargoSpaces.value.splice(index, 1);

    return new MouseEvent("");
};

const getCargoDetailInfo = (code: string) => {
    let result = cargoSpaceOptions.value.filter((option) => {
        return option.code === code;
    })[0];

    return result;
};

const addAssistanceType = () => {
    let option = filteredAssistanceTypeOptions.value.find((option) => {
        return option.value === assistanceTypeSelected.value;
    });

    if (!option) {
        return;
    }

    selectedAssistanceTypes.value.push({
        code: option.value,
        removable: true
    });

    bookingRequirementStore.filterOutSelectedAssistanceType();

    return new MouseEvent("");
};

const removeAssistanceType = (key: string) => {
    selectedAssistanceTypes.value = selectedAssistanceTypes.value.filter((value) => {
        return value.code !== key;
    });

    bookingRequirementStore.filterOutSelectedAssistanceType();

    return new MouseEvent("");
};

const getAssistanceDetailInfo = (value: string) => {
    let result = assistanceTypeOptions.value.find((option) => {
        return option.value === value;
    });

    return result;
};

const syncSeatAssistance = () => {
    const nonRemovableAssistanceType = assistanceTypeOptions.value.filter(
        (assistanceType) => {
            return assistanceType.seat_types.some(assistanceST => assistanceST.code === chosenSeat.value);
        }
    );

    selectedAssistanceTypes.value = nonRemovableAssistanceType.map((at) => {
        return {
            code: at.value,
            seat_type: chosenSeat.value
        }
    });
}

const syncSeatWithCargo = () => {
    const nonRemovableCargo = cargoSpaceOptions.value.filter(
        (cargo) => {
            return cargo.seat_types.some(cargoST => cargoST.code === chosenSeat.value);
        }
    );

    selectedCargoSpaces.value = nonRemovableCargo.map((ca) => {
        return {
            code: ca.code,
            space: ca.space_required,
            seat_type: chosenSeat.value
        }
    });
}

const seatIsDisabled = (vehicleGroup) => {
    const vehicleChosen = chosenVehicle.value[vehicleGroup.code]?.vehicles.length > 0;

    return !vehicleChosen;
}

const seatIsEmpty = (vehicleGroup) => {
    const vehicleChosen = chosenVehicle.value[vehicleGroup.code].vehicles.length > 0;

    if (vehicleChosen &&
        chosenSeat.value !== 'non_transfer_manual_wheelchair' &&
        chosenSeat.value !== 'ewheelchair'
    ) {
        return !(chosenVehicle.value[vehicleGroup.code].seat_positions.length > 0);
    }

    return false;
}

const selectAllVehiclesInGroup = (vehicleGroup) => {
    const vehicles = bookingRequirementStore.getSeatAvailableVehiclesTypeInGroup(vehicleGroup);

    chosenVehicle.value[vehicleGroup.code].vehicles = vehicles.map((vehicle) => {
        return vehicle.code;
    });
}

const switchToEditForm = () => {
    fillBeforeSaveData();
    showEditForm.value = true;
}

const fillBeforeSaveData = () => {
    originalChosenSeat.value = cloneDeep(chosenSeat.value);
    originalChosenVehicle.value = cloneDeep(chosenVehicle.value);
    originalSelectedAssistanceType.value =  cloneDeep(selectedAssistanceTypes.value);
    originalSelectedCargoSpace.value = cloneDeep(selectedCargoSpaces.value);
}

const cancelToBeforeSaveData = () => {
    chosenSeat.value = cloneDeep(originalChosenSeat.value);
    chosenVehicle.value = cloneDeep(originalChosenVehicle.value);
    selectedAssistanceTypes.value = cloneDeep(originalSelectedAssistanceType.value);
    selectedCargoSpaces.value = cloneDeep(originalSelectedCargoSpace.value);
}

const saveIsDisabled = () => {
    if (!chosenSeat.value) {
        return true;
    }

    if (Object.keys(chosenVehicle.value).length < 1) {
        return true;
    }

    let missingVehicles = true;
    // if 1 of the group has vehicle then vehicle not missing
    for (const vehicleGroup in chosenVehicle.value) {
        if (
            chosenVehicle.value[vehicleGroup].vehicles.length > 0
        ) {
            missingVehicles = false;
        }
    }

    if (missingVehicles) {
        return true;
    }

    if (
        chosenSeat.value === 'non_transfer_manual_wheelchair' ||
        chosenSeat.value === 'ewheelchair'
    ) {
        return false;
    }

    let missingSeats = false;
    for (const vehicleGroup in chosenVehicle.value) {
        if (
            chosenVehicle.value[vehicleGroup].vehicles.length > 0 &&
            chosenVehicle.value[vehicleGroup].seat_positions.length < 1
        ) {
            missingSeats = true;
        }
    }

    if (missingSeats) {
        return true;
    }

    return false;
}

onBeforeMount(() => {
    getVehicleGroupOption();
    bookingRequirementStore.resetSeat();
    getSeatTypeOption();
    getAssistanceTypeOption();
    getCargoOption();
});

watch(props, (newProps) => {
    convertFromPayload(newProps.bookingRequirements);
})

watch(chosenSeat, (newSeat) => {
    if (showEditForm.value === true) {
        chosenVehicle.value = bookingRequirementStore.defaultVehicleByGroup();
        syncSeatAssistance();
        syncSeatWithCargo();
    }
})


</script>

<style scoped>
.config-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}
.config-col {
    padding: 10px 5px;
    justify-content: center;
}
</style>
