<template>
    <div class="d-flex flex-row align-items-center">
        <template v-if="isPlannedTimeOverwritten">
            <strong>
                <span
                    class="text-danger"
                    style="text-decoration: line-through"
                    >{{
                        type == "origin"
                            ? originalBookingDatetime
                                  ?.plannedOriginTime()
                                  ?.toFormat("HH:mm")
                            : originalBookingDatetime
                                  ?.plannedDestinationTime()
                                  ?.toFormat("HH:mm")
                    }}</span
                >
            </strong>
            <SvgIcon
                :path="mdiArrowRightBold"
                class="m-1 text-danger"
                :size="12"
                type="mdi"
            />
        </template>

        <template
            v-if="
                type == 'origin'
                    ? props.booking.planned_origin_time
                    : props.booking.planned_destination_time
            "
        >
            <strong :class="{ 'text-success': isPlannedTimeOverwritten }">
                <EditableInput
                    :label="label"
                    :value="originalValue"
                    type="time"
                    @updated="updatePlannedTime"
                />
            </strong>
        </template>
        <template v-else>
            <em v-tooltip="'Estimated time'" class="muted">
                <EditableInput
                    :label="label"
                    :value="originalValue"
                    type="time"
                    @updated="updatePlannedTime"
                />
            </em>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, inject } from "vue";
import { formatDate, toDateTimeInput } from "../Utils";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";
import { toTimeStamp } from "../Utils";
import { DateTime } from "luxon";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import BookingDatetime from "@classes/DateHelpers/BookingDatetime";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiArrowRightBold } from "@mdi/js";
import DatetimeHelper from "../../classes/DateHelpers/DatetimeHelper";

const allocationStore = useAllocationStore();

const toast: any = inject("toast");

const { bookingTimeValidationErrors, originalBookings } =
    storeToRefs(allocationStore);

const props = withDefaults(
    defineProps<{
        booking: App.Models.Booking;
        type?: "origin" | "destination";
    }>(),
    {
        type: "origin",
    }
);

const originalValue = computed(() => {
    const bookingDateTime = new BookingDatetime(props.booking);
    if (props.type === "origin") {
        return DatetimeHelper.toDateTimeInput(
            bookingDateTime.displayableOriginTime() ?? DateTime.now(),
            "time"
        );
    } else {
        return DatetimeHelper.toDateTimeInput(
            bookingDateTime.displayableDestinationTime() ?? DateTime.now(),
            "time"
        );
    }
});

const label = computed(() => {
    if (props.type === "origin") {
        return formatDate(
            props.booking.planned_origin_time ??
                props.booking.estimated_origin_time,
            "short time"
        );
    } else {
        return formatDate(
            props.booking.planned_destination_time ??
                props.booking.estimated_destination_time,
            "short time"
        );
    }
});

const updatePlannedTime = (newValue: string) => {
    // Parse the string time to create a Luxon DateTime object
    const parsedTime = DateTime.fromFormat(newValue, "HH:mm");

    const bookingDateTime = new BookingDatetime(props.booking);
    const displayableOriginTime = bookingDateTime.displayableOriginTime();
    const displayableDestinationTime =
        bookingDateTime.displayableDestinationTime();

    // Create a new Luxon date with the same date and the specified time
    const datetimeValue = (
        (props.type == "destination"
            ? displayableDestinationTime
            : displayableOriginTime) ?? DateTime.now()
    ).set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
    });

    if (props.type == "destination" && displayableOriginTime) {
        if (datetimeValue < displayableOriginTime) {
            toast.error("Destination time cannot be before origin time");
            return;
        }
    }

    if (props.type == "origin" && displayableDestinationTime) {
        if (datetimeValue > displayableDestinationTime) {
            toast.error("Origin time cannot be after destination time");
            return;
        }
    }

    if (
        DateTime.fromISO(originalValue.value).toFormat("HH:mm") ==
        datetimeValue.toFormat("HH:mm")
    ) {
        return;
    }

    setPreview(datetimeValue.toISO());
};

const setPreview = (newValue: string) => {
    if (props.type === "origin") {
        props.booking.planned_origin_time = newValue;
    } else {
        props.booking.planned_destination_time = newValue;
    }
};

const originalBooking = computed(() => {
    return originalBookings.value.find(
        (booking) => booking.uuid === props.booking.uuid
    );
});

const originalBookingDatetime = computed(() => {
    return originalBooking.value
        ? new BookingDatetime(originalBooking.value)
        : null;
});

const currentBookingDatetime = computed(() => {
    return props.booking ? new BookingDatetime(props.booking) : null;
});

const isPlannedOriginTimeOverwritten = computed(() => {
    return (
        originalBookingDatetime.value?.plannedOriginTime() !== null &&
        currentBookingDatetime.value?.plannedOriginTime() !== null &&
        originalBookingDatetime.value
            ?.plannedOriginTime()
            ?.toFormat("HH:mm") !==
            currentBookingDatetime.value?.plannedOriginTime()?.toFormat("HH:mm")
    );
});

const isPlannedDestinationTimeOverwritten = computed(() => {
    return (
        originalBookingDatetime.value?.plannedDestinationTime() !== null &&
        currentBookingDatetime.value?.plannedDestinationTime() !== null &&
        originalBookingDatetime.value
            ?.plannedDestinationTime()
            ?.toFormat("HH:mm") !==
            currentBookingDatetime.value
                ?.plannedDestinationTime()
                ?.toFormat("HH:mm")
    );
});

const isPlannedTimeOverwritten = computed(() => {
    return props.type === "origin"
        ? isPlannedOriginTimeOverwritten.value
        : isPlannedDestinationTimeOverwritten.value;
});
</script>
