<template>
    <div class="row">
        <transition name="fade">
            <div class="col-md-4">
                <h4>Booking Details</h4>
                <p>Add the general details about this booking, and specify additional stops.</p>
            </div>
        </transition>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <h4 class="header-title mt-0 mb-3">Booking Details</h4>

                    <div>
                        <BookingFormStopLocationSelect
                            label="Initial pick up address"
                            placeholder="Search pickup address"
                            :stopIndex="0"
                        />
                    </div>

                    <div
                        v-for="(stops, stopIndex) in bookingStore.draft.stops"
                        v-bind:key="stopIndex"
                    >
                        <BookingFormStop
                            v-if="stopIndex > 0"
                            :stopIndex="stopIndex"
                        ></BookingFormStop>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import Button from "../../components/Button.vue";
import { mdiPlusCircle } from "@mdi/js";
import BookingFormStop from "./BookingFormStop.vue";
import BookingFormStopLocationSelect from "./BookingStop/BookingFormStopLocationSelect.vue";

const bookingStore = useBookingStore();
</script>
