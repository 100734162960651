<template>
    <div v-if="journeyLoaded">
        <VerificationJourneyDetails
            :key="journeyStatistic.uuid + componentId + '_details'"
            :journey="journeyDetail(journeyStatistic.uuid)"
            :drivers="drivers"
        ></VerificationJourneyDetails>
        <VerificationBookingTable
            :key="journeyStatistic.uuid + componentId + '_table'"
            :journey="filteredJourneyDetail(journeyStatistic.uuid)"
            class="mt-3"
            @bookingReallocated="fetchJourneyDetails"
        ></VerificationBookingTable>
        <VerificationBookingBreaksTable
            :key="journeyStatistic.uuid + componentId + '_breaks_table'"
            :journey="journeyDetail(journeyStatistic.uuid)"
        ></VerificationBookingBreaksTable>
    </div>
    <div v-else class="d-flex w-100 justify-content-center align-items-center ps-3">
        <div class="spinner-grow my-5 me-3" role="status"></div>
        <div class="fs-4">Loading Vehicle Run</div>
    </div>
</template>

<script setup lang="ts">
import VerificationBookingTable from "@components/Verifications/VerificationBookingTable.vue";
import VerificationBookingBreaksTable from "@components/Verifications/VerificationBookingBreaksTable.vue";
import VerificationJourneyDetails from "@components/Verifications/VerificationJourneyDetails.vue";
import {inject, onBeforeMount, onMounted, ref, watch} from "vue";
import {uuid} from "vue-uuid";
import {storeToRefs} from "pinia";
import {useVerificationStore} from "@stores/VerificationStore";
const axios = inject('axios');

const props = withDefaults(
    defineProps<{
        journeyStatistic: {};
        drivers: [];
        queryString: string;
    }>(),
    {}
);

const componentId = ref(uuid.v4());
const journeyLoaded = ref(false);
const verificationStore = useVerificationStore();
const { journeys, journeysNoDetail, searchBookingResult } = storeToRefs(verificationStore);

const journeyDetail = (uuid) => {
    return journeys.value.find(j => j.uuid === uuid);
}

const filteredJourneyDetail = (uuid) => {
    const thisJourneyDetail = journeyDetail(uuid);
    if (thisJourneyDetail !== undefined && props.queryString) {
        thisJourneyDetail.bookings = searchBookingResult.value.filter(booking => booking.journey?.uuid === uuid);
    }

    return thisJourneyDetail;
}

const fetchJourneyDetails = () => {
    journeyLoaded.value = false;

    axios.get(route('api.journeys.verification-bulk-details',{
        uuids: [props.journeyStatistic.uuid]
    })).then((response) => {
        const journeySet = journeys.value.filter(j => j.uuid !== props.journeyStatistic.uuid);

        journeys.value = [...journeySet, ...response.data.data];
        journeyLoaded.value = true;
    });
}

onBeforeMount(() => {
    fetchJourneyDetails();
})

</script>

<style scoped>

</style>
