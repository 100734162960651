<script lang="ts" setup>
import {defineComponent, onMounted, ref, toRefs} from 'vue'
import VueMultiselect from "vue-multiselect";
import ReferralResource from "@customTypes/resources/ReferralsResource";
import Referral from "@classes/Referral";

defineComponent({
  name: "ReferralFormInput",
  components: {VueMultiselect}
})
const props       = defineProps<{
  referral: ReferralResource,
}>();
const { referral } = toRefs(props);
const emit = defineEmits(['update:referral'])

const referralOptions = ref<ReferralResource[]>([]);
const value = ref(referral.value.pivot.value);
const updateReferral = (value:any) => {
    referral.value.pivot.value = value.id;
    emit('update:referral', referral);
}

const getAvailableReferralOptions = async () => {
    referralOptions.value = await Referral.getAvailableOptionsFor(referral.value);
}

onMounted(() => {
    getAvailableReferralOptions();
})


</script>

<template>
  <input type="text" v-model="referral.pivot.value" v-if="referral.type === 'text'" class="form-control" />
  <VueMultiselect :options="referralOptions" v-model="referral.value" track-by="id" label="name" v-if="referral.type === 'select'" @select="updateReferral"></VueMultiselect>
</template>
