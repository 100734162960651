<template>
    <div class="text-center">
        <SvgIcon
            type="mdi"
            :path="mdiCarSelect"
            class="text-secondary h-10 w-10 my-3"
        ></SvgIcon>
        <p>
            There are no unallocated bookings scheduled for the

            <DateTime :date-time="date" :format="'full date'"></DateTime>
            <template v-if="selectedJourney && selectedJourney.region">
                in the region "{{ selectedJourney.region.name }}".
            </template>
        </p>

        <p class="mt-2">
            You can unallocate a booking from the allocated row on the right by
            dragging and dropping it into the unallocated list below.
        </p>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCarSelect } from "@mdi/js";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
const allocationStore = useAllocationStore();
const { selectedJourney, date } = storeToRefs(allocationStore);
import DateTime from "../../DateTime.vue";
</script>
