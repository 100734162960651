<template>
    <button class="btn btn-primary" @click="createLocationTypeModal.show()">
        <i class="mdi mdi-plus-circle me-1"></i>
        Add Location Type
    </button>
    <div
        id="createLocationTypeModal"
        class="modal fade"
        aria-hidden="true"
        aria-labelledby="createLocationTypeModalLabel"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="createLocationTypeModalLabel" class="modal-title">Create Location Type</h5>
                    <button aria-label="Close" class="btn-close" @click="closeModal" type="button"></button>
                </div>
                <div class="modal-body">
                    <LocationTypeEditOrCreateForm v-model="newLocationType"/>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-light" @click="closeModal" type="button">Close</button>
                    <Button :loading="storing" @click="storeFundingType">Create Location Type</Button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "CreateLocationType"
}
</script>
<script setup>
import {onMounted, ref, inject} from "vue";
import {uuid} from "vue-uuid";
import Button from "../Button.vue";
import LocationTypeEditOrCreateForm from "./LocationTypeEditOrCreateForm.vue";

const toast = inject('toast')
const axios = inject('axios')

const createLocationTypeModal = ref(null)
const newLocationType = ref({})

const emit = defineEmits(['created'])

const initFields = function () {
    newLocationType.value = {
        description: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
    }
}
initFields()

const closeModal = () => {
    createLocationTypeModal.value.hide()
    initFields()
}

onMounted(() => {
    const modal = document.getElementById('createLocationTypeModal');
    createLocationTypeModal.value = new bootstrap.Modal(modal)
    modal.addEventListener('hidden.bs.modal', function (event) {
        initFields()
    })
})

const storing = ref(false)
const storeFundingType = function () {
    storing.value = true

    axios
        .post(route('api.location-types.store'), {
            description: newLocationType.value.description.value,
        })
        .then(function (response) {
            createLocationTypeModal.value.hide()
            emit('created', response.data)
            initFields()
        })
        .catch(function (error) {
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                newLocationType.value[field].errors = errors
            }
        })
        .then(function () {
            storing.value = false
        })
}

</script>