<template>
    <div class="d-flex w-100 justify-content-between mb-2">
        <CreateFundingType
            v-if="permissions.includes('create_funding_type')"
            @created="addFundingType"
        />
    </div>
    <div class="card">
        <div class="card-body">
            <div
                v-if="loading"
                class="d-flex w-100 justify-content-center align-items-center ps-3"
            >
                <div class="spinner-grow my-5 me-3" role="status"></div>
                <div class="fs-4">Loading Funding Types</div>
            </div>
            <template v-if="!loading">
                <div v-if="fundingTypes.length" class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label text-nowrap">
                            Display
                            <select
                                v-model="perPage"
                                @change="getFundingTypes"
                                class="form-select form-select-sm d-inline mx-1"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option :value="paginationMeta.total">
                                    All
                                </option>
                            </select>
                            funding types
                        </label>
                    </div>
                </div>
                <p v-if="!fundingTypes.length" class="text-center my-3">
                    There are no Funding Types currently created.
                </p>
                <table
                    v-else
                    class="table table-xs table-centered table-striped"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th class="text-center">Code</th>
                            <th class="text-center">Annual Target Amount</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="fundingType in fundingTypes">
                            <tr>
                                <td>
                                    <a
                                        :href="
                                            route('funding-types.show', {
                                                funding_type: fundingType.uuid,
                                            })
                                        "
                                    >
                                        {{ fundingType.name }}
                                    </a>
                                </td>
                                <td class="text-center">
                                    {{ fundingType.code }}
                                </td>
                                <td class="text-center">
                                    {{
                                        fundingType.yearly_target_amount
                                            ? fundingType.yearly_target_amount +
                                              " bookings"
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <Button
                                        v-if="
                                            permissions.includes(
                                                'update_funding_type'
                                            )
                                        "
                                        @click="editFundingType(fundingType)"
                                        :class-array="[
                                            'action-icon',
                                            'text-secondary',
                                        ]"
                                        color="link"
                                    >
                                        <i
                                            class="mdi mdi-square-edit-outline"
                                        ></i>
                                    </Button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
            <Pagination
                v-if="!loading && fundingTypes.length"
                @pageChanged="loadPage"
                :pagination="paginationMeta"
                record-name="funding types"
            ></Pagination>
        </div>

        <div
            id="editFundingTypeModal"
            class="modal fade"
            aria-hidden="true"
            aria-labelledby="editFundingTypeModalLabel"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                            id="createFundingTypeModalLabel"
                            class="modal-title"
                        >
                            Edit Funding Type
                        </h5>
                        <button
                            aria-label="Close"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            type="button"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <FundingTypeEditOrCreateForm
                            v-model="fundingTypeInEdit"
                        />
                    </div>
                    <div
                        class="modal-footer d-flex justify-content-between flex-nowrap"
                    >
                        <div class="flex-grow-1 me-1">
                            <DeleteFundingType
                                v-if="
                                    permissions.includes(
                                        'delete_funding_type'
                                    ) && fundingTypeInEdit.name
                                "
                                :key="fundingTypeInEdit.uuid"
                                :funding-type="fundingTypeInEdit"
                                @deleted="removeFundingType"
                            />
                        </div>
                        <div class="flex-shrink-1 d-flex flex-nowrap">
                            <button
                                class="btn btn-secondary me-1"
                                data-bs-dismiss="modal"
                                type="button"
                            >
                                Close
                            </button>
                            <Button
                                :loading="updating"
                                @click="updateFundingType"
                                >Save</Button
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListFundingTypes",
};
</script>
<script setup>
import { onMounted, inject, ref } from "vue";
import Button from "../Button.vue";
import CreateFundingType from "./CreateFundingType.vue";
import FundingTypeEditOrCreateForm from "./FundingTypeEditOrCreateForm.vue";
import DeleteFundingType from "./DeleteFundingType.vue";
import { uuid } from "vue-uuid";
import Pagination from "../Pagination.vue";
import Uploader from "../FileUpload/Uploader.vue";
import { mdiUpload, mdiDownload } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import FundingType from "@classes/FundingType";

const toast = inject("toast");
const axios = inject("axios");

const permissions = window.permissions;
const origin = window._globalAsset;

const fundingTypes = ref([]);
const loading = ref(true);
const page = ref(1);
const perPage = ref(25);
const paginationMeta = ref({});

const loadPage = (link) => {
    page.value = link.page;
    getFundingTypes();
};

const getFundingTypes = function () {
    loading.value = true;
    axios
        .get(route("api.funding-types.index"), {
            params: {
                pagination: true,
                page: page.value,
                per_page: perPage.value,
            },
        })
        .then((response) => {
            fundingTypes.value = response.data.data;
            paginationMeta.value = response.data.meta;
        })
        .catch((error) => {
            toast.error("Error fetching funding types");
        })
        .finally(() => {
            loading.value = false;
        });
};

const addFundingType = function (fundingType) {
    fundingTypes.value.push(fundingType);
};

const fundingTypeEditModal = ref(null);
const fundingTypeInEdit = ref({});
const editFundingType = function (fundingType) {
    fundingTypeInEdit.value = {
        uuid: fundingType.uuid,
        code: {
            uuid: uuid.v4(),
            value: fundingType.code,
            errors: [],
            required: true,
        },
        name: {
            uuid: uuid.v4(),
            value: fundingType.name,
            errors: [],
            required: true,
        },
        description: {
            uuid: uuid.v4(),
            value: fundingType.description,
            errors: [],
            required: false,
        },
        yearly_target_amount: {
            uuid: uuid.v4(),
            value: fundingType.yearly_target_amount,
            errors: [],
            required: false,
        },
        approved_payers: fundingType.approved_payers,
    };
    fundingTypeEditModal.value.show();
};

const updating = ref(false);
const updateFundingType = function () {
    updating.value = true;
    FundingType.update(fundingTypeInEdit.value, {
        code: fundingTypeInEdit.value.code.value,
        name: fundingTypeInEdit.value.name.value,
        description: fundingTypeInEdit.value.description.value ?? "",
        yearly_target_amount:
            fundingTypeInEdit.value.yearly_target_amount.value ?? null,
        approved_payers:
            fundingTypeInEdit.value.approved_payers?.map((i) => i.uuid) ?? [],
    })
        .then((response) => {
            const index = fundingTypes.value.findIndex(
                (fundingType) => fundingType.uuid === response.data.uuid
            );
            fundingTypes.value.splice(index, 1, response.data);
            toast.success("Funding type updated");
            fundingTypeEditModal.value.hide();
        })
        .catch((error) => {
            for (const [field, errors] of Object.entries(
                error.response.data.errors
            )) {
                fundingTypeInEdit.value[field].errors = errors;
            }
        })
        .finally(() => {
            updating.value = false;
        });
};

const removeFundingType = function (fundingType) {
    const index = fundingTypes.value.findIndex(
        (ft) => ft.uuid === fundingType.uuid
    );
    fundingTypes.value.splice(index, 1);
    fundingTypeEditModal.value.hide();
};

onMounted(() => {
    getFundingTypes();
    fundingTypeEditModal.value = new bootstrap.Modal(
        document.getElementById("editFundingTypeModal")
    );
});
</script>
