<template>
    <div class="row">
        <div class="col">
            <h5 class="text-uppercase">Attachments</h5>
            Create attachments relating to the client.
        </div>
        <div class="col d-flex align-items-end justify-content-end">
            <Button color="primary" :icon="mdiPlusCircle" @click="toggleUploadAttachmentModal">
                Create Attachment
            </Button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-sm-12 col-md-6">
            <PageLengthSelector record-name="attachments" :disabled="loadingAttachments"/>
        </div>
        <div class="col-sm-12 col-md-6"></div>
    </div>
    <template v-if="loadingAttachments">
        <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
            <Loading spinner-class="spinner-grow" text="Loading attachements" text-position="after" direction="horizontal"/>
        </div>
    </template>
    <template v-else-if="attachments?.length === 0">
        <div class="text-center mt-4 mb-3">
            <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiAttachment"></SvgIcon>
            <div class="fs-4 my-2">{{ client.preferred_name ?? client.given_names }} has no attachments.</div>
            <Button color="primary" :icon="mdiPlusCircle" @click="toggleUploadAttachmentModal">Create attachments</Button>
        </div>
    </template>
    <template v-else>
        <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
            <thead>
            <tr>
                <th>ID</th>
                <SortableColumn param="created_at">Date</SortableColumn>
                <SortableColumn param="type">
                    Type
                </SortableColumn>
                <SortableColumn param="name" style="min-width: 30%">
                    Title
                </SortableColumn>
                <th v-if="props.client == null">Attached Classes</th>
                <!-- <th v-if="props.document == null">Document</th> -->
                <th class="text-end" style="max-width: 5%">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(attachment, idx) in attachments" :key="idx">
                <td>{{ attachment.uuid }}</td>
                <td>{{ attachment.date }}</td>
                <td>
                    <AttachmentIcon :type="attachment.type"/>
                </td>
                <td>
                    <EditAttachmentName :attachment="attachment" :index="idx"/>
                </td>
                <td v-if="props.client == null">{{ attachment?.attachable?.name }}</td>
                <!-- <td v-if="props.document == null">{{ attachment?.document?.name }}</td> -->
                <td class="text-end">
                    <div class="dropdown">
                        <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <div
                                @click.prevent="confirmDeleteAttachment(attachment)"
                                class="dropdown-item pointer"
                            >
                                Delete Attachment
                            </div>
                            <a
                                :href="attachment.url + '?preview=1'"
                                target="_blank"
                                rel="noopener"
                                class="dropdown-item pointer"
                            >
                                View Attachment
                            </a>
                            <a
                                :href="attachment.url"
                                target="_blank"
                                rel="noopener"
                                class="dropdown-item pointer"
                            >
                                Download Attachment
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <Pagination v-if="!loadingAttachments" @pageChanged="getAttachments()" record-name="attachments"></Pagination>
    </template>
    <UploadAttachmentModal :attachable="getAttachable()"/>
    <ConfirmModal
        :show="showDeleteConfirmation"
        confirm_colour="danger"
        @confirmed="processDeleteAttachment"
    >
        <template v-slot:body>
            <p>Please confirm that you want to delete this attachment?</p>
        </template>
    </ConfirmModal>
</template>

<script setup lang="ts">
import {Button, ConfirmModal, Loading} from "@components";
import UploadAttachmentModal from "@components/Attachments/UploadAttachmentModal.vue";

import {PageLengthSelector, Pagination, SortableColumn,} from "@components/DataTable/index";

import {mdiAttachment, mdiPlusCircle} from "@mdi/js";

import {useAttachmentStore} from "@stores/AttachmentStore";
import {storeToRefs} from "pinia";
import {onBeforeMount, ref, watch} from "vue";
import AttachmentIcon from "./AttachmentIcon.vue";
import EditAttachmentName from "./EditAttachmentName.vue";
import SvgIcon from "@jamescoyle/vue-icon";

const attachmentStore = useAttachmentStore();

const {
    filters,
    attachments,
    loadingAttachments,
    paginateParams,
} = storeToRefs(attachmentStore);

const {
    getAttachments,
    getFileExtensions,
    toggleUploadAttachmentModal,
    setAttachment,
    deleteAttachment,
} = attachmentStore;

const props = withDefaults(
    defineProps<{
        client?: App.Models.Client,
        document?: App.Models.Document,
    }>(),
    {}
);

if (props.client != undefined) {
    filters.value.class = 'client';
    filters.value.classId = props.client?.uuid;
}

if (props.document != undefined) {
    filters.value.document = props.document.uuid ?? props.document;
}

const showDeleteConfirmation = ref<boolean>(false);

const confirmDeleteAttachment = (attachment: App.Resource.Attachment) => {
    showDeleteConfirmation.value = true;
    setAttachment(attachment);
}

const processDeleteAttachment = () => {
    showDeleteConfirmation.value = false;
    deleteAttachment();
}

const getAttachable = (): App.Attachment.Attachable | null => {
    if (props.client) {
        return {
            attachable_id: props.client.uuid ?? props.client,
            attachable_type: 'client',
        };
    }

    return null;
}

watch(
    paginateParams.value,
    () => {
        getAttachments();
    }
);

onBeforeMount(() => {
    getAttachments();
    getFileExtensions();
})
</script>

<style scoped>
.pointer {
    cursor: pointer;
}

</style>