<template>
    <div>
        <Button @click="showCreateModal" :icon="mdiPlusCircle">Add Location</Button>
        <CreateLocationForm
            :show-modal="showModal"
            @stored="handleNewLocationCreated"
        />
    </div>
</template>

<script>
export default {
    name: "CreateLocation"
}
</script>
<script setup>
import Button from "../Button.vue";
import {ref} from "vue";
import { mdiPlusCircle } from '@mdi/js'
import CreateLocationForm from "@components/Locations/CreateLocationForm.vue";

const props = defineProps({
    locationTypes: {
        type: Array,
        required: true
    }
});

const showModal = ref(false);
const emit = defineEmits(['stored']);

const showCreateModal = function () {
    showModal.value = true;
}

const handleNewLocationCreated = (newLocation) => {
    emit('stored', newLocation);
}

</script>

<style scoped>

</style>
