<template>
    <div v-if="modelValue.description">
        <div class="mb-3">
            <FormTextInput
                :key="modelValue.description.uuid"
                v-model:input="modelValue.description"
                label="Description"
                placeholder="e.g. Library"
                :validation="yup.string().required().max(255)"
            ></FormTextInput>
        </div>
    </div>
</template>

<script>
export default {
    name: "LocationTypeEditOrCreateForm"
}
</script>
<script setup>
import {FormTextInput} from "../Forms";
import * as yup from "yup";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
})
</script>
