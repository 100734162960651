<template>
    <div>
        <div
            class="mt-2 alert alert-danger"
            v-if="driverBreakBookingClashes?.length > 0"
        >
            <p>
                <strong>The following bookings clash with driver breaks</strong>
            </p>

            <p v-for="clash in driverBreakBookingClashes">
                Booking for
                <strong>{{ clash.clientName }}</strong> (<strong>{{
                    clash.origin
                }}</strong>
                to <strong>{{ clash.destination }}</strong
                >)
            </p>
        </div>

        <div
            class="mt-2 alert alert-info"
            v-if="hasBreaksChanged && originalDriverBreaks.length > 0"
        >
            <p>
                <strong
                    >Driver breaks have been changed from the following times in
                    the current draft</strong
                >
            </p>

            <p v-for="breakBreak in originalDriverBreaks">
                <strong>{{
                    DateTime.fromISO(breakBreak.start_time).toFormat("HH:mm")
                }}</strong>
                -
                <strong>{{
                    DateTime.fromISO(breakBreak.end_time ?? "").toFormat(
                        "HH:mm"
                    )
                }}</strong>
            </p>
        </div>

        <div class="mt-2">
            <DriverBreaks
                v-model="driverBreakComponentProps"
                :runStartTime="journeyDatetime?.startTime() ?? undefined"
                :runEndTime="journeyDatetime?.endTime() ?? undefined"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import { DateTime } from "luxon";
import { computed } from "vue";
import BookingDatetime from "@classes/DateHelpers/BookingDatetime";
import DriverBreaks, {
    DraftDriverBreakInterface,
} from "../../Vehicles/VehicleRuns/DriverBreaks.vue";
import JourneyDatetime from "@classes/DateHelpers/JourneyDatetime";

const allocationStore = useAllocationStore();
const { bookingsAllocatedToJourney, getUpdateParametersForJourney } =
    allocationStore;
const { selectedJourney, originalJourneys } = storeToRefs(allocationStore);

const journeyDatetime = computed(() => {
    return selectedJourney.value
        ? new JourneyDatetime(selectedJourney.value)
        : undefined;
});

const originalDriverBreaks = computed(() => {
    let originalJourney = originalJourneys.value.find(
        (i) => i.uuid == selectedJourney?.value?.uuid
    );
    return originalJourney?.breaks ?? [];
});

const driverBreakComponentProps = computed<DraftDriverBreakInterface[]>({
    get() {
        return (
            (selectedJourney.value?.breaks ?? []).map((i) => {
                return {
                    start_time: DateTime.fromISO(
                        i.start_time ?? ""
                    ).toSeconds(),
                    end_time: DateTime.fromISO(i.end_time ?? "").toSeconds(),
                };
            }) ?? []
        );
    },
    set(newBreaks: DraftDriverBreakInterface[]) {
        if (!selectedJourney.value?.breaks) {
            return;
        }

        //@ts-ignore
        selectedJourney.value.breaks = newBreaks.map((i) => {
            return {
                start_time: DateTime.fromSeconds(i.start_time).toISO(),
                end_time: DateTime.fromSeconds(i.end_time).toISO(),
            };
        });
    },
});

const hasBreaksChanged = computed(() => {
    return selectedJourney.value
        ? typeof getUpdateParametersForJourney(selectedJourney.value)
              ?.breaks !== "undefined"
        : false;
});

const driverBreakBookingClashes = computed(() => {
    let driverBreaks = selectedJourney.value?.breaks ?? [];
    let clashedBookings: App.Models.Booking[] = [];

    const bookingsAllocated = selectedJourney.value
        ? bookingsAllocatedToJourney(selectedJourney.value)
        : [];

    bookingsAllocated.forEach((booking) => {
        let bookingDatetime = new BookingDatetime(booking);

        let originTime = bookingDatetime.displayableOriginTime();
        let destinationTime = bookingDatetime.displayableDestinationTime();

        let clashesWithBreaks = driverBreaks.find((driverBeak) => {
            let breakStartTime = DateTime.fromISO(driverBeak.start_time ?? "");
            let breakEndTime = DateTime.fromISO(driverBeak.end_time ?? "");
            if (!originTime || !destinationTime) {
                return false;
            }

            return !(
                (breakStartTime < originTime && breakEndTime < originTime) ||
                (breakStartTime > destinationTime &&
                    breakEndTime > destinationTime)
            );
        });

        if (clashesWithBreaks) {
            clashedBookings.push(booking);
        }
    });

    return clashedBookings.map((booking) => {
        let bookingDatetime = new BookingDatetime(booking);

        let originTime = bookingDatetime.displayableOriginTime();
        let destinationTime = bookingDatetime.displayableDestinationTime();

        return {
            //@ts-ignore
            clientName: booking.client?.name,
            origin: originTime?.toFormat("HH:mm"),
            destination: destinationTime?.toFormat("HH:mm"),
        };
    });
});
</script>
