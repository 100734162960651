<template>
    <ConfirmButton
        :icon="mdiRestore"
        color="warning"
        :confirmation-title="'Reactivate ' + driver.name"
        :confirmation-text="'Please confirm you would like to reactivate ' + driver.name + '?'"
        @confirmed="restoreDriver"
        :loading="restoring"
    >
        Reactivate Driver
    </ConfirmButton>
</template>

<script>
export default {
    name: "ReactivateDriverLink"
}
</script>
<script setup>
import ConfirmButton from "../ConfirmButton.vue";
import { mdiRestore } from "@mdi/js";
import { ref, inject } from "vue";
import axios from "axios";
const toast = inject('toast')

const props = defineProps({
    driver: Object,
})

const restoring = ref(false)

const emit = defineEmits(['restored'])

const restoreDriver = function () {
    restoring.value = true
    axios
        .post(route('api.drivers.restore', {driver: props.driver.uuid}))
        .then((response) => {
            toast.success(`You reactivated ${props.driver.name}`)
            emit('restored', 'active')
        })
        .catch((err) => {
            toast.error('An error occurred while restoring the driver.')
        })
        .finally(() => {
            restoring.value = false
        })
}

</script>

<style scoped>

</style>
