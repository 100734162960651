<template>
    <div>
        <div class="row mb-2">
            <div class="col-md-6">
                <a
                    :href="route('clients.create')"
                    class="btn btn-primary"
                    type="button"
                >
                    <i class="mdi mdi-plus-circle me-1"></i>
                    Add Client
                </a>
            </div>
            <!--            <div class="col-md-6 text-end">-->
            <!--                <button class="btn btn-primary me-1" type="button">-->
            <!--                    <i class="mdi mdi-cog"></i>-->
            <!--                </button>-->
            <!--                <button class="btn btn-light me-1" type="button">-->
            <!--                    <i class="mdi mdi-message me-1"></i>Send Group Email/SMS-->
            <!--                </button>-->
            <!--                <button class="btn btn-light me-1" type="button">-->
            <!--                    <i class="mdi mdi-database-import-outline me-1"></i>Import-->
            <!--                </button>-->
            <!--                <button class="btn btn-light" type="button">-->
            <!--                    <i class="mdi mdi-database-export-outline me-1"></i>Export-->
            <!--                </button>-->
            <!--            </div>-->
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <PageLengthSelector
                            record-name="clients"
                            :disabled="loadingClients"
                        />
                    </div>

                    <div class="col-sm-12 col-md-6 text-md-end">
                        <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                            <button
                                class="btn btn-light btn-sm dropdown-toggle"
                                type="button"
                                id="dropDownFilterClients"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="mdiFilter"
                                    style="max-width: 16px"
                                ></SvgIcon>
                                Filter
                            </button>
                            <div class="dropdown-menu px-4 py-3" style="min-width: 500px;" aria-describedby="dropDownFilterBookings">
                                <div class="mb-2">
                                    <label class="form-label text-sm" for="status_filter_select">Status:</label>
                                    <VueMultiselect
                                        v-model="statusFilter"
                                        :options="statusFilterOptions"
                                        placeholder="Select Statuses"
                                        label="label"
                                        track-by="value"
                                        multiple
                                    ></VueMultiselect>
                                </div>
                            </div>
                        </div>
                        <DataTableSearch
                            @search="getClients"
                            placeholder="Search clients..."
                        />
                    </div>
                </div>

                <template v-if="!loadingClients">
                    <table
                        class="table table-striped table-centered table-sm dt-responsive nowrap w-100"
                    >
                        <thead>
                            <tr>
                                <SortableColumn param="uuid">ID</SortableColumn>
                                <SortableColumn param="given_names">Client</SortableColumn>
                                <th>Address</th>
                                <th>Phone</th>
                                <th class="text-center">Region</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(client, index) in clients" :key="index">
                            <td>{{ client.uuid }}</td>
                            <td class="table-user">
                                <a :href="route('clients.show', { 'client': client.uuid })">
                                    <ClientAvatar :size="'extra-small'" class="me-2" :client="client"></ClientAvatar>
                                    {{ client.name }}
                                </a>
                            </td>
                            <td>{{ client.address ? client.address.full_address : '-' }}</td>
                            <td><a :href="$filters.phone_url(client.phone)">{{ $filters.phone(client.phone) }}</a></td>
                            <td class="text-center">{{ client.regions?.map(i => i.name).join(', ') }}</td>
                            <td class="text-center">
                                <span v-if="!client.deactivated" class="badge badge-success-lighten">Active</span>
                                <span v-if="client.deactivated" class="badge badge-secondary-lighten">Deactivated</span>
                            </td>
                            <td class="text-center">
                                <CreateBookingForClientButton :client="client">
                                    <SvgIcon
                                        :path="mdiClockPlusOutline"
                                        type="mdi"
                                    ></SvgIcon>
                                </CreateBookingForClientButton>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <Pagination
                        v-if="!loadingClients"
                        @pageChanged="getClients"
                        record-name="clients"
                    ></Pagination>
                </template>

                <template v-if="loadingClients">
                    <div class="d-flex w-100 justify-content-center align-items-center ps-3">
                        <Loading
                            :show-text="false"
                            spinner-class="spinner-grow"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onBeforeMount, ref, watch } from "vue";
    import { storeToRefs } from "pinia";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiFilter, mdiMagnify, mdiClockPlusOutline } from "@mdi/js";
    import ClientAvatar from "./ClientAvatar.vue";
    import { Pagination, PageLengthSelector, SortableColumn, DataTableSearch } from "@components/DataTable";
    import VueMultiselect from 'vue-multiselect';
    import CreateBookingForClientButton from "./CreateBookingForClientButton.vue";
    import { usePaginationStore } from "@stores/PaginationStore";
    import { useRegionStore } from "@stores/RegionStore";
    import { useClientStore } from "@stores/ClientStore";
    import { Loading } from "@components";

    const paginationStore = usePaginationStore();
    const { paginateParams, paginatedMeta, searchQuery } = storeToRefs(paginationStore);
    paginateParams.value.sortBy = 'given_names';

    const { selectedRegion } = storeToRefs(useRegionStore());

    const clientStore = useClientStore();
    const { clients, loadingClients } = storeToRefs(clientStore);

    const statusFilter = ref([
        {value: 'active', label: 'Active'},
        {value: 'deactivated', label: 'Deactivated'},
    ]);

    const statusFilterOptions = ref([
        {value: 'active', label: 'Active'},
        {value: 'deactivated', label: 'Deactivated'},
    ]);

    const pageAmount = ref(25);

    const getClients = () => {
        clientStore.getClients(statusFilter);
    }

    watch(
        statusFilter,
        () => {
            getClients();
        }
    )

    watch(
        paginateParams.value,
        () => {
            getClients();
        }
    )

    watch(
        selectedRegion,
        (newParams) => {
            // the if condition is need as the watch is use for record show per page changes
            // removing if condition will result in double call when clicking pagination
            if (pageAmount.value !== newParams.perPage) {
                pageAmount.value = newParams.perPage;
                getClients();
            }
        }
    )

    onBeforeMount(() => {
        getClients();
    });
</script>

<style scoped></style>
