<template>
    <div>
        <h4>Update Password</h4>
        <div v-if="success" class="alert alert-success">
            Your password was successfully updated
        </div>
        <div class="row">
            <div class="mb-3 col-md-12 has-validation">
                <label for="current_password" class="form-label">Current Password</label>
                <input v-model="currentPassword" type="password" name="current_password"
                       :class="{ 'form-control': true, 'is-invalid': errors.current_password }"
                       aria-describedby="current_password_feedback"
                       id="current_password">
                <div v-if="errors.current_password" id="current_password_feedback" class="invalid-feedback">
                    {{ errors.current_password[0] }}
                </div>
            </div>
            <div class="mb-3 col-md-6 has-validation">
                <label for="password" class="form-label">New Password</label>
                <input v-model="password" type="password" :class="{ 'form-control': true, 'is-invalid': errors.password }"
                       aria-describedby="password_feedback" name="password" id="password">
                <div v-if="errors.password" id="password_feedback" class="invalid-feedback">
                    {{ errors.password[0] }}
                </div>
            </div>
            <div class="mb-3 col-md-6 has-validation">
                <label for="password_confirmation" class="form-label">Confirm Password</label>
                <input v-model="passwordConfirmation" type="password" :class="{ 'form-control': true, 'is-invalid': errors.password_confirmation }" name="password_confirmation" id="password_confirmation">
                <div v-if="errors.password_confirmation" id="password_confirmation_feedback" class="invalid-feedback">
                    {{ errors.password_confirmation[0] }}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" :disabled="submitButtonDisabled || loading" @click="updatePassword">
                <span v-if="loading" class="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>Update Password
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "UpdatePassword",
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
            errors: [],
            loading: false,
            success: false,
        }
    },
    computed: {
        submitButtonDisabled: function () {
            return (this.currentPassword.trim().length === 0 || this.password.trim().length === 0 || this.passwordConfirmation.trim().length === 0)
        }
    },
    methods: {
        updatePassword() {
            this.validate()
                .then(() => {
                    this.loading = true
                    this.$http
                        .put(this.route('api.users.update', { user: this.user.uuid }), {
                            'current_password': this.currentPassword,
                            'password': this.password,
                            'password_confirmation': this.passwordConfirmation,
                        })
                        .then((response) => {
                            this.success = true
                            this.currentPassword = ''
                            this.password = ''
                            this.passwordConfirmation = ''
                        })
                        .catch((response) => {
                            this.errors = response.response.data.errors
                        })
                        .finally(() => {
                            this.loading = false
                        })
                })
                .catch(() => {})
        },
        async validate() {
            return new Promise((pass, fail) => {
                this.success = false
                this.errors = []
                if (this.password.trim() !== this.passwordConfirmation.trim()) {
                    this.errors['password'] = ["These passwords don't match."]
                    this.errors['password_confirmation'] = ["These passwords don't match."]
                    return fail()
                }

                if (this.password.trim().length < 8) {
                    this.errors['password'] = ['Your password will need to be at least 8 characters long.']
                    return fail()
                }

                return pass()
            })
        }
    }
}
</script>

<style scoped>

</style>
