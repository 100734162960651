<template>
    <div class="d-inline-block">
        <span
            v-if="!editing && !loading"
            @click="editing = !(loading || disabled)"
            :class="(loading || disabled) ? null : 'click-to-edit'"
        >
            <template v-if="value"><slot>{{ value }}</slot></template>
            <template v-else><small><em>Click to add</em></small></template>
        </span>
        <div class="d-flex align-items-center" v-if="editing || loading">
            <VueMultiselect
                v-model="fieldValue"
                :options="options"
                :placeholder="placeholder"
                :label="label"
                :track-by="trackBy"
                :multiple="multiple"
                :allow-empty="allowEmpty"
                :disabled="disabled"
                :loading="loading"
            ></VueMultiselect>
            <template v-if="!loading">
                <a href="javascript:void(0);" class="px-1" @click="update">
                    <SvgIcon type="mdi" :path="mdiCheck" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
                <a href="javascript:void(0);" class="px-1" @click="editing = false">
                    <SvgIcon type="mdi" :path="mdiClose" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditableMultiSelect"
}
</script>
<script setup>
import {ref, onBeforeMount} from "vue"
import SvgIcon from "@jamescoyle/vue-icon"
import { mdiCheck, mdiClose } from "@mdi/js"
import Spinner from "../../Spinner.vue"
import VueMultiselect from "vue-multiselect";

const fieldValue = ref(null)
const props = defineProps({
    value: {
        type: Array,
        default: []
    },
    loading: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
        default: []
    },
    allowEmpty: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: 'Select option...'
    },
    label: {
        type: String,
        default: 'label'
    },
    trackBy: {
        type: String,
        default: 'value'
    },
    multiple: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['updated'])

let editing = ref(false)
const update = function () {
    editing.value = false
    emit('updated', fieldValue.value)
}

onBeforeMount(() => {
    fieldValue.value = props.value
})

</script>
<style scoped>

</style>
