import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { toTimeStamp, formatDate } from "../components/Utils.js";

export enum JourneyEventType {
    START = "start",
    DEPARTED_DEPOT = "departed_depot",
    START_BREAK = "start_break",
    END_BREAK = "end_break",
    END = "end",
}

export default class JourneyEvent {
    static update(
        event: App.Models.JourneyEvent,
        parameters: {
            occurred_at: DateTime;
        }
    ) {
        return axios.put<App.Models.JourneyEvent>(
            route("api.journey-events.update", { journey_event: event.id }),
            {
                ...parameters,
                ...{
                    occurred_at: parameters?.occurred_at
                        ? toTimeStamp(parameters?.occurred_at)
                        : undefined,
                },
            }
        );
    }
}
