<template>
    <div>
        <a href="javascript: void(0)" class="link-primary" @click="showModal">
            <slot>
                <i class="mdi mdi-message" role="button"></i>
            </slot>
        </a>
        <div
            :id="'modal-' + uuid"
            class="modal fade"
            tabindex="-1"
            :aria-labelledby="uuid + '_title'"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="uuid + '_title'">Message {{ receiver.name }}</h5>
                    </div>
                    <div class="modal-body">
                    <textarea
                        v-model="message"
                        class="form-control form-control-light"
                        :disabled="loading"
                        rows="3"
                        style="resize: none"
                        placeholder="Type message..."
                    ></textarea>
                    </div>
                    <div class="modal-footer">
                        <button :disabled="loading" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button @click="sendMessage" :disabled="loading || message.trim().length === 0" type="button" :class="{
                        'btn': true,
                        'btn-primary': !this.sent,
                        'btn-success': this.sent
                    }">
                            <template v-if="loading && !sent">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>
                            <template v-else-if="sent">
                                Sent!
                            </template>
                            <template v-else>Send</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SendMessage",
    data() {
        return {
            uuid: this.$uuid.v1(),
            message: '',
            loading: false,
            sent: false,
            modal: null,
            receiver: {}
        }
    },
    props: {
        receiverType: {
            type: String,
            required: true
        },
        receiverInfo: {
            type: Object,
            required: true
        }
    },
    methods: {
        sendMessage() {
            this.loading = true
            if (this.receiverType === 'crew') {
                this.sendCrewMessage()
            } else if (this.receiverType === 'client') {
                this.sendClientMessage()
            }
        },
        sendCrewMessage() {
            this.$http
                .post(this.route('crew.message.send', { crew: this.receiverInfo.uuid }), {
                    message: this.message,
                })
                .then((response) => {
                    this.messageSent()
                });
        },
        sendClientMessage() {
            this.$http
                .post(this.route('api.clients.message.send', { client: this.receiverInfo.uuid }), {
                    message: this.message,
                })
                .then((response) => {
                    this.messageSent()
                });
        },
        messageSent() {
            this.sent = true
            setTimeout(this.hideModal, 1000)
        },
        showModal() {
            this.modal.show()
            this.message = ''
            this.loading = false
            this.sent = false
        },
        hideModal() {
            this.modal.hide()
        }
    },
    mounted() {
        this.modal = new bootstrap.Modal(document.getElementById('modal-' + this.uuid))
    },
    beforeMount() {
        this.receiver = this.receiverInfo
    }
}
</script>

<style scoped>

</style>
