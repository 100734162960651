<template>
    <div :class="{ card: true }">
        <div
            class="card-body p-3 d-flex flex-row align-items-center"
            @click="toggle"
        >
            <h4 class="header-title flex-fill mb-0">Overview</h4>
            <div>
                <SvgIcon
                    type="mdi"
                    :path="open ? mdiChevronUp : mdiChevronDown"
                    :size="20"
                ></SvgIcon>
            </div>
        </div>
        <div :class="[className, 'ps-3 pe-3']">
            <table
                class="table table-striped table-centered table-sm dt-responsive nowrap w-100"
            >
                <thead>
                    <tr>
                        <th>Region</th>
                        <th>Vehicle Runs</th>
                        <th>Unallocated Bookings</th>
                        <th>Allocated Bookings</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="region in regionsWithStats">
                        <td>{{ region.name }}</td>
                        <td>
                            {{ getVehicleRunCountForRegion(region) }}
                        </td>
                        <td>
                            {{ getUnallocatedBookingCountForRegion(region) }}
                        </td>
                        <td>
                            {{ getAllocatedBookingCountForRegion(region) }}
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Total</strong></td>
                        <td>
                            <strong>{{ journeys.length }}</strong>
                        </td>
                        <td>
                            <strong>
                                {{
                                    bookings.filter(
                                        (b) =>
                                            !b.journey &&
                                            b.status !== BookingStatus.Cancelled
                                    ).length
                                }}</strong
                            >
                        </td>
                        <td>
                            <strong>
                                {{
                                    bookings.filter(
                                        (b) =>
                                            b.journey &&
                                            b.status !== BookingStatus.Cancelled
                                    ).length
                                }}</strong
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { uuid } from "vue-uuid";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import { BookingStatus } from "@classes/Booking";

const allocationStore = useAllocationStore();

const { journeys, bookings, regions } = storeToRefs(allocationStore);

const className = ref("toggle-" + uuid.v1());
const open = ref<boolean>(false);

const slide = () => {
    if (!open.value) {
        $("." + className.value).slideUp("fast");
    } else {
        $("." + className.value).slideDown("fast");
    }
};

const regionsWithStats = computed(() => {
    return regions.value.filter((region) => {
        return (
            getVehicleRunCountForRegion(region) > 0 ||
            getUnallocatedBookingCountForRegion(region) > 0 ||
            getAllocatedBookingCountForRegion(region) > 0
        );
    });
});

const getVehicleRunCountForRegion = (region: App.Models.Region) => {
    return journeys.value.filter((j) => j.region?.uuid == region.uuid).length;
};

const getUnallocatedBookingCountForRegion = (region: App.Models.Region) => {
    return bookings.value.filter(
        (b) =>
            b.status !== BookingStatus.Cancelled &&
            b.region?.uuid == region.uuid &&
            !b.journey
    ).length;
};

const getAllocatedBookingCountForRegion = (region: App.Models.Region) => {
    return bookings.value.filter(
        (b) =>
            b.status !== BookingStatus.Cancelled &&
            b.region?.uuid == region.uuid &&
            b.journey
    ).length;
};

const toggle = () => {
    open.value = !open.value;
    slide();
};
onMounted(() => {
    slide();
});
</script>
