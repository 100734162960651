import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, get, set, child, push, orderByKey, startAt, endAt, query, update, orderByChild, onChildAdded, onChildChanged, equalTo, startAfter } from "firebase/database";
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDiW975aEV8y4mN3WWDP9y7sSArLnqZuCs",
    authDomain: "trips-43f16.firebaseapp.com",
    databaseURL: "https://trips-43f16-default-rtdb.firebaseio.com",
    projectId: "trips-43f16",
    storageBucket: "trips-43f16.appspot.com",
    messagingSenderId: "1035557561305",
    appId: "1:1035557561305:web:eeb0ce748e0ea355da6fc8",
    measurementId: "G-M1GKX96DVE"
};

const firebase = initializeApp(firebaseConfig);
const firebaseRTDB = getDatabase(firebase);

export { firebase, firebaseRTDB, ref, onValue, get, set, child, push, orderByKey, startAt, endAt, query, update, orderByChild, onChildAdded, onChildChanged, equalTo, startAfter, getMessaging }
