<template>
    <div class="list-group list-group-flush">
        <div v-for="item in items" class="list-group-item px-0">
            <div class="d-flex">
                <div class="flex-shrink-1 me-2">
                    <div class="avatar-xs">
                        <span v-if="item.icon === 'pick_up'" class="avatar-title bg-info rounded-circle"><i class="mdi mdi-account-plus"></i></span>
                        <span v-else-if="item.icon === 'alert'" class="avatar-title bg-warning rounded-circle"><i class="mdi mdi-exclamation-thick"></i></span>
                        <span v-else class="avatar-title rounded-circle"><i class="mdi mdi-information-variant"></i></span>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="fw-bold">
                        {{ item.title }}
                    </div>
                    <small class="d-block text-muted">{{ moment(item.time).fromNow() }}</small>
                    <small class="event-content">
                        <Markdown :content="item.content"/>
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Markdown from '../Markdown.vue'

export default {
    name: "DispatchFeed",
    components: {
        Markdown,
    },
    data () {
        return {
            items: [
                {
                    id: this.$uuid.v1(),
                    time: "2021-11-09 09:00:00",
                    icon: "pick_up",
                    title: "Pick-up",
                    content: "MJH-49F picked up [@LaurieSchmidt](/clients/2)",
                    link: "#",
                },
                {
                    id: this.$uuid.v1(),
                    time: "2021-11-09 08:55:00",
                    icon: "alert",
                    title: "Registration Expiring Soon",
                    content: "Registration for [ZYL-3CF](/vehicles/1) will expire on the 15 Nov 21",
                    link: "#",
                },
                {
                    id: this.$uuid.v1(),
                    time: "2021-11-09 06:47:00",
                    icon: "info",
                    title: "New Booking Created",
                    content: "Booking created for [@ChadrickDare](/clients/3)",
                    link: "#",
                },
            ],
            moment: moment
        }
    }
}
</script>
