import axios, {AxiosResponse} from "axios";
import {ApiResourceResponseInterface} from "../types/LaravelCommonTypes";
import TrackingCategoryResource, {TrackingCategoryOptionResource} from "@customTypes/resources/TrackingCategoryResource";

export default class TrackingCategory {
    static index = async (noCache: boolean = false): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.get<ApiResourceResponseInterface<TrackingCategoryResource[]>>(
            route('xero.tracking-categories.index'),
            noCache ? {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            } : undefined
        );

    static create = async (trackingCategory: TrackingCategoryResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<TrackingCategoryResource[]>>(
            route(`xero.tracking-categories.store`),
            {
                'name': trackingCategory.name,
                'status': trackingCategory.status,
            }
        );

    static update = async (trackingCategory: TrackingCategoryResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.patch<ApiResourceResponseInterface<TrackingCategoryResource[]>>(
            route(`xero.tracking-categories.update`, {tracking_category: trackingCategory.tracking_category_id}),
            {
                'name': trackingCategory.name,
                'status': trackingCategory.status,
                'tracking_category_id': trackingCategory.tracking_category_id,
            }
        );

    static delete = async (trackingCategory: TrackingCategoryResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.delete<ApiResourceResponseInterface<TrackingCategoryResource[]>>(
            route(`xero.tracking-categories.destroy`, {tracking_category: trackingCategory.tracking_category_id})
        );

    static createOption = async (trackingCategory: TrackingCategoryResource, trackingCategoryOption: TrackingCategoryOptionResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<TrackingCategoryOptionResource>>(
            route(`xero.tracking-category-options.store`, {tracking_category: trackingCategory.tracking_category_id}),
            {
                'name': trackingCategoryOption.name,
                'status': trackingCategoryOption.status,
                'tracking_option_id': trackingCategoryOption.tracking_option_id,
            }
        );

    static updateOption = async (trackingCategory: TrackingCategoryResource, trackingCategoryOption: TrackingCategoryOptionResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.patch<ApiResourceResponseInterface<TrackingCategoryOptionResource>>(
            route(`xero.tracking-category-options.update`, {
                tracking_category: trackingCategory.tracking_category_id,
                tracking_category_option: trackingCategoryOption.tracking_option_id
            }),
            {
                'name': trackingCategoryOption.name,
                'status': trackingCategoryOption.status,
                'tracking_option_id': trackingCategoryOption.tracking_option_id,
            }
        );

    static deleteOption = async (trackingCategory: TrackingCategoryResource, trackingCategoryOption: TrackingCategoryOptionResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.delete<ApiResourceResponseInterface<TrackingCategoryOptionResource>>(
            route(`xero.tracking-category-options.destroy`, {
                tracking_category: trackingCategory.tracking_category_id,
                tracking_category_option: trackingCategoryOption.tracking_option_id
            }),
        );

}
