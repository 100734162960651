<template>
    <div>
        <div class="depot-row">
            <div @click="toggleShowVehicles">
                <i class="mdi fs-3" :class="showVehicles ? 'mdi-menu-down' : 'mdi-menu-right'"></i>
            </div>
            <div>
                <i class="mdi mdi-car-multiple fs-3"></i>
            </div>
            <div>
                <strong>{{ depot.name ? depot.name : 'No vehicle depot name specified' }}</strong>{{ depot?.address ? ' (' + depot.address + ')' : '' }}<br>
                <em class="text-muted">{{ depot.journeys.filter((journey) => journey.stops.length > 2).length }} {{ depot.journeys.filter((journey) => journey.stops.length > 2).length === 1 ? 'journey' : 'journeys' }}</em>
            </div>
        </div>
        <div v-if="depot.journeys.filter((journey) => journey.stops.length > 2).length > 0" class="vehicle-rows" :class="'depot-' + uuid">
            <div class="vehicle-row-header">
                <div></div>
                <div>ID</div>
                <div>DEPARTURE TIME</div>
                <div>RETURN TIME</div>
                <div>STATUS</div>
                <div>ACTIONS</div>
            </div>
            <template v-for="journey in depot.journeys" :key="journey.uuid">
                <JourneyRow
                    :journey="journey"
                    :expand="expand"
                    @bookingReallocated="$emit('bookingReallocated', $event)"
                ></JourneyRow>
            </template>
        </div>
    </div>
</template>

<script>
import JourneyRow from "./JourneyRow.vue";
export default {
    name: "DepotRow",
    components: {
        JourneyRow,
    },
    props: {
        depot: Object,
        expand: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            showVehicles: false,
            uuid: this.$uuid.v1(),
        }
    },
    emits: [
        'bookingReallocated',
    ],
    computed: {
        journeysWithoutTripsCount: function () {
            return this.depot.journeys.reduce((n, journey) => {
                return n + (journey.bookings.length === 0)
            }, 0)
        }
    },
    watch: {
        expand: function () {
            this.showVehicles = this.expand
            if (this.showVehicles) {
                $('.depot-' + this.uuid).slideDown('fast')
            } else {
                $('.depot-' + this.uuid).slideUp('fast')
            }
        }
    },
    methods: {
        toggleShowVehicles: function () {
            this.showVehicles = !this.showVehicles
            $('.depot-' + this.uuid).slideToggle('fast')
        }
    },
    beforeMount() {
        this.showVehicles = this.expand
    }
}
</script>

<style scoped>

</style>
