<template>
    <div class="container ms-0">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h4>{{ fundingType.name }}</h4>
                    <Button
                        @click="showModal"
                        color="light"
                    ><i class="mdi mdi-credit-card-plus me-1"></i> Edit Funding Type</Button>
                </div>
                <div id="updateFundingTypeModal" class="modal fade" aria-hidden="true" aria-labelledby="updateFundingTypeModalLabel" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 id="createFundingTypeModalLabel" class="modal-title">Update Funding Type</h5>
                                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                            </div>
                            <div class="modal-body">
                                <FundingTypeEditOrCreateForm v-model="fundingTypeModel"/>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
                                <Button :loading="updating" @click="updateFundingType">Update</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <p v-if="fundingType.description">{{ fundingType.description }}</p>
                <div class="d-flex mt-3">
                    <div class="me-5">
                        <dt>
                            <strong class="fs-5">Code</strong>
                        </dt>
                        <dd>{{ fundingType.code }}</dd>
                    </div>
                    <div class="me-5">
                        <dt>
                            <strong class="fs-5">Annual Target</strong>
                        </dt>
                        <dd>
                            <template v-if="fundingType.yearly_target_amount">
                                {{ fundingType.yearly_target_amount }} bookings
                            </template>
                            <template v-else>
                                N/A
                            </template>
                        </dd>
                    </div>
                    <div class="me-5">
                        <dt>
                            <strong class="fs-5">Tax Type</strong>
                        </dt>
                        <dd>{{ fundingType.tax_type }}</dd>
                    </div>
                    <div v-if="fundingType.funding_region" class="me-5">
                        <dt>
                            <strong class="fs-5">Funding Region</strong>
                        </dt>
                        <dd>{{ fundingType.funding_region.name }}</dd>
                    </div>
                    <div v-if="fundingType.funding_source" class="me-5">
                        <dt>
                            <strong class="fs-5">Funding Source</strong>
                        </dt>
                        <dd>Default Funding Type</dd>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, inject, reactive, onMounted } from "vue"
import Button from '../Button.vue'
import { mdiCreditCardPlus } from "@mdi/js"
import FundingTypeEditOrCreateForm from "./FundingTypeEditOrCreateForm.vue"
import { transformInput } from "../Utils"
import FundingType from "@classes/FundingType"
import FundingTypeResource from "@/resources/js/types/resources/FundingTypeResource";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

const toast = inject<any>('toast');

const props = withDefaults(
    defineProps<{
        fundingType: FundingTypeResource;
    }>(),
    {}
);


const fundingTypeModel = ref({
    name: transformInput({ value: props.fundingType.name }),
    code: transformInput({ value: props.fundingType.code }),
    description: transformInput({ value: props.fundingType.description, required: false }),
    yearly_target_amount: transformInput({ value: props.fundingType.yearly_target_amount, required: false }),
    approved_payers: props.fundingType.approved_payers,
})

const updating = ref(false)
const updateFundingType = () => {
    updating.value = true
    FundingType.update(props.fundingType, getParams())
        .then(response => {
            toast.success('Funding Type updated successfully')
            props.fundingType.name = response.data.name
            props.fundingType.code = response.data.code
            props.fundingType.description = response.data.description
            props.fundingType.yearly_target_amount = response.data.yearly_target_amount
            props.fundingType.approved_payers = response.data.approved_payers
            updateFundingTypeModal.value?.hide()
        })
        .catch(error => {
            toast.error('There was an error updating the Funding Type')
        })
        .finally(() => {
            updating.value = false
        })
}

const getParams = () => {
    return {
        name: fundingTypeModel.name.value,
        code: fundingTypeModel.code.value,
        description: fundingTypeModel.description.value,
        yearly_target_amount: fundingTypeModel.yearly_target_amount.value,
        approved_payers: fundingTypeModel.approved_payers?.map(i => i.uuid) ?? [],
    }
}

const updateFundingTypeModal = ref<null | bootstrap.Modal>(null)
const showModal = () => {
    updateFundingTypeModal.value.show()
}

onMounted(() => {
    updateFundingTypeModal.value = new bootstrap.Modal(document.getElementById('updateFundingTypeModal'))
})
</script>
