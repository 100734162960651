<template>
    <span :class="statusClass" :title="full ? '' : title" class="badge rounded-pill text-dark d-inline-flex align-items-center" data-bs-toggle="tooltip">
        <SvgIcon :path="iconPath" class="w-3 h-3" type="mdi"/>
        <span v-if="full" class="ms-1">{{ title }}</span>
    </span>
</template>

<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiCashPlus,
    mdiCashRefund,
} from "@mdi/js";
import {computed} from "vue";

const props = defineProps({
    type: String,
    full: Boolean,
})

const iconPath = computed(() => {
    switch (props.type) {
        case 'withdrawal':
            return mdiCashRefund
        default:
            return mdiCashPlus
    }
})

const title = computed(() => {
    switch (props.type) {
        case 'withdrawal':
            return 'Refund'
        default:
            return 'Payment'
    }
})

const statusClass = computed(() => {
    switch (props.type) {
        case 'withdrawal':
            return 'bg-danger-lighten'
        default:
            return 'bg-success-lighten'
    }
})

</script>

<style scoped>

</style>
