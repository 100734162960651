import { defineStore } from "pinia";
import { computed, ref } from "vue";
import {
    AssistanceTypeOptionsInterface,
    AssistanceTypeDetailsObjectInterface
} from "@classes/AssistanceType";
import {CargoSpaceFormDetailsInterface, CargoSpaceOptionsInterface} from "@classes/CargoSpace";
import {VehicleGroupInterface} from "@classes/VehicleGroup";
import {SeatTypeInterface} from "@classes/SeatType";
import {clone, cloneDeep} from "lodash";
import {uuid} from "vue-uuid";

export interface BookingRequirementPayloadInterface {
    chosen_seat: string;
    vehicle_types: any[];
    assistance_types: any[];
    cargo_spaces: any[];
}

export const useBookingRequirementStore = defineStore('bookingRequirementStore', () => {
    const seatTypeOptions = ref<SeatTypeInterface[]>([]);
    const vehicleGroupOptions = ref<VehicleGroupInterface[]>([]);
    const seatPositionList = ref([
        {
            code: 'front_seat',
            label: 'Front Seat'
        }, {
            code: 'back_seat',
            label: 'Back Seat',
        }, {
            code: 'anchored_seat',
            label: 'Anchored Seat'
        }
    ]);
    const assistanceTypeOptions = ref<AssistanceTypeOptionsInterface[]>([]);
    const filteredAssistanceTypeOptions = ref<AssistanceTypeOptionsInterface[]>([]);
    const selectedAssistanceTypes = ref<AssistanceTypeDetailsObjectInterface[]>([]);
    const cargoSpaceOptions = ref<CargoSpaceOptionsInterface[]>([]);
    const filteredCargoSpaceOptions = ref<CargoSpaceOptionsInterface[]>([]);
    const selectedCargoSpaces = ref<CargoSpaceFormDetailsInterface[]>([]);
    const chosenSeat = ref('transfer_manual_wheelchair');
    const chosenVehicle = ref({});
    const originalChosenSeat = ref('');
    const originalChosenVehicle = ref({});
    const originalSelectedAssistanceType = ref<AssistanceTypeDetailsObjectInterface[]>([]);
    const originalSelectedCargoSpace = ref<CargoSpaceFormDetailsInterface[]>([]);
    const affectedBookings = ref('future_bookings');
    const affectedBookingsFrom = ref({
        uuid: uuid.v4(),
        value: null,
        required: true,
        valid: false,
        errors: [],
    });
    const affectedBookingsTo = ref({
        uuid: uuid.v4(),
        value: null,
        required: true,
        valid: false,
        errors: [],
    });

    const fillBeforeSaveData = () => {
        originalChosenSeat.value = cloneDeep(chosenSeat.value);
        originalChosenVehicle.value = cloneDeep(chosenVehicle.value);
        originalSelectedAssistanceType.value =  cloneDeep(selectedAssistanceTypes.value);
        originalSelectedCargoSpace.value = cloneDeep(selectedCargoSpaces.value);
    }

    const cancelToBeforeSaveData = () => {
        chosenSeat.value = cloneDeep(originalChosenSeat.value);
        chosenVehicle.value = cloneDeep(originalChosenVehicle.value);
        selectedAssistanceTypes.value = cloneDeep(originalSelectedAssistanceType.value);
        selectedCargoSpaces.value = cloneDeep(originalSelectedCargoSpace.value);
    }

    const resetSeat = () => {
        chosenSeat.value = 'transfer_manual_wheelchair';
    }
    const flatVehicleTypes = ref([]);

    const setSeatTypeOptions = (sOptions: []) => {
        seatTypeOptions.value = sOptions;
    }

    const getSeatPositionDetail = (positionCode: string) => {
        return seatPositionList.value.find(sp => sp.code === positionCode);
    }

    const setVehicleGroupOptions = (vgOptions: VehicleGroupInterface[]) => {
        vehicleGroupOptions.value = vgOptions;

        resetVehicle();
    }

    const defaultVehicleByGroup = () => {
        const vehicles = {};
        vehicleGroupOptions.value.forEach((vGOption, index) => {
            vehicles[vGOption.code] = {
                'vehicles': [],
                'seat_positions': []
            }

            vGOption.vehicle_types.forEach((vType) => {
                flatVehicleTypes.value.push(vType);
            })
        });

        return vehicles;
    }

    const resetVehicle = () => {
        chosenVehicle.value = defaultVehicleByGroup();
    }

    const setAssistanceTypeOptions = (atOptions : AssistanceTypeOptionsInterface[]) => {
        assistanceTypeOptions.value = atOptions;

        syncSeatAssistance();
    }

    const seatRequireHoist = () => {
        return seatTypeOptions.value.some(sto => sto.code === chosenSeat.value && sto.require_hoist);
    }

    const seatIsTransferable = () => {
        return seatTypeOptions.value.some(sto => sto.code === chosenSeat.value && sto.is_transferable);
    }

    const getSeatVehicleGroups = () => {
        return vehicleGroupOptions.value.filter((option) => {
            if (option.code === 'standard' &&
                seatRequireHoist()
            ) {
                return false;
            }

            return true;
        });
    }

    const getSeatAvailableVehiclesTypeInGroup = (vehicleGroup: VehicleGroupInterface) => {
        return vehicleGroup.vehicle_types.filter((vehicleType) => {
            if (!seatIsTransferable() && vehicleType.with_hoist === false) {
                return false;
            }

            if (seatRequireHoist() && vehicleType.with_hoist === false) {
                return false;
            }

            return true;
        });
    }

    const syncSeatAssistance = () => {
        const nonRemovableAssistanceType = assistanceTypeOptions.value.filter(
            (assistanceType) => {
                return assistanceType.seat_types.some(assistanceST => assistanceST.code === chosenSeat.value);
            }
        );

        selectedAssistanceTypes.value = nonRemovableAssistanceType.map((at) => {
            return {
                code: at.value,
                seat_type: chosenSeat.value
            }
        });

        filterOutSelectedAssistanceType();
    }

    const inSelectedAssistanceType = (assistance: AssistanceTypeOptionsInterface) => {
        return selectedAssistanceTypes.value.some(selectedAssistance => selectedAssistance.code === assistance.value);
    }

    const filterOutSelectedAssistanceType = () => {
        filteredAssistanceTypeOptions.value = assistanceTypeOptions.value.filter(
            (assistance) => {
                return !inSelectedAssistanceType(assistance);
            }
        );
    }

    const setCargoSpaceOptions = (options: CargoSpaceOptionsInterface[]) => {
        cargoSpaceOptions.value = options;

        syncSeatWithCargo();
    }

    const syncSeatWithCargo = () => {
        const nonRemovableCargo = cargoSpaceOptions.value.filter(
            (cargo) => {
                return cargo.seat_types.some(cargoST => cargoST.code === chosenSeat.value);
            }
        );

        selectedCargoSpaces.value = nonRemovableCargo.map((ca) => {
            return {
                code: ca.code,
                space: ca.space_required,
                seat_type: chosenSeat.value
            }
        });

        //filterOutSelectedAssistanceType();
        filterOutSelectedCargo();
    }

    const inSelectedCargo = (cargo: CargoSpaceOptionsInterface) => {
        return selectedCargoSpaces.value.some(selectedCargo => selectedCargo.code === cargo.code);
    }

    const filterOutSelectedCargo = () => {
        filteredCargoSpaceOptions.value = cargoSpaceOptions.value.filter(
            (cargo) => {
                return !inSelectedCargo(cargo);
            }
        );
    }

    const hasCategoryCode = (category: [], code: String): boolean => {
        if (category === undefined) {
            return false;
        }

        const categoryFound = category.find((c) => c.code === code);
        return categoryFound ? true : false;
    }

    const getGroupDetail = (groupCode: string) => {
        return vehicleGroupOptions.value.find(vgOptions => vgOptions.code === groupCode);
    }

    const getVehicleTypeDetail = (typeCode: string) => {
        return flatVehicleTypes.value.find(vType => vType.code === typeCode);
    }

    const getAssistanceTypeDetail = (atCode: string) => {
        return assistanceTypeOptions.value.find(option => atCode === option.value);
    }

    const getCargoSpaceDetail = (caCode: string) => {
        return cargoSpaceOptions.value.find(option => caCode === option.code);
    }

    const convertToPayload = (): BookingRequirementPayloadInterface => {
        const chosenVehicleTypes: any[] = [];

        for (const vehicleGroup in chosenVehicle.value) {
            const vehicleType = chosenVehicle.value[vehicleGroup];

            if (vehicleType.vehicles.length > 0) {
                vehicleType.vehicles.forEach((trCode, index) => {
                    chosenVehicleTypes.push({
                        code: trCode,
                        value: 1,
                        chosen_seat_positions: [...vehicleType.seat_positions],
                        group: vehicleGroup
                    })
                });
            }
        }

        let payload = {
            chosen_seat: chosenSeat.value,
            vehicle_types: [...chosenVehicleTypes],
            assistance_types: [...selectedAssistanceTypes.value],
            cargo_spaces: [...selectedCargoSpaces.value],
            affected_bookings: affectedBookings.value
        }

        if (affectedBookings.value === 'date_range') {
            payload.affected_bookings_from = affectedBookingsFrom.value.value;
            payload.affected_bookings_to = affectedBookingsTo.value.value
        }

        return payload;
    }

    const convertVehicleTypesFromPayload = (vehicleTypes) => {
        const thisChosenVehicle = defaultVehicleByGroup();

        vehicleTypes.forEach((vehicleType) => {
            if (!thisChosenVehicle[vehicleType.group].vehicles.includes(vehicleType.code)) {
                thisChosenVehicle[vehicleType.group].vehicles.push(vehicleType.code);
            }
            thisChosenVehicle[vehicleType.group].seat_positions = vehicleType.chosen_seat_positions;
        });

        return thisChosenVehicle;
    }

    const convertFromPayload = (bookingRequirementObj: BookingRequirementPayloadInterface) => {
        chosenSeat.value = bookingRequirementObj.chosen_seat;

        if (bookingRequirementObj.vehicle_types) {
            chosenVehicle.value = convertVehicleTypesFromPayload(bookingRequirementObj.vehicle_types);
        }

        const assistanceTypes: AssistanceTypeDetailsObjectInterface[] = [];
        if (bookingRequirementObj.assistance_types !== undefined) {
            bookingRequirementObj.assistance_types.forEach((at: AssistanceTypeDetailsObjectInterface) => {
                const atDetail = getAssistanceTypeDetail(at.code);
                if (atDetail) {
                    if (atDetail.seat_types.some(atSeatType => atSeatType.code === chosenSeat.value)) {
                        at.seat_type = chosenSeat.value;
                    }

                    assistanceTypes.push(at);
                }
            });
        }

        selectedAssistanceTypes.value = assistanceTypes;

        const cargoSpaces: CargoSpaceFormDetailsInterface[] = [];
        if (bookingRequirementObj.cargo_spaces !== undefined) {
            selectedCargoSpaces.value = bookingRequirementObj.cargo_spaces;

            bookingRequirementObj.cargo_spaces.forEach((cs: CargoSpaceFormDetailsInterface) => {
                const cargoSpaceDetail = getCargoSpaceDetail(cs.code);
                if (cargoSpaceDetail) {
                    if (cargoSpaceDetail.seat_types.some(csSeatType => csSeatType.code === chosenSeat.value)) {
                        cs.seat_type = chosenSeat.value;
                    }

                    cargoSpaces.push(cs);
                }
            });
        }

        selectedCargoSpaces.value = cargoSpaces;
    }

    return {
        seatTypeOptions,
        setSeatTypeOptions,
        vehicleGroupOptions,
        setVehicleGroupOptions,
        seatPositionList,
        getSeatPositionDetail,
        assistanceTypeOptions,
        getSeatVehicleGroups,
        getSeatAvailableVehiclesTypeInGroup,
        filteredAssistanceTypeOptions,
        setAssistanceTypeOptions,
        seatRequireHoist,
        seatIsTransferable,
        selectedAssistanceTypes,
        cargoSpaceOptions,
        filteredCargoSpaceOptions,
        chosenSeat,
        chosenVehicle,
        resetSeat,
        syncSeatAssistance,
        filterOutSelectedAssistanceType,
        filterOutSelectedCargo,
        setCargoSpaceOptions,
        selectedCargoSpaces,
        syncSeatWithCargo,
        hasCategoryCode,
        getGroupDetail,
        getVehicleTypeDetail,
        getAssistanceTypeDetail,
        getCargoSpaceDetail,
        convertToPayload,
        convertFromPayload,
        convertVehicleTypesFromPayload,
        resetVehicle,
        defaultVehicleByGroup,
        fillBeforeSaveData,
        cancelToBeforeSaveData,
        affectedBookings,
        affectedBookingsFrom,
        affectedBookingsTo,
    }
})
