<template>
    <div
        class="card"
        @mouseover="showEditButton = true"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Depot</h4>
                <div v-if="showEditButton && !showEditForm">
                    <Button @click="showEditForm = true" color="link" size="small">
                        <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                    </Button>
                </div>
            </div>
            <div v-for="(depot, index) in draftDriverDepots">
                <div class="row">
                    <div class="col-12 config-row">
                        <div class="config-col card bg-light">
                            <div class="card-body">
                                <div v-if="showEditForm" class="d-flex justify-content-end">
                                    <a @click.prevent="removeSelected(depot.uuid)" href="javascript: void(0)">
                                        <i class="mdi mdi-close"></i>
                                    </a>
                                </div>
                                <div class="d-flex config-row">
                                    <div class="col-4">
                                        <strong>Name: </strong>
                                    </div>
                                    <div class="col-8">
                                        <span>{{ depot.name }}</span>
                                    </div>
                                </div>
                                <div class="d-flex config-row">
                                    <div class="col-4">
                                        <strong>Address: </strong>
                                    </div>
                                    <div class="col-8">
                                        <span>{{ depot.full_address }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="showEditForm">
                <div class="d-flex config-row mb-2">
                    <div  class="col-11 config-col">
                        <select v-model="selectedDepot" class="form-select">
                            <option value="null" disabled>Please select depot to add</option>
                            <option v-for="(option, index) in filteredOptions" :value="option.uuid" :key="index">
                                {{ option.full_address }}
                            </option>
                        </select>
                    </div>
                    <div class="col-1 config-col justify-content-center">
                        <button @click="addSelected" class="h-100 w-100" :disable="!selectedDepot" v-tooltip="'Add Selected Depot'">
                            <SvgIcon type="mdi" :path="mdiPlusCircle" class="text-primary" size="30"></SvgIcon>
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                    <Button size="small" :loading="updating" @click="update">Save</Button>
                    <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref, inject, onBeforeMount, watch, computed, onMounted, toRef} from 'vue';
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiPencil, mdiPlusCircle} from '@mdi/js';
import { Button } from '../';
import {useDepotStore} from "@stores/DepotStore";
import {storeToRefs} from "pinia";
import { cloneDeep } from "lodash";

const depotStore = useDepotStore();
const { depotList } = storeToRefs(depotStore);

const axios = inject('axios');
const toast = inject('toast');
const selectedDepot = ref(null);
const updating = ref(false);

const { driver } = defineProps({
    driver: {
        required: true,
        type: Object,
    },
});

const emit = defineEmits(['updated']);
const draftDriverDepots = ref([]);

const filteredOptions = computed(() => {
    let used = (draftDriverDepots.value ?? []).map((depot) => {
        return depot.uuid;
    });

    let selectable = depotList.value.filter((item) => {
        return !used.includes(item.uuid);
    });

    return selectable;
});

const showEditButton = ref(false);
const showEditForm = ref(false);

const addSelected = () => {
    let option = filteredOptions.value.find((option) => {
        return option.uuid === selectedDepot.value
    });

    if (!option) {
        let message = 'Unable to add selected Option';
        toast.error(message, { title: 'OOPs something went wrong'})
        return
    }

    draftDriverDepots.value.push(option);
}

const update = () => {
    const depotUuids = draftDriverDepots.value.map((dd) => {
        return dd.uuid;
    })

    axios.put(route('api.drivers.update', { driver: driver.uuid }), {
        depots: depotUuids
    }).then((response) => {
        response.data;
        showEditForm.value = false;
    }).finally(() => {
        toast.success('Driver depots successfully updated');
    });
}

const cancelChanges = () => {
    showEditForm.value = false;
}

const removeSelected = (index) => {
    const depots = cloneDeep(draftDriverDepots.value);
    depots.splice(index, 1);

    draftDriverDepots.value = depots;
}

onMounted(() => {
    draftDriverDepots.value = driver.depots ?? [];
})
</script>

<style scoped>

</style>
