import axios from "axios";

export default class Region {   
    static async index(data) {
        return await axios.get<App.Pagination.ApiResourceResponseInterface<App.Resource.Attachment[]>>(route("attachments.index", data));
    }

    static async store(data) {
        return await axios.post<App.Pagination.ApiResourceResponseInterface<App.Attachment.Upload>>(route('attachments.store'), data);
    }

    static async bulk(data) {
        return await axios.post<App.Pagination.ApiResourceResponseInterface<App.Attachment.BulkUpload>>(route('attachments.bulk'), data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async update(attachment, data) {
        return await axios.put<App.Pagination.ApiResourceResponseInterface<App.Attachment.Update>>(route('attachments.update', attachment), data);
    }

    static async destroy(attachment) {
        return await axios.delete<string>(route('attachments.destroy', attachment));
    }

    static async extensionIndex() {
        return await axios.get<App.Attachment.ExtensionsRequest>(route('attachments.extensions'));
    }
}