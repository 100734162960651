<template>
    <div>
        <div class="dropdown">
            <input type="search" class="form-control" :id="'driverSearchInput_' + uuid" v-model="query" @keyup="search" :placeholder="placeholder">
            <button class="visually-hidden" :id="'dropdownTrigger_' + uuid" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" v-dropdown></button>
            <ul class="dropdown-menu dropdown-menu-animated" id="driverResultsDropdown">
                <li v-for="member in results">
                    <a class="dropdown-item" href="javascript: void(0)" @click="select(member)">{{ member.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchDriver",
    data() {
        return {
            query: '',
            selectedMember: null,
            results: [],
            dropdown: null,
            uuid: this.$uuid.v1(),
        }
    },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: 'Search Drivers...',
        },
        retainWhenSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        results: function (results) {
            (results.length > 0) ? this.dropdown.show() : this.dropdown.hide()
            document.getElementById('driverSearchInput_' + this.uuid).focus()
        }
    },
    emits: [
        'memberSelected',
    ],
    methods: {
        search() {
            if (this.query.trim().length === 0) {
                this.dropdown.hide()
                return
            }

            this.$http
                .get(this.route('api.drivers.index', { query: this.query }))
                .then(response => this.results = response.data.data)
                .catch((err) => console.error(err));
        },
        select(member) {
            this.selectedMember = member
            this.query = this.retainWhenSelected ? this.selectedMember.name : ''
            this.results = []
            this.$emit('memberSelected', member)
        }
    },
    mounted() {
        this.dropdown = new bootstrap.Dropdown(document.getElementById('dropdownTrigger_' + this.uuid))
    }
}
</script>

<style scoped>

</style>
