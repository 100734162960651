<template>
    <div>
        <SendMessage :receiver-type="'client'" :receiver-info="clientInfo">
            <slot></slot>
        </SendMessage>
    </div>
</template>

<script>
import SendMessage from "./SendMessage.vue";
export default {
    name: "MessageCrew",
    components: {
        SendMessage,
    },
    data() {
        return {
            clientInfo: null
        }
    },
    props: {
        client: {
            type: Object,
            required: true,
        }
    },
    beforeMount() {
        this.clientInfo = {
            id: this.client.uuid,
            name: this.client.name,
        }
    }
}
</script>

<style scoped>

</style>
