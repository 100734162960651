import {
    BookingFormStopInterface,
    DraftBookingRequestInterface,
    StorableOptimizerEstimatedTimingsRequestOptions,
} from "../../stores/BookingStore";
import { ClientInterface } from "../Client";
import { LocationInterface } from "../Location";
import { OptimizerEstimatedTimingsRequestOptions } from "../Optimiser";
import _ from "lodash";
import BookingFormHelper from "./BookingFormHelper";

//This class provides a few helper functions for booking form and optimizer class interactions
export default class BookingFormOptimizerHelper {
    //This function will create all the different parameters for the estimated timings we need for the optimizer
    //we can use this function to compare different states of booking form and only make requests for option/paramters that have been changed or don't already have estimated timings stored
    static getEstimatedTimingParametersForBookingForm(
        draftState: DraftBookingRequestInterface
    ) {
        let result: StorableOptimizerEstimatedTimingsRequestOptions[] = [];

        draftState.stops.forEach((stop, stopIndex) => {
            if (stopIndex == 0) {
                return;
            }

            const previousStop = draftState.stops[stopIndex - 1];

            draftState.clients.filter(i => i.uuid == draftState.selectedClientUuid).forEach((client) => {
                let { origin, destination } =
                    BookingFormHelper.getOriginDestinationForCurrentStop({
                        previousStop,
                        stop,
                        client,
                    });

                let originLocationUuid = origin?.uuid;
                let destinationLocationUuid = destination?.uuid;

                if (!originLocationUuid || !destinationLocationUuid) {
                    return;
                }

                if (!stop?.time) {
                    return;
                }

                let originTime = {
                    ...(stop?.scheduleType === "pick_up"
                        ? {
                              arrive: stop?.time ?? null,
                              depart: null,
                          }
                        : {
                              arrive: null,
                              depart: null,
                          }),
                    ...{
                        duration: 0,
                        flexibility: {
                            late: stop.flexibilityAfter,
                            early: stop.flexibilityBefore,
                        },
                    },
                };

                let destinationTime = {
                    ...(stop?.scheduleType === "drop_off"
                        ? {
                              arrive: stop?.time ?? null,
                              depart: null,
                          }
                        : {
                              arrive: null,
                              depart: null,
                          }),
                    ...{
                        duration: 0,
                        flexibility: {
                            late: stop.flexibilityAfter,
                            early: stop.flexibilityBefore,
                        },
                    },
                };

                let estimatedTimingsOptions: OptimizerEstimatedTimingsRequestOptions =
                    {
                        stops: [
                            [originLocationUuid],
                            [destinationLocationUuid],
                        ],
                        timings: [originTime, destinationTime],
                    };

                result.push({
                    client_uuid: client.uuid,
                    stopIndex,
                    options: estimatedTimingsOptions,
                });
            });
        });

        return result;
    }

    //This is lenghth! But nothing else works I always get not equal (incorrectly) when I try lodash or json encode. Resulting in unwanted Optimizer requests
    static isOptimiserEstimatedTimingsRequestOptionsEqual(
        a: OptimizerEstimatedTimingsRequestOptions,
        b: OptimizerEstimatedTimingsRequestOptions
    ) {
        let isEqual = true;

        a.stops.forEach((aStop, stopIndex) => {
            let bStop = b.stops?.[stopIndex];

            if (!_.isEqual(bStop, aStop)) {
                isEqual = false;
            }
        });

        a.timings.forEach((aTiming, timingIndex) => {
            let bTiming = b.timings?.[timingIndex];

            let arriveChanged =
                aTiming.arrive?.toFormat("HH:mm") !==
                bTiming.arrive?.toFormat("HH:mm");
            let departChanged =
                aTiming.depart?.toFormat("HH:mm") !==
                bTiming.depart?.toFormat("HH:mm");
            let earlyFlexibilityChanged =
                aTiming?.flexibility?.early !== bTiming?.flexibility?.early;
            let lateFlexibilityChanged =
                aTiming?.flexibility?.late !== bTiming?.flexibility?.late;

            if (
                arriveChanged ||
                departChanged ||
                earlyFlexibilityChanged ||
                lateFlexibilityChanged
            ) {
                isEqual = false;
            }
        });

        return isEqual;
    }

    //This function gets an old booking draft and a new booking draft when the booking form is changed and determines stops have adjustmenst made within them so we don't send unneccessary requests to the optimizer
    static getEstimatedTimingsWithAdjustedParametersBetweenOldAndNewBookingStoreDraft(
        draft: DraftBookingRequestInterface,
        oldDraft: DraftBookingRequestInterface
    ) {
        let currentDraftParameters =
            BookingFormOptimizerHelper.getEstimatedTimingParametersForBookingForm(
                draft
            );

        let oldDraftParameters =
            BookingFormOptimizerHelper.getEstimatedTimingParametersForBookingForm(
                oldDraft
            );

        return currentDraftParameters.filter((estimatedTimingParameter) => {
            let matchingEstimatedTimingInOldDraft = oldDraftParameters.find(
                (i) =>
                    i.client_uuid == estimatedTimingParameter.client_uuid &&
                    i.stopIndex == estimatedTimingParameter.stopIndex
            );

            //Meaning a new client/stop has been added
            if (!matchingEstimatedTimingInOldDraft) {
                return true;
            }

            const optimiserParametersEqual =
                BookingFormOptimizerHelper.isOptimiserEstimatedTimingsRequestOptionsEqual(
                    matchingEstimatedTimingInOldDraft.options,
                    estimatedTimingParameter.options
                );

            if (!optimiserParametersEqual) {
                return true;
            }

            return false;
        });
    }
}
