import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";

export interface BookingTagInterface {
    readonly uuid?: string,
    code: string,
    description: string,
}

export interface PaginatedBookingTagInterface {
    data:BookingTagInterface[]
    meta:{
        current_page: number,
        from: number,
        last_page: number,
        per_page: number,
        to: number,
        total: number,
        path: string,
        links: string[],
    }
}

export class BookingTag {
    static async index() {
        return axios.get<LaravelResourceResponse<BookingTagInterface[]>>(
            route("api.booking-tags.index"),
        );
    }

    static async getPage(page:number): Promise<PaginatedBookingTagInterface> {
        let response: any = await axios.get(
            route("booking-tags.index", {paginate:true, page:page}),
        );
        return {data:response?.data.data, meta:response?.data.meta};
    }

    static async get(uuid): Promise<BookingTagInterface> {
        let response: any = await axios.get(
            route("booking-tags.show", {booking_tag: uuid})
        );
        return response?.data.data;
    }

    static async create(tag: BookingTagInterface): Promise<BookingTagInterface> {
        let response: any = await axios.post(
            route("booking-tags.store"),
            tag
        );
        return response?.data.data;
    }

    static async update(tag: BookingTagInterface): Promise<BookingTagInterface> {
        let response: any = await axios.put(
            route("booking-tags.update", {booking_tag: tag.uuid}),
            tag
        );
        return response?.data.data;
    }

    static async destroy(uuid: string): Promise<boolean> {
        let response:any = await axios.delete(
            route("booking-tags.destroy", {booking_tag: uuid})
        );
        return response?.data.data;
    }
}
