<template>
    <div v-if="rescheduleOptions">
        <div class="row">
            <div class="col-md-12">
                <label class="form-label"
                    >Reschedule this {{ props.eventType }}</label
                >
                <select class="form-select" v-model="rescheduleOptions.period">
                    <option value="day">Daily</option>
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                </select>
            </div>
        </div>
        <div class="mt-3">
            <template v-if="rescheduleOptions.period === 'day'">
                <div class="d-flex align-items-center">
                    Every
                    <input
                        v-model="rescheduleOptions.frequency"
                        class="form-control form-control-sm mx-1 inline-number-input"
                        max="365"
                        min="1"
                        type="number"
                    />day(s)
                </div>
            </template>
            <template v-else-if="rescheduleOptions.period === 'week'">
                <div class="d-flex align-items-center mb-2">
                    Every<input
                        v-model="rescheduleOptions.frequency"
                        max="365"
                        min="1"
                        type="number"
                        class="form-control form-control-sm mx-1 inline-number-input"
                    />week(s) on:
                </div>
                <div
                    class="btn-group btn-group-sm days-of-week-button-group"
                    role="group"
                    aria-label="Days of the week"
                >
                    <template v-for="(enabled, day) in weekdays">
                        <input
                            @change="(e) => weekdayChange(e, day)"
                            :checked="
                                rescheduleOptions.weekdays?.find(
                                    (i) =>
                                        i == convertWeekdaysToRruleWeekdays(day)
                                )
                                    ? true
                                    : false
                            "
                            type="checkbox"
                            class="btn-check"
                            :id="'reschedule_' + day"
                            autocomplete="off"
                        />
                        <label
                            :for="'reschedule_' + day"
                            class="btn btn-outline-secondary"
                            >{{ Filters.ucwords(day) }}</label
                        >
                    </template>
                </div>
            </template>
            <template v-else-if="rescheduleOptions.period === 'month'">
                <div class="d-flex align-items-center mb-2">
                    Every<input
                        v-model="rescheduleOptions.frequency"
                        type="number"
                        min="1"
                        class="form-control form-control-sm mx-1 inline-number-input"
                    />month(s):
                </div>
                <div class="mb-2">
                    <FormSwitch v-model="monthExactChecked" label="Each" />
                    <div
                        class="btn-group btn-group-sm btn-matrix btn-matrix-month mt-2"
                        role="group"
                    >
                        <template v-for="dayInMonth in 31">
                            <input
                                type="checkbox"
                                class="btn-check"
                                :disabled="
                                    rescheduleOptions.monthly !== 'exact'
                                "
                                :id="'reschedule_monthly_' + dayInMonth"
                                @change="(e) => monthDayChange(e, dayInMonth)"
                                :checked="
                                    rescheduleOptions.monthDays.find(
                                        (i) => +i == +dayInMonth
                                    )
                                        ? true
                                        : false
                                "
                                autocomplete="off"
                            />
                            <label
                                :for="'reschedule_monthly_' + dayInMonth"
                                class="btn btn-outline-secondary"
                                >{{ dayInMonth }}</label
                            >
                        </template>
                    </div>
                </div>
                <div class="mt-2">
                    <FormSwitch v-model="monthAbstractChecked" label="On the" />

                    <div class="row align-items-center mt-1">
                        <div class="col-sm-6">
                            <select
                                class="form-select"
                                :disabled="
                                    rescheduleOptions.monthly !== 'abstract'
                                "
                                v-model="rescheduleOptions.dynamicMonthDay"
                            >
                                <option value="1">first</option>
                                <option value="2">second</option>
                                <option value="3">third</option>
                                <option value="4">fourth</option>
                                <option value="5">fifth</option>
                                <option disabled>──────────</option>
                                <option value="last">last</option>
                            </select>
                        </div>
                        <div class="col-sm-6">
                            <select
                                class="form-select"
                                :disabled="
                                    rescheduleOptions.monthly !== 'abstract'
                                "
                                v-model="rescheduleOptions.dynamicMonthWeekday"
                            >
                                <option value="sun">Sunday</option>
                                <option value="mon">Monday</option>
                                <option value="tue">Tuesday</option>
                                <option value="wed">Wednesday</option>
                                <option value="thu">Thursday</option>
                                <option value="fri">Friday</option>
                                <option value="sat">Saturday</option>
                                <option disabled>──────────</option>
                                <option value="day">day</option>
                                <option value="weekday">weekday</option>
                                <option value="weekend_day">weekend day</option>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-if="!requireEndDate" class="mt-3">
            <input
                id="reschedule_end_date"
                v-model="rescheduleOptions.continuous"
                class="form-check-input"
                type="checkbox"
                value=""
            />
            <label class="form-check-label ms-1" for="reschedule_end_date">
                No end date
            </label>
        </div>
        <div v-if="!rescheduleOptions.continuous" class="mt-3">
            <div class="row">
                <div class="col-md-12">
                    <label for="recurringUntilDate" class="form-label"
                        >Until:</label
                    >
                    <input
                        type="date"
                        v-model="end"
                        id="recurringUntilDate"
                        :class="{
                            'form-control': true,
                            'is-invalid': errors?.['recursion.end'],
                        }"
                        :min="
                            DateTime.now()
                                .plus({ day: 1 })
                                .toFormat('yyyy-MM-dd')
                        "
                        :max="
                            DateTime.now()
                                .plus({ year: 1 })
                                .toFormat('yyyy-MM-dd')
                        "
                    />
                    <p
                        v-if="errors?.['recursion.end']"
                        class="invalid-feedback d-block"
                    >
                        {{ errors?.["recursion.end"]?.[0] }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, onMounted, toRef } from "vue";
import { DateTime } from "luxon";
import Filters from "@classes/Helpers/Filters";
import { RecurringBookingOptions } from "@classes/RecursiveBooking";
import FormSwitch from "./Forms/FormSwitch.vue";

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
    defineProps<{
        modelValue: RecurringBookingOptions | null;
        eventType: string;
        errors?: object;
    }>(),
    { errors: undefined }
);

//Hard code this to true as currently infinite recurring bookings are not supported in backend
const requireEndDate = ref(true);

const rescheduleOptions = toRef(props, "modelValue");

const weekdays = ref({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
});
const selectedWeekdays = computed(() => {
    return Object.keys(weekdays.value).filter((day) => weekdays.value[day]);
});

const monthDays = ref([]);
const selectedMonthDays = computed(() => {
    return Object.keys(monthDays.value).filter((day) => monthDays.value[day]);
});

const convertWeekdaysToRruleWeekdays = (day: string) => {
    return day.substring(0, 2).toUpperCase();
};

onMounted(() => {
    if (!props.modelValue) {
        emit("update:modelValue", {
            period: "day",
            frequency: "1",
            weekdays: selectedWeekdays,
            monthDays: selectedMonthDays,
            monthly: "exact",
            end: DateTime.now().plus({ months: 2 }).toSeconds().toFixed(0),
            continuous: !requireEndDate.value,
            dynamicMonthDay: "1",
            dynamicMonthWeekday: "day",
        });
    }
});

const end = computed({
    get() {
        let result = rescheduleOptions.value?.end
            ? DateTime.fromSeconds(+rescheduleOptions.value?.end).toFormat(
                  "yyyy-MM-dd"
              )
            : DateTime.now().plus({ months: 2 }).toFormat("yyyy-MM-dd");

        return result;
    },
    set(newValue) {
        emit("update:modelValue", {
            ...rescheduleOptions.value,
            ...{
                end: DateTime.fromFormat(newValue, "yyyy-MM-dd")
                    .toSeconds()
                    .toString(),
            },
        });
    },
});

const weekdayChange = (e: Event, day: string) => {
    let result = new Event("");

    let newWeekdays = [...(rescheduleOptions.value?.weekdays ?? [])];

    //@ts-ignore
    let checked: boolean = e.target?.checked ?? false;

    if (checked) {
        newWeekdays.push(convertWeekdaysToRruleWeekdays(day.toLowerCase()));
    } else {
        newWeekdays = newWeekdays.filter(
            (i) => i !== convertWeekdaysToRruleWeekdays(day)
        );
    }

    emit("update:modelValue", {
        ...rescheduleOptions.value,
        ...{ weekdays: newWeekdays },
    });

    return result;
};

const monthDayChange = (e: Event, day: number) => {
    let result = new Event("");

    //@ts-ignore
    let checked: boolean = e.target?.checked ?? false;

    let newMonthDays = [...(rescheduleOptions.value?.monthDays ?? [])];

    if (checked) {
        newMonthDays.push(day.toString());
    } else {
        newMonthDays = newMonthDays.filter((i) => +i !== +day);
    }

    emit("update:modelValue", {
        ...rescheduleOptions.value,
        ...{ monthDays: newMonthDays },
    });

    return result;
};

const monthExactChecked = computed({
    get() {
        return rescheduleOptions.value?.monthly == "exact";
    },
    set(newValue) {
        emit("update:modelValue", {
            ...rescheduleOptions.value,
            ...{ monthly: newValue ? "exact" : "abstract" },
        });
    },
});

const monthAbstractChecked = computed({
    get() {
        return rescheduleOptions.value?.monthly == "abstract";
    },
    set(newValue) {
        emit("update:modelValue", {
            ...rescheduleOptions.value,
            ...{ monthly: newValue ? "abstract" : "exact" },
        });
    },
});
</script>

<style scoped>
.inline-number-input {
    max-width: 60px;
}

.days-of-week-button-group .btn {
    min-width: 60px;
}

/* Arrange buttons */
.btn-matrix {
    flex-wrap: wrap; /* allow buttons to jump to another row */
    max-width: 420px;
}

.btn-matrix > .btn {
    margin-top: -1px;
}

.btn-matrix-month > .btn {
    width: 60px;
}

.btn-matrix-year > .btn {
    width: 105px;
}

/* Fix border radius */
.btn-matrix > .btn:first-child {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-matrix-month > .btn:nth-child(14) {
    border-top-right-radius: 4px !important;
}

.btn-matrix-month > .btn:nth-last-child(5) {
    border-bottom-left-radius: 4px !important;
}

.btn-matrix > .btn:last-child {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 0;
}
</style>
