<template>
    <DriverCreateOrEditForm
        :form="form"
        :abilities="abilities"
        :licenceClasses="licenceClasses"
        mode="create"
    />
</template>

<script setup>
import { ref, reactive, inject, computed, onMounted, nextTick } from 'vue'
import { transformInput } from '../Utils'
import DriverCreateOrEditForm from './DriverCreateOrEditForm.vue'

const { abilities, licenceClasses } = defineProps({
    abilities: {
        required: true,
        type: Array,
    },
    licenceClasses: {
        required: true,
        type: Array,
    },
})

const emergencyContactForm = () => ({
    name: transformInput({value: ''}),
    address: transformInput({value: ''}),
    full_address: transformInput({value: ''}),
    role: transformInput({value: '', required: false}),
    organisation: transformInput({value: '', required: false}),
    work_phone: transformInput({value: ''}),
    home_phone: transformInput({value: ''}),
    mobile_phone: transformInput({value: ''}),
    fax: transformInput({value: ''}),
    relationship: transformInput({value: '', required: false}),
    email: transformInput({ value: '', required: false }),
    is_primary: false,
})

const equipmentForm = () => ({
    description: transformInput({
        value: '',
        required: true,
    }),
    identifier: transformInput({
        value: '',
        required: false,
        valid: true,
        validation: yup.string().max(255)
    }),
    issued_date: transformInput({
        value: '',
        required: true,
        validation: yup.date().required()
    }),
})

const form = reactive({
    given_names: transformInput({ value: '' }),
    last_name: transformInput({ value: '' }),
    username: transformInput({ value: '' }),
    email: transformInput({ value: '' }),
    address: transformInput({ value: '', required: true }),
    indigenous_status: transformInput({ value: '' }),
    gender: transformInput({value: ''}),
    date_of_birth: transformInput({ value: '' }),
    joined_date: transformInput({ value: '' }),
    region: transformInput({ value: '' }),
    app_access: transformInput({ value: false }),
    phone: transformInput({ value: '' }),
    employment_type: transformInput({ value: '' }),
    driver_type: transformInput({ value: '' }),
    ndis_worker_expiry_date: transformInput({ value: '', required: false }),
    demerit_check_date: transformInput({ value: '', required: false }),
    driver_authority_expiry_date: transformInput({ value: '' }),
    driver_record_check_date: transformInput({ value: '' }),
    licence_expiry_date: transformInput({ value: '' }),
    medical_expiry_date: transformInput({ value: '' }),
    police_check_expiry_date: transformInput({ value: '' }),
    annual_driver_training_completed_date: transformInput({ value: '', required: false }),
    covid_safe_plan_signed_date: transformInput({ value: '', required: false }),
    flu_vaccine_date: transformInput({ value: '', required: false }),
    licence_classes: [],
    compliance: [],
    languages_spoken: [],
    tags: [],
    equipment: [],
    emergency_contacts: [],
    abilities: [],
})
</script>
