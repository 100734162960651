<template>
    <div
        class="card"
        @mouseover="showEditButton = !client.deactivated"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Assessment Details</h4>
                <div v-if="showEditButton && !showEditForm">
                    <Button @click="updateEditForm" color="link" size="small">
                        <SvgIcon class="w-4 h-4 me-1" type="mdi" :path="mdiPencil"></SvgIcon> Edit
                    </Button>
                </div>
            </div>
            <template v-if="!showEditForm">
                <div class="row  mb-1">
                    <div class="col-6">
                        <strong>Last Assessment Date:</strong>
                    </div>
                    <div class="col-6">
                        <DateTime v-if="client.last_assessment_date" :date-time="client.last_assessment_date" format="medium date"/>
                        <template v-else>Not specified</template>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-6">
                        <strong>Assessment Frequency:</strong>
                    </div>
                    <div class="col-6">
                        {{ client.assessment_frequency ? `${client.assessment_frequency} months` : 'Not specified'  }}
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-6">
                        <strong>Next Assessment Due:</strong>
                    </div>
                    <div class="col-6">
                        <DateTime v-if="client.next_assessment_due" :date-time="client.next_assessment_due" format="medium date"/>
                        <template v-else>Not specified</template>
                    </div>
                </div>
            </template>
            <template v-if="showEditForm">
                <template v-if="initialised">
                    <ClientAssessmentForm :client="clientValue" @updated="updateClient" />
                </template>
                <div class="d-flex align-items-center justify-content-end mt-3">
                    <Button size="small" :loading="updating" @click="update">Save</Button>
                    <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onBeforeMount, watchEffect, inject, computed } from "vue";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiPencil } from "@mdi/js";
    import Button from "../Button.vue";
    import ClientAssessmentForm from './ClientAssessmentForm.vue'
    import {transformInput, copyValues, onReady, toTimeStamp} from '../Utils'
    import { DateTime as Luxon } from 'luxon'
    import DateTime from '../DateTime.vue'

    const axios = inject('axios')

    const { client } = defineProps({
        client: {
            required: true,
            type: Object,
        }
    })

    const emit = defineEmits(['updated'])

    const showEditButton = ref(false)
    const showEditForm = ref(false)

    const clientValue = ref({})

    const initialised = ref(false)

    const initialiseData = function () {
        clientValue.value = copyValues(client)

        clientValue.value.last_assessment_date = transformInput({
            value: client.last_assessment_date ? Luxon.fromISO(client.last_assessment_date).toISODate() : null
        })

        clientValue.value.assessment_frequency = transformInput({
            value: client.assessment_frequency ?? null
        })

        initialised.value = true
    }

    const cancelChanges = function () {
        showEditForm.value = false
        initialiseData()
    }

    const updating = ref(false)
    const update = function () {
        updating.value = true

        let data = {
            last_assessment_date: toTimeStamp(clientValue.value.last_assessment_date.value),
            assessment_frequency: clientValue.value.assessment_frequency.value,
        }

        axios
            .put(route('api.clients.update', { client: client.uuid }), data)
            .then((response) => {
                showEditForm.value = false
                emit('updated', response.data)
            })
            .finally(() => {
                updating.value = false
            })
    }

    const updateClient = (value) => {
        clientValue.value.last_assessment_date = value.last_assessment_date
        clientValue.value.assessment_frequency = value.assessment_frequency
    }

    const updateEditForm = () => {
        initialiseData()
        showEditForm.value = true
    }

</script>

<style scoped>

</style>
