import { BookingFormStopInterface } from "../../stores/BookingStore";
import { ClientInterface } from "../Client";
import { LocationInterface } from "../Location";

export default class BookingFormHelper {
    static getOriginDestinationForCurrentStop(parameters: {
        previousStop: BookingFormStopInterface;
        stop: BookingFormStopInterface;
        client: ClientInterface | null;
        allClients?: ClientInterface[];
    }): {
        origin: LocationInterface | null;
        destination: LocationInterface | null;
        isOriginClientHome: boolean;
        isDestinationClientHome: boolean;
        multiOrigin: undefined | LocationInterface[];
        multiDestination: undefined | LocationInterface[];
    } {
        const { previousStop, stop, client, allClients } = parameters;
        const getLocationForStop = (selectedStop: BookingFormStopInterface) => {
            return selectedStop.useClientHomeAsLocation
                ? client?.address ?? null
                : selectedStop.location;
        };

        let multiOrigin: undefined | LocationInterface[] = undefined;
        if (
            stop.usePreviousStopLocationAsOrigin &&
            previousStop.useClientHomeAsLocation &&
            allClients
        ) {
            multiOrigin = allClients.map((client) => client.address);
        }

        let multiDestination: undefined | LocationInterface[] = undefined;
        if (stop.useClientHomeAsLocation && allClients) {
            multiDestination = allClients.map((client) => client.address);
        }

        return {
            origin: stop.usePreviousStopLocationAsOrigin
                ? getLocationForStop(previousStop)
                : stop.originLocation,
            destination: getLocationForStop(stop),
            isOriginClientHome: stop.usePreviousStopLocationAsOrigin
                ? previousStop.useClientHomeAsLocation
                : false,
            isDestinationClientHome: stop.useClientHomeAsLocation,
            multiOrigin,
            multiDestination,
        };
    }
}
