<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-3"><strong>Category</strong></div>
                <div class="col"><strong>Date Range</strong></div>
            </div>
            <div class="row mt-1">
                <div class="col-3">Select a category to view activity</div>
                <div class="col-2">From</div>
                <div class="col-2">Until</div>
                <div class="col"></div>
            </div>
            <div class="row mt-1">
                <div class="col-3">
                    <select class="form form-control" v-model="store.filters.category">
                        <option value="invoices">Invoices</option>
                        <option value="transactions">Transactions</option>
                    </select>
                </div>
                <div class="col-2">
                    <input type="date" class="form form-control" v-model="store.filters.dateFrom"/>
                </div>
                <div class="col-2">
                    <input type="date" class="form form-control" v-model="store.filters.dateTo"/>
                </div>
                <div class="col"></div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <Button title="View" @click="store.getAllInvoices()">View</Button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h5 class="text-uppercase">Invoices</h5>
                    {{ store.filters.dateFrom ? DateTime.fromSQL(store.filters.dateFrom).toLocaleString(DateTime.DATE_FULL) : '' }} -
                    {{ store.filters.dateTo ? DateTime.fromSQL(store.filters.dateTo).toLocaleString(DateTime.DATE_FULL) : '' }}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <PageLengthSelector record-name="invoices" store-name="InvoiceStore"/>
                </div>
                <div class="col text-end">
                    <div class="d-inline-block me-2">
                        <input type="checkbox" class="btn-check" id="btn-check-outlined" autocomplete="off" @change="toggleMode($event)"/>
                        <label class="btn btn-sm btn-outline-primary" for="btn-check-outlined">
                            <SvgIcon type="mdi" :path="mdiFileEdit"></SvgIcon>
                            Draft Bulk Selection
                        </label>
                    </div>
                    <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                        <button
                            class="btn btn-light btn-sm dropdown-toggle"
                            type="button"
                            id="dropDownFilterClients"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <SvgIcon type="mdi" :path="mdiFilter"></SvgIcon>
                            Filter
                        </button>
                        <div class="dropdown-menu px-4 py-3" style="min-width: 500px;"
                             aria-describedby="dropDownFilterBookings">
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Status:</label>
                                <VueMultiselect
                                    v-model="store.filters.statuses"
                                    :options="store.defaultStatuses"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Statuses"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Collection method:</label>
                                <VueMultiselect
                                    v-model="store.filters.collectionMethods"
                                    :options="store.defaultCollectionMethods"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Collection Methods"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2 text-end">
                                <Button title="Apply" @click="store.getAllInvoices()" color="light">Apply</Button>
                            </div>
                        </div>
                    </div>
                    <DataTableSearch
                        @search="store.getAllInvoices()"
                        placeholder="Search invoices ..."
                        store="InvoiceStore"
                    />
                </div>
            </div>
            <div class="row mt-2" v-if="displayMode === 'edit'">
                <div class="col-1">
                    <div class="form-check mt-1">
                        <input class="form-check-input"
                               type="checkbox"
                               id="checkboxSelectAll"
                               ref="checkboxSelectAll"
                               :checked="store.isAllSelected"
                               @change="store.toggleSelectAll($event)">
                        <label class="form-check-label fw-normal" for="checkboxSelectAll">
                            {{ store.selectedInvoices?.length }} selected
                        </label>
                    </div>
                </div>
                <div class="col">
                    <Button @click="bulkApproveInvoiceModal?.show()" :icon="mdiCheckDecagram" color="link">Approve All</Button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th v-if="displayMode === 'edit'"></th>
                    <th></th>
                    <SortableColumn param="uuid" store="InvoiceStore">Invoice No</SortableColumn>
                    <SortableColumn param="created_at" store="InvoiceStore">Date created</SortableColumn>
                    <th>Client</th>
                    <SortableColumn param="total" store="InvoiceStore">Amount</SortableColumn>
                    <th>Payer</th>
                    <th>Payment method</th>
                    <th>Inv. Recipient</th>
                    <SortableColumn param="due_at" store="InvoiceStore">Due</SortableColumn>
                    <th>Transaction</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="invoice in store.invoices">
                    <td v-if="displayMode === 'edit'">
                        <input class="form-check-input" type="checkbox" v-model="invoice.selected" @click="store.toggleSelect(invoice as InvoiceResource)">
                    </td>
                    <td class="align-middle">
                        <div class="d-flex d-flex justify-content-start align-items-center gap-1">
                            <InvoiceStatus :status="invoice.status"></InvoiceStatus>
                            <ServiceLinkStatus v-for="serviceLink in invoice.serviceLinks" :service-link="serviceLink"></ServiceLinkStatus>
                        </div>
                    </td>
                    <td class="align-middle"><a href="javascript:void(0)" @click="editInvoice(invoice as InvoiceResource)">{{ invoice.uuid }}</a></td>
                    <td class="align-middle">
                        <template v-if="invoice.created_at">{{ DateTime.fromSeconds(invoice.created_at).toLocaleString(DateTime.DATETIME_MED) }}</template>
                    </td>
                    <td class="align-middle" v-if="invoice.recipient_type === 'App\\Models\\Client'">
                        <a :href="route('clients.show', invoice.recipient, true) + `#invoices`">
                            <ClientAvatar :size="'extra-small'" class="me-2" :client="invoice.recipient"></ClientAvatar>
                            {{ invoice.recipient?.full_name }}
                        </a>
                    </td>
                    <td v-else><span class="text-muted">N/A</span></td>
                    <td class="align-middle">{{ formatCurrency(invoice.total) }}</td>
                    <td class="align-middle lh-1" v-if="invoice.recipient_type === 'App\\Models\\Payer'">
                        {{ invoice.recipient?.payer_code }}<br/>
                        <small>{{ invoice.recipient?.name }}</small>
                    </td>
                    <td v-else><span class="text-muted">N/A</span></td>
                    <td class="text-capitalize">{{ invoice.collection_method }}</td>
                    <td class="align-middle" v-if="invoice.recipient_type === 'App\\Models\\Client'">
                        Client
                    </td>
                    <td v-else>Payer Contact</td>
                    <td class="align-middle"
                        :class="invoice.due_at < DateTime.now().toUTC().toSeconds() && invoice.status === 'unpaid' ? 'text-danger' : ''">
                        <template v-if="invoice.due_at">{{ DateTime.fromSeconds(invoice.due_at).toLocaleString(DateTime.DATE_MED) }}</template>

                    </td>
                    <td>
                        <template v-if="invoice.transactions?.length">
                            <a href="javascript:void(0)" v-for="transaction in invoice.transactions">{{ transaction.uuid }}</a>,
                        </template>
                        <template v-else><span class="text-muted">N/A</span></template>
                    </td>
                    <td class="text-end">
                        <InvoiceActionsDropdown
                            :invoice="invoice"
                            @edit="editInvoice(invoice as InvoiceResource)"
                            @sync="syncInvoice(invoice as InvoiceResource)"
                            @approve="store.invoice = invoice; approveInvoiceModal?.show()"
                            @void="store.invoice = invoice; voidInvoiceModal?.show()"
                        ></InvoiceActionsDropdown>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td :colspan="displayMode === 'edit' ? 12 : 11">
                        <Pagination
                            v-if="paginationStore.paginatedMeta.total"
                            record-name="invoice items"
                            store-name="InvoiceStore"
                        ></Pagination>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>

    <ConfirmationModal id="voidInvoiceModal" @confirmed="voidInvoice()">
        <template #title>VOID INVOICE</template>
        <template #text>
            <div>Once voided, this invoice cannot be edited and the invoice will be removed from the system.</div>
        </template>
        <template #confirm-button-text>Void</template>
    </ConfirmationModal>

    <ConfirmationModal id="approveInvoiceModal" @confirmed="approveInvoice()">
        <template #title>APPROVE INVOICE</template>
        <template #text>
            <div>Once approved, this invoice cannot be further edited.</div>
        </template>
        <template #confirm-button-text>Approve</template>
    </ConfirmationModal>

    <ConfirmationModal id="bulkApproveInvoiceModal" @confirmed="bulkApproveInvoice()">
        <template #title>APPROVE SELECTED INVOICES</template>
        <template #text>
            <div>Once approved, these invoices cannot be further edited.</div>
        </template>
        <template #confirm-button-text>Approve</template>
    </ConfirmationModal>

</template>

<style scoped>

</style>

<script setup lang="ts">
import ServiceLinkStatus from "@components/Services/ServiceLinkStatus.vue";
import {useInvoiceStore} from "@stores/InvoiceStore";
import {inject, onMounted, Ref, ref, watch} from "vue";
import InvoiceStatus from "@components/Invoices/InvoiceStatus.vue";
import ClientAvatar from "@components/Clients/ClientAvatar.vue";
import {formatCurrency} from "@classes/Helpers/Currency";
import Pagination from "@components/DataTable/Pagination.vue";
import InvoiceActionsDropdown from "@components/Invoices/InvoiceActionsDropdown.vue";
import Button from "@components/Button.vue";
import PageLengthSelector from "@components/DataTable/PageLengthSelector.vue";
import {usePaginationStore} from "@stores/PaginationStore";
import SortableColumn from "@components/DataTable/SortableColumn.vue";
import {mdiCheck, mdiCheckDecagram, mdiFileEdit, mdiFilter} from "@mdi/js";
import VueMultiselect from "vue-multiselect";
import SvgIcon from "@jamescoyle/vue-icon";
import {DateTime} from "luxon";
import DataTableSearch from "@components/DataTable/DataTableSearch.vue";
import ConfirmationModal from "@components/ConfirmationModal.vue";
import {Modal} from "bootstrap";
import InvoiceResource from "@customTypes/resources/InvoiceResource";
import {ToastInterface} from "@components/toast";
import {ApiError} from "@customTypes/LaravelCommonTypes";

const store = useInvoiceStore();
const paginationStore = usePaginationStore('InvoiceStore');
const voidInvoiceModal: Ref<Modal | undefined> = ref<Modal>();
const approveInvoiceModal: Ref<Modal | undefined> = ref<Modal>();
const bulkApproveInvoiceModal: Ref<Modal | undefined> = ref<Modal>();
const toast: ToastInterface = inject("toast")!;
const displayMode: Ref<'view' | 'edit'> = ref<'view' | 'edit'>('view')
const checkboxSelectAll = ref(null);

onMounted(() => {
    store.getAllInvoices();
    voidInvoiceModal.value = new Modal(document.getElementById('voidInvoiceModal') as Element)
    approveInvoiceModal.value = new Modal(document.getElementById('approveInvoiceModal') as Element)
    bulkApproveInvoiceModal.value = new Modal(document.getElementById('bulkApproveInvoiceModal') as Element)
});

const editInvoice = (invoice: InvoiceResource) => {
    window.location.href = route('invoices.edit', {invoice: invoice.uuid});
}

const voidInvoice = async () => {
    if (store.invoice) {
        try {
            let response: InvoiceResource | void = await store.voidInvoice(store.invoice);
            toast.success("You successfully voided the invoice **" + response?.uuid + "**");
        } catch (e: unknown) {
            toast.error((e as ApiError).response?.data?.message);
        } finally {
            voidInvoiceModal.value?.hide();
        }
    }
}

const approveInvoice = async () => {
    if (store.invoice) {
        try {
            let response: InvoiceResource = await store.approveInvoice(store.invoice);
            toast.success("You successfully approved the invoice **" + response.uuid + "**");
        } catch (e: unknown) {
            toast.error((e as ApiError).response?.data?.message);
        } finally {
            approveInvoiceModal.value?.hide();
        }
    }
}

const bulkApproveInvoice = async () => {
    if (store.selectedInvoices) {
        try {
            let response: InvoiceResource = await store.bulkApproveInvoices(store.selectedInvoices);
            toast.success("You successfully approved the selected invoices");
        } catch (e: unknown) {
            toast.error((e as ApiError).response?.data?.message);
        } finally {
            bulkApproveInvoiceModal.value?.hide();
        }
    }
}

const syncInvoice = async (invoice: InvoiceResource) => {
    try {
        let response: InvoiceResource = await store.syncInvoice(invoice);
        toast.success("You successfully requested syncing the invoice **" + response.uuid + "**");
    } catch (e: unknown) {
        toast.error((e as ApiError).response?.data?.message);
    }
}

const toggleMode = (event: Event) => {
    displayMode.value = (event.target as HTMLInputElement).checked ? 'edit' : 'view';
}

watch(
    paginationStore.paginateParams,
    () => {
        store.getAllInvoices();
    }
)

watch(
    () => store.filters.category,
    (value, oldValue) => {
        if (value !== oldValue) {
            window.location.href = route(`${value}.index`);
        }
    }
)

watch(
    () => store.isSomeSelected,
    (isSomeSelected) => {
        if (checkboxSelectAll.value) {
            (<HTMLInputElement>checkboxSelectAll.value).indeterminate = isSomeSelected;
        }
    }
)
</script>