<template>
    <div>
        <label :for="componentId" class="form-label">{{ label }}</label>
        <input
            v-model="query"
            type="search"
            class="form-control"
            placeholder="Search postcode or town name"
            :id="componentId"
            @keyup="search"
            @change="handleQueryChange"
        />
        <div v-if="results.length > 0" class="autocomplete-results-wrapper">
            <div class="list-group">
                <a
                    href="javascript: void(0)"
                    v-for="(suburb, key) in results"
                    :key="key"
                    @click="select(suburb)"
                    @mouseover="deselectAll"
                    class="list-group-item autocomplete-suggestion"
                    :id="componentId + '_suggestion_' + key"
                >
                    {{ formatLabel(suburb) }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, inject} from 'vue'
import {uuid} from 'vue-uuid'

const query = ref(null);
const componentId = ref(uuid.v4());
const results = ref([]);
const highlighted = ref(-1);
const axios = inject('axios');

const props = defineProps({
    label: {
        type: String,
        default: 'Search suburb'
    },
    clearOnSelect: {
        type: Boolean,
        default: true
    },
})

const emit = defineEmits(['select'])

const search = function () {
    if ([40, 38, 13].includes(event.keyCode)) {
        moveThroughResults(event)
        return
    }

    highlighted.value = -1;


    if (!query.value.trim() || query.value.trim().length === 0) {
        emit('select', {})
    }

    axios
        .get(route('api.suburbs.search'), {
            params: {
                query: query.value,
            },
        })
        .then((response) => {
            results.value = response.data.data
        })
}

const handleQueryChange = function () {
    if (!query.value.trim() || query.value.trim().length === 0) {
        emit('select', {})
    }
}

const formatLabel = function (suburb) {
    return suburb.name + ', ' + suburb.state + ' (' + suburb.postcode + ')'
}

const select = function (suburb) {
    results.value = []
    query.value = props.clearOnSelect ? null : formatLabel(suburb)
    emit('select', suburb)
}

const moveSelection = function (direction) {
    const suggestions = document.getElementsByClassName('autocomplete-suggestion');
    highlighted.value += (direction === 'down') ? 1 : -1;

    if (highlighted.value > (results.value.length - 1)) {
        highlighted.value = 0;
    }

    deselectAll();

    if (highlighted.value < 0) {
        highlighted.value = -1;
        return;
    }

    suggestions[highlighted.value].classList.add('selected');
}

const moveThroughResults = function (event) {
    switch (event.keyCode) {
        case 40:
            // down arrow
            moveSelection('down');
            break;
        case 38:
            // up arrow
            moveSelection('up');
            break;
        case 13:
            // enter
            event.preventDefault();
            document.getElementById(componentId.value + '_suggestion_' + highlighted.value).click();
            break;
    }
}

const deselectAll = function () {
    const suggestions = document.getElementsByClassName('autocomplete-suggestion');
    [].forEach.call(suggestions, function (el) {
        el.classList.remove("selected");
    });
}

</script>

<style scoped>

</style>
