<template>
    <div class="row" v-if="bookingStore.draft.clients.length > 0">
        <div class="col-md-4">
            <h4>Scheduling / Recurring</h4>
            <p>
                Create a plan to repeat this booking on a regular basis. This
                will be applied to all clients who are part of this booking.
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <FormSwitch
                        label="This is a regular/recurring booking"
                        v-model="bookingStore.draft.reschedule"
                    />

                    <div
                        class="mt-2 alert alert-warning"
                        v-if="recurranceDetailsMissing"
                    >
                        <div class="d-flex flex-row align-items-center">
                            <SvgIcon
                                type="mdi"
                                :path="mdiAlert"
                                class="me-1"
                            ></SvgIcon>
                            <strong>Recurrance Schedule Missing</strong>
                        </div>
                        <p>
                            This booking is a recurring booking, but the
                            recurrance schedule is missing as this booking was
                            created before Trips developed the recurring booking
                            edit feature. Please redefine the recurrance
                            schedule using the schedule picker below and we will
                            save this detail for future edits. To help you
                            achieve this, below are some of the recurrances of
                            this booking in the future
                        </p>
                        <br />
                        <p
                            v-for="booking in futureBookingsWithRecurrance.filter(
                                (i, v) =>
                                    v < maxFutureBookingsToShow ||
                                    showAllFutureBookings
                            )"
                        >
                            <strong>
                                {{
                                    DateTime.fromISO(booking.date).toFormat(
                                        "EEEE, MM/dd/yyyy"
                                    )
                                }}
                            </strong>
                        </p>
                        <a
                            v-if="
                                futureBookingsWithRecurrance.length >
                                maxFutureBookingsToShow
                            "
                            href="javascript: void(0)"
                            @click.prevent="
                                () =>
                                    (showAllFutureBookings =
                                        !showAllFutureBookings)
                            "
                            >{{
                                showAllFutureBookings ? "Show Less" : "Show All"
                            }}</a
                        >
                    </div>
                    <div v-if="bookingStore.draft.reschedule" class="mt-3">
                        <RescheduleOptions
                            v-model="rescheduleOptions"
                            event-type="booking"
                        ></RescheduleOptions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import RescheduleOptions from "@components/RescheduleOptions.vue";
import { computed, onMounted, ref, watch } from "vue";
import FormSwitch from "../Forms/FormSwitch.vue";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { mdiAlert } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import Booking from "../../classes/Booking";
import { DateTime } from "luxon";
import BookingResource from "../../types/resources/BookingResource";

const bookingStore = useBookingStore();

const { bookingEdit } = storeToRefs(bookingStore);

const futureBookingsWithRecurrance = ref<BookingResource[]>([]);
const showAllFutureBookings = ref(false);
const maxFutureBookingsToShow = 10;

const recurranceDetailsMissing = computed(() => {
    return (
        bookingEdit.value?.reschedule_identifier &&
        !bookingEdit.value?.recurrance
    );
});

watch(
    () => bookingEdit.value,
    (newValue) => {
        if (newValue?.reschedule_identifier) {
            Booking.index({
                reschedule_identifier: newValue.reschedule_identifier ?? "",
                date_from: DateTime.fromISO(bookingEdit.value?.date ?? ""),
            }).then((response) => {
                futureBookingsWithRecurrance.value = response?.data ?? [];
            });
        }
    }
);

const rescheduleOptions = computed({
    get() {
        return bookingStore.draft.rescheduleOptions;
    },
    set(newValue) {
        bookingStore.draft.rescheduleOptions = newValue;
    },
});
</script>
