import { defineStore } from "pinia";
import {inject, ref} from "vue";
import axios from 'axios';

export const useDepotStore = defineStore("depotStore", () => {
    const depotList = ref([]);

    function getDepotList() {
        axios.get(route('api.depots'))
            .then((response) => {
                depotList.value = response.data.data;
            });
    }

    return {
        depotList,
        getDepotList
    }
});
