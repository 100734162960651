<template>
    <FormTextInput
        v-model:input="rego"
        :mask="mask"
        :label="label"
        :placeholder="placeholder"
        :inputClass="inputClass"
        :labelClass="labelClass"
        :errorClass="errorClass"
        :validation="validation"
    />
</template>

<script setup>
    import { ref, watch } from 'vue'
    import * as yup from 'yup'
    import { copyValues } from '../../Utils'
    import { FormTextInput } from '../../Forms'

    const { modelValue, label, placeholder, validation, inputClass, labelClass, errorClass } = defineProps({
        modelValue: {
            required: true,
            type: Object,
        },
        label: {
            type: String,
            default: 'Registration'
        },
        placeholder: {
            type: String,
            default: 'e.g. ABC 123'
        },
        validation: {
            type: Object,
            default: yup.string().required().min(5).max(10)
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        labelClass: {
            type: String,
            default: 'form-label',
        },
        errorClass: {
            type: String,
            default: 'text-danger',
        },
        mask: {
            type: Object,
            default: {
                mask:[
                    {
                        mask: '### ###',
                        length: 6,
                        definitions: {
                            '#': /[0-9a-zA-Z]/
                        }
                    },
                    {
                        mask: '# ### ###',
                        length: 7,
                        definitions: {
                            '#': /[0-9a-zA-Z]/
                        }
                    },
                    {
                        mask: /^[0-9a-zA-Z]{0,10}$/,
                        length: 'default'
                    }
                ],
                prepare: function (str) {
                    return str.toUpperCase();
                },
                dispatch(appended, dynamicMask){
                    length = dynamicMask.value.length
                    return dynamicMask.compiledMasks.find(mask => {
                        return mask.length === length || mask.length === 'default'
                    })
                }
            }
        }
    })

    const rego = ref(copyValues(modelValue))

    const emit = defineEmits(['update:modelValue'])

    watch(
        rego,
        (value) => emit('update:modelValue', value)
    )
</script>
