<template>
    <div class="d-flex justify-content-between">
        <div v-if="windowRef?.user_type == 'Admin'" class="d-flex align-items-center">
            <Button class="me-2"
                    :icon="mdiMapMarker"
                    color="info"
                    size="small"
                    @click="driverPositionUpdates.showForJourney(journey)"
            >GPS Updates</Button>
        </div>
        <div v-if="showPopulateTime" class="d-flex align-items-center">
            <Button class="me-2"
                    :icon="mdiNotePlus"
                    color="warning"
                    size="small"
                    @click="populateEmptyBookingEvent"
            >Populate Time</Button>
        </div>
        <div class="d-flex align-items-center">

            <Button
                v-if="journey.status === 'waiting_verification' || journey.status === 'future'"
                :disabled="journey.unable_to_verify_reason !== null"
                :icon="mdiCheck"
                :loading="verifyingJourney"
                color="primary"
                label-when-loading="Verifying"
                size="small"
                @click="verifyJourney"
            >
                Verify Run
            </Button>
            <div v-if="journey.unable_to_verify_reason !== null" class="ms-2 d-flex">
                <SvgIcon
                    :path="mdiAlertCircle"
                    class="h-5 w-5 me-1 text-warning"
                    type="mdi"/>
                <strong class="me-1">Unable to verify:</strong> {{ journey.unable_to_verify_reason }}
            </div>
        </div>
        <div class="d-flex">
            <div class="me-4">
                <strong>Driver: </strong>
                <EditDriver
                    :key="journey.driver?.uuid ?? uuid.v4()"
                    :drivers="drivers"
                    :journey="journey"
                    @updated="updateJourney"
                />
            </div>
            <div class="me-4" v-if="journeyCompleted">
                <strong>Actual Start time: </strong>
                <EditJourneyEventOccuredAtTime :journey="journey" :eventType="JourneyEventType.START"/>
            </div>
            <div class="me-4" v-if="journeyCompleted">
                <strong>Actual end time: </strong>
                <EditJourneyEventOccuredAtTime :journey="journey" :eventType="JourneyEventType.END"/>
            </div>
            <div class="me-4" v-if="!journeyCompleted">
                <strong>Scheduled start time: </strong>
                <EditStartTime :journey="journey"/>
            </div>
            <div class="me-4" v-if="!journeyCompleted">
                <strong>Scheduled end time: </strong>
                <EditEndTime :journey="journey"/>
            </div>
            <div class="me-4"><strong>Start odometer: </strong>
                <EditStartOdometer :journey="journey"/>
            </div>
            <div class="me-4"><strong>End odometer: </strong>
                <EditEndOdometer :journey="journey"/>
            </div>
            <div class="me-4">
                <strong>Dead running:</strong>
                {{ $filters.comma_separated_thousands(journey.distance_without_passengers) }} km
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VerificationJourneyDetails"
}
</script>
<script setup>
import {mdiCheck, mdiAlertCircle, mdiNotePlus, mdiMapMarker} from '@mdi/js';
import SvgIcon from "@jamescoyle/vue-icon";
import Button from "../Button.vue";
import {ref, inject, computed} from "vue";
import axios from "axios";
import EditDriver from "./EditableFields/EditDriver.vue";
import EditStartTime from "./EditableFields/EditStartTime.vue";
import EditJourneyEventOccuredAtTime from "./EditableFields/EditJourneyEventOccuredAtTime.vue";
import {JourneyEventType} from "@classes/JourneyEvent"
import EditEndTime from "./EditableFields/EditEndTime.vue";
import EditStartOdometer from "./EditableFields/EditStartOdometer.vue";
import EditEndOdometer from "./EditableFields/EditEndOdometer.vue";
import {useVerificationStore} from "../../stores/VerificationStore.js";
import {uuid} from "vue-uuid";
import BookingEvent, {BookingEventType} from "@classes/BookingEvent";
import {storeToRefs} from "pinia";
import useWindow from "../../hooks/useWindow.ts"

const driverPositionUpdates = inject("driverPositionUpdates");

const windowRef = useWindow();

const verificationStore = useVerificationStore();
const { journeys, reloadStatisticRequired } = storeToRefs(verificationStore);

const props = defineProps({
    journey: {
        type: Object,
        required: true
    },
    drivers: {
        type: Array,
        required: false,
        default: null,
    }
})

const journeyCompleted = computed(() => {
    const events = props.journey?.events ?? [];

    return (
        typeof events?.find((event) => event.type == JourneyEventType.START) !==
            "undefined" &&
        typeof events?.find((event) => event.type == JourneyEventType.END) !==
            "undefined"
    );
});

const showPopulateTime = computed(() => {
    const journeyStore = journeys.value.find((j) => j.uuid === props.journey.uuid);
    let showButton = false;

    journeyStore.bookings.forEach((booking) => {
        if (booking.events.length <= 0) {
            showButton = true;
        }
    });

    return showButton;
});

const toast = inject('toast')
const verifyingJourney = ref(false)
const verifyJourney = function () {
    verifyingJourney.value = true
    axios
        .post(route('api.journeys.verify', {journey: props.journey.uuid}))
        .then((response) => {
            verificationStore.updateJourney(response.data);
            toast.success('You have successfully verified the vehicle run **' + props.journey.vehicle.registration_code + '**');

            reloadStatisticRequired.value = true;
        })
        .catch((error) => {
            console.error(error)
            toast.error(error.response.data.message)
        })
        .finally(() => {
            verifyingJourney.value = false
        })
}

const updateJourney = function (journey) {
    emit('updated', journey)
}

const populateEmptyBookingEvent = function () {
    const journeyStore = journeys.value.find((j) => j.uuid === props.journey.uuid);

    journeyStore.bookings.filter(booking => booking.status !== 'cancelled').forEach((booking) => {
        if (booking.events.length <= 0) {
            const pickUpTime = booking.planned_origin_time ? booking.planned_origin_time : booking.estimated_origin_time;
            const dropOffTime = booking.planned_destination_time ? booking.planned_destination_time : booking.estimated_destination_time;

            if(!pickUpTime || !dropOffTime) {
                let missing = !pickUpTime ? 'origin' : 'destination';
                toast.error(`There was an error populating actual times for booking ${booking.uuid} as the booking is missing planned and estimated ${missing} times.`);
                return;
            }

            // add pickup booking from origin
            BookingEvent.store({
                bookings: [booking],
                type: BookingEventType.PICK_UP,
                occurred_at: pickUpTime,
            })
                .then((response) => {
                    toast.success(`Start time created for booking ${booking.uuid}.`);
                })
                .catch((error) => {
                    toast.error(`There was an error creating the start time for booking ${booking.uuid}.`);
                })
                .finally(() => {
                    booking.events.push({
                        type: BookingEventType.PICK_UP,
                        occurred_at: pickUpTime
                    });
                });

            // add dropoff booking from origin
            BookingEvent.store({
                bookings: [booking],
                type: BookingEventType.DROP_OFF,
                occurred_at: dropOffTime,
            })
                .then((response) => {
                    toast.success(`end time created for booking ${booking.uuid}.`);
                })
                .catch((error) => {
                    toast.error(`There was an error creating the end time for booking ${booking.uuid}.`);
                })
                .finally(() => {
                    booking.events.push({
                        type: BookingEventType.DROP_OFF,
                        occurred_at: dropOffTime,
                    });
                });
        }
    });
}

</script>

<style scoped>

</style>
