<template>
    <label class="form-label text-nowrap">
        <div class="input-group input-group-sm">
            <input
                type="search"
                class="form-control form-control-sm"
                :placeholder="props.placeholder"
                v-model="searchQuery"
                @keyup.enter="performSearch"
            />
            <button
                class="btn btn-outline-secondary"
                type="button"
                @click="performSearch"
            >
                <SvgIcon
                    type="mdi"
                    :path="mdiMagnify"
                    style="width: 1.3em"
                ></SvgIcon>
            </button>
        </div>
    </label>
</template>

<script setup lang="ts">
    import { storeToRefs } from "pinia";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiMagnify } from "@mdi/js";
    import { usePaginationStore } from "@stores/PaginationStore";

    const props = withDefaults(
        defineProps<{
            placeholder?: string,
            store?: string
        }>(),
        {
            placeholder: 'Search ...',
            store: 'default'
        }
    )

    const { searchQuery } = storeToRefs(usePaginationStore(props.store));

    const emit = defineEmits<{
        (e: 'search', value: true): void
    }>();

    const performSearch = () => {
        emit('search', true);
    }

</script>