<template>
    <div v-if="regionLoading" class="my-auto">
        <Loading color="text-white" :show-text="false" />
    </div>
    <div v-else class="dropdown my-auto">
        <a href="javascript: void(0)" title="Set Region" class="dropdown-toggle text-white arrow-none" data-bs-toggle="dropdown">
            <template v-if="selectedRegion">{{ selectedRegion.name }}</template>
            <template v-else>Select Region</template>
            <div class="arrow-down"></div>
        </a>
        <div class="dropdown-menu">
            <template v-for="region in regions">
                <a href="javascript: void(0)" @click="selectRegion(region)" class="dropdown-item">{{ region.name }}</a>
            </template>
            <a href="javascript: void(0)" @click="selectRegion(null)" class="dropdown-item">All Regions</a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onBeforeMount, watch } from "vue";
    import { storeToRefs } from "pinia";
    import { useRegionStore } from "@stores/RegionStore";
    import { useDispatchStore } from "@stores/DispatchStore";
    import { Loading } from "@components";

    const regionStore = useRegionStore();
    const dispatchStore = useDispatchStore();
    const { regions, selectedRegion, regionLoading } = storeToRefs(regionStore);
    const { selectRegion } = regionStore;

    onBeforeMount(() => {
        regionStore.sync();
    });

    watch(selectedRegion, () => {
        // clear filter for vehicle position in dispatch page
        dispatchStore.clearFilter();
    });

</script>
