<template>
    <div>
        <div :id="componentId" :style="{
            'width': props.widthPercent + '%',
            'height': props.heightPixels + 'px',
        }"></div>
    </div>
</template>

<script>
export default {
    name: "GoogleMapWithPin"
}
</script>
<script setup>
import {onMounted, ref, watch} from "vue";
import {uuid} from 'vue-uuid';

const componentId = ref(uuid.v4());
const emit = defineEmits(['LocationUpdated'])

const props = defineProps({
    widthPercent: {
        type: Number,
        required: false,
        default: 100,
    },
    heightPixels: {
        type: Number,
        required: false,
        default: 200,
    },
    locations: {
        type: Array,
        required: true,
    },
})

const pins = ref([]);
const map = ref(null);

const initMap = () => {
    pins.value = props.locations.map((location) => {
        return new google.maps.LatLng(location.lat, location.lng)
    })

    let bounds = new google.maps.LatLngBounds()
    pins.value.forEach((pin) => {
        bounds.extend(pin)
    })

    map.value = new google.maps.Map(document.getElementById(componentId.value), {
        disableDefaultUI: true,
        zoomControl: true,
    })

    if (pins.value.length === 1) {
        map.value.setCenter(pins.value[0])
        map.value.setZoom(15)
    } else {
        map.value.fitBounds(bounds)
    }

    pins.value.forEach((pin) => {
        const marker = new google.maps.Marker({
            position: pin,
            map: map.value,
            draggable: true,
        });

        marker.addListener('dragend', handleDragAndDropEvent);
    })
}

function handleDragAndDropEvent(event) {
    const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
    };

    emit('LocationUpdated', latLng);
}

onMounted(() => {
    initMap();
})

watch(() => props.locations, (value) => {
    initMap();
})

</script>

<style scoped>

</style>
