import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useMessageStore } from '@stores/MessageStore';
import { usePaginationStore } from "./PaginationStore";
import DataImport from "@classes/DataImport";
import { DataTableInterface, sortDirectionEnum } from "@customTypes/DataTableTypes";

export const useDataImportStore = defineStore('dataImportStore', () => {
    const { paginatedMeta, paginateParams, searchQuery } = storeToRefs(usePaginationStore());

    paginateParams.value.sortBy = 'created_at';
    paginateParams.value.sortDirection = sortDirectionEnum.Descending;

    const messageStore = useMessageStore();

    const { fieldErrors } = storeToRefs(messageStore);

    const { addToastMessage, addFieldError } = messageStore;

    const dataImports = ref<App.Resource.DataImportResource[]>();
    const loadingImports = ref<boolean>(false);
    const updatingAttachments = ref<boolean>(false);
    const importOptions = ref<App.DataImport.ImportTypes[]>();

    const getImports = (): void => {
        loadingImports.value = true;
        
        let data: DataTableInterface = {
            paginate: true,
            perPage: paginateParams.value.perPage,
            page: paginateParams.value.page,
            sortBy: paginateParams.value.sortBy,
            sortDirection: paginateParams.value.sortDirection,
        }

        if (searchQuery.value?.trim()?.length > 0) {
            data.query = searchQuery.value?.trim()
            data.sortBy = null
        }

        DataImport.index(data)
            .then((resp) => {
                dataImports.value = resp.data.data;
                paginatedMeta.value = resp.data.meta;
            })
            .catch((err) => {
                addToastMessage('error', 'Failed to get Imports list');
                console.error(err);
            })
            .finally(() => {
                loadingImports.value = false;
            });
    };

    const draftDataImports = ref<App.DataImport.DraftDataImport[]>([]);
    const draftDataImport = ref<App.DataImport.DraftDataImport>();
    const dataImport = ref<App.Resource.DataImportResource>();
    const importExtensions = ref<string[]>();
    const importMimes = ref<string[]>();

    const getImportOptions = (force:boolean = false): void => {

        if (importOptions.value?.length > 0 && ! force) {
            return;
        }

        DataImport.importTypes()
        .then((response) => {
            importOptions.value = response.data;
        })
        .catch((error) => {
            addToastMessage('error', 'Something has gone wrong fetching Data Import Types');
            console.error(error);
        });
    }

    const processDataImport = (dataImport): void => {
        if (! importOptionValues.value.includes(dataImport.type)) {
            addToastMessage('error', 'Please select am import type to import');
            return;
        }

        DataImport.store(dataImport)
        .then((response) => {
            addToastMessage('success', 'Data Import Processing ' + dataImport.type);
            draftDataImports.value.splice(draftDataImports.value.indexOf(dataImport), 1);
            getImports();
        })
        .catch((error) => {
            addToastMessage('error', 'Failed to start ' + dataImport.type + ' Data Import Processing');
            console.log(error);
        })
    }

    const importOptionValues = computed(() => {
        return importOptions.value?.map((option) => option.value);
    })

    watch(
        paginateParams,
        () => {
            getImports()
        }
    );

    return {
        fieldErrors,
        dataImports,
        loadingImports,
        updatingAttachments,
        importOptions,
        paginatedMeta,
        paginateParams,
        draftDataImports,
        draftDataImport,
        dataImport,
        importExtensions,
        importMimes,
        importOptionValues,
        getImports,
        getImportOptions,
        processDataImport,
    }
})