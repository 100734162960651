<template>
    <label :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
           :for="inputName">
        {{ label }}
    </label>
    <input
        v-model="inputValue"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        :step="step"
        :type="type"
        v-mask="mask"
        @accept="updated"
        @complete="updated"
    />
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
    import { kebabCase } from 'lodash'
    import { useField } from 'vee-validate'
    import * as yup from 'yup'
    import { copyValues, onReady, isInvalid, getErrorMessage } from '../Utils'

    const props = defineProps({
        input: {
            required: true,
            type: Object,
        },
        label: {
            type: String,
            default: null,
        },
        inputName: {
            type: String,
            default: kebabCase((Math.random() + 1).toString(36).substring(7)),
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        labelClass:{
            type: String,
            default: 'form-label'
        },
        errorClass: {
            type: String,
            default: 'text-danger form-text'
        },
        validation: {
            type: Object,
            default: yup.number().nullable(),
        },
        optional: {
            type: Boolean,
            default: false
        },
        step: {
            type: String,
            default: '1'
        },
        mask: {
            type: Object,
            default: {
                mask: Number,
                scale: 0,
                signed: false,
                thousandsSeparator: ''
            }
        },
        type: {
            type: String,
            default: 'number'
        }
    });

    // we have to split the const like this to make watchers work for entire props
    const { input, label, inputName, placeholder, inputClass, labelClass, validation, optional, step, mask } = props;

    const mounted = ref(false)
    const inputValue = ref(copyValues(input.value));

    onReady(() => mounted.value = true)

    const emit = defineEmits(['update:input'])

    const { errorMessage, setValue, validate, meta } = useField(inputName, validation)

    const updated = event => {
        if (mounted.value) {
            let value = event.detail.unmaskedValue
            value = value.length > 0 ? value : null
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(input)
                returnValue.value = meta.valid ? value : null
                returnValue.valid = meta.valid
                emit('update:input', returnValue)
            })
        }
    }

    watch(props, (value) => {
        inputValue.value = copyValues(props.input.value);
    });
</script>
