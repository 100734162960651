<template>
    <div v-if="mounted">
        <div class="row">
            <div class="col-12">
                <FormDateInput
                    v-model:input="lastAssessmentDate"
                    label="Last Assessment Date"
                    :validation="yup.date().required().max(DateTime.now().toISODate(), 'Last Assessment Dates must be in the past')"
                    :max="DateTime.now().toISODate()"
                />
            </div>
        </div>
        <div class="row cl
mt-3">
            <div class="col-12">
                <FormSelect
                    v-model:selection="assessmentFrequency"
                    :options="options"
                    label="Assessment Frequency"
                    placeholder="Please Assessment Frequency"
                    :validation="yup.number().required().oneOf(optionValidation)"
                />
            </div>
        </div>
    </div>
</template>

<script>

</script>
<script setup>
import {ref, reactive, watch, onMounted} from 'vue'
import { FormDateInput, FormSelect } from '../Forms'
import { copyValues, onReady, getSelectValues } from '../Utils.js'
import * as yup from 'yup'
import { DateTime } from 'luxon'

const props = defineProps({
    client: {
        required: true,
        type: Object,
    }
})

const emit = defineEmits(['updated'])

const lastAssessmentDate = ref(null)
const assessmentFrequency = ref(null)

onMounted(() => {
    lastAssessmentDate.value = props.client.last_assessment_date
    assessmentFrequency.value = props.client.assessment_frequency
    mounted.value = true
})

watch(lastAssessmentDate, () => {
    emit('updated', {
        last_assessment_date: lastAssessmentDate.value,
        assessment_frequency: assessmentFrequency.value
    })
})

watch(assessmentFrequency, () => {
    emit('updated', {
        last_assessment_date: lastAssessmentDate.value,
        assessment_frequency: assessmentFrequency.value
    })
})

const mounted = ref(false)
onMounted(() => {
    mounted.value = true
})

const options = [
    {
        label: 'Every 3 months',
        value: 3
    },
    {
        label: 'Every 6 months',
        value: 6
    },
    {
        label: 'Every 12 months',
        value: 12
    },
    {
        label: 'Every 18 months',
        value: 18
    },
    {
        label: 'Every 24 months',
        value: 24
    },
]

const optionValidation = getSelectValues(options)

</script>
