<template>
    <div class="dropdown">
        <SvgIcon
            :path="mdiDotsVertical"
            class="w-4 h-4 me-1 dropdown-toggle"
            type="mdi"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        ></SvgIcon>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
                <a
                    :class="{ 'dropdown-item': true, disabled: editDisabled }"
                    href="#"
                    @click="onBookingEditClick"
                >
                    <SvgIcon
                        :path="mdiPencil"
                        class="me-1"
                        :size="14"
                        type="mdi"
                    ></SvgIcon>
                    Edit Booking</a
                >
            </li>
            <li v-if="userCan('view_activity_logs')">
                <a
                    class="dropdown-item"
                    href="#"
                    @click="activityLogs.show('booking', booking.uuid)"
                    ><SvgIcon
                        :path="mdiHistory"
                        class="me-1"
                        :size="14"
                        type="mdi"
                    ></SvgIcon
                    >View History</a
                >
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { defineProps, toRefs, computed, inject } from "vue";
import { BookingInterface } from "@classes/Booking";
import { DateTime } from "luxon";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiDotsVertical, mdiPencil, mdiHistory } from "@mdi/js";
import { userCan } from "../Utils";

const activityLogs = inject<any>("activityLogs");

const props = withDefaults(
    defineProps<{
        booking: BookingInterface;
    }>(),
    {}
);

const { booking } = toRefs(props);

const editDisabled = computed(() => {
    return (
        DateTime.fromISO(booking.value.date) < DateTime.now().startOf("day") ||
        booking.value.client.deactivated
    );
});

const bookingEditUrl = computed(() => {
    return route("bookings.edit", { booking: booking.value.uuid });
});

const onBookingEditClick = () => {
    //@ts-ignore
    window.location = bookingEditUrl.value;
};
</script>
