<template>
    <div>
        <BookingAllocationStats />
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-3 mb-3 flex-shrink-1 text-nowrap">
                        <h4 class="header-title mt-0">Unallocated Bookings</h4>
                    </div>
                    <template v-if="!loading && bookings.length > 0">
                        <div
                            class="col-md-9 mb-3 text-end d-flex flex-wrap align-items-center justify-content-end"
                        >
                            <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown">
                                <button
                                    class="btn btn-light btn-sm dropdown-toggle"
                                    type="button"
                                    id="dropDownFilterBookings"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                >
                                    <SvgIcon
                                        type="mdi"
                                        :path="filterIcon"
                                        style="max-width: 16px"
                                    ></SvgIcon>
                                </button>
                                <div
                                    class="dropdown-menu px-4 py-3 max-w-2xl"
                                    style="min-width: 600px"
                                    aria-describedby="dropDownFilterBookings"
                                >
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label
                                                class="form-label"
                                                for="service_type_filter"
                                                >Service type</label
                                            >
                                            <VueMultiselect
                                                v-model="filters.serviceTypes"
                                                :options="serviceTypes"
                                                placeholder="Select service types"
                                                label="name"
                                                track-by="uuid"
                                                multiple
                                            ></VueMultiselect>
                                        </div>
                                        <div class="col-md-6">
                                            <FormSelect
                                                v-model:selection="
                                                    filters.distance
                                                "
                                                :options="distanceOptions"
                                                label="Estimated distance"
                                                placeholder="-- Select Distance Range --"
                                            />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <SearchSuburb
                                                label="Origin postcodes"
                                                @select="addOriginPostcode"
                                            />
                                            <div class="d-flex mt-1 flex-wrap">
                                                <template
                                                    v-for="suburb in filters.originPostcodes"
                                                >
                                                    <div
                                                        class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                                        style="font-size: 0.9em"
                                                    >
                                                        <div>
                                                            {{ suburb.name }}
                                                            {{
                                                                suburb.postcode
                                                            }}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            class="btn-close btn-close-white ms-1"
                                                            aria-label="Close"
                                                            @click="
                                                                removeOriginPostcode(
                                                                    suburb
                                                                )
                                                            "
                                                        ></button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <SearchSuburb
                                                label="Destination postcodes"
                                                @select="addDestinationPostcode"
                                            />
                                            <div class="d-flex mt-1 flex-wrap">
                                                <template
                                                    v-for="suburb in filters.destinationPostcodes"
                                                >
                                                    <div
                                                        class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                                        style="font-size: 0.9em"
                                                    >
                                                        <div>
                                                            {{ suburb.name }}
                                                            {{
                                                                suburb.postcode
                                                            }}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            class="btn-close btn-close-white ms-1"
                                                            aria-label="Close"
                                                            @click="
                                                                removeDestinationPostcode(
                                                                    suburb
                                                                )
                                                            "
                                                        ></button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <label
                                            class="form-label text-sm"
                                            for="transport_requirements_filter_select"
                                            >Seat Type:</label
                                        >
                                        <VueMultiselect
                                            id="transport_requirements_filter_select"
                                            v-model="filters.seatTypes"
                                            :options="seatTypeOptions"
                                            placeholder="Select transport requirements"
                                            label="label"
                                            track-by="value"
                                            multiple
                                        ></VueMultiselect>
                                    </div>
                                    <div class="mb-2">
                                        <label
                                            class="form-label text-sm"
                                            for="vehicle_group_filter_select"
                                        >Vehicle Group:</label
                                        >
                                        <VueMultiselect
                                            id="vehicle_group_filter_select"
                                            v-model="filters.vehicleGroups"
                                            :options="vehicleGroups"
                                            placeholder="Select Vehicle Group"
                                            label="label"
                                            track-by="value"
                                            multiple
                                        ></VueMultiselect>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label
                                    class="visually-hidden"
                                    for="searchBookings"
                                    >Search</label
                                >
                                <div class="input-group">
                                    <input
                                        type="search"
                                        class="form-control form-control-sm"
                                        :placeholder="
                                            'Search ' +
                                            (allocationSearchBy === 'Clients'
                                                ? 'client name'
                                                : 'location') +
                                            '...'
                                        "
                                        id="searchBookings"
                                        v-model="allocationSearchPhrase"
                                    />
                                    <button
                                        class="btn btn-outline-secondary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {{ allocationSearchBy }}
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                href="javascript: void(0)"
                                                @click="allocationSearchBy = 'Clients'"
                                            >
                                                Clients
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                href="javascript: void(0)"
                                                @click="allocationSearchBy = 'Locations'"
                                            >
                                                Locations
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <template v-if="!loading">
                    <div v-if="filters.serviceTypes.length > 0">
                        <div class="mb-3 card shadow-none border">
                            <div class="card-body p-2">
                                <div>
                                    Showing bookings with the following service
                                    types only:
                                </div>
                                <div class="d-flex mt-1 flex-wrap">
                                    <template
                                        v-for="serviceType in filters.serviceTypes"
                                    >
                                        <div
                                            class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                            style="font-size: 0.8em"
                                        >
                                            <div>{{ serviceType.name }}</div>
                                            <button
                                                type="button"
                                                class="btn-close btn-close-white ms-1"
                                                aria-label="Close"
                                                @click="
                                                    removeServiceType(
                                                        serviceType
                                                    )
                                                "
                                            ></button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="filters.originPostcodes.length > 0">
                        <div class="mb-3 card shadow-none border">
                            <div class="card-body p-2">
                                <div>
                                    Showing bookings with origin postcodes:
                                </div>
                                <div class="d-flex mt-1 flex-wrap">
                                    <template
                                        v-for="suburb in filters.originPostcodes"
                                    >
                                        <div
                                            class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                            style="font-size: 0.8em"
                                        >
                                            <div>
                                                {{ suburb.name }}
                                                {{ suburb.postcode }}
                                            </div>
                                            <button
                                                type="button"
                                                class="btn-close btn-close-white ms-1"
                                                aria-label="Close"
                                                @click="
                                                    removeOriginPostcode(suburb)
                                                "
                                            ></button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="filters.destinationPostcodes.length > 0">
                        <div class="mb-3 card shadow-none border">
                            <div class="card-body p-2">
                                <div>
                                    Showing bookings with destination postcodes:
                                </div>
                                <div class="d-flex mt-1 flex-wrap">
                                    <template
                                        v-for="suburb in filters.destinationPostcodes"
                                    >
                                        <div
                                            class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                            style="font-size: 0.8em"
                                        >
                                            <div>
                                                {{ suburb.name }}
                                                {{ suburb.postcode }}
                                            </div>
                                            <button
                                                type="button"
                                                class="btn-close btn-close-white ms-1"
                                                aria-label="Close"
                                                @click="
                                                    removeDestinationPostcode(
                                                        suburb
                                                    )
                                                "
                                            ></button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="filters.distance.value">
                        <div class="mb-3 card shadow-none border">
                            <div class="card-body p-2">
                                <div>
                                    Showing bookings with an estimated distance
                                    of {{ distanceFilterLabel }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="filters.transportRequirements.length">
                        <div class="mb-3 card shadow-none border">
                            <div class="card-body p-2">
                                <div>
                                    Showing bookings with transport
                                    requirements:
                                </div>
                                <div class="d-flex mt-1 flex-wrap">
                                    <div
                                        v-for="(
                                            filter, index
                                        ) in filters.transportRequirements"
                                        :key="index"
                                        class="badge rounded-pill bg-primary m-1 d-flex align-items-center lh-1"
                                        style="font-size: 0.8em"
                                    >
                                        {{ filter.label }}
                                        <template
                                            v-if="!filter.bool && filter.number"
                                            >({{ filter.number }})</template
                                        >
                                        <button
                                            type="button"
                                            class="btn-close btn-close-white ms-1"
                                            aria-label="Close"
                                            @click="
                                                removeTransportRequirement(
                                                    index
                                                )
                                            "
                                        ></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EmptyUnallocatedBookingsListMessage
                        v-if="filteredBookings.length === 0"
                    />

                    <div
                        class="mb-2"
                        v-if="selectedJourney && selectedJourney.region"
                    >
                        Region: {{ selectedJourney.region.name }}
                    </div>

                    <table
                        class="allocations-table table-xs centered no-wrap w-100 align-middle text-sm table-scroll"
                        style="max-height: 80vh; overflow-y:scroll; display:block;"
                    >
                        <thead class="response-table-header-white">
                            <tr class="small">
                                <th class="td-shrink text-center ps-2">
                                    <input
                                        v-model="selectAll"
                                        v-tooltip="'Select all bookings'"
                                        class="form-check-input"
                                        type="checkbox"
                                    />
                                </th>
                                <th class="td-shrink"></th>
                                <th class="td-shrink"></th>
                                <th
                                    class="td-shrink text-center"
                                    :class="{
                                        'sort-asc':
                                            sortBy === 'priority' &&
                                            sortDirection === 'asc',
                                        'sort-desc':
                                            sortBy === 'priority' &&
                                            sortDirection === 'desc',
                                    }"
                                    role="button"
                                    @click="toggleSort('priority')"
                                >
                                    Info
                                </th>
                                <th
                                    class="sortable"
                                    :class="{
                                        'sort-asc':
                                            sortBy === 'client' &&
                                            sortDirection === 'asc',
                                        'sort-desc':
                                            sortBy === 'client' &&
                                            sortDirection === 'desc',
                                    }"
                                    role="button"
                                    @click="toggleSort('client')"
                                    style="position: sticky;"
                                >
                                    Client
                                </th>
                                <th
                                    class="sortable"
                                    :class="{
                                        'sort-asc':
                                            sortBy === 'origin' &&
                                            sortDirection === 'asc',
                                        'sort-desc':
                                            sortBy === 'origin' &&
                                            sortDirection === 'desc',
                                    }"
                                    role="button"
                                    @click="toggleSort('origin')"
                                    style="position: sticky;"
                                >
                                    Origin
                                </th>
                                <th
                                    class="sortable"
                                    :class="{
                                        'sort-asc':
                                            sortBy === 'destination' &&
                                            sortDirection === 'asc',
                                        'sort-desc':
                                            sortBy === 'destination' &&
                                            sortDirection === 'desc',
                                    }"
                                    role="button"
                                    @click="toggleSort('destination')"
                                    style="position: sticky;"
                                >
                                    Destination
                                </th>
                                <th class="td-shrink"></th>
                            </tr>
                        </thead>

                        <draggable
                            v-model="displayedBookings"
                            group="meals"
                            tag="tbody"
                            item-key="uuid"
                        >
                            <template #item="{ element: booking, index: key }">
                                <AllocationsBookingRow
                                    :booking="booking"
                                    :keyValue="key"
                                    :key="key"
                                />
                            </template>
                        </draggable>
                    </table>

                    <button
                        class="btn btn-primary mt-3"
                        @click="allocateSelectedBookings"
                        v-if="
                            selectedBookingsInUnAllocatedList.length > 0 &&
                            selectedJourney &&
                            Object.keys(selectedJourney).length > 0
                        "
                    >
                        <i class="mdi mdi-plus-circle me-1"></i>
                        Allocate To Selected Vehicle Run
                    </button>
                </template>

                <template v-if="loading">
                    <div
                        class="d-flex w-100 justify-content-center align-items-center ps-3"
                    >
                        <div class="spinner-grow my-5 me-3" role="status"></div>
                        <div class="fs-4">Loading bookings</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ClientAvatar from "../Clients/ClientAvatar.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiChevronDoubleUp,
    mdiChevronDoubleDown,
    mdiHumanWheelchair,
    mdiAsterisk,
    mdiFilter,
    mdiPencil,
    mdiCarSelect,
} from "@mdi/js";
import ClientName from "../Clients/ClientName.vue";

import BookingTime from "../Bookings/BookingTime.vue";
import SearchSuburb from "../SearchSuburb.vue";
import FormSelect from "@components/Forms/FormSelect.vue";
import VueMultiselect from "vue-multiselect";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import axios from "axios";
import draggable from "vuedraggable";
import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import AllocationsBookingRow from "./Components/AllocationsBookingRow.vue";
import { BookingStatus } from "../../classes/Booking";
import BookingAllocationStats from "./Components/BookingAllocationStats.vue";
import EmptyUnallocatedBookingsListMessage from "./Components/EmptyUnallocatedBookingsListMessage.vue";
import SeatType from "@classes/SeatType";
import VehicleGroup from "@classes/VehicleGroup";

const allocationStore = useAllocationStore();
const { bookings, selectedJourney, selectedBookingsInUnAllocatedList, date, allocationSearchPhrase, allocationSearchBy } = storeToRefs(allocationStore);
const { checkAllocationRequirements, matchesAllocationSearchPhrase } = allocationStore;


const bookingRowKey = ref(Math.random());
const locations = ref<any[]>([]);
const sortBy = ref("origin");
const sortDirection = ref("asc");
const filterIcon = ref(mdiFilter);
const transportRequirementOptions = ref<any[]>([]);
const seatTypeOptions = ref([]);
const vehicleGroups = ref([]);
const filters = ref<{
    originPostcodes: any[];
    destinationPostcodes: any[];
    serviceTypes: any[];
    transportRequirements: any[];
    seatTypes: any[];
    vehicleGroups: any[];
    distance: {
        value: number | null;
    };
}>({
    originPostcodes: [],
    destinationPostcodes: [],
    serviceTypes: [],
    transportRequirements: [],
    distance: {
        value: null,
    },
    seatTypes: [],
    vehicleGroups: [],
});
const distanceOptions = ref([
    { value: 10000, label: "less than 10km" },
    { value: 20000, label: "10-20km" },
    { value: 30000, label: "20-30km" },
    { value: 40000, label: "30-40km" },
    { value: 50000, label: "40-50km" },
    { value: 999999999, label: "more than 50km" },
]);

const props = withDefaults(
    defineProps<{
        loading: boolean;
    }>(),
    {}
);

const distanceFilterLabel = computed(() => {
    return distanceOptions.value.find(
        (distance) => distance.value === filters.value.distance.value
    )?.label;
});

const serviceTypes = computed(() => {
    let uniqueServiceTypes: App.Models.BookingServiceType[] = [];

    bookings.value.map((booking) => {
        if (!booking.service_type) {
            return;
        }

        if (
            !uniqueServiceTypes.find(
                (i) => i.uuid == booking.service_type?.uuid
            )
        ) {
            uniqueServiceTypes.push(booking.service_type);
        }
    });

    return uniqueServiceTypes;
});

const selectAll = computed({
    get() {
        return filteredBookings.value
            ? selectedBookingsInUnAllocatedList.value.length ===
                  filteredBookings.value.length
            : false;
    },
    set(checked) {
        let newSelected: any[] = [];
        if (checked) {
            newSelected = filteredBookings.value;
        }
        selectedBookingsInUnAllocatedList.value = newSelected;
    },
});

const displayedBookings = ref<App.Models.Booking[]>([]);

const filteredBookings = computed<App.Models.Booking[]>(() => {
    let filtered: App.Models.Booking[] = bookings.value.filter((booking) => {
        return (
            booking.status !== BookingStatus.Cancelled &&
            booking.journey == null &&
            (!booking.region ||
                !selectedJourney.value ||
                !selectedJourney.value?.region ||
                booking.region.uuid === selectedJourney.value.region.uuid)
        );
    });

    if (filters.value.serviceTypes.length > 0) {
        let serviceTypes = filters.value.serviceTypes.map(
            (serviceType) => serviceType.uuid
        );
        filtered = filtered.filter((booking) => {
            return serviceTypes.includes(booking?.service_type?.uuid ?? "");
        });
    }

    // filter distance between distance range selected
    if (filters.value.distance.value) {
        let maxDistance = filters.value.distance.value;
        let maxDistanceOption = distanceOptions.value.find(
            (option) => option.value === maxDistance
        );

        let minDistance = 0;

        if (maxDistanceOption) {
            if (distanceOptions.value.indexOf(maxDistanceOption) > 0) {
                minDistance =
                    distanceOptions.value[
                        distanceOptions.value.indexOf(maxDistanceOption) - 1
                    ].value;
            }
        }

        filtered = filtered.filter((booking) => {
            return (
                (booking?.estimated_distance ?? 0) <= maxDistance &&
                (booking?.estimated_distance ?? 0) >= minDistance
            );
        });
    }

    if (filters.value.originPostcodes.length > 0) {
        let originPostcodes = filters.value.originPostcodes.map(
            (postcode) => postcode.postcode
        );
        filtered = filtered.filter((booking) => {
            return originPostcodes.includes(Number(booking?.origin?.postcode));
        });
    }

    if (filters.value.destinationPostcodes.length > 0) {
        let destinationPostcodes = filters.value.destinationPostcodes.map(
            (postcode) => postcode.postcode
        );
        filtered = filtered.filter((booking) => {
            return destinationPostcodes.includes(
                Number(booking?.destination?.postcode)
            );
        });
    }

    if (filters.value.transportRequirements.length > 0) {
        let transportFilters = filters.value.transportRequirements;
        let filteredByTransportRequirement: any[] = [];

        filtered.filter((booking) => {
            transportFilters.filter((transportFilter) => {
                booking.transport_requirements?.filter((bookingRequirement) => {
                    if (bookingRequirement.code === transportFilter.value) {
                        if (!transportFilter.bool && transportFilter.number) {
                            if (
                                //@ts-ignore
                                parseInt(bookingRequirement?.pivot?.value) ===
                                transportFilter.number
                            ) {
                                filteredByTransportRequirement.push(
                                    booking.uuid
                                );
                            }
                        } else {
                            filteredByTransportRequirement.push(booking.uuid);
                        }
                    }
                });
            });
        });

        filtered = filtered.filter((booking) => {
            return filteredByTransportRequirement.includes(booking.uuid);
        });
    }

    if (filters.value.seatTypes.length > 0) {
        let seatFilters = filters.value.seatTypes;
        let filteredBySeatType: any[] = [];

        filtered.filter((booking) => {
            seatFilters.filter((seatFilter) => {
                if (booking.chosen_seat === seatFilter.value) {
                    filteredBySeatType.push(booking.uuid);
                }
            });
        });

        filtered = filtered.filter((booking) => {
            return filteredBySeatType.includes(booking.uuid);
        });
    }

    if (filters.value.vehicleGroups.length > 0) {
        let vehicleGroupFilters = filters.value.vehicleGroups;
        let filteredByVehicleGroup: any[] = [];

        filtered.filter((booking) => {
            vehicleGroupFilters.filter((vFilter) => {
                if (booking.vehicle_types.some(vt => vt.group === vFilter.value)) {
                    filteredByVehicleGroup.push(booking.uuid);
                }
            });
        });

        filtered = filtered.filter((booking) => {
            return filteredByVehicleGroup.includes(booking.uuid);
        });
    }

    bookingRowKey.value = Math.random();

    if (allocationSearchPhrase.value.trim().length > 0) {
        filtered = filtered.filter(matchesAllocationSearchPhrase);
    }

    return filtered;
});

watch(filteredBookings, (newFilteredBookings) => {
    displayedBookings.value = newFilteredBookings;
    sort();
});

const addOriginPostcode = (suburb) => {
    if (
        !suburb?.uuid ||
        filters.value.originPostcodes.find((s) => s.uuid === suburb.uuid)
    ) {
        return;
    }

    filters.value.originPostcodes.push(suburb);
};

const removeOriginPostcode = (suburb) => {
    filters.value.originPostcodes = filters.value.originPostcodes.filter(
        (s) => s.uuid !== suburb.uuid
    );
};

const addDestinationPostcode = (suburb) => {
    if (
        !suburb?.uuid ||
        filters.value.destinationPostcodes.find((s) => s.uuid === suburb.uuid)
    ) {
        return;
    }

    filters.value.destinationPostcodes.push(suburb);
};

const removeDestinationPostcode = (suburb) => {
    filters.value.destinationPostcodes =
        filters.value.destinationPostcodes.filter(
            (s) => s.uuid !== suburb.uuid
        );
};

const removeServiceType = (serviceType) => {
    filters.value.serviceTypes = filters.value.serviceTypes.filter(
        (s) => s.uuid !== serviceType.uuid
    );
};

const removeTransportRequirement = (index) => {
    filters.value.transportRequirements.splice(index, 1);
};

const allocateSelectedBookings = () => {
    if (!selectedJourney.value) {
        return;
    }

    selectedBookingsInUnAllocatedList.value.map((unallocatedBooking) => {
        unallocatedBooking.journey = selectedJourney.value;
    });

    checkAllocationRequirements(selectedBookingsInUnAllocatedList.value);

    selectedBookingsInUnAllocatedList.value = [];
};

const sort = () => {
    switch (sortBy.value) {
        case "priority":
            sortByPriority();
            break;
        case "client":
            sortByClient();
            break;
        case "destination":
            sortByDestination();
            break;
        default:
            sortByOrigin();
    }
};

const toggleSort = (column: string) => {
    sortDirection.value =
        sortBy.value !== column
            ? "asc"
            : sortDirection.value === "asc"
            ? "desc"
            : "asc";

    sortBy.value = column;

    sort();
};

const sortByPriority = () => {
    displayedBookings.value.sort((a, b) => {
        if (a.priority === b.priority) {
            return 0;
        }
        if (a.priority === "Low" || b.priority === "High") {
            return -1;
        }
        if (b.priority === "Low" || a.priority === "High") {
            return 1;
        }
        return 0;
    });
};

const sortByClient = () => {
    let newDisplayed = displayedBookings.value;
    newDisplayed.sort((a, b) => {
        //@ts-ignore
        if (a.client?.name < b.client?.name) {
            return -1;
        }
        //@ts-ignore
        if (a.client?.name > b.client?.name) {
            return 1;
        }
        return 0;
    });

    if (sortDirection.value == "desc") {
        newDisplayed.reverse();
    }

    displayedBookings.value = newDisplayed;
};

const sortByDestination = () => {
    let newDisplayed = displayedBookings.value;
    newDisplayed.sort((a, b) => {
        let destinationA =
            a.planned_destination_time ?? a.estimated_destination_time;
        let destinationB =
            b.planned_destination_time ?? b.estimated_destination_time;

        if (!destinationA) {
            return -1;
        }
        if (!destinationB) {
            return 1;
        }

        if (destinationA < destinationB) {
            return -1;
        }
        if (destinationA > destinationB) {
            return 1;
        }

        return 0;
    });

    if (sortDirection.value == "desc") {
        newDisplayed.reverse();
    }

    displayedBookings.value = newDisplayed;
};

const sortByOrigin = () => {
    let newDisplayed = displayedBookings.value;
    newDisplayed.sort((a, b) => {
        let originA = a.planned_origin_time ?? a.estimated_origin_time;
        let originB = b.planned_origin_time ?? b.estimated_origin_time;

        if (!originA) {
            return -1;
        }
        if (!originB) {
            return 1;
        }

        if (originA < originB) {
            return -1;
        }
        if (originA > originB) {
            return 1;
        }
        return 0;
    });

    if (sortDirection.value == "desc") {
        newDisplayed.reverse();
    }

    displayedBookings.value = newDisplayed;
};

const getTransportRequirements = () => {
    axios.get(route("api.transport-requirements.options")).then((response) => {
        transportRequirementOptions.value = response.data;
    });
};

const getSeatTypeOption = () => {
    axios.get(route("api.seat-types"))
        .then((response) => {
        seatTypeOptions.value = response.data.data.map((stype) => {
            return {
                value: stype.code,
                label: stype.name
            }
        });
    });
}

const getVehicleGroupOption = () => {
    VehicleGroup.getAll().then((response) => {
        vehicleGroups.value = response.data.data.map((g) => {
            return {
                value: g.code,
                label: g.name
            }
        })
    });
}

onBeforeMount(() => {
    getSeatTypeOption();
    getVehicleGroupOption();
});

onMounted(() => {
    getTransportRequirements();
});
</script>
<style scoped>
thead th {
    background-color: white;
}
th.sortable {
    cursor: pointer;
    min-width: 65px;
    position: relative;
}
th.sortable:before,
th.sortable:after {
    right: 0.5rem;
    left: auto;
    font-family: "Material Design Icons";
    font-size: 1rem;
    position: absolute;
    bottom: 0.5em;
    display: block;
    opacity: 0.3;
}
th.sortable:before {
    content: "\F0360";
    top: 2px;
}
th.sortable:after {
    content: "\F035D";
    top: 8px;
}
th.sortable.sort-asc:before {
    opacity: 1;
}
th.sortable.sort-desc:after {
    opacity: 1;
}
.booking-requirements-filter-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 2em;
    grid-row-gap: 1.5em;
}
.booking-requirements-filter-grid > div {
    white-space: nowrap;
}
</style>
