import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import Driver from "@classes/Driver";
import { useMessageStore } from '@stores/MessageStore';
import { usePaginationStore } from "./PaginationStore";

export const useDriverStore = defineStore('driverStore', () => {
    const { paginatedMeta, paginateParams, searchQuery } = storeToRefs(usePaginationStore());
    const { addToastMessage } = useMessageStore();

    const drivers = ref<App.Models.Driver[]>();
    const loadingDrivers = ref<boolean>(false);

    const getDrivers = (active?: boolean) => {
        loadingDrivers.value = true;

        let data = {
            paginate: 1,
            page: paginateParams.value.page,
            amount: paginateParams.value.perPage,
        }

        if (active !== undefined) {
            data.is_active_now = active;
        }

        Driver.index(data)
            .then((resp) => {
                drivers.value = resp.data.data;
                paginatedMeta.value = resp.data.meta;
            })
            .catch((err) => {
                addToastMessage('error', 'Failed to get clients list');
                console.error(err);
            })
            .finally(() => {
                loadingDrivers.value = false;
            });
    };

    return {
        drivers,
        loadingDrivers,
        getDrivers,
    }
})
