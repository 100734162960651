<template>
    <div>
        <Button @click="createCancellationReasonModal.show()">Create New</Button>
        <div id="createCancellationReasonModal" aria-hidden="true" aria-labelledby="createCancellationReasonModalLabel"
             class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createCancellationReasonModalLabel" class="modal-title">Create Cancellation Reason</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <BookingCancellationReasonEditOrCreateForm v-model="newCancellationReason"/>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
                        <Button :loading="storing" @click="storeBookingCancellationReason">Create</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateBookingCancellationReason"
}
</script>
<script setup>
import {onMounted, ref, inject} from "vue";
import {uuid} from "vue-uuid";
import Button from "../Button.vue";
import axios from "axios";
import BookingCancellationReasonEditOrCreateForm from "./BookingCancellationReasonEditOrCreateForm.vue";

const toast = inject('toast')

const createCancellationReasonModal = ref(null)
const newCancellationReason = ref({})

const emit = defineEmits(['created'])

const initFields = function () {
    newCancellationReason.value = {
        name: {
            uuid: uuid.v4(),
            value: '',
            errors: [],
            required: true,
        },
        fee_percentage: {
            uuid: uuid.v4(),
            value: 0,
            errors: [],
            required: false,
        },
        fee_amount_without_tax: {
            uuid: uuid.v4(),
            value: 0.00,
            errors: [],
            required: false,
        },
        add_gst: {
            value: true,
        },
    }
}
initFields()

onMounted(() => {
    createCancellationReasonModal.value = new bootstrap.Modal(document.getElementById('createCancellationReasonModal'))
})

const storing = ref(false)
const storeBookingCancellationReason = function () {
    storing.value = true

    axios
        .post(route('api.booking-cancellation-reasons.store'), {
            name: newCancellationReason.value.name.value,
            fee_percentage: newCancellationReason.value.fee_percentage.value,
            fee_amount_without_tax: newCancellationReason.value.fee_amount_without_tax.value * 100,
            add_gst: newCancellationReason.value.add_gst.value,
        })
        .then(function (response) {
            createCancellationReasonModal.value.hide()
            emit('created', response.data)
            initFields()
        })
        .catch(function (error) {
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                newCancellationReason.value[field].errors = errors
            }
        })
        .then(function () {
            storing.value = false
        })
}

</script>

<style scoped>

</style>
