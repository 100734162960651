<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div v-if="loading" class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading Payers</div>
                </div>
                <div v-if="!loading && payers.length === 0" class="w-100 justify-content-center align-items-center py-3">
                    <SvgIcon class="h-10 w-10 text-secondary mb-2" type="mdi" :path="mdiAccountCash"/>
                    <p class="lead">No Payers have been created yet.</p>
                </div>
                <div v-if="!loading" class="w-100 py-3">
                    <div class="w-50 d-inline-flex justify-content-start">
                        <Button @click="createPayer">Create Payer</Button>
                    </div>
                </div>
                <template v-if="!loading && payers.length > 0">
                    <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                        <tr>
                            <th>Payer Code</th>
                            <th>Name</th>
                            <th>DEX Outlet Activity</th>
                            <th>Contact Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th><span class="visually-hidden">Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(payer, index) in payers">
                            <tr>
                                <td>{{ payer.payer_code }}</td>
                                <td>{{ payer.name }}</td>
                                <td>
                                    <template v-if="payer.dex_outlet_activity">
                                        <strong>{{ payer.dex_outlet_activity.name }}</strong>
                                        <div class="small">{{ payer.dex_outlet_activity.dex_outlet_activity_id }}</div>
                                    </template>
                                    <template v-else>–</template>
                                </td>
                                <td>{{ payer.contact_name }}</td>
                                <td>
                                    <a v-if="payer.phone" :href="$filters.phone_url(payer.phone)" :title="'Call ' + payer.contact_name">
                                        {{ $filters.phone(payer.phone) }}
                                    </a>
                                </td>
                                <td><a :href="'mailto:' + payer.email ">{{ payer.email }}</a></td>
                                <td class="text-end">
                                    <a href="javascript: void(0)" @click="editPayer(payer)" class="mx-1 btn btn-sm btn-outline-primary">Edit</a>
                                    <a href="javascript: void(0)" @click="showInvoices(payer)" class="mx-1 btn btn-sm btn-outline-primary">Invoices</a>
                                    <a href="javascript: void(0)" @click="syncPayer(payer)" class="mx-1 btn btn-sm btn-outline-primary"><i class="mdi mdi-sync"
                                                                                                                                           v-bind:id="`payer-sync-icon-${payer.id}`"></i>
                                        Sync</a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="!loading"
                        @pageChanged="paginatePayers"
                        :pagination="paginationMeta"
                        record-name="payers"
                    ></Pagination>
                </template>
            </div>
        </div>

        <div v-if="showCreateEditModal" class="modal fade show d-block" tabindex="-1" aria-describedby="Prayer Modal Label">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="typeFormModalLabel">
                            {{ unsavedPayer.uuid ? 'Edit Payer' : 'Create Payer' }}
                        </h5>
                        <button type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-md-6">
                                <FormTextInput
                                    v-model:input="unsavedPayer.name"
                                    :key="fieldRefreshKey + '_name'"
                                    label="Name/Description"
                                    placeholder="e.g. ACME Pty Ltd"
                                    maxlength="255"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Invoice recipient</label>
                                <div>
                                    <div class="form-check form-check-inline" v-for="(value, key) in invoiceRecipientTypes">
                                        <input
                                            v-model="unsavedPayer.invoice_recipient_type" class="form-check-input" type="radio" name="invoice_recipient_type"
                                            :id="`invoice_recipient_type_${key}`" :value="key">
                                        <label class="form-check-label" :for="`invoice_recipient_type_${key}`">{{ value }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-6">
                                <FormTextInput
                                    v-model:input="unsavedPayer.payer_code"
                                    :key="fieldRefreshKey + '_code'"
                                    label="Payer code"
                                    placeholder="e.g. ABCD"
                                    :disabled="!!unsavedPayer.uuid"
                                    maxlength="70"
                                />
                            </div>
                            <div class="col-md-6">
                                <FormSelect
                                    v-model:selection="unsavedPayer.dex_outlet_activity"
                                    :key="fieldRefreshKey + '_dex_outlet_activity'"
                                    :options="dexOutletActivities"
                                    label="Data Exchange (DEX) outlet activity id"
                                    placeholder="-- Select Outlet Activity --"
                                    :disabled="!!unsavedPayer.uuid && !!unsavedPayer.dex_outlet_activity.value"
                                />
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="form-label required-label">Billing address</label>
                            <div class="row mb-1">
                                <div class="col-md-7">
                                    <FormTextInput
                                        v-model:input="unsavedPayer.billing_address.line1"
                                        :key="fieldRefreshKey + '_address_line_1'"
                                        label="Billing address line 1"
                                        label-class="visually-hidden"
                                        placeholder="e.g. 123 Fake Street"
                                    />
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-md-7">
                                    <FormTextInput
                                        v-model:input="unsavedPayer.billing_address.line2"
                                        :key="fieldRefreshKey + '_address_line_2'"
                                        label="Billing address line 2"
                                        label-class="visually-hidden"
                                        placeholder="e.g. Floor 10"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <FormTextInput
                                        v-model:input="unsavedPayer.billing_address.suburb"
                                        :key="fieldRefreshKey + '_suburb'"
                                        label="Suburb"
                                        placeholder="e.g. Sydney"
                                    />
                                </div>
                                <div class="col-md-3">
                                    <FormTextInput
                                        v-model:input="unsavedPayer.billing_address.postcode"
                                        :key="fieldRefreshKey + '_postcode'"
                                        label="Postcode"
                                        placeholder="e.g. 2000"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <FormTextarea
                                v-model:input="unsavedPayer.notes"
                                :key="fieldRefreshKey + '_notes'"
                                label="Notes"
                                placeholder="e.g. This payer is a subsidiary of ACME Pty Ltd"
                            />
                        </div>
                        <div class="card shadow-none border">
                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-md-7">
                                        <FormTextInput
                                            v-model:input="unsavedPayer.contact_name"
                                            :key="fieldRefreshKey + '_contact_name'"
                                            label="Contact name"
                                            placeholder="e.g. John Appleseed"
                                            maxlength="255"
                                        />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <FormEmailInput
                                            v-model:input="unsavedPayer.email"
                                            :key="fieldRefreshKey + '_email'"
                                            label="Contact email"
                                            placeholder="e.g. john.appleseed@example.com"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <FormPhoneInput
                                            v-model:input="unsavedPayer.phone"
                                            :key="fieldRefreshKey + '_phone'"
                                            label="Contact phone"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow-none border" v-if="trackingCategories">
                            <div class="card-header">
                                <h5><img class="me-2" src="/images/xero-logo.png" alt="Xero Logo" style="width: 30px;">Xero Tracking Categories</h5>
                            </div>
                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-md-6" v-for="(trackingCategory, index) in trackingCategories">
                                        <FormSelect
                                            v-model:selection="unsavedPayer.tracking_options[index]"
                                            :key="trackingCategory.tracking_category_id"
                                            :options="trackingCategory.options"
                                            :label="trackingCategory.name"
                                            option-key-field="tracking_option_id"
                                            option-label-field="name"
                                            v-if="trackingCategory.options.length"
                                            :placeholder="`-- Select ${trackingCategory.name} --`"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <div v-if="unsavedPayer.uuid">
                            <transition name="basic-fade" mode="out-in">
                                <Button
                                    v-if="!showDeleteConfirmation"
                                    color="danger-link"
                                    @click="showDeleteConfirmation = true"
                                >
                                    Delete
                                </Button>
                                <span v-else>
                                    Are you sure? <Button size="small" color="danger" class="ms-1" @click="deletePayer">Yes</Button>
                                </span>
                            </transition>
                        </div>
                        <div class="text-end">
                            <Button color="light" class="me-1" @click="toggleModal">Close</Button>
                            <Button v-if="unsavedPayer.uuid" @click="updatePayer" :loading="updating">Save changes</Button>
                            <Button v-else @click="savePayer" :loading="updating">Create Payer</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ImportPayersModal v-if="showImportingModal"></ImportPayersModal>
        <ShowPayerInvoices v-if="showInvoicesModal" :payer="selectedPayer" @close="toggleInvoicesModal"></ShowPayerInvoices>
    </div>
</template>

<script lang="ts">
export default {
    name: "ListPayers"
}
</script>
<script setup lang="ts">
import {isEmpty} from "lodash";
import {ref, inject, onMounted, onBeforeMount} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiAccountCash} from "@mdi/js";
import Button from "../Button.vue";
import {FormTextInput, FormEmailInput, FormPhoneInput, FormTextarea, FormSelect, FormSwitchInput} from "@components/Forms";
import Pagination from "@components/Pagination.vue";
import Uploader from '../FileUpload/Uploader.vue';
import ShowPayerInvoices from "@components/Payers/ShowPayerInvoices.vue";
import ImportPayersModal from "@components/Payers/ImportPayersModal.vue";
import {PayerResource} from "@customTypes/resources/PayerResource";
import {Axios} from "axios";
import {ToastInterface} from "@components/toast";
import TrackingCategory from "@classes/TrackingCategory";
import TrackingCategoryResource, {TrackingCategoryOptionResource} from "@customTypes/resources/TrackingCategoryResource";

const loading = ref(false);
const axios: Axios = <Axios>inject('axios');
const toast: ToastInterface = <ToastInterface>inject('toast');
const fieldRefreshKey = ref(Math.random())
const showCreateEditModal = ref(false);
const showImportingModal = ref(false);
const showInvoicesModal = ref(false);
const perPage = 25;
const origin = window.location.origin;
const selectedPayer = ref(null);
const invoiceRecipientTypes = window.enums.invoiceRecipientTypes;
const trackingCategories = ref<TrackingCategoryResource[]>();

let page = ref(1);
let paginationMeta = ref([]);
let payers = ref<PayerResource[]>([])
const getPayers = async () => {
    loading.value = true;

    let data = {
        paginate: true,
        perPage: 25,
        page: page.value,
    }

    await axios.get(route('api.payers.index', data))
        .then((response) => {
            paginationMeta.value = response.data.meta
            page.value = response.data.meta.current_page
            payers.value = response.data.data
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            loading.value = false;
        })
}

const paginatePayers = function (link) {
    page.value = link.page
    getPayers()
}

const unsavedPayer = ref({})

const showDeleteConfirmation = ref(false);

const initialiseData = () => {
    unsavedPayer.value = {
        uuid: null,
        name: {
            value: null,
            required: true,
        },
        payer_code: {
            value: null,
            required: true,
        },
        dex_outlet_activity: {
            value: null,
            required: false,
        },
        billing_address: {
            line1: {
                value: null,
                required: true,
            },
            line2: {
                value: null,
                required: false,
            },
            suburb: {
                value: null,
                required: true,
            },
            state: {
                value: null,
                required: true,
            },
            postcode: {
                value: null,
                required: true,
            },
        },
        notes: {
            value: null,
            required: false,
        },
        contact_name: {
            value: null,
            required: false,
        },
        phone: {
            value: null,
            required: false,
        },
        email: {
            value: null,
            required: false,
        },
        invoice_recipient_type: {
            value: null,
            required: true,
        },
        tracking_options: {
            value: [{
                tracking_option_id: null,
                name: null
            }, {
                tracking_option_id: null,
                name: null
            }]
        }
    }
}

const createPayer = function () {
    initialiseData()
    showCreateEditModal.value = true;
}

const payerEditing = ref<PayerResource | null>();

const editPayer = (payer: PayerResource) => {
    initialiseData()
    payerEditing.value = payer

    unsavedPayer.value.uuid = payer.uuid;
    unsavedPayer.value.name.value = payer.name;
    unsavedPayer.value.payer_code.value = payer.payer_code;
    unsavedPayer.value.dex_outlet_activity.value = payer.dex_outlet_activity ? payer.dex_outlet_activity.uuid : null;
    unsavedPayer.value.billing_address.line1.value = payer.billing_address ? payer.billing_address.line1 : '';
    unsavedPayer.value.billing_address.line2.value = payer.billing_address ? payer.billing_address.line2 : '';
    unsavedPayer.value.billing_address.suburb.value = payer.billing_address ? payer.billing_address.suburb : '';
    unsavedPayer.value.billing_address.state.value = payer.billing_address ? payer.billing_address.state : '';
    unsavedPayer.value.billing_address.postcode.value = payer.billing_address ? payer.billing_address.postcode : '';
    unsavedPayer.value.notes.value = payer.notes;
    unsavedPayer.value.contact_name.value = payer.contact_name;
    unsavedPayer.value.phone.value = payer.phone;
    unsavedPayer.value.email.value = payer.email;
    unsavedPayer.value.invoice_recipient_type = payer.invoice_recipient_type;
    unsavedPayer.value.tracking_options = payer.tracking_options;

    showDeleteConfirmation.value = false;
    fieldRefreshKey.value = Math.random();

    showCreateEditModal.value = true;
};

const updating = ref(false)
const updatePayer = function () {
    updating.value = true;

    let formData = getFormData();

    axios
        .put(route('api.payers.update', {payer: unsavedPayer.value.uuid}), formData)
        .then((response) => {
            toast.success("You successfully updated the payer **" + unsavedPayer.value.name.value + "**")
            payers.value = payers.value.map((payer) => {
                if (payer.uuid === unsavedPayer.value.uuid) {
                    if (!response.data.data) {
                        toast.error('Failed to update Payer');
                        return payer
                    }
                    return response.data.data;
                }
                return payer;
            });
            getPayers();
            showCreateEditModal.value = false;
            initialiseData();
            payerEditing.value = null;
        })
        .catch((error) => {
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (errorKey.startsWith('billing_address.')) {
                    unsavedPayer.value.billing_address[errorKey.replace('billing_address.', '')].errors = error.response.data.errors[errorKey];
                }

                if (!unsavedPayer.value[errorKey]) {
                    return
                }
                unsavedPayer.value[errorKey].errors = error.response.data.errors[errorKey]
            })
        })
        .finally(() => {
            updating.value = false;
        })
}

const savePayer = function () {
    updating.value = true;

    let formData = getFormData();

    formData['payer_code'] = unsavedPayer.value.payer_code.value;

    axios.post(route('api.payers.store', formData))
        .then((response) => {
            toast.success("You successfully created the payer **" + unsavedPayer.value.name.value + "**")
            payers.value = payers.value.map((payer) => {
                if (payer.uuid === unsavedPayer.value.uuid) {
                    if (!response.data.data) {
                        toast.error('Failed to create Payer');
                        return payer
                    }
                    return response.data.data;
                }
                return payer;
            });
            getPayers();
            showCreateEditModal.value = false;
            initialiseData();
        })
        .catch((error) => {
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (errorKey.startsWith('billing_address.')) {
                    unsavedPayer.value.billing_address[errorKey.replace('billing_address.', '')].errors = error.response.data.errors[errorKey];
                }

                if (!unsavedPayer.value[errorKey]) {
                    return
                }
                unsavedPayer.value[errorKey].errors = error.response.data.errors[errorKey]
            })
        })
        .finally(() => {
            updating.value = false;

        })
}

const syncPayer = function (payer) {
    const icon = document.getElementById(`payer-sync-icon-${payer.id}`);
    (<HTMLElement>icon).classList.toggle('mdi-spin');
    axios.post(route('xero.contacts.sync', {recipient: payer.uuid}))
        .then((response) => {
            toast.success("You successfully synced the payer **" + payer.name + "**")
            payers.value = payers.value.map((payer) => {
                if (payer.uuid === unsavedPayer.value.uuid) {
                    if (!response.data.data) {
                        toast.error('Failed to create Payer');
                        return payer
                    }
                    return response.data.data;
                }
                return payer;
            });
            getPayers();
            initialiseData();
        })
        .catch((error) => {
            Object.keys(error.response.data.errors).forEach((errorKey) => {
                if (errorKey.startsWith('billing_address.')) {
                    unsavedPayer.value.billing_address[errorKey.replace('billing_address.', '')].errors = error.response.data.errors[errorKey];
                }

                if (!unsavedPayer.value[errorKey]) {
                    return
                }
                unsavedPayer.value[errorKey].errors = error.response.data.errors[errorKey]
            })
        })
        .finally(() => {
            icon.classList.toggle('mdi-spin');
            updating.value = false;
        })
}

const showInvoices = (payer) => {
    selectedPayer.value = payer;
    showInvoicesModal.value = true;
}

const getFormData = () => {
    let formData = {
        name: unsavedPayer.value.name.value,
        billing_address: {
            line1: unsavedPayer.value.billing_address.line1.value,
            line2: unsavedPayer.value.billing_address.line2.value,
            suburb: unsavedPayer.value.billing_address.suburb.value,
            state: unsavedPayer.value.billing_address.state.value,
            postcode: unsavedPayer.value.billing_address.postcode.value,
        },
        notes: unsavedPayer.value.notes.value,
        contact_name: unsavedPayer.value.contact_name.value,
        phone: unsavedPayer.value.phone.value,
        email: unsavedPayer.value.email.value,
        invoice_recipient_type: unsavedPayer.value.invoice_recipient_type,
        tracking_options: unsavedPayer.value.tracking_options
    };

    if (payerEditing?.value?.dex_outlet_activity === null && unsavedPayer.value.dex_outlet_activity.value) {
        formData['dex_outlet_activity'] = unsavedPayer.value.dex_outlet_activity.value;
    }

    return formData
}

const deletePayer = function () {
    axios.delete(route('api.payers.destroy', {payer: unsavedPayer.value.uuid}))
        .then((resp) => {
            toast.success('Payer deleted successfully');
            getPayers();
            showCreateEditModal.value = false;
            initialiseData();
        }).catch((err) => {
        toast.error('Failed to delete Payer');
    });
}

const dexOutletActivities = ref([])
const getDexOutletActivities = () => {
    axios
        .get(route('api.dex-outlet-activities.index'))
        .then((response) => {
            dexOutletActivities.value = response.data.data.map((activity) => {
                return {
                    value: activity.uuid,
                    label: activity.name + ' (' + activity.dex_outlet_activity_id + ')',
                }
            })
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
}

const toggleModal = () => {
    showCreateEditModal.value = !showCreateEditModal.value;
}

const toggleImportModal = () => {
    showImportingModal.value = !showImportingModal.value;
}

const toggleInvoicesModal = () => {
    showInvoicesModal.value = !showInvoicesModal.value;
}

const ShowImportPayer = () => {
    showImportingModal.value = true;
}

const downloadTemplate = () => {
    window.open(route('payers.import.template'));
}

const getTrackingCategories = async () => {
    let response = await TrackingCategory.index();
    trackingCategories.value = response.data.data;
}

onBeforeMount(() => {
    initialiseData()
})

onMounted(() => {
    getPayers();
    getDexOutletActivities();
    getTrackingCategories();
});

</script>

<style scoped>

</style>
