<template>
    <table class="table table-bordered table-sm">
        <thead>
            <tr class="small">
                <th scope="col" class="text-center">Default</th>
                <th scope="col">Funding Type</th>
                <th scope="col">Default Payer</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(approvedFundingType, index) in approvedFundingTypes"
                :key="index"
            >
                <td class="align-middle col-1">
                    <div class="form-check d-flex justify-content-center">
                        <input
                            v-model="defaultFundingType"
                            @change="updateDefaultFundingType"
                            :id="`${approvedFundingType.funding_type?.uuid}-default`"
                            :name="`${approvedFundingType.funding_type?.uuid}-default`"
                            :value="approvedFundingType.funding_type?.uuid"
                            class="form-check-input"
                            type="radio"
                        />
                    </div>
                </td>
                <td class="align-middle col-5">
                    {{ approvedFundingType.funding_type?.name }}
                </td>
                <td class="align-middle col-5">
                    {{ approvedFundingType.default_payer?.name }}
                </td>
                <td>
                    <button
                        @click="removeFundingType(index)"
                        class="btn btn-sm btn-link"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiMinusCircle"
                            class="text-secondary"
                        ></SvgIcon>
                    </button>
                </td>
            </tr>
            <tr>
                <td class="align-middle col-1">
                    <div class="form-check d-flex justify-content-center">
                        <input
                            v-model="defaultFundingType"
                            @change="updateDefaultFundingType"
                            id="newIsDefault"
                            name="new-is-default"
                            class="form-check-input"
                            type="radio"
                            value="new_is_default"
                        />
                    </div>
                </td>
                <td class="align-middle col-5">
                    <select
                        v-model="draftApprovedFundingType.funding_type"
                        class="form-select form-select-sm"
                    >
                        <option value="">-- Select Funding Type --</option>
                        <template v-for="fundingType in selectableFundingTypes">
                            <option :value="fundingType.uuid">
                                {{ fundingType.name }}
                            </option>
                        </template>
                    </select>
                </td>
                <td class="align-middle col-5">
                    <select
                        v-model="draftApprovedFundingType.default_payer"
                        :disabled="!draftApprovedFundingType.funding_type"
                        class="form-select form-select-sm"
                    >
                        <option value="">-- Select Default Payer --</option>
                        <template
                            v-for="payer in selectablePayersForDraftFundingTypeSelected"
                        >
                            <option :value="payer.uuid">
                                {{ payer.name }}
                            </option>
                        </template>
                    </select>
                </td>
                <td>
                    <button
                        @click="addFundingType(draftApprovedFundingType)"
                        class="btn btn-sm btn-link"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiPlusCircle"
                            class="text-primary"
                        ></SvgIcon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, toRef, toRefs, computed } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusCircle, mdiMinusCircle } from "@mdi/js";

interface ApprovedFundingTypeRowInterface {
    is_default: boolean;
    funding_type: App.Models.FundingType;
    default_payer?: App.Models.Payer;
}

const props = withDefaults(
    defineProps<{
        approvedFundingTypes: ApprovedFundingTypeRowInterface[];
        fundingTypes: App.Models.FundingType[];
        payers: App.Models.Payer[];
    }>(),
    {
        //@ts-ignore
        approvedFundingTypes: [],
    }
);

const defaultFundingType = ref("");
const draftApprovedFundingType = ref({
    is_default: false,
    funding_type: "",
    default_payer: "",
});

const approvedFundingTypes = ref<ApprovedFundingTypeRowInterface[]>([]);

const { payers, fundingTypes } = toRefs(props);

const selectablePayersForDraftFundingTypeSelected = computed(() => {
    if (!draftApprovedFundingType.value.funding_type) {
        return [];
    }

    let selectedFundingType = fundingTypes.value.find(
        (type) => type.uuid === draftApprovedFundingType.value.funding_type
    );

    if (!selectedFundingType) {
        return [];
    }

    return (selectedFundingType.approved_payers ?? []).length > 0
        ? selectedFundingType.approved_payers
        : payers.value;
});

const emit = defineEmits(["updated"]);

const selectableFundingTypes = computed(() => {
    return props.fundingTypes.filter((type) => {
        return !approvedFundingTypes.value.find(
            (approvedType) => approvedType.funding_type?.uuid === type.uuid
        );
    });
});

const addFundingType = (selected) => {
    if (
        !selected.funding_type ||
        approvedFundingTypes.value.find(
            (type) => type.funding_type?.uuid === selected.funding_type
        )
    ) {
        return false;
    }

    let funding_type = props.fundingTypes.find(
        (type) => type.uuid === selected.funding_type
    );
    let default_payer = props.payers.find(
        (payer) => payer.uuid === selected.default_payer
    );

    if (!funding_type) {
        return false;
    }

    approvedFundingTypes.value.push({
        is_default:
            defaultFundingType.value === "new_is_default" ? true : false,
        funding_type,
        default_payer,
    });

    if (
        defaultFundingType.value === "new_is_default" ||
        approvedFundingTypes.value.length === 1
    ) {
        approvedFundingTypes.value.forEach((type) => {
            if (selected.funding_type !== type.funding_type?.uuid) {
                type.is_default = false;
            }
        });

        defaultFundingType.value = selected.funding_type;
    }

    draftApprovedFundingType.value = {
        is_default: false,
        funding_type: "",
        default_payer: "",
    };

    emit("updated", approvedFundingTypes.value);
};

const updateDefaultFundingType = () => {
    approvedFundingTypes.value.forEach((type) => {
        type.is_default = false;
    });

    approvedFundingTypes.value.forEach((type) => {
        if (defaultFundingType.value === type.funding_type?.uuid) {
            type.is_default = true;
        }
    });

    emit("updated", approvedFundingTypes.value);
};

const removeFundingType = (index) => {
    approvedFundingTypes.value.splice(index, 1);
};

onMounted(() => {
    if (props.approvedFundingTypes.length) {
        approvedFundingTypes.value = props.approvedFundingTypes;

        approvedFundingTypes.value.forEach((type) => {
            if (type.is_default) {
                defaultFundingType.value = type.funding_type?.uuid;
            }
        });
    }
});
</script>

<style scoped></style>
