<template>
    <div
        id="commentModal"
        class="modal fade modal-lg"
        aria-hidden="true"
        aria-labelledby="commentModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div v-if="!deleteCommentConfirmation" class="modal-content">
                <div class="modal-header">
                    <div>
                        <h3 class="modal-title" id="commentModalLabel">{{ heading() }}</h3>
                        <div v-if="action !== 'create'">by {{commentator}}</div>
                    </div>
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="action !== 'view'">
                    <FormTextarea
                        v-model:input="comment"
                        label="Comment"
                        maxlength="5000"
                        placeholder="e.g. assessment complete"
                    ></FormTextarea>
                </div>
                <div class="modal-body" v-else>
                    <div>
                        <span class="fw-bold">{{date}}</span> <a href="javascript:void(0);" @click="changeToEditForm">edit <SvgIcon
                        type="mdi"
                        :path="mdiPencil"
                        class="h-4 w-4 text-primary"
                    ></SvgIcon></a>
                    </div>
                    <div>
                        {{comment.value}}
                    </div>
                </div>
                <div v-if="action !== 'view'" class="modal-footer">
                    <Button
                        :loading="submitting"
                        @click="saveComment()"
                        color="primary"
                    > Save
                    </Button>
                    <Button
                        @click="closeModal"
                        color="light"
                    >
                        Cancel
                    </Button>
                </div>
                <div v-else class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-danger" @click="deleteCommentConfirmation = true">Delete</button>
                </div>
            </div>
            <div v-else class="modal-content text-center">
                <div class="modal-header">
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h3 class="modal-title">Delete Comment</h3>
                    <div>This comment will be permanently removed.</div>
                    <div>Would you like to continue?</div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="deleteComment">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, inject, watch, onMounted, computed} from 'vue'
import Button from '@components/Button.vue'
import {kebabCase} from "lodash";
import * as yup from "yup";
import FormTextarea from "@components/Forms/FormTextarea.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPencil } from '@mdi/js';
const toast = inject('toast');
const axios = inject('axios');

const props = defineProps({
    client: {
        type: Object,
        required: true,
    },
    id: {
        type: String,
        default: '',
    },
    comment: {
        type: String,
        default: ''
    },
    commentator: {
        type: String,
        default: ''
    },
    date: {
        type: String,
        default: ''
    },
    action: {
        type: String,
        default: '',
        required: true
    }
});

const comment = ref({
    value : '',
    valid: false,
});
const action = ref(props.action);
const actionList = ['view', 'edit', 'create'];
const commentModal = ref(null);
const emit = defineEmits(['closed', 'created', 'updated', 'deleted']);
const submitting = ref(false);
const commentator = computed(() => {
    return props.commentator;
});
const date = computed(() => {
    return props.date;
});
const deleteCommentConfirmation = ref(false);

const showModal = () => {
    deleteCommentConfirmation.value = false;
    commentModal.value.show();
}

const closeModal = () => {
    commentModal.value.hide();
    emit('closed', '');
}

const heading = () => {
    let defaultHeading = 'Comment';

    if (['edit', 'create'].includes(action.value)) {
        return action.value.charAt(0).toUpperCase() + action.value.slice(1) + ' '  + defaultHeading;
    }

    return defaultHeading;
}

const saveComment = () => {
    const payload = {
        comment: comment.value.value
    };

    switch (action.value) {
        case 'create':
            axios.post(route("api.clients.comments.store", {client: props.client.uuid}), payload)
            .then((resp) => {
                    toast.success('Comment is successfully added.');
                    emit('created', resp.data);
                    closeModal();
            }).catch((message) => {
                toast.error(message);
            });
            break;
        case 'edit':
            axios.put(route("api.clients.comments.update", {
                client: props.client.uuid,
                comment: props.id,
            }), payload)
            .then((resp) => {
                    toast.success('Comment is successfully updated.');
                    emit('updated', resp.data.data);
                    closeModal();
            }).catch((message) => {
                toast.error(message);
            });
            break;
    }
};

const changeToEditForm = () => {
    action.value = 'edit';
}

const deleteComment = () => {
    axios.delete(route("api.clients.comments.destroy", {
        client: props.client.uuid,
        comment: props.id,
    }))
    .then((resp) => {
        toast.success('Comment is successfully deleted.');
        emit('deleted', resp.data.data);
        closeModal();
    }).catch((message) => {
        toast.error(message);
    });
}

onMounted(() => {
    commentModal.value = new bootstrap.Modal(document.getElementById("commentModal"));
});

watch(props, (propsValue) => {
    action.value = propsValue.action;

    comment.value.value = propsValue.comment;

    if (action.value) {
        showModal();
    }
});

</script>

<style scoped>

</style>
