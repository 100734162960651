<template>
    <template v-if="mounted">
        <div class="col-md-6">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Transport Requirements</h4>
            </div>
            <div>
                Does {{props.requirementFor}} have wheelchair?
                <div>
                    <div v-for="seat in seatTypeOptions">
                        <label>
                            <input type="radio" v-model="chosenSeat" :value="seat.code"> {{ seat.code === 'standard_seat' ? 'No' : seat.name }}
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="chosenSeat" class="mt-4">
                <h4 class="header-title my-0 mb-3">Vehicle</h4>
                <div class="mb-2">Select suitable vehicles and type of seats to meet the client's requirement</div>
                <div v-for="vehicleGroup in bookingRequirementStore.getSeatVehicleGroups()" :set="isDisabled = seatIsDisabled(vehicleGroup)" class="border border-light p-2 mb-2">
                    <div class="row mb-2">
                        <b class="col-9">{{vehicleGroup.name}}</b><a href="javascript:void(0);" class="col-3" @click="selectAllVehiclesInGroup(vehicleGroup)">Select All</a>
                    </div>
                    <div class="row mb-2">
                        <label v-for="vehicleType in bookingRequirementStore.getSeatAvailableVehiclesTypeInGroup(vehicleGroup)" class="col-md-6">
                            <input type="checkbox" v-model="chosenVehicle[vehicleGroup.code].vehicles" :value="vehicleType.code"> {{ vehicleType.description }}
                        </label>
                    </div>
                    <div class="row mb-2">
                        <b class="">Seat Options</b>
                        <div v-if="isDisabled">(Please select vehicle to select position)</div>
                        <div v-else-if="seatIsEmpty(vehicleGroup)">
                            (Please select one of the seat)
                        </div>
                    </div>

                    <div class="row">
                        <template v-if="bookingRequirementStore.seatRequireHoist()">
                            <div class="col-md-12">
                                Track Required
                            </div>
                        </template>
                        <template v-if="bookingRequirementStore.seatIsTransferable()">
                            <label v-for="(positionCode) in vehicleGroup.available_seat_positions" class="col-md-6">
                                <input type="checkbox" v-model="chosenVehicle[vehicleGroup.code].seat_positions" :value="positionCode" :disabled="isDisabled">
                                {{ (bookingRequirementStore.getSeatPositionDetail(positionCode))?.label }}
                            </label>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <h4 class="header-title my-0 mb-3">Assistance Requirements</h4>
            <div class="mb-2">Please select the type of assistance required. Leave blank if not required.</div>
            <AssistanceTypeRequirementForm
                :details="assistanceTypes"
                :columns="1"
            />

            <h4 class="header-title mt-4 mb-3">Cargo Requirements</h4>
            <div class="mb-2">Please select the mobility aid that will be carried on. Leave blank if none.</div>
            <CargoRequirementForm />

            <h4 class="header-title mt-4 mb-3">Future Bookings</h4>
            <div class="mb-2">It will always update unallocated future bookings.</div>
            <div class="row mb-2">
                <label class="col-md-12">
                    <input type="checkbox" v-model="includeAllocatedBookings">
                    Also update future allocated bookings
                </label>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import {onBeforeMount, onMounted, ref, watch} from "vue";
import { onReady } from "../Utils";
import AssistanceTypeRequirementForm from "@components/AccessRequirements/AssistanceTypeRequirementForm.vue";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import {storeToRefs} from "pinia";
import CargoRequirementForm from "@components/AccessRequirements/CargoRequirementForm.vue";
import * as yup from "yup";
import {DateTime} from "luxon";
import { FormDateInput } from '../Forms';
import {uuid} from "vue-uuid";

const bookingRequirementStore = useBookingRequirementStore();

const {
    seatTypeOptions,
    chosenSeat,
    chosenVehicle,
    affectedBookings
} = storeToRefs(bookingRequirementStore);

const props = withDefaults(
    defineProps<{
        requirementFor?: string
    }>(),
    {
        requirementFor: 'client'
    }
);

const mounted = ref(false);
const assistanceTypes = ref([]);
const includeAllocatedBookings = ref(false);

const selectAllVehiclesInGroup = (vehicleGroup) => {
    const vehicles = bookingRequirementStore.getSeatAvailableVehiclesTypeInGroup(vehicleGroup);

    chosenVehicle.value[vehicleGroup.code].vehicles = vehicles.map((vehicle) => {
        return vehicle.code;
    });
}

const seatIsDisabled = (vehicleGroup) => {
    const vehicleChosen = chosenVehicle.value[vehicleGroup.code].vehicles.length > 0;

    return !vehicleChosen;
}

const seatIsEmpty = (vehicleGroup) => {
    const vehicleChosen = chosenVehicle.value[vehicleGroup.code].vehicles.length > 0;

    if (vehicleChosen &&
        chosenSeat.value !== 'non_transfer_manual_wheelchair' &&
        chosenSeat.value !== 'ewheelchair'
    ) {
        return !(chosenVehicle.value[vehicleGroup.code].seat_positions.length > 0);
    }

    return false;
}

onMounted(() => {
    mounted.value = true;
});

onBeforeMount(() => {
})

watch(chosenSeat, () => {
    bookingRequirementStore.resetVehicle();
    bookingRequirementStore.syncSeatAssistance();
    bookingRequirementStore.syncSeatWithCargo();
});

watch(includeAllocatedBookings, () => {
    if (includeAllocatedBookings.value === true) {
        affectedBookings.value = 'future_bookings_include_allocated';
    } else {
        affectedBookings.value = 'future_bookings';
    }
});
</script>

<style scoped>
</style>
