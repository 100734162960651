<template>
    <div v-if="mounted">
        <div v-if="selectedCargoSpaces.length > 0">
            <div
                v-for="(detail, index) in selectedCargoSpaces"
                :key="index"
                class="config-row align-items-center"
            >
                <div class="col-11 config-col align-self-center">
                    <span>{{ getDetailInfo(detail.code).name }}</span>
                    <span
                        v-tooltip="getDetailInfo(detail.code).description"
                        class="ms-1 mb-2"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        />
                    </span>
                </div>
<!--                <div class="col-5 config-col">
                    <span class="d-block text-end w-100">{{
                            detail.space
                        }}</span>
                </div>-->
                <div class="col-1 config-col" v-if="!detail.seat_type">
                    <button
                        @click="() => removeCargoSpace(index)"
                        class="h-100 w-100"
                        v-tooltip="'Remove Item'"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiCloseCircleOutline"
                            class="text-danger"
                        ></SvgIcon>
                    </button>
                </div>
            </div>
        </div>
        <div class="config-row">
            <div class="col-11 config-col">
                <select v-model="cargoSpaceSelected" class="form-select">
                    <option value="null" disabled>
                        Please select a cargo type to add
                    </option>
                    <option
                        v-for="(option, index) in filteredCargoSpaceOptions"
                        :value="option.code"
                        :key="index"
                    >
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="col-1 config-col justify-content-center">
                <button
                    @click="addCargoSpace"
                    class="h-100 w-100"
                    :disable="!cargoSpaceSelected"
                    v-tooltip="'Add Selected Cargo'"
                >
                    <SvgIcon
                        type="mdi"
                        :path="mdiPlusCircle"
                        class="text-primary"
                        size="30"
                    ></SvgIcon>
                </button>
            </div>
        </div>
        <div v-if="detailsValue.length > 0" class="config-row total mt-2 pt-1">
            <div class="col-6 config-col">
                <span><strong>Total Space:</strong></span>
            </div>
            <div class="col-6 config-col ps-2">
                <span class="d-block text-end w-100">{{ totalSpace }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRef } from "vue";
import { onReady, copyValues, userCan } from "../Utils";
import SvgIcon from "@jamescoyle/vue-icon";
import { CargoSpaceFormDetailsInterface } from "../../classes/CargoSpace";
import {
    mdiPlusCircle,
    mdiInformationOutline,
    mdiCloseCircleOutline,
} from "@mdi/js";
import { Button } from "../";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import { storeToRefs } from "pinia";

const bookingRequirementStore = useBookingRequirementStore();

const detailsValue = ref<CargoSpaceFormDetailsInterface[]>([]);

const mounted = ref(false);

const {
    cargoSpaceOptions,
    filteredCargoSpaceOptions,
    selectedCargoSpaces,
} = storeToRefs(bookingRequirementStore);

onReady(() => {
    mounted.value = true;
});

const cargoSpaceSelected = ref<null | string>(null);

const addCargoSpace = () => {
    let option = cargoSpaceOptions.value.find((option) => {
        return option.code === cargoSpaceSelected.value;
    });

    if (!option) {
        return;
    }

    let cargo = {
        code: option.code,
        space: option.space_required,
        removable: true
    };

    selectedCargoSpaces.value.push(cargo);

    cargoSpaceSelected.value = null;

    bookingRequirementStore.filterOutSelectedCargo();

    return new MouseEvent("");
};

const removeCargoSpace = (index: number) => {
    selectedCargoSpaces.value.splice(index, 1);

    return new MouseEvent("");
};

const getDetailInfo = (code: string) => {
    let result = cargoSpaceOptions.value.filter((option) => {
        return option.code === code;
    })[0];

    return result;
};

const totalSpace = computed(() => {
    return selectedCargoSpaces.value.reduce((total, detail) => {
        return (
            total +
            (isNaN(parseInt(detail.space.toString()))
                ? 0
                : parseInt(detail.space.toString()))
        );
    }, 0);
});

const emit = defineEmits(["updated"]);

// newValue and oldValue always the same, so need to emit update when this watcher is triggered
watch(
    detailsValue,
    (newValue, oldValue) => {
        emit("updated", newValue);
    },
    { deep: true }
);
</script>

<style scoped>
.config-row {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}
.config-col {
    padding: 10px 5px;
    justify-content: center;
}
.total {
    border-top: 1px solid var(--bs-gray-300);
}
</style>
