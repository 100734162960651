import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";

export default class VehicleType {
    static index() {
        return axios.get<LaravelResourceResponse<App.Models.VehicleType[]>>(
            route("api.vehicle-types.index")
        );
    }
}
