<template>
    <div
        v-if="bookingStore.draft.clients.length > 0"
        class="d-flex justify-content-end align-items-center mb-5"
    >
        <div
            v-if="Object.keys(bookingFormErrors).length > 0"
            class="text-danger me-3 text-end"
        >
            There are a number of errors,<br />please fix these before
            continuing.
        </div>
        <div v-if="!bookingEdit" class="btn-group">
            <button
                type="button"
                class="btn btn-primary"
                @click="() => storeBooking(false, false)"
                :disabled="savingBookingDetails"
            >
                <template v-if="!savingBookingDetails">
                    <template v-if="!bookingStore.draft.reschedule"
                        >Save Booking</template
                    >
                    <template v-if="bookingStore.draft.reschedule"
                        >Save Bookings</template
                    >
                </template>
                <template v-else>
                    <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Saving
                </template>
            </button>
            <div
                v-if="!bookingStore.draft.reschedule"
                class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="visually-hidden">Toggle Dropdown</span>
            </div>
            <ul class="dropdown-menu dropdown-menu-end">
                <li>
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="() => storeBooking(true, false)"
                    >
                        Save and allocate
                    </a>
                    <a
                        class="dropdown-item"
                        href="javascript: void(0)"
                        @click="() => storeBooking(false, true)"
                    >
                        Save and create another
                    </a>
                </li>
            </ul>
        </div>

        <Button
            v-if="bookingEdit"
            type="button"
            class="btn btn-primary"
            @click="handleUpdateBookingPress"
            :disabled="savingBookingDetails"
        >
            <template v-if="!savingBookingDetails">Update Booking</template>
            <template v-else>
                <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Saving
            </template>
        </Button>

        <BookingFormRecurranceUpdateModal
            v-model:show="showRecurringBookingModal"
            @recurringBookingUpdateBehaviour="
                (behaviour) => updateBooking(behaviour)
            "
        />

        <BookingFormUpdateDeallocationOptionsModal
            v-model:show="showUpdateDeallocationOptionsModal"
            @deallocate-on-update="onDeallocateOnUpdateChange"
        />
    </div>
</template>
<script setup lang="ts">
import { useBookingStore, ClientSpecificValue } from "@stores/BookingStore";
import { ref, inject } from "vue";
import { storeToRefs } from "pinia";
import { toTimeStamp, copyValues } from "@components/Utils.js";
import Location from "../../classes/Location";
import _ from "lodash";
import axios from "axios";
import { DateTime } from "luxon";
import BookingFormHelper from "../../classes/Helpers/BookingFormHelper";
import BookingFormRecurranceUpdateModal from "./BookingFormRecurranceUpdateModal.vue";
import BookingFormUpdateDeallocationOptionsModal from "./BookingFormUpdateDeallocationOptionsModal.vue";
import Booking, { RecurringBookingUpdateBehaviour } from "@classes/Booking";
import Button from "@components/Button.vue";
import BookingResource from "../../types/resources/BookingResource";
const toast = inject<any>("toast");

const bookingDetails: any = inject("bookingDetails");

const showRecurringBookingModal = ref(false);

const showUpdateDeallocationOptionsModal = ref(false);

const bookingStore = useBookingStore();

const { bookingFormErrors, draft, bookingEdit } = storeToRefs(bookingStore);

const savingBookingDetails = ref(false);

const deallocateOnUpdate = ref<boolean | null>(null);

const clientsHaveTagInconsistencies = () => {
    //Rachel and I discovered that atleast for now we want to ensure if they want to manually set a cost for a leg, they must set a cost the same way for all legs
    const currentDraft = _.cloneDeep(draft.value);

    let inconsistent = false;

    currentDraft.clients.forEach((client) => {
        let tagCounts: number[] = [];

        currentDraft.stops
            .filter((i, v) => v > 0)
            .forEach((stop, stopIndex) => {
                function getClientStopSpecificValueForKey<T>(
                    clientSpecificValue: ClientSpecificValue<T>[],
                    defaultValue: any = undefined
                ): T {
                    return (
                        clientSpecificValue.find(
                            (i) => i.client_uuid == client.uuid
                        )?.value ?? defaultValue
                    );
                }

                const tags = getClientStopSpecificValueForKey(
                    stop.tags,
                    []
                ).map((i) => i?.uuid);

                tagCounts.push(tags.length);
            });

        if (
            tagCounts.filter((i) => i == 0).length > 0 &&
            tagCounts.filter((i) => i > 0).length > 0
        ) {
            inconsistent = true;
        }
    });

    return inconsistent;
};

const generateStoreBookingRequestBody = () => {
    const currentDraft = _.cloneDeep(draft.value);

    const { reschedule, rescheduleOptions } = currentDraft;

    let bookings: any[] = [];
    let rescheduleSettings: any = null;
    if (reschedule) {
        rescheduleSettings = copyValues(rescheduleOptions);
    }

    currentDraft.stops.forEach((stop, stopIndex) => {
        if (stopIndex == 0) {
            return;
        }

        const previousStop = currentDraft.stops[stopIndex - 1];
        const totalClients = currentDraft.clients.length;

        currentDraft.clients.forEach((client, clientIndex) => {
            const directionResponse = stop.directionResponses.find(
                (i) => i.client_uuid == client.uuid
            )?.value;

            const historicalEstimatedDistance =
                stop.historicalEstimatedDistances.find(
                    (i) => i.client_uuid == client.uuid
                )?.value;

            const distance =
                historicalEstimatedDistance ??
                (directionResponse
                    ? Location.getDistanceFromDirections(directionResponse)
                    : 0);

            function getClientStopSpecificValueForKey<T>(
                clientSpecificValue: ClientSpecificValue<T>[],
                defaultValue: any = undefined
            ): T {
                return (
                    clientSpecificValue.find(
                        (i) => i.client_uuid == client.uuid
                    )?.value ?? defaultValue
                );
            }

            const manualCostAmount = getClientStopSpecificValueForKey(
                stop.manualFee,
                false
            )
                ? getClientStopSpecificValueForKey(stop.manualFeeAmount, 0)
                : null;

            const { origin, destination } =
                BookingFormHelper.getOriginDestinationForCurrentStop({
                    previousStop,
                    stop,
                    client,
                });

            let originUuid = origin?.uuid;

            let destinationUuid = destination?.uuid;

            const previousStopTime = previousStop?.time?.toFormat("HH:mm");
            const currentStopTime = stop?.time?.toFormat("HH:mm");

            let originTimings = {
                arrive:
                    stop?.scheduleType === "pick_up" ? currentStopTime : null,
                depart: null,
                requires_confirmation:
                    stop?.scheduleType === "pick_up"
                        ? stop.clientToConfirmTime
                        : false,
                flexibility: {
                    early:
                        stop?.scheduleType === "pick_up"
                            ? stop.flexibilityBefore
                            : null,
                    late:
                        stop?.scheduleType === "pick_up"
                            ? stop.flexibilityAfter
                            : null,
                },
            };

            let destinationTimings = {
                arrive:
                    stop?.scheduleType === "drop_off" ? currentStopTime : null,
                depart: null,
                requires_confirmation:
                    stop?.scheduleType === "drop_off"
                        ? stop.clientToConfirmTime
                        : false,
                flexibility: {
                    early:
                        stop?.scheduleType === "drop_off"
                            ? stop.flexibilityBefore
                            : null,
                    late:
                        stop?.scheduleType === "drop_off"
                            ? stop.flexibilityAfter
                            : null,
                },
            };

            const appointmentTime =
                getClientStopSpecificValueForKey<DateTime | null>(
                    stop.appointmentTime,
                    null
                );

            //Depart estimation of the previons stop. For first booking/stop we will get the estimated times from optimiser
            let estimatedTimings = getClientStopSpecificValueForKey(
                stop.estimatedTimings,
                0
            )?.timings;

            // if its multi clients and this client stop doesn't have estimates,
            // use other clients estimate
            if (
                estimatedTimings === undefined &&
                totalClients > 1 &&
                stop.estimatedTimings[0] !== undefined
            ) {
                for (var i = 0; i <= totalClients; i++) {
                    if (
                        stop.estimatedTimings[0].value?.timings[i] !== undefined
                    ) {
                        estimatedTimings = [
                            stop.estimatedTimings[0].value.timings[i],
                        ];
                    }
                }
            }

            let includeGST = getClientStopSpecificValueForKey(
                stop.includeGST,
                false
            );

            const estimatedOriginTime = estimatedTimings
                ? estimatedTimings[0]?.depart_estimation
                : null;

            //Depart estimation of the previons stop
            const estimatedDestinationTime = estimatedTimings
                ? estimatedTimings[0]?.arrive_estimation
                : null;

            bookings.push({
                uuid: getClientStopSpecificValueForKey(stop.uuid, null),
                client: client.uuid,
                purpose: stop.purpose,
                date: currentDraft.date,
                timings: [originTimings, destinationTimings],
                schedule_type: stop.scheduleType,
                appointment_time: appointmentTime
                    ? appointmentTime.toFormat("HH:mm")
                    : null,
                estimated_origin_time: estimatedOriginTime
                    ? estimatedOriginTime?.toFormat("HH:mm")
                    : null,
                estimated_destination_time: estimatedDestinationTime
                    ? estimatedDestinationTime?.toFormat("HH:mm")
                    : null,
                stops: [[originUuid], [destinationUuid]],
                additional_minutes_required: getClientStopSpecificValueForKey(
                    stop.additionalTimeNeeded,
                    0
                ),
                maximum_transit_minutes: getClientStopSpecificValueForKey(
                    stop.maximumTransitTime,
                    0
                ),
                estimated_distance: distance,
                funding: {
                    fundingType: getClientStopSpecificValueForKey(
                        currentDraft.fundingTypeUuid
                    ),
                    manualCostAmount,
                    payer: getClientStopSpecificValueForKey(
                        currentDraft.payerUuid
                    ),
                    includeGST,
                },
                special_notes: getClientStopSpecificValueForKey(stop.notes),
                client_alert: "",
                referring_contact_id: getClientStopSpecificValueForKey(
                    currentDraft.agencyContactUuid
                ),
                group_booking: stop.transportType,
                priority: getClientStopSpecificValueForKey(stop.priority),
                treatment_type: stop.treatmentTypeUuid,
                tags: getClientStopSpecificValueForKey(stop.tags, []).map(
                    (i) => i?.uuid
                ),
                general_notes: getClientStopSpecificValueForKey(stop.notes),
                client_notes: getClientStopSpecificValueForKey(
                    stop.client_notes
                ),
                reference: getClientStopSpecificValueForKey(
                    stop.referenceNumber
                ),
                agency: getClientStopSpecificValueForKey(
                    currentDraft.agencyUuid,
                    null
                ),
                agency_contact: getClientStopSpecificValueForKey(
                    currentDraft.agencyContactUuid,
                    null
                ),
                booking_requirements: getClientStopSpecificValueForKey(
                    stop.bookingRequirements,
                    {}
                ),
                fare_collection_method: getClientStopSpecificValueForKey(
                    currentDraft.fareCollectionMethod,
                    null
                ),
                reschedule: rescheduleSettings,
                region: getClientStopSpecificValueForKey(
                    currentDraft.regionUuid,
                    null
                ),
                carers: getClientStopSpecificValueForKey(
                    currentDraft.companionUuids,
                    null
                ),
                booking_service_type: stop.bookingServiceType,
            });
        });
    });

    return { bookings: bookings };
};

const handleValidationErrorsDisplay = (error: any) => {
    Object.keys(error.response.data.errors).forEach((errorType) => {
        switch (true) {
            case errorType.includes("stops"):
                bookingFormErrors.value["stops"] =
                    error.response.data.errors[errorType][0];
                break;
            case errorType.includes("timings"):
                bookingFormErrors.value["timings"] =
                    error.response.data.errors[errorType][0];
                break;
            case errorType.includes("date"):
                bookingFormErrors.value["date"] =
                    error.response.data.errors[errorType][0];
                break;
            case errorType.includes("return_booking_origin_time"):
                bookingFormErrors.value["return_booking_origin_time"] =
                    error.response.data.errors[errorType][0];
                break;
            case errorType.includes("booking_service_type"):
                bookingFormErrors.value["booking_service_type"] =
                    error.response.data.errors[errorType][0];
                break;
            case errorType.includes("fundingType"):
                bookingFormErrors.value["fundingType"] =
                    error.response.data.errors[errorType][0];
                break;
        }
    });
};

const storeBooking = (
    showAllocationModal = false,
    saveAndCreateAnother = false
): MouseEvent => {
    let event: MouseEvent = new MouseEvent("");

    if (clientsHaveTagInconsistencies()) {
        toast.error(
            "Please ensure for all clients if one leg has tags, the other legs also have tags."
        );

        return event;
    }

    const payload = generateStoreBookingRequestBody();
    const currentDraft = _.cloneDeep(draft.value);

    bookingFormErrors.value = {};

    savingBookingDetails.value = true;
    axios
        .post(route("bookings.store"), payload)
        .then((response) => {
            if (showAllocationModal) {
                savingBookingDetails.value = false;
                bookingDetails.showForBookingId(response.data[0], {
                    onDismiss: () => {
                        location.reload();
                    },
                });
                return;
            } else if (saveAndCreateAnother) {
                savingBookingDetails.value = false;

                location.href = route("bookings.create", {
                    client: currentDraft.clients?.map((i) => i.uuid),
                });

                return;
            }

            location.href = route("bookings.create");
        })
        .catch((error) => {
            toast.error(
                error?.response?.data?.message ??
                    "Something went wrong, please try again"
            );
            console.error({ error });
            savingBookingDetails.value = false;
            if (error.response.status === 422) {
                savingBookingDetails.value = false;
                handleValidationErrorsDisplay(error);
            }
        });
    //To stop TS from complaining
    return event;
};

const deallocationPromptRequiredOnUpdate = async () => {
    let bookingsWithMatchingFormIdentifier: BookingResource[] = [];
    let bookingsWithMatchingRescheduleIdentifier: BookingResource[] = [];

    //Check if any of the bookings for this form have been allocated
    if (bookingEdit.value?.booking_form_identifier) {
        savingBookingDetails.value = true;
        bookingsWithMatchingFormIdentifier = (
            await Booking.index({
                booking_form_identifier:
                    bookingEdit.value?.booking_form_identifier,
            })
        ).data;
    }

    if (bookingEdit.value?.reschedule_identifier) {
        savingBookingDetails.value = true;
        bookingsWithMatchingRescheduleIdentifier = (
            await Booking.index({
                reschedule_identifier: bookingEdit.value?.reschedule_identifier,
            })
        ).data;
    }

    savingBookingDetails.value = false;

    return (
        bookingsWithMatchingFormIdentifier.some((i) => i.journey) ||
        bookingsWithMatchingRescheduleIdentifier.some((i) => i.journey) ||
        bookingEdit.value?.journey
    );
};

const onDeallocateOnUpdateChange = (value: boolean) => {
    deallocateOnUpdate.value = value;
    handleUpdateBookingPress();
};

const handleUpdateBookingPress = async (): Promise<MouseEvent> => {
    let event: MouseEvent = new MouseEvent("");

    let deallocationPromptRequired = await deallocationPromptRequiredOnUpdate();

    if (deallocationPromptRequired && deallocateOnUpdate.value == null) {
        showUpdateDeallocationOptionsModal.value = true;
        return event;
    } else if (bookingEdit.value?.reschedule_identifier) {
        showRecurringBookingModal.value = true;
        return event;
    } else {
        updateBooking();
    }

    return event;
};

const updateBooking = (
    recurringBookingUpdateBehaviour: RecurringBookingUpdateBehaviour = RecurringBookingUpdateBehaviour.SaveForAllFutureBookings
): MouseEvent => {
    let event: MouseEvent = new MouseEvent("");
    const payload = {
        ...generateStoreBookingRequestBody(),
        ...{
            recurring_booking_update_behaviour: recurringBookingUpdateBehaviour,
            deallocate_on_update: deallocateOnUpdate.value,
        },
    };

    bookingFormErrors.value = {};

    savingBookingDetails.value = true;
    axios
        .put(
            route("bookings.update", { booking: bookingEdit.value?.uuid }),
            payload
        )
        .then((response) => {
            location.href = route("bookings.index");
        })
        .catch((error) => {
            toast.error(
                error?.response?.data?.message ??
                    "Something went wrong, please try again"
            );
            console.error({ error });
            savingBookingDetails.value = false;
            if (error.response.status === 422) {
                savingBookingDetails.value = false;
                handleValidationErrorsDisplay(error);
            }
        });
    //To stop TS from complaining
    return event;
};
</script>
