import axios from "axios";

export default class BookingRequirement {
    static getClientDefaultBookingRequirement(clientUuid: string)
    {
        return axios.get(route('api.clients.booking-requirements.index', {client: clientUuid}));
    }

    static getBookingRequirement(bookingUuid: string)
    {
        return axios.get(route('api.bookings.booking-requirements', {booking: bookingUuid}));
    }
}
