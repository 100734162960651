<template>
    <div>
        <div style="overflow-y: auto; max-height: 95vh">
            <!-- <template v-if="(selectedJourney?.bookings ?? []).length > 0"> -->

            <div class="btn-group float-end">
              <a class="btn btn-light" :href="printJournetUrl"><i class="mdi mdi-printer me-1"></i>Print Run-List</a>
              <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" :href="printJournetUrl + '?format=verbose'">Print verbose format</a></li>
              </ul>
            </div>

            <AllocationFormJourneyBookings
                :journey="selectedJourney"
            ></AllocationFormJourneyBookings>

            <div
                v-if="allocatedBookingsToJourney.length === 0"
                class="text-center"
            >
                <SvgIcon
                    type="mdi"
                    :path="selectCarIcon"
                    class="text-secondary h-10 w-10 my-3"
                ></SvgIcon>
                <p class="fw-bold text-lg">No bookings</p>
                <p>
                    Allocate a booking to this vehicle run by drag and dropping
                    one of the unallocated bookings on the right side of the
                    screen above.
                </p>
            </div>

            <AllocationsFormJourneyBreaks />

            <div class="mt-3">
                <label for="journeyNotes" class="form-label"
                    >Vehicle Run Notes:</label
                >
                <textarea
                    id="journeyNotes"
                    class="form-control"
                    v-model="notes"
                    placeholder="Type run list notes here..."
                ></textarea>
                <div v-if="notesChanged" class="mt-2">
                    <button
                        class="btn btn-primary me-1"
                        role="button"
                        :disabled="savingJourneyNotes"
                        @click="saveJourneyNotes"
                    >
                        <template v-if="!savingJourneyNotes">
                            <i class="mdi mdi-content-save me-1"></i>Save Notes
                        </template>
                        <template v-else>
                            <div
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                            >
                                <span class="visually-hidden">Saving...</span>
                            </div>
                        </template>
                    </button>
                    <button
                        class="btn btn-light"
                        role="button"
                        :disabled="savingJourneyNotes"
                        @click="notes = selectedJourney?.notes ?? ''"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">Seat Usage:</label>
                <AllocationFormSeatUsageChart />
            </div>
            <!-- <div>
                    <label class="form-label">Timeline:</label>
                    <TimeLine
                        :height="'340px'"
                        :resources="resources"
                        :events="events"
                        :key="(currentTrips ?? []).length + '_journeyTimeline'"
                        :now-indicator="
                            moment(props.journey.start_time).format('D') ===
                            moment().format('D')
                        "
                    ></TimeLine>
                </div> -->
            <!-- </template> -->
        </div>
    </div>
</template>

<script setup lang="ts">
import AllocationFormJourneyBookings from "./AllocationFormJourneyBookings.vue";
import AllocationJourneyAdditionalCrewForm from "./AllocationJourneyAdditionalCrewForm.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import TimeLine from "../TimeLine.vue";
import { mdiCarSelect } from "@mdi/js";
import { computed, onBeforeMount, ref, watch } from "vue";
import moment from "moment";
import axios from "axios";
import AllocationsFormJourneyBreaks from "./Components/AllocationsFormJourneyBreaks.vue";

import { useAllocationStore } from "@stores/AllocationStore";
import { storeToRefs } from "pinia";
import AllocationFormSeatUsageChart from "./AllocationFormSeatUsageChart.vue";

const allocationStore = useAllocationStore();

const { bookingsAllocatedToJourney } = allocationStore;
const { selectedJourney } = storeToRefs(allocationStore);

const hasAdditionalCrew = ref(false);
const notes = ref("");
const notesChanged = ref(false);
const savingJourneyNotes = ref(false);
const selectCarIcon = ref(mdiCarSelect);

const printJournetUrl = route("journeys.print", {
    journey: selectedJourney.value?.uuid,
});

const events = computed(() => {
    let events: any[] = [];

    let bookings = JSON.parse(JSON.stringify(selectedJourney.value?.bookings));
    bookings.forEach((booking) => {
        events.push({
            resourceId: booking.uuid,
            title: booking.client.name,
            start:
                moment(booking.planned_origin_time).format("YYYY-MM-DD") +
                " " +
                moment(booking.planned_origin_time).format("HH:mm:ss"),
            end:
                moment(booking.planned_destination_time).format("YYYY-MM-DD") +
                " " +
                moment(booking.planned_destination_time).format("HH:mm:ss"),
        });
    });

    events.push({
        resourceId: "availability",
        title: "Availability",
        start: selectedJourney.value?.start_time,
        end: selectedJourney.value?.end_time,
    });

    return events;
});

const resources = computed(() => {
    let resources: any[] = [];
    let bookings = JSON.parse(JSON.stringify(currentTrips.value));
    bookings.forEach((booking) => {
        resources.push({
            id: booking.uuid,
            content: booking.client.name,
        });
    });

    resources.push({
        id: "availability",
        content: "Availability",
        eventColor: "#919ca7",
    });

    return resources;
});

const currentTrips = computed(() => {
    return selectedJourney.value?.bookings?.filter((booking) => {
        return !["cancelled", "standby"].includes(booking.status);
    });
});

const allocatedBookingsToJourney = computed(() => {
        if (!selectedJourney.value) {
            return [];
        }
        return bookingsAllocatedToJourney(
            selectedJourney.value
        )
    }
)

watch(notes, (newNotes) => {
    notesChanged.value = newNotes !== selectedJourney.value?.notes;
});

const saveJourneyNotes = () => {
    savingJourneyNotes.value = true;
    axios
        .put(
            route("api.journeys.update", {
                journey: selectedJourney.value?.uuid,
            }),
            {
                notes: notes.value,
            }
        )
        .then(() => {
            notesChanged.value = false;
            if (selectedJourney.value) {
                selectedJourney.value.notes = notes.value;
            }
            savingJourneyNotes.value = false;
        });
};

onBeforeMount(() => {
    notes.value = selectedJourney.value?.notes ?? "";
});
</script>
