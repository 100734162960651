<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <label for="dateSelector">Select Date:</label>
                <DateAdjuster
                    v-model="date"
                    :disabled="loadingJourneys"
                    disable-future-dates
                ></DateAdjuster>
            </div>
        </div>

        <div class="mt-2 d-flex justify-content-between">
            <div class="d-flex">
                <CheckboxButton v-model="statusFilter.waitingVerification" color="secondary">
                    Waiting Verification ({{ getTotalJourneysOnStatus('waiting_verification') }})
                </CheckboxButton>
                <CheckboxButton v-model="statusFilter.pendingInformation" color="secondary">
                    Pending Information ({{ getTotalJourneysOnStatus('pending_information') }})
                </CheckboxButton>
                <CheckboxButton v-model="statusFilter.verified" color="secondary">
                    Verified ({{ getTotalJourneysOnStatus('verified') }})
                </CheckboxButton>
                <CheckboxButton v-model="statusFilter.unmet" color="secondary">
                    Unmet ({{ getTotalJourneysOnStatus('unmet') }})
                </CheckboxButton>
                <CheckboxButton v-model="statusFilter.cancelled" color="secondary">
                    Cancelled ({{ getTotalJourneysOnStatus('cancelled') }})
                </CheckboxButton>
                <CheckboxButton v-model="statusFilter.future" color="secondary">
                    Future ({{ getTotalJourneysOnStatus('future') }})
                </CheckboxButton>
            </div>
            <div>
                <div class="input-group">
                    <span class="input-group-text">
                        <SvgIcon :path="mdiMagnify" class="h-5 w-5" type="mdi"/>
                    </span>
                    <input @input="updateQuery" class="form-control" placeholder="Search client name..." type="text">
                </div>
            </div>
        </div>

        <div v-if="loadingJourneys" class="d-flex w-100 justify-content-center align-items-center ps-3">
            <div class="spinner-grow my-5 me-3" role="status"></div>
            <div class="fs-4">Loading Vehicle Runs</div>
        </div>

        <template v-if="!loadingJourneys">

            <VerificationJourneyGroup
                v-if="statusFilter.waitingVerification && waitingVerificationHasJourney()"
                :status-statistics="journeysWaitingVerification"
                key="waitingVerification"
                class="border-warning"
                @bookingReallocated="reloadData"
                :drivers="drivers"
                :query-string="query"
            >
                <template #header>Waiting Verification</template>
            </VerificationJourneyGroup>

            <VerificationJourneyGroup
                v-if="statusFilter.future && futureHasJourneys()"
                :status-statistics="futureJourneys"
                key="future"
                class="border-info"
                @bookingReallocated="reloadData"
                :drivers="drivers"
                :query-string="query"
            >
                <template #header>Future</template>
            </VerificationJourneyGroup>

            <VerificationJourneyGroup
                v-if="statusFilter.pendingInformation && pendingInformationHasJourney()"
                :status-statistics="journeysPendingInformation"
                key="pendingInformation"
                class="border-warning"
                @bookingReallocated="reloadData"
                :drivers="drivers"
                :query-string="query"
            >
                <template #header>Pending Information</template>
            </VerificationJourneyGroup>

            <VerificationJourneyGroup
                v-if="statusFilter.verified && verifiedHasJourney()"
                key="verified"
                :status-statistics="verifiedJourneys"
                class="border-success"
                @bookingReallocated="reloadData"
                :drivers="drivers"
                :query-string="query"
            >
                <template #header>Verified</template>
            </VerificationJourneyGroup>

            <VerificationJourneyGroup
                v-if="statusFilter.unmet && unmetHasJourney()"
                key="unmet"
                :status-statistics="unmetJourneys"
                class="border-danger"
                @bookingReallocated="reloadData"
                :drivers="drivers"
                :query-string="query"
            >
                <template #header>Unmet Vehicle Runs</template>
            </VerificationJourneyGroup>

            <VerificationJourneyGroup
                v-if="statusFilter.cancelled && cancelledHasJourney()"
                key="cancelled"
                :status-statistics="cancelledJourneys"
                @bookingReallocated="reloadData"
                :query-string="query"
            >
                <template #header>Cancelled Vehicle Runs</template>
            </VerificationJourneyGroup>
        </template>

        <div v-if="!loadingJourneys && verificationStore.journeys.length === 0"
             class="d-flex flex-column w-100 justify-content-center align-items-center py-5">
            <SvgIcon :path="mdiCarOff" class="h-8 w-8 mb-2" type="mdi"/>
            <div class="fs-4">There are no vehicle runs for this day</div>
        </div>
        <div v-else-if="!loadingJourneys && noFilteredResults"
            class="d-flex flex-column w-100 justify-content-center align-items-center py-5"
        >
            <SvgIcon :path="mdiCarOff" class="h-8 w-8 mb-2" type="mdi"/>
            <div class="fs-4">There are no vehicle runs that can be verified for this day</div>
        </div>

        <Transactions />

    </div>
</template>

<script>
export default {
    name: "Verifications"
}
</script>
<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiCarOff,
    mdiMagnify
} from '@mdi/js';
import {computed, onBeforeMount, onMounted, ref, watch, inject} from 'vue'
import {DateTime} from 'luxon'
const axios = inject('axios')
import DateAdjuster from "../DateAdjuster.vue";
import VerificationJourneyGroup from "./VerificationJourneyGroup.vue";
import CheckboxButton from "../CheckboxButton.vue";
import {copyValues} from "../Utils.js";
import {useVerificationStore} from "@stores/VerificationStore";
import Transactions from "./Transactions.vue";
import {storeToRefs} from "pinia";
import { debounce } from "lodash";
import { useRegionStore } from "@stores/RegionStore";

const verificationStore = useVerificationStore();
const {
    journeys,
    journeysNoDetail,
    bookingReallocated,
    reloadStatisticRequired,
    searchBookingResult
} = storeToRefs(verificationStore);
const { selectedRegion } = storeToRefs(useRegionStore());

const props = defineProps({
    forDate: {
        type: String,
        required: false,
        default: null,
    }
})

const statusFilter = ref({
    waitingVerification: true,
    verified: true,
    unmet: true,
    pendingInformation: true,
    cancelled: true,
    future: true,
})

const date = ref(null)
onBeforeMount(() => {
    date.value = props.forDate
        ? DateTime.fromISO(props.forDate).toFormat('yyyy-MM-dd')
        : DateTime.local().minus({days: 1}).toFormat('yyyy-MM-dd')
})

watch(date, () => {
    if (!date.value) {
        return
    }

    window.history.pushState({}, '', route('bookings.verify', {date: date.value}))
    getStatistics();
})

const query = ref('');

const journeysWaitingVerification = ref([]);
const verifiedJourneys = ref([]);
const unmetJourneys = ref([]);
const cancelledJourneys = ref([]);
const journeysPendingInformation = ref([]);
const futureJourneys = ref([]);

const noFilteredResults = computed(() => {
    return journeysWaitingVerification.value.breakdown?.length === 0
        && verifiedJourneys.value.breakdown?.length === 0
        && unmetJourneys.value.breakdown?.length === 0
        && cancelledJourneys.value.breakdown?.length === 0
        && journeysPendingInformation.value.breakdown?.length === 0
})

const loadingJourneys = ref(true)

const drivers = ref([])
const getDrivers = function () {
    axios
        .get(route('api.drivers.index'))
        .then(response => {
            drivers.value = response.data.data
        })
        .catch((err) => console.error(err));
}

const getFundingTypes = function () {
    axios
        .get(route('api.funding-types.index'))
        .then((response) => {
            verificationStore.fundingTypes = response.data
        })
}

const getPayers = function () {
    axios
        .get(route('api.payers.index'))
        .then((response) => {
            verificationStore.payers = Object.fromEntries(response.data.data.map(payer => [payer.uuid, payer.name]))
        })
}

const getTotalJourneysOnStatus = (JourneyStatus) => {
    const JourneyWithStatus = journeysNoDetail.value.filter(j => j.status === JourneyStatus);
    return JourneyWithStatus.length;
}

/**
 * This is being used for reallocate bookings list
 */
const assignJourneyNoDetail = (responseData) => {
    journeysNoDetail.value = [];

    if (responseData.waiting_verification.breakdown) {
        responseData.waiting_verification.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }

    if (responseData.verified.breakdown) {
        responseData.verified.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }

    if (responseData.unmet.breakdown) {
        responseData.unmet.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }

    if (responseData.cancelled.breakdown) {
        responseData.cancelled.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }

    if (responseData.pending_information.breakdown) {
        responseData.pending_information.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }

    if (responseData.future.breakdown) {
        responseData.future.breakdown.forEach((j) => {
            journeysNoDetail.value.push(j);
        })
    }
}

const getStatistics = () => {
    axios
        .get(route('api.bookings.verification.statistics'), {
            params: {date: date.value}
        })
        .then(response => {
            journeysWaitingVerification.value = response.data.data.waiting_verification;
            verifiedJourneys.value = response.data.data.verified;
            unmetJourneys.value = response.data.data.unmet;
            cancelledJourneys.value = response.data.data.cancelled;
            journeysPendingInformation.value = response.data.data.pending_information;
            futureJourneys.value = response.data.data.future;

            assignJourneyNoDetail(response.data.data);
        })
        .finally(() => {
            loadingJourneys.value = false
        })
}

/**
 * When there is a search, it works differently with load page.
 * Search will just get the bookings and using the journey uuid in bookings we assign the bookings to filtered Journey
 * so then it is being filtered in the status group and inside the journey
 * @param keyword
 */
const searchBookings = (keyword) => {
    axios.get(route('api.bookings.verification.search'), {
        params: {
            date: date.value,
            query: keyword
        }
    }).then(response => {
        searchBookingResult.value = response.data.data;
        query.value = keyword
    });
}

const reloadData = () => {
    getStatistics();
}

const updateQuery = debounce((e) => {
    const keyword = e.target.value;

    if (keyword) {
        searchBookings(keyword);
    } else {
        getStatistics();
        query.value = '';
    }
}, 500);



const waitingVerificationHasJourney = () => {
    if (!query.value) {
        return journeysWaitingVerification.value.breakdown?.length > 0;
    }

    return journeysWaitingVerification.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};
const verifiedHasJourney = () => {
    if (!query.value) {
        return verifiedJourneys.value.breakdown?.length > 0;
    }

    return verifiedJourneys.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};
const unmetHasJourney = () => {
    if (!query.value) {
        return unmetJourneys.value.breakdown?.length > 0;
    }

    return unmetJourneys.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};
const cancelledHasJourney = () => {
    if (!query.value) {
        return cancelledJourneys.value.breakdown?.length > 0;
    }

    return cancelledJourneys.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};
const pendingInformationHasJourney = () => {
    if (!query.value) {
        return journeysPendingInformation.value.breakdown?.length > 0;
    }

    return journeysPendingInformation.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};
const futureHasJourneys = () => {
    if (!query.value) {
        return futureJourneys.value.breakdown?.length > 0;
    }

    return futureJourneys.value.breakdown.some((journey) => {
        return searchBookingResult.value.some(booking => journey.uuid === booking.journey?.uuid);
    });
};

onMounted(() => {
    getDrivers()
    getFundingTypes()
    getPayers()
    verificationStore.getCancellationReasons()
})

watch(reloadStatisticRequired, (reloadValue) => {
    if (reloadStatisticRequired.value === true) {
        getStatistics();
        reloadStatisticRequired.value = false;
    }
});

watch(selectedRegion, (newRegion) => {
    getStatistics();
})

</script>

<style scoped>

</style>
