<template>
    <div class="d-flex align-items-center">
        <template v-if="showDeleteConfirmation">
            <span class="me-2">Are you sure? </span>
            <Button color="danger" size="small" @click="deleteReason">Yes</Button>
        </template>
        <template v-else>
            <Button color="outline-danger" @click="showConfirmation">Delete</Button>
        </template>
    </div>
</template>

<script>
export default {
    name: "DeleteFundingType",
}
</script>
<script setup>
import Button from "../Button.vue";
import {ref, inject} from "vue";
import Spinner from "../Spinner.vue";

const toast = inject('toast')
const axios = inject('axios')

const props = defineProps({
    fundingType: {
        required: true,
        type: Object,
    },
});

const emit = defineEmits(['deleted']);

const showDeleteConfirmation = ref(false);
const showConfirmation = function () {
    showDeleteConfirmation.value = true;
}

const deleting = ref(false);
const deleteReason = function () {
    deleting.value = true;
    axios
        .delete(route('api.funding-types.destroy', {funding_type: props.fundingType.uuid}))
        .then(response => {
            toast.success('Funding type deleted');
            emit('deleted', props.fundingType);
        })
        .catch(error => {
            toast.error('Error deleting funding type');
        })
        .finally(() => {
            deleting.value = false;
        })
}

</script>
