<template>
    <div>
        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <VueMultiselect
            v-if="vehicles.length > 0 && !loading"
            :id="id"
            v-model='selectedVehicle'
            :custom-label="vehicleLabel"
            :disabled="disabled"
            :options="vehicles"
            :placeholder="placeholder"
            :select-label="''"
            label="description"
            track-by="uuid"
        >
            <template v-slot:singleLabel="props">
                <div class="option__desc">
                    <div class="d-flex align-items-center">
                        <VehicleImage :vehicle="props.option"/>
                        <div class="ms-1">
                            <div class="fs-5">{{ props.option.description }}</div>
                            <div class="mt-1 small">{{ props.option.registration_code }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:option="props">
                <div class="option__desc">
                    <div class="d-flex align-items-center">
                        <VehicleImage :vehicle="props.option"/>
                        <div class="ms-1">
                            <div class="fs-5">{{ props.option.description }}</div>
                            <div class="mt-1 small">{{ props.option.registration_code }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </VueMultiselect>
        <div v-if="error" :class="'text-danger form-text'">{{ error }}</div>
    </div>
</template>

<script>
export default {
    name: "VehicleSearchInput"
}
</script>
<script setup>
import VueMultiselect from 'vue-multiselect'
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import axios from "axios";
import {uuid} from 'vue-uuid';
import VehicleImage from "../parts/VehicleImage.vue";

const componentId = ref(uuid.v4());

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: Object,
    id: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Select vehicle...',
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    error: {
        type: String,
        required: false
    }
})

const selectedVehicle = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value);
    }
})

const vehicles = ref([])
const loading = ref(true)
const getVehicles = function (uri = 'api.vehicles.index') {
    loading.value = true
    axios
        .get(route('api.vehicles.index', {paginate: false}))
        .then(resp => {
            vehicles.value = resp.data.data
        })
        .finally(() => {
            loading.value = false
        })
}

const vehicleLabel = function (vehicle) {
    return `${vehicle.description} (${vehicle.registration_code})`
}

onBeforeMount(() => {
    getVehicles()
})

</script>

<style scoped>

</style>
