<template>
    <div>
        <div class="card bg-light shadow-none mb-3">
            <div class="card-body p-3">
                <div class="d-flex justify-content-end">
                    <a @click.prevent="emitDelete" href="javascript: void(0)">
                        <i class="mdi mdi-close"></i>
                    </a>
                </div>
                <FormRow>
                    <FormCol md="4">
                        <FormTextInput
                            v-model:input="form.description"
                            label="Description"
                            placeholder="Equipment description"
                            :validation="yup.string().max(255).required()"
                        />
                    </FormCol>
                    <FormCol md="4">
                        <FormTextInput
                            v-model:input="form.identifier"
                            label="Identifier"
                            placeholder="WC12345"
                            :validation="yup.string().max(255)"
                            optional
                        />
                    </FormCol>
                    <FormCol md="4">
                        <FormDateInput
                            v-model:input="form.issued_date"
                            label="Issued date"
                            :validation="yup.date().required()"
                        />
                    </FormCol>
                </FormRow>
            </div>
        </div>
    </div>
</template>

<script setup>
import { FormTextInput, FormDateInput } from '../Forms'
import { FormRow, FormCol } from '../Forms/Layouts'
import FormInputLabel from '../Forms/parts/FormInputLabel.vue'
import * as yup from 'yup'

const props = defineProps({
    index: {
        type: Number,
        required: true
    },
    form: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['delete'])

const emitDelete = () => {
    emit('delete', props.index)
}
</script>