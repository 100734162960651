import axios from "axios";

export default class VehicleConfigurationOption {
    static async getAll(): Promise<App.Models.VehicleConfigurationOption[]> {
        let result: App.Models.VehicleConfigurationOption[] = [];

        try {
            let response: any = await axios.get(
                route("api.vehicle-configuration-options.index")
            );

            if (response?.data?.some((i) => i?.uuid)) {
                result = response?.data;
            }
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }
}
