<template>
    <div class="mb-2">
        <CreateLocationType
            v-if="permissions.includes('create_location_type')"
            @created="addLocationType"
        />
    </div>
    <div class="card">
        <div class="card-body">
            <div v-if="loading" class="d-flex w-100 justify-content-center align-items-center ps-3">
                <div class="spinner-grow my-5 me-3" role="status"></div>
                <div class="fs-4">Loading Location Types</div>
            </div>
            <template v-if="!loading">
                <div v-if="locationTypes.length" class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label text-nowrap">
                            Display
                            <select v-model="perPage" @change="getLocationTypes" class="form-select form-select-sm d-inline mx-1">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option :value="paginationMeta.total">All</option>
                            </select>
                            location types
                        </label>
                    </div>
                </div>
                <p v-if="!locationTypes.length" class="text-center my-3">There are no Location Types currently created.</p>
                <table v-else class="table table-xs table-centered table-striped">
                    <thead>
                        <tr>
                            <th class="col-6">Description</th>
                            <th class="col-6">Locations Assigned</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="locationType in locationTypes">
                            <tr>
                                <td>{{ locationType.description }}</td>
                                <td>{{ locationType.locations_count }}</td>
                                <td class="text-end">
                                    <div class="dropdown">
                                        <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="mdi mdi-dots-vertical"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a
                                            v-if="permissions.includes('update_location_type')"
                                                @click.prevent="editLocationType(locationType)"
                                                class="dropdown-item"
                                                href="javascript:void(0);"
                                            >
                                                Edit location type
                                            </a>
                                            <a
                                                v-if="permissions.includes('delete_location_type') && locationType.can_delete"
                                                @click.prevent="showDeleteModal(locationType)"
                                                class="dropdown-item text-danger"
                                                href="javascript:void(0);"
                                            >
                                                Delete location type
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
            <Pagination
                v-if="!loading && locationTypes.length"
                @pageChanged="loadPage"
                :pagination="paginationMeta"
                record-name="funding types"
            ></Pagination>
        </div>

        <div id="editLocationTypeModal" class="modal fade" aria-hidden="true" aria-labelledby="editLocationTypeModalLabel"
            data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createLocationTypeModalLabel" class="modal-title">Edit Location Type</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <LocationTypeEditOrCreateForm v-model="locationTypeInEdit" />
                    </div>
                    <div class="modal-footer d-flex justify-content-end flex-nowrap">
                        <button class="btn btn-light me-1" data-bs-dismiss="modal" type="button">Close</button>
                        <Button :loading="updating" @click="updateLocationType">Update Location Type</Button>
                    </div>
                </div>
            </div>
        </div>

        <DeleteLocationType
            :deleting-location-type="deleteLocationType"
            :location-types="locationTypes"
            @deleted="removeLocationType"
        />
    </div>
</template>

<script>
export default {
    name: "ListLocationTypes"
}
</script>
<script setup>
import {onMounted, inject, ref} from "vue";
import Button from "../Button.vue";
import CreateLocationType from "./CreateLocationType.vue";
import DeleteLocationType from "./DeleteLocationType.vue";
import LocationTypeEditOrCreateForm from "./LocationTypeEditOrCreateForm.vue";
import {uuid} from "vue-uuid";
import Pagination from "../Pagination.vue"

const toast = inject('toast')
const axios = inject('axios')

const permissions = window.permissions

const locationTypes = ref([])
const loading = ref(true)
const page = ref(1)
const perPage = ref(25)
const paginationMeta = ref({})

const loadPage = (link) => {
    page.value = link.page
    getLocationTypes()
}

const getLocationTypes = function () {
    loading.value = true

    axios
        .get(route('api.location-types.index'), { params: {
            pagination: true,
            page: page.value,
            per_page: perPage.value
        }})
        .then(response => {
            locationTypes.value = response.data.data
            paginationMeta.value = response.data.meta
        })
        .catch(error => {
            toast.error('Error fetching location types')
        })
        .finally(() => {
            loading.value = false
        })
}

const addLocationType = function (locationType) {
    locationTypes.value.push(locationType)
    locationTypes.value.sort((a, b) => a.description.localeCompare(b.description))
}

const locationTypeEditModal = ref(null)
const locationTypeInEdit = ref({})
const editLocationType = function (locationType) {
    locationTypeInEdit.value = {
        uuid: locationType.uuid,
        description: {
            uuid: uuid.v4(),
            value: locationType.description,
            errors: [],
            required: true,
        },
    }
    locationTypeEditModal.value.show()
}

const locationTypeDeleteModal = ref(null)
const deleteLocationType = ref({})
const locationsToReassign = ref([])
const loadingLocations = ref(false)
const showDeleteModal = function (locationType) {
    deleteLocationType.value = locationType

    locationTypeDeleteModal.value.show()
}

const updating = ref(false)
const updateLocationType = function () {
    updating.value = true
    axios
        .put(route('api.location-types.update', { location_type: locationTypeInEdit.value.uuid }), {
            description: locationTypeInEdit.value.description.value ?? '',
        })
        .then(response => {
            const index = locationTypes.value.findIndex(locationType => locationType.uuid === response.data.uuid)
            locationTypes.value.splice(index, 1, response.data)
            toast.success('Location type updated')
            locationTypeEditModal.value.hide()
        })
        .catch(error => {
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                locationTypeInEdit.value[field].errors = errors
            }
        })
        .finally(() => {
            updating.value = false
        })
}

const removeLocationType = function (locationType) {
    locationTypeDeleteModal.value.hide()
    getLocationTypes()
}

onMounted(() => {
    getLocationTypes()
    locationTypeEditModal.value = new bootstrap.Modal(document.getElementById('editLocationTypeModal'))
    locationTypeDeleteModal.value = new bootstrap.Modal(document.getElementById('locationTypeDeleteModal'))
})

</script>
