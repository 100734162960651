<template>
    <div id="notificationSettingsModal" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="notificationSettingsModalLabel">Update reminder settings</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <FormSelect
                            :key="form.schedule.uuid"
                            :options="schedules"
                            v-model:selection="form.schedule"
                            label="Send on"
                        />
                      </div>
                      <div class="col-md-6">
                        <FormTimeInput
                                :key="form.send_at.uuid"
                                v-model:input="form.send_at"
                                label="Send at"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-between align-items-end mb-1">
                            <FormInputLabel input-name="reminderText" label="Reminder text" required class="mb-0" />
                            <div class="dropdown">
                                <button
                                    id="variableDropdown"
                                    class="btn btn-light dropdown-toggle btn-sm"
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                    type="button"
                                >
                                    Insert placeholder
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="variableDropdown">
                                    <li v-for="(variable, index) in variables" :key="index">
                                        <a
                                            @click.prevent="insertVariable(variable)"
                                            class="dropdown-item"
                                            href="javascript: void(0)"
                                        >
                                        {{ variable.label }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <FormTextarea
                            :key="form.template.uuid"
                            v-model:input="form.template"
                            maxlength="500"
                            rows="5"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <FormSwitch
                        class="align-self-start"
                        v-model="form.aggregate_bookings.value"
                        label="Aggregate bookings"
                    />
                    <button class="btn btn-light" data-bs-dismiss="modal" type="button">Close</button>
                    <Button :loading="savingSettings" color="primary" @click="saveSettings">Save Settings</Button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <table :key="tableRefreshKey" class="table table-xs table-centered table-striped">
                <thead>
                    <tr>
                        <th>Notification Type</th>
                        <th>Schedule</th>
                        <th>Aggregate bookings</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(setting, index) in settings">
                        <td>{{ setting.notification_type.label }}</td>
                        <td>{{ getLabelForSchedule(setting.schedule) }} @ {{ setting.send_at }} ({{ timezone }})</td>
                        <td>{{ setting.aggregate_bookings ? 'Yes' : 'No' }}</td>
                        <td class="text-end">
                            <a @click.prevent="showModal(setting)" href="javascript: void(0)">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { FormTextarea, FormTimeInput } from "../Forms"
import FormInputLabel from '../Forms/parts/FormInputLabel.vue'
import { transformInput, extractInputValues } from "../Utils"
import Button from "../Button.vue"
import FormSwitch from "@components/Forms/FormSwitch.vue";
import FormSelect from "@components/Forms/FormSelect.vue";
import {find} from "lodash";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    settings: {
        type: Array,
        required: true
    }
})

const tableRefreshKey = ref(Date.now())
const timezone = window.timezone

const showModal = (setting) => {
  form.value.settingUuid = setting.uuid
  form.value.aggregate_bookings = transformInput({
    value: setting.aggregate_bookings,
    required: true
  })
  form.value.schedule = transformInput({
    value: setting.schedule,
    required: true
  })
  form.value.send_at = transformInput({
        value: setting.send_at_value,
        required: true
    })
    form.value.template = transformInput({
        value: setting.template,
        required: true
    })
    notificationSettingsModal.value.show()
}

const form = ref({
    settingUuid: null,
    send_at: transformInput({
        value: '',
        required: true
    }),
    template: transformInput({
        value: '',
        required: true
    }),
  aggregate_bookings: transformInput({
    value: false,
    required: true
  }),
  schedule: transformInput({
    value: '',
    required: true
  }),
})

const variables = ref([
    {
        label: 'Pickup Location',
        value: '{@PickupLocation}'
    },
    {
        label: 'Destination Location',
        value: '{@DestinationLocation}'
    },
    {
        label: 'Start Time',
        value: '{@StartTime}'
    },
    {
        label: 'Drop Off Time',
        value: '{@DropOffTime}'
    },
    {
        label: 'Appointment Time',
        value: '{@AppointmentTime}'
    },
    {
        label: 'Allocated Vehicle',
        value: '{@AllocatedVehicle}'
    },
])

const schedules = [
  { value: 'day_before', label: "The day before" },
  { value: 'business_day_before', label: "The business day before" },
];

const insertVariable = (variable) => {
    form.value.template.value += variable.value
    form.value.template.uuid = Date.now()
}

const getLabelForSchedule = (schedule) => {
  return find(schedules, {'value':schedule})?.label
}

const savingSettings = ref(false)
const saveSettings = () => {
    savingSettings.value = true
    axios
        .put(route('api.notification-settings.update', {
            setting: form.value.settingUuid
        }), extractInputValues(form.value))
        .then(response => {
            notificationSettingsModal.value.hide()
            const index = props.settings.findIndex(setting => setting.uuid === response.data.uuid)
            props.settings.splice(index, 1, response.data)
            tableRefreshKey.value = Date.now()
        }).catch(error => {
            if (error.response.status && error.response.status === 422) {
                toast.error(error.response.data.message)
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    form.value[key].errors = value
                }
            } else {
                toast.error('An unknown error occurred while saving the settings.')
            }
        }).finally(() => {
            savingSettings.value = false
        })
}

const notificationSettingsModal = ref(null)
onMounted(() => {
    notificationSettingsModal.value = new bootstrap.Modal(document.getElementById('notificationSettingsModal'))
})

</script>
