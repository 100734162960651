<template>
    <div class="chatbox-container">
        <div v-if="showChatBox" class="card chatbox shadow-lg">
            <div class="card-body">
                <div
                    v-if="unreadMessages.length > 0"
                    style="max-height: 300px; overflow-y: auto"
                >
                    <UnreadMessage
                        v-for="message in unreadMessages"
                        @click.native="startNewChat(message.driver)"
                        class="mb-2"
                        :driver="message.driver"
                        :sent-at="message.message.created_at"
                        :key="message.driver.uuid"
                    ></UnreadMessage>
                </div>
                <ul class="nav nav-tabs mb-1" v-if="chatIds.length > 0">
                    <li v-for="chatId in chatIds" :key="chatId">
                        <div
                            :class="{
                                'nav-link': true,
                                'd-flex': true,
                                'align-items-center': true,
                                active: chatId === activeChatId,
                            }"
                        >
                            <a
                                @click="changeChats(chatId)"
                                href="javascript: void(0)"
                                >{{ members[chatId].name }}</a
                            >
                            <a
                                @click="closeChat(chatId)"
                                href="javascript: void(0)"
                                class="ms-2 flex-shrink-1"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="closeIcon"
                                    class="h-4 w-4"
                                ></SvgIcon>
                            </a>
                        </div>
                    </li>
                    <li>
                        <a
                            @click="
                                showNewChatForm = true;
                                activeChatId = null;
                            "
                            class="nav-link"
                            href="javascript: void(0)"
                        >
                            <SvgIcon
                                type="mdi"
                                :path="plusIcon"
                                class="h-4 w-4 text-primary"
                            ></SvgIcon>
                        </a>
                    </li>
                </ul>
                <div
                    v-if="showNewChatForm || chatIds.length === 0"
                    class="text-center mt-4 mb-2"
                >
                    <SvgIcon
                        type="mdi"
                        :path="chatIcon"
                        class="w-10 h-10 text-primary"
                    ></SvgIcon>
                    <div class="fs-4 my-2">Start a new chat with a driver</div>
                    <SearchDriver
                        :placeholder="'Search driver name...'"
                        @memberSelected="startNewChat"
                        style="max-width: 450px"
                        class="mx-auto"
                    ></SearchDriver>
                </div>
                <div
                    v-if="
                        chatIds.length > 0 &&
                        !showNewChatForm &&
                        activeChat !== {}
                    "
                >
                    <div class="conversation-container">
                        <div
                            v-if="
                                !activeChat.messages ||
                                Object.keys(activeChat.messages).length === 0
                            "
                            class="text-center my-5"
                        >
                            <SvgIcon
                                type="mdi"
                                :path="chatIcon"
                                class="w-10 h-10 text-secondary"
                            ></SvgIcon>
                            <div class="fs-4 my-2">New conversation</div>
                            <p>Type the first message below</p>
                        </div>
                        <div
                            v-if="
                                activeChat.messages &&
                                Object.keys(activeChat.messages).length > 0
                            "
                            style="height: 300px; overflow-y: auto"
                            class="position-absolute bottom-0 d-flex flex-column-reverse pb-2 w-100"
                        >
                            <ChatMessage
                                v-for="messageId in Object.keys(
                                    chats[activeChatId].messages
                                ).reverse()"
                                :message="
                                    chats[activeChatId].messages[messageId]
                                "
                                :key="messageId"
                            ></ChatMessage>
                        </div>
                    </div>
                    <div class="d-flex mt-1">
                        <input
                            @keyup.enter="sendMessage"
                            v-model="newMessage"
                            type="text"
                            :id="uuid + '_messageInput'"
                            class="form-control"
                            :placeholder="
                                'Type a new message to ' +
                                members[activeChatId].name
                            "
                        />
                        <button
                            @click="sendMessage"
                            :disabled="newMessage.trim().length === 0"
                            class="btn btn-primary flex-shrink-1 ms-1"
                        >
                            <span class="visually-hidden">Send</span>
                            <SvgIcon
                                type="mdi"
                                :path="sendIcon"
                                class="h-4 w-4 text-center"
                            ></SvgIcon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-inline-block position-relative">
            <button
                @click="showChatBox = !showChatBox"
                :class="'btn ' + (showChatBox ? 'btn-light' : 'btn-primary')"
            >
                <span
                    v-if="this.unreadMessages.length > 0"
                    class="position-absolute start-0 top-0 translate-middle badge rounded-pill bg-danger"
                >
                    {{
                        this.unreadMessages.length < 99
                            ? this.unreadMessages.length
                            : "99+"
                    }}
                    <span class="visually-hidden">unread messages</span>
                </span>
                <SvgIcon
                    type="mdi"
                    :path="chatIcon"
                    class="me-2 h-5 w-5"
                ></SvgIcon>
                <template v-if="!showChatBox">Message A Driver</template>
                <template v-else>Close Chat Window</template>
            </button>
        </div>
    </div>
</template>

<script>
import ChatMessage from "./ChatMessage.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiForumOutline, mdiSend, mdiPlus, mdiClose } from "@mdi/js";
import {
    firebase,
    firebaseRTDB,
    ref,
    onValue,
    get,
    set,
    child,
    push,
    orderByKey,
    startAt,
    endAt,
    query,
    update,
    orderByChild,
    onChildAdded,
    onChildChanged,
    equalTo,
    startAfter,
    getMessaging,
} from "../../firebase";
import UnreadMessage from "./UnreadMessage.vue";
import SearchDriver from "../Drivers/SearchDriver.vue";
import Chat from "@classes/Chat";
import { inject } from "vue";

export default {
    name: "ChatBox",
    components: {
        SearchDriver,
        UnreadMessage,
        ChatMessage,
        SvgIcon,
    },
    data() {
        return {
            activeChatId: null,
            chatIcon: mdiForumOutline,
            chatIds: [],
            chats: {},
            chatSubscriptions: {},
            closeIcon: mdiClose,
            ignoreNewChats: true, // set this to true initially so that all the chat events dont flood the user on load
            newMessage: "",
            organisationBase: "organisation/" + window.tenant.access_token,
            plusIcon: mdiPlus,
            recompute: null,
            senderId: window.tenant.id + "_office_" + window.user.uuid,
            sendIcon: mdiSend,
            showChatBox: false,
            showNewChatForm: true,
            members: {},
            unreadMessages: [],
            uuid: this.$uuid.v1(),
            toast: inject("toast"),
        };
    },
    computed: {
        activeChat: function () {
            const recompute = this.recompute;
            if (
                this.chats === {} ||
                this.activeChatId === null ||
                !Object.keys(this.chats).includes(this.activeChatId)
            ) {
                return {};
            }

            return this.chats[this.activeChatId];
        },
        tabs: function () {},
    },
    watch: {
        activeChatId: function () {
            this.setAllMessagesToRead(this.activeChatId);
        },
    },
    methods: {
        async initialiseChatsFromCookie() {
            let cookies = document.cookie
                .split("; ")
                .find((row) => row.startsWith("chatId="));

            if (!cookies) {
                return;
            }

            JSON.parse(cookies.split("=")[1]).forEach(async (code) => {
                let driver = await Chat.getCachableDriver(code);
                this.startNewChat(driver);
            });
        },
        changeChats(chatId) {
            this.showNewChatForm = false;
            this.activeChatId = chatId;
            this.newMessage = "";
        },
        closeChat(chatId) {
            this.chatIds = this.chatIds.filter((chat) => chat !== chatId);
            this.setCookie();
            this.unsubscribe(this.chatSubscriptions[chatId]);
            delete this.chatSubscriptions[chatId];
        },
        async handleNewMessage(newMessageCrewKey, message) {
            if (
                this.unreadMessages.some(
                    (unreadMessage) =>
                        unreadMessage.driver.uuid === newMessageCrewKey
                )
            ) {
                return;
            }

            if (this.chatIds.includes(newMessageCrewKey)) {
                return;
            }

            let driver = await Chat.getCachableDriver(newMessageCrewKey);

            if (driver) {
                this.unreadMessages.push({
                    driver,
                    message: message,
                });
            }
        },
        listenForNewMessages() {
            onChildAdded(
                ref(firebaseRTDB, this.organisationBase + "/events/"),
                (snapshot) => {
                    if (snapshot.hasChild("read_at")) {
                        return;
                    }

                    this.handleNewMessage(snapshot.key, snapshot.val());
                }
            );

            onChildChanged(
                ref(firebaseRTDB, this.organisationBase + "/events/"),
                (snapshot) => {
                    const message = snapshot.val();
                    if (
                        snapshot.hasChild("read_at") ||
                        message.created_at - 1800000 <
                            message.last_message_created_at
                    ) {
                        return;
                    }

                    this.handleNewMessage(snapshot.key, snapshot.val());
                }
            );
        },
        sendMessage() {
            if (this.newMessage.trim().length === 0) {
                return;
            }

            this.pushMessage(this.newMessage);
            this.sendPushNotification(this.newMessage);

            this.newMessage = "";
        },
        setAllMessagesToRead(chatId) {
            this.unreadMessages = this.unreadMessages.filter(
                (message) => message.driver.uuid !== chatId
            );
            set(
                ref(firebaseRTDB, this.organisationBase + "/events/" + chatId),
                {
                    read_at: new Date().getTime(),
                }
            );
        },
        pushMessage(message) {
            const timeNow = new Date().getTime();
            const newMessageKey = push(
                child(
                    ref(firebaseRTDB),
                    this.organisationBase +
                        "/chats/" +
                        this.activeChatId +
                        "/messages"
                )
            ).key;
            const updates = {};
            updates[
                this.organisationBase +
                    "/chats/" +
                    this.activeChatId +
                    "/messages/" +
                    newMessageKey
            ] = {
                content: message,
                created_at: timeNow,
                created_by_id: "office_" + window.user.uuid,
                type: "message",
                updated_at: timeNow,
            };

            update(ref(firebaseRTDB), updates);
            this.recompute = Math.random();
        },
        sendPushNotification(body) {
            const driver = this.members[this.activeChatId];

            this.$http
                .post(
                    this.route("api.drivers.message.send", {
                        driver: driver.uuid,
                    }),
                    {
                        message: body,
                    }
                )
                .then(() => this.messageSent())
                .catch((error) => {
                    this.toast.error(error.response.data);
                });
        },
        showBrowserIndicator() {
            const originalTitle = document.title;
            let index = 1;
            setInterval(() => {
                if (this.unreadMessages.length > 0 && index % 2) {
                    document.title = "New Message - " + originalTitle;
                    document.querySelector(
                        "link[rel~='icon'][sizes~='32x32']"
                    ).href =
                        window._globalAsset +
                        "images/favicon-32x32-notification.png";
                    document.querySelector(
                        "link[rel~='icon'][sizes~='16x16']"
                    ).href =
                        window._globalAsset +
                        "images/favicon-16x16-notification.png";
                } else {
                    document.title = originalTitle;
                    document.querySelector(
                        "link[rel~='icon'][sizes~='32x32']"
                    ).href = window._globalAsset + "images/favicon-32x32.png";
                    document.querySelector(
                        "link[rel~='icon'][sizes~='16x16']"
                    ).href = window._globalAsset + "images/favicon-16x16.png";
                }

                index++;
            }, 1000);
        },
        startNewChat(member) {
            get(
                child(
                    ref(firebaseRTDB),
                    this.organisationBase + "/chats/" + member.uuid
                )
            ).then((snapshot) => {
                if (!snapshot.exists()) {
                    const updates = {};
                    updates[this.organisationBase + "/chats/" + member.uuid] = {
                        crew_id: member.uuid,
                    };
                    update(ref(firebaseRTDB), updates);
                }

                if (!this.chatIds.includes(member.uuid)) {
                    this.members[member.uuid] = member;
                    this.chatIds.push(member.uuid);
                    this.chatSubscriptions[member.uuid] = onValue(
                        query(
                            ref(
                                firebaseRTDB,
                                this.organisationBase + "/chats/" + member.uuid
                            ),
                            orderByChild("created_at")
                        ),
                        (chatDetails) => {
                            if (chatDetails.exists()) {
                                this.chats[member.uuid] = chatDetails.val();
                                this.recompute = Math.random();
                            }
                        }
                    );
                }

                this.activeChatId = member.uuid;
                this.showNewChatForm = false;
                this.showChatBox = true;
                this.setCookie();
            });
        },
        unsubscribe(callback) {
            callback();
        },
        setCookie() {
            document.cookie = "chatId = " + JSON.stringify(this.chatIds);
        },
    },
    mounted() {
        this.listenForNewMessages();
        this.showBrowserIndicator();
        this.initialiseChatsFromCookie();
    },
};
</script>

<style scoped></style>
