<template>
    <div>
        <h4 class="mt-0">Sign In</h4>

        <form :action="this.route('auth.login.attempt')" @submit="submitForm" method="post">
            <input type="hidden" name="pin" v-model="pin.value">

            <div v-if="!showLoginForm">

                <p class="text-muted mb-4">
                    Enter the pin for your organisation.
                </p>

                <div class="mb-3">
                    <FormNumberInput v-model:input="pin" :mask="mask" label="Organisation Pin" type="text"
                        placeholder="Enter pin" />
                </div>
                <div class="d-grid mb-0 text-center">
                    <button class="btn btn-primary" :disabled="continueButtonDisabled" type="button"
                        @click="showLogin"><i class="mdi mdi-login"></i> Continue
                    </button>
                </div>
            </div>

            <transition name="basic">
                <div v-if="showLoginForm" class="pt-3">

                    <div class="mb-2">
                        <GoogleLogin :client-id="googleClientId" :csrf="csrf"></GoogleLogin>
                    </div>

                    <div class="mb-2">
                        <MicrosoftLogin></MicrosoftLogin>
                    </div>

                    <AzureLogin :pin="pin.value"></AzureLogin>

                    <div class="separator">OR</div>

                    <p class="text-muted mb-4">
                        Enter your email address and password to access your account.
                    </p>

                    <div class="mb-3">
                        <label for="email" class="form-label">Username / Email</label>
                        <input v-model="email" type="text" required class="form-control" id="email" name="email"
                            placeholder="Enter your email or username">
                    </div>
                    <div class="mb-3">
                        <a :href="this.route('auth.forgot.index')" class="text-muted float-end"><small>Forgot your
                                password?</small></a>
                        <label for="password" class="form-label">Password</label>
                        <input v-model="password" type="password" required class="form-control" id="password"
                            name="password" placeholder="Password">
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input type="checkbox" name="remember" value="1" class="form-check-input"
                                id="checkbox-signin">
                            <label class="form-check-label" for="checkbox-signin">Remember me</label>
                        </div>
                    </div>
                    <div class="d-grid mb-0 text-center">
                        <button class="btn btn-primary" type="submit"><i class="mdi mdi-login"></i> Log In</button>
                    </div>
                </div>
            </transition>

            <input type="hidden" name="_token" :value="csrf">
        </form>
    </div>
</template>

<script>
import GoogleLogin from "./GoogleLogin.vue";
import MicrosoftLogin from "./MicrosoftLogin.vue";
import AzureLogin from "./AzureLogin.vue";
import FormNumberInput from '../Forms/FormNumberInput.vue'
import { transformInput } from "../Utils"
export default {
    name: "LoginForm",
    components: {
        GoogleLogin,
        MicrosoftLogin,
        AzureLogin,
        FormNumberInput,
    },
    data() {
        return {
            pin: transformInput({ value: '' }),
            mask: {
                mask: '0000'
            },
            email: '',
            password: '',
            showLoginForm: false,
        }
    },
    props: {
        // csrf: String,
        pinValue: {
            required: false,
            type: String,
            default: ''
        },
        emailValue: {
            required: false,
            type: String,
            default: ''
        },
        googleClientId: {
            required: true,
            type: String,
        }
    },
    methods: {
        showLogin() {
            this.$http.post(this.route('auth.login.set-pin'), {
                '_token': this.csrf,
                'pin': this.pin.value,
            }).then((response) => {
                this.showLoginForm = true
            }).catch((error) => {
                if (error.response.status == 422 && error.response.data.errors) {
                    this.pin = transformInput({
                        value: this.pin.value,
                        required: true,
                        valid: false,
                        errors: error.response.data.errors.pin
                    });
                }
            })
        },
        submitForm(event) {
            if (!this.showLoginForm) {
                event.preventDefault()
                this.showLogin()
                return false;
            }
        },
    },
    computed: {
        csrf() {
            return document.querySelector('head meta[name="csrf-token"').getAttribute('content')
        },
        continueButtonDisabled() {
            return this.pin.value && this.pin.valid && this.pin.value.trim().length === 0
        }
    },
    beforeMount() {
        this.pin = transformInput({ value: this.pinValue })
        this.email = this.emailValue
    }
}
</script>

<style scoped>
.basic-enter-active,
.basic-leave-active {
    transition: opacity .25s ease-in;
    transform: scale(1, 1);
}

.basic-enter, .basic-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
