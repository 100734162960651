<template>
    <div>
        <FormTextInput
            v-model:input="providerId"
            @update:input="updateProviderId"
            :label="selected.title"
            placeholder="e.g. 123456789"
        />
        <div v-if="saved" class="mt-2 d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="mdiCheckCircle"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import {FormTextInput} from '../Forms'
import { transformInput } from '../Utils'
import * as yup from 'yup'
import { debounce } from 'lodash'
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle } from "@mdi/js";

const axios = inject('axios')

const props = defineProps({
    selected: {
        type: Object,
        required: true,
    },
});

const providerId = ref(transformInput({
    value: props.selected.value,
    required: false,
}))

const saved = ref(false)
const updateProviderId = debounce(function (providerId) {
    providerId.errors = []
    axios
        .post(route('api.set-provider-id'), {
            provider_id: providerId.value,
        }).then(() => {
            saved.value = true
            setTimeout(() => { saved.value = false }, 2000)
        }).catch(error => {
            if (error.response.status === 422) {
                providerId.errors = error.response.data.errors.provider_id
            }
        })
}, 500)

</script>
