<template>
    <div v-for="(notification, key) in notifications" class="row mb-1">
        <div class="col-12">
            <FormSelect
                v-model:selection="detailsValue[notification.name]"
                :options="notification.method_options"
                :label="notification.label"
                :placeholder-option="false"
            />
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-primary" :disabled="loading" @click="update">
                <span v-if="loading" class="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>Update Notifications
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onBeforeMount, inject, watchEffect } from 'vue'
    import { FormSelect } from '../Forms'
    import { transformInput, copyValues, getSelectValues } from '../Utils'
    import { map } from 'lodash'

    const axios = inject('axios')
    const toast = inject('toast')

    const { user, notifications } = defineProps({
        user: {
            required: true,
            type: Object,
        },
        notifications: {
            required: true,
            type: Array,
        },
    })

    const detailsValue = reactive({})

    const loading = ref(false)

    onBeforeMount(() => {
        watchEffect(() => {
            notifications.forEach((notification) => {
                detailsValue[notification.name] = transformInput({
                    value: user.notification_preferences[notification.name] ?? notification.default_method,
                    required: false,
                    valid: true,
                })
            })
        })
    })

    const emits = defineEmits(['updated'])

    const update = () => {
        loading.value = true

        let data = map(detailsValue,  (detail, key) => {
            return {
                notification: key,
                value: detail.value
            }
        })

        axios.put(route('api.users.update', { user: user.uuid }), {notifications: data})
            .then((resp) => {
                emits('updated', resp.data)
                toast.success('Your Notification Settings have been updated', { title: 'Update Successfull' })
            }).catch((err) => {
                console.error('something went wrong', err)
                toast.error('Update of the Notification Settings failed', { title: 'Ooops Something went wrong' })
            }).finally(() => {
                loading.value = false
            })
    }

</script>
