<template>
    <div @mouseover="shopPopUp" @mouseleave="hidePopUp">
        <slot style="cursor: pointer" name="element"></slot>
        <div
            v-if="popUpShow"
            class="card hover-companion__hover-box shadow-md"
            id="hover-companion__hover-box"
            :style="{
                top: screenY + 'px',
                left: screenX + 'px',
            }"
        >
            <slot name="popup-content">
            </slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted, toRefs } from "vue";

const props = withDefaults(
    defineProps<{
        position?: "top" | "bottom" | "left" | "right" | "automatic";
    }>(),
    {
        position: "right",
    }
);

const { position } = toRefs(props);

const popUpShow = ref(false);

const screenY = ref(0);
const screenX = ref(0);
const margin = 5;

const shopPopUp = (e) => {
    calculatePopupBoxXY(e.x, e.y);
    popUpShow.value = true;
};

const getAutomaticXPosition = (mouseX: number) => {
    let { clientWidth = 0 } =
        document.getElementById("hover-companion__hover-box") ?? {};

    if (mouseX + clientWidth > window.innerWidth) {
        return mouseX - clientWidth - margin;
    }

    return mouseX + margin;
};

const getAutomaticYPosition = (mouseY: number) => {
    let { clientHeight = 0 } =
        document.getElementById("hover-companion__hover-box") ?? {};

    if (mouseY + clientHeight > window.innerHeight) {
        return mouseY - clientHeight - margin;
    }

    return mouseY + margin;
};

const calculatePopupBoxXY = (mouseX: number, mouseY: number) => {
    screenY.value = getAutomaticYPosition(mouseY);
    screenX.value = getAutomaticXPosition(mouseX);
};
const hidePopUp = () => {
    popUpShow.value = false;
};
</script>
<style scoped>
.hover-companion__hover-box {
    position: fixed;
    background: white;
    padding: 20px;
    z-index: 999;
}
.hover-companion__relation {
    font-weight: bold;
}
</style>
