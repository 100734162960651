<template>
    <div>
        <div v-if="stopIndex > 1" class="my-3">
            <AddressDbSearch
                v-if="!stop.usePreviousStopLocationAsOrigin"
                class="flex-grow-1"
                v-model="stop.originLocation"
                label="Origin address"
                :placeholder="'Search origin address'"
                size="large"
                :locationBias="bookingStore.draft.clients[0].address"
                @locationSelected="
                    (location) => {
                        stop.originLocation = location;
                    }
                "
            ></AddressDbSearch>
            <FormSwitch
                class="mt-2"
                label="Use previous stop as origin address"
                v-model="stop.usePreviousStopLocationAsOrigin"
            />
        </div>

        <div class="my-3">
            <AddressDbSearch
                focus
                class="flex-grow-1"
                v-model="destinationLocations"
                :label="props.label"
                :placeholder="props.placeholder"
                size="large"
                :isDisabled="stop.useClientHomeAsLocation"
                :locationBias="bookingStore.draft.clients[0].address"
                @locationSelected="
                    (location) => {
                        stop.location = location;
                    }
                "
            ></AddressDbSearch>

            <div
                :class="[!canUseClientHomeAsBookingAddress ? 'pe-none' : '']"
                :style="[
                    !canUseClientHomeAsBookingAddress ? 'opacity: 0.5' : '',
                ]"
            >
                <FormSwitch
                    class="mt-2"
                    label="Use home address"
                    v-model="stop.useClientHomeAsLocation"
                />
            </div>

            <div v-if="bookingFormErrors.stops" class="alert alert-danger mt-3">
                <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
                Please ensure all stops have pick up and drop off addresses
                selected
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import { computed, inject, onMounted, ref, watch } from "vue";

import AddressDbSearch from "../../AddressDbSearch.vue";
import FormSwitch from "../../Forms/FormSwitch.vue";
import { storeToRefs } from "pinia";
import CreateLocation from "@components/Locations/CreateLocation.vue";

const props = withDefaults(
    defineProps<{
        stopIndex: number;
        placeholder?: string;
        label?: string;
    }>(),
    {
        placeholder: "Search address",
        label: "Address",
    }
);

const bookingStore = useBookingStore();

const { bookingFormErrors, selectedClient } = storeToRefs(bookingStore);

const previousStop = computed(() => {
    return props?.stopIndex > 0
        ? bookingStore.draft.stops?.[props?.stopIndex - 1]
        : undefined;
});

const stop = computed(() => {
    return bookingStore.draft.stops[props?.stopIndex];
});

const visibleLocationAddress = computed({
    get() {
        return bookingStore.draft.stops[props?.stopIndex]
            .useClientHomeAsLocation
            ? selectedClient.value?.address
            : bookingStore.draft.stops[props?.stopIndex].location ?? undefined;
    },
    set(value) {
        useClientHomeAsLocation.value = false;
        bookingStore.draft.stops[props?.stopIndex].location = value ?? null;
    },
});

const destinationLocations = computed({
    get() {
        return stop.value.useClientHomeAsLocation
            ? selectedClient.value?.address
            : stop.value.location;
    },
    set(newValue) {
        stop.value.location = newValue ?? null;
    },
});

const canUseClientHomeAsBookingAddress = computed(() => {
    return previousStop.value
        ? !previousStop.value?.useClientHomeAsLocation
        : true;
});

const useClientHomeAsLocation = computed({
    get() {
        return canUseClientHomeAsBookingAddress.value
            ? bookingStore.draft.stops[props?.stopIndex].useClientHomeAsLocation
            : false;
    },
    set(value) {
        bookingStore.draft.stops[props?.stopIndex].useClientHomeAsLocation =
            canUseClientHomeAsBookingAddress ? value : false;
    },
});
</script>
