<template>
    <div>
        <label class="form-label" :class="{ 'required-label': props.required }">{{ props.label }}</label>
        <div v-if="loadingVehicleOptions" class="d-flex align-items-center">
            <div class="spinner-grow spinner-grow-sm me-2" role="status"></div>Loading Vehicles
        </div>
        <template v-else>
            <template v-if="vehicleOptions?.length > 0">
                <Transition name="fade">
                    <div v-show="selectable" class="row">
                        <div class="col">
                            <label for="searchVehicles" class="visually-hidden">Search Vehicles</label>
                            <input
                                type="search"
                                id="searchVehicles"
                                class="form-control form-control-sm mb-2"
                                placeholder="Search Rego"
                                v-model="filterQuery"
                            >
                        </div>
                    </div>
                </Transition>
                <Transition name="fade">
                    <div v-if="selectable" class="pb-1" style="overflow-y: hidden; overflow-x: auto; white-space: nowrap">
                        <button
                            v-for="(vehicle, key) in vehicles"
                            @click="selectVehicle(vehicle)"
                            :key="key"
                            class="btn border border-2 py-3 px-3 me-1"
                            :class="{
                                'border-info': selectedVehicle?.value === vehicle.value,
                                'border-light': selectedVehicle?.value !== vehicle.value,
                            }"
                            style="width: 200px;"
                        >
                            <img
                                :src="vehicle.image"
                                class="img-fluid mb-3 mx-auto d-block"
                                style="max-height: 50px;"
                                alt="Vehicle Icon"
                            >
                            <div>{{ vehicle.label }}</div>
                        </button>
                    </div>
                    <div v-else class="d-flex justify-content-center">
                        <div style="width: 200px;" class="border border-2 border-info">
                            <button v-if="!props.disabled" @click="cancelSelectVehicle" class="btn-close d-block float-end mb-1" type="button"></button>
                            <div class="py-3 px-3">
                                <img
                                    :src="selectedVehicle.image"
                                    class="img-fluid mb-3 mx-auto d-block"
                                    style="max-height: 50px;"
                                    alt="Vehicle Icon"
                                >
                                <div>{{ selectedVehicle.label }}</div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </template>
            <template v-else>
                <p>No Vehicles could be found</p>
            </template>
        </template>
        <div v-if="errorMessage?.length > 0" class="text-danger">{{ errorMessage }}</div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref, watch } from "vue";
    import { useVehicleStore } from "@stores/VehicleStore";
    import { storeToRefs } from "pinia";
    import { useField } from 'vee-validate'


    const vehicleStore = useVehicleStore();
    const { vehicleOptions, loadingVehicleOptions } = storeToRefs(vehicleStore);
    const { getVehicleOptions } = vehicleStore;

    const props = withDefaults(
        defineProps<{
            vehicle?: object,
            required: boolean,
            label: string,
            disabled: boolean,
        }>(),
        {
            required: false,
            label: "Select Vehicle",
            boolean: false,
        }
    )

    const { errorMessage, validate, setValue } = useField<object>('location', (value) => {       
        if (props.required && value?.value == '') {
            return "Vehicle is required";
        }

        return true;
    });

    const emit = defineEmits<{
        (e: "update:vehicle", value: Object): void,
        (e: "isValid", value: boolean): void,
    }>();

    const filterQuery = ref<string>('');
    const selectedVehicle = ref<object>(props.vehicle ?? cancelSelectVehicle());

    const vehicles = computed<App.Resource.VehicleOptionsResource[]>(() => {
        if (filterQuery.value.trim().length < 1) {
            return vehicleOptions.value;
        }

        if (vehicleOptions.value === undefined) {
            return [];
        }

        return vehicleOptions.value.filter((option) => {
            return option.label.includes(filterQuery.value);
        });
    });

    const selectVehicle = (vehicle): void => {
        selectedVehicle.value = vehicle;

        setValue(selectedVehicle.value);

        emit('update:vehicle', vehicle);
    }

    const cancelSelectVehicle = (): void => {
        selectedVehicle.value = {
            label: '',
            value: '',
            image: '',
        };

        setValue(selectedVehicle.value);
    }

    const selectable = computed(() => {
        return selectedVehicle.value?.value.length < 1;
    })

    watch(
            selectedVehicle,
            () => {
                setValue(selectedVehicle.value)
                validate().then((results) => {
                    emit("isValid", results.valid);
                });
            }
        )

    onMounted(() => {
        getVehicleOptions();
    });
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        border: none;
        height: 0;
    }

    .fade-leave-from {
        overflow: hidden;
    }

</style>