<template>
    <div class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="options"
            :value="booking.funding_type.uuid"
            @updated="update"
        >
            {{ booking.funding_type.code }}
        </EditableSelect>
    </div>
</template>

<script>
export default {
    name: "EditBookingPurpose"
}
</script>
<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {ref, inject, computed} from "vue";
import axios from "axios";
import Booking from "@classes/Booking";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
})

const options = computed(() => {
    let optionsObject = {}

    props.booking.client.approved_funding_types?.forEach(approvedFundingType => {
        const fundingTypeDetail = verificationStore.fundingTypes.find(ft => ft.uuid === approvedFundingType.uuid);
        if (fundingTypeDetail) {
            optionsObject[fundingTypeDetail.uuid] = fundingTypeDetail.code + ' – ' + fundingTypeDetail.name
        }
    });

    return optionsObject;
})

const updating = ref(false)
const update = function (newFundingType) {
    updating.value = true
    Booking.update(props.booking, {
        funding_type: newFundingType,
    })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            toast.success('Booking funding type updated.')
        })
        .catch((error) => {
            toast.error(error?.response?.data?.message ?? 'There was an error updating the booking funding type.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
