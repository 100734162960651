<template>
    <div class="d-flex">
        <a v-if="client.email" :href="'mailto:' + client.email" title="Send Email">
            <SvgIcon
                type="mdi"
                :path="mdiEmail"
                class="me-1 w-4 h-4 text-primary"
            ></SvgIcon>
        </a>
        <EditableInput
            @updated="storeChanges"
            :value="client.email"
            :loading="storing"
            :disabled="!editable"
            type="email"
        ></EditableInput>
    </div>
</template>

<script>
export default {
    name: "EditClientEmail"
}
</script>
<script setup>
import { mdiEmail } from '@mdi/js'
import SvgIcon from "@jamescoyle/vue-icon";
import axios from "axios";
import {computed, ref} from "vue";
import EditableInput from "../Forms/EditableFields/EditableInput.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const errors = ref({})
const storing = ref(false)
const storeChanges = function (email) {
    storing.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            email: email
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storing.value = false
        })
}
</script>

<style scoped>

</style>
