<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h4 class="header-title mt-0 mb-3">Create New Report</h4>
                <div>
                    <div class="d-flex align-items-center">
                        <label for="base_select_fields" class="me-2">Select:</label>
                        <VueMultiselect
                            v-model="selectedFields"
                            clea
                            :options="report.fieldsAvailable"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="true"
                            placeholder="all fields"
                            :disabled="updatingReport"
                        ></VueMultiselect>
                        <label for="base_select_table" class="mx-2">from</label>
                        <VueMultiselect
                            v-model="selectedModel"
                            :options="availableModels"
                            :multiple="false"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :allowEmpty="false"
                            placeholder="Choose a table"
                        ></VueMultiselect>
                        <label class="mx-2">where</label>
                        <select class="form-select" v-model="dateFilter.field">
                            <option value="" selected>Select a date field</option>
                            <option v-for="field in selectedModelDateFields" v-bind:value="field">
                                {{ selectedModelDateFieldName(field) }}
                            </option>
                        </select>
                        <label class="mx-2 text-nowrap">is between</label>
                        <input class="form-control" type="date" style="max-width: 11rem;" v-model="dateFilter.from"
                               v-bind:disabled="!dateFilter.field" v-bind:max="dateFilter.to"/>
                        <label class="mx-2">and</label>
                        <input class="form-control" type="date" style="max-width: 11rem;" v-model="dateFilter.to"
                               v-bind:disabled="!dateFilter.field" v-bind:min="dateFilter.from"/>
                        <div class="flex-grow-1 w-100"></div>
                    </div>
                </div>
                <div v-if="report.ruleSets.length > 0" class="mt-2">
                    <template v-for="(ruleSet, index) in report.ruleSets" :key="ruleSet.id">
                        <RuleSet
                            :id="ruleSet.id"
                            @remove="() => report.removeRuleSet(ruleSet.id)"
                        />
                        <div v-if="index < report.ruleSets.length-1" class="my-2 col-1">
                            <select class="form-select form-control-sm" v-model="ruleSetsOperator">
                                <option value="all" selected>AND</option>
                                <option value="any">OR</option>
                            </select>
                        </div>
                    </template>
                </div>
                <div class="mt-2">
                    <a href="javascript: void(0)" @click="report.addRuleSet">Add Rule Set</a>
                </div>
                <div class="mt-3">
                    <Button :loading="updatingReport" :icon="mdiRefresh" @click="updateReportData(false)" size="small">
                        Update Preview
                    </Button>
                    <Button :loading="updatingReport" :icon="mdiDownload" @click="toggleExportDialog" size="small"
                            class="ms-2" color="light">
                        Download .csv
                    </Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="header-title mt-0 mb-3">Data Preview</h4>
                <div class="table-responsive">
                    <table class="table table-xs table-striped table-bordered">
                        <thead>
                        <tr>
                            <template v-for="field in reportFields">
                                <th style="white-space: nowrap;">{{ field }}</th>
                            </template>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="record in reportData">
                            <tr>
                                <template v-for="(value, label) in record">
                                    <td style="white-space: nowrap;">{{ value }}</td>
                                </template>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <div class="mt-4">
                    <Pagination
                        @pageChanged="updatePage"
                        :pagination="paginationMeta"
                        record-name="records"
                    ></Pagination>
                </div>
            </div>
        </div>
        <DownloadReportModal
            :show="showExportDialog"
            :model="selectedModel"
            :fields="selectedFields"
            :rule_sets="sets"
            :rule_sets_operator="ruleSetsOperator"
            @update:show="toggleExportDialog"
        ></DownloadReportModal>
    </div>
</template>

<script>
export default {
    name: "QueryBuilder"
}
</script>
<script setup>
import RuleSet from "./RuleSet.vue";
import VueMultiselect from "vue-multiselect";
import {computed, inject, nextTick, onMounted, ref, watch} from "vue";
import Button from "../Button.vue";
import {customReportStore} from "@stores/CustomReportStore";
import Pagination from "@components/Pagination.vue";
import {mdiDownload, mdiRefresh} from "@mdi/js";
import get from "lodash.get";
import {lowerCase, upperFirst} from "lodash";
import {DateTime} from "luxon";
import DownloadReportModal from "@components/ReportQueryBuilder/DownloadReportModal.vue";
import {uuid} from "vue-uuid";

const report = customReportStore();

const axios = inject('axios')
const toast = inject('toast')

const availableModels = ref([
    'Bookings',
    'Clients',
    'Vehicle Runs',
    'Drivers',
    'Regions',
    'Locations',
    'Vehicles',
])

const modelDateFields = {
    'Bookings': [`appointment_time`, `date`, `arrive_destination_no_later_than`, `arrive_destination_not_before`, `cancelled_at`, `depart_origin_no_later_than`, `depart_origin_not_before`, `estimated_destination_time`, `estimated_origin_time`, `origin_timing_confirmation_sent_at`, `planned_destination_time`, `planned_origin_time`, `created_at`, `deleted_at`, `updated_at`],
    'Clients': [`date_of_birth`, `last_assessment_date`, `created_at`, `deleted_at`, `updated_at`],
    'Vehicle Runs': [`end_time`, `start_time`, `created_at`, `deleted_at`, `updated_at`],
    'Drivers': [`ndis_worker_expiry_date`, `date_of_birth`, `demerit_check_date`, `driver_authority_expiry_date`, `joined_date`, `last_active_at`, `licence_expiry_date`, `medical_expiry_date`, `police_check_expiry_date`, `created_at`, `deleted_at`, `updated_at`],
    'Regions': [`created_at`, `updated_at`],
    'Locations': [`created_at`, `updated_at`],
    'Vehicles': [`date_of_disposal`, `date_of_purchase`, `insurance_expiry`, `lease_expiry_date`, `modification_compliance_plate_date`, `registration_expiry`, `created_at`, `deleted_at`, `updated_at`],
}

const selectedModelDateFields = computed(() => {
    return selectedModel.value ? get(modelDateFields, selectedModel.value) : [];
});

const selectedModelDateFieldName = (field) => {
    return upperFirst(lowerCase(field));
}

const selectedModel = ref(availableModels.value[0])
const ruleSetsOperator = ref('all');

const page = ref(1)
const updatePage = (link) => {
    page.value = link.page
    updateReportData()
}

const reportData = ref([])
const paginationMeta = ref({})
const dateFilter = ref({
    from: null,
    to: null,
    field: '',
})
const reportFields = computed(() => {
    if (reportData.value.length === 0) {
        return []
    }

    return Object.keys(reportData.value[0])
})

const dateFilterRuleset = computed(() => {
    if (!dateFilter.value.field) {
        return []
    }

    let dateFilterRules = [];
    let from = DateTime.fromFormat(`${dateFilter.value.from} 00:00:00`, 'yyyy-MM-dd HH:mm:ss', {zone: window.timezone}).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    let to = DateTime.fromFormat(`${dateFilter.value.to} 23:59:59`, 'yyyy-MM-dd HH:mm:ss', {zone: window.timezone}).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
    if (dateFilter.value.from) {
        dateFilterRules.push({field: dateFilter.value.field, operator: 'greater_than_or_equal_to', value: `${from}`})
    }
    if (dateFilter.value.to) {
        dateFilterRules.push({field: dateFilter.value.field, operator: 'less_than_or_equal_to', value: `${to}`})
    }
    return dateFilterRules.length ? {
        type: 'all',
        rules: dateFilterRules
    } : null;
});

const selectedFields = ref([])
let initialised = false;

watch(selectedModel, () => {
    if (initialised) {
        report.ruleSets = [];
        selectedFields.value = []
        updateReportData(true)
    }
});

const sets = ref([]);
const updatingReport = ref(false)
const updateReportData = (updateAvailableFields = false, downloadAsCsv = false) => {
    updatingReport.value = true

    sets.value = report.ruleSets.map(ruleSet => {
        return {
            type: ruleSet.matchType,
            rules: ruleSet.rules.map(rule => {
                return {
                    field: rule.field,
                    operator: rule.operator,
                    value: rule.value
                }
            })
        }
    })

    sets.value.push(dateFilterRuleset.value);

    if (downloadAsCsv) {
        toggleExportDialog();
    }

    axios
        .get(
            route(
                'api.report-builder.query.preview',
                {
                    model: selectedModel.value,
                    fields: selectedFields.value,
                    rule_sets: sets.value,
                    rule_sets_operator: ruleSetsOperator.value,
                    page: page.value,
                }
            )
        )
        .then(response => {
            paginationMeta.value = response.data.meta
            reportData.value = Object.values(response.data.data)
            if (updateAvailableFields) {
                report.fieldsAvailable = Object.keys(response.data.data[0]);
                report.ruleFieldsAvailable = response.data.meta.rules;
            }
        })
        .catch(error => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            updatingReport.value = false
        })
}

const showExportDialog = ref(false);
const toggleExportDialog = () => {
    showExportDialog.value = !showExportDialog.value;
}

onMounted(() => {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("data")) {
        let data = JSON.parse(urlParams.get("data"));
        selectedModel.value = data.model;
        selectedFields.value = data.fields;
        ruleSetsOperator.value = data.rule_sets_operator; // === 'all' ? 'and' : 'or';

        report.ruleSets.push(
            ...data.rule_sets?.map(
                (set) => ({
                        ...{
                            id: uuid.v4(),
                            matchType: set.type,
                            rules: set.rules.map((rule) => ({...rule, ...{id: uuid.v4()}}))
                        }
                    }
                )
            )
        );
        page.value = data.page;
    }
    updateReportData(true);
    nextTick(() => {
        initialised = true;
    })
})

</script>

<style scoped>
</style>
