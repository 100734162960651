import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import TreatmentType from "@classes/TreatmentType";
import PersistedStore from "./PersistedStore";

const dataExpiryThresholdMinutes = 60 * 24;

export const useTreatmentTypeStore = defineStore(
    "treatmentTypeStore",
    () => {
        const treatmentTypes = ref<App.Models.TreatmentType[]>([]);
        const lastRefreshTime = ref<number | undefined>();

        const load = async (): Promise<App.Models.TreatmentType[]> => {
            return new Promise((resolve, reject) => {
                TreatmentType.index()
                    .then((response) => {
                        treatmentTypes.value = response.data;
                        lastRefreshTime.value = DateTime.now().toMillis();
                        resolve(treatmentTypes.value);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        };

        const sync = (force = false) => {
            return PersistedStore.sync(
                {
                    lastRefreshTime: lastRefreshTime.value,
                    dataExpiryThresholdMinutes,
                    force,
                },
                load,
                treatmentTypes.value
            );
        };

        return {
            treatmentTypes,
            lastRefreshTime,
            sync,
        };
    },
    {
        persist: {
            key: "treatmentTypeStore_" + window?.tenant?.tenancy_db_name,
        },
    }
);
