<template>
    <div class="row">
        <div class="col-md-8">
            <div>
                <div
                    :id="mapElementId"
                    :style="{
                        width: '100%',
                        height: '300px',
                        display:
                            directionRequestSegments.length > 0
                                ? 'block'
                                : 'none',
                    }"
                ></div>
            </div>
        </div>

        <div
            v-if="!specificLocationSelectedSummary"
            class="col-md-4 d-flex text-center flex-column"
        >
            <div
                v-for="(direction, index) in displayableDirectionSummary"
                :key="index"
            >
                <div class="bg-dark p-1 text-white">
                    {{ direction?.client_name }} (est)
                </div>
                <div class="row p-1">
                    <div class="col-sm-6 d-flex flex-row align-items-center">
                        <SvgIcon
                            type="mdi"
                            :path="mdiClockOutline"
                            class="me-1"
                            :size="15"
                        ></SvgIcon
                        >{{ direction?.duration }}
                    </div>
                    <div
                        class="col-sm-6 d-flex flex-row align-items-center justify-content-end"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiCrosshairsGps"
                            class="me-1"
                            :size="15"
                        ></SvgIcon
                        >{{ direction?.distance }}
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="specificLocationSelectedSummary"
            class="col-md-4 d-flex justify-content-center align-items-center text-center flex-column"
        >
            <strong>Distance (est)</strong>
            <span>
                <span v-if="specificLocationSelectedSummary.historicalDistance"
                    >Google Maps: </span
                >{{ specificLocationSelectedSummary.distance }}</span
            >
            <span v-if="specificLocationSelectedSummary.historicalDistance"
                >Historical:
                {{ specificLocationSelectedSummary.historicalDistance }}</span
            >
            <strong class="mt-4">Duration (est)</strong>
            {{ specificLocationSelectedSummary.duration }}
        </div>
    </div>
</template>

<script setup lang="ts">
import Location, { LatLng, LocationInterface } from "@classes/Location";
import {
    BookingFormStopInterface,
    useBookingStore,
} from "@stores/BookingStore";
import { computed, onMounted, ref, watch } from "vue";
import { uuid } from "vue-uuid";
import mapstyle from "../../../themes/caremaster_theme.json";

import { storeToRefs } from "pinia";
import Client from "@classes/Client";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCrosshairsGps, mdiClockOutline } from "@mdi/js";
import _ from "lodash";
import BookingFormHelper from "@classes/Helpers/BookingFormHelper";
import { useOrganisationSettingStore } from "@stores/OrganisationSettingStore";
import axios from "axios";

const organisationSettingStore = useOrganisationSettingStore();

const { organisationSettings } = storeToRefs(organisationSettingStore);

const { organisationSettingCachedValue } = organisationSettingStore;

interface DirectionRequestSegment {
    origin: LocationInterface | App.Models.Location;
    destination: LocationInterface | App.Models.Location;
    client_uuid?: string;
}

interface DirectionsResponses {
    client_uuid?: string;
    directions: google.maps.DirectionsResult;
}

interface DirectionsDisplayableSummary {
    client_name?: string;
    duration?: string;
    distance?: string;
    historicalDistance?: string;
}

const mapElementId = ref(uuid.v1());

const map = ref<null | google.maps.Map>(null);

const bookingStore = useBookingStore();

const { selectedClients, selectedClient, draft } = storeToRefs(bookingStore);

const directionResponses = computed(() => {
    return stop.value.directionResponses;
});

const props = defineProps<{
    stopIndex: number;
}>();

const previousStop = computed(() => {
    return bookingStore.draft.stops?.[props?.stopIndex - 1];
});

const stop = computed(() => {
    return bookingStore.draft.stops[props?.stopIndex];
});

const historicalEstimatedDistanceForSelectedClient = computed(() => {
    return stop.value.historicalEstimatedDistances.find(
        (i) => i.client_uuid == selectedClient.value?.uuid
    )?.value;
});

const getDisplayableHistoricalDistanceForSelectedClient = computed(() => {
    if (historicalEstimatedDistanceForSelectedClient.value) {
        return `${(
            historicalEstimatedDistanceForSelectedClient.value / 1000
        ).toFixed(2)} km`;
    }

    return undefined;
});

const specificLocationSelectedSummary =
    computed<DirectionsDisplayableSummary | null>(() => {
        let result = null;

        if (selectedClient.value) {
            let directionsForSelectedClient = directionResponses.value.find(
                (i) => i.client_uuid == selectedClient.value?.uuid
            );

            if (directionsForSelectedClient?.value) {
                return {
                    distance: Location.getReadableDistanceFromDirections(
                        directionsForSelectedClient?.value
                    ),
                    duration:
                        Location.getDurationFromDirections(
                            directionsForSelectedClient?.value
                        ) + " min",
                    historicalDistance:
                        getDisplayableHistoricalDistanceForSelectedClient.value,
                };
            }
        }

        return result;
    });

const displayableDirectionSummary = computed<DirectionsDisplayableSummary[]>(
    () => {
        let result: DirectionsDisplayableSummary[] = [];
        directionResponses.value.forEach((direction) => {
            result.push({
                client_name: bookingStore.draft.clients.find(
                    (client) => client.uuid == direction.client_uuid
                )?.name,
                distance: Location.getReadableDistanceFromDirections(
                    direction.value
                ),
                duration:
                    Location.getDurationFromDirections(direction.value) +
                    " min",
            });
        });

        return result;
    }
);

const renderMarkers = () => {
    const {
        origin,
        destination,
        isDestinationClientHome,
        isOriginClientHome,
        multiDestination,
        multiOrigin,
    } = BookingFormHelper.getOriginDestinationForCurrentStop({
        previousStop: previousStop.value,
        stop: stop.value,
        client: selectedClient.value ?? null,
        allClients: draft.value.clients,
    });

    if (isOriginClientHome || isDestinationClientHome) {
        bookingStore.selectedClients.map((client) => {
            const marker = new google.maps.Marker({
                position: Location.locationToLatLng(client.address),
                title: "test",

                label: {
                    text: Client.getInitials(client),
                    fontWeight: "bold",
                    color: "#393D64",
                },
                map: map.value,
            });

            const content = `<p><strong>${
                client.name
            }'s Home Address</strong></p><p>${
                client.address ? client.address.full_address : ""
            }</p>`;

            google.maps.event.addListener(marker, "click", function () {
                var infowindow = new google.maps.InfoWindow({
                    content,
                    position: Location.locationToLatLng(client.address),
                });
                infowindow.open(map.value);
            });
        });
    }

    const specificallySelectedLocations: LocationInterface[] = [];

    if (!isOriginClientHome && origin) {
        specificallySelectedLocations.push(origin);
    }

    if (!isDestinationClientHome && destination) {
        specificallySelectedLocations.push(destination);
    }

    specificallySelectedLocations.map((location) => {
        const marker = new google.maps.Marker({
            position: Location.locationToLatLng(location),
            map: map.value,
        });

        const content = `<p><strong>Selected Address</strong></p><p>${
            location.full_address ?? location.fullAddress
        }</p>`;

        google.maps.event.addListener(marker, "click", function () {
            var infowindow = new google.maps.InfoWindow({
                content,
                position: Location.locationToLatLng(location),
            });
            infowindow.open(map.value);
        });
    });
};

const directionRequestSegments = computed<DirectionRequestSegment[]>(() => {
    const directionRequests: DirectionRequestSegment[] = [];

    const {
        origin,
        destination,
        isDestinationClientHome,
        isOriginClientHome,
        multiDestination,
        multiOrigin,
    } = BookingFormHelper.getOriginDestinationForCurrentStop({
        previousStop: previousStop.value,
        stop: stop.value,
        client: selectedClient.value ?? null,
        allClients: draft.value.clients,
    });

    //Basically we cannot have previous stop and current one both be client home
    if (isOriginClientHome && isDestinationClientHome) {
        return directionRequests;
    }

    if (isOriginClientHome && destination) {
        bookingStore.selectedClients.map((client) => {
            directionRequests.push({
                origin: client.address,
                destination: destination,
                client_uuid: client.uuid,
            });
        });
    } else if (isDestinationClientHome) {
        if (origin) {
            bookingStore.selectedClients.map((client) => {
                directionRequests.push({
                    origin: origin,
                    destination: client.address,
                    client_uuid: client.uuid,
                });
            });
        }
    } else {
        if (origin && destination)
            directionRequests.push({
                origin: origin,
                destination: destination,
            });
    }

    return directionRequests;
});

const initMap = () => {
    const trafficLayer = new google.maps.TrafficLayer();

    let bounds = new google.maps.LatLngBounds();

    directionRequestSegments.value.forEach((stop) => {
        const originLatLng = Location.locationToLatLng(stop.origin);
        const destinationLatLng = Location.locationToLatLng(stop.origin);

        bounds.extend(
            new google.maps.LatLng(originLatLng.lat, originLatLng.lng)
        );
        bounds.extend(
            new google.maps.LatLng(destinationLatLng.lat, destinationLatLng.lng)
        );
    });

    let mapElement = document.getElementById(mapElementId.value);

    if (!mapElement) {
        return;
    }

    map.value = new google.maps.Map(mapElement, {
        disableDefaultUI: true,
        styles: mapstyle,
    });

    map.value.fitBounds(bounds);

    trafficLayer.setMap(map.value);

    calculateAndDisplayRoute();
    renderMarkers();
};

const calculateAndDisplayRoute = async () => {
    const newDirections: DirectionsResponses[] = [];

    await Promise.all(
        directionRequestSegments.value.map(async (directionRequestSegment) => {
            const directionsRenderer = new google.maps.DirectionsRenderer({
                suppressMarkers: true,
            });
            const directionsService = new google.maps.DirectionsService();

            try {
                let directionPayload = {
                    origin: Location.locationToLatLng(
                        directionRequestSegment.origin
                    ),
                    destination: Location.locationToLatLng(
                        directionRequestSegment.destination
                    ),
                    travelMode: google.maps.TravelMode.DRIVING,
                };

                let historical_estimated_distance: number | undefined =
                    undefined;

                if (
                    organisationSettingCachedValue(
                        "booking_form_estimated_distance"
                    ) == "historical_data"
                ) {
                    let result = await axios.post(
                        route("api.bookings.get-historical-estimated-distance"),
                        {
                            origin: directionRequestSegment.origin.uuid,
                            destination:
                                directionRequestSegment.destination.uuid,
                        }
                    );

                    historical_estimated_distance =
                        result?.data?.estimated_distance ?? undefined;
                }

                const response = await directionsService.route(
                    directionPayload
                );

                directionsRenderer.setDirections(response);
                directionsRenderer.setMap(map.value);

                function saveResponseForClient(client_uuid: string) {
                    const savable = bookingStore.appendToClientSpecificValues(
                        stop.value.directionResponses,
                        client_uuid,
                        response
                    );

                    bookingStore.draft.stops[
                        props.stopIndex
                    ].directionResponses = savable;

                    const historicalDistancesSavable =
                        bookingStore.appendToClientSpecificValues(
                            stop.value.historicalEstimatedDistances,
                            client_uuid,
                            historical_estimated_distance
                        );

                    bookingStore.draft.stops[
                        props.stopIndex
                    ].historicalEstimatedDistances = historicalDistancesSavable;
                }

                //Determine if this direction belongs to only one client in the stop or all and save accordingly
                if (directionRequestSegment?.client_uuid) {
                    saveResponseForClient(directionRequestSegment?.client_uuid);
                } else {
                    bookingStore.draft.clients.map((client) => {
                        saveResponseForClient(client.uuid);
                    });
                }

                newDirections.push({
                    directions: response,
                    client_uuid: directionRequestSegment?.client_uuid,
                });
            } catch (ex) {
                console.error(ex);
            }
        })
    );
};

onMounted(() => {
    initMap();
});

watch(
    () =>
        _.cloneDeep({
            stop: stop.value,
            previousStop: previousStop.value,
            client: selectedClient.value,
        }),
    (newPayload, oldPayload) => {
        const oldOriginDestination =
            BookingFormHelper.getOriginDestinationForCurrentStop({
                previousStop: oldPayload.previousStop,
                stop: oldPayload.stop,
                client: oldPayload.client ?? null,
            });

        const newOriginDestination =
            BookingFormHelper.getOriginDestinationForCurrentStop({
                previousStop: newPayload.previousStop,
                stop: newPayload.stop,
                client: newPayload.client ?? null,
            });

        const originChanged = !_.isEqual(
            oldOriginDestination.origin?.uuid,
            newOriginDestination.origin?.uuid
        );

        const destinationChanged = !_.isEqual(
            oldOriginDestination.destination?.uuid,
            newOriginDestination.destination?.uuid
        );

        const isDestinationClientHomeChanged =
            oldOriginDestination.isDestinationClientHome !==
            newOriginDestination.isDestinationClientHome;

        const isDestinationMultiClientChanged = !_.isEqual(
            oldPayload.client?.uuid,
            newPayload.client?.uuid
        );

        if (
            originChanged ||
            destinationChanged ||
            isDestinationClientHomeChanged ||
            isDestinationMultiClientChanged
        ) {
            initMap();
        }
    }
);
</script>
