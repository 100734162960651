import axios from "axios";
import ReferralResource from "@customTypes/resources/ReferralsResource";
import ReferralInterface = App.Models.ReferralInterface;

export default class Referral {
    static async getAll(): Promise<ReferralResource[]> {
        let result: ReferralResource[] = [];

        try {
            let response: any = await axios.get(
                route("api.referrals.index")
            );

            if (response?.data?.data?.some((i) => i?.uuid)) {
                result = response?.data?.data;
            }
        } catch (exception) {
            console.error(exception);
        }

        return result;
    }

    static async getAvailableOptionsFor(referral:ReferralInterface):Promise<ReferralResource[]> {
        let result: ReferralResource[] = [];

        if(!referral.endpoint) {
            return result;
        }

        try {
            let response: any = await axios.get(
                route(referral.endpoint)
            );

            if (response?.data?.some((i) => i?.uuid)) {
                result = response?.data;
            }
        } catch (exception) {
            console.error(exception);
        }
        return result;
    }
}
