<template>
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="payerInvoicesModalLabel" aria-modal="true"
         role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="typeFormModalLabel">
                        Invoices paid by {{ props.payer.name }}
                    </h5>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    <th>Due date</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="invoice in invoices">
                                    <td>{{ invoice.uuid }}</td>
                                    <td>{{ invoice.status }}</td>
                                    <td>{{ invoice.total }}</td>
                                    <td>{{ invoice.due_at }}</td>
                                    <td class="text-end">
                                        <a :href="route('invoices.edit', { invoice: invoice.uuid })" class="mx-1 btn btn-sm btn-outline-primary">Edit</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-end">
                    <Button color="light" class="me-1" @click="emit('close')">Close</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup lang="ts">
import Payer = App.Models.Payer;
import Button from "@components/Button.vue";
import axios from "axios";
import {onMounted, ref} from "vue";
import Invoice = App.Models.Invoice;

const props = withDefaults(
    defineProps<{
        payer: Payer;
    }>(),
    {
        //@ts-ignore
        payer: [],
    }
);
const emit = defineEmits(['close']);
const invoices = ref<Invoice[] | null>(null);

onMounted(() => {
    getInvoices();
});

const getInvoices = () => {
    axios.get(route('api.payers.invoices.index', props.payer.uuid))
        .then((response) => {
            invoices.value = response.data.data;
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {

        });
};

</script>
