<template>
    <div class="row">
        <div class="col-md-4">
            <h4>General Details</h4>
            <p>Here you can specify the basic personal details of the client.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-10">
                            <label for="clientGivenNames" class="form-label required-label">Formal name</label>
                            <div class="d-flex">
                                <div class="me-1 has-validation">
                                    <select
                                        v-model="client.title"
                                        :class="{ 'form-select': true, 'is-invalid': errors.title }"
                                        name="title"
                                        id="clientTitle"
                                        aria-describedby="title_feedback"
                                    >
                                        <option value="">-- Select Title --</option>
                                        <option value=""></option>
                                        <template v-for="title in titles">
                                            <option :value="title.value">{{ title.label }}</option>
                                        </template>
                                    </select>
                                    <div v-if="errors.title" id="title_feedback" class="invalid-feedback">
                                        {{ errors.title[0] }}
                                    </div>
                                </div>
                                <div class="me-1 has-validation">
                                    <input
                                        v-model="client.given_names"
                                        required
                                        :class="{ 'form-control': true, 'is-invalid': errors.given_names }"
                                        type="text"
                                        id="clientGivenNames"
                                        name="given_names"
                                        placeholder="e.g. Jesse Avery"
                                        aria-describedby="given_names_feedback"
                                    >
                                    <div v-if="errors.given_names" id="given_names_feedback" class="invalid-feedback">
                                        {{ errors.given_names[0] }}
                                    </div>
                                </div>
                                <div class="has-validation">
                                    <input
                                        v-model="client.last_name"
                                        required
                                        :class="{ 'form-control': true, 'is-invalid': errors.last_name }"
                                        type="text"
                                        id="clientLastName"
                                        name="last_name"
                                        placeholder="e.g. Jones"
                                        aria-describedby="last_name_feedback"
                                    >
                                    <div v-if="errors.last_name" id="last_name_feedback" class="invalid-feedback">
                                        {{ errors.last_name[0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-text">This name should be exactly the same as the client has registered with Medicare.</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 has-validation">
                            <label for="clientPreferredName" class="form-label">Preferred name</label>
                            <input
                                v-model="client.preferred_name"
                                required
                                :class="{ 'form-control': true, 'is-invalid': errors.preferred_name }"
                                type="text"
                                id="clientPreferredName"
                                name="preferred_name"
                                placeholder="e.g. Jesse"
                                aria-describedby="preferred_name_feedback"
                            >
                            <div v-if="errors.preferred_name" id="preferred_name_feedback" class="invalid-feedback">
                                {{ errors.preferred_name[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                        <label for="clientEmail" class="form-label">Email address</label>
                        <input
                            v-model="client.email"
                            :class="{ 'form-control': true, 'is-invalid': errors.email }"
                            type="email"
                            id="clientEmail"
                            name="email"
                            placeholder="e.g. client-email@example.com"
                            aria-describedby="email_feedback"
                        >
                        <div v-if="errors.email" id="email_feedback" class="invalid-feedback">
                            {{ errors.email[0] }}
                        </div>
                    </div>
                    </div>
                    <div class="row mb-3 align-items-baseline">
                        <div class="col-md-4 has-validation">
                            <label for="clientDob" class="form-label">Date of birth</label>
                            <div class="">
                                <input
                                    v-model="client.date_of_birth"
                                    :class="{ 'form-control': true, 'is-invalid': errors.date_of_birth }"
                                    type="date"
                                    id="clientDob"
                                    name="date_of_birth"
                                    aria-describedby="date_of_birth_feedback"
                                >
                                <div v-if="age" class="text-nowrap form-text">({{ age }})</div>
                            </div>
                            <div v-if="errors.date_of_birth" id="date_of_birth_feedback" class="invalid-feedback">
                                {{ errors.date_of_birth[0] }}
                            </div>
                        </div>
                        <div class="col-md-4 has-validation">
                            <label for="clientGender" class="form-label">Gender</label>
                            <select
                                v-model="client.gender"
                                :class="{ 'form-select': true, 'is-invalid': errors.gender }"
                                name="gender"
                                id="clientGender"
                                aria-describedby="gender_feedback"
                            >
                                <option value="not_stated">Not Stated</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="intersex">Intersex</option>
                            </select>
                            <div v-if="errors.gender" id="gender_feedback" class="invalid-feedback">
                                {{ errors.gender[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="client.date_of_birth_is_estimated"
                                    id="dobEstimated"
                                >
                                <label class="form-check-label" for="dobEstimated">
                                    Date of birth is estimated
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                            <label for="clientPhone" class="form-label">Phone number</label>
                            <MazPhoneNumberInput
                                v-model="client.phone"
                                show-code-on-list
                                color="primary"
                                size="sm"
                                :preferred-countries="['AU']"
                                :placeholder="'Example 0412 345 678'"
                                default-country-code="AU"
                                @update="phoneValidation = $event"
                                :success="phoneValidation?.isValid"
                            />
                            <div v-if="errors.phone" id="phone_feedback" class="invalid-feedback">
                                {{ errors.phone[0] }}
                            </div>
                        </div>
                    </div>
                  <div class="row mb-3">
                    <div class="col-md-6 has-validation">
                      <label for="clientAdditionalPhone" class="form-label">Additional Phone number</label>
                      <MazPhoneNumberInput
                          v-model="client.additional_phone"
                          show-code-on-list
                          color="primary"
                          size="sm"
                          :preferred-countries="['AU']"
                          :placeholder="'Example 0412 345 678'"
                          default-country-code="AU"
                          @update="phoneValidation = $event"
                          :success="phoneValidation?.isValid"
                      />
                      <div v-if="errors.additional_phone" id="additional_phone_feedback" class="invalid-feedback">
                        {{ errors.additional_phone[0] }}
                      </div>
                    </div>
                  </div>
                    <div class="mb-3 has-validation">
                        <AddressDbSearch
                            :label="'Home address'"
                            @locationSelected="selectHomeLocation"
                            required
                            :invalid="errors.address"
                            aria-describedby="address_feedback"
                        />
                        <div v-if="errors.address" id="address_feedback" class="invalid-feedback">
                            {{ errors.address[0] }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="useHomeAddressForPostal"
                                id="useHomeAddressForPostal"
                            >
                            <label class="form-check-label" for="useHomeAddressForPostal">
                                Postal address is the same as home address
                            </label>
                        </div>
                    </div>
                    <div v-if="!useHomeAddressForPostal" class="mb-3">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="mb-2">
                                    <label for="postalLine1" class="form-label">Postal Address</label>
                                    <input
                                        v-model="postal.line1"
                                        required
                                        :class="{ 'form-control': true, 'is-invalid': errors.postal_line_1 }"
                                        type="text"
                                        id="postalLine1"
                                        name="postal_line_1"
                                        placeholder="Unit 4"
                                        aria-describedby="postal_line_1_feedback"
                                    >
                                    <div v-if="errors.postal_line_1" id="email_feedback" class="invalid-feedback">
                                        {{ errors.postal_line_1[0] }}
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <label for="postalLine2" class="form-label visually-hidden">Address Line 2</label>
                                    <input
                                        v-model="postal.line2"
                                        required
                                        :class="{ 'form-control': true, 'is-invalid': errors.postal_line_2 }"
                                        type="text"
                                        id="postalLine2"
                                        name="postal_line_2"
                                        placeholder="123 Example Road"
                                        aria-describedby="postal_line_2_feedback"
                                    >
                                    <div v-if="errors.postal_line_2" id="postal_line_2_feedback" class="invalid-feedback">
                                        {{ errors.postal_line_2[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 has-validation">
                                <label for="postalSuburb" class="form-label">Suburb</label>
                                <input
                                    v-model="postal.suburb"
                                    required
                                    :class="{ 'form-control': true, 'is-invalid': errors.postal_suburb }"
                                    type="text"
                                    id="postalSuburb"
                                    name="postal_suburb"
                                    placeholder="Shelbyville"
                                    aria-describedby="postal_suburb_feedback"
                                >
                                <div v-if="errors.postal_suburb" id="postal_suburb_feedback" class="invalid-feedback">
                                    {{ errors.postal_suburb[0] }}
                                </div>
                            </div>
                            <div class="col-md-3 has-validation">
                                <label for="postalState" class="form-label">State</label>
                                <select
                                    v-model="postal.state"
                                    :class="{ 'form-select': true, 'is-invalid': errors.postal_state }"
                                    name="postal_state"
                                    id="postalState"
                                    aria-describedby="postal_state_feedback"
                                >
                                    <option value="ACT">Australian Capital Territory</option>
                                    <option value="NSW">New South Wales</option>
                                    <option value="NT">Northern Territory</option>
                                    <option value="OT">Other Territories</option>
                                    <option value="QLD">Queensland</option>
                                    <option value="SA">South Australia</option>
                                    <option value="TAS">Tasmania</option>
                                    <option value="VIC">Victoria</option>
                                    <option value="WA">Western Australia</option>
                                </select>
                                <div v-if="errors.postal_state" id="postal_state_feedback" class="invalid-feedback">
                                    {{ errors.postal_state[0] }}
                                </div>
                            </div>
                            <div class="col-md-3 has-validation">
                                <label for="postalPostcode" class="form-label">Postcode</label>
                                <input
                                    v-model="postal.postcode"
                                    required
                                    :class="{ 'form-control': true, 'is-invalid': errors.postal_postcode }"
                                    type="number"
                                    max="9999"
                                    maxlength="5"
                                    min="1111"
                                    id="postalPostcode"
                                    name="postal_postcode"
                                    placeholder="2000"
                                    aria-describedby="postal_postcode_feedback"
                                >
                                <div v-if="errors.postal_postcode" id="postal_postcode_feedback" class="invalid-feedback">
                                    {{ errors.postal_postcode[0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="dvaNumber" class="form-label">DVA Number</label>
                            <span v-tooltip="'Department of Veteran Affairs Number'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.dva_number"
                                required
                                :class="{ 'form-control': true, 'is-invalid': errors.dva_number }"
                                type="text"
                                id="dvaNumber"
                                name="dva_number_feedback"
                                placeholder="DVA12345"
                                aria-describedby="dva_number_feedback"
                            >
                            <div v-if="errors.dva_number" id="dva_number_feedback" class="invalid-feedback">
                                {{ errors.dva_number[0] }}
                            </div>
                        </div>

                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="myAgedCareNumber" class="form-label">MAC Number</label>
                            <span v-tooltip="'My Aged Care Number'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.my_aged_care_number"
                                required
                                :class="{ 'form-control': true, 'is-invalid': errors.my_aged_care_number }"
                                type="text"
                                id="myAgedCareNumber"
                                name="my_aged_care_number"
                                placeholder="MAC12345"
                                aria-describedby="my_aged_care_number_feedback"
                            >
                            <div v-if="errors.my_aged_care_number" id="my_aged_care_number_feedback" class="invalid-feedback">
                                {{ errors.my_aged_care_number[0] }}
                            </div>
                        </div>

                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="ndisNumber" class="form-label">NDIS Number</label>
                            <span v-tooltip="'National Disability Insurance Scheme Number'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.ndis_number"
                                required
                                :class="{ 'form-control': true, 'is-invalid': errors.ndis_number }"
                                type="text"
                                id="ndisNumber"
                                name="ndis_number"
                                placeholder="NDIS12345"
                                aria-describedby="ndis_number_feedback"
                            >
                            <div v-if="errors.ndis_number" id="ndis_number_feedback" class="invalid-feedback">
                                {{ errors.ndis_number[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="centrelinkReferenceNumber" class="form-label">Centrelink Reference Number</label>
                            <span v-tooltip="'Centrelink Reference Number (9 numbers ending with a letter)'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.centrelink_reference_number"
                                :class="{ 'form-control': true, 'is-invalid': errors.centrelink_reference_number }"
                                type="text"
                                id="centrelinkReferenceNumber"
                                name="centrelink_reference_number"
                                placeholder="123456789A"
                                aria-describedby="centrelink_reference_number_feedback"
                            >
                            <div v-if="errors.centrelink_reference_number" id="centrelink_reference_number_feedback" class="invalid-feedback">
                                {{ errors.centrelink_reference_number[0] }}
                            </div>
                        </div>

                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="medicareCardNumber" class="form-label">Medicare Card Number</label>
                            <span v-tooltip="'Medicare Card Number (10 digit number)'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.medicare_card_number"
                                :class="{ 'form-control': true, 'is-invalid': errors.medicare_card_number }"
                                type="text"
                                id="medicareCardNumber"
                                name="medicare_card_number"
                                placeholder="1234 56789 0"
                                aria-describedby="medicare_card_number_feedback"
                            >
                            <div v-if="errors.medicare_card_number" id="medicare_card_number_feedback" class="invalid-feedback">
                                {{ errors.medicare_card_number[0] }}
                            </div>
                        </div>

                        <div class="col-md-4 mb-3 mb-md-0 has-validation">
                            <label for="medicareIRN" class="form-label">Medicare IRN</label>
                            <span v-tooltip="'Medicare Invididual Reference Number (single digit found next to the name on the card)'" class="ms-1 mb-2 text-info">
                                <SvgIcon type="mdi" :path="mdiInformationOutline" :size="20" />
                            </span>
                            <input
                                v-model="client.medicare_individual_reference_number"
                                :class="{ 'form-control': true, 'is-invalid': errors.medicare_individual_reference_number }"
                                type="text"
                                id="medicareIRN"
                                name="medicare_irn"
                                placeholder="1"
                                aria-describedby="medicare_irn_feedback"
                            >
                            <div v-if="errors.medicare_individual_reference_number" id="medicare_irn_feedback" class="invalid-feedback">
                                {{ errors.medicare_individual_reference_number[0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <FormSection title="Regions">
        <template #subtitle>
            <p>Here you can set which regions this client can have bookings created in.</p>
        </template>
        <label class='required-label form-label'>
            Regions
        </label>
        <ClientRegionEditableList v-model="client.regions" />
    </FormSection>
    <hr />
    <FormSection
        title="Client Assessments"
        subtitle="Here you can add details about the clients assessments including the clients last assessment date and assessment frequency"
    >
        <ClientAssessmentForm :client="client" @updated="updateClientAssessmentDetails" />
    </FormSection>
    <hr>
    <div class="row">
        <div class="col-md-4">
            <h4>Demographic/Research Information</h4>
            <p>This information is required if funded by Commonwealth or State Governments.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 has-validation">
                            <label for="clientIndigenousStatus" class="form-label">Indigenous status</label>
                            <select
                                v-model="client.indigenous_status"
                                :class="{ 'form-select': true, 'is-invalid': errors.indigenous_status }"
                                name="indigenous_status"
                                id="clientIndigenousStatus"
                                aria-describedby="indigenous_status_feedback"
                            >
                                <option value="not_stated">Not Stated</option>
                                <option value="neither">Not Aboriginal or Torres Strait Islander</option>
                                <option value="aboriginal">Aboriginal</option>
                                <option value="torres_straight_islander">Torres Strait Islander</option>
                                <option value="aboriginal_and_torres_straight_islander">Both Aboriginal & Torres Strait Islander</option>
                            </select>
                            <div v-if="errors.indigenous_status" id="indigenous_status_feedback" class="invalid-feedback">
                                {{ errors.indigenous_status[0] }}
                            </div>
                        </div>
                        <div class="col-md-4 has-validation">
                            <label for="clientSpokenLanguage" class="form-label">Language</label>
                            <select
                                v-model="client.language"
                                :class="{ 'form-select': true, 'is-invalid': errors.language }"
                                name="language"
                                id="clientSpokenLanguage"
                                aria-describedby="language_feedback"
                            >
                                <option value="not_stated">Not Stated</option>
                                <template v-for="(label, value) in languages">
                                    <option :value="value">{{ label }}</option>
                                </template>
                            </select>
                            <div v-if="errors.language" id="language_feedback" class="invalid-feedback">
                                {{ errors.language[0] }}
                            </div>
                        </div>
                        <div class="col-md-4 has-validation">
                            <label for="clientSpokenLanguage" class="form-label">Country of birth</label>
                            <select
                                v-model="client.birth_country"
                                :class="{ 'form-select': true, 'is-invalid': errors.birth_country }"
                                name="birth_country"
                                id="clientBirthCountry"
                                aria-describedby="birth_country_feedback"
                            >
                                <template v-for="(label, value) in countries">
                                    <option :value="value">{{ label }}</option>
                                </template>
                            </select>
                            <div v-if="errors.birth_country" id="birth_country_feedback" class="invalid-feedback">
                                {{ errors.birth_country[0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-4">
            <h4>Funding Details</h4>
            <p>Please provide the default funding details for the client, including their funding source and payer information</p>
            <p>For each booking the client makes, you can set different funding details specific to that booking if required.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <label for="fundingType" class="form-label">Approved funding types</label>
                    <ApprovedFundingTypes
                        :funding-types="fundingTypes"
                        :approved-funding-types="approvedFundingTypes"
                        :payers="payers"
                        @updated="updateSelectedFundingTypes"
                    />
                    <label class="form-label">Funding Type Reference Numbers</label>
                    <FundingReferenceNumbers
                        class="mb-0"
                        :funding-types="fundingTypes"
                        v-model="fundingTypeReferences"
                        :approved-funding-types="approvedFundingTypes"
                        @update:modelValue="updateFundingTypeReferences"
                    ></FundingReferenceNumbers>

                    <template v-if="selectedFundingTypes.length">
                        <template v-for="selected in selectedFundingTypes">
                            <template v-if="selected.is_default && selected.funding_type?.custom_fields.length > 0">
                                <hr>
                                <template v-for="field in selected.funding_type.custom_fields">
                                    <div class="mt-3">
                                        <label :for="field.slug" :class="{ 'form-label': true, 'required-label': field.is_required }">{{ field.label }}</label>
                                        <template v-if="field.type === 'boolean'">
                                            <div class="d-flex">
                                                <div class="form-check">
                                                    <input
                                                        v-model="client[field.slug]"
                                                        value="1"
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="field.slug"
                                                        :id="field.slug + '_yes'"
                                                    >
                                                    <label class="form-check-label" :for="field.slug + '_yes'">Yes</label>
                                                </div>
                                                <div class="ms-2 form-check">
                                                    <input
                                                        v-model="client[field.slug]"
                                                        value="0"
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="field.slug"
                                                        :id="field.slug + '_no'"
                                                    >
                                                    <label class="form-check-label" :for="field.slug + '_no'">No</label>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="field.type === 'select'">
                                            <select v-model="client[field.slug]" :required="field.is_required" :name="field.slug" :id="field.slug" class="form-select">
                                                <template v-for="(label, value) in field.options">
                                                    <option :value="value">{{ label }}</option>
                                                </template>
                                            </select>
                                        </template>
                                        <template v-if="field.type === 'varchar'">
                                            <input type="text" class="form-control" :required="field.is_required" :id="field.slug" v-model="client[field.slug]" :placeholder="field.label">
                                        </template>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-4">
            <h4>Default Booking Details</h4>
            <p>These details will be used for bookings made by the client. They can be updated for a specific booking in the booking form if required.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="mb-3 has-validation">
                        <label for="clientAlert" class="form-label">Client booking alert</label>
                        <textarea
                            v-model="client.alert"
                            maxlength="1000"
                            name="alert"
                            id="clientAlert"
                            :class="{ 'form-control': true, 'is-invalid': errors.alert }"
                            rows="2"
                            aria-describedby="alert_feedback"
                            placeholder="e.g. Client has asked that we use their title 'Professor'."
                        ></textarea>
                        <div class="form-text">A client alert will be displayed to the person completing the booking form when the client makes a booking.</div>
                        <div v-if="errors.alert" id="alert_feedback" class="invalid-feedback">
                            {{ errors.alert[0] }}
                        </div>
                    </div>
                    <div class="mb-3 has-validation">
                        <label for="clientComment" class="form-label">Message for Driver</label>
                        <textarea
                            v-model="client.comments"
                            maxlength="1000"
                            name="comments"
                            id="clientComment"
                            :class="{ 'form-control': true, 'is-invalid': errors.comments }"
                            rows="2"
                            aria-describedby="comment_feedback"
                            placeholder="e.g. Please do not ring door bell."
                        ></textarea>
                        <div class="form-text">A client comment will be displayed to the driver when the booking is allocated to them.</div>
                        <div v-if="errors.comments" id="comment_feedback" class="invalid-feedback">
                            {{ errors.comments[0] }}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label for="defaultPriority" class="form-label">Default booking priority</label>
                            <select
                                v-model="client.default_priority"
                                :class="{ 'form-select': true, 'is-invalid': errors.default_priority }"
                                name="default_priority"
                                id="defaultPriority"
                                aria-describedby="default_priority_feedback"
                            >
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                            </select>
                            <div v-if="errors.default_priority" id="default_priority_feedback" class="invalid-feedback">
                                {{ errors.default_priority[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="bookingServiceType" class="form-label optional-label">Default booking service type</label>
                            <select
                                v-model="client.booking_service_type"
                                class="form-select"
                                name="booking_service_type"
                                id="bookingServiceType"
                            >
                                <option value="">-- Select Booking Service Type --</option>
                                <template v-for="bookingServiceType in bookingServiceTypes">
                                    <option :value="bookingServiceType.uuid">{{ bookingServiceType.name }}</option>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6  has-validation">
                            <label for="fareCollectionMethod" class="form-label optional-label">Default fare collection method</label>
                            <select
                                v-model="client.default_fare_collection_method"
                                :class="{ 'form-select': true, 'is-invalid': errors.default_fare_collection_method }"
                                name="fare_collection_method"
                                id="fareCollectionMethod"
                                aria-describedby="fare_collection_method_feedback"
                            >
                                <option value="">-- Select Default Fare Collection Method --</option>
                                <template v-for="method in fareCollectionMethods">
                                    <option :value="method.value">
                                        {{ method.label }}
                                    </option>
                                </template>
                            </select>
                            <div v-if="errors.default_fare_collection_method" id="fare_collection_method_feedback" class="invalid-feedback">
                                {{ errors.default_fare_collection_method[0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-4">
            <h4>Referral Information</h4>
            <p>Here you can specify the client's referral source, this helps us better understand how client's find out about the organisation.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                            <label for="referringAgency" class="form-label">Referring agency</label>
                            <select
                                v-model="client.referring_agency"
                                :class="{ 'form-select': true, 'is-invalid': errors.referring_agency }"
                                name="referring_agency"
                                id="referringAgency"
                                aria-describedby="referring_agency_feedback"
                            >
                                <option value="">-- Select Agency --</option>
                                <template v-for="agency in agencies">
                                    <option :value="agency.uuid">{{ agency.name }}</option>
                                </template>
                            </select>
                            <div v-if="errors.referring_agency" id="referring_agency_feedback" class="invalid-feedback">
                                {{ errors.referring_agency[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 has-validation">
                            <label for="referringContact" class="form-label">Contact name</label>
                            <select
                                v-model="client.referring_contact"
                                :class="{ 'form-select': true, 'is-invalid': errors.referring_contact }"
                                name="referring_contact"
                                id="referringContact"
                                aria-describedby="referring_contact_feedback"
                                :disabled="!referringAgency"
                            >
                                <option value="">-- Select Contact --</option>
                                <template v-if="referringAgency" v-for="contact in referringAgency.contacts">
                                    <option :value="contact.uuid">{{ contact.name }}</option>
                                </template>
                            </select>
                            <div v-if="errors.referring_contact" id="referring_contact_feedback" class="invalid-feedback">
                                {{ errors.referring_contact[0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <h4>Additional referral options</h4>
            <p>Here you can specify additional client's referral source.</p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <ReferralsForm
                                :availableReferrals="referrals"
                                :clientReferrals="clientReferrals"
                                :columns="1"
                                @updated="updateReferral"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <Button @click="store" :loading="saving">Create Client</Button>
    </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, inject, nextTick } from 'vue'
import AddressDbSearch from "../AddressDbSearch.vue"
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import Button from "../Button.vue"
import FundingReferenceNumbers from "./FundingReferenceNumbers.vue"
import VueMultiselect from 'vue-multiselect'
import ClientAssessmentForm from './ClientAssessmentForm.vue'
import { copyValues, transformInput, toTimeStamp } from "../Utils"
import { DateTime, Interval } from "luxon"
import humanizeDuration from "humanize-duration"
import { TransportRequirementsForm, CargoSpaceForm, AssistanceTypeForm } from '../AccessRequirements'
import { FormSection } from '../Forms/Layouts'
import ClientBookingsVue from './ClientBookings.vue';
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiInformationOutline } from '@mdi/js'
import ApprovedFundingTypes from "./ApprovedFundingTypes.vue";
import BookingServiceType from "@classes/BookingServiceType";
import ClientRegionEditableList from "@components/Clients/ClientRegionEditableList.vue";
import ReferralsForm from "@components/Referrals/ReferralsForm.vue";
import ReferralResource from "@customTypes/resources/ReferralsResource";
import Referral from "@classes/Referral";
import {Axios} from "axios";
import EditReferrals from "@components/Clients/EditReferrals.vue";
import { useRegionStore } from '@stores/RegionStore';
import { useBookingServiceTypeStore } from '@stores/BookingServiceTypeStore'

const toast:any = inject('toast')
const axios:any = inject('axios')

const regionsStore = useRegionStore();
const bookingServiceTypeStore = useBookingServiceTypeStore();

const titles = window.enums.titles

const { transportRequirements, cargoSpace, assistanceTypes } = defineProps({
    transportRequirements: {
        required: true,
        type: Array,
    },
    cargoSpace: {
        required: true,
        type: Array,
    },
    assistanceTypes: {
        required: true,
        type: Array,
    }
})

let homeAddress = ref({})
const selectHomeLocation = function (location) {
    homeAddress.value = location
    client.address = location.uuid
}
const postalAddress = computed(() => {
    return useHomeAddressForPostal.value === true && homeAddress.value
        ? {
            line1: homeAddress.value.address_line_1,
            line2: homeAddress.value.address_line_2,
            suburb: homeAddress.value.suburb,
            state: homeAddress.value.state,
            postcode: homeAddress.value.postcode,
        }
        : postal.value
})

const fundingTypeReferences = ref([])
const getClientFundingReferences = () => {
    let references = {}
    if (fundingTypeReferences.value.length > 0) {
        fundingTypeReferences.value.forEach((item) => {
            references[item.funding_type.uuid] = item.reference_number
        })
    }

    return references
}

const updateFundingTypeReferences = (references) => {
    fundingTypeReferences.value = references.value
}

const age = computed(() => {
    if (!client.dob) {
        return null
    }

    const formatted = Interval
        .fromDateTimes(DateTime.fromISO(client.dob), DateTime.now())
        .toDuration()
        .valueOf()

    if (isNaN(formatted)) {
        return null
    }

    return humanizeDuration(formatted, { round: false, largest: 1 }) + ' old'
})
const client = reactive({
    address: null,
    alert: null,
    approved_funding_types: [],
    birth_country: 'AU',
    booking_service_type: '',
    client_classification: null,
    comments: null,
    default_priority: 'Medium',
    date_of_birth: null,
    date_of_birth_is_estimated: false,
    email: null,
    funding_type: null,
    gender: 'not_stated',
    given_names: null,
    indigenous_status: 'not_stated',
    language: 'en',
    last_name: null,
    payer: null,
    phone: null,
    postal_address: postalAddress,
    preferred_name: null,
    referring_agency: null,
    referring_contact: null,
    regions: [],
    seats_required: 1,
    title: 'Mr',
    last_assessment_date: transformInput({}),
    assessment_frequency: transformInput({}),
    transport_requirements: {},
    cargo: [],
    assistance: [],
    default_fare_collection_method: null,
})

const updateClientAssessmentDetails = function (clientData) {
    client.last_assessment_date = copyValues(clientData.last_assessment_date)
    client.assessment_frequency = copyValues(clientData.assessment_frequency)
}

let errors = ref([])
let saving = ref(false)
const store = function () {
    saving.value = true

    if (!checkRequired()) {
        saving.value = false
        return
    }

    axios
        .post(route('api.clients.store'), mapClient())
        .then(() => {
            toast.success('The client was successfully created, you will be redirected to the client list.', { title: 'Client Created' })
            setTimeout(() => window.location = route('clients.index'), 5000)
        })
        .catch((error) => {
            saving.value = false
            errors.value = error.response.data.errors
            if (error.response.status === 422) {
                toast.error(error.response.data.message, { title: 'Validation Error' })
            } else {
                toast.error('There was a problem creating the client.', { title: 'System Error' })
            }
        })
}

const phoneValidation = ref()

let useHomeAddressForPostal = ref(true)
let postal = ref({
    line1: null,
    line2: null,
    suburb: null,
    state: 'NSW',
    postcode: null,
})

let fundingTypes = ref([])
const getFundingTypes = function () {
    axios
        .get(route('api.funding-types.index'))
        .then((response) => {
            fundingTypes.value = response.data
        })
}

const approvedFundingTypes = ref([])
const selectedFundingTypes = ref([])
const updateSelectedFundingTypes = (selectedTypes) => {
    selectedFundingTypes.value = selectedTypes

    client.approved_funding_types = selectedTypes.map((item) => {
        return {
            is_default: item.is_default,
            funding_type: item.funding_type.uuid,
            default_payer: item.default_payer?.uuid,
        }
    })
}

let payers = ref([])
const getPayers = function () {
    axios
        .get(route('api.payers.index'))
        .then((response) => {
            payers.value = response.data.data
        })
}

let clientClassifications = ref([])
const getClientClassifications = function () {
    axios
        .get(route('api.client-classifications.index'))
        .then((response) => {
            clientClassifications.value = response.data
        })
}

let bookingServiceTypes = ref<App.Models.BookingServiceType[]>([]);
const getBookingServiceTypes = async () => {
    bookingServiceTypes.value = await bookingServiceTypeStore.sync();
}

let agencies = ref([])
const getAgencies = function () {
    axios
        .get(route('api.agencies.index'))
        .then((response) => {
            agencies.value = response.data
        })
}
let referringAgency = computed(() => {
    return agencies.value.find((agency) => agency.uuid === client.referring_agency)
})

let regions = ref<App.Models.Region[]>([]);
const getRegions = async () => {
    regions.value = await regionsStore.sync();
}

let languages = ref([])
const getLanguages = function () {
    axios
        .get(route('api.languages'))
        .then((response) => {
            languages.value = response.data
        })
}

let countries = ref([])
const getCountries = function () {
    axios
        .get(route('api.countries'))
        .then((response) => {
            countries.value = response.data
        })
}

let fareCollectionMethods = ref([])
const getFareCollectionMethods = function () {
    axios
        .get(route('api.enum.fare-collection-methods'))
        .then((response) => {
            fareCollectionMethods.value = Object.keys(response.data).map(key => {
                return {
                    value: key,
                    label: response.data[key]
                }
            })
        })
}

const clientReferrals = ref<ReferralResource[]>([]);
const referrals = ref<ReferralResource[]>([]);
const getReferrals = async () => {
    referrals.value = await Referral.getAll();
};

onMounted(() => {
    getFundingTypes();
    getPayers();
    getClientClassifications();
    getAgencies();
    getRegions();
    getLanguages();
    getCountries();
    getBookingServiceTypes();
    getFareCollectionMethods();
    getReferrals();
})

const mapClient = () => {
    let storeClient = copyValues(client)
    let transport_requirements = []

    Object.keys(client.transport_requirements).forEach((key) => {
        transport_requirements.push({
            code: key,
            value: +client.transport_requirements[key]
        })
    })

    storeClient.transport_requirements = transport_requirements

    storeClient.funding_type_references = getClientFundingReferences()

    storeClient.last_assessment_date = toTimeStamp(client.last_assessment_date.value)

    storeClient.assessment_frequency = client.assessment_frequency.value

    console.log(client.regions);

    storeClient.regions = storeClient.regions.map((region) => {
        return {
            uuid: region.uuid,
            is_default: region.pivot.is_default,
        }
    })

    return storeClient
}

const checkRequired = () => {
    const required = {
        given_names: 'given name(s)',
        last_name: 'last name',
    }

    let failed = Object.entries(required).filter(([key, label]) => {
        if (!client[key]) {
            toast.error(`The ${label} is required`, {title: 'There was a problem'})
            errors.value[key] = [`The ${label} is required`]
            return true
        }
    })

    const componentRequired = {
        last_assessment_date: 'last assessment date',
        assessment_frequency: 'assessment frequency',
    }

    let componentFailed = Object.entries(componentRequired).filter(([key, label]) => {
        let field = client[key]
        if (!field.valid) {
            toast.error(`The ${label} is required`, {title: 'There was a problem'})
            field.errors.push(`The ${label} is required`)
            return false
        }
    })

    if (failed.length > 0) {
        return false;
    }

    if (componentFailed.length > 0) {
        return false;
    }

    return true
}

const updateTransport = (value) => {
    client.transport_requirements = value
}

const updateCargo = (value) => {
    client.cargo = value
}

const updateAssistance = (value) => {
    client.assistance = value
}

const updateReferral = (value) => {
    client.referrals = value
}

</script>

<style scoped>

</style>
