<template>
    <div>
        <label class="form-label"
            >Booking Tags
            <small class="fst-italic">(Service Group)</small></label
        >
        <VueMultiselect
            v-model="tags"
            :options="allTags"
            :multiple="true"
            class="mb-3"
            placeholder="Select Booking Tags"
            label="description"
            track-by="uuid"
        ></VueMultiselect>
    </div>
</template>

<script setup lang="ts">
import {onBeforeMount, ref, watch} from "vue";
import VueMultiselect from "vue-multiselect";
import {BookingTagInterface} from "@classes/BookingTag";
import {useBookingTagStore} from '@stores/BookingTagStore'
import { usePersistedBookingTagStore } from "@stores/PersistedBookingTagStore";

const persistedBookingTagStore = usePersistedBookingTagStore();

const props = withDefaults(
    defineProps<{
        modelValue?: BookingTagInterface[];
    }>(),
    {
        modelValue: undefined,
    }
);

const bookingTagStore = useBookingTagStore()
const allTags = ref<BookingTagInterface[]>([]);
const tags = ref<BookingTagInterface[]>(props.modelValue ?? []);

const emit = defineEmits<{
    (e: "update:modelValue", tags: BookingTagInterface[]): void;
}>();

watch(props, (newProps) => {
    tags.value = newProps.modelValue ?? [];
});

watch(tags, (newTags) => {
    emit("update:modelValue", newTags);
});

const getBookingTags = async () => {
    allTags.value = await persistedBookingTagStore.sync();
};

onBeforeMount(() => {
    getBookingTags();
});
</script>
