<template>
    <th
        class="sortable"
        :class="{
            'sort-asc':
                paginateParams.sortBy === props.param &&
                paginateParams.sortDirection === sortDirectionEnum.Ascending,
            'sort-desc':
                paginateParams.sortBy === props.param &&
                paginateParams.sortDirection === sortDirectionEnum.Descending,
        }"
        role="button"
        @click="updatePaginateSort(props.param)"
    >
        <slot>Column Name</slot>
    </th>
</template>

<script setup lang="ts">
import {usePaginationStore} from '@stores/PaginationStore';
import {storeToRefs} from 'pinia';
import {sortDirectionEnum} from "@customTypes/DataTableTypes";

const props = withDefaults(
    defineProps<{
        param: string,
        store?: string
    }>(), {
        store: 'default'
    }
)

const paginationStore = usePaginationStore(props.store);
const {paginateParams} = storeToRefs(paginationStore);
const {updatePaginateSort} = paginationStore;

</script>