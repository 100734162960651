<template>
    <VueMultiselect
        v-model="initialValues"
        :options="initialOptions"
        placeholder="Select Document type"
        label="name"
        track-by="value"
        multiple
    ></VueMultiselect>
</template>

<script setup lang="ts">
import VueMultiselect from "vue-multiselect";
import { ref, watch } from "vue";

const { initial, name, options } = withDefaults(
    defineProps<{
        initial: Array<any>;
        options: any;
        name: String;
        id: String;
    }>(),
    {}
);

const emit = defineEmits<{
    (e: "change", name: String, value: string): void;
}>();

const initialValues = ref<Array<any>>(initial);
const initialOptions = ref<Array<any>>(options);

const applyChanges = () => {
    emit("change", name, JSON.stringify(initialValues.value));
};

watch(initialValues, () => {
    applyChanges();
});
</script>

<style>
.multiselect__tag {
    background: #254cb8;
}

.multiselect__option--highlight {
    background: #254cb8;
}

.multiselect__option--highlight:after {
    background: #254cb8;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #98a6ad;
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: #98a6ad;
}

.multiselect__tag-icon:hover {
    background: #254cb8;
}

.multiselect__input,
.multiselect__placeholder {
    margin-left: 4px;
    color: #98a6ad;
}
</style>
