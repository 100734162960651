<template>
    <div
        v-if="open"
        class="bg-light"
        style="
            position: fixed;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            opacity: 0.7;
            z-index: 1000;
        "
        @click="open = false"
    ></div>

    <div
        :style="[
            'position: fixed',
            'right: 0px',
            'top: 0px',
            'bottom: 0px',
            'z-index: 1001',
            `padding-top: ${verticalIconPaddingTop}px`,
        ]"
        :class="['bg-white', 'border', 'd-flex', 'flex-row']"
    >
        <div style="">
            <div
                class="rounded-circle bg-primary m-1 p-2"
                @click="open = !open"
            >
                <SvgIcon
                    type="mdi"
                    :path="mdiAccountMultiple"
                    style="color: white"
                />
            </div>
        </div>
        <div
            class="sidebar"
            :style="[
                open ? 'width: 350px' : 'width: 0px;',
                open ? 'opacity: 1' : 'opacity: 0',
                'overflow: scroll',
            ]"
            :class="[open && 'px-2']"
        >
            <BookingFormClientCard
                v-for="(client, key) in bookingStore.draft.clients"
                v-bind:key="client.uuid"
                style="min-width: 300px"
                :client="client"
            ></BookingFormClientCard>
        </div>
    </div>
</template>

<style scoped>
.sidebar {
    transition: width 0.3s, opacity 1s, max-width 0.3s;
}
</style>

<script setup lang="ts">
import { mdiAccountMultiple } from "@mdi/js";
import { useBookingStore } from "@stores/BookingStore";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import BookingFormClientCard from "./BookingFormClientCard.vue";
import SvgIcon from "@jamescoyle/vue-icon";

const open = ref(false);

const bookingStore = useBookingStore();

const scrollY = ref(0);

const verticalIconPaddingTop = computed(() => {
    const headerHeight = 70;
    const scroll = scrollY.value;
    return scroll > headerHeight ? 0 : headerHeight - scroll;
});

onMounted(() => {
    window.addEventListener("scroll", onScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
});

const onScroll = (e: any) => {
    scrollY.value = e.target.documentElement.scrollTop;
};
</script>
