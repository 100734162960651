<template>
    <div
        :style="{
            paddingRight: spaceMargin + 'px',
            paddingTop: spaceMargin + 'px',
            borderRadius: spaceMargin + 'px',
            cursor: 'pointer',
        }"
        class="bg-light"
    >
        <div v-for="(row, index) in rows">
            <VehicleDiagramRow :rowIndex="index" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useVehicleConfigurationDesignStore } from "@stores/VehicleConfigurationDesignStore";
import VehicleDiagramRow from "./VehicleDiagramRow.vue";
import { computed } from "vue";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const { rows, spaceMargin } = storeToRefs(vehicleConfigurationDesignStore);
</script>
