<template>
    <div>
        <a :href="this.route('auth.login.azure.redirect', { pin: pin })" class="btn btn-light d-flex justify-content-center px-2" type="button">
            <SvgIcon type="mdi" :path="azureIcon" style="color: #0078d4"></SvgIcon>
            <span class="ms-1 flex-grow-1">Sign in with Azure</span>
        </a>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMicrosoftAzure } from "@mdi/js";
export default {
    name: "AzureLogin",
    components: {
        SvgIcon
    },
    data() {
        return {
            azureIcon: mdiMicrosoftAzure,
        }
    },
    props: {
        pin: {
            type: String,
            required: true,
        }
    }
}
</script>
