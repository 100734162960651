<template>
    <div class="form-group mb-3">
        <div class="vehicle-row">
            <div class="vehicle-col w-100">
                <FormTextInput v-model:input="vehicle.description" label="Vehicle Name / Description" placeholder="e.g. Company Car #55" />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <VehicleRegoInput v-model="vehicle.registration_code" />
            </div>
            <div class="col-6 vehicle-col">
                <VehicleTypeSelect v-model:selection="vehicle.type" :types="types" />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <OdometerInput
                    v-model="vehicle.starting_odometer"
                    :editable="!vehicle.uuid"
                    label="Starting Odometer"
                    :validation="yup.number().required().min(1).max(9999999)"
                />
            </div>
            <div class="col-6 vehicle-col">
                <FormSelect
                    v-model:selection="vehicle.region"
                    :options="regions"
                    label="Region"
                    placeholder="-- Select Region --"
                    :validation="yup.string().oneOf(getSelectValues(regions), 'Please select a region from the list').required()"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormTextInput v-model:input="vehicle.make" label="Make" placeholder="e.g. Toyota" />
            </div>
            <div class="col-6 vehicle-col">
                <FormTextInput v-model:input="vehicle.model" label="Model" placeholder="e.g. Avensis" />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormSelect
                    v-model:selection="vehicle.year"
                    :options="years"
                    label="Year"
                    placeholder="-- Select Year --"
                    :validation="yup.number().oneOf(getSelectValues(years), 'Please select a year from the list')"
                />

            </div>
            <div class="col-6 vehicle-col">
                <FormSelect
                    v-model:selection="vehicle.colour"
                    :options="colours"
                    label="Colour"
                    placeholder="-- Select Colour --"
                    :validation="yup.string().oneOf(getSelectValues(colours), 'Please select a Colour from the list')"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormTextInput
                    v-model:input="vehicle.vin"
                    label="Vehicle Identification Number (VIN)"
                    placeholder="WVW01234567891234"
                    :mask="vinMask"
                    :validation="yup.string().min(17).max(17).required()"
                />
            </div>
            <div class="col-6 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.modification_compliance_plate_date"
                    label="Modification Compliance Plate Date"
                    :max="now"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-lg-3 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.date_of_purchase"
                    label="Date of Purchase"
                    :max="now"
                />
            </div>
            <div class="col-lg-3 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.date_of_disposal"
                    label="Date of Disposal"
                    :min="vehicle.date_of_purchase.value"
                />
            </div>
            <div class="col-lg-6 vehicle-col">
                <FormNumberInput
                    v-model:input="vehicle.purchase_cost"
                    label="Purchase Cost"
                    :validation="yup.number()"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-12 vehicle-col">
                <FormSelect
                    v-model:selection="vehicle.owner"
                    :options="ownership"
                    label="Ownership Type"
                    placeholder="-- Select Ownership Type --"
                    :validation="yup.string().oneOf(getSelectValues(ownership)).required()"
                />
            </div>
        </div>
        <div v-if="vehicle.owner.value && vehicle.owner.value === 'lease'" class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormTextInput
                    v-model:input="vehicle.leasing_company"
                    label="Leasing Company"
                    placeholder="Organisation Name"
                    :validation="yup.string().max(255)"
                />
            </div>
            <div class="col-6 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.lease_expiry_date"
                    label="Lease Expiry"
                    :min="now"
                    :validation="yup.date()"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="vehicle-col w-100">
                <AddressDbSearch 
                    v-model="vehicle.depot"
                    label="Depot Address"
                    :required="true"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormDecimalInput
                    v-model:input="vehicle.operating_costs"
                    label="Operating Costs"
                    placeholder="Operating Costs"
                />
            </div>
            <div class="col-6 vehicle-col">
                <FormSelect
                    v-model:selection="vehicle.license_required"
                    :options="licenses"
                    label="License Required"
                    placeholder="-- Select License --"
                    :validation="yup.string().oneOf(getSelectValues(licenses), 'Please select a license class from the list')"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="col-6 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.registration_expiry"
                    label="Registration Expiry"
                    :validation="yup.date().nullable().default(null).typeError('Invalid date')"
                />
            </div>
            <div class="col-6 vehicle-col">
                <FormDateInput
                    v-model:input="vehicle.insurance_expiry"
                    label="Insurance Expiry"
                    :validation="yup.date().nullable().default(null).typeError('Invalid date')"
                />
            </div>
        </div>
        <div class="vehicle-row">
            <div class="vehicle-col w-100">
                <FormTextarea v-model:input="vehicle.notes" label="Additional Notes" placeholder="e.g. Boot space is limited" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, reactive, ref, watch } from 'vue'
    import VehicleTypeSelect from './VehicleTypeSelect.vue'
    import VehicleRegoInput from './VehicleRegoInput.vue'
    import { FormTextarea, FormTextInput, FormSelect, FormDecimalInput, FormDateInput, FormNumberInput } from '../../Forms/'
    import { AddressDbSearch, OdometerInput } from '../..'
    import { copyValues, onReady, getSelectValues } from '../../Utils'
    import * as yup from 'yup'
    import { range } from 'lodash'
    import {DateTime} from 'luxon'

    const { modelValue, types, regions, colours, ownership, licenses } = defineProps({
        modelValue: {
            required: true,
            type: Object
        },
        types: {
            required: true,
            type: Object
        },
        regions: {
            required: true,
            type: Object
        },
        colours: {
            required: true,
            type: Object,
        },
        ownership: {
            required: true,
            type: Object,
        },
        licenses: {
            required: true,
            type: Object
        }
    })

    const vehicle = reactive(copyValues(modelValue))

    const now = computed(() => {
        return DateTime.local().toFormat('yyyy-MM-dd')
    })

    const vinMask =  IMask.createMask({
        mask: /^[a-z0-9]+$/i,
        prepare: function (str) {
            return str.toUpperCase();
        },
    })

    let years = range(moment().year(), moment().year() - 20).map(year => {
        return {
            value: year,
            label: year,
        }
    });

    const mounted = ref(false)

    onReady(() => mounted.value = true)

    const emit = defineEmits(['update:modelValue'])

    watch(
        vehicle,
        (value) => {
            if (mounted.value) {
                emit('update:modelValue', value)
            }
        }
    )
</script>

<style scoped>
    .vehicle-row {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
    }
    .vehicle-col {
        padding: 10px 5px;
        justify-content: center;
    }
</style>
