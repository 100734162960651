import axios from "axios";
import { DateTime } from "luxon";
import { BookingInterface } from "./Booking";
import { toTimeStamp } from "../../js/components/Utils";

export enum BookingEventType {
    PICK_UP = "pick_up",
    DROP_OFF = "drop_off",
    UNSCHEDULED_STOP = "unsch_stop",
}

export type BookingEventInterface = App.Models.BookingEvent & {
    type: BookingEventType;
};

export default class BookingEvent {
    static store(parameters: {
        bookings: BookingInterface[];
        type: BookingEventType;
        occurred_at: DateTime;
    }) {
        return axios.post(route("api.booking-events.store"), {
            bookings: parameters.bookings.map((i) => i.uuid),
            type: parameters.type,
            occurred_at: toTimeStamp(parameters.occurred_at),
        });
    }

    static update(
        booking: BookingInterface,
        event: App.Models.BookingEvent,
        parameters: {
            occurred_at: DateTime;
        }
    ) {
        return axios.put(
            route("api.bookings.events.update", {
                booking: booking.uuid,
                event: event.uuid,
            }),
            {
                ...parameters,
                ...{
                    occurred_at: toTimeStamp(parameters.occurred_at),
                },
            }
        );
    }
}
