<template>
    <div class="d-flex">
        <div
            :class="[
                'me-2 card shadow-none border border-2 rounded-3',
                scheduleType === 'drop_off'
                    ? 'border-primary'
                    : 'border-light bg-light',
            ]"
        >
            <div class="card-body p-2">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        :name="'schedule_timing_for_' + stopIndex"
                        :id="'drop_off_schedule_timing_for_' + stopIndex"
                        value="drop_off"
                        v-model="scheduleType"
                    />
                    <label
                        class="form-check-label"
                        :for="'drop_off_schedule_timing_for_' + stopIndex"
                    >
                        Set drop off timing
                    </label>
                </div>
            </div>
        </div>
        <div
            :class="[
                'card shadow-none border border-2 rounded-3',
                scheduleType === 'pick_up'
                    ? 'border-primary'
                    : 'border-light bg-light',
            ]"
        >
            <div class="card-body p-2">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        :name="'schedule_timing_for_' + stopIndex"
                        :id="'pick_up_schedule_timing_for_' + stopIndex"
                        value="pick_up"
                        v-model="scheduleType"
                    />
                    <label
                        class="form-check-label"
                        :for="'pick_up_schedule_timing_for_' + stopIndex"
                    >
                        Set pick up timing
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <label class="form-label">Time</label>
            <input
                v-model="time"
                :min="DateTime.now().toFormat('yyyy-MM-dd')"
                :max="DateTime.now().plus({ years: 1 }).toFormat('yyyy-MM-dd')"
                type="time"
                id="bookingDateInput"
                class="form-control"
            />
        </div>
        <div class="col-lg-3">
            <label class="form-label">Booking Service Type</label>
            <select class="form-select" v-model="bookingServiceType">
                <option :value="null">-- Select Type --</option>
                <option
                    v-for="(serviceType, index) in bookingServiceTypes"
                    :key="index"
                    :value="serviceType.uuid"
                >
                    {{ serviceType.name }}
                </option>
            </select>
        </div>

        <div class="col-lg-3">
            <label class="form-label">Flexibility</label>
            <select class="form-select" v-model="flexibilityBefore">
                <option :value="60">1 hour early</option>
                <option :value="30">30 min early</option>
                <option :value="15">15 min early</option>
                <option :value="10">10 min early</option>
                <option :value="5">5 min early</option>
                <option :value="0">On Time</option>
            </select>
        </div>
        <div class="col-lg-3 d-flex align-items-end">
            <select class="form-select" v-model="flexibilityAfter">
                <option :value="60">1 hour late</option>
                <option :value="30">30 min late</option>
                <option :value="15">15 min late</option>
                <option :value="10">10 min late</option>
                <option :value="5">5 min late</option>
                <option :value="0">On Time</option>
            </select>
        </div>
    </div>

    <div class="row mb-3 mt-3" v-if="scheduleType === 'drop_off'">
        <div class="col-md-3">
            <FormSwitch label="Has Appointment" v-model="isAppointment" />
            <div v-if="isAppointment" class="pt-3">
                <label class="form-label">Appointment Time</label>
                <input
                    v-model="appointmentTimeDisplayable"
                    :min="DateTime.now().toFormat('YYYY-MM-DD')"
                    :max="
                        DateTime.now().plus({ days: 1 }).toFormat('YYYY-MM-DD')
                    "
                    type="time"
                    id="bookingDateInput"
                    class="form-control"
                />
            </div>
        </div>
    </div>

    <div
        v-if="bookingFormErrors.booking_service_type"
        class="alert alert-danger mt-3"
    >
        <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
        {{ bookingFormErrors.booking_service_type }}
    </div>

    <div class="row mb-3 mt-3" v-if="scheduleType === 'pick_up'">
        <div class="col-md-12">
            <transition name="basic-fade">
                <FormSwitch
                    label="Client to confirm time"
                    v-model="clientToConfirmTime"
                />
            </transition>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <label class="form-label">Timing Summary</label>
            <div class="card shadow-sm">
                <div class="card-body p-2">
                    <div class="mb-2">
                        <strong>Requested Times</strong>
                        <div v-if="humanReadableTime">
                            {{ humanReadableTime }}
                        </div>
                        <div v-else class="text-secondary fst-italic">
                            Please enter time
                        </div>
                    </div>
                    <strong>Estimated Times</strong>
                    <div
                        v-if="!estimatedTimingsForStop"
                        class="text-secondary fst-italic"
                    >
                        Please enter times and locations
                    </div>
                    <div v-if="estimatedTimingsForStop">
                        {{
                            estimatedTimingsForStop.timings[0].depart_not_before?.toFormat(
                                "HH:mm"
                            )
                        }}
                        -
                        {{
                            estimatedTimingsForStop.timings[0].depart_no_later_than?.toFormat(
                                "HH:mm"
                            )
                        }}
                        <span class="ms-2">Depart pick up address</span>
                    </div>
                    <div v-if="estimatedTimingsForStop">
                        {{
                            estimatedTimingsForStop.timings[0].arrive_not_before?.toFormat(
                                "HH:mm"
                            )
                        }}
                        -
                        {{
                            estimatedTimingsForStop.timings[0].arrive_no_later_than?.toFormat(
                                "HH:mm"
                            )
                        }}
                        <span class="ms-2">Arrive at destination</span>
                    </div>
                    <div class="text-sm mt-2">
                        * Timings are <strong>estimates only</strong> based on
                        distance between stops. More accurate timings will be
                        provided when the booking has been allocated to a
                        vehicle.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="bookingFormErrors.timings" class="alert alert-danger">
        <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
        {{ bookingFormErrors.timings }}
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useBookingStore, BookingStopScheduleType } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import FormSwitch from "../../Forms/FormSwitch.vue";
import { DateTime } from "luxon";
import { OptimizerEstimatedTimingResponse } from "@/resources/js/classes/Optimiser";

const bookingStore = useBookingStore();

const { bookingFormErrors, bookingServiceTypes, draft } =
    storeToRefs(bookingStore);

const initialValuesSet = ref(false);

const props = defineProps<{
    stopIndex: number;
}>();

const stopTime = bookingStore.getComputedForStopValue<DateTime | null>(
    props.stopIndex,
    "time"
);

const time = computed({
    get: () => stopTime.value?.toFormat("HH:mm"),
    set: (newValue) => {
        stopTime.value = newValue
            ? DateTime.fromFormat(newValue, "HH:mm")
            : null;
    },
});

const scheduleType =
    bookingStore.getComputedForStopValue<BookingStopScheduleType>(
        props.stopIndex,
        "scheduleType"
    );

watch(scheduleType, (newScheduleType) => {
    if (bookingServiceType.value) {
        const selectedBookingServiceType =
            bookingStore.bookingServiceTypes.find((serviceType) => {
                return serviceType.uuid === bookingServiceType.value;
            });

        adjustFlexibilityWindow(selectedBookingServiceType, newScheduleType);
    }
});

const bookingServiceType = bookingStore.getComputedForStopValue<string>(
    props.stopIndex,
    "bookingServiceType",
    null
);

watch(bookingServiceType, (newValue) => {
    const selectedBookingServiceType = bookingStore.bookingServiceTypes.find(
        (serviceType) => {
            return serviceType.uuid === newValue;
        }
    );

    adjustFlexibilityWindow(selectedBookingServiceType, scheduleType.value);
});

const adjustFlexibilityWindow = (
    selectedBookingServiceType: App.Models.BookingServiceType | undefined,
    scheduleType: string
) => {
    //We do not want to set the flexibility window if the user is opening an edit form with pre-selected flexibility window
    if (!initialValuesSet.value) {
        return;
    }

    if (selectedBookingServiceType) {
        if (scheduleType === "drop_off") {
            flexibilityBefore.value =
                selectedBookingServiceType.drop_off_early_minutes;
            flexibilityAfter.value =
                selectedBookingServiceType.drop_off_late_minutes;
        }

        if (scheduleType === "pick_up") {
            flexibilityBefore.value =
                selectedBookingServiceType.pickup_early_minutes;
            flexibilityAfter.value =
                selectedBookingServiceType.pickup_late_minutes;
        }

        if (selectedBookingServiceType.default_booking_type) {
            transportType.value =
                selectedBookingServiceType.default_booking_type;
        }
    }
};

const flexibilityBefore = bookingStore.getComputedForStopValue<number>(
    props.stopIndex,
    "flexibilityBefore"
);

const flexibilityAfter = bookingStore.getComputedForStopValue<number>(
    props.stopIndex,
    "flexibilityAfter"
);

const transportType = bookingStore.getComputedForStopValue<string>(
    props.stopIndex,
    "transportType",
    "individual"
);

const clientToConfirmTime = bookingStore.getComputedForStopValue<any>(
    props.stopIndex,
    "clientToConfirmTime"
);

const estimatedTimingsForStop =
    bookingStore.getComputedForStopClientSpecificValue<
        OptimizerEstimatedTimingResponse | undefined
    >(props.stopIndex, "estimatedTimings", undefined);

const humanReadableTime = computed(() => {
    let result: string | null = null;

    let selectedTime = stopTime.value;

    if (selectedTime instanceof DateTime) {
        let start = selectedTime.minus({
            minutes: flexibilityBefore.value ?? 0,
        });
        let end = selectedTime.plus({ minutes: flexibilityAfter.value ?? 0 });

        let timeWording =
            start.toFormat("X") == end.toFormat("X")
                ? ` at exactly ${start.toFormat("h:mm a")}`
                : `anytime from ${start.toFormat("h:mm a")} to ${end.toFormat(
                      "h:mm a"
                  )}`;

        result = `${
            scheduleType.value == "drop_off" ? "Drop off" : "Pick up"
        } ${timeWording}`;
    } else {
        result = null;
    }

    return result;
});

const appointmentTimeStored =
    bookingStore.getComputedForStopClientSpecificValue<DateTime | null>(
        props.stopIndex,
        "appointmentTime"
    );

const appointmentTimeDisplayable = computed({
    get() {
        return appointmentTimeStored.value
            ? appointmentTimeStored.value.toFormat("HH:mm")
            : null;
    },
    set(newValue) {
        if (newValue) {
            appointmentTimeStored.value = DateTime.fromFormat(
                newValue,
                "HH:mm"
            );
        }
    },
});

const isAppointment = computed<boolean>({
    get() {
        return appointmentTimeStored.value ? true : false;
    },
    set(newValue) {
        appointmentTimeStored.value = newValue
            ? stopTime.value ?? DateTime.now()
            : null;
    },
});

onMounted(() => {
    setTimeout(() => {
        initialValuesSet.value = true;
        //For booking edit we want to populate based on previously selected window where as for create we want to populate based on ...
        //Default values of booking service and schedule type
        if (!bookingStore.bookingEdit) {
            adjustFlexibilityWindow(
                bookingStore.bookingServiceTypes.find((serviceType) => {
                    return serviceType.uuid === bookingServiceType.value;
                }),
                scheduleType.value
            );
        }
    }, 300);
});

watch(time, () => {
    stopTime.value = time?.value
        ? DateTime.fromFormat(time?.value, "HH:mm")
        : null;
});
</script>
