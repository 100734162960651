import {defineStore} from "pinia";
import {onMounted, ref, inject} from "vue";
import axios from "axios";

export const useVerificationStore = defineStore('verification', () => {
    const journeys = ref([]);
    const fundingTypes = ref([]);
    const fundingRules = ref([]);
    const payers = ref([]);
    const transactionModal = ref(null);
    const fundingRulesModal = ref(null);
    const axios = inject('axios')
    const toast = inject('toast')
    const transactionRefreshKey = ref(0);
    const journeysNoDetail = ref([]);
    const reloadStatisticRequired = ref(false);
    const searchBookingResult = ref([]);

    onMounted(() => {
        transactionModal.value = new bootstrap.Modal(document.getElementById('verification_transaction_modal'))
        fundingRulesModal.value = new bootstrap.Modal(document.getElementById('verification_funding_rules_modal'))
    })

    const bookingTransactionsLoading = ref(false);
    const fundingRulesLoading = ref(false);
    const transactionForBooking = ref(null);
    const transactionForBookingJourney = ref(null);
    const transactions = ref([])
    const clientTransactions = ref([])
    const getBookingTransactions = function (booking, journeyUuid) {
        transactions.value = [];
        bookingTransactionsLoading.value = true;
        transactionForBooking.value = booking
        transactionForBookingJourney.value = journeyUuid
        axios
            .get(route('api.bookings.transactions.index', {booking: booking.uuid}))
            .then((response) => {
                transactions.value = response.data.data
            })
            .catch((error) => {
                toast.error('An error occurred while fetching transactions')
            })
            .finally(() => {
                bookingTransactionsLoading.value = false;
                transactionRefreshKey.value = Math.random();
            })
    }

    const clientTransactionsLoading = ref(false);
    const getClientTransactions = function (client) {
        clientTransactions.value = [];
        clientTransactionsLoading.value = true;
        axios
            .get(route('api.clients.transactions.index', {client: client.uuid, take: 10, unlinked: true, type: 'payment'}))
            .then((response) => {
                clientTransactions.value = response.data.data
            })
            .catch((error) => {
                toast.error('An error occurred while fetching transactions')
            })
            .finally(() => {
                clientTransactionsLoading.value = false;
                transactionRefreshKey.value = Math.random();
            })
    }

    const invoices = ref([])
    const getInvoices = function (booking) {
        invoices.value = [];
        axios
            .get(route('api.invoices.index', {booking: booking.uuid}))
            .then((response) => {
                invoices.value = response.data.data
            })
            .catch((error) => {
                toast.error('An error occurred while fetching invoices')
            })
    }

    const cancellationReasons = ref([])
    const getCancellationReasons = function () {
        axios
            .get(route('api.booking-cancellation-reasons.index'))
            .then(response => {
                cancellationReasons.value = response.data.data
            })
            .catch(error => {
                toast.error('Error fetching cancellation reasons')
            })
    }

    const updateJourney = function (updatedJourney) {
        const index = journeys.value.findIndex(journey => journey.uuid === updatedJourney.uuid)
        if (index !== -1) {
            journeys.value[index] = updatedJourney
        }
    }

    const updateBooking = function (journeyId, updatedBooking) {
        let journeyIndex = journeys.value.findIndex(journey => journey.uuid === journeyId)
        let bookingIndex = journeys.value[journeyIndex].bookings.findIndex(booking => booking.uuid === updatedBooking.uuid)
        if (bookingIndex !== -1) {
            journeys.value[journeyIndex].bookings[bookingIndex] = updatedBooking
        }
    }

    const linkTransactionToBooking = function (uuid) {
        const transaction = clientTransactions.value.find(t => t.uuid === uuid);
        clientTransactions.value.splice(clientTransactions.value.indexOf(transaction), 1);
        transactions.value.push(transaction);
        transactionForBooking.value.transactions.push(transaction);
        updateBooking(transactionForBookingJourney.value, transactionForBooking.value);
        transactionRefreshKey.value = Math.random();

        axios
            .put(
                route('api.clients.transactions.update',
                    {client: transactionForBooking.value.client.uuid, transaction: uuid}
                ),
                {booking: transactionForBooking.value.uuid}
            )
    }

    const unlinkTransaction = function (uuid) {
        const transaction = transactions.value.find(t => t.uuid === uuid);
        transactions.value.splice(transactions.value.indexOf(transaction), 1);
        clientTransactions.value.push(transaction);
        transactionRefreshKey.value = Math.random();
        transactionForBooking.value.transactions.slice(transactionForBooking.value.transactions.indexOf(transaction), 1);
        updateBooking(transactionForBookingJourney.value, transactionForBooking.value);

        axios
            .put(
                route('api.clients.transactions.update',
                    {client: transactionForBooking.value.client.uuid, transaction: uuid}
                ),
                {booking: null}
            )
    }

    const getFundingRules = function (booking) {
        fundingRules.value = [];
        fundingRulesLoading.value = true;
        axios
            .get(route('api.funding-types.funding-rules', {'funding_type': booking.funding_type.uuid}))
            .then((response) => {
                fundingRules.value = response.data.data
            })
            .catch((error) => {
                toast.error('An error occurred while fetching funding rules')
            })
            .finally(() => {
                fundingRulesLoading.value = false;
            })
    }

    return {
        journeys,
        fundingTypes,
        transactionModal,
        transactionForBooking,
        transactionForBookingJourney,
        transactions,
        clientTransactions,
        bookingTransactionsLoading,
        clientTransactionsLoading,
        transactionRefreshKey,
        updateJourney,
        updateBooking,
        getBookingTransactions,
        getClientTransactions,
        linkTransactionToBooking,
        unlinkTransaction,
        getCancellationReasons,
        getInvoices,
        invoices,
        cancellationReasons,
        payers,
        fundingRulesModal,
        fundingRulesLoading,
        fundingRules,
        getFundingRules,
        journeysNoDetail,
        reloadStatisticRequired,
        searchBookingResult
    };
});
