<template>
    <div class="d-inline-block">
        <EditableSelect
            :loading="updating"
            :options="filteredPayers"
            :value="booking.payer ? booking.payer.uuid : ''"
            @updated="update"
        >
            {{ booking.payer ? booking.payer.name : '—' }}
        </EditableSelect>
    </div>
</template>

<script>
export default {
    name: "EditBookingPayer"
}
</script>
<script setup>
import EditableSelect from "../../Forms/EditableFields/EditableSelect.vue";
import {ref, inject, computed, onMounted, watch} from "vue";
import axios from "axios";

const toast = inject('toast')
import {useVerificationStore} from "../../../stores/VerificationStore.js";
import {storeToRefs} from "pinia";

const verificationStore = useVerificationStore();
const { payers, fundingTypes } = storeToRefs(verificationStore);

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
})

const filteredPayers = computed(() => {
    const ftDetail = fundingTypes.value.find(ft => ft.uuid === props.booking.funding_type.uuid);
    const approvedPayers = {};

    if (ftDetail.approved_payers.length > 0) {
        ftDetail.approved_payers.forEach((ap) => {
            approvedPayers[ap.uuid] = ap.name;
        });
        return approvedPayers;
    }

    return payers.value;
});

const updating = ref(false)
const update = function (newPayer) {
    updating.value = true
    axios
        .put(route('api.bookings.update.payer', {booking: props.booking.uuid}), {
            payer: newPayer,
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data.data)
            toast.success('Booking payer updated.')
        })
        .catch((error) => {
            toast.error('There was an error updating the booking payer.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
