<template>
    <div
        class="modal fade"
        id="deactivateModal"
        tabindex="-1"
        aria-labelledby="deactivateModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deactivateModalLabel">Deactivate this client's account?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="py-2 px-2 mb-3 rounded border-1 shadow d-flex align-items-center">
                        <ClientAvatar :client="client" size="large" class="me-3"></ClientAvatar>
                        <div>
                            <div>{{ client.formal_name }}</div>
                            <small v-if="client.address">{{ client.address.formattedAddress }}</small>
                        </div>
                    </div>
                    <template v-if="!deactivated">
                        <p>
                            If you proceed, {{ props.client.preferred_name }} will no longer be able to make new bookings
                            or log into the mobile app.
                        </p>
                        <p>Previous booking data will still be kept for reporting purposes.</p>
                        <p>Future bookings for {{ client.preferred_name }} will be cancelled.</p>
                        <p>You can re-instate this client in the future if you choose.</p>
                        <div class="mb-2">
                            <label for="futureBookingAction" class="form-label required-label">
                                Cancellation reason:
                            </label>
                            <select v-model="deactivationReason" class="form-select" id="futureBookingAction">
                                <template v-for="(reason, value) in reasons">
                                    <option :value="value">{{ reason }}</option>
                                </template>
                            </select>
                        </div>
                        <div>
                            <label for="additionalInformation" class="form-label">
                                Additional Comments: <small class="text-muted">(optional)</small>
                            </label>
                            <textarea
                                v-model="deactivationComment"
                                maxlength="500"
                                placeholder="Start typing here..."
                                class="form-control"
                                id="additionalInformation"
                                rows="2"
                            ></textarea>
                        </div>
                    </template>
                    <template v-if="deactivated">
                        <p>The client's account was successfully deactivated.</p>
                        <p>Please wait while you're redirected back to the list of all clients.</p>
                    </template>
                </div>
                <div class="modal-footer" v-if="!deactivated">
                    <Button color="secondary" :disabled="deactivating" data-bs-dismiss="modal">Cancel</Button>
                    <Button color="danger" :loading="deactivating" @click="deactivateClient">
                        Confirm Cancellation
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from "vue"
import axios from "axios";
import ClientAvatar from "./ClientAvatar.vue";
import Button from "../Button.vue";

const props = defineProps({
    client: Object,
})

const deactivationReason = ref(null)
const deactivationComment = ref('')

const reasons = ref([])
const getReasons = function () {
    axios
        .get(route('api.cancellation-reasons'))
        .then((response) => {
            reasons.value = response.data
        })
}

const deactivating = ref(false)
const deactivated = ref(false)
const deactivateClient = function () {
    deactivating.value = true
    axios
        .delete(route('api.clients.destroy', { client: props.client.uuid }), {
            data: {
                deactivation_reason: deactivationReason.value,
                deactivation_comment: deactivationComment.value,
            },
        })
        .then((response) => {
            deactivated.value = true
            setTimeout(() => {
                window.location.href = route('clients.index')
            }, 3000)
        })
        .finally(() => {
            deactivating.value = false
        })
}

onBeforeMount(() => {
    getReasons()
})

</script>

<style scoped>

</style>
