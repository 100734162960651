<template>
    <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">Recurring Booking Update</h4>
                </div>
                <div class="modal-body pb-3">
                    <div
                        v-for="option in RecurringBookingUpdateBehaviourOptions"
                    >
                        <div
                            :class="[
                                'me-2 card shadow-none border border-2 rounded-3',
                                recurringBookingUpdateBehaviour === option.value
                                    ? 'border-primary'
                                    : 'border-light',
                            ]"
                        >
                            <div class="card-body p-2">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        :value="option.value"
                                        v-model="
                                            recurringBookingUpdateBehaviour
                                        "
                                    />
                                    <label class="form-check-label">
                                        {{ option.description }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <Button class="btn btn-secondary" @click="close">
                        Close
                    </Button>
                    <Button class="btn btn-primary" @click="applyChanges">
                        Update Bookings
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRef, watch } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Button from "@components/Button.vue";
import {
    RecurringBookingUpdateBehaviourOptions,
    RecurringBookingUpdateBehaviour,
} from "../../classes/Booking";

const modalId = ref("bookingFormReccurranceUpdateModal");

const modal = ref<null | bootstrap.Modal>(null);

const recurringBookingUpdateBehaviour = ref<RecurringBookingUpdateBehaviour>(
    RecurringBookingUpdateBehaviour.SaveForAllFutureBookings
);

const props = withDefaults(
    defineProps<{
        show?: boolean;
    }>(),
    {
        show: false,
    }
);

const emit = defineEmits<{
    (e: "update:show", value: boolean): void;
    (
        e: "recurringBookingUpdateBehaviour",
        value: RecurringBookingUpdateBehaviour
    ): void;
}>();

const show = toRef(props, "show");

const applyChanges = () => {
    emit(
        "recurringBookingUpdateBehaviour",
        recurringBookingUpdateBehaviour.value
    );
    emit("update:show", false);
    // modal.value?.hide();
};

const close = () => {
    emit("update:show", false);
};

const initModal = () => {
    const modalElement = document.getElementById(modalId.value);
    if (!modalElement) {
        return;
    }
    modal.value = new bootstrap.Modal(modalElement, {});
};

watch(
    () => show.value,
    (newValue) => {
        if (newValue && modal.value) {
            modal.value?.show();
        } else {
            modal.value?.hide();
        }
    }
);

onMounted(() => {
    initModal();
});
</script>
