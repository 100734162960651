<template>
    <div>
        <div id="googleLoginButton"></div>
        <form :action="this.route('auth.login.google.callback')" method="post" id="g-login-form">
            <input type="hidden" value="" name="credential" id="g-login-credential">
            <input type="hidden" value="auto" name="select_by">
            <input type="hidden" :value="csrf" name="_token">
        </form>
    </div>
</template>

<script>
function handleCredentialResponse(response) {
    document.getElementById('g-login-credential').value = response.credential
    document.getElementById('g-login-form').submit()
}

export default {
    name: "GoogleLogin",
    props: {
        clientId: {
            required: true,
            type: String,
        },
        csrf: String
    },
    mounted() {
        google.accounts.id.initialize({
            client_id: this.clientId,
            callback: handleCredentialResponse
        });

        google.accounts.id.renderButton(
            document.getElementById('googleLoginButton'),
            {
                type: 'standard',
                size: 'large',
                theme: 'outline',
                text: 'sign_in_with',
                shape: 'rectangular',
                logo_alignment: 'left',
            }
        );
    }
}
</script>

<style scoped>

</style>
