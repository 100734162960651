<template>
    <BookingStopDropdown
        :label="
            selectedClient
                ? `Additional Details For ${selectedClient?.name}`
                : 'Additional Details'
        "
        :initiallyOpen="false"
        :disabled="bookingStore.draft.selectedClientUuid == null"
    >
        <template v-slot:content>
            <div class="p-3" v-if="selectedClient">
                <div class="row mb-2 mt-3">
                    <div class="col-md-6">
                        <label class="form-label"
                            >Attach Custom Reference Numbers:</label
                        >
                        <input
                            v-model="referenceNumber"
                            class="form-control"
                            placeholder="Type reference number"
                        />
                    </div>
                </div>

                <div class="row mb-2 mt-3">
                    <div class="col-md-4">
                        <label class="form-label">Booking Priority</label>
                        <select class="form-select" v-model="priority">
                            <option value="">-- Not Applicable --</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                    </div>
                </div>

                <label class="form-label mt-2">General Booking Notes</label>
                <textarea
                    v-model="bookingNotes"
                    :class="['form-control']"
                    placeholder="Stop Notes"
                ></textarea>

                <label class="form-label mt-3">Message for Driver</label>
                <textarea
                    v-model="notesForClient"
                    :class="['form-control']"
                    placeholder="Stop Notes"
                ></textarea>
                <div class="form-text">
                    A client comment will be displayed to the driver when the
                    booking is allocated to them.
                </div>

                <div class="my-3">
                    <label class="form-label">Referring agency</label>
                    <select v-model="agencyUuid" class="form-select">
                        <option value="" :selected="!agencyUuid">
                            -- Not Applicable --
                        </option>
                        <option v-for="agency in agencies" :value="agency.uuid">
                            {{ agency.name }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">Referring contact</label>
                    <select
                        v-model="agencyContactUuid"
                        class="form-select"
                        :disabled="!agencyUuid"
                    >
                        <option value="null" :selected="!agencyContactUuid">
                            -- Not Applicable --
                        </option>
                        <option
                            v-for="contact in agencyContactOptions ?? []"
                            :value="contact.uuid"
                        >
                            {{ contact.name }}
                        </option>
                    </select>
                </div>
            </div>
        </template>
    </BookingStopDropdown>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { uuid } from "vue-uuid";
import BookingStopDropdown from "./BookingStopDropdown.vue";
import { useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { BookingPriority } from "@classes/Booking";

const props = defineProps<{
    stopIndex: number;
}>();

const bookingStore = useBookingStore();

const { selectedClient, agencies } = storeToRefs(bookingStore);

const bookingNotes = bookingStore.getComputedForStopClientSpecificValue<string>(
    props.stopIndex,
    "notes",
    ""
);

const notesForClient =
    bookingStore.getComputedForStopClientSpecificValue<string>(
        props.stopIndex,
        "client_notes",
        ""
    );

const agencyUuid = bookingStore.getComputedForClientSpecificValue<string>(
    "agencyUuid",
    ""
);

const agencyContactUuid =
    bookingStore.getComputedForClientSpecificValue<string>(
        "agencyContactUuid",
        ""
    );

const selectedAgency = computed(() => {
    let result = agencies.value.find((i) => i.uuid == agencyUuid.value);
    return result;
});

const agencyContactOptions = computed(() => {
    const selectedAgencyContacts = selectedAgency.value?.contacts ?? [];

    const clientPresetContacts = selectedClient.value?.agency_contact
        ? [selectedClient.value?.agency_contact]
        : [];

    return [...selectedAgencyContacts, ...clientPresetContacts];
});

const priority = bookingStore.getComputedForStopClientSpecificValue<string>(
    props.stopIndex,
    "priority"
);

const referenceNumber =
    bookingStore.getComputedForStopClientSpecificValue<string>(
        props.stopIndex,
        "referenceNumber"
    );

const setDefaultAgency = () => {
    if (agencyUuid.value == "" && selectedClient.value?.agency) {
        agencyUuid.value = selectedClient.value?.agency.uuid;
    }

    if (agencyContactUuid.value == "" && selectedClient.value?.agency_contact) {
        agencyContactUuid.value = selectedClient.value?.agency_contact.uuid;
    }

    if (
        notesForClient.value == "" &&
        (selectedClient.value?.comments ?? "").length > 0
    ) {
        notesForClient.value = selectedClient.value?.comments ?? "";
    }

    if (!priority.value) {
        priority.value = BookingPriority.Medium;
    }
};

onMounted(() => {
    setDefaultAgency();
});

watch([selectedClient, agencies], () => {
    setDefaultAgency();
});
</script>
