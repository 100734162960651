<template>
    <div class="d-inline-block">
        <span
            v-if="!editing && !loading"
            @click="editing = !(loading || disabled)"
            :class="(loading || disabled) ? null : 'click-to-edit'"
        >
            <template v-if="value"><slot>{{ value }}</slot></template>
            <template v-else><small><em>Click to add</em></small></template>
        </span>
        <div class="d-flex align-items-center" v-if="editing || loading">
            <input
                v-focus
                v-model="fieldValue"
                :disabled="updating"
                type="date"
                class="form-control form-control-sm"
            >
            <template v-if="!loading">
                <a href="javascript:void(0);" class="px-1" @click="update">
                    <SvgIcon type="mdi" :path="mdiCheck" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
                <a href="javascript:void(0);" class="px-1" @click="editing = false">
                    <SvgIcon type="mdi" :path="mdiClose" class="position-relative w-4 h-4" style="top: -1px"/>
                </a>
            </template>
            <Spinner v-else class="ms-1" type="border" size="small"></Spinner>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditableDate"
}
</script>
<script setup>
import {ref, onBeforeMount} from "vue"
import Spinner from "../../Spinner.vue"
import SvgIcon from "@jamescoyle/vue-icon"
import { mdiCheck, mdiClose } from "@mdi/js"
import { DateTime } from "luxon"

const fieldValue = ref(null)
const props = defineProps({
    value: String,
    loading: Boolean,
    disabled: Boolean,
    updating: Boolean
})

const emit = defineEmits(['updated'])

let editing = ref(false)
const update = function () {
    editing.value = false
    emit('updated', fieldValue.value)
}

onBeforeMount(() => {
    fieldValue.value = DateTime.fromISO(props.value).toFormat('yyyy-MM-dd')
})

</script>
<style scoped>

</style>
