<template>
    <BookingStopDropdown
        :label="
            selectedClient
                ? `Requirements For ${selectedClient?.name}`
                : 'Requirements'
        "
        :initiallyOpen="false"
        :disabled="bookingStore.draft.selectedClientUuid == null"
    >
        <template v-slot:content v-if="selectedClient">
            <div class="p-1">
                <BookingFormTransportRequirement
                    :booking-requirements="bookingRequirements"
                    @saving="saving"
                ></BookingFormTransportRequirement>
            </div>
        </template>
    </BookingStopDropdown>
</template>

<script setup lang="ts">
import {ref, computed, watch, onMounted, inject} from "vue";
import { useBookingStore } from "@stores/BookingStore";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import BookingStopDropdown from "./BookingStopDropdown.vue";
import { storeToRefs } from "pinia";
import EditTransportRequirements from "@components/Clients/EditTransportRequirements.vue";
import BookingRequirement from "@classes/BookingRequirement";
import BookingFormTransportRequirement from "@components/BookingForm/BookingFormTransportRequirement.vue";
const axios = inject('axios');
const toast = inject('toast');

const props = defineProps<{
    stopIndex: number;
}>();

const bookingStore = useBookingStore();
const bookingRequirementStore = useBookingRequirementStore();

const { selectedClient, bookingEdit, draft } = storeToRefs(bookingStore);
const { chosenSeat, selectedCargoSpaces, selectedAssistanceTypes } = storeToRefs(bookingRequirementStore);

const bookingRequirements = ref({
    chosen_seat: '',
    vehicle_types: [],
    assistance_types: [],
    cargo_spaces: [],
    client_uuid: '',
});

const VehicleConfigurationOptions =
    bookingStore.getComputedForStopClientSpecificValue(
        props.stopIndex,
        "vehicleConfigurationOptions",
        []
    );

const additionalTimeNeeded = bookingStore.getComputedForStopClientSpecificValue(
    props.stopIndex,
    "additionalTimeNeeded",
    0
);

const maximumTransitTime = bookingStore.getComputedForStopClientSpecificValue(
    props.stopIndex,
    "maximumTransitTime",
    0
);

const seatsNeeded = bookingStore.getComputedForStopClientSpecificValue(
    props.stopIndex,
    "maximumTransitTime",
    1
);

const getBookingRequirements = () => {
    const thisBookingStop = draft.value.stops[props.stopIndex];

    // 1 stop contains multiple clients requirement. listed as array
    // for whatever the reason is, the first stop (index 0) is always for pickup place. so it start with index 1
    if (props.stopIndex === 0) {
        return;
    }

    // if already fetched with client default use draft
    // at this point if the booking is edit, it will also has this already
    if (thisBookingStop !== undefined) {
        const clientBookingRequirementForStop = thisBookingStop.bookingRequirements.find(br => br.client_uuid === selectedClient.value.uuid);

        if (clientBookingRequirementForStop !== undefined) {
            bookingRequirements.value = clientBookingRequirementForStop.value;
            return;
        }
    }

    // fall back is get client default requirements
    BookingRequirement.getClientDefaultBookingRequirement(selectedClient.value.uuid)
        .then((response) => {
            bookingRequirements.value = response.data.data;
            bookingRequirements.value.client_uuid = selectedClient.value.uuid;
            bookingStore.updateBookingRequirementByIndex(props.stopIndex, bookingRequirements.value);
        });
}

const updateBookingRequirementByIndex = () => {
    bookingStore.updateBookingRequirementByIndex(props.stopIndex, bookingRequirements.value);
}

const saving = (data) => {
    bookingRequirements.value = data;
    bookingRequirements.value.client_uuid = selectedClient.value.uuid;

    updateBookingRequirementByIndex();
}

onMounted(() => {
    // after giving await on BookingForm.vue, this still failed in vehicle types,
    // most likely in rendering, so we wait for another second after to make sure everything loaded
    // if not, it will not loaded properly for booking edit
    setTimeout(() => {
        getBookingRequirements();
    }, 3000);
});

watch((selectedClient), (currentClient) => {
    getBookingRequirements();
})
</script>
