<template>
    <div>
        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <VueMultiselect
            v-if="driverOptions.length > 0 && !loading"
            :id="id"
            v-model='selectedDriver'
            :custom-label="driverName"
            :disabled="disabled"
            :options="driverOptions"
            :placeholder="placeholder"
            :select-label="''"
            label="Driver"
            track-by="uuid"
        >
            <template v-slot:singleLabel="props">
                <div class="option__desc">
                    <div class="d-flex">
                        <DriverAvatar :driver="props.option" size="small"/>
                        <div class="ms-2">
                            <div class="fs-5">{{ props.option.name }}</div>
                            <div class="mt-1 small d-flex flex-wrap">
                                <template v-for="cert in props.option.current_certificates">
                                    <span class="badge bg-light text-dark me-1 mb-1">{{ cert.label }}</span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:option="props">
                <div class="option__desc">
                    <div class="d-flex">
                        <DriverAvatar :driver="props.option" size="small"/>
                        <div class="ms-2">
                            <div class="fs-5">{{ props.option.name }}</div>
                            <div class="mt-1 small d-flex flex-wrap">
                                <template v-for="cert in props.option.current_certificates">
                                    <span class="badge bg-light text-dark me-1 mb-1">{{ cert.label }}</span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </VueMultiselect>
        <span v-if="driverOptions.length === 0">No drivers are available for the selected run times.</span>
    </div>
</template>

<script>
export default {
    name: "DriverSearchSelect"
}
</script>
<script setup>
import VueMultiselect from 'vue-multiselect'
import axios from "axios";
import {onBeforeMount, ref, computed, toRef} from "vue";
import DriverAvatar from "../Drivers/DriverAvatar.vue";

const props = defineProps({
    modelValue: Object,
    id: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Select driver...',
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    availableFrom: {
        type: Number,
        required: false,
        default: null,
    },
    availableUntil: {
        type: Number,
        required: false,
        default: null,
    },
});

let modelValueProps = toRef(props, "modelValue");

const emit = defineEmits(['update:modelValue'])

const selectedDriver = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const loading = ref(true)
const drivers = ref([])
const getDrivers = function () {
    axios
        .get(route('api.drivers.index', {
            paginate: false,
            active: true,
            available_from: props.availableFrom,
            available_until: props.availableUntil,
        }))
        .then((response) => {
            drivers.value = response.data.data
        })
        .catch((err) => console.error(err))
        .finally(() => {
            loading.value = false
        })
}

const driverOptions = computed(() => {
    let options = [...drivers.value];
    if (
        !options.find((driver) => driver.uuid == modelValueProps.value?.uuiu) &&
        modelValueProps.value?.uuid
    ) {
        options = [...drivers.value, modelValueProps?.value];
    }
    return options;
});

const driverName = function (driver) {
    return driver.name
}

onBeforeMount(() => {
    getDrivers()
})

</script>

<style scoped>

</style>
