<template>
    <div class="card">
        <div class="card-body">
            <template v-if="loaded">
                <div class="d-flex flex-wrap">
                    <div class="flex-grow-1">
                        <h4 class="header-title mb-1">Vehicle Runs
                            <a v-if="!reloading" v-tooltip="'Refresh Vehicle Runs'" href="javascript: void(0)"
                               @click="updateJourneys">
                                <i class="mdi mdi-refresh"></i>
                            </a>
                            <div v-if="reloading" class="spinner-border spinner-border-sm text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </h4>
                        <a href="javascript: void(0)" @click="expandAll = !expandAll" class="text-sm">
                            <template v-if="!expandAll">Expand all</template>
                            <template v-if="expandAll">Collapse all</template>
                        </a>
                    </div>
                    <div class="flex-shrink-1 text-end">
                        <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                            <button
                                class="btn btn-light btn-sm dropdown-toggle"
                                type="button"
                                id="dropDownFilterRegions"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="mdiFilter"
                                    style="max-width: 16px"
                                ></SvgIcon>
                                Filters
                            </button>
                            <div class="dropdown-menu px-4 py-3" aria-describedby="dropDownFilterRegions">
                                <label class="form-label mb-2">
                                    Time
                                    <a href="javascript: void(0)"
                                       data-bs-container="body"
                                       v-popover
                                       data-bs-trigger="focus"
                                       data-bs-html="true"
                                       :data-bs-content="'Vehicle runs will be shown when any part of the run occurs within this time window.'"
                                       data-bs-placement="top">
                                        <SvgIcon type="mdi" :path="mdiInformation"></SvgIcon>
                                    </a>
                                </label>
                                <div class="region-filter-grid">
                                    <div>
                                        <label for="starttime" class="form-label">Start Time</label>
                                        <input v-model="timeFilterStart" class="form-control" type="time"
                                               id="starttime" min="00:00" max="24:00">
                                    </div>
                                    <div>
                                        <label for="endtime" class="form-label">End Time</label>
                                        <input v-model="timeFilterEnd" class="form-control" type="time"
                                               id="endtime" min="00:00" max="24:00">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-shrink-1 text-end">
                        <div class="input-group input-group-sm">
                            <select v-model="filterType" class="form-select" style="flex: 0 0 40%;">
                                <option value="driver">Driver</option>
                                <option value="client">Client</option>
                            </select>
                            <input v-model="filterString" type="search" class="form-control" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="journey-tree-view">
                    <template v-for="depot in filteredDepots">
                        <DepotRow
                            v-if="depot.journeys.filter((journey) => journey?.stops?.length > 2).length > 0"
                            v-bind:key="depot.id + '_' + depot.journeys.length"
                            v-bind:depot="depot"
                            :expand="expandAll"
                            @bookingReallocated="updateJourneys"
                        ></DepotRow>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading Vehicle Runs</div>
                </div>
            </template>
        </div>
        <DeallocateBookingModal :toggle-details-modal="true" />
    </div>
</template>

<script setup lang="ts">
import { ref, inject, watch, computed, onBeforeMount, onMounted} from 'vue'
import DepotRow from "./DepotRow.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiFilter, mdiInformation} from "@mdi/js";
import DeallocateBookingModal from '@components/Bookings/RescheduleBooking/DeallocateBookingModal.vue'
import { copyValues } from '../Utils.js'
import { useRegionStore } from "@stores/RegionStore";
import { storeToRefs } from "pinia";
import { useDispatchStore } from "@stores/DispatchStore";
import axios from "axios";
import moment from "moment";
const toast: any = inject('toast');

const regionStore = useRegionStore();
const dispatchStore = useDispatchStore();

const { selectedRegion } = storeToRefs(regionStore);
const { journeys: persistedJourneys } = storeToRefs(dispatchStore);

const rescheduleBooking = inject<any>('rescheduleBooking');

const yieldedJourneys = ref<App.Models.Journey[]>([]);

const expandAll = ref(false)
const filterType = ref('driver')
const filterString = ref('')

const timeFilterStart = ref('')
const timeFilterEnd = ref('')

const reloading = ref(false);
const loaded = ref(false);

watch(rescheduleBooking.getUpdatedBooking, (updatedBooking) => {
    if (updatedBooking) {
        updateJourneys()
    }
});

const journeys = computed(() => {
    return (persistedJourneys?.value ?? []).length > 0 ? persistedJourneys.value : yieldedJourneys.value;
});

const updateJourneys = () => {
    reloading.value = true;
    loaded.value = journeys.value.length > 0;
    axios
        .get(route('api.journeys.index'), {
            params: {
                'date': moment().format('YYYY-MM-DD'),
                'with_stops': 1,
            }
        }).then((response) => {
            if (Array.isArray(response?.data?.data)) {
                persistedJourneys.value = response?.data?.data;
                yieldedJourneys.value = response?.data?.data;
            }
            loaded.value = true;
            dispatchStore.setJourneyDriverVehicle(journeys.value);
        })
        .catch((error) => {
            toast.error("There was an error refreshing vehicle runs for the day, please try again.");
            console.error(error.response);
        })
        .finally(() => {
            reloading.value = false
        })
}

const regions = ref([])
const getRegions = async () => {
    regions.value = await regionStore.sync();
}

const getMappedDepots = () => {
    let depots: any[] = []

    journeys.value.forEach((journey) => {
        let depotId = journey?.vehicle?.depot ? journey?.vehicle?.depot?.uuid : '1'
        let depot = depots.find((depot) => depot.id === depotId)

        if (typeof depot !== 'undefined') {
            depot.journeys.push(journey)
            return
        }

        depots.push({
            id: depotId,
            //@ts-ignore
            address: journey?.vehicle?.depot ? journey?.vehicle?.depot?.formatted_address : 'No depot address',
            name: journey?.vehicle?.depot?.name,
            journeys: [copyValues(journey)],
        });
    })

    return depots
}

const filteredDepots = computed(() => {
    let depots = getMappedDepots()
    if (regionFilter.value.length > 0) {
        depots = depots.map((depot) => {
            depot.journeys = depot.journeys.filter((journey) => {
                return regionFilter.value.includes(journey.region_id)
            })

            return depot
        })

        depots = depots.filter((depot) => depot.journeys.filter((journey) => journey.stops.length > 2).length > 0)
    }

    if (timeFilterStart.value || timeFilterEnd.value) {
        let filterStart = timeFilterStart.value ? moment(timeFilterStart.value, 'HH:mm') : null
        let filterEnd = timeFilterEnd.value ? moment(timeFilterEnd.value, 'HH:mm') : null

        depots = depots.map((depot) => {
            depot.journeys = depot.journeys.filter((journey) => {

                let today = moment().format('YYYY-MM-DD')

                let journeyStart = moment(today, 'YYYY-MM-DD')
                    .add(moment(journey.start_time).hours(), 'hours')
                    .add(moment(journey.start_time).minutes(), 'minutes')

                let journeyEnd = moment(today, 'YYYY-MM-DD')
                    .add(moment(journey.end_time).hours(), 'hours')
                    .add(moment(journey.end_time).minutes(), 'minutes')

                if (filterStart && filterEnd === null) {
                    return filterStart.isBetween(journeyStart, journeyEnd) || journeyStart.isAfter(filterStart)
                }

                if (filterStart === null && filterEnd) {
                    return filterEnd.isBetween(journeyStart, journeyEnd) || journeyStart.isBefore(filterEnd)
                }

                return !(journeyStart.isAfter(filterEnd) || journeyEnd.isBefore(filterStart))
            })

            return depot
        })

        depots = depots.filter((depot) => depot.journeys.filter((journey) => journey.stops.length > 2).length > 0)
    }

    if (filterString.value.trim().length === 0) {
        return depots
    }

    depots = depots.map((depot) => {
        const query = filterString.value.trim().toLowerCase().split(" ").join("|");
        const regex = new RegExp(query, "ig");
        const required = 1; // change to filterString.value.trim().toLowerCase().split(" ").length if we want AND query;
        depot.journeys = depot.journeys.filter((journey) => {
            if (filterType.value === 'driver' && journey?.driver?.name?.toLowerCase()?.match(regex)?.length >= required ) {
                return true;
            }
            return !!(filterType.value === 'client' && journey?.bookings?.some(booking => booking && booking.client?.full_name?.toLowerCase().match(regex)?.length >= required));
        })

        return depot
    })

    return depots.filter((depot) => depot.journeys.length > 0)
})

const regionFilter = computed(() => {
    let regions: any[] = [];

    regions.map((region) => {
        return region.enabled ? regions.push(region.id) : null
    })

    return regions ?? null;
})

watch(
    selectedRegion,
    () => {
        updateJourneys();
    }
)

onBeforeMount(() => {
    getRegions()
})

onMounted(() => {
    updateJourneys()
})

</script>

<style scoped>

</style>
