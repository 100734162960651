<template>
    <div :key="formRefreshKey">
        <VehicleRunStatusDropdown
            v-if="updating"
            :run-updating="runUpdating"
            :vehicle-run-statuses="vehicleRunStatuses"
            @cancelled="emit('cancelled', $event)"
        />
        <div class="row">
            <div class="col-md-4">
                <FormDateTimeInput
                    v-model:input="run.start_time"
                    label="Departure time"
                    placeholder="hh:mm"
                    @update:input="updateEarliestTime"
                />
            </div>
            <div class="col-md-4">
                <FormDateTimeInput
                    v-model:input="run.end_time"
                    label="Return time"
                    placeholder="hh:mm"
                    @update:input="updateLatestTime"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <FormDateTimeInput
                    v-model:input="run.earliest_time"
                    label="Earliest departure time"
                    placeholder="hh:mm"
                    :key="earliestTimeKey"
                />
            </div>
            <div class="col-md-4">
                <FormDateTimeInput
                    v-model:input="run.latest_time"
                    label="Latest Return time"
                    placeholder="hh:mm"
                    :key="latestTimeKey"
                />
            </div>
        </div>
        <div v-if="updating && dateHasChanged" class="row">
            <div class="col-md-12 p-2">
                <div class="border rounded p-2">
                    Date has been changed. Bookings and driver will automatically unassigned to the run when saved.
                </div>
            </div>
        </div>
        <div class="mt-3 row">
            <div class="col-md-8">
                <div class="form-check">
                    <input
                        id="rescheduleCheckbox"
                        v-model="showRescheduleOptions"
                        class="form-check-input"
                        type="checkbox"
                        value=""
                    />
                    <label class="form-check-label" for="rescheduleCheckbox">
                        Reschedule this vehicle run
                    </label>
                </div>
                <div
                    v-if="showRescheduleOptions"
                    class="border rounded p-3 mt-2"
                >
                    <RescheduleOptions
                        v-model="run.reschedule"
                        :errors="errors"
                        event-type="vehicle run"
                    />
                </div>
            </div>
        </div>
        <hr class="border-top mt-3" />
        <div>
            <label class="form-label required-label" for="run_vehicle"
                >Vehicle</label
            >
            <VehicleSearchInput
                id="run_vehicle"
                v-model="run.vehicle"
                :disabled="props.vehicle ? true : false"
                :error="vehicleError"
            />
        </div>
        <div class="form-check mt-3">
            <input
                id="vehicleDepartureCheck"
                v-model="run.use_depot_as_departure_location"
                class="form-check-input"
                type="checkbox"
                value=""
            />
            <label class="form-check-label" for="vehicleDepartureCheck">
                Use vehicle depot as departure / return location
            </label>
        </div>
        <div v-if="!run.use_depot_as_departure_location" class="mt-2 row">
            <div class="col-md-6">
                <AddresDbSearch
                    :key="JSON.stringify(run.departure_location)"
                    v-model="run.departure_location"
                    label="Departure Address"
                    optional
                />
            </div>
            <div class="col-md-6">
                <AddressDbSearch
                    :key="JSON.stringify(run.return_location)"
                    v-model="run.return_location"
                    label="Return Address"
                    optional
                />
            </div>
        </div>
        <hr class="border-top mt-3" />
        <DriverRecordCheckAlert :driver="run.driver"></DriverRecordCheckAlert>
        <div class="mt-3 row">
            <div class="col-md-6">
                <label class="form-label optional-label" for="run_driver"
                    >Driver</label
                >
                {{ run.driver?.given_names }}
                <DriverSearchSelect
                    id="run_driver"
                    key="run_driver"
                    v-model="run.driver"
                    placeholder="Select driver"
                    :disabled="!canSelectDriver"
                    :available-from="runStartTime"
                    :available-until="runEndTime"
                />
            </div>
        </div>
        <div class="mt-3">
            <DriverBreaks
                v-model="run.breaks"
                :runStartTime="run.start_time.value ? DateTime.fromISO(run.start_time.value) : undefined"
                :runEndTime="run.end_time.value ? DateTime.fromISO(run.end_time.value): undefined"
            />
        </div>

        <div class="mt-3">
            <FormTextarea
                v-model:input="run.notes"
                label="Notes for the driver"
                placeholder="e.g. Avoid the festival in the CBD"
            />
        </div>
        <div class="mt-3 row">
            <div class="col-md-6">
                <label
                    class="form-label optional-label"
                    for="run_additional_crew"
                    >Additional crew</label
                >
                <DriverSearchSelect
                    id="run_additional_crew"
                    key="run_additional_crew"
                    v-model="run.additional_crew"
                    placeholder="Select additional crew member"
                />
            </div>
        </div>
        <hr class="border-top mt-3" />
        <div class="mt-3">
            <FormSelect
                v-if="regions.length > 0"
                v-model:selection="run.region"
                :options="regions"
                :validation="
                    yup
                        .string()
                        .oneOf(
                            getSelectValues(regions),
                            'Please select a region from the list'
                        )
                        .required()
                "
                label="Region"
                placeholder="Select a region..."
            />
        </div>
        <div class="mt-3">
            <FormTextInput
                v-model:input="run.description"
                label="Run Description"
                placeholder="e.g. Weekend Market Outing"
            />
        </div>
        <div
            class="mt-3 d-flex align-items-center"
            :class="[
                creating ? 'justify-content-end' : 'justify-content-between',
            ]"
        >
            <template v-if="updating">
                <template v-if="!runHasBookings">
                    <Button
                        v-if="!confirmDelete"
                        :loading="deleting"
                        @click="confirmDelete = true"
                        color="outline-danger"
                        >Delete</Button
                    >
                    <div v-else class="d-flex align-items-center">
                        <p class="m-0 me-2">Are you sure?</p>
                        <Button
                            :loading="deleting"
                            @click="deleteRun"
                            color="danger"
                            >Yes</Button
                        >
                    </div>
                </template>
                <template v-else>
                    <small
                        >To delete this vehicle run, you will need to
                        de-allocate all bookings first.</small
                    >
                </template>
            </template>
            <Button :loading="storing" @click="store"
                >{{ mode }} Vehicle Run</Button
            >
        </div>
    </div>
</template>

<script setup>
import {
    FormTextInput,
    FormSelect,
    FormDateTimeInput,
    FormTextarea,
    FormTimeInput,
} from "../../Forms";
import VehicleSearchInput from "../parts/VehicleSearchInput.vue";
import { ref, reactive, onMounted, inject, watch, computed } from "vue";
import axios from "axios";
import * as yup from "yup";
import { getSelectValues } from "../../Utils";
import AddressDbSearch from "../../AddressDbSearch.vue";
import RescheduleOptions from "../../RescheduleOptions.vue";
import DriverSearchSelect from "../../Drivers/DriverSearchSelect.vue";
import DriverRecordCheckAlert from "../../Drivers/DriverRecordCheckAlert.vue";
import VehicleRunStatusDropdown from "./VehicleRunStatusDropdown.vue";
import Button from "../../Button.vue";
import { DateTime } from "luxon";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusThick, mdiMinusThick } from "@mdi/js";
import AddresDbSearch from "../../AddressDbSearch.vue";
import DriverBreaks from "./DriverBreaks.vue";
import DatetimeHelper from "@classes/DateHelpers/DatetimeHelper";
import { useRegionStore } from "@stores/RegionStore";

const regionStore = useRegionStore();

const props = defineProps({
    date: {
        type: String,
        required: false,
    },
    vehicle: {
        type: Object,
        required: false,
    },
    startTime: {
        type: String,
        required: false,
    },
    endTime: {
        type: String,
        required: false,
    },
    earliestTime: {
        type: String,
        required: false,
    },
    latestTime: {
        type: String,
        required: false,
    },
    runUpdating: {
        type: Object,
        required: false,
    },
    mode: {
        type: String,
        required: true,
    },
    vehicleRunStatuses: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(["created", "updated", "deleted", "cancelled"]);
const earliestTimeKey = ref(0);
const latestTimeKey = ref(0);

const forceRerenderEarliestTime = () => {
    earliestTimeKey.value += 1;
};

const forceRerenderLatestTime = () => {
    latestTimeKey.value += 1;
};

import { toTimeStamp } from "../../Utils.js";

const runHasBookings = computed(() => {
    if (updating.value) {
        return (
            props.runUpdating.bookings && props.runUpdating.bookings.length > 0
        );
    }

    return false;
});

let run = reactive({
    uuid: null,
    vehicle: props.vehicle ?? null,
    driver: null,
    breaks: [],
    additional_crew: null,
    start_time: {
        value: props.startTime ?? null,
        errors: [],
        required: true,
    },
    end_time: {
        value: props.endTime ?? null,
        errors: [],
        required: true,
    },
    earliest_time: {
        value: props.earliestTime ?? null,
        errors: [],
        required: false,
    },
    latest_time: {
        value: props.latestTime ?? null,
        errors: [],
        required: false,
    },
    use_depot_as_departure_location: true,
    departure_location: null,
    return_location: null,
    description: {
        value: "",
        errors: [],
    },
    notes: {
        value: "",
        errors: [],
    },
    region: {
        value: props.vehicle
            ? props.vehicle?.region?.uuid
            : window.selected_region
            ? window.selected_region.uuid
            : null,
        errors: [],
        required: true,
    },
    reschedule: {
        period: "day",
        frequency: "1",
        weekdays: [],
        monthDays: [],
        monthly: "exact",
        end: DateTime.now().plus({ months: 2 }).toSeconds().toFixed(0),
        dynamicMonthDay: "1",
        dynamicMonthWeekday: "day",
        continuous: false,
    },
});

const errors = ref({});

const vehicleError = ref("");

const showRescheduleOptions = ref(false);

watch(run, (newRun, oldRun) => {
    if (newRun?.vehicle?.uuid == oldRun?.vehicle?.uuid) {
        return;
    }
    run.departure_location = vehicle.depot;
    run.return_location = vehicle.depot;
});

const runStartTime = computed(() => {
    if (run.start_time.value) {
        return DateTime.fromISO(run.start_time.value).toSeconds();
    }

    return null;
});

const runEndTime = computed(() => {
    if (run.end_time.value) {
        return DateTime.fromISO(run.end_time.value).toSeconds();
    }

    return null;
});

const updating = computed(() => props.mode === "Update");
const creating = computed(() => props.mode === "Create");

const storing = ref(false);
const store = function () {
    storing.value = true;

    if (creating.value) {
        axios
            .post(route("api.journeys.store"), getParams())
            .then((response) => {
                toast.success(
                    "New run created for **" +
                        run.vehicle.description +
                        " " +
                        run.vehicle.registration_code +
                        "**"
                );
                emit("created", response.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                errors.value = error.response.data.errors;
            })
            .finally(() => {
                storing.value = false;
            });
    } else {
        axios
            .patch(
                route("api.journeys.update", {
                    journey: props.runUpdating.uuid,
                }),
                getParams()
            )
            .then((response) => {
                toast.success(
                    "Run updated for **" +
                        run.vehicle.description +
                        " " +
                        run.vehicle.registration_code +
                        "**"
                );
                emit("updated", response.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                errors.value = error.response.data.errors;
            })
            .finally(() => {
                storing.value = false;
            });
    }
};

const validateRequiredField = () => {
    let isValid = true;

    if (!run.start_time.value) {
        run.start_time.errors.push("Please enter a departure time");
        isValid = false;
    }

    if (!run.end_time.value) {
        run.end_time.errors.push("Please enter a return time");
        isValid = false;
    }

    if (!run.vehicle) {
        vehicleError.value = "Please select the vehicle";
        isValid = false;
    }

    return isValid;
};

const getParams = () => {
    if (!validateRequiredField()) {
        return;
    }

    let params = {
        uuid: run.uuid ?? null,
        start_time: DateTime.fromISO(run.start_time.value, {zone: window.timezone}).toSeconds(),
        end_time: DateTime.fromISO(run.end_time.value, {zone: window.timezone}).toSeconds(), //toTimeStamp(run.end_time.value),
        earliest_time: run.earliest_time.value ? DateTime.fromISO(run.earliest_time.value, {zone: window.timezone}).toSeconds() : null,
        latest_time: run.latest_time.value ? DateTime.fromISO(run.latest_time.value, {zone: window.timezone}).toSeconds() : null,
        vehicle: run.vehicle.uuid,
        departure_location: run.departure_location?.uuid ?? null,
        return_location: run.return_location?.uuid ?? null,
        driver: run.driver ? run.driver.uuid : null,
        breaks: run.breaks,
        notes: run.notes.value,
        additional_crew: run.additional_crew
            ? [run.additional_crew.uuid]
            : [],
        region: run.region.value,
        description: run.description.value,
        recursion: showRescheduleOptions.value ? run.reschedule : {},
    };

    return params;
};

const toast = inject("toast");
const regions = ref([]);
const getRegions = async () => {
    let fullRegions = await regionStore.sync();
    regions.value = fullRegions.map((region) => {
        return {
            value: region.uuid,
            label: region.name,
        };
    });
};

const formRefreshKey = ref(Date.now());
const editRun = () => {
    run = reactive({
        uuid: props.runUpdating.uuid,
        vehicle: props.vehicle ?? null,
        breaks: props.runUpdating.breaks != undefined ?
            Object.values(props.runUpdating.breaks).map((break_) => {
                return {
                    start_time: toTimeStamp(break_.start_time),
                    end_time: toTimeStamp(break_.end_time),
                };
            }) :
            [],
        start_time: {
            value: props.startTime,
            errors: [],
            required: true,
        },
        end_time: {
            value: props.endTime,
            errors: [],
            required: true,
        },
        earliest_time: {
            value: props.earliestTime ?? null,
            errors: [],
            required: false,
        },
        latest_time: {
            value: props.latestTime ?? null,
            errors: [],
            required: false,
        },
        use_depot_as_departure_location:
            props.runUpdating.departure_location?.id ===
                props.runUpdating.vehicle?.depot?.id &&
            props.runUpdating.return_location?.id ===
                props.runUpdating.vehicle?.depot?.id,
        departure_location: props.runUpdating.departure_location,
        return_location: props.runUpdating.return_location,
        description: {
            value: props.runUpdating.description,
            errors: [],
        },
        notes: {
            value: props.runUpdating.notes,
            errors: [],
        },
        region: {
            value: props.runUpdating.region?.uuid ?? null,
            errors: [],
        },
        reschedule: {
            period: "day",
            frequency: "1",
            weekdays: {},
            monthDays: {},
            monthly: "exact",
            end: DateTime.now().plus({ year: 1 }).toISODate(),
            dynamicMonthDay: "1",
            dynamicMonthWeekday: "day",
            continuous: true,
        },
    });

    getDriver()
        .then(() => {
            getAdditionalCrew();
        })
        .finally(() => {
            formRefreshKey.value = Date.now();
        });
};

const getDriver = () => {
    return new Promise((resolve, reject) => {
        if (!props.runUpdating.driver) {
            resolve();
        }

        axios
            .get(
                route("api.drivers.show", {
                    driver: props.runUpdating.driver.uuid,
                })
            )
            .then((response) => {
                run.driver = response.data;
                resolve();
            })
            .catch((error) => {
                toast.error(
                    "An error occurred while retrieving the Driver, please try again later."
                );
                reject(error);
            });
    });
};

const getAdditionalCrew = () => {
    return new Promise((resolve, reject) => {
        if (!props.runUpdating.additional_crew?.length) {
            resolve();
        }

        axios
            .get(
                route("api.drivers.show", {
                    driver: props.runUpdating.additional_crew[0].uuid,
                })
            )
            .then((response) => {
                run.additional_crew = response.data;
                resolve();
            })
            .catch((error) => {
                toast.error(
                    "An error occurred while retrieving the Additional Crew, please try again later."
                );
                reject(error);
            });
    });
};

const getBreakTime = (key) => {
    if (updating.value && props.runUpdating.breaks.length > 0) {
        return DateTime.fromISO(props.runUpdating.breaks[0][key]).toISO({
            includeOffset: false,
            suppressMilliseconds: true,
            suppressSeconds: true,
        });
    }

    return null;
};

const confirmDelete = ref(false);
const deleting = ref(false);
const deleteRun = () => {
    deleting.value = true;
    axios
        .delete(
            route("api.journeys.destroy", { journey: props.runUpdating.uuid })
        )
        .then(() => {
            emit("deleted", props.runUpdating.uuid);
            toast.success("Vehicle run deleted successfully.");
        })
        .catch((error) => {
            if (error.response.status === 422) {
                toast.error(error.response.data.message);
            } else {
                toast.error("An error occurred, please try again later.");
            }
        })
        .finally(() => {
            deleting.value = false;
        });
};

const updateEarliestTime = () => {
    // only on creation because some company adjust 15 mins before departure after optimiser comes back
    // and it shouldn't affect earliest time
    if (creating.value) {
        run.earliest_time.value = run.start_time.value;
        forceRerenderEarliestTime();
    } else {
        checkDateHasChanged();
    }
};

const updateLatestTime = () => {
    // same case as updateEarliestTime
    if (creating.value) {
        run.latest_time.value = run.end_time.value;
        forceRerenderLatestTime();
    } else {
        checkDateHasChanged();
    }
};

const dateHasChanged = ref(false);
const checkDateHasChanged = () => {
    if (DatetimeHelper.toLocalDate(props.startTime) != DatetimeHelper.toLocalDate(run.start_time.value) ||
        DatetimeHelper.toLocalDate(props.endTime) != DatetimeHelper.toLocalDate(run.end_time.value)
    ) {
        dateHasChanged.value = true;
        return;
    }

    dateHasChanged.value = false;
}


onMounted(() => {
    getRegions();

    if (creating.value && props.vehicle) {
        run.departure_location = run.vehicle.depot;
        run.return_location = run.vehicle.depot;
    } else if (updating.value) {
        editRun();
    }
});

const canSelectDriver = computed(() => {
    if (!run.start_time.value || !run.end_time.value) {
        return false;
    }

    if (!run.vehicle) {
        return false;
    }

    return true;
});
</script>

<style scoped></style>
