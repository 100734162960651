<template>
    <template v-if="modalKey > 0">
        <ClientCarerModal
            :key="modalKey"
            :action="modalAction"
            :client="props.client"
            :carer="selectedCarer"
            @created="carerCreated"
            @updated="carerUpdated"
            @closed="modalClosed()"
        />
    </template>
    <DeleteModal
        :uuid="carerToDelete"
        :label-type="'carer'"
        @commit="deleteCarer"
        @closed="deleteModalClosed()"
    />
    <div class="d-flex justify-content-between mb-3">
        <div>
            <h4 class="header-title m-0">Carers</h4>
            Manage the details of people caring the client on their trip.
        </div>
        <Button size="small" @click="showModal('create')">
            <SvgIcon type="mdi" :path="mdiPlusCircle" class="w-4 h-4 me-1"></SvgIcon>
            Add Client Carer
        </Button>
    </div>
    <div>
        <table class="table table-centered table-striped table-xs nowrap w-100 text-sm">
            <thead>
                <tr class="small">
                    <th rowspan="1">Name</th>
                    <th>Relationship</th>
                    <th>Primary Phone Number</th>
                    <th>Alternate Phone Number</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="carer in carers">
                    <td>{{carer.given_names}} {{carer.last_name}}</td>
                    <td>{{carer.relationship}}</td>
                    <td>{{carer.phone}}</td>
                    <td>{{carer.alt_phone}}</td>
                    <td class="td-shrink pe-2">
                        <div class="dropdown">
                            <button
                                :id="'actionItemsButton' + carer.uuid"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                            >
                                <SvgIcon
                                    type="mdi"
                                    :path="mdiDotsVertical"
                                    class="text-primary"
                                ></SvgIcon>
                            </button>
                            <div
                                class="dropdown-menu"
                                :aria-labelledby="'actionItemsButton' + carer.uuid"
                            >
                                <a
                                    class="dropdown-item"
                                    href="javascript: void(0)"
                                    @click="showModal('edit', carer)"
                                >Edit</a>
                                <a
                                    class="dropdown-item"
                                    href="javascript: void(0)"
                                    @click="showDeleteModal(carer)"
                                >Delete
                                </a>

                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import {inject, onMounted, ref, toRef} from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiDotsVertical, mdiOpenInNew, mdiPlusCircle} from "@mdi/js";
import Button from "@components/Button.vue";
import ClientCarer, { CarerInterface } from "@classes/ClientCarer";
import ClientCarerModal from "@components/Clients/ClientCarerModal.vue";
import DeleteModal from "@components/DeleteModal.vue";

const toast: any = inject("toast");
const props = withDefaults(
    defineProps<{
        client: App.Models.Client,
    }>(),
    {}
);
const modalAction = ref('create');
const selectedCarer = ref({});
const carerToDelete = ref('');
const carers = ref<CarerInterface[]>([]);
const modalKey = ref(0);

const showModal = (action, carer = {}) => {
    if (carer) {
        selectedCarer.value = carer;
    } else {
        selectedCarer.value = {};
    }
    modalAction.value = action;
    // when key is greater than 0 it will start recreating and show the modal if key changes
    modalKey.value++;
}

const modalClosed = () => {
    modalAction.value = '';
}

const deleteModalClosed = () => {
    carerToDelete.value = '';
}

const showDeleteModal = (carer = {}) => {
    if (carer) {
        carerToDelete.value = carer.uuid;
    }
}

const getCarers = async () => {
    carers.value = await ClientCarer.list(props.client.uuid);
}

const deleteCarer = (uuid: string) => {
    ClientCarer.delete(props.client.uuid, uuid).then((response) => {
        carers.value = carers.value.filter((carer) => { return carer.uuid != uuid; });
        toast.success('Carer is successfully deleted');
    });
}

const carerCreated = (createdCarer: CarerInterface) => {
    carers.value.push(createdCarer);
    modalClosed();
}

const carerUpdated = (updatedCarer: CarerInterface) => {
    const carerIndex = carers.value.findIndex((carer) => carer.uuid === updatedCarer.uuid);
    carers.value[carerIndex] = updatedCarer;
    modalClosed();
}

onMounted(() => {
    getCarers();
});
</script>

<style scoped>

</style>
