import axios, {AxiosResponse} from "axios";
import {ApiResourceResponseInterface} from "../types/LaravelCommonTypes";
import InvoiceResource from "@customTypes/resources/InvoiceResource";

export enum TransactionStatus {
    "Draft" = 'draft',
    "Unpaid" = 'unpaid',
    "Paid" = 'paid',
    "Cancelled" = 'cancelled',
    "Approved" = 'approved',
    "Void" = 'void',
}

export default class Transaction {
    static index = async (data: object): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> => {
        return await axios.get<ApiResourceResponseInterface<InvoiceResource[]>>(
            route('api.transactions.index', data)
        );
    };
    static create = async (data: object): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> => {
        return await axios.post<ApiResourceResponseInterface<InvoiceResource>>(
            route('api.transactions.store'),
            data
        );
    };
}
