<template>
    <div class="d-inline-block">
        <EditableInput
            :label="formatDate(journey.end_time, 'short time')"
            :loading="updating"
            :value="DateTime.fromISO(journey.end_time).toISO({includeOffset: false, suppressMilliseconds: true, suppressSeconds:true}).toString()"
            type="datetime-local"
            @updated="updateEndTime"
        />
    </div>
</template>

<script>
export default {
    name: "EditEndTime"
}
</script>
<script setup>
import EditableInput from "../../Forms/EditableFields/EditableInput.vue";
import {inject, ref} from 'vue'
import {toTimeStamp, formatDate} from "../../Utils.js";
import {DateTime} from 'luxon'

const toast = inject('toast')
import axios from "axios";
import {useVerificationStore} from "../../../stores/VerificationStore.js";

const verificationStore = useVerificationStore();

const props = defineProps({
    journey: {
        type: Object,
        required: true,
    },
})

const updating = ref(false)
const updateEndTime = function (newEndTime) {
    updating.value = true
    axios
        .put(route('api.journeys.update', {journey: props.journey.uuid}), {
            end_time: toTimeStamp(newEndTime),
        })
        .then((response) => {
            verificationStore.updateJourney(response.data)
            toast.success('End time updated for vehicle run.')
        })
        .catch((error) => {
            toast.error('There was an error updating the end time for this vehicle run.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
