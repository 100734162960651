<template>
    <FullCalendar ref="fullCalendar" :options="calendarOptions"></FullCalendar>
</template>

<script setup lang="ts">
import {ref, onBeforeMount, onMounted, inject} from "vue";
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {DateTime as LuxonDateTime} from "luxon";
import axios from "axios";

const toast = inject('toast');

const calendarOptions = ref({})
const fullCalendar = ref(null);

const props = withDefaults(
    defineProps<{
        vehicle: App.Models.Vehicle,
        height: string
    }>(),
    {
        height: '400px'
    }
);

const emit = defineEmits(['eventClicked', 'selected'])

const setCalendarOptions = function () {
    calendarOptions.value = {
        locale: 'en-au',
        allDaySlot: false,
        events: (info, successCallback, failureCallback) => {
            const startTime = LuxonDateTime.fromISO(info.startStr);
            const endTime = LuxonDateTime.fromISO(info.endStr);
            const startTimestamp = Math.floor(startTime.toMillis() / 1000);
            const endTimestamp = Math.floor(endTime.toMillis() / 1000);

            // showing vehicle run
            axios.get(route('api.vehicle.run.index', {vehicle: props.vehicle.uuid}), {
                params: {
                    'start_time': startTimestamp,
                    'end_time': endTimestamp
                },
            }).then(response => {
                const vehicleRun = response.data.map(function(run) {
                    run.type = 'run';

                    console.log(run);

                    return {
                        id: run.uuid,
                        title: vehicleRunDescription(run),
                        start: LuxonDateTime.fromISO(run.start_time).toJSDate(),
                        end: LuxonDateTime.fromISO(run.end_time).toJSDate(),
                        allDay: false,
                        color: run.status == 'cancelled' ? '#E3655B' : '#3788d8',
                        extendedProps: run,
                    }
                });

                // showing unavailability
                axios.get(route('api.vehicles.unavailability-periods.index', {vehicle: props.vehicle.uuid}), {
                    params: {
                        'start_time': startTimestamp,
                        'end_time': endTimestamp
                    },
                }).then(response => {
                    const unavailability = response.data.map(function(unavailability) {
                        unavailability.type = 'unavailability';

                        return {
                            id: unavailability.uuid,
                            title: unavailability.notes ? unavailability.notes : 'Unavailable',
                            start: LuxonDateTime.fromSeconds(unavailability.start_time).toJSDate(),
                            end: LuxonDateTime.fromSeconds(unavailability.end_time).toJSDate(),
                            allDay: false,
                            color: '#919ca7',
                            extendedProps: unavailability,
                        }
                    });

                    const schedule = vehicleRun.concat(unavailability);
                    successCallback(schedule);
                })
                .catch(error => {
                    failureCallback(error);
                });
            })
            .catch(error => {
                failureCallback(error);
            });
        },
        eventClick: handleEventClicked,
        headerToolbar: {
            start: 'dayGridMonth,timeGridWeek',
            center: 'title',
            end: 'prev,next'
        },
        height: props.height,
        initialView: 'timeGridWeek',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        select: handleSelect,
        selectable: true,
        nowIndicator: true,
    }
}

const handleEventClicked = (event) => { emit('eventClicked', event) }
const handleSelect = (selected) => { emit('selected', selected) }

const vehicleRunDescription = function (run) {
    let descriptionParts: Array<string> = [];
    if (run.description) {
        descriptionParts.push(run.description)
    }

    if (run.driver) {
        descriptionParts.push(`Driver: ${run.driver.name}`)
    } else {
        descriptionParts.push('No Driver Assigned')
    }

    if(run.status == 'cancelled') {
        descriptionParts.push('Cancelled')
    }

    return descriptionParts.join('  |  ')
}

onBeforeMount(() => {
    setCalendarOptions();
});

</script>

<style scoped>

</style>
