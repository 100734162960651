<template>
    <div class="row">
        <div class="col-md-4 pe-5">
            <h4>Settings</h4>
            <p>
                A set of settings that allow you to control how Trips Driver app
                works.
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <ul class="list-group list-group-flush container">
                        <SettingRow
                            v-for="(setting, index) in settings"
                            v-bind:key="index"
                            v-bind:setting="setting"
                        ></SettingRow>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingRow from "./SettingRow.vue";
export default {
    name: "DriverAppSettings",
    components: {
        SettingRow,
    },
    props: {
        settings: Array,
    },
    data() {
        return {};
    },
    computed: {},
    beforeMount() {},
    methods: {},
};
</script>

<style scoped></style>
