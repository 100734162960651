<template>
  <span :class="colorClass">
      {{ moment(date).format(format) }}
  </span>
</template>

<script>
export default {
    name: "ColorContextualDate",
    props: {
        date: String,
        format: {
            type: String,
            required: false,
            default: 'D MMMM YYYY',
        }
    },
    computed: {
        colorClass: function () {
            if (this.moment(this.date).isBefore(this.moment().add(1, 'months'))) {
                return 'text-danger'
            } else if (this.moment(this.date).isBefore(this.moment().add(3, 'months'))) {
                return 'text-warning'
            }

            return ''
        },
    }
}
</script>

<style scoped>

</style>
