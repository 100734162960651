<template>
    <div>
        <div class="row mb-2">
            <div class="col-md-6">
                <CreateLocation
                    @stored="getLocationTypes"
                    :location-types="locationTypes"
                />
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row mb-3 justify-content-between">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label text-nowrap">
                            Display
                            <select v-model="amountToDisplay" class="form-select form-select-sm d-inline mx-1">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option :value="paginationMeta?.total">All</option>
                            </select>
                            locations
                        </label>
                    </div>
                    <div class="col-md-2 text-end">
                        <div class="input-group input-group-sm">
                            <input v-model="searchQuery"
                                class="form-control form-control-sm"
                                placeholder="Search locations..."
                                type="search"
                            >
                            <button class="btn btn-outline-secondary" type="button" @click="getLocations">
                                <SvgIcon
                                    :path="mdiMagnify"
                                    style="width: 1.3em"
                                    type="mdi"
                                ></SvgIcon>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="gettingLocations" class="d-flex w-100 justify-content-center align-items-center ps-3">
                    <div class="spinner-grow my-5 me-3" role="status"></div>
                    <div class="fs-4">Loading Locations</div>
                </div>
                <template v-if="!gettingLocations">
                    <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100">
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th>Address</th>
                            <th>Location Type</th>
                            <th>Suburb</th>
                            <th>Postcode</th>
                            <th>State</th>
                            <th>Timezone</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Contact</th>
                            <th class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            <template v-if="locations.length > 0">
                                <template v-for="(location, index) in locations" :key="index">
                                    <tr>
                                        <td>
                                            <template v-if="!location.name">&mdash;</template>
                                            <template v-else>{{ location.name }}</template>
                                        </td>
                                        <td class="fw-bold">
                                            {{
                                                location.address_line_1
                                            }}{{ location.address_line_2 ? ', ' + location.address_line_2 : '' }}
                                        </td>
                                        <td>{{ location.location_type?.description ?? '&mdash;' }}</td>
                                        <td>{{ location.suburb }}</td>
                                        <td>{{ location.postcode }}</td>
                                        <td>{{ location.state }}</td>
                                        <td>{{ location.timezone }}</td>
                                        <td>{{ location.latitude }}</td>
                                        <td>{{ location.longitude }}</td>
                                        <td>
                                            <template v-if="!location.contact">&mdash;</template>
                                            <template v-else>{{ location.contact.name }}</template>
                                        </td>
                                        <td class="text-end">
                                            <div class="dropdown">
                                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="mdi mdi-dots-vertical"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <a
                                                        @click.prevent="createLocationContact(location)"
                                                        class="dropdown-item"
                                                        href="javascript:void(0);"
                                                    >
                                                        {{ location.contact ? 'Edit' : 'Add' }} contact
                                                    </a>
                                                    <a
                                                        @click.prevent="showLocationEditModal(location)"
                                                        class="dropdown-item"
                                                        href="javascript:void(0);"
                                                    >
                                                        Edit location
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="paginationMeta && !gettingLocations && locations.length > 0"
                        @pageChanged="loadPage"
                        :pagination="paginationMeta"
                        record-name="locations"
                    ></Pagination>
                </template>
                <div
                    v-if="!gettingLocations && locations.length === 0"
                    class="d-flex w-100 justify-content-center align-items-center ps-3"
                >
                    <p>No locations found.</p>
                </div>
            </div>

            <div id="createLocationContactModal" aria-hidden="true" aria-labelledby="createLocationContactModalLabel"
                class="modal fade"
                tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="createLocationContactModalLabel" class="modal-title">Add contact for
                                {{ locationForContactBeingCreated.formatted_address }}</h5>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <FormTextInput
                                        :key="draftLocationContact.name.uuid"
                                        v-model:input="draftLocationContact.name"
                                        label="Name"
                                        placeholder="e.g. John Smith"
                                    ></FormTextInput>
                                </div>
                                <div class="col-md-6">
                                    <FormTextInput
                                        :key="draftLocationContact.role.uuid"
                                        v-model:input="draftLocationContact.role"
                                        label="Role"
                                        optional
                                        placeholder="e.g. Reception Manager"
                                    ></FormTextInput>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-8">
                                    <FormTextInput
                                        :key="draftLocationContact.email.uuid"
                                        v-model:input="draftLocationContact.email"
                                        label="Email"
                                        optional
                                        placeholder="e.g. example@email.com"
                                    ></FormTextInput>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <FormPhoneInput
                                        :key="draftLocationContact.work_phone.uuid"
                                        v-model:input="draftLocationContact.work_phone"
                                        label="Work phone"
                                        optional
                                        placeholder="e.g. 02 9876 5432"
                                    ></FormPhoneInput>
                                </div>
                                <div class="col-md-6">
                                    <FormPhoneInput
                                        :key="draftLocationContact.mobile_phone.uuid"
                                        v-model:input="draftLocationContact.mobile_phone"
                                        label="Mobile phone"
                                        optional
                                        placeholder="e.g. 0412 345 678"
                                    ></FormPhoneInput>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-8">
                                    <FormTextInput
                                        :key="draftLocationContact.organisation.uuid"
                                        v-model:input="draftLocationContact.organisation"
                                        label="Organisation name"
                                        optional
                                        placeholder="e.g. ACME Pty Ltd"
                                    ></FormTextInput>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-light" data-bs-dismiss="modal" type="button">Close</button>
                            <Button :loading="storingLocationContact" @click="storeOrUpdateLocationContact">Save changes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="locationEditModal"
                aria-hidden="true"
                aria-labelledby="locationEditModalLabel" class="modal fade"
                tabindex="-1"
            >
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 id="locationEditModalLabel" class="modal-title">Edit description for {{ locationInEdit.address_line_1 }}</h5>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div :key="refreshKey" class="modal-body">
                            <div class="mb-3">
                                <FormTextInput
                                    :key="draftLocation.address_line_1.uuid"
                                    v-model:input="draftLocation.address_line_1"
                                    label="Address Line 1"
                                    placeholder="e.g. 123 Fake Street"
                                />
                            </div>
                            <div class="mb-3">
                                <FormTextInput
                                    :key="draftLocation.address_line_2.uuid"
                                    v-model:input="draftLocation.address_line_2"
                                    label="Address Line 2"
                                    optional
                                    placeholder="e.g. Apartment 15"
                                />
                            </div>
                            <div class="mb-3 row">
                                <div class="col-md-4">
                                    <FormTextInput
                                        :key="draftLocation.suburb.uuid"
                                        v-model:input="draftLocation.suburb"
                                        label="Suburb"
                                        placeholder="e.g. Newcastle"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <FormSelect
                                        :key="draftLocation.state.uuid"
                                        v-model:selection="draftLocation.state"
                                        :options="australianStates"
                                        label="State"
                                        placeholder="Select a state..."
                                    />
                                </div>
                                <div class="col-md-4">
                                    <FormNumberInput
                                        :key="draftLocation.postcode.uuid"
                                        v-model:input="draftLocation.postcode"
                                        :mask="{
                                            mask: Number,
                                            scale: 0,
                                            signed: false,
                                            thousandsSeparator: ''
                                        }"
                                        label="Postcode"
                                        placeholder="e.g. 2000"
                                    />
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <div class="col-md-6">
                                    <FormTextInput
                                        :key="draftLocation.latitude.uuid"
                                        v-model:input="draftLocation.latitude"
                                        label="Latitude"
                                        placeholder="e.g. -33.85662"
                                        :disabled="true"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormTextInput
                                        :key="draftLocation.longitude.uuid"
                                        v-model:input="draftLocation.longitude"
                                        label="Longitude"
                                        placeholder="e.g. 151.21534"
                                        :disabled="true"
                                    />
                                </div>
                            </div>
                            <div v-if="draftLocation.latitude.value && draftLocation.longitude.value" class="mb-3 row">
                                <GoogleMapWithPin
                                    :key="draftLocation.latitude.value + draftLocation.longitude.value"
                                    :locations="[{lat: draftLocation.latitude.value, lng: draftLocation.longitude.value}]"
                                    @locationUpdated="updateLatLng"
                                />
                            </div>
                            <div class="mb-3">
                                <FormTextInput
                                    :key="draftLocation.description.uuid"
                                    v-model:input="draftLocation.description"
                                    label="Description"
                                    placeholder="e.g. Kings Cross Station"
                                ></FormTextInput>
                            </div>
                            <div class="mb-3">
                                <div v-if="!showCreateLocationTypeForm">
                                    <FormSelect
                                        :key="draftLocation.location_type.uuid"
                                        v-model:selection="draftLocation.location_type"
                                        :options="locationTypes.map(type => {
                                            return {
                                                value: type.uuid,
                                                label: type.description
                                            }
                                        })"
                                        label="Location type"
                                        optional
                                        placeholder="Select a location type..."
                                    />
                                    <div class="d-flex justify-content-end mt-1">
                                        <a @click.prevent="createLocationType" href="javascript: void(0)">Create new</a>
                                    </div>
                                </div>
                                <div v-else>
                                    <FormTextInput
                                        :key="draftLocation.location_type.uuid"
                                        v-model:input="draftLocation.location_type"
                                        label="Create a new location type"
                                        placeholder="e.g. Library"
                                    />
                                    <div class="d-flex justify-content-end mt-1">
                                        <Button @click="hideCreateLocationTypeForm" size="small" color="light">Cancel</Button>
                                    </div>
                                </div>
                            </div>
                            <FormTextarea
                                :key="draftLocation.driver_alert.uuid"
                                v-model:input="draftLocation.driver_alert"
                                label="Driver alert"
                                maxlength="250"
                                optional
                                placeholder="e.g. Low ceiling in car park"
                            ></FormTextarea>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-light" data-bs-dismiss="modal" type="button">Close</button>
                            <ConfirmButton @confirmed="deleteLocation" confirmationText="Are you sure you want to delete this location?" confirmationButtonColor="danger" color="danger">Delete Location</ConfirmButton>
                            <Button @click="storeLocation" :loading="storingLocationDescription">Save Location</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LocationsList"
}
</script>
<script setup>
import {onMounted, ref, inject, watch} from 'vue'
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiMagnify} from '@mdi/js'
import {FormTextarea, FormTextInput, FormPhoneInput, FormSelect, FormNumberInput} from '../Forms';
import {uuid} from 'vue-uuid';
import Button from "../Button.vue";
import CreateLocation from "./CreateLocation.vue";
import Pagination from "../Pagination.vue"
import GoogleMapWithPin from "@components/GoogleMapWithPin.vue";
import ConfirmButton from "@components/ConfirmButton.vue";

const axios = inject('axios')
const locations = ref([])
let paginationMeta = ref(null)
const page = ref(1)
const searchQuery = ref('')
const toast = inject('toast')
const amountToDisplay = ref(25)

watch(page, () => {
    getLocations()
})

const updateLocation = (index, newLocation) => {
    locations.value[index] = newLocation
}

const loadPage = (link) => {
    page.value = link.page
    getLocations()
}

watch(amountToDisplay, () => getLocations())

const gettingLocations = ref(true)
const getLocations = () => {
    gettingLocations.value = true
    axios
        .get(route('api.locations.index'), {
            params: {
                page: page.value,
                query: searchQuery.value,
                limit: amountToDisplay.value
            }
        })
        .then(response => {
            locations.value = response.data.data
            if (response.data.meta) {
                paginationMeta.value = response.data.meta
            }
        })
        .finally(() => {
            gettingLocations.value = false
        })
        .catch(error => {
            toast.error('There was an error fetching locations.')
            console.error(error)
        })
}

const createLocationContactModal = ref(null)
const locationForContactBeingCreated = ref({})
const draftLocationContact = ref({})

const initialiseDraftLocationContact = () => {
    draftLocationContact.value = {
        role: {uuid: uuid.v4(), value: null, errors: []},
        name: {uuid: uuid.v4(), value: null, errors: [], required: true},
        organisation: {uuid: uuid.v4(), value: null, errors: []},
        work_phone: {uuid: uuid.v4(), value: null, errors: []},
        mobile_phone: {uuid: uuid.v4(), value: null, errors: []},
        email: {uuid: uuid.v4(), value: null, errors: []},
    }
}
initialiseDraftLocationContact()

const createLocationContact = (location) => {
    locationForContactBeingCreated.value = location

    if (location.contact) {
        draftLocationContact.value = {
            role: {uuid: uuid.v4(), value: location.contact.role, errors: []},
            name: {uuid: uuid.v4(), value: location.contact.name, errors: [], required: true},
            organisation: {uuid: uuid.v4(), value: location.contact.organisation, errors: []},
            work_phone: {uuid: uuid.v4(), value: location.contact.work_phone, errors: []},
            mobile_phone: {uuid: uuid.v4(), value: location.contact.mobile_phone, errors: []},
            email: {uuid: uuid.v4(), value: location.contact.email, errors: []},
        }
    } else {
        initialiseDraftLocationContact()
    }

    createLocationContactModal.value.show()
}

const storingLocationContact = ref(false)
const storeOrUpdateLocationContact = () => {
    storingLocationContact.value = true
    let baseRequest
    let requestBody = {
        role: draftLocationContact.value.role.value,
        name: draftLocationContact.value.name.value,
        organisation: draftLocationContact.value.organisation.value,
        work_phone: draftLocationContact.value.work_phone.value,
        mobile_phone: draftLocationContact.value.mobile_phone.value,
        email: draftLocationContact.value.email.value,
    }

    if (locationForContactBeingCreated.value.contact) {
        baseRequest = axios
            .put(
                route(
                    'api.locations.contacts.update',
                    {
                        location: locationForContactBeingCreated.value.uuid,
                        contact: locationForContactBeingCreated.value.contact.uuid
                    }
                ),
                requestBody
            )
    } else {
        baseRequest = axios
            .post(
                route('api.locations.contacts.store', {location: locationForContactBeingCreated.value.uuid}),
                requestBody
            )
    }

    baseRequest
        .then(response => {
            locationForContactBeingCreated.value.contact = response.data
            createLocationContactModal.value.hide()
            let storageVerb = response.status === 201 ? 'created' : 'updated'
            toast.success('Contact ' + storageVerb + ' for **' + locationForContactBeingCreated.value.formatted_address + '**.')
        })
        .finally(() => {
            storingLocationContact.value = false
        })
        .catch(error => {
            toast.error('There was an error creating the contact.')
        })
}

const errors = ref({})
const storingLocationDescription = ref(false)
const deletingLocationDescription = ref(false)
const storeLocation = function () {
    storingLocationDescription.value = true;
    errors.value = {};

    axios
        .put(route('api.locations.update', {location: locationInEdit.value.uuid}), {
            address_line_1: draftLocation.value.address_line_1.value,
            address_line_2: draftLocation.value.address_line_2.value,
            suburb: draftLocation.value.suburb.value,
            state: draftLocation.value.state.value,
            postcode: draftLocation.value.postcode.value,
            name: draftLocation.value.description.value,
            location_type: draftLocation.value.location_type.value,
            driver_alert: draftLocation.value.driver_alert.value,
            longitude: draftLocation.value.longitude.value,
            latitude: draftLocation.value.latitude.value,
        })
        .then((response) => {
            locationEditModal.value.hide()
            getLocations()
            toast.success('Location updated **' + locationInEdit.value.formatted_address + '**.')
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            storingLocationDescription.value = false
        })
}
const deleteLocation = function () {
  deletingLocationDescription.value = true;
  errors.value = {};

  axios
      .delete(route('api.locations.destroy', {location: locationInEdit.value.uuid}))
      .then((response) => {
        locationEditModal.value.hide();
        getLocations();
        toast.success('Location deleted.');
      })
      .catch((error) => {
        errors.value = error.response.data.errors;
        error.response.data.errors.id.forEach((message) => toast.error(message));
      })
      .finally(() => {
        deletingLocationDescription.value = false;
      })
}

const locationInEdit = ref(location)
const locationEditModal = ref(null)
const draftLocation = ref({
    address_line_1: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
        required: true,
    },
    address_line_2: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
        required: false,
    },
    suburb: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
        required: true,
    },
    state: {
        uuid: uuid.v4(),
        value: 'NSW',
        errors: [],
        required: true,
    },
    postcode: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
        required: true,
    },
    description: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
    },
    driver_alert: {
        uuid: uuid.v4(),
        value: '',
        errors: [],
    },
    location_type: {
        uuid: uuid.v4(),
        value: null,
        errors: [],
    },
    latitude : {
        uuid: uuid.v4(),
        value: '',
        errors: [],
    },
    longitude : {
        uuid: uuid.v4(),
        value: '',
        errors: [],
    }
});

const australianStates = ref([
    {value: 'ACT', label: 'ACT'},
    {value: 'NSW', label: 'NSW'},
    {value: 'NT', label: 'NT'},
    {value: 'QLD', label: 'QLD'},
    {value: 'SA', label: 'SA'},
    {value: 'TAS', label: 'TAS'},
    {value: 'VIC', label: 'VIC'},
    {value: 'WA', label: 'WA'},
]);

const refreshKey = ref(Date.now())
const showLocationEditModal = (location) => {
    locationInEdit.value = location;
    draftLocation.value.address_line_1.value = location.address_line_1 ?? '';
    draftLocation.value.address_line_2.value = location.address_line_2 ?? '';
    draftLocation.value.suburb.value = location.suburb ?? '';
    draftLocation.value.postcode.value = location.postcode ?? '';
    draftLocation.value.state.value = location.state ?? '';
    draftLocation.value.description.value = location.name ?? '';
    draftLocation.value.driver_alert.value = location.driver_alert ?? '';
    draftLocation.value.location_type.value = location.location_type?.uuid ?? '';
    draftLocation.value.latitude.value = location.latitude ?? '';
    draftLocation.value.longitude.value = location.longitude ?? '';
    refreshKey.value = Date.now()
    locationEditModal.value.show()
}

const resetForm = () => {
    initialiseLocation();
    showCreateLocationTypeForm.value = false;
}

const showCreateLocationTypeForm = ref(false)
const createLocationType = function () {
    draftLocation.value.location_type.value = null;
    showCreateLocationTypeForm.value = true;
}

const hideCreateLocationTypeForm = () => {
    showCreateLocationTypeForm.value = false;
    draftLocation.value.location_type.value = locationInEdit.value.location_type?.uuid ?? '';
}

const locationTypes = ref([])
const getLocationTypes = () => {
    axios
        .get(route('api.location-types.index'))
        .then(response => {
            locationTypes.value = response.data.data
        })
        .catch(error => {
            toast.error('There was an error retrieving the location types.')
        })
}

const updateLatLng = (latLng) => {
    draftLocation.value.latitude.value = latLng.lat;
    draftLocation.value.longitude.value = latLng.lng;
}

onMounted(() => {
    getLocations()
    getLocationTypes()

    createLocationContactModal.value = new bootstrap.Modal(document.getElementById('createLocationContactModal'))
    locationEditModal.value = new bootstrap.Modal(document.getElementById('locationEditModal'))
})
</script>

<style scoped>

</style>
