<template>
    <div class="mb-3">
        <FormInputLabel input-name="tags" label="Tags"></FormInputLabel>

        <div class="row">
            <div class="col-md-8 d-flex">
                <div class="flex-grow-1">
                    <input
                        @input="searchTags"
                        @focus="showResults = true"
                        v-click-outside="hideResults"
                        v-model="addTagInput"
                        id="driverTagsSearchInput"
                        class="form-control"
                        :class="{'is-invalid': isInvalid }"
                        placeholder="Search tags"
                        type="text"
                    >
                </div>
                <div class="flex-shrink-1">
                    <button
                        @click="saveNewTag"
                        v-if="showNewTagButton"
                        class="btn btn-primary btn-md ms-1"
                    >
                        <span class="visually-hidden">Add</span>
                        <i class="mdi mdi-plus"></i>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="showAutocomplete" class="autocomplete-results-wrapper">
            <div class="list-group">
                <a
                    href="javascript: void(0)"
                    v-for="(tag, index) in results"
                    @click.prevent="selectTag(tag, index)"
                    class="list-group-item autocomplete-suggestion"
                    :key="index"
                >
                    <div>{{ tag.tag }}</div>
                </a>
            </div>
        </div>

        <FormValidationError :errors="arrayErrors" field="tags"></FormValidationError>
        <FormValidationError :errors="errors" field="tag"></FormValidationError>

        <div class="my-1">
            <span
                v-for="(selectedTag, index) in selectedTags"
                :key="index"
                class="badge bg-light text-dark me-1 mb-1"
            >
                {{ selectedTag.tag }}
                <button
                    @click.prevent="removeTag(selectedTag, index)"
                    class="btn btn-sm btn-link text-dark"
                >
                    <i class="mdi mdi-close"></i>
                </button>
            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import FormInputLabel from '../Forms/parts/FormInputLabel.vue'
import { FormValidationError } from '../Forms'

const axios = inject('axios')

const props = defineProps({
    arrayErrors: {
        type: Object
    },
    savedTags: {
        type: Array
    }
})

const selectedTags = ref(props.savedTags?.length ? props.savedTags : [])
const showAddTagButton = ref([])
let showResults = ref(false)
let addTagInput = ref('')
let results = ref([])
let errors = ref([])
let noExactMatch = ref(false)
let tagSelected = ref(false)

const showNewTagButton = computed(() => {
    if(tagSelected.value) {
        return false
    }else if (addTagInput.value.length > 0 && noExactMatch.value) {
        return true;
    } else if(addTagInput.value.length > 0 && results.value.length === 0) {
        return false;
    }
})

const showAutocomplete = computed(() => {
    return results.value.length > 0 && showResults.value === true
})

const isInvalid = computed(() => {
    return errors && errors['tag'] || props.arrayErrors['tags'].length
})

const hideResults = () => {
    showResults.value = false
}

const unfocusAddTagForm = () => {
    if (addTagInput.value.trim().length > 0) {
        return
    }

    addTagInput.value = ''
    errors.value = []
    results.value = []
}

const searchTags = () => {
    showResults.value = true
    errors.value = []

    if (!addTagInput.value.length) {
        results.value = []
        return
    }

    results.value = allTags.value.filter(tag => {
        return tag.tag.toLowerCase().includes(addTagInput.value.toLowerCase())
    })

    noExactMatch.value = !results.value.some(tag => {
        return tag.tag.toLowerCase() === addTagInput.value.toLowerCase()
    })

    tagSelected.value = selectedTags.value.some(tag => {
        return tag.tag.toLowerCase() === addTagInput.value.toLowerCase()
    })
}

const selectTag = (tag, index) => {
    let tagIndex = allTags.value.findIndex(selectedTag => selectedTag.uuid === tag.uuid)
    selectedTags.value.push(tag)
    allTags.value.splice(tagIndex, 1)
    results.value = []
    addTagInput.value = ''
    emitTagsUpdated()
}

const emit = defineEmits(['tagsUpdated'])

const emitTagsUpdated = () => {
    emit('tagsUpdated', selectedTags.value.map(tag => tag.uuid))
}

const removeTag = (tag, index) => {
    let tagIndex = selectedTags.value.findIndex(selectedTag => selectedTag.uuid === tag.uuid)
    selectedTags.value.splice(tagIndex, 1)
    allTags.value.push(tag)
    emit('tagsUpdated', selectedTags.value.map(tag => tag.uuid))
}

const saveNewTag = () => {
    axios
        .post(route('api.driver-tags.store'), { tag: addTagInput.value })
        .then((res) => {
            addTagInput.value = ''
            errors = []
            selectedTags.value.push(res.data)
            unfocusAddTagForm()
            emitTagsUpdated()
        })
        .catch((error) => {
            errors.value = error.response.data.errors
            results.value = []
        })
}

const allTags = ref([]);
const getTags = () => {
    axios
        .get(route('api.driver-tags.index'))
        .then((res) => {
            allTags.value = res.data.tags
        })
}

onMounted(() => {
    getTags()
})
</script>
