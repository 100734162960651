import axios from "axios";

export interface CargoSpaceFormDetailsInterface {
    code: string;
    space: number;
    seat_type?: string;
}

export interface CargoSpaceOptionsInterface {
    code: string;
    space: number;
    name: string;
    description: string;
    seat_types: [];
}

export type CargoSpaceWithPivot = App.Models.CargoSpace & {
    pivot: App.Models.CargoSpacePassengerable;
};

export default class CargoSpace {
    static async getAll(): Promise<App.Models.CargoSpace[]> {
        let result: App.Models.CargoSpace[] = [];

        try {
            let response: any = await axios.get(
                route("api.cargo-spaces.index")
            );

            if (response?.data?.some((i) => i?.id)) {
                result = response?.data;
            }
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }

    static convertToFormProp = (
        spaces: CargoSpaceWithPivot[]
    ): CargoSpaceFormDetailsInterface[] => {
        let result: CargoSpaceFormDetailsInterface[] = [];

        spaces.map((space) => {
            result.push({
                code: space.code,
                //@ts-ignore
                space: space.space,
                seat_type: ''
            });
        });

        return result;
    };

    static async options(): Promise<CargoSpaceOptionsInterface[]> {
        let result: CargoSpaceOptionsInterface[] = [];

        try {
            let response: any = await axios.get(
                route("api.cargo-spaces.options")
            );

            result = response.data.data;
        } catch (ex) {
            console.error(ex);
        }

        return result;
    }
}
