<template>
    <div v-if="selectedSpaces.length > 0" class="card">
        <div class="card-body">
            <h5>Space Configuration</h5>

            <VueMultiselect
                v-model="selectedSpaceTypes"
                :options="spaceTypes"
                placeholder="Select service types"
                label="label"
                track-by="value"
                multiple
            ></VueMultiselect>

            <h5>Actions</h5>

            <div v-if="singleSelectedSpace">
                <h6>Rows</h6>

                <div
                    class="d-grid gap-2 d-md-grid"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <Button
                        :icon="mdiArrowUpBold"
                        color="primary"
                        @click="addRowAbove"
                    >
                        Add Row Above
                    </Button>

                    <Button
                        :icon="mdiArrowDownBold"
                        color="primary"
                        @click="addRowBelow"
                    >
                        Add Row Below
                    </Button>
                </div>

                <h6>Delete</h6>

                <div
                    class="d-grid gap-2 d-md-grid"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <Button
                        :icon="mdiDelete"
                        color="danger"
                        @click="deleteSelectedRow"
                    >
                        Remove Row
                    </Button>

                    <Button
                        :icon="mdiDelete"
                        color="danger"
                        @click="deleteSelectedSpace"
                    >
                        Remove Space
                    </Button>
                </div>
            </div>
            <div v-if="selectedSpaces.length > 1">
                <h6>Linking</h6>

                <div
                    class="d-grid gap-2 d-md-grid"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <Button
                        :icon="mdiLink"
                        color="primary"
                        @click="linkSelected"
                    >
                        Link Selected
                    </Button>

                    <Button
                        :icon="mdiLinkOff"
                        color="primary"
                        @click="unlinkSelected"
                    >
                        Unlink Selected
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useVehicleConfigurationDesignStore } from "@stores/VehicleConfigurationDesignStore";
import VehicleDiagram from "./VehicleDiagram.vue";
import { FormSelect, FormTextarea } from "@components/Forms";
import { computed } from "vue";
import Button from "@components/Button.vue";
import {
    mdiArrowUpBold,
    mdiArrowDownBold,
    mdiDelete,
    mdiLink,
    mdiLinkOff,
} from "@mdi/js";
import VueMultiselect from "vue-multiselect";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const { deleteRow, deleteSpaceByUuid } = vehicleConfigurationDesignStore;

const { selectedSpacesUuid, selectedSpaces, spaceTypes, linkedSpaces } =
    storeToRefs(vehicleConfigurationDesignStore);

const singleSelectedSpace = computed(() => {
    if (selectedSpaces.value.length == 1) {
        return selectedSpaces.value[0];
    }

    return undefined;
});

const rowIndex = computed(() => {
    if (!singleSelectedSpace.value) {
        return;
    }

    return vehicleConfigurationDesignStore.getRowIndexFromSpaceUuid(
        singleSelectedSpace.value.uuid
    );
});

const addRowAbove = () => {
    if (rowIndex.value == undefined) {
        return;
    }

    vehicleConfigurationDesignStore.addRow(rowIndex.value);
};

const addRowBelow = () => {
    if (rowIndex.value == undefined) {
        return;
    }

    vehicleConfigurationDesignStore.addRow(rowIndex.value + 1);
};

const linkSelected = () => {
    if (selectedSpaces.value.length < 2) {
        return;
    }
    linkedSpaces.value.push({
        spaces: selectedSpaces.value.map((i) => i.uuid),
    });
};

const unlinkSelected = () => {
    linkedSpaces.value = linkedSpaces.value.filter((i) => {
        return !i.spaces.find((i) => {
            return selectedSpaces.value.find((a) => a.uuid == i);
        });
    });
};

const deleteSelectedRow = () => {
    if (rowIndex.value == undefined) {
        return;
    }

    deleteRow(rowIndex.value);
};

const deleteSelectedSpace = () => {
    if (!singleSelectedSpace.value) {
        return;
    }

    deleteSpaceByUuid(singleSelectedSpace.value.uuid);
};

const selectedSpaceTypes = computed({
    get() {
        return spaceTypes.value.filter((i) => {
            return selectedSpaces.value.every((space) => {
                return space.type.includes(i.value);
            });
        });
    },
    set(newValue) {
        console.log("newValue", newValue);
        selectedSpaces.value.forEach((space) => {
            space.type = newValue.map((i) => i.value);
        });
    },
});
</script>
