import axios from "axios";
import { LaravelResourceResponse } from "../types/LaravelCommonTypes";

export default class OrganisationSetting {
    static index() {
        return axios.get<App.Models.OrganisationSetting[]>(
            route("api.organisation-settings.index")
        );
    }

    static update(payload: {
        category: string;
        settings: { [key: string]: any };
    }) {
        return axios.put(route("organisation-settings.update"), payload);
    }
}
