<template>
    <div>
        <label class="form-label" for="timezone">{{ selected.title }}</label>
        <select :disabled="loading" v-model="selectedTimezone" @change="updateTimezone" name="timezone" id="timezone" class="form-select">
            <option v-for="timezone in timezones" :value="timezone">{{ timezone }}</option>
        </select>
        <div v-if="loading" class="mt-2">
            <div class="spinner-grow spinner-grow-sm me-1" role="status"></div>
            Updating...
        </div>
        <transition name="fade">
        <div v-if="saved" class="mt-2 d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="checkIcon"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>
        </transition>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle } from "@mdi/js";
export default {
    name: "TimezoneSettings",
    components: {
        SvgIcon,
    },
    data() {
        return {
            timezones: [],
            selectedTimezone: '',
            loading: false,
            saved: false,
            checkIcon: mdiCheckCircle,
        }
    },
    props: {
        selected: {
            type: Object,
            required: true,
        },
    },
    methods: {
        updateTimezone() {
            this.$http
                .post(this.route('api.set-timezone'), {
                    timezone: this.selectedTimezone,
                })
                .then(() => {
                    this.loading = false
                    this.saved = true
                    setTimeout(() => { this.saved = false }, 2000)
                })
                .catch(() => {
                    this.$root.$refs.alert.show('Unable to update timezone, please try again later')
                })
        }
    },
    beforeMount() {
        this.timezones = Intl.supportedValuesOf('timeZone')
    },
    mounted() {
        this.selectedTimezone = this.selected.value
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
