<template>
    <div v-if="modelValue.name">
        <div class="mb-3">
            <FormTextInput
                :key="modelValue.name.uuid"
                v-model:input="modelValue.name"
                label="Name"
                placeholder="e.g. Department Of Veteran Affairs"
                :validation="yup.string().required().max(255)"
            ></FormTextInput>
        </div>
        <div class="mb-3">
            <FormTextInput
                :key="modelValue.code.uuid"
                v-model:input="modelValue.code"
                label="Code"
                placeholder="e.g. DVA"
                :validation="yup.string().required().max(255)"
            ></FormTextInput>
        </div>
        <div class="mb-3">
            <FormTextarea
                :key="modelValue.description.uuid"
                v-model:input="modelValue.description"
                label="Description"
                optional
                placeholder="e.g. Organisation Description"
                :validation="yup.string().required().max(1000)"
            ></FormTextarea>
        </div>
        <div class="mb-3">
            <label :class="'optional-label'">Approved Payers</label>
            <VueMultiselect
                v-model="modelValue.approved_payers"
                :options="payers"
                placeholder="Select Approved Payers"
                label="name"
                track-by="uuid"
                multiple
            ></VueMultiselect>
        </div>
        <div class="mb-3">
            <FormNumberInput
                :key="modelValue.yearly_target_amount.uuid"
                v-model:input="yearlyTargetAmount"
                label="Annual target amount"
                placeholder="e.g. 300"
                optional
                step="1"
            ></FormNumberInput>
            <div class="form-text">
                The annual target relates to the amount of bookings completed
                per financial year.
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FormTextInput, FormTextarea, FormNumberInput } from "../Forms";
import * as yup from "yup";
import VueMultiselect from "vue-multiselect";
import { onMounted, ref, computed } from "vue";
import { usePayerStore } from "@stores/PayerStore";

const payerStore = usePayerStore();

const props = withDefaults(
    defineProps<{
        modelValue: any;
    }>(),
    {}
);

const yearlyTargetAmount = computed({
    get: () => {
        //Remove the commas from the value so the input can be populated
        let converted = props.modelValue.yearly_target_amount;
        converted.value = converted.value?.replace(/,/g, "") ?? '';
        return converted;
    },
    set: (value) => {
        props.modelValue.yearly_target_amount = value;
    },
});

const payers = ref<App.Models.Payer[]>([]);

const loadPayers = async () => {
    payers.value = await payerStore.sync();
};

onMounted(() => {
    loadPayers();
});
</script>
