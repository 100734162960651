<template>
    <a class="small" href="javascript:void(0)" @click.prevent="showBookingModal">View booking details</a>
</template>

<script setup>
import { inject, watch } from 'vue'
const props = defineProps({
    booking: {
        type: Object,
        required: true,
    }
})

const bookingDetails = inject('bookingDetails')

const emit = defineEmits(['bookingReallocated'])

watch(bookingDetails.getAllocatedBooking, (newValue, oldValue) => {
    if (newValue.uuid === props.booking.uuid) {
        emit('bookingReallocated')
    }
})

const showBookingModal = () => {
    bookingDetails.showForBookingId(props.booking.uuid);
};
</script>