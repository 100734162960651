<template>
    <modal :show="showConfirm" :title="title">
        <template v-slot:body>
            <slot name="content">
                <p>Are you sure that you want to perform this action?</p>
            </slot>
        </template>
        <template v-slot:footer>
            <Button 
                @click="closeModal"
                :icon="mdiCancel"
                :color="cancelColor"
            >Cancel</Button>
            <Button 
                @click="confirm"
                :icon="mdiCheck"
                :color="confirmColor"
            >Confirm</Button>
        </template>
    </modal>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { Modal, Button } from "@components";
    import { mdiCancel, mdiCheck } from '@mdi/js';

    const props = withDefaults(
        defineProps<{
            show: boolean,
            title?: string,
            confirmColor?: string,
            cancelColor?: string,
        }>(),{
            title: "Are you sure?",
            confirmColor: "danger",
            cancelColor: "light",
        }
    )

    const emit = defineEmits<{
        (e: 'update:show', value: boolean): void,
        (e: 'confirm', value: boolean): void,
    }>();

    const showConfirm = computed<boolean>({
        get: () => props.show,
        set: (value) => emit('update:show', value),
    });

    const closeModal = () => {
        showConfirm.value = false;
    }

    const confirm = () => {
        emit('confirm', true);
    }
</script>