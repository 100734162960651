<template>
    <span :title="full ? '' : title">
        <span :class="statusClass" class="badge rounded-pill text-dark d-inline-flex align-items-center" data-bs-toggle="tooltip">
            <SvgIcon :path="iconPath" class="w-3 h-3" type="mdi"/>
            <span v-if="full && titleInBadge" class="ms-1">{{ title }}</span>
        </span>
        <span v-if="full && !titleInBadge" class="ms-1">{{ title }}</span>
    </span>
</template>

<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiClockOutline,
    mdiCheck,
    mdiCancel,
    mdiMinusCircleOutline,
    mdiFileClockOutline,
    mdiFile,
    mdiAlertCircleOutline, mdiAccountClockOutline, mdiSwapHorizontal, mdiCarClock, mdiCheckAll, mdiPencil
} from "@mdi/js";
import {computed} from "vue";

const props = defineProps({
    status: String,
    full: Boolean,
    titleInBadge: {
        type: Boolean,
        required: false,
        default: true
    },
})

const iconPath = computed(() => {
    switch (props.status) {
        case 'draft':
            return mdiFile
        case 'unpaid':
            return mdiClockOutline
        case 'paid':
            return mdiCheckAll
        case 'cancelled':
            return mdiCancel
        case 'approved':
            return mdiCheck
        default:
            return mdiMinusCircleOutline // void
    }
})

const title = computed(() => {
    switch (props.status) {
        case 'draft':
            return 'Draft'
        case 'unpaid':
            return 'Unpaid'
        case 'paid':
            return 'Paid'
        case 'cancelled':
            return 'Cancelled'
        case 'approved':
            return 'Approved'
        default:
            return 'Void'
    }
})

const statusClass = computed(() => {
    switch (props.status) {
        case 'draft':
            return 'bg-warning-lighten'
        case 'unpaid':
            return 'bg-warning-lighten'
        case 'paid':
        case 'approved':
            return 'bg-success-lighten'
        case 'cancelled':
            return 'bg-secondary-lighten'
        default:
            return 'bg-danger-lighten'
    }
})

</script>

<style scoped>

</style>
