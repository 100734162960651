<template>
    <div class="card" v-if="store.invoice">
        <div class="card-header">
            <table class="table table-borderless table-sm">
                <thead>
                <tr>
                    <td colspan="3">
                        <h4 class="d-inline me-2">INVOICE {{ store.invoice.uuid }}</h4>
                        <div v-if="invoice.status === 'draft'" class="d-inline-block">
                            <InvoiceStatusDropDown :invoice="store.invoice" mode="verbose"></InvoiceStatusDropDown>
                        </div>
                        <template v-else class="d-inline mt-2">
                            <InvoiceStatus :status="store.invoice.status" full :titleInBadge="false"></InvoiceStatus>
                        </template>
                        <p :class="invoice.due_at < DateTime.now().toUTC().toSeconds() && invoice.status === 'unpaid' ? 'text-danger' : ''">Due at {{ DateTime.fromISO(store.invoice.due_at).toLocaleString(DateTime.DATE_MED) }}</p>
                    </td>
                    <td colspan="2" class="text-end align-text-top">
                        <template v-if="invoice.status === 'draft'">
                            <Button @click="saveInvoice" :loading="saving" :icon="mdiCheck">Finish Edit</Button>
                        </template>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="col-2">Payer</th>
                    <td class="col-2">
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Client'">
                            {{ store.invoice.recipient.full_name }}
                        </template>
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Payer'">
                            {{ store.invoice.recipient.payer_code }}<br />
                            <small>{{ store.invoice.recipient.name }}</small>
                        </template>
                    </td>
                    <th rowspan="2" class="col-2">Payer Contact Details</th>
                    <td rowspan="2" class="col-2">
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Client'">
                            {{ store.invoice.recipient?.full_name }}<br />
                            {{ store.invoice.recipient?.phone }}<br />
                            <small>{{ store.invoice.recipient?.email }}</small>
                        </template>
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Payer'">
                            {{ store.invoice.recipient?.contact_name }}<br />
                            {{ store.invoice.recipient?.phone }}<br />
                            <small>{{ store.invoice.recipient?.email }}</small>
                        </template>
                    </td>
                    <td rowspan="4" class="">
                        <div class="border border-1 rounded d-inline-block px-3 py-2 float-end">
                            <span class="lead text-muted float-end">$</span>
                            <h6 class="text-uppercase">Invoice total</h6>
                            <h2 class="me-4">{{ formatCurrency(store.invoice.total) }}</h2>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Purchase Order No.</th>
                    <td></td>
                </tr>
                <tr>
                    <th>Payment Method</th>
                    <td class="text-capitalize">
                        {{ store.invoice.collection_method }}
                    </td>
                    <th>Billing Period</th>
                    <td></td>
                </tr>
                <tr>
                    <th>Inv. Recipient</th>
                    <td>
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Client'">
                            Client
                        </template>
                        <template v-if="store.invoice.recipient_type === 'App\\Models\\Payer'">
                            Payer contact
                        </template>
                    </td>
                    <th></th>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-body">
            <h5>CHARGES SUMMARY</h5>
            <div class="row">
                <div class="col">
                    <small><strong>{{ store.itemCount() }} bookings</strong></small>
                </div>
                <div class="col-4 ps-4">
                    <PageLengthSelector record-name="invoice items" store-name="InvoiceStore" @changed="store.getAllInvoiceItems()"/>
                </div>
            </div>
            <table class="table table-striped border-top">
                <thead>
                <tr>
                    <th class="col">Date</th>
                    <th class="col">Booking</th>
                    <th class="col">Status</th>
                    <th class="col">Client</th>
                    <th class="col">Pick Up</th>
                    <th class="col">Drop Off</th>
                    <th class="col">Distance</th>
                    <th class="col">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in store.invoice.items">
                    <td class="text-nowrap">{{ DatetimeHelper.toLocalDate(item.booking.date, "date_med") }}</td>
                    <td class="text-nowrap"><strong><a :href="route('bookings.show', item.booking, true)">{{ item.booking.uuid }}</a></strong></td>
                    <td class="text-nowrap"><BookingStatus :status="item.booking.status" full :title-in-badge="false"/></td>
                    <td class="text-nowrap"><a :href="route('clients.show', {client:item.booking.client}, true)">{{ item.booking.client?.full_name }}</a></td>
                    <td>{{ item.booking.origin.short_address }}</td>
                    <td>{{ item.booking.destination.short_address }}</td>
                    <td class="text-end text-nowrap">{{ (item.booking.actual_distance ?? item.booking.estimated_distance) / 1000 }} km</td>
                    <td class="text-end text-nowrap">{{ formatCurrency(item.total) }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="8">
                        <Pagination
                            v-if="paginationStore.paginatedMeta.total"
                            store-name="InvoiceStore"
                            record-name="invoice items"
                        ></Pagination>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<style scoped>
    .text-muted {
        color: #E0E1E1 !important;
    }
</style>

<script setup lang="ts">

import {formatCurrency} from "@classes/Helpers/Currency";
import Filters from "@classes/Helpers/Filters";
import BookingStatus from "@components/BookingStatus.vue";
import Button from "@components/Button.vue";
import ClientAvatar from "@components/Clients/ClientAvatar.vue";
import Pagination from "@components/DataTable/Pagination.vue";
import InvoiceStatus from "@components/Invoices/InvoiceStatus.vue";
import InvoiceStatusDropDown from "@components/Invoices/InvoiceStatusDropDown.vue";
import {ToastInterface} from "@components/toast";
import {ApiError} from "@customTypes/LaravelCommonTypes";
import InvoiceResource, {InvoiceItemResource} from "@customTypes/resources/InvoiceResource";
import {mdiCheck, mdiDelete} from "@mdi/js";
import {useInvoiceStore} from "@stores/InvoiceStore";
import {usePaginationStore} from "@stores/PaginationStore";
import {DateTime} from "luxon";
import {Store} from "pinia";
import {inject, onMounted, ref, watch} from "vue";
import DatetimeHelper from "@classes/DateHelpers/DatetimeHelper";
import PageLengthSelector from "../DataTable/PageLengthSelector.vue";

const toast: ToastInterface = inject('toast') as ToastInterface;

const props = withDefaults(defineProps<{
        invoice: InvoiceResource;
    }>(),
    {}
);

const errors = ref({});
const saving = ref(false);
const store = useInvoiceStore();
const paginationStore = usePaginationStore('InvoiceStore');

onMounted(() => {
    store.invoice = props.invoice;
    store.getAllInvoiceItems();
})

const saveInvoice = async () => {
    try {
        await store.save();
        toast.success("Invoice saved.");
    } catch (e:unknown) {
        toast.error((e as ApiError).response?.data?.message);
    }
}

watch(
    paginationStore.paginateParams,
    () => {
        store.getAllInvoiceItems();
    }
)

</script>