<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center align-items-center text-center">
                    <div 
                        @dragover.prevent="dragOver"
                        @dragleave.prevent="dragLeave"
                        @drop="drop"
                        class="uploader"
                        :class="hovering ? 'bg-hover' : 'bg-normal'"
                    >
                        <input
                            id="fileInput"
                            type="file"
                            :multiple="true"
                            name="fields[fileInput][]"
                            @change="onChange"
                            :accept="importExtensions" 
                            ref="filesInput"
                            class="file-input"
                        />
                        <label for="fileInput" class="file-input-label">
                            <SvgIcon class="file-input-icon" type="mdi" :path="mdiFilePlusOutline" size="72"></SvgIcon>
                            <div class="upload-text-main">
                                Click here to select data to import
                            </div>
                            or drag & drop here
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="draftDataImports?.length > 0" class="file-list">
            <div class="row file-item" v-for="(dataImport, idx) in draftDataImports" :key="idx">
                <div class="col-md-7 col-sm-11 lh-lg pt-1">
                    <strong>Filename:</strong> <br />
                    {{ dataImport.attachment.name }}
                </div>
                <div class="col-md-4 col-sm-12">
                    <strong>Type:</strong> <br />
                    <select class="mt-2 w-100" v-model="dataImport.type">
                        <option value="null" disabled>Select a Data Import Type</option>
                        <option v-for="(type, idx) in importOptions" :value="type.value">{{ type.label }}</option>
                    </select>
                </div>
                <div class="col-md-1 col-sm-1 d-flex text-end align-self-center">
                    <button @click="removeDataImport(idx)" class="text-danger">
                        <SvgIcon  type="mdi" :path="mdiClose"></SvgIcon>
                    </button>
                    <button @click="processDataImport(dataImport)" class="text-success ms-1">
                        <SvgIcon type="mdi" :path="mdiCheck "></SvgIcon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount, watch, computed } from "vue";
    import { each } from "lodash";
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiFilePlusOutline, mdiClose, mdiCheck } from "@mdi/js"
    import { useDataImportStore } from "@stores/DataImportStore";
    import { storeToRefs } from "pinia";

    const dataImportStore = useDataImportStore();

    const { importExtensions, draftDataImports, importOptions } = storeToRefs(dataImportStore);
    
    const { getImportOptions, processDataImport } = dataImportStore;

    const filesInput = ref<any>(null);
    const hovering = ref<boolean>(false);

    const onChange = (): void => {
        if (filesInput.value?.files?.length > 0) {
            each(filesInput.value?.files, (file) => {
                let data: App.DataImport.DraftDataImport = {
                    type: null,
                    attachment: file
                }

                draftDataImports.value.push(data);
            });
            filesInput.value = null;
        };
    }

    const dragOver = (): void => {
        hovering.value = true;
    }

    const dragLeave = (): void => {
        hovering.value = false;
    }

    const drop = (event): void => {
        event.preventDefault();
        
        if (event.dataTransfer.files.length > 0) {
            filesInput.value.files = event.dataTransfer.files
        }

        hovering.value = false;
        onChange();
    }

    const removeDataImport = (index) => {
        draftDataImports.value.splice(index, 1);
    }

    onBeforeMount(() => {
        getImportOptions();
    })
</script>

<style scoped>
    .uploader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        min-height: 200px;
        padding: 2rem;
        border: 2px dashed #dee2e6;
        border-radius: 20px; 
        box-shadow: 1px 1px 1px 0 #F1F3FA,
            5px 2px 5px 0 #BDC1C5;
    }
    .upload-text-main {
        color: #0351BF;
        font-size: 1.1rem;
    }
    .bg-hover {
        background: #F1F3FA;
    }
    .bg-normal {
        background: #fff;
    }
    .file-input {
        opacity: 0;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
    .file-input-label {
        display: block;
        cursor: pointer;
        color: #BDC1C5;
        font-size: 1rem;
    }
    .file-input-icon {
        fill: #BDC1C5;
        margin-bottom: 2rem;
    }
    .file-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .file-item {
        width: 80%;
        padding: 1rem;
        border: 2px dashed #dee2e6;
        border-radius: 20px; 
        margin-top: 0.5rem;
        box-shadow: 1px 1px 1px 0 #F1F3FA,
            5px 2px 5px 0 #BDC1C5;
    }
</style>