<template>
    <div class="card">
        <div class="card-body">
            <i :class="iconClass + ' float-end text-muted'"></i>
            <h6 class="text-muted text-uppercase mt-0">{{ title }}</h6>
            <h2 class="m-b-20">{{ value }}</h2>
            <div class="d-flex align-items-center">
                <div v-if="change" class="badge bg-primary me-2">{{ change }}</div>
                <div v-if="change" class="text-muted">{{ changeLabel }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfileTileCard",
    props: {
        title: String,
        value: String|Number,
        change: {
            type: String,
            required: false,
            default: null,
        },
        changeLabel: {
            type: String,
            required: false,
            default: "From previous period",
        },
        iconClass: {
            type: String,
            required: false,
            default: 'mdi mdi-checkbox-blank-circle-outline',
        }
    }
}
</script>

<style scoped>
i {
    font-size: 2em;
    opacity: .3;
}
</style>
