import { readonly, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { usePaginationStore } from "@stores/PaginationStore";
import LocationRestriction, { LocationRestrictionChange } from "@classes/LocationRestriction";
import { useMessageStore } from "@stores/MessageStore";
import { copyValues } from "@components/Utils";

export const useLocationRestrictionStore = defineStore('locationRestrictionStore', () => {
    const paginateStore = usePaginationStore();
    const { paginateParams, paginatedMeta } = storeToRefs(paginateStore);

    const messageStore = useMessageStore();
    const { addToastMessage, addFieldError } = messageStore;

    const restrictions = ref<App.Resource.LocationRestrictionResource[]>([]);
    const restriction = ref<App.Resource.LocationRestrictionResource>();
    const loadingRestrictions = ref<boolean>(false);

    const getRestrictions = (vehicle: string | undefined): void => {
        loadingRestrictions.value = true;

        const params = ref<object>(copyValues(paginateParams.value));

        if (vehicle) {
            params.value.vehicle =  vehicle;
        }

        LocationRestriction.getAll(params.value)
        .then((resp) => {
            restrictions.value = resp.data?.data;
            paginatedMeta.value = resp.data?.meta;
        })
        .catch((err) => {
            addToastMessage('error', 'Failed to get Location Restrictions');
            console.error(err);
        }).finally(() => {
            loadingRestrictions.value = false;
        })
            
    };

    const changeRestrictionDefault = readonly<object>({
        uuid: '',
        location: null,
        type: 'vehicle',
        vehicle: {
            label: '',
            value: '',
            image: '',
        },
        comment: {
            value: '',
            required: false,
            valid: true,
            errors: [],
        }
    });

    const changeRestriction = ref<object>(copyValues(changeRestrictionDefault));
    const storingRestriction = ref<boolean>(false);

    const storeRestriction = (vehicle: string | undefined) => {
        storingRestriction.value = true;
        const values: LocationRestrictionChange = getChangeData();

        LocationRestriction.store(values)
        .then((resp) => {
            restriction.value = resp.data.data;
            addToastMessage('success', 'Create Location Restiction Successful');
            getRestrictions(vehicle);
        })
        .catch((err) => {
            addToastMessage('error', 'Failed to create Location Restriction')
            console.error(err);
        })
        .finally(() => {
            storingRestriction.value = false;
        });

        return true;
    };

    const updatingRestriction = ref<boolean>(false);

    const updateRestriction = (vehicle: string | undefined) => {
        updatingRestriction.value = true;
        const values: LocationRestrictionChange = getChangeData();

        LocationRestriction.update(changeRestriction.value.uuid, values)
        .then(() => {
            addToastMessage('success', 'Update Location Restiction Successful');
            getRestrictions(vehicle);
        })
        .catch((err) => {
            addToastMessage('error', 'Failed to update Location Restriction')
            console.error(err);
        })
        .finally(() => {
            updatingRestriction.value = false;
        });
    }

    const deleteRestriction = (vehicle: string | undefined) => {
        LocationRestriction.delete(restriction.value.uuid)
        .then(() => {
            addToastMessage('success', 'Location Restiction Deleted Successful');
            getRestrictions(vehicle);
        }).catch((err) => {
            addToastMessage('error', 'Failed to delete Location Restriction')
            console.error(err);
        });
    }

    const getChangeData = (): LocationRestrictionChange => {
        return {
            location: changeRestriction.value?.location?.uuid,
            type: 'vehicle',
            vehicle: changeRestriction.value?.vehicle?.value,
            comment: changeRestriction.value?.comment?.value,
        }
    }

    return {
        paginateParams,
        paginatedMeta,
        restrictions,
        restriction,
        changeRestriction,
        changeRestrictionDefault,
        loadingRestrictions,
        storingRestriction,
        updatingRestriction,
        getRestrictions,
        storeRestriction,
        updateRestriction,
        deleteRestriction,
    };
})