import Booking from "@classes/Booking";
import BookingDetailsModal from "./BookingDetailsModal.vue";
import { nextTick, ref } from "vue";

export interface ShowForBookingOptionsInterface {
    onDismiss?: () => void;
}

const bookingDetailsPlugin = {
    install: (app: any, customOptions: any = {}) => {
        const bookingDetails: any = {};
        let booking = ref<App.Models.Booking | null>(null);
        let reallocatedBooking = ref<App.Models.Booking | null>(null);
        let options = ref<ShowForBookingOptionsInterface>({});

        bookingDetails.showForBooking = (
            showForBooking: App.Models.Booking,
            showForBookingOptions: ShowForBookingOptionsInterface = {
                onDismiss: () => {},
            }
        ) => {
            // Force the watcher to trigger
            booking.value = null;
            nextTick(() => {
                booking.value = showForBooking;
            });
            options.value = showForBookingOptions;
        };

        bookingDetails.updateAllocatedBooking = (
            booking: App.Models.Booking
        ) => {
            reallocatedBooking.value = booking;
        };

        bookingDetails.showForBookingId = (
            uuid: string,
            showForBookingOptions: ShowForBookingOptionsInterface = {
                onDismiss: () => {},
            }
        ) => {
            Booking.show(uuid).then((response) => {
                // Force the watcher to trigger
                booking.value = null;
                nextTick(() => {
                    booking.value = response.data;
                });
                options.value = showForBookingOptions;
            });
        };

        bookingDetails.getAllocatedBooking = () => {
            return reallocatedBooking.value
        }

        app.provide("booking", booking);
        app.provide("bookingDetails", bookingDetails);
        app.provide("showForBookingOptions", options);
        app.component("booking-details-modal", BookingDetailsModal);
    },
};
export default bookingDetailsPlugin;
