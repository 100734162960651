<template>
    <h4 class="mt-0">Welcome to Trips!</h4>
    <p class="text-muted mb-4">Add your details below to create using your account.</p>

    <div v-if="errors.general" class="alert alert-danger">
        {{ errors.general[0] }}
    </div>

    <div class="mb-3 has-validation">
        <label for="name" class="form-label">Full Name</label>
        <input
            id="name"
            name="name"
            type="text"
            v-model="name"
            :class="{ 'form-control': true, 'is-invalid': errors.name }"
            placeholder="Firstname Lastname"
            aria-describedby="name_feedback"
        >
        <div v-if="errors.name" id="name_feedback" class="invalid-feedback">
            {{ errors.name[0] }}
        </div>
    </div>
    <div class="mb-3 has-validation">
        <label for="email" class="form-label">Email</label>
        <input
            id="email"
            name="email"
            type="email"
            disabled
            v-model="email"
            :class="{ 'form-control': true, 'is-invalid': errors.email }"
            placeholder="Enter your email"
            aria-describedby="email_feedback"
        >
        <div v-if="errors.email" id="email_feedback" class="invalid-feedback">
            {{ errors.email[0] }}
        </div>
    </div>
    <div class="mb-3 has-validation">
        <label for="username" class="form-label">Username</label>
        <input
            id="username"
            name="username"
            type="text"
            v-model="username"
            :class="{ 'form-control': true, 'is-invalid': errors.username }"
            placeholder="your.unique.username"
            aria-describedby="username_feedback"
        >
        <div v-if="errors.username" id="username_feedback" class="invalid-feedback">
            {{ errors.username[0] }}
        </div>
    </div>
    <div class="mb-3">
        <label for="password" class="form-label">Create A Password</label>
        <input
            id="password"
            name="password"
            type="password"
            v-model="password"
            :class="{ 'form-control': true, 'is-invalid': errors.password }"
            placeholder="Make it strong"
            aria-describedby="password_feedback"
        >
        <div v-if="errors.password" id="password_feedback" class="invalid-feedback">
            {{ errors.password[0] }}
        </div>
    </div>
    <div class="mb-3">
        <label for="password_confirmation" class="form-label">Confirm Password</label>
        <input
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            v-model="password_confirmation"
            :class="{ 'form-control': true, 'is-invalid': errors.password_confirmation }"
            placeholder="Type password again"
            aria-describedby="password_confirmation_feedback"
        >
        <div v-if="errors.password_confirmation" id="password_confirmation_feedback" class="invalid-feedback">
            {{ errors.password_confirmation[0] }}
        </div>
    </div>
    <button type="button" @click="register" class="btn btn-primary">Continue</button>

</template>

<script>
export default {
    name: "AcceptInvitation",
    props: {
        initialEmail: {
            required: false,
            type: String,
        },
        tenant: {
            required: true,
            type: Number,
        },
        token: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            name: '',
            email: '',
            username: '',
            password: '',
            password_confirmation: '',
            errors: {},
        }
    },
    watch: {
        username: function () {

            this.errors['username'] = null

            this.$http
                .get(this.route('api.users.check-username', {
                    username: this.username
                }))
                .then((response) => {
                    if (response.data.unique === false) {
                        this.errors['username'] = ['This username is taken.']
                    }
                })
        }
    },
    methods: {
        register() {
            this.validate().then(() => {
                this.$http
                    .post(this.route('auth.register.invitation'), {
                        name: this.name,
                        email: this.email,
                        username: this.username,
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                        tenant: this.tenant,
                        token: this.token,
                    })
                    .then((response) => {
                        window.location.href = response.request.responseURL
                    })
                    .catch((response) => {
                        this.errors = response.response.data.errors
                    })
            })
        },
        async validate() {
            return new Promise((pass, fail) => {
                this.errors = {}

                if (this.name.trim().length === 0) {
                    this.errors['name'] = ['Please enter your name.']
                }

                if (this.email.trim().length === 0) {
                    this.errors['email'] = ['Please enter your email address.']
                }

                if (this.username.trim().length === 0) {
                    this.errors['username'] = ['Please enter a username for your account.']
                }

                if (this.password.trim().length < 8) {
                    this.errors['password'] = ['Your password will need to be at least 8 characters long.']
                }

                if (this.password.trim() !== this.password_confirmation.trim()) {
                    this.errors['password'] = ['Passwords do not match.']
                    this.errors['password_confirmation'] = ['Passwords do not match.']
                }

                if (Object.keys(this.errors).length > 0) {
                    fail()
                    return
                }

                pass()
            })
        },
    },
    beforeMount() {
        this.email = this.initialEmail
    }
}
</script>

<style scoped>

</style>
