<template>
    <div>
        <EditableInput
            :label="currencyFilter(transaction.amount.toString())"
            :value="transaction.amount.toString()"
            :loading="loading"
            @updated="updateAmount"
        />
    </div>
</template>

<script setup>
import EditableInput from "@components/Forms/EditableFields/EditableInput.vue";
import {inject, ref} from 'vue'
import {currencyFilter} from "@components/Utils";

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const axios = inject('axios')
const toast = inject('toast')
const emit = defineEmits(['updated'])
const loading = ref(false)
const updateAmount = (newValue) => {
    if (props.transaction.amount === newValue) {
        return
    }

    loading.value = true

    axios
        .put(route('api.transactions.update', {transaction: props.transaction.uuid}), {
            amount: parseFloat(newValue) * 100,
        })
        .then((response) => {
            props.transaction.amount = newValue
            emit('updated', { transaction: props.transaction })
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            loading.value = false
        })
}

</script>

<style scoped>

</style>
