<template>
    <div class="my-2">
        <Button
            :icon="mdiPlusCircle"
            color="primary"
            size="small"
            @click="showCreateBreakModal"
        >Add Break</Button>
    </div>
    <p v-if="!journey.breaks.length">
        This vehicle run has no driver breaks.
    </p>
    <div v-else class="table-responsive">
        <table :key="tableRefreshKey" class="table table-sm table-striped text-xs">
            <thead>
            <tr>
                <th class="col-4">Break Start</th>
                <th class="col-4">Break End</th>
                <th class="col-4">Duration</th>
                <th class="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
                <component
                    v-for="(journeyBreak, index) in sortedJourneyBreaks"
                    :key="index"
                    :is="VerificationBookingBreak"
                    :journey="journey"
                    :journey-break="journeyBreak"
                    @updated="updateBreak"
                    @confirmDelete="confirmDelete"
                ></component>
            </tbody>
        </table>
    </div>
    <div id="createBreakModal" class="modal fade" aria-labelledby="createBreakModalLabel" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="createBreakModalLabel" class="modal-title">Add Break</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <FormDateTimeInput
                                v-model:input="journeyBreak.start_time"
                                label="Break start time"
                                :min="toDateTimeInput(journey.start_time)"
                                :max="toDateTimeInput(journey.end_time)"
                            />
                        </div>
                        <div class="col-md-6">
                            <FormDateTimeInput
                                v-model:input="journeyBreak.end_time"
                                label="Break end time"
                                :min="toDateTimeInput(journey.start_time)"
                                :max="toDateTimeInput(journey.end_time)"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <Button
                        color="primary"
                        @click="storeBreak"
                        :loading="storing"
                    >Save Break</Button>
                </div>
            </div>
        </div>
    </div>
    <ConfirmationModal
        id="confirmBreakDelete"
        confirmationButtonColor="danger"
        @confirmed="deleteBreak"
        :loading="deleting"
    >
        <template #confirm-button-text>
            <Spinner
              v-if="deleting"
              color="white"
              type="border"
              size="small"
            /> Delete
        </template>
    </ConfirmationModal>
</template>

<script setup>
import { ref, inject, onMounted, computed } from 'vue'
import VerificationBookingBreak from "./VerificationBookingBreak.vue"
import ConfirmationModal from '../ConfirmationModal.vue'
import { FormDateTimeInput } from '../Forms'
import Button from '../Button.vue'
import Spinner from '../Spinner.vue'
import {mdiPlusCircle} from '@mdi/js'
import SvgIcon from "@jamescoyle/vue-icon"
import { toTimeStamp, toDateTimeInput, transformInput, formatDate } from "@components/Utils.js"
import {DateTime} from 'luxon'

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    journey: {
        type: Object,
        required: true
    }
})

const sortedJourneyBreaks = computed(() => {
    return props.journey.breaks.sort((a, b) => {
        return a.start_time.localeCompare(b.start_time)
    })
})

const tableRefreshKey = ref(Date.now())

const journeyBreak = ref({
    id: null,
    start_time: transformInput(),
    end_time: transformInput(),
})

const showCreateBreakModal = () => {
    createBreakModal.value.show()
}

const updateBreak = (updatedBreak) => {
    props.journey.breaks.splice(
        props.journey.breaks.findIndex(breakItem => breakItem.id === updatedBreak.id), 1, updatedBreak
    )
    tableRefreshKey.value = Date.now()
}

const deletingBreak = ref({})
const confirmDelete = (journeyBreak) => {
    deletingBreak.value = journeyBreak
    confirmDeleteModal.value.show()
}

const storing = ref(false)
const storeBreak = () => {
    storing.value = true;

    axios
        .post(route('api.journeys.breaks.store', {
            journey: props.journey.uuid,
            start_time: toTimeStamp(journeyBreak.value.start_time.value),
            end_time: toTimeStamp(journeyBreak.value.end_time.value),
        }))
        .then(response => {
            props.journey.breaks.push(response.data)
            props.journey.breaks.sort((a, b) => a.start_time.localeCompare(b.start_time))
            tableRefreshKey.value = Date.now()
            createBreakModal.value.hide()
        }).catch(error => {
            if (error.response.status === 422) {
                Object.entries(error.response.data.errors).forEach(([key, value]) => {
                    journeyBreak.value[key].errors = value
                })
            }
            toast.error('There was an error creating the break.')
        }).finally(() => {
            storing.value = false;
        })
}

const deleting = ref(false)
const deleteBreak = () => {
    deleting.value = true
    axios
        .delete(route('api.journeys.breaks.destroy', {
            journey: props.journey.uuid,
            break: deletingBreak.value.id
        }))
        .then(response => {
            const index = props.journey.breaks.findIndex(b => b.id === deletingBreak.value.id)
            props.journey.breaks.splice(index, 1)
            tableRefreshKey.value = Date.now()
            confirmDeleteModal.value.hide()
            deletingBreak.value = {}
        }).catch(error => {
            toast.error('There was an error deleting the break.')
        }).finally(() => {
            deleting.value = false;
        })
}

const createBreakModal = ref(null)
const confirmDeleteModal = ref(null)
onMounted(() => {
    createBreakModal.value = new bootstrap.Modal(document.getElementById('createBreakModal'))
    confirmDeleteModal.value = new bootstrap.Modal(document.getElementById('confirmBreakDelete'))
})

</script>
