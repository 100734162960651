<template>
    <div>
        <h4 class="header-title mt-0 mb-3">Vehicle Schedule</h4>
        <VehicleScheduleCalendar
            :key="calendarRefreshKey"
            :vehicle="vehicle"
            :height="'600px'"
            @eventClicked="handleEventClicked"
            @selected="handleSelected"
        ></VehicleScheduleCalendar>

        <div id="scheduleModal" aria-hidden="true" aria-labelledby="scheduleModal" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div v-if="mode === 'Create'" class="modal-header">
                        <div class="d-flex">
                            <div class="form-check me-2">
                                <input
                                    v-model="scheduleType"
                                    value="run"
                                    class="form-check-input"
                                    type="radio"
                                    name="schedule_type"
                                >
                                <label class="form-check-label fw-normal" for="periodTypeAvailable">
                                    Vehicle Run
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    v-model="scheduleType"
                                    value="unavailability"
                                    class="form-check-input"
                                    type="radio"
                                    name="schedule_type"
                                >
                                <label class="form-check-label fw-normal" for="periodTypeUnavailable">
                                    Unavailability Period
                                </label>
                            </div>
                        </div>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div v-else class="modal-header">
                        <h5 class="modal-title" v-if="scheduleType === 'run'">Vehicle Run <span class="badge bg-light text-dark">{{runUpdating.uuid}}</span></h5>
                        <h5 class="modal-title" v-else>Unavailability Period</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>

                    <div v-if="scheduleType === 'run'" class="modal-body">
                        <CreateOrEditVehicleRun
                            v-if="createRunStartTime && createRunEndTime"
                            :key="modalRefreshKey"
                            ref="vehicleRunCreateForm"
                            :end-time="createRunEndTime"
                            :start-time="createRunStartTime"
                            :earliest-time="createRunEarliestTime"
                            :latest-time="createRunLatestTime"
                            :vehicle="vehicle"
                            :run-updating="runUpdating"
                            :vehicle-run-statuses="vehicleRunStatuses"
                            @created="handleCreatedRuns"
                            @updated="handleUpdatedRuns"
                            @deleted="handleDeletedRuns"
                            @cancelled="handleCancelledRuns"
                            :mode="mode"
                        />
                    </div>
                    <div v-else class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                <FormDateTimeInput
                                    v-model:input="period.start_time"
                                    :key="modalRefreshKey + '_start_time'"
                                    label="Unavailable from"
                                    placeholder="dd/mm/yyyy, hh:mm"
                                />
                            </div>
                            <div class="col-md-4">
                                <FormDateTimeInput
                                    v-model:input="period.end_time"
                                    :key="modalRefreshKey + '_end_time'"
                                    label="Unavailable until"
                                    placeholder="dd/mm/yyyy, hh:mm"
                                />
                            </div>
                        </div>
                        <div class="mt-3">
                            <FormTextarea
                                v-model:input="period.notes"
                                :key="modalRefreshKey + '_notes'"
                                label="Notes"
                                placeholder="e.g. Scheduled maintenance period"
                                maxlength="255"
                            />
                        </div>
                        <div v-if="periodUpdating === null" class="mt-3 row">
                            <div class="col-md-8">
                                <div class="form-check">
                                    <input id="rescheduleCheckbox" v-model="showRescheduleOptions" class="form-check-input"
                                           type="checkbox" value="">
                                    <label class="form-check-label" for="rescheduleCheckbox">
                                        This will be a regular unavailability period
                                    </label>
                                </div>
                                <div v-if="showRescheduleOptions" class="border rounded p-3 mt-2">
                                    <RescheduleOptions
                                        v-model="period.reschedule"
                                        :errors="errors"
                                        event-type="unavailability period"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="periodUpdating && periodUpdating.recursion_key" class="mt-3 form-check">
                            <input
                                v-model="period.update_series"
                                class="form-check-input"
                                type="checkbox"
                                id="updateFutureEvents"
                            >
                            <label class="form-check-label" for="updateFutureEvents">Update all future unavailability periods linked with this one.</label>
                        </div>
                        <div class="mt-3 d-flex align-items-center justify-content-between">
                            <div v-if="periodUpdating">
                                <div class="btn-group" role="group">
                                    <Button :loading="storing" color="outline-danger" @click="deletePeriod(false)">Delete</Button>
                                    <div v-if="periodUpdating.recursion_key" class="btn-group" role="group">
                                        <button
                                            :disabled="storing"
                                            id="deleteAllButtonGroup"
                                            type="button"
                                            class="btn btn-outline-danger dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        ><span class="visually-hidden">More Options</span></button>
                                        <ul class="dropdown-menu" aria-labelledby="deleteAllButtonGroup">
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    href="javascript:void(0)"
                                                    @click="deletePeriod(true)"
                                                >
                                                    Delete all future periods linked with this one
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Button v-if="periodUpdating" :loading="storing" @click="updatePeriod">Save Changes</Button>
                            <Button v-else :loading="storing" @click="storePeriod">Create Period</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import VehicleScheduleCalendar from "@components/Vehicles/VehicleScheduleCalendar.vue";
import {inject, onMounted, ref} from "vue";
import RescheduleOptions from "@components/RescheduleOptions.vue";
import {FormDateTimeInput, FormTextarea} from "../Forms";
import Button from "@components/Button.vue";
import {DateTime} from "luxon";
import CreateOrEditVehicleRun from "@components/Vehicles/VehicleRuns/CreateOrEditVehicleRun.vue";
import axios from "axios";
import {toTimeStamp} from "@components/Utils";
const toast:any = inject('toast');
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

const calendarRefreshKey = ref(Date.now());
const scheduleModal:any = ref(null);
const mode = ref('Create');
const scheduleType = ref('run');
const errors = ref({});
const periodUpdating:any = ref(null);
const modalRefreshKey = ref(Date.now());
const storing = ref(false);
const showRescheduleOptions = ref(false);
const createRunStartTime = ref('');
const createRunEndTime = ref('');
const createRunEarliestTime = ref('');
const createRunLatestTime = ref('null');
const runUpdating = ref<any>({});
const vehicleRunStatuses = ref([]);

const props = withDefaults(
    defineProps<{
        vehicle: App.Models.Vehicle;
    }>(),
    {
    }
);

const vehicle = ref(props.vehicle);

const period:any = ref({
    start_time: {
        value: null,
        required: true,
    },
    end_time: {
        value: null,
        required: true,
    },
    notes: {
        value: '',
        required: false,
    },
    reschedule: {
        period: 'day',
        frequency: '1',
        weekdays: [],
        monthDays: [],
        monthly: 'exact',
        end: DateTime.now().plus({ months: 2 }).toSeconds().toFixed(0),
        dynamicMonthDay: '1',
        dynamicMonthWeekday: 'day',
        continuous: false,
    },
    update_series: true,
})

const handleEventClicked = function (event) {
    mode.value = 'Update';
    scheduleType.value = event.event.extendedProps.type;

    if (scheduleType.value === 'run') {
        axios.get(route('api.vehicle.run.show', {vehicle: props.vehicle.uuid, run: event.event.extendedProps.uuid}))
            .then(response => {
                runUpdating.value = response.data;

                modalRefreshKey.value = Date.now()
                createRunStartTime.value = DateTime.fromISO(runUpdating.value.start_time).toISO({
                    includeOffset: false,
                    suppressMilliseconds: true,
                    suppressSeconds: true,
                })
                createRunEndTime.value = DateTime.fromISO(runUpdating.value.end_time).toISO({
                    includeOffset: false,
                    suppressMilliseconds: true,
                    suppressSeconds: true,
                });
                createRunEarliestTime.value = DateTime.fromISO(runUpdating.value.earliest_time).toISO({
                    includeOffset: false,
                    suppressMilliseconds: true,
                    suppressSeconds: true,
                });
                createRunLatestTime.value = DateTime.fromISO(runUpdating.value.latest_time).toISO({
                    includeOffset: false,
                    suppressMilliseconds: true,
                    suppressSeconds: true,
                });

                scheduleModal.value.show();
            });
    } else {
        periodUpdating.value = JSON.parse(JSON.stringify(event.event.extendedProps))
        modalRefreshKey.value = Date.now()
        period.value.start_time.value = DateTime.fromSeconds(periodUpdating.value.start_time).toISO({
            includeOffset: false,
            suppressMilliseconds: true,
            suppressSeconds: true,
        })
        period.value.end_time.value = DateTime.fromSeconds(periodUpdating.value.end_time).toISO({
            includeOffset: false,
            suppressMilliseconds: true,
            suppressSeconds: true,
        })
        period.value.notes.value = periodUpdating.value.notes ?? '';
        scheduleModal.value.show();
    }
}

const handleSelected = function (selected) {
    mode.value = 'Create';

    modalRefreshKey.value = Date.now();
    periodUpdating.value = null
    period.value.start_time.value = DateTime.fromISO(selected.startStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    })
    period.value.end_time.value = DateTime.fromISO(selected.endStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    })
    period.value.notes.value = '';

    createRunStartTime.value = DateTime.fromISO(selected.startStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    });
    createRunEndTime.value = DateTime.fromISO(selected.endStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    });
    createRunEarliestTime.value = DateTime.fromISO(selected.startStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    });
    createRunLatestTime.value = DateTime.fromISO(selected.endStr).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    });

    scheduleModal.value.show();
}

const handleCreatedRuns = function (runs) {
    scheduleModal.value.hide();
    calendarRefreshKey.value = Date.now();
}

const handleUpdatedRuns = function (...runs) {
    scheduleModal.value.hide();
    calendarRefreshKey.value = Date.now();
}

const handleDeletedRuns = function (runUuid) {
    scheduleModal.value.hide();
    calendarRefreshKey.value = Date.now();
}

const handleCancelledRuns = function (run) {
    scheduleModal.value.hide();
    calendarRefreshKey.value = Date.now();
}

const deletePeriod = function (all_in_series = false) {
    storing.value = true

    axios
        .delete(
            route(
                'api.vehicles.unavailability-periods.destroy',
                {
                    vehicle: props.vehicle.uuid,
                    unavailability_period: periodUpdating.value.uuid,
                    delete_series: all_in_series
                }
            )
        )
        .then((response) => {
            // handle successful response
            toast.success('You successfully removed this unavailability period.')
            scheduleModal.value.hide();
            calendarRefreshKey.value = Date.now()
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            storing.value = false
        });

}

const storePeriod = function () {
    storing.value = true

    axios
        .post(route('api.vehicles.unavailability-periods.store', {vehicle: props.vehicle.uuid}), {
            start_time: period.value.start_time.value ? toTimeStamp(period.value.start_time.value) : null,
            end_time: period.value.end_time.value ? toTimeStamp(period.value.end_time.value) : null,
            notes: period.value.notes.value,
            recursion: showRescheduleOptions.value ? period.value.reschedule : {},
        })
        .then((response) => {
            // handle successful response
            toast.success('You successfully created a new unavailability period')
            scheduleModal.value.hide();
            calendarRefreshKey.value = Date.now()
        })
        .catch((error) => {
            toast.error(error.response.data.message)
            errors.value = error.response.data.errors
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                period.value[field].errors = errors
            }
        })
        .finally(() => {
            storing.value = false
        })
}

const updatePeriod = function () {
    storing.value = true

    axios
        .put(
            route(
                'api.vehicles.unavailability-periods.update',
                {vehicle: props.vehicle.uuid, unavailability_period: periodUpdating.value.uuid}
            ),
            {
                start_time: period.value.start_time.value ? toTimeStamp(period.value.start_time.value) : null,
                end_time: period.value.end_time.value ? toTimeStamp(period.value.end_time.value) : null,
                notes: period.value.notes.value,
                update_series: period.value.update_series,
            }
        )
        .then((response) => {
            // handle successful response
            toast.success('You successfully updated this unavailable period.')
            scheduleModal.value.hide();
            calendarRefreshKey.value = Date.now()
        })
        .catch((error) => {
            toast.error(error.response.data.message)
            errors.value = error.response.data.errors
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                period.value[field].errors = errors
            }
        })
        .finally(() => {
            storing.value = false
        })
}

onMounted(() => {
    const modal:any = document.getElementById('scheduleModal');
    scheduleModal.value = new bootstrap.Modal(modal);
    modal.addEventListener('hidden.bs.modal', () => {
        runUpdating.value = {}
    })

    axios
        .get(route('api.vehicle-run-statuses'))
        .then(response => {
            vehicleRunStatuses.value = response.data
        })
        .catch(error => {
            toast.error('Error loading vehicle run statuses')
        })
})

</script>

<style scoped>

</style>
