<template>
    <div>
        <span
            v-if="!editDOBForm"
            @click="showEditDOBForm"
            :class="[ !editable ? 'text-muted' : 'click-to-edit' ]"
        >
            <template v-if="client.date_of_birth">
                <DateTime :date-time="client.date_of_birth" format="medium date"></DateTime>
                <span v-if="client.date_of_birth_is_estimated" class="ms-1">(estimated)</span>
                <span v-if="age" class="small ms-1">({{ age }})</span>
            </template>
            <template v-if="!client.date_of_birth"><small><em>Click to add</em></small></template>
        </span>
        <template v-if="editDOBForm">
            <div class="mb-1">
                <label for="clientDOB" class="visually-hidden">Date of birth:</label>
                <input
                    v-focus
                    v-model="dateOfBirth"
                    :disabled="updating"
                    type="date"
                    class="form-control form-control-sm"
                >
            </div>
            <div class="form-check mb-1">
                <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="dobIsEstimated"
                    id="dobEstimated"
                >
                <label class="form-check-label" for="dobEstimated">
                    Date of birth is estimated
                </label>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <Button size="small" :loading="updating" @click="update">Save</Button>
                <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "EditClientDOB"
}
</script>
<script setup>
import DateTime from "../DateTime.vue";
import {computed, ref, onBeforeMount} from "vue";
import {DateTime as Date, Interval} from "luxon";
import humanizeDuration from "humanize-duration";
import axios from "axios";
import Button from "../Button.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const dateOfBirth = ref(null)
const dobIsEstimated = ref(null)

const errors = ref({})
const updating = ref(false)
const update = function () {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            date_of_birth: dateOfBirth.value,
            date_of_birth_is_estimated: dobIsEstimated.value,
        })
        .then((response) => {
            emit('updated', response.data)
            editDOBForm.value = false
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

const editDOBForm = ref(false)
const showEditDOBForm = function () {
    if (!editable.value) {
        return
    }

    editDOBForm.value = true
}

const age = computed(() => {
    if (!props.client.date_of_birth) {
        return null
    }

    const formatted = Interval
        .fromDateTimes(Date.fromISO(props.client.date_of_birth), Date.now())
        .toDuration()
        .valueOf()

    if (isNaN(formatted)) {
        return null
    }

    return humanizeDuration(formatted, { round: false, largest: 1 }) + ' old'
})

const cancelChanges = function () {
    initialiseData()
    editDOBForm.value = false
}

const initialiseData = function () {
    dateOfBirth.value = Date.fromISO(props.client.date_of_birth).toFormat('yyyy-MM-dd')
    dobIsEstimated.value = props.client.date_of_birth_is_estimated
}
onBeforeMount(() => {
    initialiseData()
})

</script>

<style scoped>

</style>
