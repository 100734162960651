<template>
    <div class="d-inline-flex align-items-center">
        <template v-if="type === 'origin'">
            <div class="dropdown">
                <a
                    @click.prevent="editOriginTime"
                    :id="`editBookingOriginTime${booking.uuid}`"
                    style="text-decoration:underline; text-decoration-style: dotted"
                    class="dropdown-toggle"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    href="javascript: void(0)"
                    role="button"
                >
                    <template v-if="booking.planned_origin_time">
                        <DateTime
                            :date-time="booking.planned_origin_time"
                            format="short time"
                            :key="'planned_orig_' + booking.uuid"
                        ></DateTime>
                    </template>
                    <template v-else-if="booking.estimated_origin_time">
                        <DateTime
                            :date-time="booking.estimated_origin_time"
                            format="short time"
                            :key="'est_origin_' + booking.uuid"
                        ></DateTime>
                        <span v-tooltip="'Estimated time'" class="ms-1" style="line-height: 0"><SvgIcon class="h-4 w-4 text-secondary" type="mdi" :path="mdiHelpCircle" /></span>
                    </template>
                    <template v-else>
                        <a role="button">
                            Add time
                        </a>
                    </template>
                </a>

                <div class="dropdown-menu p-2" :aria-labelledby="`editBookingOriginTime${booking.uuid}`">
                    <div class="d-inline-flex align-items-center">
                        <input
                            v-model="bookingPickupTime"
                            class="form-control form-control-sm me-1"
                            :class="{'is-invalid': invalidDate}"
                            style="max-width: 11rem"
                            type="time"
                        />
                        <a href="javascript: void(0)" class="px-1" @click="confirmTime($event, 'origin')">
                            <SvgIcon type="mdi" :path="mdiCheck" class="position-relative w-4 h-4" style="top: -1px"/>
                        </a>
                        <a href="javascript: void(0)" class="px-1">
                            <SvgIcon type="mdi" :path="mdiClose" class="position-relative w-4 h-4" style="top: -1px"/>
                        </a>
                    </div>
                </div>
            </div>
            <template v-if="booking.origin_time_requires_confirmation">
                <div v-tooltip="'Client to confirm time'" class="muted ms-1" style="line-height: 0">
                    <SvgIcon class="h-4 w-4 text-primary" type="mdi" :path="mdiAccountQuestion" />
                </div>
            </template>
        </template>
        <template v-else>
            <div class="dropdown">
                <a
                    @click.prevent="editDestinationTime"
                    :id="`editBookingDestinationTime${booking.uuid}`"
                    style="text-decoration:underline; text-decoration-style: dotted"
                    class="dropdown-toggle"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    href="javascript: void(0)"
                    role="button"
                >
                    <template v-if="booking.planned_destination_time">
                        <DateTime
                            :date-time="booking.planned_destination_time"
                            format="short time"
                            :key="'planned_orig_' + booking.uuid"
                        ></DateTime>
                    </template>
                    <template v-else-if="booking.estimated_destination_time">
                        <DateTime
                            :date-time="booking.estimated_destination_time"
                            format="short time"
                            :key="'est_origin_' + booking.uuid"
                        ></DateTime>
                        <span v-tooltip="'Estimated time'" class="ms-1" style="line-height: 0"><SvgIcon class="h-4 w-4 text-secondary" type="mdi" :path="mdiHelpCircle" /></span>
                    </template>
                    <template v-else>
                        <a role="button">
                            Add time
                        </a>
                    </template>
                </a>

                <div class="dropdown-menu p-2" :aria-labelledby="`editBookingDestinationTime${booking.uuid}`">
                    <div class="d-inline-flex align-items-center">
                        <input
                            v-model="bookingDestinationTime"
                            class="form-control form-control-sm me-1"
                            :class="{'is-invalid': invalidDate}"
                            style="max-width: 11rem"
                            type="time"
                        />
                        <a href="javascript: void(0)" class="px-1" @click="confirmTime($event, 'destination')">
                            <SvgIcon type="mdi" :path="mdiCheck" class="position-relative w-4 h-4" style="top: -1px"/>
                        </a>
                        <a href="javascript: void(0)" class="px-1">
                            <SvgIcon type="mdi" :path="mdiClose" class="position-relative w-4 h-4" style="top: -1px"/>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "BookingTime"
}
</script>
<script setup>
import DateTime from "@components/DateTime.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiHelpCircle, mdiAccountQuestion, mdiCheck, mdiClose} from "@mdi/js";
import {inject, ref, watch, onMounted} from 'vue';
import {DateTime as LuxonDateTime} from 'luxon';
import { toDateTimeInput } from '../Utils';
import BookingDatetime from "@classes/DateHelpers/BookingDatetime";

const axios = inject('axios')
const toast = inject('toast')
const rescheduleBooking = inject('rescheduleBooking')

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
})

watch(rescheduleBooking.getUpdatedBooking, (updatedBooking) => {
    if (props.booking.uuid === updatedBooking.uuid) {
        const newBookingDate = rescheduleBooking.getNewBookingDate()

        if (newBookingDate.timePoint === 'origin') {
            props.booking.planned_origin_time = newBookingDate.dateTime.toISO()
        } else {
            props.booking.planned_destination_time = newBookingDate.dateTime.toISO()
        }

        props.booking.journey = updatedBooking.journey
        props.booking.journey_id = updatedBooking.journey_id
    }
}, { deep: true })

const updatingTime = ref(false)
const invalidDate = ref(false)
const confirmTime = (event, timePoint) => {

    // Parse the string time to create a Luxon DateTime object
    const parsedTime = LuxonDateTime.fromFormat(timePoint === 'origin' ? bookingPickupTime.value : bookingDestinationTime.value, "HH:mm");

    const bookingDateTime = new BookingDatetime(props.booking);
    const displayableOriginTime = bookingDateTime.displayableOriginTime();
    const displayableDestinationTime =
        bookingDateTime.displayableDestinationTime();

    // Create a new Luxon date with the same date and the specified time
    const dateTime = (
        (timePoint == "destination"
            ? displayableDestinationTime
            : displayableOriginTime) ?? LuxonDateTime.now()
    ).set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
    });

    invalidDate.value = dateTime.invalid !== null ? true : false

    if (invalidDate.value) {
        event.stopPropagation()
        updatingTime.value = false
        toast.error('The date and time you entered is invalid. Please try again.')
        return
    }

    updatingTime.value = true

    rescheduleBooking.submitFor(props.booking, { timePoint: timePoint, dateTime: dateTime})

    if (!rescheduleBooking.shouldPromptForDeallocation()) {
        rescheduleBooking.submitReschedule()
            .then(response => {
                toast.success(`The planned ${timePoint === 'origin' ? 'pick up' : 'drop off'} time for ${props.booking.client.name} (${props.booking.uuid}) has been changed to ${dateTime.toLocaleString(LuxonDateTime.TIME_24_SIMPLE)} on ${dateTime.toLocaleString(LuxonDateTime.DATE_HUGE)}`)
            }).catch(error => {
                if (error.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('There was an unknown error updating the booking time. Our developers have been notified of this issue.')
                }
            }).finally(() => {
                updatingTime.value = false
            })
    }
}

const bookingPickupTime = ref(LuxonDateTime.fromISO(props.booking.planned_origin_time).toFormat('HH:mm'))
const bookingDestinationTime = ref(LuxonDateTime.fromISO(props.booking.planned_destination_time).toFormat('HH:mm'))

const editOriginTime = () => {
    bookingPickupTime.value = LuxonDateTime.fromISO(props.booking.planned_origin_time ?? props.booking.estimated_origin_time).toFormat('HH:mm')
}
const editDestinationTime = () => {
    bookingDestinationTime.value = LuxonDateTime.fromISO(props.booking.planned_destination_time ?? props.booking.estimated_destination_time).toFormat('HH:mm')
}

</script>

<style scoped>

</style>
