<template>
    <div>
        <Modal
            :show="true"
            :title="title"
            @update:show="cancel"
        >
            <template v-slot:body>
                <div class="row">
                    <div class="col">
                        <input
                            type="text"
                            class="form-control"
                            :value="modelValue"
                            @input="$emit('update:modelValue', $event.target.value)"
                        />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <Button @click="cancel" class="btn btn-secondary mx-1">Cancel</Button>
                <Button @click="save" class="btn btn-primary">Save</Button>
            </template>
        </Modal>
    </div>
</template>

<style scoped>

</style>

<script setup lang="ts">
import Button from "@components/Button.vue";
import {onMounted, ref} from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import Modal from "@components/Modal.vue";

const props = defineProps({
    modelValue: String,
    title: String,
});
const emit = defineEmits(['update:modelValue', 'updated', 'cancelled']);

const renameModal = ref<null | bootstrap.Modal>(null);

const cancel = () => {
    emit('cancelled')
};

const save = () => {
    emit('updated')
};

onMounted(() => {
    const modalElement = document.getElementById("renameModal");
    if (!modalElement) {
        return;
    }
    renameModal.value = new bootstrap.Modal(modalElement);
});
</script>