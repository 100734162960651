<template>
    <div>
        <a :href="this.route('auth.login.microsoft.redirect')" class="btn btn-light d-flex justify-content-center px-2" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="20" height="20"
                 viewBox="0 0 48 48"
                 style=" fill:#000000;"><path fill="#ff5722" d="M6 6H22V22H6z" transform="rotate(-180 14 14)"></path><path fill="#4caf50" d="M26 6H42V22H26z" transform="rotate(-180 34 14)"></path><path fill="#ffc107" d="M26 26H42V42H26z" transform="rotate(-180 34 34)"></path><path fill="#03a9f4" d="M6 26H22V42H6z" transform="rotate(-180 14 34)"></path></svg>
            <span class="ms-1 flex-grow-1">Sign in with Microsoft</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "MicrosoftLogin"
}
</script>
