export interface DataTableInterface {
    paginate: boolean,
    perPage?: number,
    page?: number,
    sortBy?: string | null,
    sortDirection?: sortDirectionEnum,
    query?: string | null,
}

export enum sortDirectionEnum {
    Ascending = 'asc',
    Descending = 'desc',
}