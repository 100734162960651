<template>
    <label :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
           :for="componentId">
        {{ label }}
    </label>
    <div class="input-group">
        <input
            :id="componentId"
            v-model="inputValue"
            :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
            :name="inputName"
            :placeholder="placeholder"
            :step="step"
            type="number"
        />
        <span class="input-group-text">%</span>
    </div>
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {ref, watch, onMounted} from 'vue'
import {kebabCase} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, onReady, isInvalid, getErrorMessage, setNumberOnlyFilter} from '../Utils'
import {uuid} from 'vue-uuid'

const {
    input,
    label,
    placeholder,
    inputClass,
    labelClass,
    errorClass,
    inputName,
    validation,
    optional,
    step,
    integerOnly,
} = defineProps({
    input: {
        required: true,
        type: Object,
    },
    label: {
        type: String,
        default: null,
    },
    inputName: {
        type: String,
        default: () => {
            kebabCase((Math.random() + 1).toString(36).substring(7))
        }
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputClass: {
        type: String,
        default: 'form-control',
    },
    labelClass: {
        type: String,
        default: 'form-label'
    },
    errorClass: {
        type: String,
        default: 'text-danger form-text'
    },
    validation: {
        type: Object,
        default: yup.string().max(255),
    },
    optional: {
        type: Boolean,
        default: false
    },
    step: {
        type: String,
        default: '0.01'
    },
    integerOnly: {
        type: Boolean,
        default: false
    }
})

const componentId = ref(uuid.v4())
const mounted = ref(false)
const inputValue = ref(copyValues(input.value))

onReady(() => mounted.value = true)

const emit = defineEmits(['update:input'])

const {errorMessage, setValue, validate, meta} = useField(inputName, validation)

watch(
    inputValue,
    (value) => {
        if (mounted.value) {
            setValue(value)
            validate().then(() => {
                let returnValue = copyValues(input)
                returnValue.value = meta.valid ? value : ''
                returnValue.valid = meta.valid
                emit('update:input', returnValue)
            })
        }
    }
)

onMounted(() => {
    if (integerOnly) {
        setNumberOnlyFilter([document.getElementById(componentId.value)])
    }
})

</script>
