<template>
    <div>
        <span
            v-if="!editAddressForm"
            @click="showEditAddressForm"
            :class="[ !editable ? 'text-muted' : 'click-to-edit' ]"
        >
            <template v-if="client.formatted_postal_address">{{ client.formatted_postal_address }}</template>
            <template v-else>Use home address</template>
        </span>
        <template v-if="editAddressForm">
            <div class="mb-1">
                <label for="clientPostalAddressLine1" class="visually-hidden">Address Line 1</label>
                <input
                    v-model="address.line1"
                    :disabled="updating"
                    type="text"
                    id="clientPostalAddressLine1"
                    class="form-control form-select-sm"
                    :class="{ 'is-invalid': errors.postal_line1 }"
                    name="postal_line1"
                    placeholder="PO BOX 123"
                >
            </div>
            <div class="mb-1">
                <label for="clientPostalAddressLine2" class="visually-hidden">Address Line 2</label>
                <input
                    v-model="address.line2"
                    :disabled="updating"
                    type="text"
                    id="clientPostalAddressLine2"
                    class="form-control form-select-sm"
                    :class="{ 'is-invalid': errors.postal_line2 }"
                    name="postal_line2"
                    placeholder="123 Example Road"
                >
            </div>
            <div class="row mb-1">
                <div class="col-md-5">
                    <label for="clientPostalAddressSuburb" class="visually-hidden">Suburb</label>
                    <input
                        v-model="address.suburb"
                        :disabled="updating"
                        required
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.postal_suburb }"
                        type="text"
                        id="clientPostalAddressSuburb"
                        name="postal_suburb"
                        placeholder="Shelbyville"
                        aria-describedby="postal_suburb_feedback"
                    >
                </div>
                <div class="col-md-3 px-0">
                    <label for="clientPostalAddressState" class="visually-hidden">State</label>
                    <select
                        v-model="address.state"
                        :disabled="updating"
                        required
                        class="form-select form-select-sm"
                        :class="{ 'is-invalid': errors.postal_state }"
                        name="postal_state"
                        id="clientPostalAddressState"
                        aria-describedby="postal_state_feedback"
                    >
                        <option value="">-- State --</option>
                        <option value="ACT">ACT</option>
                        <option value="NSW">NSW</option>
                        <option value="NT">NT</option>
                        <option value="OT">OT</option>
                        <option value="QLD">QLD</option>
                        <option value="SA">SA</option>
                        <option value="TAS">TAS</option>
                        <option value="VIC">VIC</option>
                        <option value="WA">WA</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="clientPostalAddressPostcode" class="visually-hidden">Postcode</label>
                    <input
                        v-model="address.postcode"
                        :disabled="updating"
                        required
                        :class="['form-control', 'form-control-sm', (Object.keys(errors).includes('postal_postcode') ? 'is-invalid' : null)]"
                        type="number"
                        max="9999"
                        maxlength="5"
                        min="1111"
                        id="clientPostalAddressPostcode"
                        name="postal_postcode"
                        placeholder="2000"
                        aria-describedby="postal_postcode_feedback"
                    >
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <Button size="small" :loading="updating" @click="updateAddress">Save</Button>
                <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "EditClientPostalAddress"
}
</script>
<script setup>
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import Button from "../Button.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])
const address = ref({})

const initialiseData = function () {
    address.value.line1 = props.client.postal_address?.line1
    address.value.line2 = props.client.postal_address?.line2
    address.value.suburb = props.client.postal_address?.suburb
    address.value.state = props.client.postal_address?.state
    address.value.postcode = props.client.postal_address?.postcode
}

const errors = ref({})
const editAddressForm = ref(false)
const showEditAddressForm = function () {
    if (!editable.value) {
        return
    }

    editAddressForm.value = true
}

const cancelChanges = function () {
    initialiseData()
    editAddressForm.value = false
}

const updating = ref(false)
const updateAddress = function () {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), { postal_address: address.value })
        .then((response) => {
            emit('updated', response.data)
            editAddressForm.value = false
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

onBeforeMount(() => {
    initialiseData()
})
</script>

<style scoped>

</style>
