<template>
    <div class="card">
        <div class="card-body">
            <a class="mb-3 btn btn-primary btn-sm" :href="route('funding-rules.create')">
                <span class="d-flex align-items-center">
                    <SvgIcon
                        class="h-4 w-4 me-1"
                        :path="mdiPlusThick"
                        type="mdi"
                    ></SvgIcon>
                    <span>
                        Create Billing Rule
                    </span>
                </span>
            </a>
            <DataTable
                v-model:filters="filters"
                :datatable="datatable"
                :headers="headers"
                :loading="loading"
                :search="false"
                record-name="Funding Rules"
                :default-sort="defaultSort"
            />
        </div>
    </div>
</template>

<script setup>
import {ref, inject, reactive, watchEffect} from 'vue'
const axios = inject('axios')
const toast = inject('toast')
import DataTable from '../DataTable.vue'
import Button from '../Button.vue'
import {currencyFilter, onReady} from '../Utils.js'
import {DateTime} from "luxon";
import {mdiPlusThick} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const mounted = ref(false)
onReady(() => {
    mounted.value = true
})

const loading = ref(true)

const defaultSort = reactive({
    column: 'name',
    direction: 'desc'
})

let filters = reactive({
    page: 1,
    perPage: 10,
    order: {
        column: 'name',
        direction: 'desc'
    }
})

const datatable = reactive({
    meta: {
        from: null,
        to: null,
        total: null,
        lastPage: null,
    },
    data: {},
})

const headers = [
    {
        class: '',
        column: 'name',
        label: 'Name',
        orderable: false,
    },
    {
        class: '',
        column: 'valid_from',
        label: 'Valid From',
        orderable: false,
    },
    {
        class: '',
        column: 'valid_until',
        label: 'Valid Until',
        orderable: false,
    },
    {
        class: '',
        column: 'funding_type',
        label: 'Funding Type',
        orderable: false,
    },
    {
        class: '',
        column: 'purpose',
        label: 'Booking Purpose',
        orderable: false,
    },
    {
        class: 'text-center',
        column: 'applies_to',
        label: 'Group / Individual',
        orderable: false,
    },
    {
        class: 'text-center',
        column: 'carer_fee',
        label: 'Additional Carer Fee',
        orderable: false,
    },
]

const fetchRow = record => {
    return {
        name: {
            id: record.uuid,
            class: null,
            content: record.name,
            type: 'link',
            href: route('funding-rules.edit', record.uuid),
        },
        valid_from: {
            class: '',
            content: record.valid_from ? DateTime.fromSeconds(record.valid_from).toLocaleString(DateTime.DATE_MED) : ''
        },
        valid_until: {
            class: '',
            content: record.valid_until ? DateTime.fromSeconds(record.valid_until).toLocaleString(DateTime.DATE_MED) : ''
        },
        funding_type: {
            class: '',
            content: record.funding_types.length > 1 ? 'Multiple' : (record.funding_types ? record.funding_types[0]?.name : ''),
        },
        purpose: {
            class: '',
            content: record.purpose?.join(', '),
        },
        applies_to: {
            class: 'text-center',
            content: record.applies_to ? 'Individual' : 'Group'
        },
        carer_fee: {
            class: 'text-center',
            content: formatCompanionFee(record)
        },
    }
}

const formatCompanionFee = (record) => {
    let feeStringParts = []
    if (record.companion_fixed_fee) {
        feeStringParts.push(currencyFilter(record.companion_fixed_fee))
    }

    if (record.companion_percentage_fee) {
        feeStringParts.push(record.companion_percentage_fee + '%')
    }

    return feeStringParts.join(' + ')
}

const getFundingRules = function () {
    loading.value = true

    let params = {
        paginate: 1,
        page: filters.page,
        limit: filters.perPage,
    }

    if (filters.order.column || filters.order.column.length > 0) {
        params['order[column]'] = filters.order.column
        params['order[direction]'] = filters.order.direction
    }

    return axios
        .get(route('api.funding-rules.index'), { params })
        .then(response => {
            let data = response.data
            datatable.data = data.data.map((record) => fetchRow(record))
            datatable.meta = {
                from: data.meta.from,
                to: data.meta.to,
                total: data.meta.total,
                lastPage: data.meta.last_page,
            }
            filters.page = data.meta.current_page
        })
        .catch(error => {
            console.error(error)
            toast.error('There was an issue loading the billing rules, please try again later.')
        })
        .finally(() => {
            loading.value = false
        })
}

watchEffect(() => {

    getFundingRules()
})

</script>

<style scoped>

</style>
