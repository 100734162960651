<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-describedby="ContactModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ContactModalLabel">{{ method }} {{ $filters.title_case(type) }} Contact</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if="loaded" class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                            <label for="contactName" class="form-label required-label">Contact Name:</label>
                            <input
                                v-model="form.name"
                                type="text"
                                class="form-control"
                                placeholder="Firstname Lastname"
                                id="contactName"
                                :class="{ 'is-invalid': errors['name'] !== undefined }"
                            >
                            <FormValidationError :errors="errors" field="name"></FormValidationError>
                        </div>
                    </div>
                    <div class="mb-3 form-check">
                        <input class="form-check-input"
                               v-model="form.is_primary"
                               type="checkbox"
                               id="contactPrimary"
                        >
                        <label class="form-check-label" for="contactPrimary">
                            Make this the primary contact
                        </label>
                    </div>
                    <div class="row mb-3">
                        <div v-if="form.type === 'emergency'" class="col-md-6 has-validation">
                            <label for="contactRelationship" class="form-label">Relationship:</label>
                            <select
                                v-model="form.relationship"
                                class="form-select"
                                id="contactRelationship"
                            >
                                <option value="">-- Select Relationship --</option>
                                <template v-for="relationship in relationships">
                                    <option :value="relationship">{{ relationship }}</option>
                                </template>
                            </select>
                            <FormValidationError :errors="errors" field="relationship"></FormValidationError>
                        </div>
                        <div v-if="form.type === 'medical'" class="col-md-6 has-validation">
                            <label for="contactOrganisation" class="form-label">Organisation Name:</label>
                            <input
                                v-model="form.organisation"
                                type="text"
                                class="form-control"
                                placeholder="e.g. Southern Health"
                                id="contactOrganisation"
                            >
                            <FormValidationError :errors="errors" field="organisation"></FormValidationError>
                        </div>
                        <div class="col-md-6 has-validation">
                            <label for="contactRole" class="form-label">Role:</label>
                            <input
                                v-model="form.role"
                                type="text"
                                class="form-control"
                                placeholder="e.g. Primary Carer"
                                id="contactRole"
                            >
                            <FormValidationError :errors="errors" field="role"></FormValidationError>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-8 has-validation">
                            <label for="contactEmail" class="form-label">Email Address:</label>
                            <input
                                v-model="form.email"
                                type="email"
                                class="form-control"
                                placeholder="example@email.com"
                                id="contactEmail"
                            >
                            <FormValidationError :errors="errors" field="email"></FormValidationError>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                            <label for="contactMobile" class="form-label required-label">Mobile Phone Number:</label>
                            <MazPhoneNumberInput
                                v-model="form.mobile_phone"
                                :key="'mobile_' + updateKey"
                                color="primary"
                                size="sm"
                                :preferred-countries="['AU']"
                                :placeholder="'Example 0412 345 678'"
                                default-country-code="AU"
                                @update="mobileValidation = $event"
                                :success="mobileValidation?.isValid"
                                id="contactMobile"
                            />
                            <FormValidationError :errors="errors" field="mobile_phone"></FormValidationError>
                        </div>
                        <div class="col-md-6 has-validation">
                            <label for="contactWorkPhone" class="form-label">Work Phone Number:</label>
                            <MazPhoneNumberInput
                                v-model="form.work_phone"
                                :key="'home_' + updateKey"
                                color="primary"
                                size="sm"
                                :preferred-countries="['AU']"
                                :placeholder="'Example 02 9876 5432'"
                                default-country-code="AU"
                                @update="workPhoneValidation = $event"
                                :success="workPhoneValidation?.isValid"
                                id="contactWorkPhone"
                            />
                            <FormValidationError :errors="errors" field="work_phone"></FormValidationError>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 has-validation">
                            <label for="contactHomePhone" class="form-label">Home Phone Number:</label>
                            <MazPhoneNumberInput
                                v-model="contact.home_phone"
                                :key="'home_' + updateKey"
                                color="primary"
                                size="sm"
                                :preferred-countries="['AU']"
                                :placeholder="'Example 02 9876 5432'"
                                default-country-code="AU"
                                @update="homePhoneValidation = $event"
                                :success="homePhoneValidation?.isValid"
                                id="contactHomePhone"
                            />
                            <FormValidationError :errors="errors" field="home_phone"></FormValidationError>
                        </div>
                        <div class="col-md-6 has-validation">
                            <label for="contactFax" class="form-label">Fax Number:</label>
                            <MazPhoneNumberInput
                                v-model="form.fax"
                                :key="'fax_' + updateKey"
                                color="primary"
                                size="sm"
                                :preferred-countries="['AU']"
                                :placeholder="'Example 02 9876 5432'"
                                default-country-code="AU"
                                @update="faxValidation = $event"
                                :success="faxValidation?.isValid"
                                id="contactFax"
                            />
                            <FormValidationError :errors="errors" field="fax"></FormValidationError>
                        </div>
                    </div>
                    <div class="row">
                        <AddressDbSearch
                            v-model="contactAddressObject"
                            @locationSelected=updateAddress
                            label="Address"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <Button :loading="saving" @click="saveContact">{{ method }} Contact</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { ref, reactive, onBeforeMount, watch, computed, inject, onMounted } from 'vue'
import axios from "axios";
import AddressDbSearch from "../AddressDbSearch.vue";
import { FormValidationError } from "../Forms";
import Button from "../Button.vue";
import ElmHelper from "@classes/Helpers/ElmHelper";

const toast = inject('toast');

const props = defineProps({
    id: {
        type: String,
        default: 'Modal'
    },
    contact: {
        type: Object,
        default: () => ({})
    },
    model: {
        type: String,
        required: true
    },
    model_id: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
})

const loaded = ref(false)
const method = ref('');
const form = reactive({
    type: null,
    for_model: null,
    for: null,
    name: null,
    address: null,
    role: null,
    organisation: null,
    work_phone: null,
    home_phone: null,
    mobile_phone: null,
    fax: null,
    relationship: null,
    email: null,
    is_primary: false,
})

watch(() => props.contact, (contact) => {
    method.value = contact.uuid ? 'Update' : 'Create'
    contactAddressObject.value = contact.address ? contact.address : null
    form.type = props.type
    form.for_model = props.model
    form.for = props.model_id
    form.name = contact.name
    form.email = contact.email
    form.work_phone = contact.work_phone
    form.mobile_phone = contact.mobile_phone
    form.home_phone = contact.home_phone
    form.fax = contact.fax
    form.role = contact.role
    form.relationship = contact.relationship
    form.organisation = contact.organisation
    form.is_primary = contact.primary_contact_from ? true : false
    form.address = contact.address ? contact.address.uuid : null
    loaded.value = true
})

const contactAddressObject = ref({})
const updateAddress = (location) => {
    form.address = location.uuid
}

const mobileValidation = ref()
const workPhoneValidation = ref()
const homePhoneValidation = ref()
const faxValidation = ref()
const updateKey = ref(Date.now())
const errors = ref([])

const emit = defineEmits(['created', 'updated'])

const saving = ref(false)
const saveContact = function () {
    resetErrors()
    saving.value = true
    if (method.value === 'Update') {
        axios
            .patch(route('api.contact.update', { contact: props.contact.uuid }), form)
            .then((response) => {
                toast.success('Contact was updated successfully.')
                emit('updated', response.data)
            }).catch(error => {
                if (error.response && error.response.status === 422) {
                    setErrors(error)
                } else {
                    toast.error('There was an error updating the contact.')
                }
            })
            .finally(() => saving.value = false)
    } else {
        axios
            .post(route('api.contact.store'), form)
            .then((response) => {
                toast.success('Contact was created successfully.')
                emit('created', response.data)
            }).catch(error => {
                if (error.response && error.response.status === 422) {
                    setErrors(error)
                } else {
                    toast.error('There was an error creating the contact.')
                }
            })
            .finally(() => saving.value = false)
    }
}

const setErrors = (error) => {
    if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message)
        for (const [key, value] of Object.entries(error.response.data.errors)) {
            errors.value[key] = value
        }
    }
}

const resetErrors = () => {
    errors.value = []
    mobileValidation.value = null
    homePhoneValidation.value = null
    workPhoneValidation.value = null
    faxValidation.value = null
}

onMounted(() => {
    ElmHelper.waitUntilRendered('#'+props.id).then((elm) => {
        const modal = document.getElementById(props.id)
        modal.addEventListener('hidden.bs.modal', function (event) {
            resetErrors()
            loaded.value = false
        })
    });
})

const relationships = ref([])
const getRelationships = function () {
    axios
        .get(route('api.enum.personal-relationships'))
        .then((response) => {
            relationships.value = response.data
        })
}

onBeforeMount(() => {
    getRelationships()
})

</script>

<style scoped>

</style>
