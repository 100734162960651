<template>
    <EditableDate
        @updated="update"
        :disabled="!editable"
        :value="annualTrainingDate"
        :loading="updating"
    >
        <small v-if="annualTrainingDate">{{moment(annualTrainingDate).format(dateFormat)}}</small>
    </EditableDate>
    <FormValidationError :errors="errors" field="ndis_worker_expiry_date"></FormValidationError>
</template>

<script setup>
import {computed, inject, ref} from "vue";
import EditableDate from "@components/Forms/EditableFields/EditableDate.vue";
import { FormValidationError } from "@components/Forms";

const axios = inject('axios');

const props = defineProps({
    driver: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
});

const dateFormat = 'D MMMM YYYY';

const annualTrainingDate = ref(props.driver.annual_driver_training_completed_date);

const editable = computed(() => {
    return !props.readOnly;
});

const emit = defineEmits(['updated']);

const updating = ref(false);
const errors = ref([]);
const update = function (newDate) {
    updating.value = true;
    axios
        .put(route('api.drivers.update', { driver: props.driver.uuid }), {
            annual_driver_training_completed_date: newDate
        })
        .then((response) => {
            annualTrainingDate.value = response.data.annual_driver_training_completed_date;
            errors.value = [];
        })
        .catch((error) => {
            errors.value = error.response.data.errors;
        })
        .finally(() => {
            updating.value = false;
        })
}

</script>
