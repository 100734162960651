<template>
    <div id="locationTypeDeleteModal" class="modal fade" aria-hidden="true" aria-labelledby="locationTypeDeleteModalLabel"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="locationTypeDeleteModalLabel" class="modal-title">Delete Location Type: {{ deletingLocationType.description }}</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                    <div v-if="loadingAffectedLocations" class="d-flex w-100 justify-content-center align-items-center ps-3">
                        <div class="spinner-grow my-3 me-3" role="status"></div>
                        <div class="fs-4">Loading data</div>
                    </div>
                    <template v-else>
                        <template v-if="deleteForbidden">
                            <p>This location cannot be deleted.</p>
                        </template>
                        <template v-else-if="affectedLocations.length == 0">
                            <p>There are no locations affected by this change. You can safely delete this location type.</p>
                        </template>
                        <template v-else>
                            <p>Please select which action you would like to carry out. <strong>Use with caution</strong>, this cannot be undone.</p>
                            <div class="form-check">
                                <input
                                    v-model="locationTypeInDelete.action"
                                    id="reassignLocationType"
                                    class="form-check-input"
                                    type="radio"
                                    value="reassign"
                                    checked
                                >
                                <label class="form-check-label" for="reassignLocationType">
                                    I want to delete this location type and reassign locations with this type to a different type
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    v-model="locationTypeInDelete.action"
                                    id="deletingLocationType"
                                    class="form-check-input"
                                    type="radio"
                                    value="delete"
                                >
                                <label class="form-check-label" for="deletingLocationType">
                                    I want to delete this location type and dissassociate it from locations with this type
                                </label>
                            </div>

                            <div class="my-3">
                                <FormSelect
                                    v-if="locationTypeInDelete.action === 'reassign'"
                                    v-model:selection="locationTypeInDelete.reassign_to"
                                    :options="locationTypesToReassignTo"
                                    label="Select a location type to reassign locations to"
                                    placeholder="-- Select a location type --"
                                ></FormSelect>
                            </div>

                            <p class="mb-0 alert alert-danger mb-2">The locations listed below will be affected by this change</p>
                            <div class="overflow-auto" style="max-height: 45vh;">
                                <table class="table table-striped table-centered table-sm dt-responsive nowrap w-100 ">
                                    <thead>
                                        <tr>
                                            <th>Address</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="location in affectedLocations" :key="location.uuid">
                                            <td>{{ location.formatted_address }}</td>
                                            <td>{{ location.description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="modal-footer d-flex justify-content-end">
                    <div v-if="!deleteForbidden">
                        <button class="btn btn-light me-1" data-bs-dismiss="modal" type="button">Close</button>
                        <Button :loading="deleting" @click="performDelete" color="danger">Delete Location Type</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, inject, computed } from 'vue'
import Button from "../Button.vue"
import { FormSelect } from "../Forms"

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    locationTypes: {
        type: Array,
        required: true
    },
    deletingLocationType: {
        type: Object,
        required: true
    }
})

const deleteForm = () => {
    return {
        uuid: null,
        action: 'reassign',
        reassign_to: {
            value: null,
            required: true,
            valid: false,
            errors: []
        }
    }
}
const locationTypeInDelete = ref(deleteForm())
const loadingAffectedLocations = ref(false)
const affectedLocations = ref([])
const deleting = ref(false)
const deleteForbidden = ref(false)

const locationTypesToReassignTo = computed(() => {
    return props.locationTypes.filter(locationType => locationType.uuid !== props.deletingLocationType.uuid)
        .map(locationType => {
            return {
                value: locationType.uuid,
                label: locationType.description
            }
        })
})

watch(() => props.deletingLocationType, (deletingLocationType) => {
    locationTypeInDelete.value.uuid = deletingLocationType.uuid
    loadingAffectedLocations.value = true
    deleteForbidden.value = false

    axios
        .get(route('api.locations.with-type'), {
            params: {
                location_type: props.deletingLocationType.uuid
            }
        })
        .then(response => {
            affectedLocations.value = response.data.data
            if (affectedLocations.value.length === 0) {
                locationTypeInDelete.value.action = 'delete'
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                deleteForbidden.value = true
            } else {
                toast.error('Error fetching affected locations')
            }
        })
        .finally(() => {
            loadingAffectedLocations.value = false
        })
})

const emit = defineEmits(['deleted'])

const performDelete = () => {
    deleting.value = true
    locationTypeInDelete.value.reassign_to.errors = []

    axios
        .delete(route('api.location-types.destroy', locationTypeInDelete.value.uuid), {
            data: {
                action: locationTypeInDelete.value.action,
                reassign_to: locationTypeInDelete.value.reassign_to.value
            }
        })
        .then(response => {
            emit('deleted', locationTypeInDelete.value)
            toast.success('Location type deleted')
            locationTypeInDelete.value = deleteForm()
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                toast.error('This location type cannot be deleted.')
            } else if (error.response && error.response.status === 422) {
                toast.error('There was an error validating the form')
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    locationTypeInDelete.value[key].errors = value
                }
            } else {
                toast.error('Error deleting location type')
            }
        })
        .finally(() => {
            deleting.value = false
        })
}

</script>