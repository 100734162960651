<template>
    <div class="d-inline-block">
        <span
            v-if="!editForm"
            class="click-to-edit"
            @click="showEditForm"
        >
            {{ booking.destination.fullAddress }}
        </span>
        <div v-if="editForm" class="d-flex align-items-center">
            <AddressDbSearch
                v-model="selectedLocation"
                :disabled="updating"
                :label="'Booking destination address'"
                focus
                hide-label
                size="small"
                :can-create="false"
                @locationSelected="updateDestination"
            />
            <button
                v-if="!updating"
                aria-label="Close"
                class="btn-close ms-1"
                type="button"
                @click="editForm = false"
            ></button>
            <Spinner v-if="updating" class="ms-1" size="small" type="border"></Spinner>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditBookingDestination"
}
</script>
<script setup>
import {inject, onBeforeMount, ref} from 'vue'

const toast = inject('toast')
import axios from "axios";
import {useVerificationStore} from "../../../stores/VerificationStore.js";
import AddressDbSearch from "../../AddressDbSearch.vue";
import Spinner from "../../Spinner.vue";

const verificationStore = useVerificationStore();

const editForm = ref(false)
const showEditForm = function () {
    editForm.value = true
}

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    journeyId: {
        type: String,
        required: true,
    },
})

const selectedLocation = ref(null)

const updating = ref(false)
const updateDestination = function (newDestination) {
    updating.value = true
    axios
        .put(route('api.bookings.update', {booking: props.booking.uuid}), {
            destination: newDestination.uuid,
        })
        .then((response) => {
            verificationStore.updateBooking(props.journeyId, response.data)
            editForm.value = false
            toast.success('Destination updated for booking.')
        })
        .catch((error) => {
            toast.error('There was an error updating the destination address for this booking.')
        })
        .finally(() => {
            updating.value = false
        })
}

onBeforeMount(() => {
    selectedLocation.value = props.booking.origin
})

</script>

<style scoped>

</style>
