<template>
    <div v-if="loaded && selectedStatus" class="mb-3">
        <label :for="componentId" class="form-label">Change the status of this vehicle run</label>
        <div class="dropdown mb-3">
            <button
                :disabled="runUpdating.status === 'cancelled'"
                :id="componentId"
                class="btn btn-outline-light dropdown-toggle text-dark"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                type="button"
            >
                <JourneyStatus :status="selectedStatus?.value" class="me-2" hide-tool-tip/>
                {{ selectedStatus?.label }}
            </button>
            <ul :aria-labelledby="componentId" class="dropdown-menu">
                <li v-for="(status, index) in selectableOptions" :key="index">
                    <a class="dropdown-item" href="javascript: void(0)" @click="updateStatus(status)">
                        <JourneyStatus :status="status.value"/>
                        <span class="mx-2">{{ status.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="row" v-if="showConfirmCancel && showCancellationOptions">
            <div class="col-lg-6">
                <FormSelect
                    v-model:selection="selectedCancellationOption"
                    :options="cancellationOptions"
                    label="Select an option for existing bookings"
                    placeholder="Choose an option"
                />
            </div>
        </div>
        <div v-if="showConfirmCancel" class="my-3">
            <p>Are you sure you want to proceed?</p>
            <div v-if="(runUpdating?.bookings?.length ?? 0) > 0" class="alert alert-danger mt-3">
                <div class="d-flex flex-row align-items-center">
                    <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
                    <p>The following bookings are assigned to this vehicle run and they will be unallocated once you confirm this cancellation:</p>
                </div>
                <div v-for="booking in runUpdating?.bookings ?? []" class="d-flex flex-row align-items-center">
                    <BookingListLinkIcon :booking="booking"/>
                    <ClientName :client="booking.client"></ClientName>
                </div>
            </div>

            <Button @click="undoCancel" color="light" size="small" class="me-2">
                Cancel
            </Button>
            <Button @click="confirmCancellation" :loading="cancelling" color="danger" size="small">
                Cancel Vehicle Run
            </Button>
        </div>

        <div v-if="showConfirmUnverified" class="my-3">
            <p>Are you sure you want to proceed?</p>
            <div v-if="(runUpdating?.bookings?.length ?? 0) > 0" class="alert alert-warning mt-3">
                <div class="d-flex flex-row align-items-center">
                    <i class="mdi mdi-alert-circle-outline fs-4 me-2"></i>
                    <p>The following bookings are assigned to this vehicle run and they will be changed to Waiting For Verification once you confirm this process:</p>
                </div>
                <div v-for="booking in runUpdating?.bookings ?? []" class="d-flex flex-row align-items-center">
                    <BookingListLinkIcon :booking="booking"/>
                    <ClientName :client="booking.client"></ClientName>
                </div>
            </div>

            <Button @click="undoUnverified" color="light" size="small" class="me-2">
                Cancel
            </Button>
            <Button @click="confirmUnverified" :loading="unverifying" color="danger" size="small">
                Change Vehicle Run to Waiting Verification
            </Button>
        </div>
    </div>
    <hr class="border-top"/>
</template>

<script setup>
import {computed, onMounted, ref, inject, onBeforeMount, nextTick} from "vue"
import JourneyStatus from "../../JourneyStatus.vue";
import Button from "../../Button.vue"
import Spinner from "../../Spinner.vue"
import {uuid} from "vue-uuid"
import {FormSelect} from '../../Forms';
import {transformInput} from "../../Utils";
import BookingListLinkIcon from "@components/Bookings/BookingListLinkIcon.vue";
import ClientName from "@components/Clients/ClientName.vue";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    runUpdating: {
        type: Object,
        required: true
    },
    vehicleRunStatuses: {
        type: Array,
        required: true
    },
})

const emit = defineEmits(['cancelled', 'unverified'])

const componentId = ref(uuid.v4())

const selectedStatus = ref({
    value: null,
    label: null
})

const originalStatus = ref({
    value: null,
    label: null
})

const showConfirmCancel = ref(false);
const showConfirmUnverified = ref(false);
const updateStatus = (status) => {
    selectedStatus.value = status;
    showConfirmCancel.value = status.value === 'cancelled';
    showConfirmUnverified.value = status.value === 'waiting_verification';
}

const undoCancel = () => {
    showConfirmCancel.value = false
    selectedStatus.value = originalStatus.value
}

const undoUnverified = () => {
    showConfirmUnverified.value = false
    selectedStatus.value = originalStatus.value
}

const showCancellationOptions = computed(() => {
    return selectedStatus.value.value === 'cancelled'
        && props.runUpdating.bookings
        && props.runUpdating.bookings.length
})

const cancellationOptions = ref([
    {
        value: 'unassign',
        label: 'Un-assign all bookings in this run',
    }
])

const selectedCancellationOption = ref(transformInput())

const cancelling = ref(false)
const confirmCancellation = (status) => {
    cancelling.value = true
    axios.patch(route('api.journeys.status.update', {journey: props.runUpdating.uuid}), {
        status: 'cancelled',
        cancellation_option: selectedCancellationOption.value.value
    })
        .then(response => {
            toast.success('This run has been cancelled')
            props.runUpdating.status = 'cancelled'
            showConfirmCancel.value = false
            emit('cancelled', props.runUpdating)
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message)
            }
        }).finally(() => {
        cancelling.value = false
    })
}

const unverifying = ref(false)
const confirmUnverified = (status) => {
    unverifying.value = true
    axios.patch(route('api.journeys.status.update', {journey: props.runUpdating.uuid}), {
        status: 'waiting_verification',
    })
        .then(response => {
            toast.success('This run has been change to Waiting Verification')
            props.runUpdating.status = 'waiting_verificaiton'
            showConfirmUnverified.value = false
            emit('unverified', props.runUpdating)
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message)
            }
        }).finally(() => {
        unverifying.value = false
    })
}

const selectableOptions = computed(() => {
    switch (selectedStatus.value?.value) {
        case 'verified':
            return props.vehicleRunStatuses.filter(status => status.value === 'cancelled' || status.value === 'waiting_verification')
        case 'waiting_verification':
        case 'pending_information':
        case 'unmet':
        case 'future':
            return props.vehicleRunStatuses.filter(status => status.value === 'cancelled')
        case 'cancelled':
        default:
            return props.vehicleRunStatuses.filter(status => status.value === originalStatus.value.value)
    }
})

const loaded = ref(false)
onMounted(() => {
    let chosenStatus = props.vehicleRunStatuses.find(status => status.value === props.runUpdating.status);
    if (!chosenStatus) {
        chosenStatus = props.vehicleRunStatuses[0];
    }
    selectedStatus.value = chosenStatus;
    originalStatus.value = selectedStatus.value
    loaded.value = true
})

</script>
