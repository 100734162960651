<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="header-title m-0">Driver App Access</h4>
            </div>

            <dl class="row mb-0">
                <dt class="col-sm-4">Status:</dt>
                <dd class="col-sm-8">
                    <span
                        v-if="driverIsPasswordSet"
                        class="badge bg-success me-1 mb-1"
                        >Active</span
                    >
                    <span
                        v-if="!driverIsPasswordSet"
                        class="badge bg-warning me-1 mb-1"
                        >Pending Setup</span
                    >
                </dd>

                <dt class="col-sm-4">Password:</dt>
                <dd class="col-sm-8">
                    <a
                        href="#"
                        @click="showResetPasswordEmailConfirmationModal"
                        >{{
                            !driverIsPasswordSet
                                ? "Send invitation link"
                                : "Send password reset link"
                        }}</a
                    >
                </dd>
            </dl>

            <ConfirmationModal
                id="sendResetPasswordEmailConfirmationModal"
                confirmation-button-color="danger"
                @confirmed="sendResetPasswordEmail"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from "@components/Button.vue";
import { onMounted, ref, computed, inject } from "vue";
import ConfirmationModal from "../ConfirmationModal.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import axios from "axios";
import ElmHelper from "@classes/Helpers/ElmHelper";

const toast = inject<any>("toast");

const sendResetPasswordEmailConfirmationModal = ref<null | bootstrap.Modal>();

const { driver, driverIsPasswordSet } = defineProps<{
    driver: App.Models.Driver;
    driverIsPasswordSet: boolean;
}>();

onMounted(() => {
    ElmHelper.waitUntilRendered('#sendResetPasswordEmailConfirmationModal').then((elm) => {
        sendResetPasswordEmailConfirmationModal.value = new bootstrap.Modal(
            document.getElementById("sendResetPasswordEmailConfirmationModal")
        );
    })
});

const showResetPasswordEmailConfirmationModal = function (contact) {
    sendResetPasswordEmailConfirmationModal.value.show();
};

const sendResetPasswordEmail = async () => {
    sendResetPasswordEmailConfirmationModal.value.hide();

    axios
        .post(route("api.send-password-reset-link"), {
            email: driver.email,
        })
        .then((response) => {
            if (response.status == 200) {
                toast.success(
                    `An email has been sent to ${driver.given_names} (${driver.email}) with instructions to reset their password.`
                );
            } else {
                toast.error("Something went wrong");
            }
        });
};
</script>

<style scoped></style>
