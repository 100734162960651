<template>
    <HoverPopUp>
        <template v-slot:element>
            <SvgIcon
                type="mdi"
                :path="mdiClockTimeEightOutline"
                class="text-primary"
                :size="18"
            ></SvgIcon>
        </template>
        <template v-slot:popup-content>
            <div>
                <div class="d-flex flex-row align-items-center">
                    <SvgIcon
                        type="mdi"
                        :path="mdiClockTimeEightOutline"
                        class="text-primary me-1"
                        :size="20"
                    ></SvgIcon>
                    <strong class="text-primary"
                        >{{
                            type == "origin" ? "Origin" : "Destination"
                        }}
                        timings</strong
                    >
                </div>

                <br />

                <strong>Requested Time</strong>
                <br />
                {{ requestedTime }}
                <br />
                <strong>Planned Time</strong>
                <br />
                {{ plannedTime }}
                <br />
                <strong>Estimated Time</strong>
                <br />
                {{ estimatedTime }}
            </div>
        </template>
    </HoverPopUp>
</template>
<script setup lang="ts">
import { computed } from "vue";
import HoverPopUp from "@components/HoverPopUp/HoverPopUp.vue";
import BookingResource from "@customTypes/resources/BookingResource";
import { DateTime } from "luxon";
import { mdiClockTimeEightOutline } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

const props = withDefaults(
    defineProps<{
        booking: BookingResource;
        type?: "origin" | "destination";
    }>(),
    {
        type: "origin",
    }
);

const plannedTime = computed(() => {
    let storeTime =
        props.type == "origin"
            ? props.booking.planned_origin_time
            : props.booking.planned_destination_time;

    return storeTime
        ? DateTime.fromISO(storeTime ?? "").toFormat("dd/MM/yyyy HH:mm")
        : "N/A";
});

const estimatedTime = computed(() => {
    let storeTime =
        props.type == "origin"
            ? props.booking.estimated_origin_time
            : props.booking.estimated_destination_time;

    return storeTime
        ? DateTime.fromISO(storeTime ?? "").toFormat("dd/MM/yyyy HH:mm")
        : "N/A";
});

const requestedTime = computed(() => {
    let storeTime =
        props.type == "origin"
            ? props.booking.requested_origin_time
            : props.booking.requested_destination_time;

    return storeTime
        ? DateTime.fromISO(storeTime ?? "").toFormat("dd/MM/yyyy HH:mm")
        : "N/A";
});
</script>
