<template>
    <div class="row">
        <div class="col-md-4 pe-5">
            <h4>General Settings</h4>
            <p>
                Here you can update the basic application settings for your organisation.
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <SupportNumberSettings :selected="settings.find((setting) => setting.name === 'support_phone_number')" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <TimezoneSettings :selected="settings.find((setting) => setting.name === 'timezone')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <ProviderIdSettings :selected="settings.find((setting) => setting.name === 'provider_id')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 pe-5">
            <h4>System Settings</h4>
            <p>
                Here you can adjust the system settings for your organisation.
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <DriverRegulationCheckSettings
                                :setting="settings.find((setting) => setting.name === 'driver_regulation_check')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 pe-5">
            <h4>Customise Run List</h4>
            <p>
                Here you can adjust how the printed run list is displayed
            </p>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <RunListSettings
                                :settings="settings.filter((setting) => setting.name.startsWith('run_list_'))"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import TimezoneSettings from "./TimezoneSettings.vue";
import ProviderIdSettings from "./ProviderIdSettings.vue";
import SupportNumberSettings from "./SupportNumberSettings.vue";
import DriverRegulationCheckSettings from "./DriverRegulationCheckSettings.vue";
import RunListSettings from "@components/Settings/RunListSettings.vue";
export default {
    name: "GeneralSettings",
    components: {
        RunListSettings,
        TimezoneSettings,
        ProviderIdSettings,
        SupportNumberSettings,
        DriverRegulationCheckSettings,
    },
    props: {
        settings: Array,
    },
}
</script>

<style scoped>

</style>
