<template>
    <div class="row">
        <div class="col-md-4 d-flex flex-column">
            <label class="form-label">Select Template</label>
            <VueMultiselect
                v-model="selectedTemplate"
                :options="templates"
                placeholder="Select service types"
                label="name"
                track-by="id"
            ></VueMultiselect>

            <a
                :href="templateUrl"
                type="button"
                class="btn btn-primary btn-block mt-2"
            >
                <SvgIcon type="mdi" :path="mdiPencil" class="me-1"></SvgIcon
                >Modify
            </a>
        </div>
        <div
            class="col-md-8 d-flex align-items-center justify-content-center flex-column"
        >
            <VehicleDiagram />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import templates, {
    VehicleConfigurationTemplateInterface,
} from "@components/Vehicles/Configuration/Design/VehicleConfigurationTemplates";
import VueMultiselect from "vue-multiselect";
import VehicleDiagram from "../Configuration/Design/VehicleDiagram.vue";
import { storeToRefs } from "pinia";
import { useVehicleConfigurationDesignStore } from "@stores/VehicleConfigurationDesignStore";
import { mdiPencil } from "@mdi/js";

import SvgIcon from "@jamescoyle/vue-icon";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const { rows, spaceMargin, editable } = storeToRefs(
    vehicleConfigurationDesignStore
);

const selectedTemplate = ref<VehicleConfigurationTemplateInterface>(
    templates[0]
);

const templateUrl = computed(() => {
    return route("vehicle-configuration-design.index", {
        templateid: selectedTemplate.value.id,
    });
});

onMounted(() => {
    editable.value = false;
    rows.value = selectedTemplate.value.rows;
});

watch(
    () => selectedTemplate.value,
    (template) => {
        rows.value = template.rows;
    }
);
</script>
