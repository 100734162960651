<template>
    <div
        id="deleteModal"
        class="modal fade modal-lg"
        aria-hidden="true"
        aria-labelledby="deleteModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content text-center">
                <div class="modal-header">
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h3 class="modal-title">Delete {{props.labelType}}</h3>
                    <div>This {{props.labelType}} will be permanently removed.</div>
                    <div>Would you like to continue?</div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="commitDelete">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from "@components/Button.vue";
import {CarerInterface} from "@classes/ClientCarer";
import {onMounted, ref, watch} from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

const deleteModal = ref<null | bootstrap.Modal>(null);
const props = withDefaults(
    defineProps<{
        uuid: string,
        labelType?: string,
    }>(),
    {
        labelType: 'item'
    }
);
const emit = defineEmits(['commit', 'closed']);
const showModal = () => {
    deleteModal.value.show();
}

const closeModal = () => {
    deleteModal.value.hide();
    emit('closed', '');
}

const commitDelete = () => {
    emit('commit', props.uuid);
    emit('closed', '');
    closeModal();
}

onMounted(() => {
    deleteModal.value = new bootstrap.Modal(document.getElementById("deleteModal"));
});

watch(props, (propsValue) => {
    if (propsValue.uuid) {
        showModal();
    }
});
</script>

<style scoped>

</style>
