import axios from "axios";
import { DateTime } from "luxon";

export default class DriverPositionUpdate {
    static getVehiclePositions(parameters: {
        driver?: string;
        vehicle?: string;
        run?: string;
    }) {
        return axios.get<App.Models.DriverPositionUpdate[]>(
            route("api.vehicle-positions.index", parameters)
        );
    }

    static index = (parameters: {
        journey?: string;
        booking?: string;
    }) => {
        return axios.get<App.Models.DriverPositionUpdate[]>(
            route("api.driver-position-updates.index", parameters)
        );
    };
}
