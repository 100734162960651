<template>
    <div>
        <FormPhoneInput
            v-model:input="phoneNumber"
            @update:input="updatePhoneNumber"
            :label="selected.title"
        />

        <div v-if="saved" class="mt-2 d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="mdiCheckCircle"
                class="text-success h-5 w-5 me-1"
            ></SvgIcon>
            Changes Saved
        </div>

    </div>
</template>

<script>
export default {
    name: "SupportNumberSettings"
}
</script>
<script setup>
import { ref, inject } from 'vue'
import {FormPhoneInput} from '../Forms'
import { transformInput } from '../Utils'
import * as yup from 'yup'
import { debounce } from 'lodash'
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheckCircle } from "@mdi/js";

const axios = inject('axios')

const props = defineProps({
    selected: {
        type: Object,
        required: true,
    },
});

const phoneNumber = ref(transformInput({
    value: props.selected.value,
    required: false,
}))

const saved = ref(false)
const updatePhoneNumber = debounce(function (phoneNumber) {
    phoneNumber.errors = []
    axios
        .post(route('api.set-customer-support-number'), {
            support_phone_number: phoneNumber.value,
        }).then(() => {
        saved.value = true
        setTimeout(() => { saved.value = false }, 2000)
    }).catch(error => {
        if (error.response.status === 422) {
            phoneNumber.errors = error.response.data.errors.support_phone_number
        }
    })
}, 500)
</script>

<style scoped>

</style>
