<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-3"><strong>Category</strong></div>
                <div class="col"><strong>Date Range</strong></div>
            </div>
            <div class="row mt-1">
                <div class="col-3">Select a category to view activity</div>
                <div class="col-2">From</div>
                <div class="col-2">Until</div>
                <div class="col"></div>
            </div>
            <div class="row mt-1">
                <div class="col-3">
                    <select class="form form-control" v-model="store.filters.category">
                        <option value="invoices">Invoices</option>
                        <option value="transactions">Transactions</option>
                    </select>
                </div>
                <div class="col-2">
                    <input type="date" class="form form-control" v-model="store.filters.dateFrom"/>
                </div>
                <div class="col-2">
                    <input type="date" class="form form-control" v-model="store.filters.dateTo"/>
                </div>
                <div class="col"></div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <Button title="View" @click="store.getAllTransactions(store.filters)">View</Button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col">
                    <h5 class="text-uppercase">Transactions</h5>
                    {{ DateTime.fromSQL(store.filters.dateFrom).toLocaleString(DateTime.DATE_FULL) }} - {{ DateTime.fromSQL(store.filters.dateTo).toLocaleString(DateTime.DATE_FULL) }}
                </div>
                <div class="col d-flex align-items-end justify-content-end">
                    <Button title="Add Transactions" @click="addTransactionModal.show()" class="mt-2">
                        <SvgIcon type="mdi" :path="mdiPlus" class="w-4 h-4 me-1"></SvgIcon>
                        Add Transactions
                    </Button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <PageLengthSelector record-name="transactions" store-name="TransactionStore"/>
                </div>
                <div class="col text-end">
                    <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                        <button
                            class="btn btn-light btn-sm dropdown-toggle"
                            type="button"
                            id="dropDownFilterClients"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <SvgIcon type="mdi" :path="mdiFilter" class="w-4 h-4 me-1"></SvgIcon>
                            Filter
                        </button>
                        <div class="dropdown-menu px-4 py-3" style="min-width: 500px;"
                             aria-describedby="dropDownFilterBookings">
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Transaction type:</label>
                                <VueMultiselect
                                    v-model="store.filters.types"
                                    :options="store.defaultTypes"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Statuses"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Payment method:</label>
                                <VueMultiselect
                                    v-model="store.filters.paymentMethods"
                                    :options="store.defaultPaymentMethods"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Collection Methods"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2">
                                <label class="form-label text-sm" for="status_filter_select">Collection method:</label>
                                <VueMultiselect
                                    v-model="store.filters.collectionMethods"
                                    :options="store.defaultCollectionMethods"
                                    track-by="value"
                                    label="label"
                                    placeholder="Select Collection Methods"
                                    multiple
                                ></VueMultiselect>
                            </div>
                            <div class="mb-2 text-end">
                                <Button title="Apply" @click="store.getAllTransactions(store.filters)"
                                        color="light">Apply
                                </Button>
                            </div>
                        </div>
                    </div>
                    <DataTableSearch
                        @search="store.getAllTransactions(store.filters)"
                        placeholder="Search transactions ..."
                        store="TransactionStore"
                    />
                </div>
            </div>

        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <SortableColumn param="uuid" store="TransactionStore">Reference</SortableColumn>
                    <SortableColumn param="created_at" store="TransactionStore">Date</SortableColumn>
                    <SortableColumn param="amount" store="TransactionStore">Amount</SortableColumn>
                    <th>Invoice</th>
                    <th>Payment method</th>
                    <th>Collection method</th>
                    <th>Receipt No.</th>
                    <th class="text-center">Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="transaction in store.transactions">
                    <td class="align-middle">
                        <TransactionType :status="transaction.type" :full="false"></TransactionType>
                    </td>
                    <td class="align-middle">{{ transaction.uuid }}</td>
                    <td class="align-middle">
                        {{ DateTime.fromSeconds(transaction.created_at as number).toLocaleString(DateTime.DATE_MED) }}
                    </td>
                    <td class="align-middle">{{ formatCurrency(transaction.amount) }}</td>
                    <td class="text-capitalize"><a v-if="transaction.invoice" :href="route('invoices.edit', {invoice: transaction.invoice?.uuid}, true)">{{ transaction.invoice?.uuid }}</a></td>
                    <td class="text-capitalize">{{ store.availablePaymentMethods.find((item) => item.value === transaction.payment_method).label }}</td>
                    <td class="text-capitalize">{{ store.availableCollectionMethods.find((item) => item.value === transaction.collection_method).label }}</td>
                    <td>{{ transaction.receipt_number }}</td>
                    <td class="text-center">
                        <SvgIcon type="mdi"
                                 :path="mdiMessageReplyText"
                                 size="18"
                                 v-popover
                                 data-bs-container="body"
                                 data-bs-trigger="focus"
                                 data-bs-html="true"
                                 :data-bs-content="transaction.comments"
                                 data-bs-placement="left">
                        ></SvgIcon>
                        </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="10">
                        <Pagination
                            v-if="paginationStore.paginatedMeta.total"
                            store-name="TransactionStore"
                            record-name="transaction items"
                        ></Pagination>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>

    <AddTransactionModal id="addTransactionModal" @confirmed="addTransaction()"></AddTransactionModal>

</template>

<style scoped>

</style>

<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {formatCurrency} from "@classes/Helpers/Currency";
import Pagination from "@components/DataTable/Pagination.vue";
import Button from "@components/Button.vue";
import PageLengthSelector from "@components/DataTable/PageLengthSelector.vue";
import {usePaginationStore} from "@stores/PaginationStore";
import SortableColumn from "@components/DataTable/SortableColumn.vue";
import {mdiFilter, mdiMessageReplyText, mdiPlus} from "@mdi/js";
import VueMultiselect from "vue-multiselect";
import SvgIcon from "@jamescoyle/vue-icon";
import {DateTime} from "luxon";
import DataTableSearch from "@components/DataTable/DataTableSearch.vue";
import {useTransactionStore} from "@stores/TransactionStore";
import TransactionType from "@components/TransactionType.vue";
import {Modal} from "bootstrap";
import AddTransactionModal from "@components/Transactions/AddTransactionModal.vue";

const store = useTransactionStore();
const paginationStore = usePaginationStore('TransactionStore');
const addTransactionModal = ref();

onMounted(() => {
    store.getAllTransactions(store.filters);
    addTransactionModal.value = new Modal(document.getElementById('addTransactionModal') ?? 'addTransactionModal')
});

const addTransaction = async () => {
    if (store.transaction) {
        await store.addTransaction(store.transaction);
        await store.getAllTransactions();
        addTransactionModal.value.hide();
    }
}

watch(
    paginationStore.paginateParams,
    () => {
        store.getAllTransactions(store.filters);
    }
)

watch(
    () => store.filters.category,
    (value, oldValue, onCleanup) => {
        if(value !== oldValue) {
            window.location.href = route(`${value}.index`);
        }
    }
)
</script>