<template>
    <span class="d-inline-flex align-items-center">
        <SvgIcon v-if="!hideIcon" :path="icon" class="w-4 h-4 me-1" type="mdi"/>
        {{ label }}
    </span>
</template>

<script>
export default {
    name: "FareCollectionMethod"
}
</script>
<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiCashCheck, mdiAccountCashOutline, mdiFileTableOutline} from '@mdi/js';
import {computed} from "vue";

const icon = computed(() => {
    switch (props.method) {
        case 'account':
        case 'Account':
            return mdiFileTableOutline
        case 'payment_to_driver':
        case 'Payment to driver':
            return mdiAccountCashOutline
        case 'prepaid':
        case 'Prepaid':
            return mdiCashCheck
    }
})

const label = computed(() => {
    return window.enums.fareCollectionMethod[props.method] ?? props.method
})

const props = defineProps({
    method: {
        type: String,
        required: true
    },
    hideIcon: {
        type: Boolean,
        required: false,
        default: false
    }
})
</script>
