<template>
    <div class="modal fade" id="inviteMemberModal" tabindex="-1" aria-labelledby="inviteMemberModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header pb-0 d-flex align-items-start">
                    <div class="modal-title">
                        <h5 id="inviteMemberModalLabel">Invite New Members</h5>
                        <p>Invite new members by email to join your organization.</p>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="sentInvites.length > 0" class="card bg-light">
                        <div class="card-body p-2">
                            <strong>Pending Invites</strong>
                            <div class="overflow-auto" style="max-height: 20vh">
                                <table class="table table-sm">
                                    <tbody>
                                    <PendingInviteRow v-for="invite in sentInvites" :key="invite.email" :invite="invite"></PendingInviteRow>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-8">Email Address</div>
                        <div class="col-3">Role</div>
                        <div class="col-1"></div>
                    </div>
                    <div v-for="invite in invites" :key="invite.id" class="row align-items-center mb-1">
                        <div class="col-8">
                            <label :for="invite.id + '_email'" class="visually-hidden">Email Address</label>
                            <input
                                v-model="invite.email"
                                type="email"
                                autocomplete="off"
                                autocapitalize="none"
                                :id="invite.id + '_email'"
                                class="form-control"
                                name="email"
                                placeholder="email@example.com"
                                spellcheck="false"
                            >
                        </div>
                        <div class="col-3">
                            <label :for="invite.id + '_role'" class="visually-hidden">Role</label>
                            <select v-model="invite.role" class="form-select" name="role" :id="invite.id + '_role'">
                                <option v-for="role in roles" :value="role.id">{{ role.name }}</option>
                            </select>
                        </div>
                        <div v-if="invites.length > 1" class="col-1 text-end">
                            <button
                                type="button"
                                @click="removeInviteRow(invite.id)"
                                class="btn-close"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                    <a href="javascript: void(0)" @click="addInviteRow" class="d-inline-flex align-items-center mt-3">
                        <SvgIcon type="mdi" :path="addAnotherInviteIcon" class="w-5 h-5 me-1"></SvgIcon>
                        Add another
                    </a>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-light" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    <button @click="sendInvites" class="btn btn-primary" :disabled="!enableSubmit">Send Invite</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiPlusCircleOutline } from '@mdi/js';
import SvgIcon from "@jamescoyle/vue-icon";
import PendingInviteRow from "./PendingInviteRow.vue";
export default {
    name: "InviteMemberModal",
    components: {
        SvgIcon,
        PendingInviteRow,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'inviteMemberModal'
        }
    },
    data() {
        return {
            roles: null,
            invites: [],
            addAnotherInviteIcon: mdiPlusCircleOutline,
            sentInvites: [],
        }
    },
    computed: {
        enableSubmit: function () {
            return this.invites && this.invites.some((invite) => this.validEmail(invite.email.trim()))
        }
    },
    methods: {
        getRoles() {
            this.$http
                .get(this.route('api.roles.index'))
                .then((response) => {
                    this.roles = response.data
                    this.addInviteRow()
                })
        },
        getInvites() {
            this.$http
                .get(this.route('api.invitation.index'))
                .then((response) => {
                    this.sentInvites = response.data
                })
        },
        addInviteRow() {
            this.invites.push({
                id: this.$uuid.v1(),
                email: '',
                role: this.roles[0].id,
            })
        },
        removeInviteRow(rowId) {
            this.invites = this.invites.filter((row) => {
                return row.id !== rowId
            })
        },
        sendInvites() {
            this.$http
                .post(this.route('api.invitation.store'), {
                    invitations: this.invites,
                })
                .then((response) => {
                    this.invites = []
                    this.addInviteRow()
                    this.$emit('invitationsSent')
                })
        },
    },
    beforeMount() {
        this.getRoles()
    },
    mounted() {
        this.getInvites()
    }
}
</script>

<style scoped>

</style>
