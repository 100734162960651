<template>
    <ClientCommentModal
        :action="modalAction"
        :client="props.client"
        :comment="comment.comment"
        :commentator="comment.commentator_name"
        :date="comment.created_at"
        :id="comment.id"
        @closed="modalClosed"
        @created="commentAdded"
        @updated="commentUpdated"
        @deleted="commentDeleted"
    />
    <div class="row">
        <div class="col">
            <h5 class="text-uppercase">Comments</h5>
            Add a comment to document activities relating to the client.
        </div>
        <div class="col d-flex align-items-end justify-content-end">
            <Button @click="showModal('create', { 'comment': '', 'id': ''})">
                <SvgIcon type="mdi" :path="mdiPlusCircle" class="w-4 h-4 me-1"></SvgIcon>
                Add Comments
            </Button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-sm-12 col-md-6">
            <label v-if="!loadingComments" class="form-label text-nowrap">
                Display
                <select
                    :disabled="loadingComments"
                    v-model="perPage"
                    class="form-select form-select-sm d-inline mx-1"
                    @change="changePerPage()"
                >
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="paginationMeta.total">All</option>
                </select>
                comments
            </label>
        </div>
        <div class="col-sm-12 col-md-6 text-md-end">
            <div class="mb-2 mb-md-0 me-0 me-md-2 dropdown d-inline-block">
                <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="dropDownFilterBookings"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <SvgIcon type="mdi" :path="mdiFilter" class="w-4 h-4 me-1"></SvgIcon>
                    Filter
                </button>
                <div class="dropdown-menu px-4 py-3" style="min-width: 500px;" aria-describedby="dropDownFilterBookings">
                    <div class="mb-2 row">
                        <div class="col-sm-6" style="min-width: 200px">
                            <label for="date_from" class="form-label text-sm mb-1">From:</label>
                            <input v-model="dateRange.from" @change="getComments()" type="date" id="date_from" class="form-control form-control-sm">
                        </div>
                        <div class="col-sm-6" style="min-width: 200px">
                            <label for="date_to" class="form-label text-sm mb-1">Until:</label>
                            <input v-model="dateRange.to" @change="getComments()" type="date" id="date_to" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
            </div>
            <label class="form-label text-nowrap">
                <div class="input-group input-group-sm">
                    <input type="search"
                           :disabled="loadingComments"
                           class="form-control form-control-sm"
                           placeholder="Search comment"
                           v-model="tempSearchQuery"
                    >
                    <button
                        :disabled="loadingComments"
                        class="btn btn-outline-secondary"
                        type="button"
                        @click="search()"
                    >
                        <SvgIcon
                            type="mdi"
                            :path="mdiMagnify"
                            style="width: 1.3em"
                        ></SvgIcon>
                    </button>
                </div>
            </label>
        </div>
    </div>
    <template v-if="loadingComments">
        <div class="d-flex w-100 justify-content-center align-items-center mt-4 mb-3">
            <Loading spinner-class="spinner-grow" text="Loading comments" text-position="after" direction="horizontal"/>
        </div>
    </template>
    <template v-else-if="comments?.length === 0">
        <div class="text-center mt-4 mb-3">
            <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiComment"></SvgIcon>
            <div class="fs-4 my-2">{{ client.preferred_name ?? client.given_names }} has no comments.</div>
            <Button @click="showModal('create', { 'comment': '', 'id': ''})">
                <SvgIcon type="mdi" :path="mdiPlusCircle" class="w-4 h-4 me-1"></SvgIcon>
                Add Comments
            </Button>
        </div>
    </template>
    <template v-else>
        <table class="table table-striped table-sm">
            <thead>
            <tr>
                <th class="sortable"
                    :class="{
                        'sort-asc': sortBy === 'date' && sortDirection === 'asc',
                        'sort-desc': sortBy === 'date' && sortDirection === 'desc',
                    }"
                    role="button"
                    @click="toggleSort('date')"
                >Date
                </th>
                <th>Comment</th>
                <th>Added By</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="comment in comments">
                <tr>
                    <td>{{ comment.created_at }}</td>
                    <td><a
                        href="javascript:void(0);"
                        class="me-1"
                        @click="showModal('view', comment)"
                    >
                        <SvgIcon :path="mdiOpenInNew" :size="15" type="mdi"/>
                    </a>
                        <span class="summary"> {{ comment.comment }}</span>
                    </td>
                    <td>{{ comment.commentator_name }}</td>
                </tr>
            </template>
            </tbody>
        </table>
        <Pagination
            v-if="!loadingComments"
            @pageChanged="loadPage"
            :pagination="paginationMeta"
            record-name="comments"
        ></Pagination>
    </template>
</template>

<script setup lang="ts">
import DatetimeHelper from "@classes/DateHelpers/DatetimeHelper";
import {Loading} from "@components";
import Button from "@components/Button.vue";
import ClientCommentModal from "@components/Clients/ClientCommentModal.vue";
import {ToastInterface} from "@components/toast";
import ClientResource from "@customTypes/resources/ClientResource";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiComment, mdiFilter, mdiMagnify, mdiOpenInNew, mdiPlusCircle} from "@mdi/js";
import {Axios} from "axios";
import {inject, onMounted, ref} from "vue";
import Pagination from "../Pagination.vue"

const filterIcon = ref(mdiFilter);
const toast: ToastInterface = inject('toast')!;
const axios: Axios = inject('axios')!;

interface PropsInterface {
    client: ClientResource
}

interface CommentInterface {
    comment: string,
    commentator_name?: string,
    id: string,
    created_at?: string
}

const props = defineProps<PropsInterface>();

const loadingComments = ref(false);
const page = ref(1);
const perPage = ref(20);
const dateRange = ref({
    from: null,
    to: null
});
const paginationMeta = ref({});
const searchQuery = ref('');
const tempSearchQuery = ref('');
const modalAction = ref('view');
const modalToggle = ref(false);
const sortBy = ref('uuid');
const sortDirection = ref('asc');
const comment = ref<CommentInterface>({
    comment: '',
    id: '',
    created_at: ''
});
const comments = ref<CommentInterface[]>([]);

const loadPage = (link) => {
    page.value = link.page;
    getComments();
}

const fixCommentsDate = () => {
    comments.value = comments.value.map(comment => ({
        ...comment,
        created_at: DatetimeHelper.convertToLuxon(comment?.created_at)?.toFormat('dd/MM/yyyy HH:mm')
    }));
}

const getComments = () => {
    const params = {
        'order_by': sortBy.value,
        'order_direction': sortDirection.value,
        'amount': perPage.value,
        'page': page.value,
        'query': searchQuery.value,
        'date_from': dateRange.value.from,
        'date_to': dateRange.value.to,
    }
    loadingComments.value = true;
    axios.get(route("api.clients.comments.index", {client: props.client.uuid, params: params}))
        .then((resp) => {
            comments.value = resp.data.data;
            fixCommentsDate();
            paginationMeta.value = resp.data.meta;
        })
        .catch((message) => {
            console.error()
        }).finally(() => {
        loadingComments.value = false;
    });
}

const commentAdded = () => {
    // hacky way to make all work after adding comment
    // TODO update how pagination should work
    if (perPage.value !== 20 && perPage.value !== 50) {
        perPage.value++;
    }

    getComments();
}

const commentUpdated = (updatedComment) => {
    const foundCommentIndex = comments.value.findIndex(c => c.id === updatedComment.id);

    if (foundCommentIndex >= 0) {
        const unwrappedComment = JSON.parse(JSON.stringify(comments.value));
        unwrappedComment[foundCommentIndex].comment = updatedComment.comment;
        comments.value = unwrappedComment;
    }
}

const commentDeleted = () => {
    getComments();
}

function showModal(action: string, selectedComment: CommentInterface) {
    if (selectedComment) {
        comment.value = {...selectedComment};
    }

    modalAction.value = action;
    modalToggle.value = true;
}

const toggleSort = (column: string) => {
    sortDirection.value =
        sortBy.value !== column
            ? "asc"
            : sortDirection.value === "asc"
                ? "desc"
                : "asc";

    sortBy.value = column;

    getComments();
};

const modalClosed = () => {
    modalAction.value = '';
}

const changePerPage = () => {
    page.value = 1;
    getComments();
}

const search = () => {
    page.value = 1;
    searchQuery.value = tempSearchQuery.value;
    getComments();
}

onMounted(() => {
    loadPage(page.value);
})
</script>

<style scoped>
.summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: inline;
}
</style>