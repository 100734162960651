<template>
    <div class="modal fade" :id="componentId" tabindex="-1" :aria-labelledby="componentId + '_title'" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" v-if="invoice">
                <div class="modal-header">
                    <h5 class="modal-title d-flex align-items-center" :id="componentId + '_title'">
                        Invoice #{{ invoice.uuid }}
                        <InvoiceStatus :status="invoice.status" full class="ms-2"/>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table table-sm">
                        <tbody>
                        <template v-for="item in invoice.items">
                            <tr>
                                <td>{{ item.description }}</td>
                                <td class="text-end">{{ formatCurrency(item.total) }}</td>
                            </tr>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th class="fw-bold pt-3">Total</th>
                            <th class="fw-bold pt-3 text-end">{{ formatCurrency(invoice.total) }}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="editInvoice(invoice)">Edit</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {uuid} from "vue-uuid";
import InvoiceStatus from "./InvoiceStatus.vue";
import {Modal} from "bootstrap";
import InvoiceResource from "@customTypes/resources/InvoiceResource";
import {formatCurrency} from "@classes/Helpers/Currency";

interface Props {
    show: boolean,
    invoice?: InvoiceResource
}

const props = withDefaults(defineProps<Props>(), {
    show: false,
    invoice: undefined
})

const emit = defineEmits<{
    (e: 'close'): void
}>()

const componentId = ref(uuid.v4());
const modal = ref();

const editInvoice = (invoice: InvoiceResource) => {
    window.location.href = route('invoices.edit', {invoice: invoice.uuid});
}
watch(() => props.show, (value) => {
    if (value) {
        modal.value.show();
        return;
    }

    modal.value?.hide();
})

onMounted(function () {
    let element = <HTMLElement>document.getElementById(componentId.value);
    element.addEventListener('hidden.bs.modal', function () {
        emit('close');
    });

    modal.value = new Modal(element);

    if (props.show) {
        modal.value.show();
    }
})

</script>

<style scoped>

</style>
