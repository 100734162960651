<template>
    <ConfirmButton
        :icon="mdiRestore"
        color="warning"
        :confirmation-title="'Restore ' + (client.preferred_name ?? client.name)"
        :confirmation-text="'Please confirm you would like to restore the account for ' + client.formal_name + '?'"
        @confirmed="restoreClient"
        :loading="restoring"
    >
        Reactivate Client
    </ConfirmButton>
</template>

<script>
export default {
    name: "ReactivateClientLink"
}
</script>
<script setup>
import ConfirmButton from "../ConfirmButton.vue";
import { mdiRestore } from "@mdi/js";
import { ref, inject } from "vue";
import axios from "axios";
const toast = inject('toast')

const props = defineProps({
    client: Object,
})

const emit = defineEmits(['restored'])

const restoring = ref(false)
const restoreClient = function () {
    restoring.value = true
    axios
        .post(route('api.clients.restore', {client: props.client.uuid}))
        .then((response) => {
            toast.success('You restored ' + (props.client.preferred_name ?? props.client.name) + '.')
            emit('restored', response.data)
        })
        .catch(() => {
            toast.error('An error occurred while restoring the client.')
        })
        .finally(() => {
            restoring.value = false
        })
}

</script>

<style scoped>

</style>
