<template>
    <div v-if="permissions.includes('delete_vehicle')" class="d-flex align-items-center">
        <template v-if="showDeleteConfirmation">
            <span class="me-2">Are you sure? </span>
            <Button color="danger" size="small" @click="deleteConfig">Yes</Button>
        </template>
        <template v-else>
            <Button color="outline-danger" @click="showConfirmation">Delete</Button>
        </template>
    </div>
</template>

<script setup>
import Button from "../../Button.vue";
import {ref, inject} from "vue";

const toast = inject('toast')
const axios = inject('axios')

const permissions = window.permissions

const props = defineProps({
    vehicle: {
        required: true,
        type: Object,
    },
    config: {
        required: true,
        type: Object,
    },
});

const emit = defineEmits(['deleted']);

const showDeleteConfirmation = ref(false);
const showConfirmation = function () {
    showDeleteConfirmation.value = true;
}

const deleteConfig = function () {
    axios
        .delete(
            route('api.vehicles.vehicle-configuration.destroy', {
                vehicle: props.vehicle.uuid,
                vehicle_configuration: props.config.uuid
            })
        )
        .then(() => {
            toast.success('Vehicle configuration deleted.');
            showDeleteConfirmation.value = false;
            emit('deleted', props.config);
        })
        .catch(() => {
            toast.error('Sorry we encountered a technical error. Our support staff have been notified. Please try again later.');
        })
}
</script>