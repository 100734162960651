<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action"
                               :class="{active: filters.category === category}"
                               @click="selectCategory(category)"
                               v-for="category in categories">{{ category }}</a>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <TutorialFilter v-model="filters" @update:model-value="getTutorials"></TutorialFilter>
                        <div class="row">
                            <div class="card col-md-3" v-for="tutorial in tutorials">
                                <img class="card-img-top" v-bind:src="tutorial.video_thumbnail"
                                     v-bind:alt="tutorial.title">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title">{{ tutorial.title }}</h5>
                                    <p class="card-text">{{ tutorial.description }}</p>
                                    <div class="d-grid gap-2 mt-auto">
                                        <a href="#" class="btn btn-outline-secondary"
                                           @click="showTutorialVideoModal(tutorial)">View</a>
                                    </div>
                                </div>
                                <div class="card-footer text-end"><span
                                    class="badge badge-info-lighten">{{ tutorial.category }}</span></div>
                            </div>
                        </div>
                        <Pagination
                            v-if="tutorials"
                            @pageChanged="loadPage"
                            :pagination="meta"
                            record-name="tutorials"
                        ></Pagination>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade" id="tutorialVideoModal" tabindex="-1" aria-labelledby="tutorialVideoModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="currentTutorial">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ currentTutorial.title }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="ratio ratio-16x9">
                            <iframe :src="currentTutorial.video_embed" type="text/html" allowfullscreen></iframe>
                        </div>
                        <p>{{ currentTutorial.description }}</p>
                    </div>
                    <div class="modal-footer">
                        <Button color="secondary" data-bs-dismiss="modal">Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import axios from "axios";
import Pagination from "../Pagination.vue";
import {onBeforeMount, onMounted, ref} from "vue";
import TutorialFilter from "@components/Tutorials/TutorialFilter.vue";
import {isEmpty, omitBy} from "lodash";
import Button from "@components/Button.vue";
import {TutorialInterface} from "@classes/Tutorial";

const tutorialVideoModal = ref();
const categories = ref<string[]>([]);
const tutorials = ref<TutorialInterface[]>([]);
const meta = ref({});
const currentTutorial = ref<TutorialInterface | null>(null);
const filters = ref({
    perPage: 10,
    query: '',
    video_service: '',
    order_direction: 'desc',
    page: 1,
    order_by: '',
    category: ''
});

const getCategories = (): void => {
    axios
        .get(route(`api.tutorials.categories.index`))
        .then((response) => {
                categories.value = response.data;
            }
        )
}

const getTutorials = (): void => {
    axios
        .get(route(`api.tutorials.index`, {
            paginate: true,
            ...omitBy(filters.value, isEmpty)
        }))
        .then(
            (response) => {
                tutorials.value = response.data.data;
                meta.value = response.data.meta;
                filters.value.page = response.data.meta.current_page;
            }
        )
}

const selectCategory = (value: string): void => {
    filters.value.category = value;
    getTutorials();
}

const loadPage = (link: { page: number; }): void => {
    filters.value.page = link.page;
    getTutorials();
}

const showTutorialVideoModal = (tutorial: TutorialInterface): void => {
    currentTutorial.value = tutorial;
    tutorialVideoModal?.value.show()
}

onBeforeMount(() => {
    getCategories();
    getTutorials();
})

onMounted(() => {
    tutorialVideoModal.value = new bootstrap.Modal(document.getElementById("tutorialVideoModal"))
})

</script>

<style scoped>
</style>
