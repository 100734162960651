<template>
    <div
        :class="[
            enabled ? 'bg-' + color + '-lighten' : 'bg-none'
        ]"
        class="me-2 border-0 py-1 px-2 rounded d-flex align-items-center justify-content-center"
    >
        <div class="form-check">
            <input
                :id="componentId"
                v-model="enabled"
                class="form-check-input"
                style="cursor:pointer"
                type="checkbox"
                value=""
            >
            <label :for="componentId" class="form-check-label">
                <slot/>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckboxButton"
}
</script>
<script setup>
import {computed, ref} from 'vue'

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    color: {
        type: String,
        required: false,
        default: 'primary'
    }
})

import {uuid} from "vue-uuid"

const componentId = ref(uuid.v4())

const emit = defineEmits(['update:modelValue'])

const enabled = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

</script>

<style scoped>

</style>
