<template>
    <div v-if="vehicle">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-1 mx-2">
                        <div class="me-2 flex-shrink-1 d-none d-md-block">
                            <img
                                :src="'/images/vehicles/'+ vehicle.type.image  +'.png'"
                                class="img-fluid me-3"
                                style="max-width: 200px;"
                                alt="Vehicle Icon"
                            >
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <h4>{{ vehicle.description }}</h4>
                        <p><span class="badge fs-6" :class="'badge-' + (vehicle.available ? 'success' : 'danger') + '-lighten'">{{ vehicle.available ? 'Available' : 'Unavailable' }}</span></p>
                        <div class="d-flex mt-3">
                            <div class="me-4">
                                <strong class="fs-5">{{ odometerFilter(vehicle.odometer)}}</strong>
                                <div>Odometer</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ numberFilter(vehicle.journey_count) }}</strong>
                                <div>Trips This Year</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ regoFilter(vehicle.registration_code) }}</strong>
                                <div>Registration</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ vehicle.make }}</strong>
                                <div>Make</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ vehicle.model }}</strong>
                                <div>Model</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ vehicle.colour }}</strong>
                                <div>Colour</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <ConfirmationModal
                            id="deleteVehicleConfirmationModal"
                            confirmation-title="Are You Sure"
                            confirmation-button-color="danger"
                            @confirmed="deleteVehicle"
                        >
                            <template #text>
                                <p>Are you sure that want to delete {{ vehicle.description }}?</p>
                            </template>
                            <template #confirm-button-text>
                                <Spinner
                                    v-if="deleting"
                                    class="me-1"
                                    color="white"
                                    size="small"
                                    type="border"
                                /> Delete Vehicle
                            </template>
                        </ConfirmationModal>
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h4 class="header-title">Vehicle Information</h4>
                            <div class="dropdown">
                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="mdi mdi-dots-vertical"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a :href="route('vehicles.edit', { 'vehicle': vehicle.uuid })" class="dropdown-item">Edit Vehicle</a>
                                    <a href="#" @click.prevent="showConfirmationModal" class="dropdown-item text-danger">Delete Vehicle</a>
                                </div>
                            </div>
                        </div>
                        <strong>Address:</strong>
                        <p>{{ vehicle.depot.full_address }}</p>
                        <hr />
                        <dl class="row mb-0">
                            <dt class="col-sm-5">Registration:</dt><dd class="col-sm-7">{{ vehicle.registration_code }}</dd>
                            <dt class="col-sm-5">VIN:</dt><dd class="col-sm-7">{{ vehicle.vin }}</dd>
                            <dt class="col-sm-5">Year:</dt><dd class="col-sm-7">{{ vehicle.year }}</dd>
                            <dt class="col-sm-5">Owner:</dt><dd class="col-sm-7">{{ vehicle.owner }}</dd>
                            <template v-if="vehicle.owner === 'Lease'">
                                <dt class="col-sm-5">Leasing Company:</dt>
                                <dd class="col-sm-7">
                                    <template v-if="vehicle.leasing_company">{{ vehicle.leasing_company }}</template>
                                    <template v-else>Not specified</template>
                                </dd>
                                <dt class="col-sm-5">Lease Expiry:</dt>
                                <dd class="col-sm-7">
                                    <ColorContextualDate v-if="vehicle.lease_expiry_date" :date="vehicle.lease_expiry_date" />
                                    <template v-else>Not specified</template>
                                </dd>
                            </template>
                            <dt class="col-sm-5">Type:</dt><dd class="col-sm-7">{{ vehicle.type.description }} ({{ vehicle.type.code }})</dd>
                            <dt class="col-sm-5">Cost per km:</dt><dd class="col-sm-7">{{ currencyFilter(vehicle.operating_costs) }}</dd>
                            <dt class="col-sm-5">Registration Expiry:</dt>
                            <dd class="col-sm-7">
                                <ColorContextualDate v-if="vehicle.registration_expiry" :date="vehicle.registration_expiry" />
                                <template v-else>Not specified</template>
                            </dd>
                            <dt class="col-sm-5">Insurance Expiry:</dt>
                            <dd class="col-sm-7">
                                <ColorContextualDate v-if="vehicle.insurance_expiry" :date="vehicle.insurance_expiry" />
                                <template v-else>Not specified</template>
                            </dd>
                            <dt class="col-sm-5">Modification Compliance Plate:</dt><dd class="col-sm-7">{{ vehicle.modification_compliance_plate_date }}</dd>
                            <dt class="col-sm-5">Date of Purchase:</dt>
                            <dd class="col-sm-7">
                                <template v-if="vehicle.date_of_purchase">{{ vehicle.date_of_purchase }}</template>
                                <template v-else>Not specified</template>
                            </dd>
                            <dt class="col-sm-5">Date of Disposal:</dt>
                            <dd class="col-sm-7">
                                <template v-if="vehicle.date_of_disposal">{{ vehicle.date_of_disposal }}</template>
                                <template v-else>Not specified</template>
                            </dd>
                            <dt class="col-sm-5">Vehicle Cost:</dt>
                            <dd class="col-sm-7">
                                <template v-if="vehicle.purchase_cost">{{ currencyFilter(vehicle.purchase_cost) }}</template>
                                <template v-else>Not specified</template>
                            </dd>
                        </dl>
                        <hr />
                        <strong>Notes:</strong>
                        <p>{{ vehicle.notes}}</p>
                        <hr />
                        <hr />
                        <ConfirmButton
                            :class-array="['p-0 text-danger']"
                            :confirmationText="`Are you sure that want to delete ${vehicle.description} vehicle`"
                            confirmationTitle="Are You Sure"
                            confirmation-button-color="danger"
                            color="link"
                            @confirmed="deleteVehicle(vehicle.uuid)"
                        >
                            Delete vehicle
                        </ConfirmButton>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="tabs">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'vehicle_schedule' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('vehicle_schedule')"
                            >Vehicle Schedule</a>
                        </li>
                        <!--
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'maint' }"
                                @click.prevent="setTab('maint')"
                                href="#"
                            >Maintenance</a>
                        </li> -->
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'logs' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('logs')"
                            >Vehicle Log</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'config' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('config')"
                            >Vehicle Configuration</a>
                        </li>
<!-- TODO uncomment this when building TR -->
<!--                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'configuration_templates' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('configuration_templates')"
                            >Configuration Templates</a>
                        </li>-->
                        <!-- <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'incid' }"
                                @click.prevent="setTab('incid')"
                                href="#"
                            >Incidents</a>
                        </li> -->
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ 'active': currentTab === 'restriction' }"
                                href="javascript: void(0)"
                                @click.prevent="setTab('restriction')"
                            >Location Restrictions</a>
                        </li>
                    </ul>
                </div>
                <div class="card">
                    <div class="card-body">
                        <span v-if="isTab('vehicle_schedule')">
                            <VehicleSchedule :vehicle="vehicle" />
                        </span>
                        <span v-if="isTab('maint')">Maintenance Goes Here</span>
                        <VehicleLogs v-if="isTab('logs')" :vehicle="vehicle"/>
                        <span v-if="isTab('incid')">Incidents Goes Here</span>
                        <span v-if="isTab('config')">
                            <VehicleConfigurationShow :vehicle="vehicle"/>
                        </span>
                        <span v-if="isTab('restriction')">
                            <h4 class="header-title mt-0 mb-3">Location Restrictions</h4>
                            <RestrictionsList :vehicle="vehicle.uuid" />
                        </span>
                        <span v-if="isTab('configuration_templates')">
                            <VehicleConfigurationTemplates :vehicle="vehicle"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex justify-content-center">
        <Spinner></Spinner>
    </div>
</template>

<script setup>
import {ref, inject, onMounted, onBeforeMount, nextTick} from 'vue'
import {odometerFilter, regoFilter, currencyFilter, numberFilter} from '../Utils'
import VehicleConfigurationShow from './parts/VehicleConfigurationShow.vue';
import VehicleConfigurationTemplates from './parts/VehicleConfigurationTemplates.vue';
import VehicleLogs from './parts/VehicleLogs.vue'
import ConfirmButton from "../ConfirmButton.vue";
import ConfirmationModal from "../ConfirmationModal.vue";
import Spinner from "../Spinner.vue";
import ColorContextualDate from "../../components/ColorContextualDate.vue";
import VehicleSchedule from "@components/Vehicles/VehicleSchedule.vue";
import RestrictionsList from "@components/Locations/Restrictions/RestrictionsList.vue";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    vehicle: {
        required: true,
        type: Object
    }
})

const vehicle = ref();

const currentTab = ref('vehicle_schedule')

const setTab = tab => currentTab.value = tab

const isTab = tab => currentTab.value === tab

const showConfirmationModal = () => {
    deleteVehicleModal.value.show()
}

const deleting = ref(false)
const deleteVehicle = () => {
    deleting.value = true
    let message = 'An unknown error has occured'

    axios.delete(route('api.vehicles.destroy', { vehicle: vehicle.value.uuid})).then((resp) => {
        toast.success('Success, redirecting to vehicles list...')
        setTimeout(() => {
            window.location.href = route('vehicles.index')
        }, 1500)
    }).catch((err) => {
        console.error(message)
        toast.error(message)
    }).finally(() => {
        deleting.value = false
        deleteVehicleModal.value.hide()
    })
}

const deleteVehicleModal = ref(null)

const getVehicleProfileData = (mountModal = false) => {
    axios.get(route('api.vehicles.show', { vehicle: props.vehicle.uuid})).then((resp) => {
        vehicle.value = resp.data.data;
        if (mountModal) {
            nextTick(() => {
                deleteVehicleModal.value = new bootstrap.Modal(document.getElementById('deleteVehicleConfirmationModal'));
            });
        }
    }).catch((message) => {
        console.error(message)
        toast.error(message)
    })
}

onMounted(() => {
    getVehicleProfileData(true);
})

</script>
