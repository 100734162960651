<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Funding Plans</h4>
                <div>
                    <Button :icon="mdiPlusCircle" @click="createFundingPlan" color="link" size="small">
                        Add Funding Plan
                    </Button>
                </div>
            </div>
            <template v-if="fundingPlans.length === 0">
                <div class="text-center">
                    <SvgIcon class="h-14 w-14 text-secondary" type="mdi" :path="mdiCashMultiple"></SvgIcon>
                    <div class="fs-4 my-2">{{ client.preferred_name ?? client.name }} has no funding plans.</div>
                </div>
            </template>
            <template v-else-if="fundingPlans.length > 0">
                <template v-for="(plan, index) in fundingPlans" :key="index">
                    <FundingPlan
                        :client="client"
                        :plan="plan"
                        class="mt-2"
                        @updated="(newPlanDetails) => updatePlan(index, newPlanDetails)"
                    ></FundingPlan>
                </template>
            </template>
        </div>
        <div id="createFundingPlan" aria-hidden="true" aria-labelledby="createFundingPlanLabel" class="modal fade"
             tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createFundingPlanLabel" class="modal-title">Create Funding Plan</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <FundingPlanForm
                            :client="client"
                            @cancel="cancelModalCreation"
                            @saved="addNewClientFundingPlan"></FundingPlanForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditClientFundingPlans"
}
</script>
<script setup>
import {ref, onBeforeMount, onMounted} from "vue"
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCashMultiple, mdiPlusCircle } from "@mdi/js";
import Button from "../Button.vue";
import FundingPlan from "./FundingPlan.vue";
import FundingPlanForm from "./FundingPlanForm.vue";

const props = defineProps({
    client: Object,
})
const emit = defineEmits(['updated'])

const cancelModalCreation = function () {
    createFundingPlanModal.value.hide()
    initialiseData()
}

const updatePlan = function (index, planDetails) {

}

import {copyValues} from "../Utils.js";

const addNewClientFundingPlan = function (newFundingPlan) {
    let newClientDetails = copyValues(props.client)
    newClientDetails.funding_plans.push(newFundingPlan)
    createFundingPlanModal.value.hide()
    emit.updated(newClientDetails)
    initialiseData()
}

const updating = ref(false)
const update = function () {
    updating.value = true
}

const createFundingPlanModal = ref(null)
const createFundingPlan = function () {
    createFundingPlanModal.value.show()
}

const fundingPlans = ref([])
const initialiseData = function () {
    fundingPlans.value = props.client.funding_plans
}

onMounted(() => {
    createFundingPlanModal.value = new bootstrap.Modal(document.getElementById("createFundingPlan"))
})

onBeforeMount(initialiseData)

</script>
