<template>
    <div>
        <div v-if="driver && driverRegulationCheckEnabled && !driver.is_compliant" class="alert alert-warning">
            The Driver Record Check for {{ driver.name }} is out of date, and must be completed before assigning them to a vehicle run.
            <a :href="route('drivers.show', { driver: driver.uuid })">Update Driver Record Check</a>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'

const axios = inject('axios')

const props = defineProps({
    driver: Object,
})

const driverRegulationCheckEnabled = ref(true)

const getSettings = () => {
    axios
        .get(route('api.organisation-settings.index'), {
            params: {
                category: 'general'
            }
        }).then(response => {
            const setting = response.data.find(setting => setting.name === 'driver_regulation_check')
            driverRegulationCheckEnabled.value = setting.value === "1"
        })
}

onMounted(() => {
    getSettings()
})
</script>