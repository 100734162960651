<template>
    <div
        class="card"
        @mouseover="showEditButton = true"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body row">
            <template v-if="!showEditForm">
                <div class="col-md-6">
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title my-0 mb-3">Transport Requirements</h4>
                    </div>
                    <div>
                        <b>{{ chosenSeatDetail?.name}}</b>
                    </div>
                    <div class="mt-4">
                        <h4 class="header-title my-0 mb-3">Vehicle</h4>
                        <template v-for="(groupVal, group) in chosenVehicle">
                            <div v-if="groupVal.vehicles.length > 0" class="border border-light p-2 mb-2">
                                <b>{{bookingRequirementStore.getGroupDetail(group)?.name}}</b>
                                <div class="row">
                                    <div v-for="vehicleType in groupVal.vehicles" class="col-md-6">
                                        {{ bookingRequirementStore.getVehicleTypeDetail(vehicleType)?.description }}
                                    </div>
                                </div>
                                <div class="mt-2"><b>Seat Options</b></div>
                                <div class="row">
                                    <div v-for="seatPosition in groupVal.seat_positions" class="col-md-6">
                                        {{ bookingRequirementStore.getSeatPositionDetail(seatPosition)?.label }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title my-0 mb-3">Assistance Requirements</h4>
                        <div v-if="showEditButton && !showEditForm">
                            <Button @click="switchToEditForm()" color="link" size="small">
                                <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                                Edit
                            </Button>
                        </div>
                    </div>
                    <div v-for="assistanceType in selectedAssistanceTypes" :set="assistanceTypeDetail = bookingRequirementStore.getAssistanceTypeDetail(assistanceType.code)">
                        <span
                            v-tooltip="assistanceTypeDetail?.description"
                            class="ms-1 mb-2"
                        >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        /></span> {{ assistanceTypeDetail?.label }}
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                        <h4 class="header-title mt-4 mb-3">Cargo Requirements</h4>
                    </div>
                    <div v-for="cargoSpace in selectedCargoSpaces" :set="cargoDetail = bookingRequirementStore.getCargoSpaceDetail(cargoSpace.code)">
                        <span
                            v-tooltip="cargoDetail?.description"
                            class="ms-1 mb-2"
                        >
                        <SvgIcon
                            type="mdi"
                            :path="mdiInformationOutline"
                            :size="20"
                            class="text-info"
                        /></span> {{ cargoDetail?.name }}
                    </div>
                </div>
            </template>
            <template v-if="showEditForm">
                <TransportRequirementsForm />
                <div class="d-flex align-items-center justify-content-end">
                    <Button size="small" :loading="updating" :disabled="saveIsDisabled()" @click="saving">Save</Button>
                    <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {ref, onBeforeMount, inject, watch, computed} from "vue";
import Button from "../Button.vue";
import { mdiPencil, mdiInformationOutline } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon"
import { TransportRequirementsForm } from '../AccessRequirements'
import AssistanceType from "@classes/AssistanceType";
import { useBookingRequirementStore } from "@stores/BookingRequirementStore";
import CargoSpace from "@classes/CargoSpace";
import {storeToRefs} from "pinia";
import VehicleGroup from "@classes/VehicleGroup";
import SeatType from "@classes/SeatType";

const axios = inject('axios');
const toast = inject('toast');
const bookingRequirementStore = useBookingRequirementStore();

const props = defineProps({
    bookingRequirements: {
        required: true,
        type: Object
    }
});

const {
    seatTypeOptions,
    chosenSeat,
    chosenVehicle,
    selectedAssistanceTypes,
    selectedCargoSpaces,
} = storeToRefs(bookingRequirementStore);

const emit = defineEmits(['saving', 'updated']);
const showEditButton = ref(false);
const showEditForm = ref(false);

const cancelChanges = function () {
    bookingRequirementStore.cancelToBeforeSaveData();

    showEditForm.value = false;
}

const chosenSeatDetail = computed(() => {
    return seatTypeOptions.value.find(sto => sto.code === chosenSeat.value);
})

const updating = ref(false)

const saving = () => {
    const payload = bookingRequirementStore.convertToPayload();
    emit('saving', payload);
    showEditForm.value = false;
}

const switchToEditForm = () => {
    bookingRequirementStore.fillBeforeSaveData();
    showEditForm.value = true;
}

const saveIsDisabled = () => {
    if (!chosenSeat.value) {
        return true;
    }

    if (Object.keys(chosenVehicle.value).length < 1) {
        return true;
    }

    let missingVehicles = true;
    // if 1 of the group has vehicle then vehicle not missing
    for (const vehicleGroup in chosenVehicle.value) {
        if (
            chosenVehicle.value[vehicleGroup].vehicles.length > 0
        ) {
            missingVehicles = false;
        }
    }

    if (missingVehicles) {
        return true;
    }

    if (
        chosenSeat.value === 'non_transfer_manual_wheelchair' ||
        chosenSeat.value === 'ewheelchair'
    ) {
        return false;
    }

    let missingSeats = false;
    for (const vehicleGroup in chosenVehicle.value) {
        if (
            chosenVehicle.value[vehicleGroup].vehicles.length > 0 &&
            chosenVehicle.value[vehicleGroup].seat_positions.length < 1
        ) {
            missingSeats = true;
        }
    }

    if (missingSeats) {
        return true;
    }

    return false;
}

onBeforeMount(() => {
    bookingRequirementStore.resetSeat();
});

watch(props, (newProps) => {
    bookingRequirementStore.convertFromPayload(newProps.bookingRequirements);
})

</script>

<style scoped>

</style>
