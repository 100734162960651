<template>
    <div>
        <div v-if="uploads.length > 0">
            <File v-for="(upload, index) in uploads"
                  :key="index"
                  :baseUrl="options.baseUrl"
                  :endpoint="endpointFor(upload.file.type)"
                  @progress="handleUploadProgress"
                  @stateChange="handleStateChange"
                  :upload="upload"/>
        </div>
        <UploaderForm :completedUploadCount="completedUploadCount"
            :currentlyUploading="currentlyUploading"
            :options="options"
            @chosen="handleFilesChosen"
        />
    </div>
</template>

<script>
export default {
    name: "Uploader"
}
</script>
<script setup>
import defaultOptions from "./defaultOptions"
import states from "./states.js"
import File from "./components/File.vue"
import UploaderForm from "./components/UploaderForm.vue"
import {computed, onMounted, ref} from 'vue'
import {uuid} from 'vue-uuid'
import get from "lodash.get"

const uploads = ref([])

const props = defineProps({
    options: {
        required: false,
        type: Object,
        default: {}
    },
    handlers: {
        required: true,
        type: Object,
    }
})

const options = {
    ...defaultOptions,
    ...props.options
}

const overallProgress = computed(() => {
    uploads.value = uploads.value.filter((upload) => !(upload.cancelled || upload.failed));
    if (uploads.value.length === 0) {
        return 0;
    }
    return parseInt(uploads.value.reduce((a, b) => a + b.progress, 0) / uploads.value.length);
})

const currentUploadCount = computed(() => {
    return uploads.value.filter((upload) => upload.uploading).length;
})

const currentQueuedCount = computed(() => {
    return uploads.value.filter((upload) => upload.queued).length;
})

const completedUploadCount = computed(() => {
    return uploads.value.filter((upload) => upload.complete).length;
})

const currentlyUploading = computed(() => {
    return currentUploadCount.value > 0 || currentQueuedCount.value > 0;
})

const handleFilesChosen = (files) => {
    files.forEach((file) => {
        uploads.value.push({
            id: uuid.v4(),
            file: file,
            progress: 0,
            state: states.QUEUED,
            cancelled: false,
            failed: false,
            complete: false,
            uploading: false,
            queued: true,
            response: null
        })
    })
}

const handleUploadProgress = (event) => {
    uploads.value = uploads.value.map((upload) => {
        if (upload.id === event.id) {
            upload.progress = event.progress;
        }
        return upload;
    })
}

const handleStateChange = (event) => {
    uploads.value = uploads.value.map((upload) => {
        if (upload.id === event.id) {
            switch (event.state) {
                case states.UPLOADING:
                    upload.uploading = true;
                    break;
                case states.COMPLETED:
                    upload.complete = true;
                    upload.uploading = false;
                    break;
                case states.CANCELLED:
                    upload.cancelled = true;
                    upload.uploading = false;
                    break;
                case states.FAILED:
                case states.TOO_LARGE:
                    upload.failed = true;
                    upload.uploading = false;
                    break;
            }
        }
        return upload;
    })
}

const endpointFor = (type) => get(props.handlers[type], 'endpoint', null)

onMounted(() => {
    setInterval(() => {
        if (currentUploadCount.value >= options.maxConcurrentUploads) {
            return
        }

        if (currentUploadCount.value + currentUploadCount.value >= options.maxUploads) {
            return
        }

        let queued = uploads.value.filter((upload) => upload.queued === true)
        if (queued.length) {
            queued[0].queued = false
        }
    }, 500)
})

</script>

<style scoped>

</style>
