<template>
    <div class="row">
        <transition name="fade">
            <div class="col-md-4">
                <h4>Passenger Details</h4>
                <p>Details about who will be taking part in this booking</p>
            </div>
        </transition>
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <ClientSearch
                        @clientSelected="bookingStore.addClientToDraftBooking"
                    ></ClientSearch>

                    <div class="mt-2 row">
                        <div
                            v-for="(client, key) in bookingStore.draft.clients"
                            v-bind:key="client.uuid"
                            class="col-md-6"
                        >
                            <BookingFormClientCard :client="client">
                                <template v-slot:action>
                                    <a
                                        href="javascript: void(0)"
                                        class="float-end"
                                        @click="() => removeClient(client)"
                                    >
                                        <i class="mdi mdi-close"></i>
                                    </a>
                                </template>
                                <template v-slot:content>
                                    <div
                                        v-if="client.alert"
                                        class="mt-2 mb-0 py-1 px-2 alert alert-light d-flex align-items-center"
                                    >
                                        <i
                                            class="mdi mdi-alert-circle-outline me-2 fs-4"
                                        ></i>
                                        <div>
                                            {{ client.alert }}
                                        </div>
                                    </div>
                                </template>
                            </BookingFormClientCard>
                        </div>
                    </div>

                    <div v-if="bookingStore.draft.clients.length > 0">
                        <div class="mt-3 row">
                            <div class="col-md-6">
                                <label for="bookingDateInput" class="form-label"
                                    >Booking Date</label
                                >
                                <input
                                    v-model="draft.date"
                                    :min="
                                        DateTime.now()
                                            .minus({ years: 1 })
                                            .toFormat('yyyy-MM-dd')
                                    "
                                    :max="
                                        DateTime.now()
                                            .plus({ years: 1 })
                                            .toFormat('yyyy-MM-dd')
                                    "
                                    type="date"
                                    id="bookingDateInput"
                                    class="form-control"
                                    :class="{
                                        'border border-warning rounded-0 rounded-top':
                                            pastDate,
                                    }"
                                />
                                <div
                                    v-if="pastDate"
                                    class="w-100 text-center bg-warning rounded-bottom"
                                >
                                    Date is in the past
                                </div>
                            </div>
                        </div>

                        <div class="mt-3 row" v-if="selectedClient">
                            <div class="col-md-6">
                                <label
                                    for="clientSearchInput"
                                    class="form-label"
                                    >Person Accompanying
                                    {{ selectedClient?.name }}</label
                                >
                                <VueMultiselect
                                    v-model="selectedCarer"
                                    :options="carerOptions"
                                    :multiple="false"
                                    :close-on-select="true"
                                    placeholder="Select Carer"
                                    label="given_names"
                                    track-by="uuid"
                                ></VueMultiselect>
                            </div>
                        </div>

                        <div
                            class="mt-3 row"
                            v-if="
                                selectedClient &&
                                (selectedClient?.regions ?? []).length > 1
                            "
                        >
                            <div class="col-md-6">
                                <label
                                    for="clientSearchInput"
                                    class="form-label"
                                    >Region For
                                    {{ selectedClient?.name }}</label
                                >

                                <VueMultiselect
                                    v-model="region"
                                    :options="regionsOptions"
                                    :multiple="false"
                                    :allow-empty="false"
                                    :close-on-select="true"
                                    placeholder="Select Region"
                                    label="name"
                                    track-by="uuid"
                                ></VueMultiselect>
                            </div>
                        </div>

                        <div class="mt-3 row">
                            <div class="col-md-6">
                                <CompanionCard :companion="selectedCarer">
                                    <template v-slot:action>
                                        <a
                                            href="javascript: void(0)"
                                            class="float-end"
                                            @click="() => removeCarer()"
                                        >
                                            <i class="mdi mdi-close"></i>
                                        </a> </template
                                ></CompanionCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, inject, watch } from "vue";
import ClientSearch from "./ClientSearch.vue";
import CompanionCard from "./CompanionCard.vue";
import BookingFormClientCard from "./BookingFormClientCard.vue";
import { ClientInterface } from "@/resources/js/classes/Client";
import VueMultiselect from "vue-multiselect";
import { DateTime } from "luxon";
import Client from "@classes/Client";

const bookingStore = useBookingStore();

const { selectedClient, regions } = storeToRefs(bookingStore);

const removeClient = (client: ClientInterface): MouseEvent => {
    let event: MouseEvent = new MouseEvent("");

    bookingStore.removeClientFromDraftBooking(client);

    //Stop TS from complaining as clicks need to return a mouse event
    return event;
};

const removeCarer = (): MouseEvent => {
    let event: MouseEvent = new MouseEvent("");

    selectedCarer.value = undefined;

    //Stop TS from complaining as clicks need to return a mouse event
    return event;
};

const carerOptions = computed<App.Models.Carer[]>(() => {
    return selectedClient?.value?.carers ?? [];
});

//In the future we may have multiple companions per booking
const companionUuids = bookingStore.getComputedForClientSpecificValue<string[]>(
    "companionUuids",
    []
);

const regionUuid = bookingStore.getComputedForClientSpecificValue<
    string | undefined
>("regionUuid", undefined);

const region = computed<App.Models.Region | undefined>({
    get() {
        return regions.value?.find((i) => regionUuid.value == i.uuid);
    },
    set(newValue) {
        regionUuid.value = newValue?.uuid;
    },
});

const selectedCarer = computed<App.Models.Carer | undefined>({
    get() {
        return carerOptions.value?.find((i) =>
            companionUuids.value.includes(i.uuid)
        );
    },
    set(newValue) {
        companionUuids.value = newValue ? [newValue.uuid] : [];
    },
});

const regionsOptions = computed<App.Models.Region[]>(() => {
    return regions.value.filter((i) => {
        return selectedClient.value?.regions?.find((j) => j.uuid === i.uuid);
    });
});

//Ziggy js encodes client array like => ?client[0]=CLI-23-YDG7ECVD&client[1]=CLI-23-PQ8KZGEA
//I would use URLSearchParams but that function is not used to the way Ziggy encodes arrays for GET parameters
const getClientUuidsFromUrlSearchParams = () => {
    const str = window.location.search;

    const regex = /client\[(\d+)\]=([^&]+)/g;
    const matches = str.matchAll(regex);
    const values: string[] = [];

    for (const match of matches) {
        const value = match[2];
        values.push(value);
    }

    return values;
};

onMounted(() => {
    getClientUuidsFromUrlSearchParams().map((clientUuid) => {
        Client.show(clientUuid).then((response) => {
            bookingStore.addClientToDraftBooking(response.data.data);
        });
    });
});

const { draft } = storeToRefs(bookingStore);

const pastDate = computed(() => {
    return (
        DateTime.fromFormat(bookingStore.draft.date, "yyyy-MM-dd") <
        DateTime.now().startOf("day")
    );
});

watch(
    () => selectedClient.value,
    (newValue) => {
        if (!regionUuid.value) {
            regionUuid.value = selectedClient.value?.regions?.find(
                (i) => i.pivot.is_default
            )?.uuid;
        }
    }
);
</script>
