<template>
    <div
        id="carerModal"
        class="modal fade modal-lg"
        aria-hidden="true"
        aria-labelledby="carerModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h3 class="modal-title" id="carerModalLabel">{{action === 'create'? 'Create' : 'Edit'}} Client Carer</h3>
                    </div>
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <FormTextInput
                                v-model:input="carerInput.given_names"
                                :validation="yup.string().max(255).required()"
                                label="Given name"
                                placeholder="e.g. Jesse"
                            />
                        </div>
                        <div class="col-md-6">
                            <FormTextInput
                                v-model:input="carerInput.last_name"
                                :validation="yup.string().max(255).required()"
                                label="Last name"
                                placeholder="e.g. Appleseed"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <FormTextInput
                                v-model:input="carerInput.email"
                                :validation="yup.string().email().max(255).required()"
                                :type="'email'"
                                label="Email"
                                placeholder="e.g. example@email.com"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <FormPhoneInput
                                v-model:input="carerInput.phone"
                                :validation="yup.string().max(255).required()"
                                label="Primary phone number"
                                placeholder="e.g. 02 9876 5432"
                            />
                        </div>
                        <div class="col-md-6">
                            <FormPhoneInput
                                v-model:input="carerInput.alt_phone"
                                :validation="yup.string().max(255).optional()"
                                label="Alternate phone number"
                                placeholder="e.g. 02 9876 5432"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <AddressDbSearch v-if="carerModal?._isShown"
                                :invalid="carerInput.address.errors.length > 0"
                                :label="'Home address'"
                                v-model="carer.address"
                                @locationSelected="selectHomeLocation"
                                aria-describedby="address_feedback"
                            />
                            <div v-if="carerInput.address.errors.length" id="address_feedback" class="invalid-feedback">
                                {{ carerInput.address.errors[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.gender"
                                :options="genders"
                                label="Gender"
                                placeholder="-- Select gender --"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormDateInput
                                v-model:input="carerInput.date_of_birth"
                                label="Date of birth"
                                :validation="yup.date()"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSwitch
                                class="mt-4"
                                label="Date of birth is estimated"
                                v-model:input="carerInput.date_of_birth_is_estimated"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.indigenous_status"
                                :options="indigenousStatuses"
                                label="Indigenous status"
                                placeholder="-- Select indigenous status --"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.language"
                                :options="languages"
                                label="Language"
                                placeholder="-- Select language --"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.birth_country"
                                :options="countries"
                                label="Country of birth"
                                placeholder="-- Select country of birth --"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.residency_status"
                                :options="residencyStatuses"
                                label="Residency status"
                                placeholder="-- Select residency status --"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.carer_for_multiple"
                                :options="carerForMultiples"
                                label="carer for more than one person"
                                placeholder="-- Select carer status --"
                            />
                        </div>
                        <div class="col-md-4">
                            <FormSelect
                                v-model:selection="carerInput.relationship"
                                :options="relationships"
                                label="Relationship to the care recipient"
                                placeholder="-- Select carer relationship --"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <Button @click.prevent="saveCarer" :loading="loading">
                        Save changes
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, toRef, toRefs, watch} from "vue";
import ClientCarer, {CarerInputPayloadInterface, CarerInterface} from "@classes/ClientCarer";
import Button from "@components/Button.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import * as yup from "yup";
import {FormTextInput, FormEmailInput, FormPhoneInput, FormDateInput, FormSelect} from "../Forms";
import AddressDbSearch from "@components/AddressDbSearch.vue";
import FormSwitch from "@components/Forms/FormSwitch.vue";
import axios from "axios";
import {uuid} from "vue-uuid";
import {LocationInterface} from "@classes/Location";

const toast: any = inject('toast');
const carerModal = ref<null | bootstrap.Modal>(null);
const props = withDefaults(
    defineProps<{
        client: App.Models.Client,
        carer: CarerInterface,
        action?: string
    }>(),
    {
        action: 'create'
    }
);
const submitting = ref(false);
const { action, carer } = toRefs(props);
const carerInput = ref<CarerInputPayloadInterface>({
    given_names: {
        uuid: uuid.v4(),
        value: '',
        required: true,
        errors: [],
    },
    last_name: {
        uuid: uuid.v4(),
        value: '',
        required: true,
        errors: [],
    },
    email: {
        uuid: uuid.v4(),
        value: '',
        required: true,
        errors: [],
    },
    phone: {
        uuid: uuid.v4(),
        value: '',
        required: true,
        errors: [],
    },
    alt_phone: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    address: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    date_of_birth: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    date_of_birth_is_estimated: {
        uuid: uuid.v4(),
        value: false,
        required: false,
        errors: [],
    },
    gender: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    indigenous_status: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    language: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    birth_country: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    residency_status: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
    carer_for_multiple: {
        uuid: uuid.v4(),
        value: false,
        required: false,
        errors: [],
    },
    relationship: {
        uuid: uuid.v4(),
        value: '',
        required: false,
        errors: [],
    },
});
const actionList = ['view', 'edit', 'create'];
const emit = defineEmits(['closed', 'created', 'updated', 'deleted']);

const showModal = () => {
    carerModal.value.show();
}

const closeModal = () => {
    carerModal.value.hide();
    emit('closed', '');
}
const permissions = window.permissions
const genders = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'intersex', label: 'Intersex / Indeterminate'},
    {value: 'not_stated', label: 'Not Stated'},
];

const indigenousStatuses = [
    {value: 'aboriginal', label: 'Aboriginal'},
    {value: 'torres_straight_islander', label: 'Torres Strait Islander'},
    {value: 'aboriginal_and_torres_straight_islander', label: 'Both Aboriginal & Torres Strait Islander '},
    {value: 'neither', label: 'Neither Aboriginal nor Torres Strait Islander'},
    {value: 'not_stated', label: 'Not stated/inadequately described'},
];

const residencyStatuses = [
    {value: 'co-resident', label: 'Co-resident carer'},
    {value: 'non-resident', label:'Non-resident carer'},
    {value: 'not_stated', label: 'Not Stated'},
];

const carerForMultiples = [
    {value: 'yes', label: 'Yes'},
    {value: 'no', label: 'No'},
    {value: 'not_stated', label: 'Not stated/inadequately described'},
];

const relationships = [
    {value:'spouse', label:'Spouse/partner'},
    {value:'parent', label:'Parent'},
    {value:'child', label:'Son or daughter'},
    {value:'child_spouse', label:'Son-in-law or daughter-in-law'},
    {value:'other', label:'Other relative'},
    {value:'friend', label:'Friend/neighbour'},
    {value:'agency_worker', label:'Paid Agency worker'},
    {value:'not_stated', label:'Not stated/inadequately described'},
];

const selectHomeLocation = (location) => {
    carerInput.value.address.value = location.uuid;
}

let loading = ref(false);
const languages = ref([]);
const getLanguages = function () {
    axios
        .get(route('api.languages'))
        .then((response) => {
                languages.value = Object.entries(response.data).map(([key, value]) =>
                    ({'value': key, 'label': value})
                );
        });
}

const countries = ref([]);
const getCountries = function () {
    axios
        .get(route('api.countries'))
        .then((response) => {
            countries.value = Object.entries(response.data).map(([key, value]) =>
                ({'value':key, 'label':value})
            );
        });
}

const setValidationErrors = (error) => {
    for (const [key, value] of Object.entries(error.response.data.errors)) {
        carerInput.value[key].errors = value
    }
}

const clearValidationErrors = () => {
    for (var key in carerInput.value) {
        if (carerInput.value.hasOwnProperty(key)) {
            carerInput.value[key].errors = [];
        }
    }
}

const resetEditState = () => {
    closeModal();
    loading.value = false;
}

const carerPayload = () => {
    let address = '';
    if (typeof carerInput.value.address.value === 'string') {
        address = carerInput.value.address.value;
    } else if (typeof carerInput.value.address.value === 'object' &&
        carerInput.value.address.value?.uuid
    ) {
        address = carerInput.value.address.value.uuid;
    }
    return {
        'given_names': carerInput.value.given_names.value,
        'last_name':  carerInput.value.last_name.value,
        'email': carerInput.value.email.value,
        'phone': carerInput.value.phone.value,
        'alt_phone': carerInput.value.alt_phone.value,
        'relationship': carerInput.value.relationship.value,
        'gender': carerInput.value.gender.value,
        'date_of_birth': carerInput.value.date_of_birth.value,
        'date_of_birth_is_estimated': carerInput.value.date_of_birth_is_estimated.value,
        'birth_country': carerInput.value.birth_country.value,
        'language': carerInput.value.language.value,
        'indigenous_status': carerInput.value.indigenous_status.value,
        'residency_status': carerInput.value.residency_status.value,
        'carer_for_multiple': carerInput.value.carer_for_multiple.value !== false ? carerInput.value.carer_for_multiple.value : '',
        'address': address
    }
}

const carerToInput = () => {
    carerInput.value.given_names.value = props.carer.given_names;
    carerInput.value.last_name.value = props.carer.last_name;
    carerInput.value.email.value = props.carer.email;
    carerInput.value.phone.value = props.carer.phone;
    carerInput.value.alt_phone.value = props.carer.alt_phone;
    carerInput.value.relationship.value = props.carer.relationship;
    carerInput.value.gender.value = props.carer.gender;
    carerInput.value.date_of_birth.value = props.carer.date_of_birth;
    carerInput.value.date_of_birth_is_estimated.value = props.carer.date_of_birth_is_estimated;
    carerInput.value.birth_country.value = props.carer.birth_country;
    carerInput.value.language.value = props.carer.language;
    carerInput.value.indigenous_status.value = props.carer.indigenous_status;
    carerInput.value.residency_status.value = props.carer.residency_status;
    carerInput.value.carer_for_multiple.value = props.carer.carer_for_multiple;
    carerInput.value.address.value = props.carer.address;
}

const saveCarer = () => {
    clearValidationErrors();
    loading.value = true;
    const payload = carerPayload();

    if (props.carer.uuid && action.value == 'edit') {
        ClientCarer.update(props.client.uuid, props.carer.uuid, payload)
            .then(response => {
                resetEditState();
                toast.success('Carer successfully updated.');
                emit('updated', response.data.data);
            })
            .catch(error => {
                console.error(error);
                toast.error(error.response.data.message)
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error)
                }
            }).finally(() => {
            loading.value = false;
        })
    } else {
        ClientCarer.store(props.client.uuid, payload)
            .then(response => {
                resetEditState();
                toast.success('Carer successfully added.');
                emit('created', response.data.data);
            })
            .catch(error => {
                toast.error(error.response.data.message)
                if (error.response && error.response.status === 422) {
                    setValidationErrors(error)
                }
            }).finally(() => {
            loading.value = false;
        })
    }
}

onMounted(() => {
    getLanguages();
    getCountries();
    carerModal.value = new bootstrap.Modal(document.getElementById("carerModal"));

    showModal();
    if (action.value === 'edit') {
        carerToInput();
    }
})

</script>

<style scoped>

</style>
