<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        Generate DEX Report
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <FormDateInput v-model:input="dateFrom" :max="dateUntil.value" label="Date from" />
                            </div>
                            <div class="col-md-4">
                                <FormDateInput v-model:input="dateUntil" :min="dateFrom.value" label="Date until" />
                            </div>
                        </div>
                        <div class="mb-3" v-if="payers.length > 0">
                            <label for="payerFilterSelect" class="form-label">Filter by Payer</label>
                            <VueMultiselect
                                v-model="selectedPayers"
                                :options="payers"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Any Payer"
                                label="name"
                                track-by="uuid"
                            />
                        </div>
                        <div class="mb-3" v-if="regions.length > 0">
                            <label for="regionFilterSelect" class="form-label">Filter by Region</label>
                            <VueMultiselect
                                v-model="selectedRegions"
                                :options="regions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Any Region"
                                label="name"
                                track-by="uuid"
                            />
                        </div>
                        <Button :icon="mdiDownload" @click="download" :loading="preparingDownload">
                            <template v-if="preparingDownload">Preparing File</template>
                            <template v-else>Download</template>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DataExchangeDashboard"
}
</script>
<script setup>
import {mdiDownload} from '@mdi/js'
import Button from '../Button.vue'
import {ref, inject, onMounted} from 'vue'
import {FormDateInput} from '../Forms'
import {toTimeStamp} from '../Utils.js'
import VueMultiselect from "vue-multiselect";
import {DateTime} from 'luxon'

const axios = inject('axios')
const toast = inject('toast')

const dateFrom = ref({
    value: '',
    required: false,
})
const dateUntil = ref({
    value: '',
    required: false,
})
const selectedPayers = ref([])
const selectedRegions = ref([])
const selectedFundingTypes = ref([])

let payers = ref([])
const getPayers = function () {
    axios
        .get(route('api.payers.index'))
        .then((response) => {
            payers.value = response.data.data
        })
}

let regions = ref([])
const getRegions = function () {
    axios
        .get(route('api.regions.index'))
        .then((response) => {
            regions.value = response.data
        })
}

const preparingDownload = ref(false)
const download = function () {
    preparingDownload.value = true
    axios({
        url: route('api.data-exchange.download', {
            date_from: dateFrom.value.value,
            date_until: dateUntil.value.value,
            funding_types: selectedFundingTypes.value.map((type) => type.uuid),
            payers: selectedPayers.value.map((payer) => payer.uuid),
            regions: selectedRegions.value.map((region) => region.uuid),
        }),
            method: 'GET',
            responseType: 'blob',
        })
        .then((response) => {
            let file = window.URL.createObjectURL(new Blob([response.data]));
            let docUrl = document.createElement('a');
            docUrl.href = file;
            docUrl.setAttribute('download', 'data-exchange-report-'+ DateTime.now().toISODate() +'.xml');
            document.body.appendChild(docUrl);
            docUrl.click();
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
                error.response.data.text().then((text) => {
                    const errorResponse = JSON.parse(text);
                    const errors = errorResponse.errors;
                    Object.values(errors).forEach((error) => {
                        toast.error(error[0]);
                    });
                });
            } else {
                toast.error('We found an issue with the requested data and cannot process your request. Please contact the helpdesk.')
            }
        })
        .finally(() => {
            preparingDownload.value = false
        })
}

onMounted(() => {
    getPayers()
    getRegions()
})

</script>
