<template>
    <tr>
        <td class="col-3 table-user">
            <div class="d-flex align-items-center">
                <UserAvatar :user="user" size="extra-small" class="me-2"></UserAvatar>
                <div>
                    <div><a :href="this.route('users.show', {user: user.uuid})">{{ user.name }}</a></div>
                </div>
            </div>
        </td>
        <td class="col-3 align-middle">{{ user.email }}</td>
        <td class="col-3 align-middle">{{ user.role.name }}</td>
        <td class="col-1 align-middle">
            <div class="d-flex align-items-center">
                <span v-if="status === 'requires_verification'" class="badge bg-warning">Email Not Verified</span>
                <span v-if="status === 'two_factor_disabled'" class="badge bg-danger">2FA Not Enabled</span>
                <span v-if="status === 'active'" class="badge bg-success">Active</span>
            </div>
        </td>
        <td class="col-1 align-middle text-center">
            <div v-if="user.uuid !== currentUser.uuid">
                <ConfirmButton
                    :class-array="['action-icon', 'text-secondary']"
                    color="link"
                    :confirmation-title="'Remove ' + user.name + '?'"
                    :confirmation-text="'Are you sure you want to remove ' + user.name + ' from your organisation? They will no longer be able to access Trips.'"
                    @confirmed="deleteUser()"
                >
                    <i class="mdi mdi-delete"></i>
                </ConfirmButton>
            </div>
        </td>
    </tr>
</template>

<script>
import UserAvatar from "./UserAvatar.vue";
import { ConfirmButton } from '../'
import {mdiTrashCan} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
export default {
    name: "UserListRow",
    components: {
        UserAvatar,
        ConfirmButton,
        SvgIcon,
    },
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            removeUserButtonIcon: mdiTrashCan,
            currentUser: window.user,
        }
    },
    computed: {
        status: function () {
            if (!this.user.email_verified_at) {
                return 'requires_verification'
            }
            if (!this.user.has_two_factor_enabled) {
                return 'two_factor_disabled'
            }

            return 'active'
        }
    },
    methods: {
        deleteUser() {
            this.$http
                .delete(this.route('api.users.destroy', {
                    user: this.user.uuid,
                }))
                .then(() => {
                    this.$emit('userRemoved', this.user)
                })
        }
    }
}
</script>

<style scoped>

</style>
