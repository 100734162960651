<template>
    <div>
        <EditableInput
            :label="transaction.receipt_number"
            :value="transaction.receipt_number"
            :loading="loading"
            @updated="updateReceiptNumber"
        />
    </div>
</template>

<script setup>
import EditableInput from "@components/Forms/EditableFields/EditableInput.vue";
import {ref, computed, inject} from 'vue'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const axios = inject('axios')
const toast = inject('toast')
const emit = defineEmits(['updated'])
const loading = ref(false)
const updateReceiptNumber = (newValue) => {
    if (props.transaction.receipt_number === newValue) {
        return
    }

    loading.value = true

    axios
        .put(route('api.transactions.update', {transaction: props.transaction.uuid}), {
            receipt_number: newValue,
        })
        .then((response) => {
            props.transaction.receipt_number = newValue
            emit('updated', { transaction: props.transaction })
        })
        .catch((error) => {
            toast.error(error.response.data.message)
        })
        .finally(() => {
            loading.value = false
        })
}

</script>

<style scoped>

</style>
