<template>
    <EditableDate
        @updated="update"
        :disabled="!editable"
        :value="authorityCheckDate"
        :loading="updating"
    >
        <small>expires <ColorContextualDate :date="authorityCheckDate"></ColorContextualDate></small>
    </EditableDate>
    <FormValidationError :errors="errors" field="driver_authority_expiry_date"></FormValidationError>
</template>

<script setup>
import {computed, inject, ref} from "vue"
import ColorContextualDate from "@components/ColorContextualDate.vue"
import EditableDate from "@components/Forms/EditableFields/EditableDate.vue"
import { FormValidationError } from "@components/Forms"

const axios = inject('axios')

const props = defineProps({
    driver: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const authorityCheckDate = ref(props.driver.driver_authority_expiry_date)

const editable = computed(() => {
    return !props.readOnly
})

const emit = defineEmits(['updated'])

const updating = ref(false)
const errors = ref([])
const update = function (newDate) {
    updating.value = true
    axios
        .put(route('api.drivers.update', { driver: props.driver.uuid }), {
            driver_authority_expiry_date: newDate
        })
        .then((response) => {
            authorityCheckDate.value = response.data.driver_authority_expiry_date
            errors.value = []
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

</script>
