<template>
    <div class="row">
        <div class="col-sm-5">
            <label class="form-label" :for="componentId + '_subsidyAmount'">Amount per booking</label>
            <div class="input-group input-group-sm">
                <span class="input-group-text">$</span>
                <input
                    type="text"
                    v-model="subsidy.amount"
                    :id="componentId + '_copaymentAmount'"
                    placeholder="e.g. 55.00"
                    class="form-control"
                    aria-label="Amount"
                >
                <span class="input-group-text">AUD</span>
            </div>
        </div>
        <div class="col-sm-7">
            <label class="form-label" :for="componentId + '_subsidyPayer'">Payer</label>
            <select class="form-select form-select-sm" v-model="subsidy.payer" :id="componentId + '_subsidyPayer'">
                <option>-- Select Payer --</option>
                <template v-for="type in fundingTypes">
                    <option :value="type.uuid">{{ type.name }}</option>
                </template>
            </select>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import {uuid} from 'vue-uuid';

const componentId = ref(uuid.v4());
const props = defineProps({
    modelValue: {
        required: true,
        type: Object,
    },
    fundingTypes: {
        type: Array,
        default: [],
    },
})

const subsidy = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

</script>
