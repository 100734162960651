<template>
    <div
        class="card"
        @mouseover="showEditButton = !client.deactivated"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="header-title my-0 mb-3">Funding Details</h4>
                <div v-if="!client.deactivated && !readOnly">
                    <div v-if="showEditButton && !showEditForm">
                        <Button @click="showEditFundingDetailsModal" color="link" size="small">
                            <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                            Edit
                        </Button>
                    </div>
                </div>
            </div>

            <dl class="mb-0">
                <template v-for="reference in client.funding_type_references">
                    <dt>{{ reference.funding_type.code }} number:</dt>
                    <dd>{{ reference.reference_number }}</dd>
                </template>
                <template v-if="client.funding_type">
                    <dt class="col-sm-4">Default Funding Type:</dt>
                    <dd class="col-sm-8">{{ client.funding_type.name }} ({{ client.funding_type.code }})</dd>
                </template>
                <template v-if="client.payer">
                    <dt class="col-sm-4">Default Payer:</dt>
                    <dd class="col-sm-8">{{ client.payer.name }} ({{ client.payer.payer_code }})</dd>
                </template>
                <template v-if="client.approved_funding_types.length">
                    <dt>Approved Funding Types:</dt>
                    <dd>
                        <ul class="list-unstyled mb-0">
                            <template v-for="fundingType in client.approved_funding_types">
                                <li>{{ fundingType.name }} ({{ fundingType.code }})</li>
                            </template>
                        </ul>
                    </dd>
                </template>
                <p v-else>There are no approved funding types saved.</p>
            </dl>
            <template v-if="client.custom_field_values.length > 0">
                <hr>
                <h5>Additional Reporting Information</h5>
                <dl class="mt-3 mb-0">
                    <template v-for="field in client.custom_field_values">
                        <dt>{{ field.funding_type_custom_field.name }}</dt>
                        <dd>
                            <template v-if="field.funding_type_custom_field.type === 'boolean' && field.value === '1'">
                                Yes
                            </template>
                            <template v-else-if="field.funding_type_custom_field.type === 'boolean' && field.value === '0'">
                                No
                            </template>
                            <template v-else>
                                {{ $filters.title_case(field.value) }}
                            </template>
                        </dd>
                    </template>
                </dl>
            </template>

            <div
                class="modal fade"
                id="editFundingDetailsModal"
                tabindex="-1"
                aria-labelledby="editFundingDetailsModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editFundingDetailsModalLabel">Edit Funding Details</h5>
                            <button type="button" class="btn-close" @click="cancelChanges" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label for="fundingType" class="form-label">Approved funding types</label>
                            <ApprovedFundingTypes
                                :funding-types="fundingTypes"
                                :payers="payers"
                                :approved-funding-types="client.approved_funding_types_form_data"
                                @updated="updateSelectedFundingTypes"
                            />
                            <div class="mb-3">
                                <label class="form-label">Funding Type Reference Numbers</label>
                                <FundingReferenceNumbers
                                    :funding-types="fundingTypes"
                                    v-model="fundingTypeReferences"
                                    :approved-funding-types="approvedFundingTypes"
                                    @update:modelValue="updateFundingTypeReferences"
                                ></FundingReferenceNumbers>
                            </div>
                            <template v-if="approvedFundingTypes.length">
                                <template v-for="selected in approvedFundingTypes">
                                    <template v-if="selected.is_default && selected.funding_type?.custom_fields.length > 0">
                                        <hr>
                                        <template v-for="field in selected.funding_type.custom_fields">
                                            <div class="mt-3">
                                                <label :for="field.slug" :class="{ 'form-label': true, 'required-label': field.is_required }">
                                                    {{ field.label }}
                                                </label>
                                                <template v-if="field.type === 'boolean'">
                                                    <div class="d-flex">
                                                        <div class="form-check">
                                                            <input
                                                                v-model="customFields[field.slug]"
                                                                value="1"
                                                                class="form-check-input"
                                                                type="radio"
                                                                :name="field.slug"
                                                                :id="field.slug + '_yes'"
                                                            >
                                                            <label class="form-check-label" :for="field.slug + '_yes'">Yes</label>
                                                        </div>
                                                        <div class="ms-2 form-check">
                                                            <input
                                                                v-model="customFields[field.slug]"
                                                                value="0"
                                                                class="form-check-input"
                                                                type="radio"
                                                                :name="field.slug"
                                                                :id="field.slug + '_no'"
                                                            >
                                                            <label class="form-check-label" :for="field.slug + '_no'">No</label>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="field.type === 'select'">
                                                    <select v-model="customFields[field.slug]" :required="field.is_required" :name="field.slug" :id="field.slug" class="form-select">
                                                        <template v-for="(label, value) in field.options">
                                                            <option :value="value">{{ label }}</option>
                                                        </template>
                                                    </select>
                                                </template>
                                                <template v-if="field.type === 'varchar'">
                                                    <input type="text" class="form-control" :required="field.is_required" :id="field.slug" v-model="customFields[field.slug]" :placeholder="field.label">
                                                </template>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </div>
                        <div class="modal-footer">
                            <Button :disabled="updating" color="light" @click="cancelChanges">Cancel</Button>
                            <Button :loading="updating" @click="update">Save Details</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditClientFundingDetails"
}
</script>
<script setup>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPencil } from "@mdi/js";
import {ref, onBeforeMount, onMounted, computed, watch, inject, nextTick} from "vue";
import Button from "../Button.vue";
import VueMultiselect from "vue-multiselect";
import FundingReferenceNumbers from "./FundingReferenceNumbers.vue";
import ApprovedFundingTypes from "./ApprovedFundingTypes.vue";
import {uuid as uuidv4} from "vue-uuid";

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})
const emit = defineEmits(['updated'])

const showEditButton = ref(false)
const showEditForm = ref(false)

const approvedFundingTypes = ref([])
const fundingTypeReferences = ref([])
const customFields = ref({})

const initialiseData = function () {
    approvedFundingTypes.value = props.client.approved_funding_types_form_data
    fundingTypeReferences.value = props.client.funding_type_references.map((reference) => {
        return {
            uuid: uuidv4.v4(),
            funding_type: reference.funding_type,
            reference_number: reference.reference_number,
        }
    })
    customFields.value = props.client.custom_field_values.reduce((acc, value) => {
        acc[value.funding_type_custom_field.slug] = value.value
        return acc
    }, {})
}

const cancelChanges = function () {
    setTimeout(() => {
        showEditForm.value = false
    }, 200)
    editModal.value.hide()
    initialiseData()
}

const updating = ref(false)
const update = function () {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            approved_funding_types: getClientApprovedFundingTypes(),
            funding_type_references: getClientFundingReferences(),
            ...customFields.value
    })
        .then((response) => {
            setTimeout(() => {
                showEditForm.value = false
            }, 200)
            editModal.value.hide()
            emit('updated', response.data)
        }).catch(error => {
            if (error.response.status === 422) {
                toast.warning(error.response.data.message, { title: 'Validation Error' })
            } else {
                toast.error('There was a problem updating the client\'s funding details', { title: 'System Error' })
            }
        })
        .finally(() => {
            updating.value = false
        })
}

let fundingTypes = ref([])
const getFundingTypes = function () {
    axios
        .get(route('api.funding-types.index'))
        .then((response) => {
            fundingTypes.value = response.data
        })
}

let payers = ref([])
const getPayers = function () {
    axios
        .get(route('api.payers.index'))
        .then((response) => {
            payers.value = response.data.data
        })
}

const getClientApprovedFundingTypes = () => {
    return approvedFundingTypes.value.filter(i => i.funding_type).map((type) => {
        return {
            funding_type: type.funding_type?.uuid,
            default_payer: type.default_payer?.uuid,
            is_default: type.is_default,
        };
    })
}

const getClientFundingReferences = () => {
    let references = {}
    if (fundingTypeReferences.value.length > 0) {
        fundingTypeReferences.value.forEach((item) => {
            references[item.funding_type.uuid] = item.reference_number
        })
    }

    return references
}

const updateFundingTypeReferences = (references) => {
    fundingTypeReferences.value = references.value
}

const updateSelectedFundingTypes = (selectedTypes) => {
    approvedFundingTypes.value = selectedTypes
}

const showEditFundingDetailsModal = (() => {
    showEditForm.value = true
    editModal.value.show()
})

const editModal = ref(null)
onMounted(() => {
    editModal.value = new bootstrap.Modal(document.getElementById("editFundingDetailsModal"))
})

onBeforeMount(function () {
    getFundingTypes()
    getPayers()
    initialiseData()
})
</script>

<style scoped>

</style>
