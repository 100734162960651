import { defineStore } from "pinia";
import { computed, ref } from "vue";
import AssistanceType from "../classes/AssistanceType";
import { BookingPurposesInterface } from "../classes/BookingPurpose";
import CargoSpace, {
    CargoSpaceFormDetailsInterface,
} from "../classes/CargoSpace";
import { ClientInterface } from "../classes/Client";
import { FareCollectionMethodsInterface } from "../classes/FareCollectionMethod";
import { LocationInterface } from "../classes/Location";
import TransportRequirement, {
    TransportRequirementOptionsInterface,
} from "../classes/TransportRequirement";
import VehicleConfigurationOption from "../classes/VehicleConfigurationOption";
import _ from "lodash";
import { DateTime } from "luxon";
import Optimiser, {
    OptimizerEstimatedTimingResponse,
    OptimizerEstimatedTimingsRequestOptions,
} from "../classes/Optimiser";
import { BookingInterface } from "../classes/Booking";
import BookingFormEditHelper from "../classes/Helpers/BookingFormEditHelper";
import { BookingCalculatedFareInterface } from "@classes/Fare";
import BookingResource from "@customTypes/resources/BookingResource";
import { RecurringBookingOptions } from "../classes/RecursiveBooking";
import { cloneDeep } from "lodash";
import { useFundingTypeStore } from "./FundingTypeStore";
import { useFundingRuleStore } from "./FundingRuleStore";
import { usePayerStore } from "./PayerStore";
import { useRegionStore } from "./RegionStore";
import { useTreatmentTypeStore } from "./TreatmentTypeStore";
import { useVehicleTypeStore } from "./VehicleTypeStore";
import { useAgencyStore } from "./AgencyStore";
import { useBookingServiceTypeStore } from "./BookingServiceTypeStore";
import useWindow from "../hooks/useWindow";
import {BookingRequirementPayloadInterface} from "@stores/BookingRequirementStore";

export type ClientSpecificValue<T> = {
    client_uuid: string;
    value: T;
};

export type BookingStopScheduleType = "pick_up" | "drop_off";

export type BookingTransportType = "individual" | "group";

export interface BookingFormStopInterface {
    //Shared attributes between all clients in a stop
    originLocation: LocationInterface | null;
    usePreviousStopLocationAsOrigin: boolean;
    location: LocationInterface | null;
    useClientHomeAsLocation: boolean;
    time: DateTime | null;
    bookingServiceType: string | null;
    flexibilityBefore: number;
    flexibilityAfter: number;
    clientToConfirmTime: boolean;
    purpose: string | null;
    treatmentTypeUuid: string | null;
    scheduleType: BookingStopScheduleType;
    transportType: BookingTransportType;
    vehicleTypeCode: string | null;

    //Client specifc attributes
    directionResponses: ClientSpecificValue<google.maps.DirectionsResult>[];
    historicalEstimatedDistances: ClientSpecificValue<number | undefined>[];
    tags: ClientSpecificValue<App.Models.BookingTag[]>[];
    notes: ClientSpecificValue<string>[];
    client_notes: ClientSpecificValue<string>[];
    vehicleConfigurationOptions: ClientSpecificValue<
        App.Models.VehicleConfigurationOption[]
    >[];
    includeGST: ClientSpecificValue<boolean>[];
    fare: ClientSpecificValue<BookingCalculatedFareInterface>[];
    manualFee: ClientSpecificValue<boolean>[];
    manualFeeAmount: ClientSpecificValue<number>[];
    appointmentTime: ClientSpecificValue<DateTime | null>[];
    priority: ClientSpecificValue<string>[];
    referenceNumber: ClientSpecificValue<string>[];
    additionalTimeNeeded: ClientSpecificValue<number>[];
    maximumTransitTime: ClientSpecificValue<number>[];
    seatsNeeded: ClientSpecificValue<number>[];
    bookingRequirements: ClientSpecificValue<BookingRequirementPayloadInterface[]>[];
    estimatedTimings: ClientSpecificValue<OptimizerEstimatedTimingResponse | null>[];
    uuid: ClientSpecificValue<string>[];
}

export interface DraftBookingRequestInterface {
    clients: ClientInterface[];
    selectedClientUuid: string | null;
    selectedBookingServiceTypeUuid: string | null;
    errors: any[];
    date: string;
    reschedule: boolean;
    rescheduleOptions: RecurringBookingOptions | null;
    stops: BookingFormStopInterface[];
    useClientFunding: ClientSpecificValue<boolean>[];
    payerUuid: ClientSpecificValue<string>[];
    regionUuid: ClientSpecificValue<string>[];
    fundingTypeUuid: ClientSpecificValue<string>[];
    fareCollectionMethod: ClientSpecificValue<string>[];
    agencyUuid: ClientSpecificValue<string | null>[];
    agencyContactUuid: ClientSpecificValue<string | null>[];
    companionUuids: ClientSpecificValue<string[]>[];
}

export interface StorableOptimizerEstimatedTimingsRequestOptions {
    client_uuid: string;
    stopIndex: number;
    options: OptimizerEstimatedTimingsRequestOptions;
}

export const getDefaultStop = (): BookingFormStopInterface => {
    return {
        originLocation: null,
        usePreviousStopLocationAsOrigin: true,
        location: null,
        useClientHomeAsLocation: true,
        directionResponses: [],
        historicalEstimatedDistances: [],
        purpose: null,
        treatmentTypeUuid: null,
        scheduleType: "drop_off",
        bookingServiceType: null,
        transportType: "group",
        vehicleTypeCode: null,
        time: null,
        flexibilityBefore: 0,
        flexibilityAfter: 0,
        clientToConfirmTime: false,
        tags: [],
        notes: [],
        client_notes: [],
        vehicleConfigurationOptions: [],
        includeGST: [],
        fare: [],
        manualFee: [],
        manualFeeAmount: [],
        appointmentTime: [],
        priority: [],
        referenceNumber: [],
        additionalTimeNeeded: [],
        maximumTransitTime: [],
        seatsNeeded: [],
        bookingRequirements: [],
        estimatedTimings: [],
        uuid: [],
    };
};

export const useBookingStore = defineStore("booking", () => {
    const fundingTypeStore = useFundingTypeStore();
    const fundingRuleStore = useFundingRuleStore();
    const payerStore = usePayerStore();
    const regionStore = useRegionStore();
    const treatmentTypeStore = useTreatmentTypeStore();
    const agencyStore = useAgencyStore();
    const vehicleTypeStore = useVehicleTypeStore();
    const bookingServiceTypeStore = useBookingServiceTypeStore();

    const windowRef = useWindow();

    const draft = ref<DraftBookingRequestInterface>({
        clients: [],
        selectedClientUuid: null,
        selectedBookingServiceTypeUuid: null,
        errors: [],
        date: DateTime.now().toFormat("yyyy-MM-dd"),
        reschedule: false,
        rescheduleOptions: null,
        stops: [
            getDefaultStop(),
            { ...getDefaultStop(), ...{ useClientHomeAsLocation: false } },
        ],
        useClientFunding: [],
        payerUuid: [],
        regionUuid: [],
        fundingTypeUuid: [],
        fareCollectionMethod: [],
        agencyUuid: [],
        agencyContactUuid: [],
        companionUuids: [],
    });

    const bookingEdit = ref<undefined | BookingInterface>();

    const vehicleConfigurationOptions = ref<
        App.Models.VehicleConfigurationOption[]
    >([]);

    const fundingTypes = ref<App.Models.FundingType[]>([]);

    const fundingRules = ref<App.Models.FundingRule[]>([]);

    const bookingPurposes = ref<BookingPurposesInterface>({});

    const fareCollectionMethods = ref<FareCollectionMethodsInterface>({});

    const treatmentTypes = ref<App.Models.TreatmentType[]>([]);

    const payers = ref<App.Models.Payer[]>([]);

    const agencies = ref<App.Models.Agency[]>([]);

    const regions = ref<App.Models.Region[]>([]);

    const bookingServiceTypes = ref<App.Models.BookingServiceType[]>([]);

    const bookingFormErrors = ref<{ [key: string]: string }>({});

    function addClientToDraftBooking(client: ClientInterface) {
        if (!draft.value.clients.find((i) => i.uuid == client.uuid)) {
            draft.value.clients.push(client);
            draft.value.selectedClientUuid = client.uuid;
            setClientDefaultBookingServiceType(client);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        }
    }

    function removeClientFromDraftBooking(client: ClientInterface) {
        draft.value.clients = draft.value.clients.filter(
            (i) => i.uuid !== client.uuid
        );
    }

    function setClientDefaultBookingServiceType(client: ClientInterface) {
        draft.value.stops?.forEach((stop) => {
            stop.bookingServiceType = client.booking_service_type?.uuid ?? null;
        });
    }

    function addStop(stopIndexToBeInsertedAfter: number) {
        let toAppendBasedOnCurrentSelections: Partial<BookingFormStopInterface> =
            {
                transportType:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.transportType
                    ) ?? "individual",
                purpose:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]?.purpose
                    ) ?? null,
                treatmentTypeUuid:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.treatmentTypeUuid
                    ) ?? null,
                bookingRequirements:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.bookingRequirements
                    ) ?? [],
                priority:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.priority
                    ) ?? [],
                notes:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]?.notes
                    ) ?? [],
                client_notes:
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.client_notes
                    ) ?? [],
                bookingServiceType:
                    //Cary over the booking service type from the first stop, or the client's default booking service type
                    cloneDeep(
                        draft.value.stops?.[stopIndexToBeInsertedAfter]
                            ?.bookingServiceType
                    ) ??
                    cloneDeep(
                        selectedClient.value?.booking_service_type?.uuid
                    ) ??
                    null,
            };

        let newStop = {
            ...getDefaultStop(),
            ...toAppendBasedOnCurrentSelections,
        };

        draft.value.stops.splice(stopIndexToBeInsertedAfter + 1, 0, newStop);

        setTimeout(() => {
            let newStopIndex = stopIndexToBeInsertedAfter + 1;
            let newStopElement = document.getElementById(
                "stop-index-" + newStopIndex
            );
            let newElementDistanceToTop =
                //pageYOffset actually seems to be wrongly marked as derecated, but it's not
                window.pageYOffset +
                (newStopElement?.getBoundingClientRect()?.top ?? 0);
            window.scrollTo(0, newElementDistanceToTop);
        }, 200);
    }

    function removeStopByIndex(stopIndex: number) {
        draft.value.stops = draft.value.stops.filter(
            (stop, index) => index !== stopIndex
        );
    }

    function updateBookingRequirementByIndex(stopIndex: number, bookingRequirements: {}) {
        let newDraft = draft.value;
        const currentStop = draft.value.stops[stopIndex];
        const clientBookingRequirementForStopIndex = currentStop.bookingRequirements.findIndex(br => br.client_uuid === bookingRequirements.client_uuid);

        if (clientBookingRequirementForStopIndex >= 0) {
            draft.value.stops[stopIndex].bookingRequirements[clientBookingRequirementForStopIndex].value = bookingRequirements;
            return;
        }

        currentStop.bookingRequirements.push({
            client_uuid: bookingRequirements.client_uuid,
            value: bookingRequirements
        });

        newDraft.stops[stopIndex] = currentStop;
        draft.value = newDraft;
    }

    const selectedClients = computed(() => {
        const selectedClientUuid = draft.value.selectedClientUuid;

        return draft.value.clients.filter(
            (client) => !selectedClientUuid || client.uuid == selectedClientUuid
        );
    });

    const hasDateChanged = computed(() => {
        if (!bookingEdit.value){
            return false;
        }
        return DateTime.fromISO(bookingEdit.value?.date)?.toFormat("yyyy-MM-dd") !== draft.value.date;
    });

    const selectedClient = computed(() => {
        const selectedClientUuid = draft.value.selectedClientUuid;

        return draft.value.clients.find(
            (client) => client.uuid && client.uuid == selectedClientUuid
        );
    });

    const loadVehicleConfigurationOptions = async () => {
        vehicleConfigurationOptions.value =
            await VehicleConfigurationOption.getAll();
    };

    const loadFundingTypes = async () => {
        fundingTypes.value = await fundingTypeStore.sync();
    };

    const loadFundingRules = async () => {
        fundingRules.value = await fundingRuleStore.sync();
    };

    const loadBookingPurposes = () => {
        bookingPurposes.value = windowRef.value?.enums.bookingPurpose;
    };

    const loadFareCollectionMethods = async () => {
        fareCollectionMethods.value =
            windowRef.value?.enums.fareCollectionMethod;
    };

    const loadTreatmentTypes = async () => {
        treatmentTypes.value = await treatmentTypeStore.sync();
    };

    const loadPayers = async () => {
        payers.value = await payerStore.sync();
    };

    const loadRegions = async () => {
        regions.value = await regionStore.sync();
    };

    const loadAgencies = async () => {
        agencies.value = await agencyStore.sync();
    };

    const loadVehicleTypes = async () => {
        vehicleTypes.value = await vehicleTypeStore.sync();
    };

    const loadBookingServiceTypes = async () => {
        bookingServiceTypes.value = await bookingServiceTypeStore.sync();
    };

    const loadAndSaveEstimatedTimingsForStorableEstimatedTimingRequestOptions =
        async (
            storabeOptions: StorableOptimizerEstimatedTimingsRequestOptions
        ) => {
            let estimatedTimings = await Optimiser.estimateTimings(
                storabeOptions.options
            );

            appendToStopByIndex(storabeOptions.stopIndex, {
                estimatedTimings: appendToClientSpecificValues(
                    draft.value.stops[storabeOptions.stopIndex]
                        .estimatedTimings,
                    storabeOptions.client_uuid,
                    estimatedTimings
                ),
            });
        };

    function appendToStopByIndex(
        stopIndex: number,
        append: Partial<BookingFormStopInterface>
    ) {
        const newStops = [...draft.value.stops];

        newStops[stopIndex] = { ...newStops[stopIndex], ...append };

        draft.value.stops = newStops;
    }

    function appendToClientSpecificValues<T>(
        original: ClientSpecificValue<T>[],
        client_uuid: string,
        value: T
    ): ClientSpecificValue<T>[] {
        let result: ClientSpecificValue<T>[] = original;
        const clientChild = result.find((i) => i.client_uuid == client_uuid);

        const newChild = {
            client_uuid,
            value,
        };

        if (clientChild) {
            result[result.indexOf(clientChild)] = newChild;
        } else {
            result.push(newChild);
        }

        return original;
    }

    //Helper to make calculated values on the go for a stop value by index
    const getComputedForStopValue = <K>(
        stopIndex: number,
        valueKey: string,
        defaultValue: any = undefined
    ) => {
        return computed<K>({
            get<K>() {
                return draft.value.stops[stopIndex][valueKey] ?? defaultValue;
            },
            set(newValue) {
                if (typeof newValue !== "undefined") {
                    appendToStopByIndex(stopIndex, {
                        [valueKey]: newValue,
                    });
                }
            },
        });
    };

    const getComputedForClientSpecificValue = <T>(
        valueKey: string,
        defaultValue: any = undefined
    ) => {
        return computed<T>({
            get<T>() {
                const client_uuid = draft.value.selectedClientUuid;
                return (
                    draft.value[valueKey]?.find(
                        (i) => i.client_uuid == client_uuid
                    )?.value ?? defaultValue
                );
            },
            set(newValue) {
                const client_uuid = draft.value.selectedClientUuid;
                if (client_uuid && typeof newValue !== "undefined") {
                    draft.value[valueKey] = appendToClientSpecificValues(
                        draft.value[valueKey],
                        client_uuid,
                        newValue
                    );
                }
            },
        });
    };

    //Some stop values are different per client, this function allows you to get a calculate value for the selected client on the go
    const getComputedForStopClientSpecificValue = <T>(
        stopIndex: number,
        valueKey: string,
        defaultValue: any = undefined
    ) => {
        return computed<T>({
            get<T>() {
                const client_uuid = draft.value.selectedClientUuid;
                return (
                    draft.value.stops[stopIndex][valueKey]?.find(
                        (i) => i.client_uuid == client_uuid
                    )?.value ?? defaultValue
                );
            },
            set(newValue) {
                const client_uuid = draft.value.selectedClientUuid;
                if (client_uuid && typeof newValue !== "undefined") {
                    appendToStopByIndex(stopIndex, {
                        [valueKey]: appendToClientSpecificValues(
                            draft.value.stops[stopIndex][valueKey],
                            client_uuid,
                            newValue
                        ),
                    });
                }
            },
        });
    };

    const populateBookingFormFromBookingEdit = async (
        booking: BookingResource | undefined
    ) => {
        bookingEdit.value = booking ?? undefined;

        if (!booking) {
            return true;
        }

        let bookingsInGroup = await BookingFormEditHelper.getBookingsInGroup(
            booking
        );

        let clients =
            await BookingFormEditHelper.getClientDetailsFromArrayOfBookings(
                bookingsInGroup
            );

        if (bookingsInGroup.length == 0 || clients.length == 0) {
            return true;
        }

        const firstBooking = bookingsInGroup[0];

        //Populate the basic level information
        draft.value.clients = clients;
        //Stop the page from scrolling down
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        draft.value.selectedClientUuid = clients[0]?.uuid;
        draft.value.selectedBookingServiceTypeUuid =
            firstBooking.service_type?.uuid ?? null;

        draft.value.date =
            DateTime.fromISO(firstBooking.date)?.toFormat("yyyy-MM-dd");

        //Populate client specific (but not stop/client specific, meaning they are shared between all stops values)
        draft.value = {
            ...draft.value,
            ...(await BookingFormEditHelper.getClientSpecificValues(
                bookingsInGroup,
                clients,
                fareCollectionMethods.value
            )),
        };

        draft.value.stops = await BookingFormEditHelper.convertBookingsToStops(
            bookingsInGroup,
            bookingPurposes.value
        );

        draft.value.reschedule = booking.reschedule_identifier ? true : false;
        draft.value.rescheduleOptions =
            booking.recurrance?.recursion_rule ?? null;

        return true;
    };

    return {
        draft,
        bookingEdit,
        bookingFormErrors,
        vehicleConfigurationOptions,
        fundingTypes,
        fundingRules,
        bookingPurposes,
        bookingServiceTypes,
        fareCollectionMethods,
        treatmentTypes,
        payers,
        agencies,
        regions,
        hasDateChanged,
        removeStopByIndex,
        addStop,
        addClientToDraftBooking,
        removeClientFromDraftBooking,
        appendToStopByIndex,
        appendToClientSpecificValues,
        selectedClient,
        selectedClients,
        loadVehicleConfigurationOptions,
        loadFundingTypes,
        loadRegions,
        loadFundingRules,
        loadBookingPurposes,
        loadFareCollectionMethods,
        loadPayers,
        loadTreatmentTypes,
        loadAgencies,
        loadAndSaveEstimatedTimingsForStorableEstimatedTimingRequestOptions,
        loadBookingServiceTypes,
        getComputedForStopClientSpecificValue,
        getComputedForClientSpecificValue,
        getComputedForStopValue,
        populateBookingFormFromBookingEdit,
        updateBookingRequirementByIndex
    };
});
