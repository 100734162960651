import axios, {AxiosResponse} from "axios";
import {ApiResourceResponseInterface} from "../types/LaravelCommonTypes";
import InvoiceResource, {InvoiceItemResource} from "@customTypes/resources/InvoiceResource";

export enum InvoiceStatus {
    "Draft" = 'draft',
    "Unpaid" = 'unpaid',
    "Paid" = 'paid',
    "Cancelled" = 'cancelled',
    "Approved" = 'approved',
    "Void" = 'void',
}

export enum BookingPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

export default class Invoice {
    static index = async (data: object): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.get<ApiResourceResponseInterface<InvoiceResource[]>>(
            route('api.invoices.index', data)
        );

    static getItems = async (invoice: InvoiceResource, data: object): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.get<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.items.index`, {invoice: invoice.uuid, ...data})
        );

    static save = async (invoice: InvoiceResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.patch<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.update`, {invoice: invoice.uuid}), {...invoice}
        );

    static approve = async (invoice: InvoiceResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.approve`, {invoice: invoice.uuid}),
            {status: InvoiceStatus.Approved}
        );

    static bulkApprove = async (invoices: InvoiceResource[]): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.approve.bulk`, {}),
            {invoices: invoices.map((invoice: InvoiceResource) => invoice.uuid), status: InvoiceStatus.Approved}
        );

    static void = async (invoice: InvoiceResource, reason: string): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.void`, {invoice: invoice.uuid}),
            {status: InvoiceStatus.Void, reason: reason}
        );

    static sync = async (invoice: InvoiceResource): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.post<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`xero.invoices.sync`, {invoice: invoice.uuid})
        );

    static deleteItems = async (invoice: InvoiceResource, selectedItems: InvoiceItemResource[]): Promise<AxiosResponse<ApiResourceResponseInterface<any>>> =>
        await axios.delete<ApiResourceResponseInterface<InvoiceResource[]>>(
            route(`api.invoices.batchDelete`, {invoice: invoice.uuid}),
            {
                data: {
                    items: selectedItems.map((item: InvoiceItemResource) => {
                        return {uuid: item.uuid, booking_id: item.booking?.uuid}
                    })
                }
            }
        );
}
