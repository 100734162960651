<template>
    <Modal title="Create New Transaction" v-model:show="show" :loading="loading">
        <template v-slot:body>
            <div class="mb-3" v-if="props.client">
                <label>Client</label>
                <div class="py-2 px-2 mb-3 mt-2 rounded border d-flex align-items-center">
                    <ClientAvatar :client="props.client" size="large" class="me-3"></ClientAvatar>
                    <div>
                        <div>{{ props.client.name }}</div>
                        <small>
                            {{ props.client.uuid }}
                        </small>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="booking">
                <label>Booking</label>
                <div class="py-2 px-2 mb-3 mt-2 rounded border">
                    <small>
                        {{ booking.uuid }}
                    </small>
                    <div>
                        <strong>O:</strong> {{ booking.origin?.fullAddress }}
                    </div>
                    <div>
                        <strong>D:</strong> {{ booking.destination?.fullAddress }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <FormDollarInput
                    v-model:input="newTransaction.amount"
                    label="Amount"
                    placeholder="e.g. 15.30"
                    step=".01"
                    :validation="
                        yup.number()
                            .required('Please enter a number amount for this transaction.')
                            .typeError('Please enter a number amount for this transaction.')
                    "
                />
            </div>
            <div class="mb-3">
                <FormTextarea
                    v-if="showComment"
                    v-model:input="newTransaction.comments"
                    label="Adjustment Description"
                    placeholder="What is the reason for this adjustment"
                    :validation="
                        yup.string()
                        .required('Please enter a description for the transaction')"
                />
            </div>
            <div class="mb-3">
                <FormSelect
                    v-model:selection="newTransaction.payment_method"
                    v-if="paymentMethods"
                    :options="paymentMethods"
                    label="Payment method"
                    placeholder="-- Select Payment Method --"
                />
            </div>
            <div class="mb-3">
                <FormSelect
                    v-model:selection="newTransaction.collection_method"
                    v-if="collectionMethods"
                    :options="collectionMethods"
                    label="Collection method"
                    placeholder="-- Select Collection Method --"
                />
            </div>
            <div class="mb-3">
                <FormTextInput
                    v-model:input="newTransaction.receipt_number"
                    label="Receipt number"
                    placeholder="e.g. 123456"
                />
            </div>
        </template>
        <template v-slot:footer>
            <Button
                type="button"
                class="btn btn-secondary"
                :disabled="loading"
                @click="close"
            >
                Close
            </Button>
            <Button
                type="button"
                class="btn btn-primary"
                :loading="loading"
                @click="storeTransaction"
            >
                Save changes
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
    import {inject, reactive, ref, computed, watch} from "vue";
    import ClientAvatar from "@components/Clients/ClientAvatar.vue";
    import { FormSelect, FormDollarInput, FormTextInput, FormTextarea } from "@components/Forms";
    import { Button, Modal } from "@components";
    import * as yup from "yup";
    import { Axios } from "axios";
    import BookingResource from "@customTypes/resources/BookingResource";
    import { reduce } from "lodash"

    const axios = inject<Axios>('axios');
    const toast = inject<any>('toast');

    const props = withDefaults(
        defineProps<{
            booking?: BookingResource,
            client: App.Models.Client,
            open: boolean,
            paymentMethods: object[],
            collectionMethods: object[],
        }>(), 
    {});

    const loading = ref<boolean>(false);
    const showComment = ref<boolean>(false);

    const newTransaction = reactive({
        amount: <App.Forms.FormDollarInput>{
            value: 0,
            required: true,
            valid: false,
            errors: [],
        },
        payment_method: <App.Forms.FormSelect>{
            value: 'cash',
            required: true,
            valid: true,
            errors: [],
        },
        collection_method: <App.Forms.FormSelect>{
            value: 'payment_to_driver',
            required: true,
            valid: true,
            errors: [],
        },
        receipt_number: <App.Forms.FormTextInput>{
            value: '',
            required: false,
            valid: true,
            errors: [],
        },
        comments: <App.Forms.FormTextArea>{
            value: '',
            required: false,
            valid: true,
            errors: [],
        }
    })

    const emit = defineEmits<{
        (e:'stored', value: boolean): void,
        (e:'close', value: boolean): void,
    }>();

    const show = computed<boolean>({
        get() {
            return props.open;
        },
        set(value) {
            emit('close', value);
        }
    });

    const close = (): void => {
        show.value = false;
    }

    const storeTransaction = (): void => {
        loading.value = true

        const valid: boolean = reduce(newTransaction, (result: boolean, item: object, key: string) => {
            if (!item.valid) {
                result = false
                newTransaction[key].errors.push('This field is required');
            }
            return result;
        }, true);

        if (!valid) {
            toast.error('Please ensure that all fields have been completed correctly');
            loading.value = false;
            return;
        }

        axios.post(
                route('api.clients.transactions.store', {client: props.client.uuid}),
                {
                    booking: props.booking?.uuid,
                    amount: (newTransaction.amount.value * 100),
                    payment_method: newTransaction.payment_method.value,
                    collection_method: newTransaction.collection_method.value,
                    receipt_number: newTransaction.receipt_number.value,
                    comments: newTransaction.comments.value,
                }
            )
            .then(response => {
                emit('stored', response.data.data)
                toast.success('Transaction Created Successfully');
                show.value = false;
            })
            .catch((error) => {
                if (error.response.data.errors) {
                    console.error(error);
                    Object.keys(error.response.data.errors).forEach((errorKey) => {
                        newTransaction[errorKey].value.errors = error.response.data.errors[errorKey]
                    })
                    toast.error('Please fix the errors in the form');
                }
                toast.error('An Error has occured, Please Try again or report the error');
            })
            .finally(() => {
                loading.value = false
            })
    }

    watch(
        () => newTransaction.amount.value,
        (value: number) => {
            if (value < 0) {
                showComment.value = true;
                newTransaction.comments.required = true;
                
                if (!newTransaction.comments.value?.length) { 
                    newTransaction.comments.valid = false;
                }

                return;
            }

            showComment.value = false;
            newTransaction.comments.required = false;

            if (!newTransaction.comments.value?.length) { 
                newTransaction.comments.valid = true;
            }
        }
    )
</script>