<template>
    <div class="d-inline-block">
        <EditableInput
            :label="time"
            :loading="updating"
            :value="toDateTimeInput(journeyBreak[field])"
            type="datetime-local"
            @updated="updateBreakTime"
        />
    </div>
</template>

<script setup>
import {inject, computed, onBeforeMount, ref} from 'vue'
import EditableInput from "../../Forms/EditableFields/EditableInput.vue";
import Spinner from "../../Spinner.vue";
import {DateTime} from 'luxon'
import {toDateTimeInput, toTimeStamp, formatDate} from '../../Utils'

const toast = inject('toast')
const axios =inject("axios")

const props = defineProps({
    journey: {
        type: Object,
        required: true,
    },
    journeyBreak: {
        type: Object,
        required: true,
    },
    field: {
        type: String,
        required: true,
    },
})

const time = computed(() => {
    return formatDate(props.journeyBreak[props.field], 'short');
})

const emit = defineEmits(['confirmDelete', 'updated'])

const updating = ref(false)
const updateBreakTime = function (newValue) {
    updating.value = true
    axios
        .put(route('api.journeys.breaks.update', {
            journey: props.journey.uuid,
            break: props.journeyBreak.id
        }), {
            start_time: props.field === 'start_time'
                ? toTimeStamp(newValue)
                : toTimeStamp(props.journeyBreak.start_time),
            end_time: props.field === 'end_time'
                ? toTimeStamp(newValue)
                : toTimeStamp(props.journeyBreak.end_time),
        })
        .then((response) => {
            emit('updated', response.data)
            toast.success('Break updated for run.')
        })
        .catch((error) => {
            if (error.response.status === 422) {
                toast.warning(error.response.data.message)
                return
            }
            toast.error('There was an error updating the break time for this run.')
        })
        .finally(() => {
            updating.value = false
        })
}

</script>

<style scoped>

</style>
