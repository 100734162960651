<template>
    <div
        class="card"
        @mouseover="showEditButton = true"
        @mouseleave="showEditButton = false"
    >
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start">
                <h4 class="header-title my-0 mb-3">Abilities</h4>
                <div v-if="showEditButton && !showEditForm">
                    <Button @click="showEditForm = true" color="link" size="small">
                        <SvgIcon :path="mdiPencil" class="w-4 h-4 me-1" type="mdi"></SvgIcon>
                    </Button>
                </div>
            </div>
            <template v-if="!showEditForm">
                <AbilityDisplay :details="driver.abilities" :columns="1" />
            </template>
            <template v-if="showEditForm">
                <div class="row ps-2 mb-3">
                    <AbilityForm
                        :details="driverValues"
                        :options="options"
                        :columns="1"
                        @updated="updateAbilitites"
                    />
                </div>
                <div class="d-flex align-items-center justify-content-end">
                    <Button size="small" :loading="updating" @click="update">Save</Button>
                    <Button :class-array="['ms-1']" :disabled="updating" size="small" color="light" @click="cancelChanges">Cancel</Button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, onBeforeMount, watch } from 'vue'
    import { AbilityForm, AbilityDisplay } from '../AccessRequirements'
    import { copyValues, transformInput } from '../Utils'
    import SvgIcon from "@jamescoyle/vue-icon";
    import { mdiPencil } from '@mdi/js'
    import { Button } from '../'

    const axios = inject('axios')

    const { driver, options } = defineProps({
        driver: {
            required: true,
            type: Object,
        },
        options: {
            required: true,
            type: Array,
        }
    })

    const emit = defineEmits(['updated'])

    const showEditButton = ref(false)
    const showEditForm = ref(false)
    const driverValues = ref({})
    const updating = ref(false)

    const cancelChanges = function () {
        showEditForm.value = false
        initialiseData()
    }

    const update = function () {
        updating.value = true

        const data = Object.entries(driverValues.value)?.map(([key, item]) => {
            return {
                code: item.code,
                doc: item?.doc?.value,
                doc_name: item.doc_name.value,
                expiry: item.expiry.value,
                source: item.source.value,
            }
        })

        axios.put(route('api.drivers.update', {driver: driver.uuid}), {
                abilities: data
            })
            .then((response) => {
                showEditForm.value = false
                emit('updated', response.data)
                driver.abilities = response.data.abilities
                initialiseData()
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                updating.value = false
            })
    }

    const initialiseData = function () {
        let options = copyValues(driver.abilities)
        driverValues.value = options?.map((option) => {
            return {
                code: option.code,
                name: option.name,
                description: option.description,
                doc: transformInput({
                    value: option?.doc
                }),
                doc_name: transformInput({
                    value: option?.doc_name
                }),
                source: transformInput({
                    value: option?.source,
                    required: false,
                    valid: true,
                }),
                expiry: transformInput({
                    value: option?.expiry ? moment(option?.expiry).format("yyyy-MM-DD") : null,
                    required: false,
                    valid: true,
                }),
            }
        })
    }

    onBeforeMount(function () {
        initialiseData()
    })

    const updateAbilitites = (value) => {
        driverValues.value = value
    }

    watch(driver.abilities, () => {
        initialiseData()
    });

</script>
