<template>
    <div
        class="d-flex flex-row align-items-center flex-row-reverse"
        :style="{ marginBottom: spaceMargin + 'px' }"
    >
        <VehicleDiagramSpace v-for="space in row?.spaces" :space="space" />
    </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import {
    useVehicleConfigurationDesignStore,
    VehicleConfigurationRowInterface,
} from "@stores/VehicleConfigurationDesignStore";
import VehicleDiagramSpace from "./VehicleDiagramSpace.vue";
import { computed, toRef, toRefs, watch } from "vue";

const vehicleConfigurationDesignStore = useVehicleConfigurationDesignStore();

const { rows, unitSize, spaceMargin } = storeToRefs(
    vehicleConfigurationDesignStore
);

const props = withDefaults(
    defineProps<{
        rowIndex: number;
    }>(),
    {}
);

const { rowIndex } = toRefs(props);

const row = computed<VehicleConfigurationRowInterface | undefined>(() => {
    return rows.value?.[rowIndex.value];
});

watch(
    () => row.value,
    (row) => {
        console.log("row", row);
    }
);
</script>
