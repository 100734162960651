<template>
    <EditableSelect
        @updated="update"
        :disabled="!editable"
        :value="client.gender"
        :options="genderOptions"
        :loading="updating"
    >
        {{ client.gender_description }}
    </EditableSelect>
</template>

<script>
export default {
    name: "EditClientGender"
}
</script>
<script setup>
import axios from "axios";
import {computed, ref} from "vue";
import EditableSelect from "../Forms/EditableFields/EditableSelect.vue";

const props = defineProps({
    client: Object,
    readOnly: {
        type: Boolean,
        default: false
    }
})

const editable = computed(() => {
    return !props.readOnly && !props.client.deactivated
})
const emit = defineEmits(['updated'])

const genderOptions = ref({
    not_stated: 'Not Stated',
    male: 'Male',
    female: 'Female',
    intersex: 'Intersex',
})

const updating = ref(false)
const update = function (newGender) {
    updating.value = true
    axios
        .put(route('api.clients.update', { client: props.client.uuid }), {
            gender: newGender
        })
        .then((response) => {
            emit('updated', response.data)
        })
        .catch((error) => {
            errors.value = error.response.data.errors
        })
        .finally(() => {
            updating.value = false
        })
}

</script>
