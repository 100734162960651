<template>
    <a v-tooltip:left="'Toggle dark mode'" href="javascript: void(0);" @click="toggleDarkMode">
        <i class="dripicons dripicons-lightbulb"></i>
    </a>
</template>

<script>
export default {
    name: "DarkModeToggle",
    props: {
        updateRoute: String,
        token: String,
        darkMode: Boolean,
    },
    methods: {
        toggleDarkMode: function () {
            this.$http
                .put(this.updateRoute, {
                    '_token': this.token,
                    'dark_mode': this.darkMode,
                }).then(function () {
                    window.location.reload(false);
                });
        }
    }
}
</script>
