<template>
    <span v-if="booking.priority === 'High'" v-tooltip="'High Priority'">
        <SvgIcon
            type="mdi"
            :path="mdiChevronDoubleUp"
            :size="iconSize"
            class="me-1 text-danger"
        ></SvgIcon>
    </span>
    <span v-if="booking.priority === 'Low'" v-tooltip="'Low Priority'">
        <SvgIcon
            type="mdi"
            :path="mdiChevronDoubleDown"
            :size="iconSize"
            class="me-1 text-info"
        ></SvgIcon>
    </span>
    <span
        v-for="alertMessage in warningAlertMessages ?? []"
        v-tooltip="alertMessage"
    >
        <SvgIcon
            type="mdi"
            :path="mdiAlert"
            :size="iconSize"
            class="me-1 text-warning"
        ></SvgIcon>
    </span>

    <span
        v-for="alertMessage in dangerAlertMessages ?? []"
        v-tooltip="alertMessage"
        data-container="body"
    >
        <SvgIcon
            type="mdi"
            :path="mdiAlert"
            :size="iconSize"
            class="me-1 text-danger"
        ></SvgIcon>
    </span>

    <span
        v-if="booking.reschedule_identifier"
        v-tooltip="'This booking is part of a recurrance'"
    >
        <SvgIcon
            type="mdi"
            :path="mdiRepeatVariant"
            :size="iconSize"
            class="me-1 text-info"
        ></SvgIcon>
    </span>

    <BookingRequirementsIcon :booking="booking" />

    <span
        v-if="
            booking.chosen_seat === 'non_transfer_manual_wheelchair' ||
            booking.chosen_seat === 'ewheelchair'
        "
        v-tooltip="'Stay in Wheelchair'"
    >
        <SvgIcon
            type="mdi"
            :path="mdiWheelchair"
            :size="iconSize"
            class="me-1 text-danger"
        ></SvgIcon>
    </span>

    <span
        v-if="booking.appointment_time"
        v-tooltip="
            'Appointment Time: ' +
            currentBookingDatetime?.appointmentTime()?.toFormat('HH:mm')
        "
    >
        <SvgIcon
            type="mdi"
            :size="iconSize"
            :path="mdiClockAlertOutline"
            class="me-1 text-warning"
        ></SvgIcon>
    </span>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
    mdiChevronDoubleUp,
    mdiChevronDoubleDown,
    mdiRepeatVariant,
    mdiOpenInNew,
    mdiClockAlertOutline,
    mdiAlert,
    mdiDotsVertical,
    mdiWheelchair,
    mdiCar,
    mdiCarSeat,
    mdiAsteriskCircleOutline,
} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import BookingDatetime from "@classes/DateHelpers/BookingDatetime";
import BookingResource from "@customTypes/resources/BookingResource";
import BookingRequirementsIcon from "@components/Bookings/BookingRequirementsIcon.vue";

const props = withDefaults(
    defineProps<{
        booking: BookingResource;
        dangerAlertMessages?: string[];
        warningAlertMessages?: string[];
    }>(),
    {
        dangerAlertMessages: undefined,
        warningAlertMessages: undefined,
    }
);

const frontSeatGroups = ref([]);
const frontSeatOnly = computed(() => {
    if (props.booking.chosen_seat === 'non_transfer_manual_wheelchair' ||
        props.booking.chosen_seat === 'ewheelchair') {
        return false;
    }

    if (props.booking.vehicle_types.length === 0) {
        return false;
    }

    for (var i = 0; i < props.booking.vehicle_types.length; i ++) {
        const vt = props.booking.vehicle_types[i];
        if (!vt.chosen_seat_positions) {
            return false;
        }

        if (vt.chosen_seat_positions.includes('back_seat')) {
            return false;
        }

        if (vt.chosen_seat_positions.includes('anchored_seat')) {
            return false;
        }

        if (!vt.chosen_seat_positions.includes('front_seat')) {
            return false;
        }

        // add vehicle groups
        if (!frontSeatGroups.value.includes(vt.group_name)) {
            frontSeatGroups.value.push(vt.group_name);
        }
    }

    return true;
});

const currentBookingDatetime = computed(() => {
    return props.booking ? new BookingDatetime(props.booking) : null;
});

const iconSize = ref(20);
</script>
