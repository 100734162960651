import { DateTime } from "luxon";

export type DateInputType = "datetime-local" | "time" | 'date_med' | 'date_short';

export default class DatetimeHelper {
    static convertToLuxon(isoString: string | null | undefined) {
        //TODO this function should in the future handle all timezone conversions
        return isoString ? DateTime.fromISO(isoString) : null;
    }

    static getDatetimeFormatForInputType(inputType?: DateInputType) {
        switch (inputType) {
            case "datetime-local":
                return "yyyy-MM-dd'T'HH:mm";
            case "time":
                return "HH:mm";
            case "date_med":
                return "dd LLL yyyy";
            case "date_short":
                return "dd/MM/yyyy";
            default:
                return "yyyy-MM-dd'T'HH:mm";
        }
    }

    static toDateTimeInput(dateTime: DateTime, inputType: DateInputType) {
        return dateTime.toFormat(this.getDatetimeFormatForInputType(inputType));
    }

    static toLocaleDateTime(isoString: string | null | undefined) {
        if (typeof isoString !== 'string') {
            return '';
        }

        return DateTime.fromISO(isoString).toFormat('dd/MM/yyyy HH:mm');
    }

    static toLocalDate(isoString: string | null | undefined, inputType?: DateInputType) {
        if (typeof isoString !== 'string') {
            return '';
        }

        return DateTime.fromISO(isoString).toFormat(this.getDatetimeFormatForInputType(inputType ?? 'date_short'));
    }
}
