<template>
    <div class="card">
        <div class="card-body">
            <div v-if="loading" class="d-flex w-100 justify-content-center align-items-center ps-3">
                <div class="spinner-grow my-5 me-3" role="status"></div>
                <div class="fs-4">Loading Cancellation Reasons</div>
            </div>
            <template v-if="!loading">
                <div class="mb-2">
                    <CreateBookingCancellationReason @created="addCancellationReason"/>
                </div>
                <table class="table table-sm table-centered table-striped">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th class="text-center">Fee Percentage</th>
                        <th class="text-center">Fixed Fee Amount</th>
                        <th class="text-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="reason in cancellationReasons">
                        <tr>
                            <td>{{ reason.name }}</td>
                            <td class="text-center">
                                {{ reason.fee_percentage ? reason.fee_percentage + '%' : '-' }}
                            </td>
                            <td class="text-center">
                                {{
                                    reason.fee_amount_without_tax ? $filters.currency(reason.fee_amount_without_tax) : '-'
                                }}
                            </td>
                            <td class="text-end">
                                <div class="dropdown">
                                    <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="mdi mdi-dots-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <button
                                            @click="editReason(reason)"
                                            class="dropdown-item"
                                        >
                                            Edit cancellation reason
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </template>
        </div>

        <div id="editCancellationReasonModal" aria-hidden="true" aria-labelledby="editCancellationReasonModalLabel"
             class="modal fade" data-bs-backdrop="static"
             data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="createCancellationReasonModalLabel" class="modal-title">Edit Cancellation Reason</h5>
                        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                    </div>
                    <div class="modal-body">
                        <BookingCancellationReasonEditOrCreateForm v-model="cancellationReasonInEdit"/>
                    </div>
                    <div class="modal-footer d-flex justify-content-between flex-nowrap">
                        <div class="flex-grow-1 me-1">
                            <DeleteBookingCancellationReason
                                v-if="cancellationReasonInEdit.name"
                                :key="cancellationReasonInEdit.uuid"
                                :reason="cancellationReasonInEdit"
                                @deleted="removeReason"
                            />
                        </div>
                        <div class="flex-shrink-1 d-flex flex-nowrap">
                            <button class="btn btn-secondary me-1" data-bs-dismiss="modal" type="button">Close</button>
                            <Button :loading="updating" @click="updateCancellationReason">Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ListBookingCancellationReasons"
}
</script>
<script setup>
import {onMounted, inject, ref, computed} from "vue";
import axios from "axios";
import Button from "../Button.vue";
import CreateBookingCancellationReason from "./CreateBookingCancellationReason.vue";
import BookingCancellationReasonEditOrCreateForm from "./BookingCancellationReasonEditOrCreateForm.vue";
import DeleteBookingCancellationReason from "./DeleteBookingCancellationReason.vue";
import { useBookingCancellationReasonStore } from "@stores/BookingCancellationReasonStore";
import {uuid} from "vue-uuid";

const bookingCancellationReasonStore = useBookingCancellationReasonStore();

const toast = inject('toast')

const cancellationReasons = ref([])
const loading = ref(true)
const getCancellationReasons = async () => {
    loading.value = true;
    cancellationReasons.value = await bookingCancellationReasonStore.sync(true);
    loading.value = false
}

const addCancellationReason = function (reason) {
    cancellationReasons.value.push(reason)
}

const cancellationReasonEditModal = ref(null)
const cancellationReasonInEdit = ref({})
const editReason = function (reason) {
    cancellationReasonInEdit.value = {
        uuid: reason.uuid,
        name: {
            uuid: uuid.v4(),
            value: reason.name,
            errors: [],
            required: true,
        },
        fee_percentage: {
            uuid: uuid.v4(),
            value: reason.fee_percentage,
            errors: [],
            required: false,
        },
        fee_amount_without_tax: {
            uuid: uuid.v4(),
            value: reason.fee_amount_without_tax,
            errors: [],
            required: false,
        },
        add_gst: {
            value: reason.add_gst,
        },
    }
    cancellationReasonEditModal.value.show()
}

const updating = ref(false)
const updateCancellationReason = function () {
    updating.value = true
    axios
        .put(route('api.booking-cancellation-reasons.update', {booking_cancellation_reason: cancellationReasonInEdit.value.uuid}), {
            name: cancellationReasonInEdit.value.name.value,
            fee_percentage: cancellationReasonInEdit.value.fee_percentage.value ?? 0,
            fee_amount_without_tax: cancellationReasonInEdit.value.fee_amount_without_tax.value * 100,
            add_gst: cancellationReasonInEdit.value.add_gst.value ? 1 : 0,
        })
        .then(response => {
            const index = cancellationReasons.value.findIndex(reason => reason.uuid === response.data.uuid)
            cancellationReasons.value.splice(index, 1, response.data)
            toast.success('Cancellation reason updated')
            cancellationReasonEditModal.value.hide()
        })
        .catch(error => {
            for (const [field, errors] of Object.entries(error.response.data.errors)) {
                cancellationReasons.value[field].errors = errors
            }
        })
        .finally(() => {
            updating.value = false
        })
}

const removeReason = function (reason) {
    const index = cancellationReasons.value.findIndex(cancellationReason => cancellationReason.uuid === reason.uuid)
    cancellationReasons.value.splice(index, 1)
    cancellationReasonEditModal.value.hide()
}

onMounted(() => {
    getCancellationReasons()
    cancellationReasonEditModal.value = new bootstrap.Modal(document.getElementById('editCancellationReasonModal'))
})

</script>

<style scoped>

</style>
