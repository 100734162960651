import axios from "axios";
import FundingTypeResource from "@/resources/js/types/resources/FundingTypeResource";
export default class FundingType {
    static index() {
        return axios.get<FundingTypeResource[]>(
            route("api.funding-types.index")
        );
    }

    static update(fundingType: FundingTypeResource, parameters: any) {
        return axios.patch(
            route("api.funding-types.update", {
                funding_type: fundingType?.uuid,
            }),
            parameters
        );
    }
}
