<template>
<div class="card-body">
    <div class="d-flex justify-content-between align-items-start">
        <h4 class="header-title my-0 mb-3">Referrals</h4>
    </div>
    <div class="row ps-2 mb-3">
        <ReferralsForm
            :clientReferrals="clientReferrals"
            :availableReferrals="availableReferrals"
            :columns="1"
            @updated="updateReferral"
        />
    </div>
    <div class="d-flex align-items-center justify-content-end">
        <Button size="small" :loading="updating" @click="update" :disabled="updating || !isDirty">Save</Button>
        <Button :class-array="['ms-1']" :disabled="updating || !isDirty" size="small" color="light" @click="cancelChanges">Cancel</Button>
    </div>
</div>
</template>

<script setup>
import {inject, nextTick, onMounted, ref} from "vue";
import Button from "../Button.vue";
import ReferralsForm from "@components/Referrals/ReferralsForm.vue";
import {cloneDeep} from "lodash";

const axios = inject('axios')

    const { client, options } = defineProps({
        client: {
            required: true,
            type: Object,
        },
        availableReferrals: {
            required: true,
            type: Array
        }
    })
    const emit = defineEmits(['updated'])

    const clientReferrals = ref([])

    const updating = ref(false);
    const isDirty = ref(false);

    const update = () => {
        updating.value = true;

        axios.put(route('api.clients.update', {client: client.uuid}), {
                referrals: clientReferrals.value
            })
            .then((response) => {
                client.referrals = response.data.data.referrals;
                cancelChanges();
                emit('updated', response.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                updating.value = false;
                isDirty.value = false;
            })
    }

    const updateReferral = (value) => {
        clientReferrals.value = value;
        isDirty.value = true;
    }

    const cancelChanges = () => {
        clientReferrals.value = cloneDeep(client.referrals);
        nextTick(() => { isDirty.value = false });
    }

    onMounted(() => {
        clientReferrals.value = cloneDeep(client.referrals);
    })
</script>

<style scoped>

</style>
