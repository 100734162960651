<template>
    <div class="row">
        <div class="col-md-4">
            <label for="purpose_select" class="form-label">Purpose</label>
            <select
                class="form-select"
                v-model="bookingPurpose"
                id="purpose_select"
            >
                <option :value="null">-- Select Purpose --</option>
                <option
                    v-for="purposeKey in Object.keys(bookingPurposes)"
                    :value="purposeKey"
                >
                    {{ bookingPurposes[purposeKey] }}
                </option>
            </select>
        </div>
        <div class="col-md-4">
            <label for="treatment_type_select" class="form-label"
                >Treatment</label
            >
            <select
                class="form-select"
                v-model="treatmentTypeUuid"
                id="treatment_type_select"
            >
                <option :value="null">-- Select Treatment --</option>
                <option
                    v-for="treatmentType in treatmentTypes"
                    :value="treatmentType.uuid"
                >
                    {{ treatmentType.code }} ({{ treatmentType.description }})
                </option>
            </select>
        </div>
        <div class="col-md-4">
            <label for="transport_type_select" class="form-label"
                >Transport Type</label
            >
            <select
                class="form-select"
                v-model="transportType"
                id="transport_type_select"
            >
                <option value="individual">Individual</option>
                <option value="group">Group</option>
            </select>
        </div>
    </div>
</template>
<script setup lang="ts">
import { BookingTransportType, useBookingStore } from "@stores/BookingStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";

const props = defineProps<{
    stopIndex: number;
}>();

const bookingStore = useBookingStore();

const { bookingPurposes, treatmentTypes } = storeToRefs(bookingStore);

const bookingPurpose = bookingStore.getComputedForStopValue<null | string>(
    props.stopIndex,
    "purpose",
    null
);

const treatmentTypeUuid = bookingStore.getComputedForStopValue<null | string>(
    props.stopIndex,
    "treatmentTypeUuid",
    null
);

const transportType =
    bookingStore.getComputedForStopValue<BookingTransportType>(
        props.stopIndex,
        "transportType",
        "individual"
    );

const setInitialValues = () => {
    // if (!treatmentTypeUuid.value && treatmentTypes.value?.[0]) {
    //     treatmentTypeUuid.value = treatmentTypes.value?.[0].uuid;
    // }
    // if (!bookingPurpose.value && Object.keys(bookingPurposes.value)?.[0]) {
    //     bookingPurpose.value = Object.keys(bookingPurposes.value)?.[0];
    // }
};

//Set the initial value for purpose and treatment
watch([bookingPurposes, treatmentTypes, treatmentTypeUuid], () => {
    setInitialValues();
});

onMounted(() => {
    setInitialValues();
});
</script>
