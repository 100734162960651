<template>
    <div v-if="driverLoaded">
        <div v-if="showReactivateLink" class="alert alert-warning d-flex justify-content-between align-items-center">
            <div>This driver was deactivated on {{ driverDeletedDate }}.</div>
            <ReactivateDriverLink :driver="driver" @restored="updateDriverStatus"></ReactivateDriverLink>
        </div>
        <DriverProfileRecordCheck
            :is-compliant="driverIsCompliant"
            :show-modal="showDriverComplianceModal"
            :driver="driver"
            :driver-regulation-check-enabled="driverRegulationCheckEnabled"
            @modal-hidden="showDriverComplianceModal = false"
        ></DriverProfileRecordCheck>
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-1 mx-2">
                        <div class="me-2 flex-shrink-1 d-none d-md-block">
                            <DriverAvatar :driver="driver" size="full"></DriverAvatar>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <h4>{{ driver.name }}
                            <template v-if="driver.username !== ''">({{ driver.username }})</template>
                        </h4>
                        <p v-if="driver.address">{{ driver.address.formattedAddress }}</p>
                        <div class="d-flex mt-3">
                            <div class="me-4">
                                <strong class="fs-5">Northern Suburbs</strong>
                                <div>Region</div>
                            </div>
                            <div class="me-4">
                                <strong class="fs-5">{{ moment(driver.created_at).toNow(true) }}</strong>
                                <div>Time in role</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end d-flex flex-column justify-content-between" style="min-height: 125px">
                        <div>
                           <!-- <button class="btn btn-light"><i class="mdi mdi-pencil me-1"></i>Edit Driver</button> -->
                        </div>
                        <div>
                            <!-- any flags or pill badges you want to show about the driver member -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-start">
                            <h4 class="header-title mt-0 mb-3">Driver Information</h4>
                            <div v-if="permissions.includes('update_driver') && showDeactivateLink" class="dropdown">
                                <button href="#" class="dropdown-toggle arrow-none card-drop p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="mdi mdi-dots-vertical"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a
                                        class="dropdown-item"
                                        :href="route('drivers.edit', driver.uuid)"
                                    >
                                        Edit Driver
                                    </a>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        @click.prevent="showDriverComplianceModal = true"
                                    >
                                        Update Driver Compliance
                                    </a>
                                    <a
                                        class="dropdown-item text-danger"
                                        href="javascript:void(0);"
                                        @click.prevent="deactivateDriverConfirmationModal.show()"
                                    >
                                        Deactivate Driver
                                    </a>
                                </div>
                            </div>
                        </div>
                        <DeactivateDriverModal
                            :driver="driver"
                            @deactivated="updateDriverStatus"
                        ></DeactivateDriverModal>
                        <dl class="row mb-0">
                            <dt class="col-sm-4">Driver ID:</dt>
                            <dd class="col-sm-8">#{{ driver.uuid }}</dd>
                            <dt class="col-sm-4">Full Name:</dt>
                            <dd class="col-sm-8">{{ driver.name }}</dd>
                            <dt class="col-sm-4">Username:</dt>
                            <dd class="col-sm-8">{{ driver.username }}</dd>
                            <dt class="col-sm-4">Phone:</dt>
                            <dd class="col-sm-8"><a :href="$filters.phone_url(driver.phone)">{{ $filters.phone(driver.phone) }}</a></dd>
                            <dt class="col-sm-4">Email:</dt>
                            <dd class="col-sm-8"><a :href="'mailto:'+ driver.email">{{ driver.email }}</a>
                            </dd>
                            <dt class="col-sm-4">Default Type:</dt>
                            <dd class="col-sm-8">{{ driver.type.description }}</dd>
                            <dt class="col-sm-4">Joined Date:</dt>
                            <dd class="col-sm-8">{{ moment(driver.joined_date).format('D MMMM YYYY') }}
                                ({{ moment(driver.joined_date).toNow(true) }})
                            </dd>
                            <dt class="col-sm-4">Gender:</dt>
                            <dd class="col-sm-8">{{ _.startCase(driver.gender) }}</dd>
                        </dl>
                        <hr>
                    </div>
                </div>
                <DriverProfileCertificates :driver="driver"/>
                <DriverProfileBackground :driver="driver"/>
                <EmergencyContacts
                    :model="driver"
                    model-name="driver"
                    :emergency-contacts="emergencyContacts"
                ></EmergencyContacts>
                <DriverAppAccess :driver="driver" :driverIsPasswordSet="driverIsPasswordSet">

                </DriverAppAccess>
                <DriverAbilities :driver="driver" :options="abilities" @updated="updateDriver"/>
                <DriverDepot :options="depotList" :driver="driver"/>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <DriverAvailability :driver="driver"></DriverAvailability>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex justify-content-center">
        <Spinner></Spinner>
    </div>
</template>

<script>
export default {
    name: "DriverProfile"
}
</script>
<script setup>
import {ref, computed, onMounted, inject, nextTick, onBeforeMount, watch} from 'vue'
import DriverAvatar from "./DriverAvatar.vue";
import EmergencyContacts from "../Contacts/EmergencyContacts.vue";
import DriverAvailability from "./AvailabilityCalendar/DriverAvailability.vue";
import DriverProfileRecordCheck from "./DriverProfileRecordCheck.vue";
import DriverProfileCertificates from "./DriverProfileCertificates.vue";
import DriverProfileBackground from "./DriverProfileBackground.vue";
import DeactivateDriverModal from "./DeactivateDriverModal.vue";
import ReactivateDriverLink from "./ReactivateDriverLink.vue";
import {DateTime} from "luxon";
import Button from "../Button.vue";
import DriverAbilities from './DriverAbilities.vue';
import DriverAppAccess from './DriverAppAccess.vue';
import ElmHelper from "@classes/Helpers/ElmHelper";
import Spinner from "@components/Spinner.vue";
import DriverDepot from "@components/Drivers/DriverDepot.vue";
import { useDepotStore } from "@stores/DepotStore";
import _ from "lodash";
import {storeToRefs} from "pinia";
const axios = inject('axios');
const toast = inject('toast');

const kitIssued = ref([])

const permissions = window.permissions;
const driverLoaded = ref(false);
const depotStore = useDepotStore();

const { depotList } = storeToRefs(depotStore);

const props = defineProps({
    driver: Object,
    driverIsCompliant: Boolean,
    driverIsPasswordSet: Boolean,
    driverRegulationCheckEnabled: Boolean,
    abilities: {
        required: true,
        type: Array,
    }
});

const driver = ref(props.driver);

const emergencyContacts = computed(() => {
    if (driver.value.contacts.length === 0) {
        return []
    }

    return driver.value.contacts.filter((contact) => contact.type === 'emergency')
})

let driverStatus = ref(driver.value.deleted_at ? 'deleted' : 'active')

const showDeactivateLink = computed(() => {
    return driverStatus.value === 'active' && permissions.includes('create_driver')
})

const showReactivateLink = computed(() => {
    return driverStatus.value === 'deleted' && permissions.includes('create_driver')
})

const updateDriverStatus = (status) => {
    deactivateDriverConfirmationModal.value.hide()
    driverStatus.value = status
}

const driverDeletedDate = computed(() => {
    if (driver.value.deleted_at === null) {
        return DateTime.now().toLocaleString(DateTime.DATE_MED)
    }

    return DateTime.fromISO(driver.value.deleted_at).toLocaleString(DateTime.DATE_MED)
})

const deactivateDriverConfirmationModal = ref(null)
const showDriverComplianceModal = ref(false)

onMounted(() => {
    ElmHelper.waitUntilRendered('#deactivateDriverConfirmationModal').then((elm) => {
        deactivateDriverConfirmationModal.value = new bootstrap.Modal(document.getElementById('deactivateDriverConfirmationModal'))
    });

    depotStore.getDepotList();
})

onBeforeMount(() => {
    getDriverDetailData();
});

const updateDriver = (value) => {
    driver.value = value;
}

const getDriverDetailData = () => {
    axios.get(route('api.driver.detail', { driver: driver.value.uuid})).then((resp) => {
        driver.value = resp.data.data;
        driverLoaded.value = true;
    }).catch((message) => {
        console.error(message)
        toast.error(message)
    })
}
</script>

<style scoped>

</style>
