<template>
    <div v-if="dataLoaded">
        <FormSection title="Personal Details" subtitle="Here you can specify the basic personal details of the driver.">
            <FormRow>
                <FormCol md="6">
                    <FormTextInput
                        v-model:input="driver.given_names"
                        @update:input="populateUsername"
                        label="Given Names"
                        placeholder="Given names"
                        :validation="yup.string().max(255).required()"
                    />
                </FormCol>
                <FormCol md="6">
                    <FormTextInput
                        v-model:input="driver.last_name"
                        @update:input="populateUsername"
                        label="Last Name"
                        placeholder="Last name"
                        :validation="yup.string().max(255).required()"
                    />
                </FormCol>
            </FormRow>

            <FormRow>
                <FormCol md="6">
                    <FormInputLabel
                        input-name="driverUsername"
                        label="Username"
                        required
                        class="me-1"
                    >
                    </FormInputLabel>
                    <Spinner v-if="loadingUsername" size="small" type="border"></Spinner>
                    <input
                        v-model="driver.username.value"
                        id="driverUsername"
                        class="form-control"
                        :class="{'is-invalid': errors['username']}"
                        type="text"
                        disabled
                    >
                    <FormValidationError :errors="errors" field="username"></FormValidationError>
                </FormCol>
                <FormCol md="6">
                    <FormTextInput
                        v-model:input="driver.email"
                        label="Email Address"
                        placeholder="driver-email@example.com"
                        type="email"
                        :validation="yup.string().email().max(255).required()"
                    />
                </FormCol>
            </FormRow>

        <FormRow>
            <FormCol xl="6" md="12">
                <FormInputLabel input-name="driverPhone" label="Phone number" required></FormInputLabel>
                <MazPhoneNumberInput
                    v-model="driver.phone.value"
                    :placeholder="'Example 0412 345 678'"
                    :preferred-countries="['AU']"
                    :success="phoneValidation?.isValid"
                    @update="phoneValidation = $event"
                    color="primary"
                    default-country-code="AU"
                    size="sm"
                    show-code-on-list
                />
                <FormValidationError :errors="errors" field="phone"></FormValidationError>
            </FormCol>
            <FormCol xl="4" md="12">
                <FormDateInput
                    v-model:input="driver.date_of_birth"
                    label="Date of birth"
                    :validation="yup.date().required()"
                />
                <div v-if="age" class="text-nowrap form-text">({{ age }})</div>
            </FormCol>
            <FormCol xl="2" md="12">
                <FormSelect
                    v-model:selection="driver.gender"
                    :options="genderOptions"
                    label="Gender"
                    placeholder="Select gender"
                />
            </FormCol>
        </FormRow>

        <FormRow class="mb-3">
            <AddressDbSearch
                :label="'Home address'"
                v-model="driver.address"
                required
                :invalid="errors['address'] !== undefined"
                @locationSelected="selectHomeLocation"
            />
            <FormValidationError :errors="errors" field="address"></FormValidationError>
        </FormRow>

        <FormRow>
            <FormCol md="6">
                <FormSelect
                    v-model:selection="driver.region"
                    :options="regions"
                    label="Region"
                    placeholder="Select a Region"
                />
            </FormCol>

            <FormCol md="6">
                <FormSelect
                    v-model:selection="driver.indigenous_status"
                    :options="indigenousStatuses"
                    label="Indigenous Status"
                    placeholder="Select an Indigenous Status"
                />
            </FormCol>
        </FormRow>

        <FormRow>
            <FormCol>
                <div class="form-check form-switch">
                    <input
                        v-model="driver.app_access.value"
                        id="driverAppAccess"
                        class="form-check-input"
                        type="checkbox"
                        @input="emitNonPrimaryContactToggle"
                        :value="true"
                    >
                    <label
                        class="form-check-label"
                        for="driverAppAccess"
                    >Has access to driver app</label>
                </div>
            </FormCol>
        </FormRow>
    </FormSection>

    <FormSection title="Background &amp; Medical" subtitle="Here you can specify the driver's current background and medical history.">
        <FormRow>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.police_check_expiry_date"
                    label="Police check expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.medical_expiry_date"
                    label="Medical expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.ndis_worker_expiry_date"
                    label="NDIS worker expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
        </FormRow>
    </FormSection>

    <FormSection title="Driver Licence" subtitle="Here you can record the driver's current licence status.">
        <FormRow>
            <FormCol xl="12">
                <FormInputLabel
                    input-name="driverUsername"
                    label="Select driver licence classes"
                    required
                    class="me-1"
                />
                <div :class="{ 'invalid' : errors['licence_classes']}">
                    <VueMultiselect
                        v-model="driver.licence_classes"
                        :options="licenceClasses"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="Select Licence Classes"
                        label="label"
                        track-by="value"
                        class="is-invalid"
                    ></VueMultiselect>
                </div>
                <FormValidationError :errors="errors" field="licence_classes"></FormValidationError>
            </FormCol>
        </FormRow>
        <FormRow>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.licence_expiry_date"
                    label="Licence expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.driver_authority_expiry_date"
                    label="Driver authority expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.demerit_check_date"
                    label="Demerit check expiry date"
                    :validation="yup.date().required()"
                />
            </FormCol>
        </FormRow>
    </FormSection>

    <FormSection v-if="mode === 'create'" title="Driver Compliance" subtitle="Here you can specify the driver's licence record check results.">
        <DriverRecordCheck
            :errors="errors"
            @updated="driverLicenceComplianceUpdated"
        ></DriverRecordCheck>
    </FormSection>

    <FormSection title="Employment Details" subtitle="Here you can specify the driver's employment details.">
        <FormRow>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.joined_date"
                    label="Date joined"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormSelect
                    v-model:selection="driver.employment_type"
                    :options="employmentTypes"
                    label="Employment type"
                    placeholder="Select an Employment Type"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormSelect
                    v-model:selection="driver.driver_type"
                    :options="driverTypes"
                    label="Driver type"
                    placeholder="Select a Driver Type"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.annual_driver_training_completed_date"
                    label="Latest Annual Training Date"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.covid_safe_plan_signed_date"
                    label="Latest Covid Safe Plan sign off"
                    :validation="yup.date().required()"
                />
            </FormCol>
            <FormCol xl="4" lg="4" md="12">
                <FormDateInput
                    v-model:input="driver.flu_vaccine_date"
                    label="Latest Flu Vaccine"
                    :validation="yup.date().required()"
                />
            </FormCol>
        </FormRow>

        <FormRow>
            <DriverTags @tagsUpdated="updateTags" :array-errors="arrayErrors" :saved-tags="driver.tags"></DriverTags>
        </FormRow>

        <FormRow>
            <FormCol md="10">
                <FormInputLabel input-name="driverLanguagesSpoken" label="Languages Spoken" required></FormInputLabel>
                <template v-if="languages">
                    <VueMultiselect
                        id="driverLanguagesSpoken"
                        v-model="driver.languages_spoken"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :multiple="true"
                        :options="languages"
                        label="name"
                        placeholder="Select Languages"
                        track-by="value"
                        class="is-invalid"
                    ></VueMultiselect>
                </template>
                <FormValidationError :errors="arrayErrors" field="languages_spoken"></FormValidationError>
            </FormCol>
        </FormRow>
    </FormSection>

    <FormSection title="Emergency Contacts" subtitle="Here you can specify the driver's emergency contacts.">
        <div :key="emergencyContactsRefreshKey">
            <DriverEmergencyContactForm
                v-for="(form, index) in driver.emergency_contacts"
                :key="index"
                :index="index"
                :form="form"
                :relationships="relationships"
                @delete="deleteEmergencyContact"
                @toggle-non-primary-contacts="toggleNonPrimaryContacts"
            ></DriverEmergencyContactForm>
        </div>
        <div class="row my-3">
            <div class="col-md-8 offset-md-4">
                <Button
                    :class-array="['float-end']"
                    @click="addEmergencyContact"
                    color="primary"
                >
                    Add Emergency Contact
                </Button>
            </div>
        </div>
    </FormSection>

    <FormSection title="Equipment Issued" subtitle="Here you can specify equiment the driver has been issued.">
        <template v-if="equipmentLoaded">
            <DriverEquipmentForm
                v-for="(form, index) in driver.equipment"
                :key="index"
                :index="index"
                :form="form"
                @delete="deleteEquipment"
            ></DriverEquipmentForm>
        </template>
        <div class="row my-3">
            <div class="col-md-8 offset-md-4">
                <Button
                    :class-array="['float-end']"
                    @click="addEquipment"
                    color="primary"
                >
                    Add Equipment
                </Button>
            </div>
        </div>
    </FormSection>

    <FormSection title="Driver Abilities" subtitle="Here you can specify the driver's abilities to support clients.">
        <AbilityForm :options="abilities" :details="driver.abilities" @updated="updateAbilities" />
    </FormSection>

    <hr />
    <div class="row">
        <div class="col-md-8 offset-md-4">
            <Button
                :class-array="['float-end']"
                :loading="loading"
                @click="saveDriver"
                color="primary"
            >{{ mode === 'create' ? 'Create' : 'Update' }} Driver</Button>
        </div>
    </div>
    </div>
</template>

<script setup>
import {ref, reactive, inject, computed, onMounted, nextTick, onBeforeMount} from 'vue'
import { onReady, transformInput, copyValues } from '../Utils'
import { DateTime, Interval } from "luxon";
import humanizeDuration from "humanize-duration";
import { FormTextInput, FormSelect, FormDateInput, FormValidationError } from '../Forms'
import { FormSection, FormRow, FormCol } from '../Forms/Layouts'
import FormInputLabel from '../Forms/parts/FormInputLabel.vue'
import Spinner from '../Spinner.vue'
import AddressDbSearch from "../AddressDbSearch.vue";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import VueMultiselect from 'vue-multiselect'
import DriverTags from './DriverTags.vue'
import DriverEmergencyContactForm from './DriverEmergencyContactForm.vue'
import DriverEquipmentForm from './DriverEquipmentForm.vue'
import DriverRecordCheck from './DriverRecordCheck.vue'
import { Button } from '../'
import { each, debounce } from 'lodash'
import * as yup from 'yup'
import { AbilityForm } from '../AccessRequirements'

const axios = inject('axios')
const toast = inject('toast')

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    mode: {
        type: String,
        default: 'create'
    },
    abilities: {
        type: Array,
        required: true,
    },
    licenceClasses: {
        type: Array,
        required: true,
    },
})

const driver = reactive(props.form)

/**
 * Driver Personal Details
 */
 const loadingUsername = ref(false)
const populateUsername = debounce(function () {
    if (props.mode === 'create') {
        loadingUsername.value = false
        if (driver.given_names.value.length && driver.last_name.value.length) {
            loadingUsername.value = true
            axios.post(route('api.drivers.username.generate'), {
                given_names: driver.given_names.value,
                last_name: driver.last_name.value,
            }).then(res => {
                loadingUsername.value = false
                driver.username.value = res.data.username;
            }).catch(err => {
                loadingUsername.value = false
                toast.error('There was an error generating a unique username.')
            })
        }
    }
}, 500)

const phoneValidation = ref(null)

const selectHomeLocation = function (location) {
    driver.address = location;
}

const age = computed(() => {
    if (!driver.date_of_birth.value) {
        return null
    }

    const formatted = Interval
        .fromDateTimes(DateTime.fromISO(driver.date_of_birth.value), DateTime.now())
        .toDuration()
        .valueOf()

    if (isNaN(formatted)) {
        return null
    }

    return humanizeDuration(formatted, { round: false, largest: 1 }) + ' old'
})

const updateTags = (tags) => {
    arrayErrors['tags'] = []
    driver.tags.value = tags
}

/**
 * Driver Licence Compliance
 */
 const driverLicenceComplianceUpdated = (record) => {
    driver.driver_record_check_date.value = record.driver_record_check_date.value
    driver.compliance = record.compliance
}

/**
 * Driver Emergency Contacts
 */
 const emergencyContactForm = () => ({
    name: transformInput({ value: '' }),
    address: null,
    full_address: transformInput({ value: '' }),
    role: transformInput({ value: '', required: false }),
    organisation: transformInput({ value: '', required: false }),
    work_phone: transformInput({ value: '' }),
    home_phone: transformInput({ value: '' }),
    mobile_phone: transformInput({ value: '' }),
    fax: transformInput({ value: '' }),
    relationship: transformInput({ value: '', required: false }),
    email: transformInput({ value: '', required: false }),
    is_primary: false,
})

const addEmergencyContact = () => {
    driver.emergency_contacts.push(emergencyContactForm())
}

let emergencyContactsRefreshKey = ref(Date.now());

const deleteEmergencyContact = (index) => {
    driver.emergency_contacts.splice(index, 1)
    emergencyContactsRefreshKey.value = Date.now()
}

const toggleNonPrimaryContacts = (index) => {
    driver.emergency_contacts.forEach((contact, i) => {
        if (i !== index) {
            contact.is_primary = false
        }
    })
}

/**
 * Driver Equipment
 */
const equipmentForm = () => ({
    description: transformInput({
        value: '',
        required: true,
    }),
    identifier: transformInput({
        value: '',
        required: false,
        valid: true,
        validation: yup.string().max(255)
    }),
    issued_date: transformInput({
        value: '',
        required: true,
        validation: yup.date().required()
    }),
})

const addEquipment = () => {
    driver.equipment.push(equipmentForm())
}

let equipmentLoaded = ref(true);

const deleteEquipment = (index) => {
    equipmentLoaded.value = false
    driver.equipment.splice(index, 1)
    nextTick(() => {
        equipmentLoaded.value = true
    })
}

/**
 * Persistence
 */
 let errors = ref([])

 const arrayErrors = reactive({
    languages_spoken: [],
    tags: [],
    equipment: [],
    emergency_contacts: [],
    abilities: [],
    licence_classes: []
})

const saveDriver = () => {
    clearErrors()
    loading.value = true

    if (props.mode === 'create') {
        axios
            .post(route('api.drivers.store', {}, true), getParams()).then(res => {
                window.location = res.data.redirect
            }).catch(err => {
                if (err.response && err.response.status === 422) {
                    setValidationErrors(err)
                } else {
                    toast.error('Failed to Create Driver')
                }
            }).finally(() => {
                loading.value = false
            })
    } else {
        axios
            .put(route('api.drivers.update', { driver: driver.uuid}), getParams()).then(res => {
                toast.success('Driver Updated')
                setTimeout(() => window.location = route('drivers.show', {driver: driver.uuid}), 2000)
            }).catch(err => {
                if (err.response && err.response.status === 422) {
                    setValidationErrors(err)
                } else {
                    toast.error('Failed to Update Driver')
                }
            }).finally(() => {
                loading.value = false
            })
    }
}

const setValidationErrors = (err) => {
    toast.warning('Please Correct Marked Driver Input Issues')

    errors.value = err.response.data.errors

    each(err.response.data.errors, (error, key) => {
        if(arrayErrors.hasOwnProperty(key)) {
            arrayErrors[key] = error
        } else if (driver.hasOwnProperty(key)) {
            driver[key].errors = error
        } else {
            setArrayErrors(key, error)
        }
    })
}

const setArrayErrors = (key, error) => {
    const [repeater, index, field] = key.split('.');

    if (driver[repeater] !== undefined && driver[repeater].length > 0) {
        driver[repeater][index][field].errors = formatArrayError(error)
    }
}

const formatArrayError = (message) => {
    const messageParts = message[0].split('.')
    const errorMessage = `${messageParts[0]} ${messageParts[2]}.`

    return [errorMessage.replaceAll('_', ' ')]
}

const clearErrors = () => {
    each(driver, (field) => {
        if (field && field.hasOwnProperty('errors')) {
            field.errors = []
        }
    })

    each(arrayErrors, (error, key) => {
        arrayErrors[key] = []
        for (const [repeater, value] of Object.entries(driver[key])) {
            each(value, (field) => {
                if (field && field.hasOwnProperty('errors')) {
                    field.errors = []
                }
            })
        }
    })
}

const getParams = () => {
    return {
        given_names: driver.given_names.value,
        last_name: driver.last_name.value,
        username: driver.username.value,
        email: driver.email.value,
        address: driver.address?.uuid,
        phone: driver.phone.value,
        date_of_birth: driver.date_of_birth.value,
        joined_date: driver.joined_date.value,
        region: driver.region.value,
        indigenous_status: driver.indigenous_status.value,
        employment_type: driver.employment_type.value,
        driver_type: driver.driver_type.value,
        compliance: driver.compliance,
        driver_record_check_date: driver.driver_record_check_date.value,
        app_access: driver.app_access.value,
        ndis_worker_expiry_date: driver.ndis_worker_expiry_date.value,
        demerit_check_date: driver.demerit_check_date.value,
        driver_authority_expiry_date: driver.driver_authority_expiry_date.value,
        licence_expiry_date: driver.licence_expiry_date.value,
        medical_expiry_date: driver.medical_expiry_date.value,
        police_check_expiry_date: driver.police_check_expiry_date.value,
        gender: driver.gender.value,
        annual_driver_training_completed_date: driver.annual_driver_training_completed_date.value,
        covid_safe_plan_signed_date: driver.covid_safe_plan_signed_date.value,
        flu_vaccine_date: driver.flu_vaccine_date.value,
        licence_classes: driver.licence_classes.map(licence => licence.value),
        tags: driver.tags.value == undefined || driver.tags.value.length === 0 ?
            [] :
            driver.tags.value.map(tag => {
                return { tag: tag }
            }),
        languages_spoken: driver.languages_spoken.map(language => language.value),
        emergency_contacts: driver.emergency_contacts.map(contact => {
            return {
                name: contact.name.value,
                address: contact.address?.uuid,
                mobile_phone: contact.mobile_phone.value,
                work_phone: contact.work_phone?.value,
                home_phone: contact.home_phone?.value,
                fax: contact.fax?.value,
                relationship: contact.relationship?.value,
                role: contact.role?.value,
                organisation: contact.organisation?.value,
                is_primary: contact.is_primary,
            }
        }),
        equipment: driver.equipment.map(equipment => {
            return {
                description: equipment.description.value,
                identifier: equipment.identifier.value,
                issued_date: equipment.issued_date.value,
            }
        }),
        abilities: driver.abilities.map((item) => {
            return {
                code: item.code,
                name: item.name,
                doc_name: item.doc_name.value,
                source: item.source?.value ?? null,
                expiry: item.expiry?.value ?? null
            }
        })
    };
}

/**
 * Setup
 */
const regions = ref([]);
const getRegions = function () {
    axios
        .get(route('api.regions.index'))
        .then((response) => {
            regions.value = response.data.map((region) => {
                return {
                    value: region.uuid,
                    label: region.name,
                };
            })
        })
}

const employmentTypes = ref([]);
const getEmploymentTypes = function () {
    axios
        .get(route('api.enum.employment-types'))
        .then((response) => {
            employmentTypes.value = response.data
        })
}

const driverTypes = ref([]);
const getDriverTypes = function () {
    axios
        .get(route('api.driver-types'))
        .then((response) => {
            driverTypes.value = response.data.map((driverType) => {
                return {
                    value: driverType.code,
                    label: driverType.description,
                };
            })
        })
}

const indigenousStatuses = ref([]);
const getIndigenousStatuses = function () {
    axios
        .get(route('api.enum.indigenous-statuses'))
        .then((response) => {
            indigenousStatuses.value = response.data
        })
}

const languages = ref([]);
const getLanguages = function () {
    axios
        .get(route('api.languages'))
        .then((response) => {
            for (const [key, value] of Object.entries(response.data)) {
                languages.value.push({
                    value: key,
                    name: value
                })
            }
        })
}

const relationships = ref([]);
const getRelationships = function () {
    axios
        .get(route('api.enum.personal-relationships'))
        .then((res) => {
            res.data.forEach((relationship) => {
                relationships.value.push({
                    value: relationship,
                    label: relationship,
                })
            })
        })
}

const updateAbilities = (value) => {
    driver.abilities = value
}

const genderOptions = ref([
    {
        value: 'not_stated',
        label: 'Not Stated'
    }, {
        value: 'male',
        label: 'Male',
    }, {
        value: 'female',
        label: 'Female',
    }, {
        value: 'intersex',
        label: 'Intersex'
    }
])

const mounted = ref(false)
const loading = ref(false)
const dataLoaded = ref(false);

onReady(() => {
    mounted.value = true
    dataLoaded.value = true
})

onMounted(() => {
    getRegions();
    getEmploymentTypes();
    getDriverTypes();
    getIndigenousStatuses();
    getLanguages();
    getRelationships();

    if (!driver.gender.value) {
        driver.gender.value = 'not_stated'
    }
})
</script>
