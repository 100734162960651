<template>
    <div>
        <label :for="'dateAdjuster_' + componentId" class="visually-hidden">Select Date:</label>
        <div class="row align-items-center">
            <div class="col-sm-8">
                <input
                    :id="'dateAdjuster_' + componentId"
                    v-model="date"
                    :max="maximumDate"
                    :min="minimumDate"
                    :disabled="disabled"
                    class="form-control"
                    type="date"
                >
            </div>
            <div class="col-sm-4">
                <div class="btn-group btn-group-sm d-none d-sm-inline-flex" role="group">
                    <button
                        :disabled="shouldDisablePreviousButton || disabled"
                        aria-label="Previous Day"
                        class="btn btn-light"
                        type="button"
                        @click="adjustDate(-1)"
                    >
                        <i class="mdi mdi-chevron-left"></i>
                        <span class="visually-hidden">Previous Day</span>
                    </button>
                    <button
                        :disabled="shouldDisableNextButton || disabled"
                        aria-label="Next Day"
                        class="btn btn-light"
                        type="button"
                        @click="adjustDate(1)"
                    >
                        <i class="mdi mdi-chevron-right"></i>
                        <span class="visually-hidden">Next Day</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DateAdjuster"
}
</script>
<script setup>
import {DateTime} from 'luxon'
import {ref, computed, watch, onMounted} from 'vue'
import {uuid} from "vue-uuid";

const componentId = ref(uuid.v4())
const date = ref('')

const emit = defineEmits(['update:modelValue'])
watch(date, () => {
    emit('update:modelValue', date.value)
})

const adjustDate = function (days) {
    date.value = DateTime.fromISO(date.value).plus({days: days}).toFormat('yyyy-MM-dd')
}

const shouldDisablePreviousButton = computed(() => {
    if (!props.disablePastDates) {
        return false
    }

    return DateTime.fromISO(date.value).startOf('day') <= DateTime.local().startOf('day')
})

const shouldDisableNextButton = computed(() => {
    if (!props.disableFutureDates && !props.disableCurrentAndFutureDates) {
        return false
    }

    let max = DateTime.local()
    if (props.disableCurrentAndFutureDates) {
        max = max.minus({days: 1})
    }

    return DateTime.fromISO(date.value).startOf('day') >= max.startOf('day')
})

const minimumDate = computed(() => {
    if (!props.disablePastDates) {
        return null
    }

    return DateTime.local().toFormat('yyyy-MM-dd')
})

const maximumDate = computed(() => {
    if (!props.disableFutureDates && !props.disableCurrentAndFutureDates) {
        return null
    }

    let max = DateTime.local()
    if (props.disableCurrentAndFutureDates) {
        max = max.minus({days: 1})
    }

    return max.toFormat('yyyy-MM-dd')
})

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disablePastDates: {
        type: Boolean,
        default: false,
    },
    disableFutureDates: {
        type: Boolean,
        default: false,
    },
    disableCurrentAndFutureDates: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

onMounted(() => {
    date.value = DateTime.fromISO(props.modelValue).toFormat('yyyy-MM-dd')
})
</script>

<style scoped>

</style>
