<template>
    <label
        v-if="label && label.length > 0"
        :class="[labelClass, optional ? 'optional-label' : '', input.required ? 'required-label' : '']"
        :for="inputName"
    >
        {{ label }}
    </label>
    <slot name="label-suffix"></slot>
    <input
        @input="validateInput"
        v-mask="mask"
        :value="input.value"
        :type="type"
        :class="[inputClass, {'is-invalid': isInvalid(meta, input)}]"
        :name="inputName"
        :placeholder="placeholder"
        :disabled="disabled"
        :tabindex="tabindex"
        :maxlength="maxlength"
    />
    <div v-if="maxlength && input.value" class="text-end text-xs text-secondary">{{ input.value.length }}/{{ maxlength }}</div>
    <div :class="errorClass">{{ getErrorMessage(input, errorMessage) }}</div>
</template>

<script setup>
import {kebabCase} from 'lodash'
import {useField} from 'vee-validate'
import * as yup from 'yup'
import {copyValues, isInvalid, getErrorMessage} from '../Utils'

    const {
        input,
        label,
        placeholder,
        inputClass,
        labelClass,
        errorClass,
        inputName,
        validation,
        mask,
        optional,
        disabled,
        type,
        tabindex,
        maxlength,
    } = defineProps({
        input: {
            required: true,
            type: Object,
        },
        label: {
            type: String,
            default: null,
        },
        inputName: {
            type: String,
            default: () => {
                kebabCase((Math.random() + 1).toString(36).substring(7))
            }
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        labelClass: {
            type: String,
            default: 'form-label'
        },
        errorClass: {
            type: String,
            default: 'text-danger form-text'
        },
        validation: {
            type: Object,
            default: yup.string().max(255),
        },
        mask: {
            type: Object,
            default: null
        },
        optional: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        tabindex: {
            type: Number,
            default: 0
        },
        maxlength: {
            type: String,
            required: false,
            default: null,
        },
    })

    const emit = defineEmits(['update:input'])
    const { errorMessage, setValue, validate, meta } = useField(inputName, validation)

    const validateInput = (event) => {
        const value = event.target.value
        setValue(value)
        validate().then(() => {
            let returnValue = copyValues(input)
            returnValue.value = value;
            returnValue.valid = meta.valid
            emit('update:input', returnValue)
        })
    }
</script>
