<template>
    <Modal :show="show" :closable="false">
        <template v-slot:header>
            <slot name="header">
                <h5 class="modal-title">{{ props.title }}</h5>
            </slot>
        </template>
        <template v-slot:body>
            <slot name="body">
                Please confirm that you would like to take this action!
            </slot>
        </template>
        <template v-slot:footer>
            <slot name="footer">
                <div class="d-inline-flex justify-content-end">
                    <Button @click="emits('confirmed')" :color="props.confirm_colour">Confirm</Button>
                    <button @click="cancel" class="btn btn-light ms-2" type="button">Cancel</button>
                </div>
            </slot>
        </template>

    </Modal>
</template>

<script setup lang="ts">
import {Modal, Button} from "@components";
import {Ref, ref, toRef, watch} from "vue";

const props = withDefaults(
    defineProps<{
        show: boolean,
        title?: string,
        confirm_colour?: string,
    }>(),
    {
        title: 'Please Confirm',
        confirm_colour: 'primary'
    }
)

const show: Ref<boolean> = ref<boolean>(props.show);

const emits = defineEmits<{
    (e: 'confirmed'): void,
    (e: 'cancelled', event: MouseEvent): void,
}>();

const cancel = (event: MouseEvent) => {
    emits('cancelled', event);
    console.log(`[ConfirmModal] - cancel() :: `, event.defaultPrevented)
    if (!event.defaultPrevented) {
        show.value = false;
    }
}

watch(props,
    () => {
        show.value = props.show;
    }
)

</script>