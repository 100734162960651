<template>
    <div class="d-flex">
        <EditableInput
            @updated="storeChanges"
            :value="props.attachment.name"
            :loading="updatingAttachments"
            type="text"
        ></EditableInput>
        <ul class="text-danger" v-if="fieldErrors[`${props.attachment.uuid}.name`]?.length > 0">
            <li v-for="(error, idx) in fieldErrors[`${props.attachment.uuid}.name`]" :key="idx">{{ error }}</li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import { storeToRefs } from "pinia";
    import EditableInput from "@components/Forms/EditableFields/EditableInput.vue";
    import { useAttachmentStore } from "@stores/AttachmentStore";

    const attachmentStore = useAttachmentStore();
    const { fieldErrors, updatingAttachments } = storeToRefs(attachmentStore);
    const { updateAttachment } = attachmentStore;

    const props = withDefaults(
        defineProps<{
            attachment: App.Resource.Attachment,
            index: number,
        }>(),
        {}
    );

    const emit = defineEmits<{
        (e: 'update:attachment', value: App.Resource.Attachment): void
    }>();

    const storeChanges = (value): void => {

        let data = {
            name: value,
        }

        const attachment = props.attachment;

        updateAttachment(attachment.uuid, data);

        attachment.name = value;

        emit('update:attachment', attachment);
    }
</script>