<template>
    <BookingFromClientSelectionStickyHeader />
    <BookingFormClientSelection />
    <div v-if="bookingStore.draft.clients.length > 0 && bookingPopulated === true">
        <BookingFormStopsSection />
    </div>
    <BookingFormRescheduleSection />
    <BookingFormSaveSection />
</template>

<script setup lang="ts">
// noinspection ES6PreferShortImport
import { useBookingStore } from "@stores/BookingStore";
import {onBeforeMount, onMounted, ref, watch} from "vue";
import BookingFormClientSelection from "./BookingFormClientSelection.vue";
import BookingFormStopsSection from "./BookingFormStopsSection.vue";
import BookingFromClientSelectionStickyHeader from "./BookingFromClientSelectionStickyHeader.vue";
import BookingFormRescheduleSection from "./BookingFormRescheduleSection.vue";
import BookingFormSaveSection from "./BookingFormSaveSection.vue";
import BookingFormOptimizerHelper from "@classes/Helpers/BookingFormOptimizerHelper";
import Booking, { BookingInterface } from "@classes/Booking";
import { calculateBookingFareIfRequired } from "@classes/Helpers/BookingFormFareHelper";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { useFundingTypeStore } from "../../stores/FundingTypeStore";
import { useOrganisationSettingStore } from "@stores/OrganisationSettingStore";
const organisationSettingStore = useOrganisationSettingStore();

const bookingStore = useBookingStore();
const fundingTypeStore = useFundingTypeStore();

const props = withDefaults(
    defineProps<{
        booking?: BookingInterface;
    }>(),
    { booking: undefined }
);

const bookingPopulated = ref(false);

const populateBooking = async () => {
    bookingPopulated.value = await bookingStore.populateBookingFormFromBookingEdit(props.booking);
}

onBeforeMount( () => {
    populateBooking();
});

// moved from mounted to this so we wait until booking is populated
watch(bookingPopulated, () => {
    bookingStore.loadVehicleConfigurationOptions();
    bookingStore.loadFundingTypes();
    bookingStore.loadFundingRules();
    bookingStore.loadBookingPurposes();
    bookingStore.loadFareCollectionMethods();
    bookingStore.loadTreatmentTypes();
    bookingStore.loadPayers();
    bookingStore.loadAgencies();
    bookingStore.loadBookingServiceTypes();
    bookingStore.loadRegions();
    organisationSettingStore.sync(true);
});

//Everytime the booking draft changes, create a list of estimated timing requests for each booking for old and new state
//Then see which estimated timing requests have been changed and only send optimizer requests for those
watch(
    () => _.cloneDeep(bookingStore.draft),
    (newDraft, oldDraft) => {
        let estimatedTimingParametersWithRequiredUpdate =
            BookingFormOptimizerHelper.getEstimatedTimingsWithAdjustedParametersBetweenOldAndNewBookingStoreDraft(
                newDraft,
                oldDraft
            );

        estimatedTimingParametersWithRequiredUpdate.map((parameters) => {
            bookingStore.loadAndSaveEstimatedTimingsForStorableEstimatedTimingRequestOptions(
                parameters
            );
        });
    }
);

//We want to for some clients send a combined KMs rather that individual fare calculator requests per stop

calculateBookingFareIfRequired();
</script>
